import Sitebar from "../../components/Partials/Sitebar/SiteBar.vue"
import Helper from "../../components/Partials/Helper/Helper.vue"
import { ref } from 'vue';
import { useUserStore } from '../../store/store.js'

var MyShared = {
    /* Name */
    name: "Home",
    /* Data */
    setup() {

    },
    data() {
        return {
            user: {
                "permissions": []
            },

        }
    },
    /* Components */
    components: {
        Sitebar,
        Helper,
    },
    /* Props */
    props: {},
    /* Methods */
    methods: {},
};
export default MyShared;