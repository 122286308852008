<!----------------------------------------------->
<!--                  TEMPLATE                 -->
<!----------------------------------------------->
<template>
  <MobileIntegrationsDetails v-if="  mq.current  === 'mobile'"></MobileIntegrationsDetails>
  <TabletIntegrationsDetails v-if="  mq.current  === 'tablet'"></TabletIntegrationsDetails>
  <DesktopIntegrationsDetails v-if=" mq.current  === 'desktop'"></DesktopIntegrationsDetails>
</template>

<!----------------------------------------------->
<!--                    SCRIPT                 -->
<!----------------------------------------------->
<script>
import MobileIntegrationsDetails from "../components/IntegrationsDetails/MobileIntegrationsDetails";
import TabletIntegrationsDetails from "../components/IntegrationsDetails/TabletIntegrationsDetails";
import DesktopIntegrationsDetails from "../components/IntegrationsDetails/DesktopIntegrationsDetails";

export default {
  /* Name */
  name: "IntegrationsDetails",
  inject: ["mq"],
  /* Components */
  components: {
    MobileIntegrationsDetails,
    TabletIntegrationsDetails,
    DesktopIntegrationsDetails,
  },
};
</script>

<script setup>
import { useMq } from "vue3-mq";
const mq = useMq();
</script>

<!----------------------------------------------->
<!--                    STYLES                 -->
<!----------------------------------------------->
<style scoped >
</style>
