import Sitebar from "../../components/Partials/Sitebar/SiteBar.vue"
import Helper from "../../components/Partials/Helper/Helper.vue"
import { FilterMatchMode, FilterOperator } from 'primevue/api';
import OpenGroup from "../../components/Partials/OpenGroup/OpenGroup.vue"
import CustomChart from "../../components/Partials/CustomChart/CustomChart.vue"
import Chart from 'primevue/chart';
import Modal from '../../components/Partials/Modals/Modal.vue'
import ConfirmDialog from "primevue/confirmdialog";
import * as $api from "../../api/api";
import MapChart from 'vue-chart-map'

import { useUserStore } from '../../store/store.js'
const axios = require('axios');

import { nextTick } from "vue";

var MyShared = {
    /* Name */
    name: "Home",
    /* Data */
    setup() {

    },
    data() {
        return {
            user: {
                "permissions": []
            },
            editMode: false,
            uploadReady: false,
            uploadError: false,
            uploadLoading: false,
            showActions: true,
            actions: [{
                    label: this.$t('newAsset'),
                    icon: 'pi pi-plus',
                    callback: () => { this.createData() },
                    permit: false,
                },
                {
                    label: this.$t('editMode'),
                    icon: 'pi pi-pencil',
                    callback: () => { this.changeToEditMode() },
                    permit: false
                },
                {
                    label: this.$t('import'),
                    icon: 'pi pi-upload',
                    callback: () => { this.openImportData() },
                    permit: false,
                },
                /*
                {
                    label: this.$t('createNewAgent'),
                    icon: 'pi pi-stop-circle',
                    callback: () => { this.openNewAgent() },
                    permit: true,
                },*/
            ],
            items: [{
                    label: this.$t('details'),
                    step: 0
                },
                /*
                {
                    label: this.$t('events'),
                    step: 1
                },
                {
                    label: this.$t('incidents'),
                    step: 2
                },
                {
                    label: this.$t('alertConfiguration'),
                    step: 3
                },*/
            ],
            step: 0,
            viewmode: 0,
            viewmodemenu: false,
            tab_position: true,
            filters: {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
                id: {
                    operator: FilterOperator.AND,
                    constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
                },
                id: {
                    operator: FilterOperator.AND,
                    constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
                },
                name: {
                    operator: FilterOperator.AND,
                    constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
                },
                os: {
                    operator: FilterOperator.AND,
                    constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
                },
                host: {
                    operator: FilterOperator.AND,
                    constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
                },
                type: {
                    operator: FilterOperator.AND,
                    constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
                },
                criticality: {
                    operator: FilterOperator.OR,
                    constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
                },
                last_act_date: {
                    operator: FilterOperator.AND,
                    constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
                }
            },
            selectedData: null,
            atRiskAssets: 0,
            selectedGroups: [],
            hours: [
                this.$t('all_time'),
                this.$t('last_12_hours'),
                this.$t('last_24_hours'),
                this.$t('last_48_hours'),
                this.$t('last_7_days'),
                this.$t('last_1_month'),
            ],
            lastHours: this.$t('all_time'),
            data: [],
            data_backup: {},
            sources: [],
            search: '',
            selectedCategories: null,
            categories: [],
            selected: [],
            selectedSources: [],
            operatingSystems: [
                { name: 'Windows', logo: require('@/assets/OsLogos/windows.png'), value: 'windows' },
                { name: 'Mac OS', logo: require('@/assets/OsLogos/apple.png'), value: 'mac' },
                { name: 'Linux', logo: require('@/assets/OsLogos/linux.png'), value: 'linux' },
            ],
            selectedOS: null,
            details: {
                "id": 0,
                "description": '',
                "name": '',
                "os": '',
                "host": '',
                "type": '',
                "location": {
                    "country": '',
                    "city": '',
                    "latitude": 0,
                    "longitude": 0
                },
                "criticality": '',
                "tags": [],
                "ip_addr": [],
                "mac_addr": [],
                "first_date": '',
                "last_date": '',
                "asset_groups": [{
                    "description": "",
                    "name": "",
                    "organization_id": "",
                    "tags": [],
                    "id": ""
                }],
                "asset_events": [{
                    "title": "",
                    "description": "",
                    "severity": 0,
                    "detection_rule_id": "",
                    "created_at": "2022-11-09T11:39:01",
                    "status": [{
                        "status": "",
                        "description": "",
                        "user": "",
                        "date": ""
                    }],
                    "id": "",
                    "organization_id": ""
                }],
                "asset_incidents": [{
                    "title": "",
                    "description": "",
                    "severity": 1,
                    "status": [{
                        "status": "",
                        "description": ""
                    }],
                    "id": "",
                    "team_id": "",
                    "correlation_rule_id": "",
                    "events": [
                        ""
                    ]
                }]
            },
            groups: [],
            criticality_1: 0,
            criticality_2: 0,
            criticality_3: 0,
            criticality_4: 0,
            number_of_low_criticality: 0,
            number_of_medium_criticality: 0,
            number_of_high_criticality: 0,
            number_of_critical_criticality: 0,
            loading_table: false,
            loading_details: false,
            /* ==================================================================== Charts ==================================================================== */
            pieData1: {
                labels: [],
                datasets: [{
                    data: [],
                    backgroundColor: [],
                    hoverBackgroundColor: []
                }]
            },
            pieData2: {
                labels: [],
                datasets: [{
                    data: [0, 0, 0, 0],
                    backgroundColor: [],
                    hoverBackgroundColor: []
                }]
            },
            barData: {

            },
            pieChartOptions: {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        labels: {
                            color: '#495057'
                        }
                    }
                }
            },
            lineChartOptions: {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        labels: {
                            color: '#495057'
                        }
                    }
                },
                scales: {
                    x: {
                        ticks: {
                            color: '#495057'
                        },
                        grid: {
                            color: '#ebedef'
                        }
                    },
                    y: {
                        ticks: {
                            color: '#495057'
                        },
                        grid: {
                            color: '#ebedef'
                        }
                    }
                }
            },
            new_name: '',
            new_hostname: '',
            new_description: '',
            new_tags: [],
            new_ips: [],
            new_macs: [],
            new_type: '',
            new_location: '',
            new_groups: null,
            events: [],
            alerts: [],
            incidents: [],
            new_selectedAlerts: [],
            new_selectedIncidents: [],
            countryData: {},
            new_selectedEvents: [],
            criticalities: [
                { label: this.$t('low'), value: 1 },
                { label: this.$t('medium'), value: 2 },
                { label: this.$t('high'), value: 3 },
                { label: this.$t('critical'), value: 4 },
            ],

        }

    },
    /* Components */
    components: {
        Sitebar,
        Helper,
        OpenGroup,
        CustomChart,
        Chart,
        Modal,
        ConfirmDialog,
        MapChart

    },
    /* Props */
    props: {},
    /* Methods */
    methods: {
        formatString (text, max_chars = 30) {
            // if str.length > 30 then return str.substring(0, 30) + "..."
            if (text.length > max_chars) {
                return text.substring(0, max_chars) + "..."
            }
            return text
        },
        hasUserPermission(permission) {
            console.log('hasUserPermission!!!!!!!!!!');
            return this.hasUserPermissionGlobal(this.user, permission);
        },
        openImportData() {
            this.$refs.importData.openModal();
        },
        openNewAgent() {
            this.getCategories();
            this.$refs.newAgent.openModal();
        },
        getCategories() {
            // loop this.sources and get all categories
            for (let i = 0; i < this.sources.length; i++) {
                // check if category is already in the array
                if (!this.categories.some(e => e.name === this.sources[i].category)) {
                    // if it's not, add it
                    this.categories.push({
                        "name": this.sources[i].category,
                        "active": false
                    });
                }
            }
        },
        addToSelectedSources(id, selected) {
            if (selected) {
                this.selectedSources.push(id);
            } else {
                // remove id from selected
                for (let i = 0; i < this.selectedSources.length; i++) {
                    if (this.selectedSources[i] == id) {
                        this.selectedSources.splice(i, 1);
                    }
                }
            }
            console.log("SELECTED OS: ", this.selectedOS);
            console.log("SELECTED SOURCES: ", this.selectedSources);
        },
        createNewAgent() {
            // TODO: LLamar a la api, esperar y descargar el agente
            console.log("SELECTED OS: ", this.selectedOS);
            console.log("SELECTED SOURCES: ", this.selectedSources);
        },
        checkChar(letter) {
            // check if letter is between a-i or A-I
            if ((letter >= 'a' && letter <= 'i') || (letter >= 'A' && letter <= 'I')) {
                return 'default-img-v1';
            }
            // check if letter is between j-r or J-R
            if ((letter >= 'j' && letter <= 'r') || (letter >= 'J' && letter <= 'R')) {
                return 'default-img-v2';
            }
            // check if letter is between s-z or S-Z
            if ((letter >= 's' && letter <= 'z') || (letter >= 'S' && letter <= 'Z')) {
                return 'default-img-v3';
            }
        },
        updateSelected() {
            this.selected = [];
            // loop through selectedCategories
            for (let i = 0; i < this.selectedCategories.length; i++) {
                this.selected.push(this.selectedCategories[i].name);
            }
        },
        getSourceLogo(logo) {
            return process.env.VUE_APP_BASE_URL + '/' + logo;
        },
        onUploadOne() {
            console.log('onUploadOne');
            this.uploadLoading = true;
            setTimeout(() => {
                this.uploadLoading = false;
                this.uploadReady = true;
                this.$toast.add({ severity: 'success', summary: this.$t('success'), detail: this.$t('importedSuccesfully'), life: 3000 });
            }, 2000);
        },
        onUploadVarious() {
            console.log('onUploadVarious');
            this.uploadLoading = true;
            setTimeout(() => {
                this.uploadLoading = false;
                this.uploadReady = true;
                this.$toast.add({ severity: 'success', summary: this.$t('success'), detail: this.$t('importedSuccesfully'), life: 3000 });
            }, 2000);
        },
        theFormat(number) {
            return number.toFixed(2);
        },
        setSelectedData(data) {
            this.loading_details = true;
            $api.getOneAsset(data.id, true).then((response) => {
                this.details = data;
                if (response.events.length > 0) {
                    this.details.events = response.events;
                }
                if (response.alerts.length > 0) {
                    this.details.alerts = response.alerts;
                }
                if (response.incidents.length > 0) {
                    this.details.incidents = response.incidents;
                }
                if (response.sources.length > 0) {
                    this.details.sources = response.sources;
                }
                this.loading_details = false;
            }).catch((err) => {
                console.log(`[-] Error (getOneAsset): ${err}`);
            });
        },
        setSelectedToEdit(data) {
            this.toEditData = this.selectedData;
        },

        setLastHours(hour) {
            this.loading_table = true;
            this.lastHours = hour;
            let sdate = new Date();
            let edate = new Date();

            switch (this.lastHours) {
                case this.$t('last_12_hours'):
                    sdate.setHours(sdate.getHours() - 12);
                    break;
                case this.$t('last_24_hours'):
                    sdate.setHours(sdate.getHours() - 24);
                    break;
                case this.$t('last_48_hours'):
                    sdate.setHours(sdate.getHours() - 48);
                    break;
                case this.$t('last_7_days'):
                    sdate.setDate(sdate.getDate() - 7);
                    break;
                case this.$t('last_1_month'):
                    sdate.setMonth(sdate.getMonth() - 1);
                    break;
                case this.$t('all_time'):
                    sdate.setFullYear(sdate.getFullYear() - 10);
                    break;
            }

            // Format dates to yyyy-mm-ddThh:mm:ss
            sdate = sdate.toISOString().split('.')[0];
            edate = edate.toISOString().split('.')[0];

            $api.getAllAssets(true, sdate).then((response) => {
                this.data = response;
                if (this.data) {
                    this.details = this.data[0];
                    this.calcPercentageByCriticality();
                    this.createChartsData();
                    this.getAtRiskAssets();
                }

                this.loading_table = false;
            }).catch((err) => {
                console.log(`[-] Error (getAssets): ${err}`);
            });
        },
        formatDate(date) {
            if (date == null || date == '') return;

            // Format date to dd/mm/yyyy hh:mm
            let d = new Date(date);
            let day = d.getDate();
            let month = d.getMonth() + 1;
            let year = d.getFullYear();
            let hour = d.getHours();
            let minutes = d.getMinutes();
            // Add 0 if month is less than 10
            if (month < 10) {
                month = "0" + month;
            }
            // Add 0 if day is less than 10
            if (day < 10) {
                day = "0" + day;
            }
            // Add 0 if minutes is less than 10
            if (minutes < 10) {
                minutes = "0" + minutes;
            }
            return `${day}/${month}/${year} ${hour}:${minutes}`;
        },
        /*
        loadGroups() {
            // Load groups from data 
            let groups = [];
            // loop through data
            this.data.forEach((item) => {
                // loop through asset_groups
                item.asset_groups.forEach((group) => {
                    // check if group is already in groups
                    // loop through groups
                    let found = false;
                    groups.forEach((g) => {
                        if (g.id == group.id) {
                            found = true;
                        }
                    });
                    if (!found) {
                        // if not, add it
                        groups.push({
                            'id': group.id,
                            'name': group.name
                        });
                    }
                });
            });
            // set groups
            this.groups = groups;
        },
        */
        filterByGroup(group_ids, add) {
            // If no group is selected, show all assets
            if (group_ids.length == 0) {
                this.data = this.data_backup;
                this.calcPercentageByCriticality();
                this.getAtRiskAssets();
                return;
            }

            // Other case 
            // If is clicked on group tab
            if (add) {
                // find group id in groups and add it to selectedGroups
                this.groups.forEach((group) => {
                    if (group.id == group_ids[0].id) {
                        this.selectedGroups.push(group);
                    }
                });
            }
            // Get all data assets from group
            let data = this.data_backup;
            let data_filtered = [];
            for (let i = 0; i < data.length; i++) {
                for (let j = 0; j < data[i].asset_groups.length; j++) {
                    for (let k = 0; k < group_ids.length; k++) {
                        if (data[i].asset_groups[j].id == group_ids[k].id) {
                            // Add asset to data_filtered if not already in it
                            let found = false;
                            for (let l = 0; l < data_filtered.length; l++) {
                                if (data_filtered[l].id == data[i].id) {
                                    found = true;
                                }
                            }
                            if (!found) {
                                data_filtered.push(data[i]);
                            }
                        }
                    }
                }
            }
            this.data = data_filtered;
            this.calcPercentageByCriticality();
            this.getAtRiskAssets();
        },
        getAtRiskAssets() {
            // Find all assets with risk == true
            let data = this.data;
            let data_filtered = [];
            for (let i = 0; i < data.length; i++) {
                if (data[i].risk == true) {
                    data_filtered.push(data[i]);
                }
            }
            this.atRiskAssets = data_filtered.length
        },
        calcPercentageByCriticality() {
            // Calculate the percentage of each criticality
            let criticality_1 = 0;
            let criticality_2 = 0;
            let criticality_3 = 0;
            let criticality_4 = 0;
            let total = 0;
            this.data.forEach(element => {
                switch (element.criticality) {
                    case 1:
                        criticality_1++;
                        break;
                    case 2:
                        criticality_2++;
                        break;
                    case 3:
                        criticality_3++;
                        break;
                    case 4:
                        criticality_4++;
                        break;
                }
                total++;
            });

            total = criticality_1 + criticality_2 + criticality_3 + criticality_4;
            // Set the percentage of each criticality
            this.criticality_1 = (criticality_1 / total) * 100
            this.criticality_2 = (criticality_2 / total) * 100
            this.criticality_3 = (criticality_3 / total) * 100
            this.criticality_4 = (criticality_4 / total) * 100
                // Prepare for the chart
            this.criticality_1 = this.criticality_1 - 0.75;
            this.criticality_2 = this.criticality_2 - 0.75;
            this.criticality_3 = this.criticality_3 - 0.75;
            this.criticality_4 = this.criticality_4 - 0.75;
            // Number of elements 
            this.number_of_low_criticality = criticality_1;
            this.number_of_medium_criticality = criticality_2;
            this.number_of_high_criticality = criticality_3;
            this.number_of_critical_criticality = criticality_4;
        },
        createData() {
            this.$refs.createData.openModal();
            /*
            // get al incidents from API
            if (process.env.VUE_APP_ENV === 'development') {
                //await (this.incidents = require('./incidents.json'));
            } else if (process.env.VUE_APP_ENV === 'staging' || process.env.VUE_APP_ENV === 'production') {
                $api.getAllIncidents(false).then((response) => {
                    this.incidents = response;
                    console.log("INCIDENTS: ", this.incidents);
                }).catch((err) => {
                    console.log(`[-] Error (getAllIncidents): ${err}`);
                });
            } else {
                console.log("ERROR: NO ENVIRONMENT SELECTED");
            }

            //get all events from API
            if (process.env.VUE_APP_ENV === 'development') {
                //await (this.events = require('./events.json'));
            } else if (process.env.VUE_APP_ENV === 'staging' || process.env.VUE_APP_ENV === 'production') {
                $api.getAllEvents(false).then((response) => {
                    this.events = response;
                    console.log("EVENTS: ", this.events);
                }).catch((err) => {
                    console.log(`[-] Error (getAllEvents): ${err}`);
                });
            } else {
                console.log("ERROR: NO ENVIRONMENT SELECTED");
            }

            //get all alerts from API
            if (process.env.VUE_APP_ENV === 'development') {
                //await (this.alerts = require('./alerts.json'));
            } else if (process.env.VUE_APP_ENV === 'staging' || process.env.VUE_APP_ENV === 'production') {
                $api.getAllAlerts(false).then((response) => {
                    this.alerts = response;
                    console.log("ALERTS: ", this.alerts);
                }).catch((err) => {
                    console.log(`[-] Error (getAllAlerts): ${err}`);
                });
            } else {
                console.log("ERROR: NO ENVIRONMENT SELECTED");
            }
            */
        },
        changeToEditMode() {
            this.editMode = true;
            this.selectedData = null
        },
        deleteData() {
            this.$confirm.require({
                message: this.$t('deleteConfirmationAlert'),
                header: this.$t('deleteConfirmation'),
                icon: 'pi pi-info-circle',
                acceptClass: 'p-button-danger',
                accept: () => {
                    console.log(this.selectedData);
                    // loop selectedData and delete each one
                    for (let i = 0; i < this.selectedData.length; i++) {
                        $api.deleteAsset(this.selectedData[i].id).then((response) => {
                            console.log(`[+] Asset ${this.selectedData[i].name} deleted`);
                            // toast
                            this.$toast.add({ severity: 'success', summary: this.$t('success'), detail: this.$t('recordDeleted'), life: 3000 });
                            //refresh 
                            this.$router.go();
                        }).catch((err) => {
                            console.log(`[-] Error (deleteAsset): ${err}`);
                            // toast
                            this.$toast.add({ severity: 'error', summary: this.$t('error'), detail: this.$t('recordNotDeleted'), life: 3000 });
                        });
                    }
                },
                reject: () => {
                    //this.$toast.add({severity:'error', summary:this.$t('rejected'), detail:this.$t('rejectedAlert'), life: 3000});
                }
            });
        },
        setCriticalityForSelectedItems(criticality){
            this.$confirm.require({
                message: this.$t('confirmDialog'),
                header: this.$t('confirmation'),
                icon: 'pi pi-info-circle',
                acceptClass: 'p-button-danger',
                accept: () => {
                    console.log(this.selectedData);
                    // loop selectedData and delete each one
                    for (let i = 0; i < this.selectedData.length; i++) {
                        $api.updateAsset(this.selectedData[i].id, { "criticality": criticality }).then((response) => {
                            console.log(`[+] Asset ${this.selectedData[i].id} updated`);
                            // toast
                            this.$toast.add({ severity: 'success', summary: this.$t('success'), detail: this.$t('assetUpdated'), life: 3000 });
                            //refresh
                            this.$router.go();
                        }).catch((err) => {
                            console.log(`[-] Error (updateAsset): ${err}`);
                            // toast
                            this.$toast.add({ severity: 'error', summary: this.$t('error'), detail: this.$t('assetNotUpdated'), life: 3000 });
                        });;
                    }
                },
                reject: () => {
                    //this.$toast.add({severity:'error', summary:this.$t('rejected'), detail:this.$t('rejectedAlert'), life: 3000});
                }
            });
        },
        updateCriticality(id, criticality) {
            let data = this.data;

            for (let i = 0; i < data.length; i++) {
                if (data[i].id == id) {
                    data[i].criticality = criticality;
                    $api.updateAsset(id, { "criticality": criticality }).then((response) => {
                        console.log(`[+] Incident ${id} updated`);
                        // toast
                        this.$toast.add({ severity: 'success', summary: this.$t('success'), detail: this.$t('assetUpdated'), life: 3000 });
                    }).catch((err) => {
                        console.log(`[-] Error (updateAsset): ${err}`);
                        // toast
                        this.$toast.add({ severity: 'error', summary: this.$t('error'), detail: this.$t('assetNotUpdated'), life: 3000 });
                    });;
                }
            }
            this.data = data;
            this.calcPercentageByCriticality();
        },
        createArrayOfIds(array) {
            let ids = [];
            for (let i = 0; i < array.length; i++) {
                ids.push(array[i].id);
            }
            return ids;
        },
        createNewAsset(new_name, new_hostname, new_description, new_tags, new_ips, new_macs, new_type, new_city, new_country, new_groups, new_os) {
            let new_asset = {
                "name": new_name,
                "hostname": new_hostname,
                "description": new_description,
                "tags": new_tags,
                "ip_addr": new_ips,
                "mac_addr": new_macs,
                "type": new_type,
                "location": {
                    "city": new_city,
                    "country": new_country
                },
                "os": new_os,
                "criticality": 1,
                "asset_groups": this.createArrayOfIds(new_groups),
                /*
                "events": this.createArrayOfIds(this.new_selectedEvents),
                "alerts": this.createArrayOfIds(this.new_selectedAlerts),
                "incidents": this.createArrayOfIds(this.new_selectedIncidents),
                */
            }

            console.log("NEW ASSET: ", new_asset);

            $api.createAsset(new_asset).then((response) => {
                this.data.push(response)
                console.log(`[+] Asset ${new_name} created`);
                // toast
                this.$toast.add({ severity: 'success', summary: this.$t('success'), detail: this.$t('assetCreated'), life: 3000 });
                this.$refs.createData.closeModal();
            }).catch((err) => {
                console.log(`[-] Error (createAsset): ${err}`);
                // toast
                this.$toast.add({ severity: 'error', summary: this.$t('error'), detail: err.response.data.detail, life: 3000 });
            });
        },
        async createChartsData() {
            /* --------------------------- Assets at risk --------------------------- */
            // get at_risk assets
            let risk_assets = this.atRiskAssets;
            let no_risk_assets = this.data.length - risk_assets;
            // set data
            this.pieData1 = {
                labels: [this.$t('atRisk'), this.$t('notAtRisk')],
                datasets: [{
                    data: [risk_assets, no_risk_assets],
                    backgroundColor: [
                        '#ef6154',
                        '#6dc193'
                    ],
                    hoverBackgroundColor: [
                        '#ef6154',
                        '#6dc193'
                    ]
                }]
            };
            console.log("PIE DATA 1: ", this.pieData1);

            /* --------------------------- Groups --------------------------- */
            // get groups
            let groups = this.groups;
            let groups_data = [];
            let groups_labels = [];
            let groups_colors = [];
            for (let i = 0; i < groups.length; i++) {
                groups_data.push(groups[i].assets.length);
                groups_labels.push(groups[i].name);
                // generate random color
            }
            // set data
            this.pieData2 = {
                labels: groups_labels,
                datasets: [{
                    data: groups_data,
                    backgroundColor: ['#9e0142', '#d53e4f', '#f46d43', '#fdae61', '#fee08b', '#ffffbf', '#e6f598', '#abdda4', '#66c2a5', '#3288bd', '#5e4fa2'],
                    hoverBackgroundColor: ['#9e0142', '#d53e4f', '#f46d43', '#fdae61', '#fee08b', '#ffffbf', '#e6f598', '#abdda4', '#66c2a5', '#3288bd', '#5e4fa2']
                }]
            };
            console.log("PIE DATA 2: ", this.pieData2);


            /* --------------------------- Map --------------------------- */
            let mapData = {};
            for (let asset of this.data) {
                //convert location to country code
                if(!asset.location.country){
                    continue;
                }
                let countryCode = await this.convertLocationToCountryCode(asset.location.country)
                    // loop mapData and add to the country code
                    //console.log("COUNTRY CODE: ", countryCode);
                    //console.log("MAP DATA: ", mapData[countryCode]);
                if (mapData[countryCode]) {
                    mapData[countryCode] += 1;
                } else {
                    mapData[countryCode] = 1;
                }
            }
            console.log("MAP DATA: ", mapData);
            this.countryData = mapData

            /* --------------------------- Operating System --------------------------- */
            this.mac_assets = 0;
            this.windows_assets = 0;
            this.linux_assets = 0;
            this.other_assets = 0;

            for (let asset of this.data) {
                if (asset.os.toLowerCase().includes("mac")) {
                    this.mac_assets += 1;
                    console.log("MAC+++++");
                } else if (asset.os.toLowerCase().includes("windows")) {
                    this.windows_assets += 1;
                } else if (asset.os.toLowerCase().includes("linux")) {
                    this.linux_assets += 1;
                } else {
                    this.other_assets += 1;
                }
            }

            /* --------------------------- Top assets --------------------------- */
            this.barData = {
                labels: [],
                datasets: [{
                        type: 'bar',
                        label: this.$t('info'),
                        backgroundColor: '#aeb5c1',
                        data: [0, 0, 0, 0, 0, 0, 0, 0],
                    }, {
                        type: 'bar',
                        label: this.$t('low'),
                        backgroundColor: '#6dc193',
                        data: [0, 0, 0, 0, 0, 0, 0, 0],
                    }, {
                        type: 'bar',
                        label: this.$t('medium'),
                        backgroundColor: '#f7b34c',
                        data: [0, 0, 0, 0, 0, 0, 0, 0],
                    },
                    {
                        type: 'bar',
                        label: this.$t('high'),
                        backgroundColor: '#ef6154',
                        data: [0, 0, 0, 0, 0, 0, 0, 0],
                    },
                    {
                        type: 'bar',
                        label: this.$t('critical'),
                        backgroundColor: '#b83c32',
                        data: [0, 0, 0, 0, 0, 0, 0, 0],
                    }
                ]
            };

            $api.getAssetsByIncidents().then((response) => {
                console.log("TOP ASSETS: ", response);
                this.topAssets = response;
                // loop through top assets and get the asset name
                let top_assets_names = [];
                let top_info_values = [];
                let top_low_values = [];
                let top_medium_values = [];
                let top_high_values = [];
                let top_critical_values = [];

                // get top 8 assets labels
                for (let asset of this.topAssets) {
                    top_assets_names.push(asset.name);
                    top_info_values.push(asset.incidents[0]);
                    top_low_values.push(asset.incidents[1]);
                    top_medium_values.push(asset.incidents[2]);
                    top_high_values.push(asset.incidents[3]);
                    top_critical_values.push(asset.incidents[4]);
                }


                this.barData.labels = top_assets_names
                this.barData.datasets[0].data = top_info_values
                this.barData.datasets[1].data = top_low_values
                this.barData.datasets[2].data = top_medium_values
                this.barData.datasets[3].data = top_high_values
                this.barData.datasets[4].data = top_critical_values

                console.log("BAR DATA: ", this.barData);

            }).catch((err) => {
                console.log(`[-] Error (getAssetsByIncidents): ${err}`);
            })

        },
        async convertLocationToCountryCode(location) {
            // Convert location to country code
            const res = await axios.get('https://restcountries.com/v3.1/name/' + location);
            //console.log("RES: ", res.data[0].cca2);
            // return the country code
            return res.data[0].cca2;
        },
    },
    async mounted() {
        const userStore = useUserStore();
        const userObject = await userStore.getUser();
        this.user = userObject;

        // set actions permissions
        this.actions[0].permit = this.hasUserPermissionGlobal(this.user, 'asset:create')
        this.actions[1].permit = this.hasUserPermissionGlobal(this.user, 'asset:update')
        this.actions[2].permit = this.hasUserPermissionGlobal(this.user, 'asset:create')
            // Loop actions, if all are false, then hide actions button
        let allFalse = true;
        for (let i = 0; i < this.actions.length; i++) {
            if (this.actions[i].permit) {
                allFalse = false;
                break;
            }
        }
        if (allFalse) {
            this.showActions = false;
        }

        // get assets from API
        if (process.env.VUE_APP_ENV === 'development') {
            await (this.data = require('./assets.json'));
            await (this.data_backup = require('./assets.json'));
        } else if (process.env.VUE_APP_ENV === 'staging' || process.env.VUE_APP_ENV === 'production') {
            this.loading_table = true;
            $api.getAllAssets(true).then((response) => {
                this.data = response;
                this.data_backup = response;
                console.log("ASSETS: ", this.data);

                /* ---- Data loaded ---- */
                this.selectedData = this.data[0];
                this.setSelectedData(this.data[0]);
                if (this.data)
                    this.details = this.data[0];
                //wait for 5 secs
                this.loading_table = false;
                //this.loadGroups();
                this.calcPercentageByCriticality();
                this.getAtRiskAssets();
                this.lastHours = this.hours[0];

                // get asset_groups from API
                if (process.env.VUE_APP_ENV === 'development') {
                    //await (this.asset_groups = require('./asset_groups.json'));
                } else if (process.env.VUE_APP_ENV === 'staging' || process.env.VUE_APP_ENV === 'production') {
                    $api.getAllAssetGroups(true).then((response) => {
                        this.groups = response;
                        console.log("ASSET GROUPS: ", this.groups);

                        // ***************** create charts data *****************
                        this.createChartsData();

                    }).catch((err) => {
                        console.log(`[-] Error (getAllAssetGroups): ${err}`);
                    });
                } else {
                    console.log("ERROR: NO ENVIRONMENT SELECTED");
                }


            }).catch((err) => {
                this.loading_table = false;
                this.loading_details = false;
                console.log(`[-] Error (getAllAssets): ${err}`);
            });
            $api.getAllSources().then((response) => {
                this.sources = response;
                for (let i = 0; i < this.sources.length; i++) {
                    this.sources[i].selected = false;
                }
                console.log("SOURCES: ", this.sources);
            }).catch((err) => {
                console.log(`[-] Error (getAllSources): ${err}`);
            });

        } else {
            console.log("ERROR: NO ENVIRONMENT SELECTED");
        }


    },
};
export default MyShared;