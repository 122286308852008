<!----------------------------------------------->
<!--                  TEMPLATE                 -->
<!----------------------------------------------->
<template>
  <MobileInvestigationCreator v-if="mq.current === 'mobile'"></MobileInvestigationCreator>
  <TabletInvestigationCreator v-if="mq.current === 'tablet'"></TabletInvestigationCreator>
  <DesktopInvestigationCreator v-if="mq.current === 'desktop'"></DesktopInvestigationCreator>
</template>

<!----------------------------------------------->
<!--                    SCRIPT                 -->
<!----------------------------------------------->
<script>
import MobileInvestigationCreator from "../components/InvestigationCreator/MobileInvestigationCreator";
import TabletInvestigationCreator from "../components/InvestigationCreator/TabletInvestigationCreator";
import DesktopInvestigationCreator from "../components/InvestigationCreator/DesktopInvestigationCreator";

export default {
  /* Name */
  name: "InvestigationCreator",
  inject: ["mq"],
  /* Components */
  components: {
    MobileInvestigationCreator,
    TabletInvestigationCreator,
    DesktopInvestigationCreator,
  },
  methods: {

  },
  /*
  beforeRouteLeave: function(to, from, next) {
      if (this.getDashboardSaved()) {
        next()
      } else {
        const answer = window.confirm('Do you really want to leave? you have unsaved changes!')
        if (answer) {
          next()
        } else {
          next(false)
        }
      }
    }
    */
};
</script>

<script setup>
import { useMq } from "vue3-mq";
const mq = useMq();
</script>

<!----------------------------------------------->
<!--                    STYLES                 -->
<!----------------------------------------------->
<style scoped ></style>
