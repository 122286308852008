import * as $api from "../../api/api";

var MyShared = {
    /* Name */
    name: "Disputas",
    /* Data */
    data() {
        return {
            user: {
                "permissions": []
            },
            email: '',
            error: ''
        };
    },
    /* Components */
    components: {},
    /* Props */
    props: {},
    /* Methods */
    methods: {
        recoverPassword() {
            $api.recoverPassword(this.email).then((response) => {
                console.log("RECOVER PASS: ", response);
                this.$toast.add({ severity: 'success', summary: this.$t('success'), detail: this.$t('The email has been sent successfully'), life: 3000 });
                this.error = '';
            }).catch((err) => {
                console.log(`[-] Error (recoverPassword): ${err}`);
                this.$toast.add({ severity: 'error', summary: this.$t('error'), detail: this.$t('The email could not be sent'), life: 3000 });
                this.error = err.response.data.detail;
            });
        }
    },
};
export default MyShared;