<!----------------------------------------------->
<!--                  TEMPLATE                 -->
<!----------------------------------------------->
<template>
    <div class="aux">
    <Sitebar></Sitebar>
    <div class="maincontainer">
      <div class="row">
        <div class="col-12">
          <div class="welcome-container">
            <img class="welcome-img" :src="require('@/assets/logo.svg')" />
            <h2 class="welcome-text">You are welcome</h2>
            <div class="working-text">
              <h5 class=""> You are working in: <a>Name of the Dashboard</a></h5>
              <h6 class="mt-4"> You are actually working for this teams:</h6>
              <div class="team-div mt-2">
                <div class="team" v-for="team in 5" :key="team">
                  <p class="p-0 m-0 team-text"><i class="pi pi-bookmark-fill"></i>&nbsp;Team 1</p>
                </div>
              </div>
              <h2 class="welcome-text mt-2">Quick access</h2>
              <div class="card-div mt-2">
                <router-link to="integrations" style="text-decoration:none; color:black;">
                  <div class="card" >
                    <p class="p-0 m-0 card-text"><i class="pi pi-th-large"></i>&nbsp;&nbsp;{{ $t('integrations') }}</p>
                  </div>
                </router-link>
                <router-link to="logs" style="text-decoration:none; color:black;">
                  <div class="card" >
                    <p class="p-0 m-0 card-text"><i class="pi pi-desktop"></i>&nbsp;&nbsp;{{ $t('logs') }}</p>
                  </div>
                </router-link>
                <router-link to="manage-users" style="text-decoration:none; color:black;">
                  <div class="card" >
                    <p class="p-0 m-0 card-text"><i class="pi pi-users"></i>&nbsp;&nbsp;{{ $t('manageUsers') }}</p>
                  </div>
                </router-link>
                <router-link to="assets" style="text-decoration:none; color:black;">
                  <div class="card" >
                    <p class="p-0 m-0 card-text"><i class="pi pi-server"></i>&nbsp;&nbsp;{{ $t('assets') }}</p>
                  </div>
                </router-link>
                <router-link to="incidents" style="text-decoration:none; color:black;">
                  <div class="card" >
                    <p class="p-0 m-0 card-text"><i class="pi pi-briefcase"></i>&nbsp;&nbsp;{{ $t('incidents') }}</p>
                  </div>
                </router-link>
                <router-link to="machine-learning" style="text-decoration:none; color:black;">
                  <div class="card" >
                    <p class="p-0 m-0 card-text"><i class="pi pi-sitemap"></i>&nbsp;&nbsp;{{ $t('machineLearning') }}</p>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="row resume-row">
            <div class="col-12 pt-4 text-center">
              <number style="font-size: 8em; font-weight:700" ref="users_number" :from="0" :to="80" :duration="2"
                easing="Power1.easeOut" />
              <h3>Total indicents</h3>
            </div>
            <div class="col-8 pl-4 pr-0">
              <Chart type="doughnut" :data="chartData" :options="lightOptions" />
            </div>
            <div class="col-4">
              <div class="col-12 legend" style="border-left:#b83c32 10px solid;">
                <p class="m-0 p-0 incidents-label">&nbsp;Critical:</p>
                <p class="m-0 p-0 incidents-number">&nbsp;40</p>
              </div>
              <div class="col-12 legend" style="border-left:#ef6154 10px solid;">
                <p class="m-0 p-0 incidents-label">&nbsp;High:</p>
                <p class="m-0 p-0 incidents-number">&nbsp;60</p>
              </div>
              <div class="col-12 legend" style="border-left:#f7b34c 10px solid;">
                <p class="m-0 p-0 incidents-label">&nbsp;Medium:</p>
                <p class="m-0 p-0 incidents-number">&nbsp;10</p>
              </div>
              <div class="col-12 legend" style="border-left:#6dc193 10px solid;">
                <p class="m-0 p-0 incidents-label">&nbsp;Low:</p>
                <p class="m-0 p-0 incidents-number">&nbsp;30</p>
              </div>
              <div class="col-12 legend" style="border-left:#aeb5c1 10px solid;">
                <p class="m-0 p-0 incidents-label">&nbsp;Information:</p>
                <p class="m-0 p-0 incidents-number">&nbsp;60</p>
              </div>
            </div>
          </div>
          <div class="row resume-row mt-4">
            <Chart type="line" :data="lineChartData" :options="lineChartOptions" />
          </div>

        </div>
      </div>

    </div>
  </div>
</template>
<!----------------------------------------------->
<!--                    SCRIPT                 -->
<!----------------------------------------------->

<script>
import MyShared from "./shared";
export default {
  mixins: [MyShared],
};

</script>

<script setup>
import { useMq } from "vue3-mq";
const mq = useMq();
</script>

<!----------------------------------------------->
<!--                    STYLES                 -->
<!----------------------------------------------->
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

p {
  font-family: 'Open Sans', sans-serif !important;
}
.incidents-number {
  font-size: 1.25em;
  font-weight: 800 !important;
}
.incidents-label{
  font-size: 0.8em;
}


.resume-row{
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
}
.welcome-img {
  height: 8em;
  padding: 0.5em;
  padding-right: 0;
}

.welcome-text {
  padding-left: 0em;
  font-size: 2rem;
  padding-top: 1em;
  font-weight: 200;
}

.working-text {
  font-size: 0.9rem;
}

a {
}

.team {
  margin-right: 1em;
  margin-top: 1em;
  width: fit-content;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  border-radius: 3px;
  padding: 0.5em;
  padding-left: 1em;
  padding-right: 1em;
  display: inline-block;
}

.team:hover {
  box-shadow: 0 4px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
}

.team-text {
  font-size: 0.8rem;
}
.card-div {
  width: 100%;
}
.card {
  margin-top: 1em;
  width: 100%;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  border-radius: 3px;
  padding: 1.5em;
  padding-top: 1em;
  padding-bottom:1em;
  display: inline-block;
}

.card:hover {
  box-shadow: 0 4px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
}

.card-text {
  font-size: 0.9rem;
}

.legend {
  font-size: 1rem;
  margin: 0 !important;
  padding: 0 !important;
  margin-top:0.25em !important;
}
</style>
