<!----------------------------------------------->
<!--                  TEMPLATE                 -->
<!----------------------------------------------->
<template>
  <MobileDashboards v-if="  mq.current  === 'mobile'"></MobileDashboards>
  <TabletDashboards v-if="  mq.current  === 'tablet'"></TabletDashboards>
  <DesktopDashboards v-if=" mq.current  === 'desktop'"></DesktopDashboards>
</template>

<!----------------------------------------------->
<!--                    SCRIPT                 -->
<!----------------------------------------------->
<script>
import MobileDashboards from "../components/Dashboards/MobileDashboards";
import TabletDashboards from "../components/Dashboards/TabletDashboards";
import DesktopDashboards from "../components/Dashboards/DesktopDashboards";

export default {
  /* Name */
  name: "Dashboards",
  inject: ["mq"],
  /* Components */
  components: {
    MobileDashboards,
    TabletDashboards,
    DesktopDashboards,
  },
};
</script>

<script setup>
import { useMq } from "vue3-mq";
const mq = useMq();
</script>

<!----------------------------------------------->
<!--                    STYLES                 -->
<!----------------------------------------------->
<style scoped >
</style>
