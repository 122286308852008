import $axios from '../utils';

export function getAllMachineLearningTasks(full = true, offset = 0, limit = 10000) {
    return $axios.get(`analysis/machine_learning_task/?full=${full}&offset=${offset}&limit=${limit}`)
        .then(response => response.data)
}
export function getMachineLearningTasksBy(key, value, full, offset, limit) {
    return $axios.get(`analysis/machine_learning_task/by?key${key}&value=${value}&full=${full}&offset=${offset}&limit=${limit}`)
        .then(response => response.data)
}
export function getOneMachineLearningTask(id, full) {
    return $axios.get(`analysis/machine_learning_task/${id}?full=${full}`)
        .then(response => response.data)
}
export function createMachineLearningTask(data) {
    return $axios.post('analysis/machine_learning_task/', data)
        .then(response => response.data)
}
export function updateMachineLearningTask(id, data) {
    return $axios.put(`analysis/machine_learning_task/${id}`, data)
        .then(response => response.data)
}
export function deleteAllMachineLearningTasks() {
    return $axios.delete('analysis/machine_learning_task/')
        .then(response => response.data)
}
export function deleteMachineLearningTask(id) {
    return $axios.delete(`analysis/machine_learning_task/${id}`)
        .then(response => response.data)
}