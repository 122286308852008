import $axios from '../utils';

export function getAllUsers(full = true, offset = 0, limit = 10000) {
    return $axios.get(`management/user/?full=${full}&offset=${offset}&limit=${limit}`)
        .then(response => response.data)
}
export function getOneUser(id, full) {
    return $axios.get(`management/user/${id}?full=${full}`)
        .then(response => response.data)
}
export function createUser(data) {
    return $axios.post('management/user/', data)
        .then(response => response.data)
}
export function updateUser(id, data) {
    return $axios.put(`management/user/${id}`, data)
        .then(response => response.data)
}
export function deleteUser(id) {
    return $axios.delete(`management/user/${id}`)
        .then(response => response.data)
}
export function register(token, data) {
    return $axios.post(`management/user/register?token=${token}`, data)
        .then(response => response.data)
}
export function recoverPassword(email) {
    return $axios.post(`management/user/password-recovery/${email}`)
        .then(response => response.data)
}
export function resetPassword(token, data) {
    return $axios.post(`management/user/password-reset?token=${token}`, data)
        .then(response => response.data)
}
export function checkRegister(token) {
    return $axios.get(`management/user/check-token?token=${token}`)
        .then(response => response.data)
}