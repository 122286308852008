<!----------------------------------------------->
<!--                  TEMPLATE                 -->
<!----------------------------------------------->
<template>
  <div class="aux" style="height: 100vh;">
    <div class="login-container">
      <div class="form-container">
        <div class="login-header text-center">
          <img class="logo" v-bind:src="projectLogo" alt="logo" />
        </div>
        <div class="field row pass-field">
          <h5 class="p-0">{{$t('enterTheEmailOfYourAccount')}}</h5>
          <p class="p-0" style="color:gray">{{$t('weWillSendYouAnEmailWithInstructions')}}</p>
        </div>
        <div class="field row pass-field">
          <p class="show_error">{{error}}</p>
          <label for="email" class="pl-0 mt-1 mb-0">{{$t('email')}}</label>
          <InputText id="email" type="text" v-model="email" class="input-text"></InputText>
        </div>

        <div class="row button-row mt-4"></div>
        <Button class="btn-custom btn-login" label="Send" @click="recoverPassword()"></Button>
        <Button class="btn-custom btn-login mr-2" label="Go login" @click="$router.push({name : 'SignIn'})"></Button>
      </div>
    </div>
  </div>
</template>

<!----------------------------------------------->
<!--                    SCRIPT                 -->
<!----------------------------------------------->

<script>
import MyShared from "./shared";
export default {
  mixins: [MyShared],
};

</script>

<script setup>
import { useMq } from "vue3-mq";
const mq = useMq();
</script>
<!----------------------------------------------->
<!--                    STYLES                 -->
<!----------------------------------------------->
<style scoped lang="css" >
* {
  font-family: 'Open Sans', sans-serif !important;
}

a {
  text-decoration: none !important;
  color: #ec635f !important;
}

.aux {
  background-image: url("../../assets/bg-login1.png");
}

.button-row {
  width: 100%;
}

.btn-login {
  float: right;
  font-weight: 400 !important;
}

.forgot-pass {
  font-size: 0.9em;
  margin-top: 0.25em;
  font-weight: 600;
  margin-top: 0.5em;
}

.input-text {
  width: 100%;
}

.pass-field {
  margin-top: 1em;
}

.email-field {
  margin-top: 3em;
}

.form-container {
  padding-left: 2em;
  padding-right: 2em;
  padding-bottom: 6em;
  padding-top: 1em;
}

.logo {
  width: 100%;
  height: 8em;
  margin-bottom: 1em;
  margin-top: 2em;
}

.login-container {
  width: 30em;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.title {
  color: black;
}

/* Custom buttons */
.btn-custom {
  margin: auto;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  background: #ec635f !important;
  color: white;
  border: none;
  padding: 0.25rem 0.5rem;
  font-weight: bold;
  width: auto;
}

.btn-custom:focus {
  box-shadow: 0px 0px 2px #ec635f !important;
}

.btn-custom:hover {
  background: #d65955 !important;
}

.p-inputtext:focus {
  border-color: #ec635f !important;
  box-shadow: 0px 0px 2px #ec635f !important;
  outline: none;
}

.p-inputtext:hover {
  border-color: #ec635f !important;
}
</style>
