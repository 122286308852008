import Sitebar from "../../components/Partials/Sitebar/SiteBar.vue"

var MyShared = {
    /* Name */
    name: "NotFound",
    /* Data */
    setup() {

    },
    data() {
        return {

        }
    },
    /* Components */
    components: {
        Sitebar,
    },
    /* Props */
    props: {},
    /* Methods */
    methods: {
        
    },
    mounted() {

    }
};
export default MyShared;