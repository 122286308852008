<!----------------------------------------------->
<!--                  TEMPLATE                 -->
<!----------------------------------------------->
<template>
  <div class="col-12 p-0">
    <h6 class="m-0">{{ permissionGroupName }} <i :class="(!open) ? 'pi pi-chevron-down':'pi pi-chevron-up'" style="float:right; cursor:pointer;" @click="open = !open"></i></h6>
    <hr class="m-0">
    <div class="row" v-if="open">
      <div class="col-12 pt-1 pb-0" v-for="permission in permissions" :key="permission">
        <Checkbox v-model="selected_permissions" :value="permission.id" @change="updateSelectedPermissions(selected_permissions)"  />
        <span class="ml-2">{{ permission.name }}</span>
      </div>
    </div>
  </div>
</template>
<!----------------------------------------------->
<!--                    SCRIPT                 -->
<!----------------------------------------------->

<script>
import MyShared from "./shared";
export default {
  mixins: [MyShared],
};
</script>

<script setup>
import { useMq } from "vue3-mq";
const mq = useMq();
</script>

<!----------------------------------------------->
<!--                    STYLES                 -->
<!----------------------------------------------->
<style scoped>
/* Groups tab */
.open-group {
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  margin-bottom: 1em;
}

.open-group-title {
  background: #d3d3d36e;
  cursor: pointer;
}

.group-content {
  height: fit-content;
}

.new-tag {
  background: #ec635f;
  color: #fff;
  padding: 0.2em 0.5em;
  border-radius: 500px;
  font-size: 0.8em;
  margin-left: 0.5em;
}
</style>
