var MyShared = {
    /* Name */
    name: "NameHere",
    inject: ["mq"],
    /* Data */
    data() {
        return {
            user: {
                "permissions": []
            },
            currentUrl: ''
        };
    },
    /* Components */
    components: {},
    /* Props */
    props: ['target'],
    /* Methods */
    methods: {
        setCurrentRoute() {},
    },
    async created() {
        var Url = this.$route.fullPath
        console.log(this.$route.fullPath)
        if (Url.includes("home")) {
            this.currentUrl = "Home";
        }
        if (Url.includes("integrations")) {
            this.currentUrl = "Integrations";
        }
        if (Url.includes("listeners")) {
            this.currentUrl = "Listeners";
        }
        if (Url.includes("logs")) {
            this.currentUrl = "Logs";
        }
        if (Url.includes("assets")) {
            this.currentUrl = "Assets";
        }
        if (Url.includes("events")) {
            this.currentUrl = "Events";
        }
        if (Url.includes("incidents")) {
            this.currentUrl = "Incidents";
        }
        if (Url.includes("alert-configuration")) {
            this.currentUrl = "Alert Configuration";
        }
        if (Url.includes("machine-learning")) {
            this.currentUrl = "Machine Learning";
        }
        if (Url.includes("correlation")) {
            this.currentUrl = "Correlation";
        }
        if (Url.includes("detection")) {
            this.currentUrl = "Detection";
        }
        if (Url.includes("manage-users")) {
            this.currentUrl = "Manage Users";
        }
        if (Url.includes("manage-teams")) {
            this.currentUrl = "Manage Teams";
        }
        if (Url.includes("manage-organizations")) {
            this.currentUrl = "Manage Organizations";
        }
        if (Url.includes("manage-roles")) {
            this.currentUrl = "Manage Roles";
        }
        if (Url.includes("permissions")) {
            this.currentUrl = "Permissions";
        }
        if (Url.includes("investigation-creator")) {
            this.currentUrl = "Investigation Creator";
        }
        if (Url.includes("investigations")) {
            this.currentUrl = "Investigations";
        }
    },
    /* Emits */
    emits: ["emits", "emits"],
};
export default MyShared;