<!----------------------------------------------->
<!--                  TEMPLATE                 -->
<!----------------------------------------------->
<template>
  <MobileAssets v-if="  mq.current  === 'mobile'"></MobileAssets>
  <TabletAssets v-if="  mq.current  === 'tablet'"></TabletAssets>
  <DesktopAssets v-if=" mq.current  === 'desktop'"></DesktopAssets>
</template>

<!----------------------------------------------->
<!--                    SCRIPT                 -->
<!----------------------------------------------->
<script>
import MobileAssets from "../components/Assets/MobileAssets";
import TabletAssets from "../components/Assets/TabletAssets";
import DesktopAssets from "../components/Assets/DesktopAssets";

export default {
  /* Name */
  name: "Assets",
  inject: ["mq"],
  /* Components */
  components: {
    MobileAssets,
    TabletAssets,
    DesktopAssets,
  },
};
</script>

<script setup>
import { useMq } from "vue3-mq";
const mq = useMq();
</script>

<!----------------------------------------------->
<!--                    STYLES                 -->
<!----------------------------------------------->
<style scoped >
</style>
