<!----------------------------------------------->
<!--                  TEMPLATE                 -->
<!----------------------------------------------->
<template>
  <div>
    <Sitebar></Sitebar>
    <div class="maincontainer" :style="'--my-font-var:' + primaryFont + ';'">
      <div class="">
        <div class="row">
          <div class="col-12">
            <div class="p-datatable pb-2" style="border: 1px solid #e9ecef">
              <div class="p-datatable-header">
                Agency user
                <Button type="button" :icon="isDisabled ? 'pi pi-pencil' : 'pi pi-save'" iconPos="right"
                  class="p-button-secondary ml-2 edit-profile"
                  @click="isDisabled = !isDisabled; !isDisabled ? null : saveProfile(name, nif, country);">
                </Button>
              </div>
              <div class="new-travel pl-2">
                <div class="row pr-4 pb-4">
                  <div class="col-12 text-center" @click="toggleShow">
                    <img :src="userimg" alt="Avatar" class="rounded-circle mt-2" height="200" width="200" />
                  </div>
                  <div class="col-12">
                    <div class="row pr-4">
                      <div class="col-12 pt-0">
                        <label class="profile-label" for="name"> Name </label>
                        <InputText class="profile-input" id="name" type="text" v-model="name" :value="user.name"
                          :disabled="isDisabled" />
                      </div>
                      <div class="col-12 pt-0">
                        <label class="profile-label" for="nif"> NIF </label>
                        <InputText class="profile-input" id="nif" type="text" v-model="nif" :value="user.nif"
                          :disabled="isDisabled" />
                      </div>
                      <div class="col-12 pt-0">
                        <label class="profile-label" for="country">
                          Country
                        </label>
                        <InputText class="profile-input" id="country" type="text" v-model="country"
                          :value="user.country" :disabled="isDisabled" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <my-upload field="img" @srcFileSet="srcFileSet" @crop-success="cropSuccess" @crop-upload-success="cropUploadSuccess"
      @crop-upload-fail="cropUploadFail" v-model="show" :width="300" :height="300" img-format="png" langType="en">
    </my-upload>
  </div>
</template>

<!----------------------------------------------->
<!--                    SCRIPT                 -->
<!----------------------------------------------->
<script>
import MyShared from "./shared";

export default {
  mixins: [MyShared],
};
</script>

<!----------------------------------------------->
<!--                    STYLES                 -->
<!----------------------------------------------->
<style scoped>
.rounded-circle:hover {
  cursor: pointer;
  filter: brightness(1.25);
}
a {
  text-decoration: none;
}

.form-control:focus {
  box-shadow: none;
  border-color: var(--my-color-var);
}

.labels {
  font-size: 11px;
}

.profile-row {
  background-color: #f8f9fa;
  width: 100%;
  justify-content: center;
  /* margin-right: 2rem !important; */
}

.row {
  /* margin-right: 2rem !important;
    margin-left: 7rem !important; */
}

.btn-custom {
  background: linear-gradient(90deg,
      rgba(27, 83, 136, 1) 0%,
      rgba(59, 123, 140, 1) 50%,
      rgba(100, 174, 146, 1) 100%) !important;
  color: white;
  border: none !important;
  border-radius: 0.5rem;
  width: auto;
}

.profile-title {
  color: #70ad47;
  border-bottom: 2px solid #114687;
  font-weight: bold;
  margin-bottom: 2rem;
  margin-top: 1rem;
  padding: 0;
  width: 100%;
}

.profile-input {
  /* border-bottom: 2px solid rgb(201, 201, 201); */
  border-radius: 0;
  width: 100%;
}

.profile-label {
  color: #70ad47;
  font-weight: bold;
  text-align: left;
}

.edit-profile {
  float: right;
  margin-top: -0.5rem !important;
}

.modal-cont {
  padding-left: 4em;
  padding-right: 4em;
}

.modal-title {
  font-size: 1.5em;
  font-weight: bold;
  color: #70ad47;
}

.btn-custom {
  background: linear-gradient(90deg,
      rgba(27, 83, 136, 1) 0%,
      rgba(59, 123, 140, 1) 50%,
      rgba(100, 174, 146, 1) 100%) !important;
  color: white;
  border: none !important;
  border-radius: 0.5rem;
  width: auto;
}
</style>


