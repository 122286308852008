<!----------------------------------------------->
<!--                  TEMPLATE                 -->
<!----------------------------------------------->
<template>
  <div class="col-12 open-group p-0">
    <h6 class="p-2 m-0 open-group-title" @click="open = !open">
      {{ groupName }} <span v-if="new_item" class="new-tag">New</span> <i v-if="open" class="pi pi-angle-up"
        style="float:right;"></i><i v-else class="pi pi-angle-down" style="float:right;"></i>
    </h6>
    <div class="group-content p-2" v-if="open">
      <div class="row pl-3 pt-0 pr-3 pb-3">
        <slot name="body" />
      </div>
    </div>
  </div>
</template>
<!----------------------------------------------->
<!--                    SCRIPT                 -->
<!----------------------------------------------->

<script>
import MyShared from "./shared";
export default {
  mixins: [MyShared],
};
</script>

<script setup>
import { useMq } from "vue3-mq";
const mq = useMq();
</script>

<!----------------------------------------------->
<!--                    STYLES                 -->
<!----------------------------------------------->
<style scoped>
/* Groups tab */
.open-group {
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  margin-bottom: 1em;
}

.open-group-title {
  background: #d3d3d36e;
  cursor: pointer;
}

.group-content {
  height: fit-content;
}

.new-tag {
  background: #ec635f;
  color: #fff;
  padding: 0.2em 0.5em;
  border-radius: 500px;
  font-size: 0.8em;
  margin-left: 0.5em;
}
</style>
