<!----------------------------------------------->
<!--                  TEMPLATE                 -->
<!----------------------------------------------->
<template>
  <div class="aux">
    <Sitebar></Sitebar>
    <ConfirmDialog></ConfirmDialog>
    <div class="maincontainer" >
      <div class="row">
        <div class="col-6">
          <h5 class="ml-0">
            {{ $t('yourDashboards') }}
            <span> <Button :label="$t('createNewDashboard')" class="custom-btn" icon="pi pi-plus"
                @click="$router.push({ path: 'dashboard-creator' })" style="float:right; padding-left:0 ;" /> </span>
          </h5>
          <div class="card p-3">
            <span class="p-input-icon-left" style="width:100%;">
              <InputText v-model="filters['global'].value" :placeholder="$t('searchByAsset')" class="search-input mb-2">
              </InputText>
            </span>
            <DataTable :value="data" :paginator="true" class="p-datatable-customers" :rows="15" :row-hover="true"
              v-model:selection="selectedAsset" dataKey="id" v-model:filters="filters" filter-display="menu"
              :loading="loading_table" paginator-template="PrevPageLink PageLinks NextPageLink CurrentPageReport"
              :rows-per-page-options="[10, 25, 50]"
              current-page-report-template="Showing {first} to {last} of {totalRecords} entries"
              :global-filter-fields="['id', 'id', 'name', 'created_at']" responsive-layout="scroll">
              <template #empty>
                {{ $t('noDataFound') }}
              </template>
              <template #loading>
                {{ $t('loadingData') }}
              </template>
              <Column field="name" :header="$t('name')" sortable>
                <template #body="{ data }">
                  <span @click="$router.push({ path: 'dashboard-viewer', query: { id: data.id } })"
                    class="row-name-link">{{data.name}}
                  </span>
                </template>
              </Column>
              <Column field="created_at" :header="$t('createdAt')" sortable>
                <template #body="{ data }">
                  {{ formatDate(data.created_at) }}
                </template>
              </Column>
              <Column field="">
                <template #body="{ data }">
                  <i class="pi pi-eye mr-2" style="cursor:pointer"
                    @click="$router.push({ path: 'dashboard-viewer', query: { id: data.id } })"></i>
                  <i class="pi pi-trash mr-2" style="cursor:pointer" @click="deleteDashboard(data.id)"></i>
                  <i class="pi pi-file-edit mr-2" style="cursor:pointer"
                    @click="$router.push({ path: 'dashboard-creator', query: { id: data.id } })"></i>
                </template>
              </Column>
            </DataTable>
          </div>
        </div>
        <div class="col-6">
          <div class="recent-container row ml-0 mt-0">
              <h5 class="ml-0 pl-0">
                {{ $t('recentDashboards') }}
              </h5>
              <div class="p-3" v-if="recentDashboards.length == 0">
                <p class="p-0 m-0 card-text"> <i class="pi pi-info-circle"></i>
                  {{ $t('noRecentDashboards') }}
                </p>
              </div>
              <div class="p-3 recent-dashboard" v-else
                @click="$router.push({ path: 'dashboard-viewer', query: { id: dashboard.id } })"
                v-for="dashboard in recentDashboards" :key="dashboard">
                <p class="p-0 m-0 card-text"><i class="pi pi-desktop"></i>&nbsp;&nbsp;
                  {{ dashboard.name }}
                </p>
                <p class="pl-4 m-0 card-text">
                  <small>{{ dashboard.description }}</small>
                </p>
              </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>
<!----------------------------------------------->
<!--                    SCRIPT                 -->
<!----------------------------------------------->

<script>
import MyShared from "./shared";
export default {
  mixins: [MyShared],
};

</script>

<script setup>
import { useMq } from "vue3-mq";
const mq = useMq();
</script>

<!----------------------------------------------->
<!--                    STYLES                 -->
<!----------------------------------------------->
<style scoped>


.recent-dashboard {
  width: 22em !important;
  display: inline-block !important;
  border: 1px solid rgba(0, 0, 0, .125);
  border-radius: .25rem;
  margin-right: 1em;
  background-color: white;
  cursor: pointer;
  margin-bottom: 1rem;
}
</style>
