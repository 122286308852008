<!----------------------------------------------->
<!--                  TEMPLATE                 -->
<!----------------------------------------------->
<template>
  <div class="aux">
    <!-- Desktop -->
    <div class="row siterow-desktop" v-if="mq.current === 'desktop'">
      <i  v-if="currentUrl != 'Home'" class="pi pi-angle-left pl-2 mb-2" @click="$router.go(-1);" style="font-size:0.8em; cursor: pointer; color: #ec635f; width: auto;"><span class="goback">{{$t('goback')}}</span></i>
      <h6 id="full_route" v-if="currentUrl != 'Home'">
        <a>
          <router-link to="/home" >AIRsiem | </router-link>
          <router-link to="/home" v-if="$route.fullPath == '/home'"> <strong>{{$t('home')}}</strong> </router-link>
          <router-link to="/profile" v-if="$route.fullPath.includes('profile')"> <strong>{{$t('profile')}}</strong> </router-link>
          <router-link to="/source" v-if="$route.fullPath == '/source'"> <strong>{{$t('integrations')}}</strong> </router-link>
          <span v-if="$route.fullPath.includes('source-details')"> <router-link to="/source"> {{$t('integrations')}} | </router-link><strong>{{$route.query.id}}</strong> </span>
          <router-link to="/listener" v-if="$route.fullPath == '/listener'"> <strong >{{$t('listeners')}}</strong> </router-link>
          <router-link to="/log" v-if="$route.fullPath == '/log'"> <strong >{{$t('logs')}}</strong> </router-link>
          <router-link to="/asset" v-if="$route.fullPath == '/asset'"> <strong >{{$t('assets')}}</strong> </router-link>
          <span v-if="$route.fullPath.includes('asset-details')"> <router-link to="/asset"> {{$t('assets')}} | </router-link><strong>{{$route.query.id}}</strong> </span>
          <router-link to="/asset" v-if="$route.fullPath == '/dashboard'"> <strong >{{$t('dashboards')}}</strong> </router-link>
          <span v-if="$route.fullPath.includes('dashboard-creator')"> <router-link to="/dashboard"> {{$t('dashboards')}} | </router-link><strong>{{$t('dashboardCreator')}}</strong> </span>
          <span v-if="$route.fullPath.includes('dashboard-viewer')"> <router-link to="/dashboard"> {{$t('dashboards')}} | </router-link><strong>{{$t('dashboardViewer')}}</strong> </span>
          <router-link to="/asset" v-if="$route.fullPath == '/investigation'"> <strong >{{$t('investigations')}}</strong> </router-link>
          <span v-if="$route.fullPath.includes('investigation-creator')"> <router-link to="/investigation"> {{$t('investigations')}} | </router-link><strong>{{$t('investigationCreator')}}</strong> </span>
          <router-link to="/event" v-if="$route.fullPath == '/event'"> <strong v-if="$route.fullPath.includes('event')">{{$t('events')}}</strong> </router-link>
          <span v-if="$route.fullPath.includes('event-details')"> <router-link to="/event"> {{$t('events')}} | </router-link><strong>{{$route.query.id}}</strong> </span>
          <router-link to="/incident" v-if="$route.fullPath == '/incident'"><strong>{{$t('incidents')}}</strong> </router-link>
          <span v-if="$route.fullPath.includes('incident-details')"> <router-link to="/incident"> {{$t('incidents')}} | </router-link><strong>{{$route.query.id}}</strong> </span>
          <router-link to="/alert" v-if="$route.fullPath == '/alert'"><strong>{{$t('alertConfiguration')}}</strong> </router-link>
          <span v-if="$route.fullPath.includes('alert-details')"> <router-link to="/alert"> {{$t('alertConfiguration')}} | </router-link><strong>{{$route.query.id}}</strong> </span>
          <router-link to="/machine-learning-job" v-if="$route.fullPath == '/machine-learning-job'"><strong>{{$t('machineLearning')}}</strong> </router-link>
          <router-link to="/correlation-rule" v-if="$route.fullPath == '/correlation-rule'"><strong>{{$t('correlation')}}</strong> </router-link>
          <span v-if="$route.fullPath.includes('correlation-rule-details')"> <router-link to="/correlation-rule"> {{$t('correlation')}} | </router-link><strong>{{$route.query.id}}</strong> </span>
          <router-link to="/detection-rule" v-if="$route.fullPath == '/detection-rule'"><strong>{{$t('detection')}}</strong> </router-link>
          <span v-if="$route.fullPath.includes('detection-rule-details')"> <router-link to="/detection-rule"> {{$t('detection')}} | </router-link><strong>{{$route.query.id}}</strong> </span>
          <router-link to="/manage-users" v-if="$route.fullPath == '/manage-users'"><strong>{{$t('manageUsers')}}</strong> </router-link>
          <router-link to="/manage-teams" v-if="$route.fullPath == '/manage-teams'"><strong>{{$t('manageTeams')}}</strong> </router-link>
          <router-link to="/organization" v-if="$route.fullPath == '/organization'"><strong>{{$t('manageOrganizations')}}</strong> </router-link>
          <router-link to="/manage-roles" v-if="$route.fullPath == '/manage-roles'"><strong>{{$t('manageUsersAndRoles')}}</strong> </router-link>
          <router-link to="/permissions" v-if="$route.fullPath == '/permissions'"> <strong>{{$t('permissions')}}</strong> </router-link>
          <router-link to="/documentation" v-if="$route.fullPath == '/documentation'"> <strong>{{$t('documentation')}}</strong> </router-link>
          <router-link to="/task" v-if="$route.fullPath == '/task'"> <strong>{{$t('tasks')}}</strong> </router-link>
          <router-link to="/surveillance" v-if="$route.fullPath == '/surveillance'"> <strong>{{$t('surveillance')}}</strong> </router-link>
        </a>
      </h6>
    </div>
    <!-- Mobile -->
    <div class="row siterow-mobile" v-if="mq.current === 'mobile'">
      <i  v-if="currentUrl != 'Home'" class="pi pi-angle-left pl-2 mb-2" @click="$router.go(-1);" style="font-size:0.8em; cursor: pointer; color: #ec635f;"><span class="goback">{{$t('goback')}}</span></i>
      <h6 id="full_route" v-if="currentUrl != 'Home'">
        <a>
          <router-link to="/home" >AIRsiem | </router-link>
          <router-link to="/home" v-if="$route.fullPath == '/home'"> <strong>{{$t('home')}}</strong> </router-link>
          <router-link to="/profile" v-if="$route.fullPath.includes('profile')"> <strong>{{$t('profile')}}</strong> </router-link>
          <router-link to="/source" v-if="$route.fullPath == '/source'"> <strong>{{$t('integrations')}}</strong> </router-link>
          <span v-if="$route.fullPath.includes('source-details')"> <router-link to="/source"> {{$t('integrations')}} | </router-link><strong>{{$route.query.id}}</strong> </span>
          <router-link to="/listener" v-if="$route.fullPath == '/listener'"> <strong >{{$t('listeners')}}</strong> </router-link>
          <router-link to="/log" v-if="$route.fullPath == '/log'"> <strong >{{$t('logs')}}</strong> </router-link>
          <router-link to="/asset" v-if="$route.fullPath == '/asset'"> <strong >{{$t('assets')}}</strong> </router-link>
          <span v-if="$route.fullPath.includes('asset-details')"> <router-link to="/asset"> {{$t('assets')}} | </router-link><strong>{{$route.query.id}}</strong> </span>
          <router-link to="/asset" v-if="$route.fullPath == '/dashboard'"> <strong >{{$t('dashboards')}}</strong> </router-link>
          <span v-if="$route.fullPath.includes('dashboard-creator')"> <router-link to="/dashboard"> {{$t('dashboards')}} | </router-link><strong>{{$t('dashboardCreator')}}</strong> </span>
          <span v-if="$route.fullPath.includes('dashboard-viewer')"> <router-link to="/dashboard"> {{$t('dashboards')}} | </router-link><strong>{{$t('dashboardViewer')}}</strong> </span>
          <router-link to="/event" v-if="$route.fullPath == '/event'"> <strong v-if="$route.fullPath.includes('event')">{{$t('events')}}</strong> </router-link>
          <span v-if="$route.fullPath.includes('event-details')"> <router-link to="/event"> {{$t('events')}} | </router-link><strong>{{$route.query.id}}</strong> </span>
          <router-link to="/incident" v-if="$route.fullPath == '/incident'"><strong>{{$t('incidents')}}</strong> </router-link>
          <span v-if="$route.fullPath.includes('incident-details')"> <router-link to="/incident"> {{$t('incidents')}} | </router-link><strong>{{$route.query.id}}</strong> </span>
          <router-link to="/alert" v-if="$route.fullPath == '/alert'"><strong>{{$t('alertConfiguration')}}</strong> </router-link>
          <span v-if="$route.fullPath.includes('alert-details')"> <router-link to="/alert"> {{$t('alertConfiguration')}} | </router-link><strong>{{$route.query.id}}</strong> </span>
          <router-link to="/machine-learning-job" v-if="$route.fullPath == '/machine-learning-job'"><strong>{{$t('machineLearning')}}</strong> </router-link>
          <router-link to="/correlation-rule" v-if="$route.fullPath == '/correlation-rule'"><strong>{{$t('correlation')}}</strong> </router-link>
          <span v-if="$route.fullPath.includes('correlation-rule-details')"> <router-link to="/correlation-rule"> {{$t('correlation')}} | </router-link><strong>{{$route.query.id}}</strong> </span>
          <router-link to="/detection-rule" v-if="$route.fullPath == '/detection-rule'"><strong>{{$t('detection')}}</strong> </router-link>
          <span v-if="$route.fullPath.includes('detection-rule-details')"> <router-link to="/detection-rule"> {{$t('detection')}} | </router-link><strong>{{$route.query.id}}</strong> </span>
          <router-link to="/manage-users" v-if="$route.fullPath == '/manage-users'"><strong>{{$t('manageUsers')}}</strong> </router-link>
          <router-link to="/manage-teams" v-if="$route.fullPath == '/manage-teams'"><strong>{{$t('manageTeams')}}</strong> </router-link>
          <router-link to="/organization" v-if="$route.fullPath == '/organization'"><strong>{{$t('manageOrganizations')}}</strong> </router-link>
          <router-link to="/manage-roles" v-if="$route.fullPath == '/manage-roles'"><strong>{{$t('manageUsersAndRoles')}}</strong> </router-link>
          <router-link to="/permissions" v-if="$route.fullPath == '/permissions'"> <strong>{{$t('permissions')}}</strong> </router-link>
          <router-link to="/task" v-if="$route.fullPath == '/task'"> <strong>{{$t('tasks')}}</strong> </router-link>
          <router-link to="/surveillance" v-if="$route.fullPath == '/surveillance'"> <strong>{{$t('surveillance')}}</strong> </router-link>
        </a>
      </h6>
    </div>
    <!-- Tablet -->
    <div class="row siterow-tablet" v-if="mq.current === 'tablet'">
      <i  v-if="currentUrl != 'Home'" class="pi pi-angle-left pl-2 mb-2" @click="$router.go(-1);" style="font-size:0.8em; cursor: pointer; color: #ec635f;"><span class="goback">{{$t('goback')}}</span></i>
      <h6 id="full_route" v-if="currentUrl != 'Home'">
        <a>
          <router-link to="/home" >AIRsiem | </router-link>
          <router-link to="/home" v-if="$route.fullPath == '/home'"> <strong>{{$t('home')}}</strong> </router-link>
          <router-link to="/profile" v-if="$route.fullPath.includes('profile')"> <strong>{{$t('profile')}}</strong> </router-link>
          <router-link to="/source" v-if="$route.fullPath == '/source'"> <strong>{{$t('integrations')}}</strong> </router-link>
          <span v-if="$route.fullPath.includes('source-details')"> <router-link to="/source"> {{$t('integrations')}} | </router-link><strong>{{$route.query.id}}</strong> </span>
          <router-link to="/listener" v-if="$route.fullPath == '/listener'"> <strong >{{$t('listeners')}}</strong> </router-link>
          <router-link to="/log" v-if="$route.fullPath == '/log'"> <strong >{{$t('logs')}}</strong> </router-link>
          <router-link to="/asset" v-if="$route.fullPath == '/asset'"> <strong >{{$t('assets')}}</strong> </router-link>
          <span v-if="$route.fullPath.includes('asset-details')"> <router-link to="/asset"> {{$t('assets')}} | </router-link><strong>{{$route.query.id}}</strong> </span>
          <router-link to="/asset" v-if="$route.fullPath == '/dashboard'"> <strong >{{$t('dashboards')}}</strong> </router-link>
          <span v-if="$route.fullPath.includes('dashboard-creator')"> <router-link to="/dashboard"> {{$t('dashboards')}} | </router-link><strong>{{$t('dashboardCreator')}}</strong> </span>
          <span v-if="$route.fullPath.includes('dashboard-viewer')"> <router-link to="/dashboard"> {{$t('dashboards')}} | </router-link><strong>{{$t('dashboardViewer')}}</strong> </span>
          <router-link to="/event" v-if="$route.fullPath == '/event'"> <strong v-if="$route.fullPath.includes('event')">{{$t('events')}}</strong> </router-link>
          <span v-if="$route.fullPath.includes('event-details')"> <router-link to="/event"> {{$t('events')}} | </router-link><strong>{{$route.query.id}}</strong> </span>
          <router-link to="/incident" v-if="$route.fullPath == '/incident'"><strong>{{$t('incidents')}}</strong> </router-link>
          <span v-if="$route.fullPath.includes('incident-details')"> <router-link to="/incident"> {{$t('incidents')}} | </router-link><strong>{{$route.query.id}}</strong> </span>
          <router-link to="/alert" v-if="$route.fullPath == '/alert'"><strong>{{$t('alertConfiguration')}}</strong> </router-link>
          <span v-if="$route.fullPath.includes('alert-details')"> <router-link to="/alert"> {{$t('alertConfiguration')}} | </router-link><strong>{{$route.query.id}}</strong> </span>
          <router-link to="/machine-learning-job" v-if="$route.fullPath == '/machine-learning-job'"><strong>{{$t('machineLearning')}}</strong> </router-link>
          <router-link to="/correlation-rule" v-if="$route.fullPath == '/correlation-rule'"><strong>{{$t('correlation')}}</strong> </router-link>
          <span v-if="$route.fullPath.includes('correlation-rule-details')"> <router-link to="/correlation-rule"> {{$t('correlation')}} | </router-link><strong>{{$route.query.id}}</strong> </span>
          <router-link to="/detection-rule" v-if="$route.fullPath == '/detection-rule'"><strong>{{$t('detection')}}</strong> </router-link>
          <span v-if="$route.fullPath.includes('detection-rule-details')"> <router-link to="/detection-rule"> {{$t('detection')}} | </router-link><strong>{{$route.query.id}}</strong> </span>
          <router-link to="/manage-users" v-if="$route.fullPath == '/manage-users'"><strong>{{$t('manageUsers')}}</strong> </router-link>
          <router-link to="/manage-teams" v-if="$route.fullPath == '/manage-teams'"><strong>{{$t('manageTeams')}}</strong> </router-link>
          <router-link to="/organization" v-if="$route.fullPath == '/organization'"><strong>{{$t('manageOrganizations')}}</strong> </router-link>
          <router-link to="/manage-roles" v-if="$route.fullPath == '/manage-roles'"><strong>{{$t('manageUsersAndRoles')}}</strong> </router-link>
          <router-link to="/permissions" v-if="$route.fullPath == '/permissions'"> <strong>{{$t('permissions')}}</strong> </router-link>
          <router-link to="/task" v-if="$route.fullPath == '/task'"> <strong>{{$t('tasks')}}</strong> </router-link>
          <router-link to="/surveillance" v-if="$route.fullPath == '/surveillance'"> <strong>{{$t('surveillance')}}</strong> </router-link>
        </a>
      </h6>
    </div>
  </div>
</template>
  
  <!----------------------------------------------->
  <!--                    SCRIPT                 -->
  <!----------------------------------------------->
<script>
import MyShared from "./sharedSiteBar";

export default {
  mixins: [MyShared],
};
</script>

<script setup>
import { useMq } from "vue3-mq";
const mq = useMq();
</script>
  
  <!----------------------------------------------->
  <!--                    STYLES                 -->
  <!----------------------------------------------->
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

h6 {
  font-family: 'Open Sans', sans-serif !important;
  font-weight: 400 !important;
}
.goback {
  font-family: 'Open Sans', sans-serif !important;
  font-size: 1.1em;
}
.goback:hover {
  text-decoration: underline;
}

a {
  text-decoration: none;
  color: black !important;
  font-size: 1em;
}
a:hover{
  color: #ec635f;
}

.siterow-mobile {
  width: 100%;
  margin-top: 2em;
  padding-left: 2%;
  padding-top: 80px;
}

.siterow-desktop {
  width: 100%;
  margin-top: 2em;
  padding-left: 84px;
  padding-top: 50px;
  margin-bottom: 0.5em;
}

.siterow-tablet {
  width: 100%;
  margin-top: 2em;
  padding-top: 4em;
  margin-left: 272.5px;
}

h2 {
  color: black;
  font-weight: 600;
}

h6 {
  color: black;
  font-weight: 600;
  font-size: 1em;
}
</style>
  
  
  