import Sitebar from "../../components/Partials/Sitebar/SiteBar.vue"
import Helper from "../../components/Partials/Helper/Helper.vue"
import Modal from "../../components/Partials/Modals/Modal.vue"
import ConfirmDialog from "primevue/confirmdialog";
import { ref } from 'vue';
import { useUserStore } from '../../store/store.js'
import * as $api from "../../api/api";

var MyShared = {
    /* Name */
    name: "Home",
    /* Data */
    setup() {

    },
    data() {
        return {
            user: {
                "permissions": []
            },
            search: '',
            security: false,
            database: false,
            os: false,
            network: false,
            web: false,
            azure: false,
            cloud: false,
            toggleIntakeConfig: false,
            new_data_source_name: '',
            new_data_source_description: '',
            new_intake_conf_schema_name: '',
            new_schema_params: {},
            new_schema_params_array: [],
            selected: [],
            selectedType: null,
            types: [
                { name: this.$t('integer'), code: 'int' },
                { name: this.$t('string'), code: 'string' },
                { name: this.$t('boolean'), code: 'boolean' },
                { name: this.$t('float'), code: 'float' },
            ],
            selectedIntakeMethods: [],
            selectedIntakeMethodsIds: [],
            image: '',
            sources: [],
            selectedIntakeMethods: [],
            intake_methods: [],
            newPicture: null,
            selectedCategory: null,
            selectedCategories: [],
            categories: [],
            createNewIntegrationStep: 0,
            createNewIntegrationStepTitle: this.$t('createNewIntegration'),
        }
    },
    /* Components */
    components: {
        Sitebar,
        Helper,
        Modal,
        ConfirmDialog
    },
    /* Props */
    props: {},
    /* Methods */
    methods: {
        /* ====================================== Picture ====================================== */
        onAdvancedUpload(event) {
            let files = event.files[0]
            console.log("FILES: ", files);

            let data = new FormData()
            data.append('in_file', files)
            console.log("DATA: ", data);

            this.newPicture = data

            //this.updatePicture(data)
        },
        updatePicture(id, result) {
            console.log("ID: ", id);
            console.log("RESULT: ", result);
            $api.newSourceLogo(id, result).then(
                response => {
                    $api.getAllIntakeMethods(false).then((response) => {
                        this.selectedIntakeMethods = response;
                        console.log("INTAKE METHODS: ", response);
                    }).catch((err) => {
                        console.log(`[-] Error (getAllIntakeMethods): ${err}`);
                    });
                    this.$toast.add({ severity: 'success', summary: this.$t('success'), detail: this.$t('pictureAdded'), life: 3000 });
                }
            ).catch((err) => {
                console.log(`[-] Error (updateMe): ${err}`);
            })
        },
        /* ==================================================================================== */
        addOrRemove(name) {
            if (this.selected.includes(name)) {
                this.selected.splice(this.selected.indexOf(name), 1)
            } else {
                this.selected.push(name)
            }
            console.log(this.selected);
        },
        addNewIntegration(name, desc) {
            console.log("SELECTED INTAKES IDS", this.selectedIntakeMethodsIds);
            console.log("SELECTED CATEGORY", this.selectedCategory);
            $api.createSource({ "name": name, "description": desc, "intake_methods": this.selectedIntakeMethodsIds, "category": (this.selectedCategory.name) ? this.selectedCategory.name : this.selectedCategory }).then(response => {
                if (this.newPicture != null)
                    this.updatePicture(response.id, this.newPicture)
                $api.getAllSources(false).then((response) => {
                    this.sources = response;
                    this.$toast.add({ severity: 'success', summary: this.$t('success'), detail: this.$t('integrationCreated'), life: 3000 });
                    this.$refs.newIntegration.closeModal();
                    // refresh the page
                    this.$router.go();
                }).catch((err) => {
                    console.log(`[-] Error (getAllSources): ${err}`);
                });
            }).catch(error => {
                this.$toast.add({ severity: 'error', summary: this.$t('error'), detail: this.$t('integrationNotCreated'), life: 3000 });
            });
        },
        addNewIntakeMethod(name, desc, nifi) {
            console.log(this.new_schema_params);
            if (Object.keys(this.new_schema_params).length === 0 && this.new_schema_params.constructor === Object) {
                this.show_params_error = true;
                return;
            }
            $api.createIntakeMethod({
                "description": desc,
                "name": name,
                "nifi_listener": nifi,
                "configuration_schema": this.new_schema_params
            }).then((response) => {

                $api.getAllIntakeMethods(false).then((response) => {
                    this.intake_methods = response;
                    this.selectedIntakeMethods = response;
                    console.log("INTAKE METHODS: ", response);
                }).catch((err) => {
                    console.log(`[-] Error (getAllIntakeMethods): ${err}`);
                });

                //toast 
                this.$toast.add({ severity: 'success', summary: this.$t('confirmed'), detail: this.$t('intakeMethodCreated'), life: 3000 });
                this.$refs.newIntakeMethod.closeModal();

            }).catch((error) => {
                console.log(error);
            });
        },
        addNewParam(name, desc, type) {
            // check if the name is already in the object
            if (this.new_schema_params.hasOwnProperty(name)) {
                // if it is, delete it
                delete this.new_schema_params[name];
            }

            // use the name as key
            this.new_schema_params[name] = {
                "type": type.code,
                "description": desc
            };
            //console.log(this.new_schema_params);

            // get new_schema_params keys
            let keys = Object.keys(this.new_schema_params);

            // loop through the keys
            for (let i = 0; i < keys.length; i++) {
                // use the key for the name
                let aux = {
                        "name": keys[i],
                        "description": this.new_schema_params[keys[i]].description,
                        "type": this.new_schema_params[keys[i]].type
                    }
                    // add the object to the array if it doesn't exist one with the same name
                if (!this.new_schema_params_array.some(e => e.name === aux.name))
                    this.new_schema_params_array.push(aux);
                //console.log(this.new_schema_params_array);
            }
        },
        deleteParamFromIntakeMethodConf(name) {
            console.log("deleteParamFromIntakeMethodConf: ", name);
            // delete the param from the object
            delete this.new_schema_params[name];
            // find name in array and delete it
            this.new_schema_params_array = this.new_schema_params_array.filter(function(obj) {
                return obj.name !== name;
            });
        },
        updateSelectedIntakeMethods(id) {
            // find name in selectedIntakeMethods and set active to !active
            for (let i = 0; i < this.selectedIntakeMethods.length; i++) {
                if (this.selectedIntakeMethods[i].id === id) {
                    this.selectedIntakeMethods[i].active = !this.selectedIntakeMethods[i].active;
                    if (this.selectedIntakeMethods[i].active){
                        this.selectedIntakeMethodsIds.push(this.selectedIntakeMethods[i].id)
                        console.log("SELECTED INTAKE METHODS IDS", this.selectedIntakeMethodsIds);
                    }
                    else
                        this.selectedIntakeMethodsIds.splice(this.selectedIntakeMethodsIds.indexOf(this.selectedIntakeMethods[i].id), 1)
                }
            }
        },
        setSelectedIntakeMethods() {
            this.selectedIntakeMethods = this.intake_methods
                // loop through the intake methods
            for (let i = 0; i < this.intake_methods.length; i++) {
                this.selectedIntakeMethods[i].active = false
            }
        },
        checkChar(letter) {
            // check if letter is between a-i or A-I
            if ((letter >= 'a' && letter <= 'i') || (letter >= 'A' && letter <= 'I')) {
                return 'default-img-v1';
            }
            // check if letter is between j-r or J-R
            if ((letter >= 'j' && letter <= 'r') || (letter >= 'J' && letter <= 'R')) {
                return 'default-img-v2';
            }
            // check if letter is between s-z or S-Z
            if ((letter >= 's' && letter <= 'z') || (letter >= 'S' && letter <= 'Z')) {
                return 'default-img-v3';
            }
        },
        getCategories() {
            // loop this.sources and get all categories
            for (let i = 0; i < this.sources.length; i++) {
                // check if category is already in the array
                if (!this.categories.some(e => e.name === this.sources[i].category)) {
                    // if it's not, add it
                    this.categories.push({
                        "name": this.sources[i].category,
                        "active": false
                    });
                }
            }
        },
        updateSelected() {
            this.selected = [];
            // loop through selectedCategories
            for (let i = 0; i < this.selectedCategories.length; i++) {
                this.selected.push(this.selectedCategories[i].name);
            }


        },
        getSourceLogo(logo) {
            return process.env.VUE_APP_BASE_URL + '/' + logo;
        },
        stepFront() {
            this.createNewIntegrationStep++;
            if (this.createNewIntegrationStep === 0) {
                this.createNewIntegrationStepTitle = '1. ' + this.$t('createNewIntegration');
            } else if (this.createNewIntegrationStep === 1) {
                this.createNewIntegrationStepTitle = '2. ' + this.$t('selectIntakeMethods');
            }
        },
        stepBack() {
            this.createNewIntegrationStep--;
            if (this.createNewIntegrationStep === 0) {
                this.createNewIntegrationStepTitle = '1. ' + this.$t('createNewIntegration');
            } else if (this.createNewIntegrationStep === 1) {
                this.createNewIntegrationStepTitle = '2. ' + this.$t('selectIntakeMethods');
            }
        }

    },
    async mounted() {
        const userStore = useUserStore();
        const userObject = await userStore.getUser();
        this.user = userObject;

        console.log("GET USER:", userObject);
        // get sources from API
        if (process.env.VUE_APP_ENV === 'development') {
            //await (this.data = require('./events.json'));
            //await (this.data_backup = require('./events.json'));
        } else if (process.env.VUE_APP_ENV === 'staging' || process.env.VUE_APP_ENV === 'production') {
            this.loading_table = true;
            $api.getAllSources(false).then((response) => {
                this.sources = response;
                this.getCategories();
                console.log("SOURCES: ", this.sources);
                $api.getAllIntakeMethods(false).then((response) => {
                    this.selectedIntakeMethods = response;
                    console.log("INTAKE METHODS: ", response);
                }).catch((err) => {
                    console.log(`[-] Error (getAllIntakeMethods): ${err}`);
                });
            }).catch((err) => {
                console.log(`[-] Error (getAllSources): ${err}`);
            });
        } else {
            console.log("ERROR: NO ENVIRONMENT SELECTED");
        }

    }
};
export default MyShared;