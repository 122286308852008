import $axios from '../utils';

export function getAllAssets(full = true, sdate = '1975-01-01T00:00:00', edate = '3000-01-01T00:00:00', offset = 0, limit = 10000) {
    return $axios.get(`asset/?full=${full}&offset=${offset}&limit=${limit}&sdate=${sdate}&edate=${edate}`)
        .then(response => response.data)
}
export function getAssetsBy(key, value, full, offset, limit) {
    return $axios.get(`asset/by?key${key}&value=${value}&full=${full}&offset=${offset}&limit=${limit}`)
        .then(response => response.data)
}
export function getOneAsset(id, full) {
    return $axios.get(`asset/${id}?full=${full}`)
        .then(response => response.data)
}
export function createAsset(data) {
    return $axios.post('asset/', data)
        .then(response => response.data)
}
export function updateAsset(id, data) {
    return $axios.put(`asset/${id}`, data)
        .then(response => response.data)
}
export function deleteAllAssets() {
    return $axios.delete('asset/')
        .then(response => response.data)
}
export function deleteAsset(id) {
    return $axios.delete(`asset/${id}`)
        .then(response => response.data)
}

export function getAssetsByIncidents(limit = 8) {
    return $axios.get(`asset/top-incidents?limit=${limit}`)
        .then(response => response.data)
}

export function getAssetRecording(id, start_datetime, end_datetime = null) {
    if (end_datetime == null) {
        return $axios.get(`asset/video_surveillance/${id}?start_datetime=${start_datetime}`)
            .then(response => response.data)
    }
    return $axios.get(`asset/video_surveillance/${id}?start_datetime=${start_datetime}&end_datetime=${end_datetime}`)
        .then(response => response.data)
}