<!----------------------------------------------->
<!--                  TEMPLATE                 -->
<!----------------------------------------------->
<template>
  <MobileDetection v-if="  mq.current  === 'mobile'"></MobileDetection>
  <TabletDetection v-if="  mq.current  === 'tablet'"></TabletDetection>
  <DesktopDetection v-if=" mq.current  === 'desktop'"></DesktopDetection>
</template>

<!----------------------------------------------->
<!--                    SCRIPT                 -->
<!----------------------------------------------->
<script>
import MobileDetection from "../components/Detection/MobileDetection";
import TabletDetection from "../components/Detection/TabletDetection";
import DesktopDetection from "../components/Detection/DesktopDetection";

export default {
  /* Name */
  name: "Detection",
  inject: ["mq"],
  /* Components */
  components: {
    MobileDetection,
    TabletDetection,
    DesktopDetection,
  },
};
</script>

<script setup>
import { useMq } from "vue3-mq";
const mq = useMq();
</script>

<!----------------------------------------------->
<!--                    STYLES                 -->
<!----------------------------------------------->
<style scoped >
</style>
