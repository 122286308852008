<!----------------------------------------------->
<!--                  TEMPLATE                 -->
<!----------------------------------------------->
<template>
  <div class="aux">
    <Sitebar></Sitebar>
    <div class="maincontainer" >
      <ConfirmDialog></ConfirmDialog>
      <!-- ==================================================================================================================================================== -->
      <!-- =================================================================== Top Buttons ==================================================================== -->
      <!-- ==================================================================================================================================================== -->
      <div class="row">
        <div class="col-6 p-0"></div>
        <div class="col-6 top-config">
          <i class="pi pi-replay top-config-icon" @click="$router.go()"><span class="top-config-label">&nbsp;{{
            $t('refresh')
          }}</span></i>
          <div class="dropdown">
            <i class="pi pi-clock top-config-icon dropdown-toggle" data-toggle="dropdown"><span
                class="top-config-label">&nbsp;{{ lastHours }}</span></i>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
              <a class="dropdown-item" v-for="hour in hours" :key="hour" @click="setLastHours(hour)">
                {{ hour }}
              </a>
            </div>
          </div>
          <div class="dropdown" v-if="showActions">
            <i class="pi pi-cog top-config-icon dropdown-toggle" data-toggle="dropdown"><span
                class="top-config-label">&nbsp;{{ $t('actions') }}</span></i>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown" style="width:max-content">
              <a class="dropdown-item" @click="editMode = false" v-if="editMode">
                <p><i class="pi pi-times"></i>&nbsp;{{ $t('closeEditMode') }}</p>
              </a>
              <section v-for="(action, index) in actions" :key="action" @click="action.callback()">
                <a class="dropdown-item" v-if="action.permit">
                  <p><i :class="action.icon"></i>&nbsp;{{ action.label }}</p>
                </a>
              </section>
            </div>
          </div>
        </div>
      </div>
      <!-- ==================================================================================================================================================== -->
      <!-- ======================================================================= Top ======================================================================= -->
      <!-- ==================================================================================================================================================== -->
      <div class="row" style="width:100%">
        <div class="col-12 resume-row">
          <Card>
            <template #content>
              <div class="row pl-4">
                <div class="col-2 pt-0" v-if="!loading_table">
                  <i class="fa-solid fa-cog top-tab-icon"></i>
                  <span class="top-tab-text">
                    {{ data.length }}
                  </span>
                  <p class="top-tab-subtext mb-0">{{ $t('alertConfigurationTotal') }} </p>
                </div>
                <div class="col-2" v-else><ContentLoader></ContentLoader></div>
                <div class="col-2 pt-0" v-if="!loading_table">
                  <i class="fa-solid fa-spinner top-tab-icon"></i>
                  <span class="top-tab-text">
                    {{ activeAlerts }}
                  </span>
                  <p class="top-tab-subtext mb-0">{{ $t('activeConfigurations') }} </p>
                </div>
                <div class="col-2" v-else><ContentLoader></ContentLoader></div>
                <div class="col-2 pt-0" v-if="!loading_table">
                  <i class="fa-solid fa-bell top-tab-icon"></i>
                  <span class="top-tab-text">
                    {{ alertMethods.length }}
                  </span>
                  <p class="top-tab-subtext mb-0">{{ $t('avaliableAlertMethods') }} </p>
                </div>
                <div class="col-2" v-else><ContentLoader></ContentLoader></div>
              </div>
            </template>
          </Card>
        </div>
        <!-- ==================================================================================================================================================== -->
        <!-- ===================================================================== EditMenu ===================================================================== -->
        <!-- ==================================================================================================================================================== -->
        <div v-if="editMode" class="col-12 resume-row">
            <Card>
              <template #content>
                <div class="row pl-2">
                  <p><strong>{{$t('editOptions')}}</strong></p>
                  <div class="col-12">
                    <i class="pi pi-times mr-3" style="position: absolute; top:0; right:0; margin-top: -20px; cursor: pointer;" @click="editMode = false"></i>
                    <div class="" style="display: block ruby;">
                      <Button class="btn-custom-save" icon="pi pi-trash" :label="$t('deleteSelected')" @click="deleteData()"></Button>
                      <section class="dropdown ml-2" style="margin-top: 8px !important; margin-bottom: 8px !important;">
                          <span class="dropdown-toggle btn-custom-save" data-toggle="dropdown" style="padding-top: 0px !important; padding-bottom: 0 !important; border-radius: 3px;">
                            <span class="" style="font-weight: normal !important; font-size: small !important;"><i class="pi pi-pencil" style="font-size:small"></i>&nbsp;{{$t('changeSelectedCriticality')}}</span>
                          </span>
                          <div class="dropdown-menu" aria-labelledby="navbarDropdown" style="z-index: 999;">
                            <a class="dropdown-item" v-for="criticality in criticalities" :key="hour" @click="setCriticalityForSelectedItems(criticality.value)">
                              {{ criticality.label }}
                            </a>
                          </div>
                      </section>
                    </div> 
                  </div>
                </div>
              </template>
            </Card>
          </div>
        <!-- ==================================================================================================================================================== -->
        <!-- ====================================================================== Table ======================================================================= -->
        <!-- ==================================================================================================================================================== -->
        <div :class="tab_position ? 'col-8' : 'col-12 mb-0'" v-if="viewmode == 0">
          <div class="pad-helper main-table pt-2 pr-2 pl-2 m-0 pb-3" style="height:100%;">
            <span class="p-input-icon-left" style="width:100%;">
              <InputText v-model="filters['global'].value" :placeholder="$t('searchByAsset')" class="search-input mb-2">
              </InputText>
            </span>
           <div class="loader text-center mt-2 mt-2" style="width:100%;" v-if="loading_table">
            <ContentLoader width="100%"></ContentLoader>
            </div>
            <DataTable v-else :value="data" :paginator="true" class="p-datatable-customers" :rows="tab_position ? 15 : 6"
              :row-hover="true" v-model:selection="selectedData" dataKey="id" v-model:filters="filters"
              filter-display="menu" :loading="loading_table" :selectionMode="(!editMode)?'single':''"
              paginator-template="PrevPageLink PageLinks NextPageLink CurrentPageReport"
              :rows-per-page-options="[10, 25, 50]"
              current-page-report-template="Showing {first} to {last} of {totalRecords} entries"
              :global-filter-fields="['id', 'name', 'os', 'hostname', 'type', 'criticality', 'last_date', 'risk']"
              responsive-layout="scroll" @row-click="setSelectedData($event.data)">
              <template #empty>
                {{$t('noDataFound')}}
              </template>
              <template #loading>
               {{$t('loadingData')}}
              </template>
              <Column selection-mode="multiple" v-if="editMode"></Column>
              <Column field="name" :header="$t('name')" sortable>
                <template #body="{ data }">
                  <span @click="$router.push({ path: 'alert-details', query: { id: data.id } })" class="row-name-link">
                    {{ data.name }}
                  </span>
                </template>
              </Column>
              <Column field="severity" :header="$t('minimumSeverity')" sortable>
                <template #body="{ data }">
                  <span v-if="data.severity == 0"><i class="pi pi-circle-fill  criticality-filter-Info"
                      style="font-size: 0.7em;"></i>&nbsp;{{ $t('informational') }}</span>
                  <span v-if="data.severity == 1"><i class="pi pi-circle-fill  criticality-filter-Low"
                      style="font-size: 0.7em;"></i>&nbsp;{{ $t('low') }}</span>
                  <span v-if="data.severity == 2"><i class="pi pi-circle-fill  criticality-filter-Medium"
                      style="font-size: 0.7em;"></i>&nbsp;{{ $t('medium') }}</span>
                  <span v-if="data.severity == 3"><i class="pi pi-circle-fill  criticality-filter-High"
                      style="font-size: 0.7em;"></i>&nbsp;{{ $t('high') }}</span>
                  <span v-if="data.severity == 4"><i class="pi pi-circle-fill  criticality-filter-Critical"
                      style="font-size: 0.7em;"></i>&nbsp;{{ $t('critical') }}</span>
                </template>
              </Column>
              <Column field="is_active" :header="$t('status')" sortable>
                <template #body="{ data }">
                  <span v-if="data.is_active"><i class="fa-solid fa-circle-exclamation" style="color:#6dc193"></i>&nbsp;{{
                    $t('activated')
                }}</span>
                <span v-if="!data.is_active"><i class="fa-solid fa-circle-exclamation" style="color:#ef6154"></i>&nbsp;{{
                    $t('deactivated')
                }}</span>
                </template>
              </Column>
            </DataTable>
          </div>
        </div>

        <!-- ==================================================================================================================================================== -->
        <!-- ======================================================================= Tabs ======================================================================= -->
        <!-- ==================================================================================================================================================== -->

        <div :class="tab_position ? 'col-4 pl-0' : 'col-12'" :style="tab_position ? 'height:auto;' : 'height:35vh;'"
          v-if="viewmode == 0">
          <div class="pad-helper main-table pt-2 pr-2 pl-2 m-0" style="height:100%;">
            <div class="row">
              <div class="change-pos-div">
                <img v-if="tab_position" style="float:right; cursor:pointer; height:1.25em"
                  @click="tab_position = !tab_position" :src="require('@/assets/icon-bottom.svg')">
                <img v-if="!tab_position" style="float:right; cursor:pointer; height:1.25em"
                  @click="tab_position = !tab_position" :src="require('@/assets/icon-right.svg')">
                <!-- 
                  <i v-if="tab_position" class="fa-solid fa-turn-down icon-flipped" style="float:right; cursor:pointer;"
                    @click="tab_position = !tab_position"></i>
                  <i v-if="!tab_position" class="fa-solid fa-turn-up" style="float:right; cursor:pointer;"
                    @click="tab_position = !tab_position"></i>
                  -->
              </div>
            </div>
            <div class="loader text-center mt-4" style="width:100%;" v-if="loading_table">
              <ContentLoader width="100%"></ContentLoader>
            </div>
            <div v-else class="row">
              <TabView v-if="details">
                <!-- ====================================================================== Details ============================================================================== -->
                <TabPanel :header="$t('details')">
                  <div class="loader text-center mt-2 mt-2" style="width:100%;" v-if="loading_details">
                    <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                    <p>{{ $t('loading') }}</p>
                  </div>
                  <ScrollPanel :style="tab_position ? 'width: 100%; height:auto' : 'width: 100%; height:15em'"
                    class="custom" v-else>
                    <div class="sub-scroll pl-3 pr-3 pb-4" :style="tab_position ? 'height:auto;' : 'height:25em;'">
                      <div class="row details-top">
                        <i class="fa-solid fa-yaveremos detail-icon"><span class="detail-name">{{
                          details.name
                        }}</span></i>
                        <p class="mb-0 pl-0"
                          @click="$router.push({ path: 'alert-details', query: { id: details.id } })">
                          <span class="id-prefix" style="text-decoration:none;">Alert ID:</span> <span class="details-id">
                            {{ details.id }}
                          </span>
                        </p>
                      </div>
                      <div class="row details-data">
                        <div class="col-6 p-0 dropdown">
                          <Card data-toggle="dropdown">
                            <template #content>
                              <i v-if="details.is_active" class="fa-solid fa-circle-check subdetail-icon"
                                style="color:#6dc193">
                                <p class="subdetail-name dropdown-toggle" v-if="details.is_active">&nbsp;{{
                                  $t('activated')
                                }}</p>
                                <p class="subdetail-tag">&nbsp;{{ $t('status') }}</p>
                              </i>
                              <i v-else class="fa-solid fa-circle-xmark subdetail-icon dropdown" style="color:#ef6154">
                                <p class="subdetail-name dropdown-toggle" v-if="!details.is_active">&nbsp;{{
                                  $t('deactivated')
                                }}</p>
                                <p class="subdetail-tag">&nbsp;{{ $t('status') }}</p>
                              </i>
                            </template>
                          </Card>
                          <div class="dropdown-menu">
                            <a class="dropdown-item" v-for="status in isActiveStatuses" :key="status"
                              @click="updateIsActive(details.id, status.value)">
                              {{ status.label }}
                            </a>
                          </div>
                        </div>
                        <div class="col-6 p-0 dropdown">
                          <Card data-toggle="dropdown">
                            <template #content>
                              <i v-if="details.severity == 1" class="fa-solid fa-shield-halved subdetail-icon"
                                style="color:#6dc193">
                                <p class="subdetail-name dropdown-toggle" v-if="details.severity == 1">&nbsp;{{
                                  $t('low')
                                }}</p>
                                <p class="subdetail-tag">&nbsp;{{ $t('minimumSeverity') }}</p>
                              </i>
                              <i v-if="details.severity == 2" class="fa-solid fa-shield-halved subdetail-icon dropdown"
                                style="color:#f7b34c">
                                <p class="subdetail-name dropdown-toggle" v-if="details.severity == 2">&nbsp;{{
                                  $t('medium')
                                }}</p>
                                <p class="subdetail-tag">&nbsp;{{ $t('minimumSeverity') }}</p>
                              </i>
                              <i v-if="details.severity == 3" class="fa-solid fa-shield-halved subdetail-icon dropdown"
                                style="color:#ef6154">
                                <p class="subdetail-name dropdown-toggle" v-if="details.severity == 3">&nbsp;{{
                                  $t('high')
                                }}</p>
                                <p class="subdetail-tag">&nbsp;{{ $t('minimumSeverity') }}</p>
                              </i>
                              <i v-if="details.severity == 4" class="fa-solid fa-shield-halved subdetail-icon dropdown"
                                style="color:#b83c32">
                                <p class="subdetail-name dropdown-toggle" v-if="details.severity == 4">&nbsp;{{
                                  $t('critical')
                                }}
                                </p>
                                <p class="subdetail-tag">&nbsp;{{ $t('minimumSeverity') }}</p>
                              </i>
                              <i v-if="details.severity == 0" class="fa-solid fa-shield-halved subdetail-icon dropdown"
                                style="color:#aeb5c1">
                                <p class="subdetail-name dropdown-toggle" v-if="details.severity == 0">&nbsp;{{
                                  $t('informational')
                                }}
                                </p>
                                <p class="subdetail-tag">&nbsp;{{ $t('minimumSeverity') }}</p>
                              </i>
                            </template>
                          </Card>
                          <div class="dropdown-menu">
                            <a class="dropdown-item" v-for="severity in criticalities" :key="severity"
                              @click="updateSeverity(details.id, severity.value)">
                              {{ severity.label }}
                            </a>
                          </div>
                        </div>
                      </div>
                      <div class="row details-data">
                        <div :class="tab_position ? 'col-12 pr-0 pl-0' : 'col-6 pr-4'">
                          <div class="row">
                            <p class="mt-0 mb-0 details-title"><strong>{{ $t('description') }}</strong></p>
                            <p class="">{{ details.description }}</p>
                          </div>
                          <div class="row">
                            <p class="mb-0 mt-2 details-title"><strong>{{ $t('filters') }}</strong></p>
                            <div class="tag-cont">
                              <p class="details-tag" v-for="tag in details.filters" :key="tag"
                                style="display:inline-block;">{{ tag }}&nbsp;</p>
                            </div>
                          </div>
                          <div class="row">
                            <p class="mb-0 mt-2 details-title"><strong>{{ $t('methods') }}</strong></p>
                            <div class="tag-cont">
                              <p class="mb-0 ml-1" v-for="alert in details.alert_methods" :key="alert"
                                style="display:inline-block;">
                                <i class="pi pi-slack" v-if="alert.method == 'slack'" style="font-size: small;"></i>
                                <i class="pi pi-mobile" v-if="alert.method == 'sms'" style="font-size: small;"></i>
                                <i class="pi pi-envelope" v-if="alert.method == 'email'" style="font-size: small;"></i>
                                <span class="ml-1 mr-3">{{ alert.name }}</span>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div :class="tab_position ? 'col-12 pr-0 pl-0 pt-0 pb-4' : 'col-6 pr-4'">
                        </div>
                      </div>
                    </div>
                  </ScrollPanel>
                </TabPanel>
                <!-- ====================================================================== Tab correlation rules =========================================================================== -->
                <TabPanel :header="$t('correlationRules')">
                  <div class="loader text-center mt-2" style="width:100%;" v-if="loading_details">
                    <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                    <p>{{ $t('loading') }}</p>
                  </div>
                  <ScrollPanel :style="tab_position ? 'width: 100%; height:32.5em' : 'width: 100%; height:15em'"
                    class="custom" v-else>
                    <div class="row pb-2 pl-1 pr-1 pt-1">
                    <h6 v-if="!details.correlation_rules" class="text-center mt-4" style="width:100%;">{{ $t('noDataAvailable') }}
                    </h6>
                    <div v-else class="aux pt-2" :style="tab_position ? 'height:55vh;' : 'height:35vh;'">
                        <OpenGroup v-for="(correlation_rule, index) in details.correlation_rules" :key="correlation_rule"
                          :groupName="correlation_rule.title" :openFirst="index == 0 ? true : false">
                          <template #body>
                            <p class="p-0 m-0 group-title"><strong>{{ $t('ID') }}</strong></p>
                            <p class="p-0 m-0 group-data row-name-link" @click="$router.push({ path: 'correlation-rule-details', query: { id: correlation_rule.id } })">{{ correlation_rule.id }}</p>
                            <p class="p-0 m-0 mt-2 group-title "><strong>{{ $t('description') }}</strong></p>
                            <p class="p-0 m-0 group-data">{{ correlation_rule.description }}</p>
                            <p class="p-0 m-0 mt-2 group-title "><strong>{{ $t('severity') }}</strong></p>
                            <p v-if="correlation_rule.severity == 0" class="p-0 m-0 group-data">
                              <i class="pi pi-circle-fill  criticality-filter-Info"
                                style="color:rgb(109, 122, 193); font-size: 0.8em;"></i>
                              <span>&nbsp;{{ $t('informational') }}</span>
                            </p>
                            <p v-if="correlation_rule.severity == 1" class="p-0 m-0 group-data">
                              <i class="pi pi-circle-fill  criticality-filter-Low"
                                style="color:#6dc193; font-size: 0.8em;"></i>
                              <span>&nbsp;{{ $t('low') }}</span>
                            </p>
                            <p v-if="correlation_rule.severity == 2" class="p-0 m-0 group-data">
                              <i class="pi pi-circle-fill  criticality-filter-Medium"
                                style="color:#f7b34c; font-size: 0.8em;"></i>
                              <span>&nbsp;{{ $t('medium') }}</span>
                            </p>
                            <p v-if="correlation_rule.severity == 3" class="p-0 m-0 group-data">
                              <i class="pi pi-circle-fill  criticality-filter-High"
                                style="color:#ef6154; font-size: 0.8em;"></i>
                              <span>&nbsp;{{ $t('high') }}</span>
                            </p>
                            <p v-if="correlation_rule.severity == 4" class="p-0 m-0 group-data">
                              <i class="pi pi-circle-fill  criticality-filter-Critical"
                                style="color:#b83c32; font-size: 0.8em;"></i>
                              <span>&nbsp;{{ $t('critical') }}</span>
                            </p>
                            <p class="p-0 m-0 mt-2 group-title "><strong>{{ $t('status') }}</strong></p>
                            <p v-if="correlation_rule.is_active" class="p-0 m-0 group-data">
                              <i class="pi pi-circle-fill  criticality-filter-Low"
                                style="color:#6dc193; font-size: 0.8em;"></i>
                              <span>&nbsp;{{ $t('active') }}</span>
                            </p>
                            <p v-if="!correlation_rule.is_active" class="p-0 m-0 group-data">
                              <i class="pi pi-circle-fill  criticality-filter-High"
                                style="color:#ef6154; font-size: 0.8em;"></i>
                              <span>&nbsp;{{ $t('deactivated') }}</span>
                            </p>
                          </template>
                        </OpenGroup>
                      </div>
                    </div>
                  </ScrollPanel>
                </TabPanel>
                <!-- ====================================================================== Tab detection rules =========================================================================== -->
                <TabPanel :header="$t('detectionRules')">
                  <div class="loader text-center mt-2" style="width:100%;" v-if="loading_details">
                    <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                    <p>{{ $t('loading') }}</p>
                  </div>
                  <ScrollPanel :style="tab_position ? 'width: 100%; height:32.5em' : 'width: 100%; height:15em'"
                    class="custom" v-else>
                    <div class="row pb-2 pl-1 pr-1 pt-1">
                    <h6 v-if="!details.detection_rules" class="text-center mt-4" style="width:100%;">{{ $t('noDataAvailable') }}
                    </h6>
                    <div v-else class="aux pt-2" :style="tab_position ? 'height:55vh;' : 'height:35vh;'">
                        <OpenGroup v-for="(detection_rule, index) in details.detection_rules" :key="detection_rule"
                          :groupName="detection_rule.title" :openFirst="index == 0 ? true : false">
                          <template #body>
                            <p class="p-0 m-0 group-title"><strong>{{ $t('ID') }}</strong></p>
                            <p class="p-0 m-0 group-data row-name-link" @click="$router.push({ path: 'detection-rule-details', query: { id: detection_rule.id } })">{{ detection_rule.id }}</p>
                            <p class="p-0 m-0 mt-2 group-title "><strong>{{ $t('description') }}</strong></p>
                            <p class="p-0 m-0 group-data">{{ detection_rule.description }}</p>
                            <p class="p-0 m-0 mt-2 group-title "><strong>{{ $t('severity') }}</strong></p>
                            <p v-if="detection_rule.severity == 0" class="p-0 m-0 group-data">
                              <i class="pi pi-circle-fill  criticality-filter-Info"
                                style="color:rgb(109, 122, 193); font-size: 0.8em;"></i>
                              <span>&nbsp;{{ $t('informational') }}</span>
                            </p>
                            <p v-if="detection_rule.severity == 1" class="p-0 m-0 group-data">
                              <i class="pi pi-circle-fill  criticality-filter-Low"
                                style="color:#6dc193; font-size: 0.8em;"></i>
                              <span>&nbsp;{{ $t('low') }}</span>
                            </p>
                            <p v-if="detection_rule.severity == 2" class="p-0 m-0 group-data">
                              <i class="pi pi-circle-fill  criticality-filter-Medium"
                                style="color:#f7b34c; font-size: 0.8em;"></i>
                              <span>&nbsp;{{ $t('medium') }}</span>
                            </p>
                            <p v-if="detection_rule.severity == 3" class="p-0 m-0 group-data">
                              <i class="pi pi-circle-fill  criticality-filter-High"
                                style="color:#ef6154; font-size: 0.8em;"></i>
                              <span>&nbsp;{{ $t('high') }}</span>
                            </p>
                            <p v-if="detection_rule.severity == 4" class="p-0 m-0 group-data">
                              <i class="pi pi-circle-fill  criticality-filter-Critical"
                                style="color:#b83c32; font-size: 0.8em;"></i>
                              <span>&nbsp;{{ $t('critical') }}</span>
                            </p>
                            <p class="p-0 m-0 mt-2 group-title "><strong>{{ $t('status') }}</strong></p>
                            <p v-if="detection_rule.is_active" class="p-0 m-0 group-data">
                              <i class="pi pi-circle-fill  criticality-filter-Low"
                                style="color:#6dc193; font-size: 0.8em;"></i>
                              <span>&nbsp;{{ $t('active') }}</span>
                            </p>
                            <p v-if="!detection_rule.is_active" class="p-0 m-0 group-data">
                              <i class="pi pi-circle-fill  criticality-filter-High"
                                style="color:#ef6154; font-size: 0.8em;"></i>
                              <span>&nbsp;{{ $t('deactivated') }}</span>
                            </p>
                          </template>
                        </OpenGroup>
                      </div>
                    </div>
                  </ScrollPanel>
                </TabPanel>
                <!-- ====================================================================== Tab assets =========================================================================== -->
                <TabPanel :header="$t('assets')">
                  <div class="loader text-center mt-2" style="width:100%;" v-if="loading_details">
                    <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                    <p>{{ $t('loading') }}</p>
                  </div>
                  <ScrollPanel :style="tab_position ? 'width: 100%; height:32.5em' : 'width: 100%; height:15em'"
                    class="custom" v-else>
                    <div class="row pb-2 pl-1 pr-1 pt-1">
                    <h6 v-if="!details.assets" class="text-center mt-4" style="width:100%;">{{ $t('noDataAvailable') }}
                    </h6>
                    <div v-else class="aux pt-2" :style="tab_position ? 'height:55vh;' : 'height:35vh;'">
                        <OpenGroup v-for="(asset, index) in details.assets" :key="asset" :groupName="asset.name"
                          :openFirst="index == 0 ? true : false">
                          <template #body>
                            <p class="p-0 m-0 group-title"><strong>{{ $t('ID') }}</strong></p>
                            <p class="p-0 m-0 group-data row-name-link" @click="$router.push({ path: 'asset-details', query: { id: asset.id } })">{{ asset.id }}</p>
                            <p class="p-0 m-0 mt-2 group-title "><strong>{{ $t('description') }}</strong></p>
                            <p class="p-0 m-0 group-data">{{ asset.description }}</p>
                            <p class="p-0 m-0 mt-2 group-title "><strong>{{ $t('severity') }}</strong></p>
                            <p v-if="asset.criticality == 0" class="p-0 m-0 group-data">
                              <i class="pi pi-circle-fill  criticality-filter-Info"
                                style="color:rgb(109, 122, 193); font-size: 0.8em;"></i>
                              <span>&nbsp;{{ $t('informational') }}</span>
                            </p>
                            <p v-if="asset.criticality == 1" class="p-0 m-0 group-data">
                              <i class="pi pi-circle-fill  criticality-filter-Low"
                                style="color:#6dc193; font-size: 0.8em;"></i>
                              <span>&nbsp;{{ $t('low') }}</span>
                            </p>
                            <p v-if="asset.criticality == 2" class="p-0 m-0 group-data">
                              <i class="pi pi-circle-fill  criticality-filter-Medium"
                                style="color:#f7b34c; font-size: 0.8em;"></i>
                              <span>&nbsp;{{ $t('medium') }}</span>
                            </p>
                            <p v-if="asset.criticality == 3" class="p-0 m-0 group-data">
                              <i class="pi pi-circle-fill  criticality-filter-High"
                                style="color:#ef6154; font-size: 0.8em;"></i>
                              <span>&nbsp;{{ $t('high') }}</span>
                            </p>
                            <p v-if="asset.criticality == 4" class="p-0 m-0 group-data">
                              <i class="pi pi-circle-fill  criticality-filter-Critical"
                                style="color:#b83c32; font-size: 0.8em;"></i>
                              <span>&nbsp;{{ $t('critical') }}</span>
                            </p>
                            <p class="p-0 m-0 mt-2 group-title "><strong>{{ $t('atRiskAssets') }}</strong></p>
                            <p v-if="asset.at_risk" class="p-0 m-0 group-data">
                              <i class="pi pi-circle-fill  criticality-filter-Low"
                                style="color:#6dc193; font-size: 0.8em;"></i>
                              <span>&nbsp;{{ $t('yes') }}</span>
                            </p>
                            <p v-if="!asset.at_risk" class="p-0 m-0 group-data">
                              <i class="pi pi-circle-fill  criticality-filter-High"
                                style="color:#ef6154; font-size: 0.8em;"></i>
                              <span>&nbsp;{{ $t('no') }}</span>
                            </p>
                          </template>
                        </OpenGroup>
                      </div>
                    </div>
                  </ScrollPanel>
                </TabPanel>
                <!-- ====================================================================== Tab users =========================================================================== -->
                <TabPanel :header="$t('users')">
                  <div class="loader text-center mt-2" style="width:100%;" v-if="loading_details">
                    <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                    <p>{{ $t('loading') }}</p>
                  </div>
                  <ScrollPanel :style="tab_position ? 'width: 100%; height:32.5em' : 'width: 100%; height:15em'"
                    class="custom" v-else>
                    <div class="row pb-2 pl-1 pr-1 pt-1">
                    <h6 v-if="!details.users" class="text-center mt-4" style="width:100%;">{{ $t('noDataAvailable') }}
                    </h6>
                    <div v-else class="aux pt-2" :style="tab_position ? 'height:55vh;' : 'height:35vh;'">
                        <OpenGroup v-for="(user, index) in details.users" :key="user" :groupName="user.full_name"
                          :openFirst="index == 0 ? true : false">
                          <template #body>
                            <p class="p-0 m-0 group-title"><strong>{{ $t('ID') }}</strong></p>
                            <p class="p-0 m-0 group-data row-name-link" @click="$router.push({ path: 'profile', query: { id: user.id } })">{{ user.id }}</p>
                            <p class="p-0 m-0 mt-2 group-title "><strong>{{ $t('email') }}</strong></p>
                            <p class="p-0 m-0 group-data">{{ user.email }}</p>
                          </template>
                        </OpenGroup>
                      </div>
                    </div>
                  </ScrollPanel>
                </TabPanel>
              </TabView>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--============================================================================== createAlert ==============================================================================-->
    <Modal ref="createAlert">
      <template #body>
        <div class="row">
          <div class="col-12 text-center pt-4">
            <i class="pi pi-th-large" style="font-size:2.5em;"></i>
          </div>
          <div class="col-12 text-center pt-0 pb-0">
            <h5 class="modal-title">{{ $t('createAlertConfiguration') }}</h5>
            {{ $t('or') }}
            <h5 class="mt-2">
              <FileUpload mode="basic" name="demo[]" :auto="true" :chooseLabel="(uploadLoading)?$t('loading'):$t('import')" :disabled="uploadLoading" :maxFileSize="1000000"
                @uploader="onUploadOne()" customUpload /><Helper :hlp_msg="$t('importFileHelper')"></Helper>
                
            </h5>
          </div>
        </div>
        <div class="row mt-4">
          <Steps :model="items" :readonly="false" class="mt-4">
            <template #item="{item}">
                <a :style="(item.step == step)?'color:#ec635f; font-weight: 700; cursor:pointer;':'color:black; cursor:pointer;'" @click="step = item.step">{{item.label}}</a>
            </template>
          </Steps>
        </div>
        <div class="row" v-if="step == 0">
          <div class="col-12 p-4">
            <label for="" class="pl-0 pb-0 mb-0 modal-label">{{ $t('name') }}</label>
            <InputText type="text" v-model="new_alert_name" :placeholder="$t('alertName')"
              style="width:100%;">
            </InputText>
            <label for="" class="pl-0 pb-0 mb-0 mt-1 modal-label">{{ $t('description') }}</label>
            <Textarea type="text" v-model="new_alert_description" :placeholder="$t('alertDescription')"
              style="width:100%;">
            </Textarea>
            <label for="" class="pl-0 pb-0 mb-0 mt-1 modal-label">{{ $t('tags') }}</label>
            <Chips v-model="new_alert_tags" :disabled="locked" style="width:100%;" />

            <label for="" class="pl-0 pb-0 mb-0 mt-2 modal-label">{{ $t('alertMethods') }}</label>
            <div class="col-12 pt-0 pb-0 pl-0">
                  <Checkbox v-model="slack_checked" :binary="true" /><i class="pi pi-slack ml-1" style="font-size: small;"></i><span class="ml-1 mr-3">SLACK</span>
                  <Checkbox v-model="phone_checked" :binary="true" /><i class="pi pi-mobile ml-1" style="font-size: small;"></i><span class="ml-1 mr-3">PHONE</span>
                  <Checkbox v-model="email_checked" :binary="true" /><i class="pi pi-envelope ml-1" style="font-size: small;"></i><span class="ml-1 mr-3">EMAIL</span>
            </div>
          </div>
        </div>
        <div class="row" v-if="step == 1">
          <div class="col-12 p-4">
            <span class="p-input-icon-left" style="width:100%;">
              <label for="" class="pl-0 pb-0 mb-0 modal-label">{{ $t('search') }}</label>
              <InputText v-model="filters1['global'].value" :placeholder="$t('searchByName')" class="search-input mb-2" style="width:100% !important;">
              </InputText>
            </span>
            <DataTable :value="new_detectionRules" :paginator="true" class="p-datatable-customers" :rows="8"
              :row-hover="true" v-model:selection="new_selectedDetectionRules" dataKey="id" v-model:filters="filters1"
              filter-display="menu" :loading="loading_table"
              :global-filter-fields="['id', 'title']" responsive-layout="scroll">
              <template #empty>
                {{$t('noDataFound')}}
              </template>
              <template #loading>
               {{$t('loadingData')}}
              </template>
              <Column selection-mode="multiple" header-style="width: 3rem"></Column>
              <Column field="title" :header="$t('title')" sortable>
                <template #body="{ data }">
                  <span class="row-name-link" style="cursor: default; text-decoration: none;">
                    {{ data.title }}
                  </span>
                </template>
              </Column>
              <Column field="description" :header="$t('description')" sortable>
                <template #body="{ data }">
                  <span >
                    {{ data.description }}
                  </span>
                </template>
              </Column>
            </DataTable>
          </div>
        </div>
        <div class="row" v-if="step == 2">
          <div class="col-12 p-4">
            <DataTable :value="new_correlationRules" :paginator="true" class="p-datatable-customers" :rows="8"
              :row-hover="true" v-model:selection="new_selectedCorrelationsRules" dataKey="id" v-model:filters="filters"
              filter-display="menu" :loading="loading_table"
              :global-filter-fields="['id', 'title']" responsive-layout="scroll">
              <template #empty>
                {{$t('noDataFound')}}
              </template>
              <template #loading>
               {{$t('loadingData')}}
              </template>
              <Column selection-mode="multiple" header-style="width: 3rem"></Column>
              <Column field="name" :header="$t('name')" sortable>
                <template #body="{ data }">
                  <span class="row-name-link" style="cursor: default; text-decoration: none;">
                    {{ data.title }}
                  </span>
                </template>
              </Column>
              <Column field="description" :header="$t('description')" sortable>
                <template #body="{ data }">
                  <span >
                    {{ data.description }}
                  </span>
                </template>
              </Column>
            </DataTable>
          </div>
        </div>
        <div class="row" v-if="step == 3">
          <div class="col-12 p-4">
            <DataTable :value="new_assets" :paginator="true" class="p-datatable-customers" :rows="8"
              :row-hover="true" v-model:selection="new_selectedAssets" dataKey="id" v-model:filters="filters"
              filter-display="menu" :loading="loading_table"
              :global-filter-fields="['id', 'name']" responsive-layout="scroll">
              <template #empty>
                {{$t('noDataFound')}}
              </template>
              <template #loading>
               {{$t('loadingData')}}
              </template>
              <Column selection-mode="multiple" header-style="width: 3rem"></Column>
              <Column field="name" :header="$t('name')" sortable>
                <template #body="{ data }">
                  <span class="row-name-link" style="cursor: default; text-decoration: none;">
                    {{ data.name }}
                  </span>
                </template>
              </Column>
            </DataTable>
          </div>
        </div>
        <div class="row" v-if="step == 4">
          <div class="col-12 p-4">
            <DataTable :value="new_users" :paginator="true" class="p-datatable-customers" :rows="8"
              :row-hover="true" v-model:selection="new_selectedUsers" dataKey="id" v-model:filters="filters"
              filter-display="menu" :loading="loading_table"
              :global-filter-fields="['id', 'name', 'email']" responsive-layout="scroll">
              <template #empty>
                {{$t('noDataFound')}}
              </template>
              <template #loading>
               {{$t('loadingData')}}
              </template>
              <Column selection-mode="multiple" header-style="width: 3rem"></Column>
              <Column field="name" :header="$t('name')" sortable>
                <template #body="{ data }">
                  <span class="row-name-link" style="cursor: default; text-decoration: none;">
                    {{ data.full_name }}
                  </span>
                </template>
              </Column>
              <Column field="email" :header="$t('email')" sortable>
                <template #body="{ data }">
                  <span class="row-name-link" style="cursor: default; text-decoration: none;">
                    {{ data.email }}
                  </span>
                </template>
              </Column>
            </DataTable>
          </div>
        </div>

      </template>
      <template #footer>
        <Button v-if="step != 0" class="btn-custom-save" :label="$t('previous')" @click="step = step-1"></Button>
        <Button v-if="step != 4" class="btn-custom-save" :label="$t('next')" @click="step = step+1"></Button>
        <Button v-if="step == 4" class="btn-custom" :label="$t('save')" @click="createNewAlertConfiguration(new_alert_name,new_alert_description,new_alert_tags)"></Button>
      </template>
    </Modal>
    <Modal ref="importData">
      <template #body>
        <div class="row">
          <div class="col-12 text-center pt-4">
            <i class="pi pi-th-large" style="font-size:2.5em;"></i>
          </div>
          <div class="col-12 text-center pt-0 pb-0">
            <h5 class="modal-title">{{ $t('import') }} {{ $t('assets') }}</h5>
            <h5 class="mt-3">
              <FileUpload mode="basic" name="demo[]" :auto="true" :chooseLabel="(uploadLoading)?$t('loading'):$t('import')" :disabled="uploadLoading" :maxFileSize="1000000"
                @uploader="onUploadVarious()" customUpload />
                
            </h5>
          </div>
        </div>
      </template>
      <template #footer>
      </template>
    </Modal>
  </div>
</template>
<!----------------------------------------------->
<!--                    SCRIPT                 -->
<!----------------------------------------------->

<script>
import MyShared from "./shared";
export default {
  mixins: [MyShared],
};

</script>

<script setup>
import { useMq } from "vue3-mq";
const mq = useMq();
</script>

<!----------------------------------------------->
<!--                    STYLES                 -->
<!----------------------------------------------->
<style>

</style>
