<!----------------------------------------------->
<!--                  TEMPLATE                 -->
<!----------------------------------------------->
<template>
  <div class="aux">
  <Sitebar></Sitebar>
  <div class="maincontainer" >
    <ConfirmDialog></ConfirmDialog>
    <!-- ==================================================================================================================================================== -->
    <!-- =================================================================== Top Buttons ==================================================================== -->
    <!-- ==================================================================================================================================================== -->
    <div class="row">
      <div class="col-4 p-0"></div>
        <div class="col-8 top-config">
        <i class="pi pi-replay top-config-icon" @click="$router.go()"><span class="top-config-label">&nbsp;{{
            $t('refresh')
        }}</span></i>
        <div class="dropdown">
          <i class="pi pi-clock top-config-icon dropdown-toggle" data-toggle="dropdown"><span
              class="top-config-label">&nbsp;{{ lastHours }}</span></i>
          <div class="dropdown-menu" aria-labelledby="navbarDropdown">
            <a class="dropdown-item" v-for="hour in hours" :key="hour" @click="setLastHours(hour)">
              {{ hour }}
            </a>
          </div>
        </div>
        <div class="dropdown" v-if="showActions">
            <i class="pi pi-cog top-config-icon dropdown-toggle" data-toggle="dropdown"><span
                class="top-config-label">&nbsp;{{ $t('actions') }}</span></i>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown" style="width:max-content">
              <a class="dropdown-item" @click="editMode = false" v-if="editMode">
                <p><i class="pi pi-times"></i>&nbsp;{{ $t('closeEditMode') }}</p>
              </a>
              <section v-for="(action, index) in actions" :key="action" @click="action.callback()">
                <a class="dropdown-item" v-if="action.permit">
                  <p><i :class="action.icon"></i>&nbsp;{{ action.label }}</p>
                </a>
              </section>
            </div>
          </div>
      </div>
    </div>
    <!-- ==================================================================================================================================================== -->
    <!-- ======================================================================= Top ======================================================================= -->
    <!-- ==================================================================================================================================================== -->
    <div class="row" style="width:100%">
      <div class="col-12 ">
        <Card>
          <template #content>
            <div class="row pl-4">
              <div class="col-2 pt-0" v-if="!loading_table">
                <i class="fa-solid fa-link top-tab-icon"></i>
                <span class="top-tab-text">
                  {{ data.length }}
                </span>
                <p class="top-tab-subtext mb-0">{{ $t('correlationRulesTotal') }} </p>
              </div>
              <div class="col-2" v-else><ContentLoader></ContentLoader></div>
              <div class="col-2 pt-0" v-if="!loading_table">
                <i class="fa-solid fa-play top-tab-icon"></i>
                <span class="top-tab-text">
                  {{ actives }}
                </span>
                <p class="top-tab-subtext mb-0">{{ $t('activatedCorrelations') }} </p>
              </div>
              <div class="col-2" v-else><ContentLoader></ContentLoader></div>
              <div class="col-2 pt-0" v-if="!loading_table">
                <i class="fa-solid fa-pause top-tab-icon"></i>
                <span class="top-tab-text">
                  {{ data.length - actives }}
                </span>
                <p class="top-tab-subtext mb-0">{{ $t('deactivatedCorrelations') }} </p>
              </div>
              <div class="col-2" v-else><ContentLoader></ContentLoader></div>
              <div class="col-6" v-if="!loading_table">
                  <strong class="chart-label">{{ $t('correlationsBySeverity') }} <Helper :hlp_msg="$t('elementByMinimumSeverityHelper')"></Helper></strong>
                  <div class="percentage-bar">
                    <div class="percentage-portion"
                      :style="'display:inline-block; background:#aeb5c1; width:' + (criticality_0) + '%;'"
                      v-if="criticality_0 != -0.8">&nbsp;
                    </div>
                    <div class="percentage-portion" :style="'display:inline-block; background:white; width:1%;'"
                      v-if="(criticality_0 != -0.8 && criticality_1 != -0.8) || (criticality_0 != -0.8 && criticality_1 == -0.8 && criticality_2 != -0.8) || (criticality_0 != -0.8 && criticality_1 == -0.8 && criticality_2 == -0.8 && criticality_3 != -0.8) || (criticality_0 != -0.8 && criticality_1 == -0.8 && criticality_2 == -0.8 && criticality_3 == -0.8 && criticality_4 != -0.8)">
                    </div>

                    <div class="percentage-portion"
                      :style="'display:inline-block; background:#6dc193; width:' + (criticality_1) + '%;'"
                      v-if="criticality_1 != -0.8">&nbsp;
                    </div>
                    <div class="percentage-portion" :style="'display:inline-block; background:white; width:1%;'"
                      v-if="(criticality_1 != -0.8 && criticality_2 != -0.8) || (criticality_1 != -0.8 && criticality_3 != -0.8 && criticality_2 == -0.8) || (criticality_1 != -0.8 && criticality_4 != -0.8 && criticality_2 == -0.8 && criticality_3 == -0.8)">
                    </div>

                    <div class="percentage-portion"
                      :style="'display:inline-block; background:#f7b34c; width:' + (criticality_2) + '%;'"
                      v-if="criticality_2 != -0.8">&nbsp;
                    </div>
                    <div class="percentage-portion" :style="'display:inline-block; background:white; width:1%;'"
                      v-if="(criticality_2 != -0.8 && criticality_3 != -0.8) || (criticality_2 != -0.8 && criticality_4 != -0.8 && criticality_3 == -0.8)">
                    </div>

                    <div class="percentage-portion"
                      :style="'display:inline-block; background:#ef6154; width:' + (criticality_3) + '%;'"
                      v-if="criticality_3 != -0.8">&nbsp;
                    </div>
                    <div class="percentage-portion" :style="'display:inline-block; background:white; width:1%;'"
                      v-if="criticality_3 != -0.8 && criticality_4 != -0.8">
                    </div>
                    <div class="percentage-portion"
                      :style="'display:inline-block; background:#b83c32; width:' + (criticality_4) + '%;'"
                      v-if="criticality_4 != -0.8">&nbsp;
                    </div>
                  </div>

                  <div class="percentage-bar row" style="width: 82%;">
                    <div class="pt-0 pb-0" style="display:ruby; width:auto;"><i class="pi pi-circle-fill"
                        style="color:#aeb5c1; font-size: 0.7em;"></i>&nbsp;{{ $t('informational') }}:
                      <strong>{{ number_of_info_criticality }}</strong>
                    </div>
                    <div class="pt-0 pb-0" style="display:ruby; width:auto;"><i class="pi pi-circle-fill"
                        style="color:#6dc193; font-size: 0.7em;"></i>&nbsp;{{ $t('low') }}:
                      <strong>{{ number_of_low_criticality }}</strong>
                    </div>
                    <div class="pt-0 pb-0" style="display:ruby; width:auto;"><i class="pi pi-circle-fill"
                        style="color:#f7b34c; font-size: 0.7em;"></i>&nbsp;{{ $t('medium') }}:
                      <strong>{{ number_of_medium_criticality }}</strong>
                    </div>
                    <div class="pt-0 pb-0" style="display:ruby; width:auto;"><i class="pi pi-circle-fill"
                        style="color:#ef6154; font-size: 0.7em;"></i>&nbsp;{{ $t('high') }}:
                      <strong>{{ number_of_high_criticality }}</strong>
                    </div>
                    <div class="pt-0 pb-0 pr-0" style="display:ruby; width:auto;"><i class="pi pi-circle-fill"
                        style="color:#b83c32; font-size: 0.7em;"></i>&nbsp;{{ $t('critical')
                        }}:
                      <strong>{{ number_of_critical_criticality }}</strong>
                    </div>
                  </div>
                  <!--
                  <div class="percentage-bar row">
                    <div class="pt-0 pb-0" style="display:ruby; width:auto;"><i class="pi pi-circle-fill" style="color:#6dc193; font-size: 0.7em;"></i>&nbsp;{{$t('low')}}: <strong>{{number_of_low_criticality}}</strong></div>
                    <div class="pt-0 pb-0" style="display:ruby; width:auto;"><i class="pi pi-circle-fill" style="color:#f7b34c; font-size: 0.7em;"></i>&nbsp;{{$t('medium')}}: <strong>{{number_of_medium_criticality}}</strong></div>
                    <div class="pt-0 pb-0" style="display:ruby; width:auto;"><i class="pi pi-circle-fill" style="color:#ef6154; font-size: 0.7em;"></i>&nbsp;{{$t('high')}}: <strong>{{number_of_high_criticality}}</strong></div>
                    <div class="pt-0 pb-0" style="display:ruby; width:auto;"><i class="pi pi-circle-fill" style="color:#b83c32; font-size: 0.7em;"></i>&nbsp;{{$t('critical')}}: <strong>{{number_of_critical_criticality}}</strong></div>
                  </div>
                  -->
                </div>
                <div class="col-6" v-else><ContentLoader height="90px" width="90%"></ContentLoader></div>
            </div>
          </template>
        </Card>
      </div>
        <!-- ==================================================================================================================================================== -->
        <!-- ===================================================================== EditMenu ===================================================================== -->
        <!-- ==================================================================================================================================================== -->
        <div v-if="editMode" class="col-12 ">
            <Card>
              <template #content>
                <div class="row pl-2">
                  <p><strong>{{$t('editOptions')}}</strong></p>
                  <div class="col-12">
                    <i class="pi pi-times mr-3" style="position: absolute; top:0; right:0; margin-top: -20px; cursor: pointer;" @click="editMode = false"></i>
                    <div class="" style="display: block ruby;">
                      <Button class="btn-custom-save" icon="pi pi-trash" :label="$t('deleteSelected')" @click="deleteData()"></Button>
                      <section class="dropdown ml-2" style="margin-top: 8px !important; margin-bottom: 8px !important;">
                          <span class="dropdown-toggle btn-custom-save" data-toggle="dropdown" style="padding-top: 0px !important; padding-bottom: 0 !important; border-radius: 3px;">
                            <span class="" style="font-weight: normal !important; font-size: small !important;"><i class="pi pi-pencil" style="font-size:small"></i>&nbsp;{{$t('changeSelectedCriticality')}}</span>
                          </span>
                          <div class="dropdown-menu" aria-labelledby="navbarDropdown" style="z-index: 999;">
                            <a class="dropdown-item" v-for="criticality in criticalities" :key="hour" @click="setCriticalityForSelectedItems(criticality.value)">
                              {{ criticality.label }}
                            </a>
                          </div>
                      </section>
                    </div> 
                  </div>
                </div>
              </template>
            </Card>
          </div>
      <!-- ==================================================================================================================================================== -->
      <!-- ======================================================================= Table ======================================================================= -->
      <!-- ==================================================================================================================================================== -->
      <div :class="tab_position ? 'col-8' : 'col-12 mb-0'" v-if="viewmode == 0">
        <div class="pad-helper main-table pt-2 pr-2 pl-2 m-0 pb-3" style="height:100%">
          <span class="p-input-icon-left" style="width:100%;">
            <InputText v-model="filters['global'].value" :placeholder="$t('searchByAsset')" class="search-input mb-2">
            </InputText>
          </span>
          <div class="loader text-center mt-2 mt-2" style="width:100%;" v-if="loading_table">
              <ContentLoader width="100%"></ContentLoader>
            </div>
            <DataTable v-else :value="data" :paginator="true" class="p-datatable-customers" :rows="tab_position ? 15 : 6"
            :row-hover="true" v-model:selection="selectedData" dataKey="id" v-model:filters="filters"
            filter-display="menu" :loading="loading_table" :selectionMode="(!editMode)?'single':''"
            paginator-template="PrevPageLink PageLinks NextPageLink CurrentPageReport"
            :rows-per-page-options="[10, 25, 50]"
            current-page-report-template="Showing {first} to {last} of {totalRecords} entries"
            :global-filter-fields="['id', 'title', 'criticality', 'is_active', 'created_at']"
            responsive-layout="scroll" @row-click="setSelectedData($event.data)">
            <template #empty>
              {{$t('noDataFound')}}
            </template>
            <template #loading>
             {{$t('loadingData')}}
            </template>
            <Column selection-mode="multiple" v-if="editMode"></Column>
            <Column field="title" :header="$t('title')" sortable>
              <template #body="{ data }">
                <span @click="$router.push({ path: 'correlation-rule-details', query: { id: data.id } })"
                  class="row-name-link">{{
                      data.title
                  }} </span>
              </template>
            </Column>
            <Column field="severity" :header="$t('minimumSeverity')" sortable>
              <template #body="{ data }">
              <span v-if="data.severity == 0"><i class="pi pi-circle-fill  criticality-filter-Info"
                      style="font-size: 0.7em;"></i>&nbsp;{{ $t('informational') }}</span>
                <span v-if="data.severity == 1"><i class="pi pi-circle-fill  criticality-filter-Low"
                    style="font-size: 0.7em;"></i>&nbsp;{{ $t('low') }}</span>
                <span v-if="data.severity == 2"><i class="pi pi-circle-fill  criticality-filter-Medium"
                    style="font-size: 0.7em;"></i>&nbsp;{{ $t('medium') }}</span>
                <span v-if="data.severity == 3"><i class="pi pi-circle-fill  criticality-filter-High"
                    style="font-size: 0.7em;"></i>&nbsp;{{ $t('high') }}</span>
                <span v-if="data.severity == 4"><i class="pi pi-circle-fill  criticality-filter-Critical"
                    style="font-size: 0.7em;"></i>&nbsp;{{ $t('critical') }}</span>

              </template>
            </Column>
            <Column field="is_active" :header="$t('isActive')" sortable>
              <template #body="{ data }">
                <span v-if="data.is_active"><i class="fa-solid fa-circle-exclamation" style="color:#6dc193"></i>&nbsp;{{
                    $t('yes')
                }}</span>
                <span v-if="!data.is_active"><i class="fa-solid fa-circle-exclamation" style="color:#ef6154"></i>&nbsp;{{
                    $t('no')
                }}</span>
              </template>
            </Column>
            <Column field="created_at" :header="$t('createdAt')" sortable>
              <template #body="{ data }">
                {{ formatDate(data.created_at) }}
              </template>
            </Column>
          </DataTable>
        </div>
      </div>

      <!-- ==================================================================================================================================================== -->
      <!-- ======================================================================= Tabs ======================================================================= -->
      <!-- ==================================================================================================================================================== -->

      <div :class="tab_position ? 'col-4 pl-0' : 'col-12'" :style="tab_position ? 'height:auto;' : 'height:35vh;'"
      v-if="viewmode == 0">
        <div class="pad-helper main-table pt-2 pr-2 pl-2 m-0" style="height:100%;">
          <div class="row">
            <div class="change-pos-div">
              <img v-if="tab_position" style="float:right; cursor:pointer; height:1.25em"
                @click="tab_position = !tab_position" :src="require('@/assets/icon-bottom.svg')">
              <img v-if="!tab_position" style="float:right; cursor:pointer; height:1.25em"
                @click="tab_position = !tab_position" :src="require('@/assets/icon-right.svg')">
              <!-- 
                <i v-if="tab_position" class="fa-solid fa-turn-down icon-flipped" style="float:right; cursor:pointer;"
                  @click="tab_position = !tab_position"></i>
                <i v-if="!tab_position" class="fa-solid fa-turn-up" style="float:right; cursor:pointer;"
                  @click="tab_position = !tab_position"></i>
                -->
            </div>
          </div>
          <div class="loader text-center mt-4" style="width:100%;" v-if="loading_table">
            <ContentLoader width="100%"></ContentLoader>
            </div>
            <div v-else class="row">
              <TabView v-if="details">
              <!-- ====================================================================== Details ============================================================================== -->
              <TabPanel :header="$t('details')">
                <div class="loader text-center mt-2" style="width:100%;" v-if="loading_details">
                  <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                  <p>{{ $t('loading') }}</p>
                </div>
                <ScrollPanel :style="tab_position ? 'width: 100%; height:auto' : 'width: 100%; height:15em'"
                    class="custom" v-else>
                    <div class="sub-scroll pl-3 pr-3 pb-4" :style="tab_position ? 'height:auto;' : 'height:25em;'">
                    <div class="row details-top">
                      <i class="fa-solid fa-yaveremos detail-icon"><span class="detail-name">{{ details.title
                      }}</span></i>
                      <p class="mb-0 pl-0"
                        @click="$router.push({ path: 'correlation-rule-details', query: { id: details.id } })">
                        <span class="id-prefix" style="text-decoration:none;">Correlation ID:</span> <span class="details-id">
                          {{ details.id }}
                        </span>
                      </p>
                    </div>
                    <div class="row details-data">
                      <div class="col-6 p-0 dropdown">
                        <Card data-toggle="dropdown">
                            <template #content>
                              <i v-if="details.is_active" class="fa-solid fa-circle-check subdetail-icon"
                                style="color:#6dc193">
                                <p class="subdetail-name dropdown-toggle" v-if="details.is_active">&nbsp;{{
                                  $t('activated')
                                }}</p>
                                <p class="subdetail-tag">&nbsp;{{ $t('status') }}</p>
                              </i>
                              <i v-else class="fa-solid fa-circle-xmark subdetail-icon dropdown" style="color:#ef6154">
                                <p class="subdetail-name dropdown-toggle" v-if="!details.is_active">&nbsp;{{
                                  $t('deactivated')
                                }}</p>
                                <p class="subdetail-tag">&nbsp;{{ $t('status') }}</p>
                              </i>
                            </template>
                          </Card>
                          <div class="dropdown-menu">
                            <a class="dropdown-item" v-for="status in statuses" :key="status"
                            @click="updateIsActive(details.id, status.value)">
                              {{ status.label }}
                            </a>
                          </div>
                      </div>
                      <div class="col-6 p-0 dropdown">
                        <Card data-toggle="dropdown">
                          <template #content>
                            <i v-if="details.severity == 0" class="fa-solid fa-shield-halved subdetail-icon"
                              style="color:#aeb5c1">
                              <p class="subdetail-name dropdown-toggle" v-if="details.severity == 0">&nbsp;{{ $t('critical') }}
                              </p>
                              <p class="subdetail-tag">&nbsp;{{ $t('minimumSeverity') }}</p>
                            </i>
                            <i v-if="details.severity == 1" class="fa-solid fa-shield-halved subdetail-icon"
                              style="color:#6dc193">
                              <p class="subdetail-name dropdown-toggle" v-if="details.severity == 1">&nbsp;{{ $t('low') }}</p>
                              <p class="subdetail-tag">&nbsp;{{ $t('minimumSeverity') }}</p>
                            </i>
                            <i v-if="details.severity == 2" class="fa-solid fa-shield-halved subdetail-icon"
                              style="color:#f7b34c">
                              <p class="subdetail-name dropdown-toggle" v-if="details.severity == 2">&nbsp;{{ $t('medium') }}</p>
                              <p class="subdetail-tag">&nbsp;{{ $t('minimumSeverity') }}</p>
                            </i>
                            <i v-if="details.severity == 3" class="fa-solid fa-shield-halved subdetail-icon"
                              style="color:#ef6154">
                              <p class="subdetail-name dropdown-toggle" v-if="details.severity == 3">&nbsp;{{ $t('high') }}</p>
                              <p class="subdetail-tag">&nbsp;{{ $t('minimumSeverity') }}</p>
                            </i>
                            <i v-if="details.severity == 4" class="fa-solid fa-shield-halved subdetail-icon"
                              style="color:#b83c32">
                              <p class="subdetail-name dropdown-toggle" v-if="details.severity == 4">&nbsp;{{ $t('critical') }}
                              </p>
                              <p class="subdetail-tag">&nbsp;{{ $t('minimumSeverity') }}</p>
                            </i>
                          </template>
                        </Card>
                        <div class="dropdown-menu">
                          <a class="dropdown-item" v-for="severity in criticalities" :key="severity"
                            @click="updateSeverity(details.id, severity.value)">
                            {{ severity.label }}
                          </a>
                        </div>
                      </div>
                    </div>
                    <div class="row details-data">
                      <div :class="tab_position ? 'col-12 pr-0 pl-0' : 'col-6 pr-4'">
                        <div class="row">
                          <p class="mt-0 mb-0 details-title"><strong>{{ $t('description') }}</strong></p>
                          <p class="">{{ details.description }}</p>
                        </div>
                        <div class="row">
                          <p class="mb-0 mt-2  details-title"><strong>{{ $t('author') }}</strong></p>
                          <p class="mb-0 mb-2">{{ details.author }}</p>
                        </div>
                        <div class="row">
                          <p class="mb-0 mt-2  details-title"><strong>{{ $t('createdAt') }}</strong></p>
                          <p class="">{{ formatDate(details.created_at) }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </ScrollPanel>
              </TabPanel>
              <!-- ====================================================================== sources =========================================================================== -->
              <TabPanel :header="$t('sources')">
                <div class="loader text-center mt-2" style="width:100%;" v-if="loading_details">
                  <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                  <p>{{ $t('loading') }}</p>
                </div>
                <ScrollPanel :style="tab_position ? 'width: 100%;height:32.5em' : 'width: 100%;height:15em'" class="custom" v-else>
                  <div class="row pb-2 pl-1 pr-1 pt-1">
                    <h6 v-if="!details.sources" class="text-center mt-4" style="width:100%;">{{ $t('noDataAvailable') }}
                    </h6>
                    <div v-else class="aux pt-2" :style="tab_position ? 'height:55vh;' : 'height:35vh;'">
                    <OpenGroup v-for="(source, index) in details.sources" :key="source" :groupName="source.name"
                      :openFirst="index == 0 ? true : false">
                      <template #body>
                          <p class="p-0 m-0 group-title"><strong>{{ $t('ID') }}</strong></p>
                          <p class="p-0 m-0 group-data row-name-link" @click="$router.push({ path: 'source-details', query: { id: source.id } })">{{ source.id }}</p>
                          <p class="p-0 m-0 mt-2 group-title "><strong>{{ $t('description') }}</strong></p>
                          <p class="p-0 m-0 group-data">{{ source.description }}</p>
                        </template>
                    </OpenGroup>
                  </div>
                  </div>
                </ScrollPanel>
              </TabPanel>
              <!-- ====================================================================== alerts =========================================================================== -->
              <TabPanel :header="$t('alerts')">
                <div class="loader text-center mt-2" style="width:100%;" v-if="loading_details">
                  <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                  <p>{{ $t('loading') }}</p>
                </div>
                <ScrollPanel :style="tab_position ? 'width: 100%;height:32.5em' : 'width: 100%;height:15em'" class="custom" v-else>
                  <div class="row pb-2 pl-1 pr-1 pt-1">
                    <h6 v-if="!details.alerts" class="text-center mt-4" style="width:100%;">{{ $t('noDataAvailable') }}
                    </h6>
                    <div v-else class="aux pt-2" :style="tab_position ? 'height:55vh;' : 'height:35vh;'">
                    <OpenGroup v-for="(alert, index) in details.alerts" :key="alert" :groupName="alert.name"
                      :openFirst="index == 0 ? true : false">
                      <template #body>
                          <p class="p-0 m-0 group-title"><strong>{{ $t('ID') }}</strong></p>
                          <p class="p-0 m-0 group-data row-name-link" @click="$router.push({ path: 'alert-details', query: { id: alert.id } })">{{ alert.id }}</p>
                          <p class="p-0 m-0 mt-2 group-title "><strong>{{ $t('description') }}</strong></p>
                          <p class="p-0 m-0 group-data">{{ alert.description }}</p>
                          <p class="p-0 m-0 mt-2 group-title "><strong>{{ $t('filters') }}</strong></p>
                          <div class="tag-cont p-0">
                            <p class="details-tag" v-for="filter in alert.filters" :key="filter"
                              style="display:inline-block;">
                              {{ filter }}&nbsp;</p>
                          </div>
                          <p class="p-0 m-0 mt-2 group-title "><strong>{{ $t('minimumSeverity') }}</strong></p>
                          <p v-if="alert.severity == 0" class="p-0 m-0 group-data">
                            <i class="pi pi-circle-fill  criticality-filter-Info"
                              style="color:rgb(109, 122, 193); font-size: 0.8em;"></i>
                            <span>&nbsp;{{ $t('informational') }}</span>
                          </p>
                          <p v-if="alert.severity == 1" class="p-0 m-0 group-data">
                            <i class="pi pi-circle-fill  criticality-filter-Low"
                              style="color:#6dc193; font-size: 0.8em;"></i>
                            <span>&nbsp;{{ $t('low') }}</span>
                          </p>
                          <p v-if="alert.severity == 2" class="p-0 m-0 group-data">
                            <i class="pi pi-circle-fill  criticality-filter-Medium"
                              style="color:#f7b34c; font-size: 0.8em;"></i>
                            <span>&nbsp;{{ $t('medium') }}</span>
                          </p>
                          <p v-if="alert.severity == 3" class="p-0 m-0 group-data">
                            <i class="pi pi-circle-fill  criticality-filter-High"
                              style="color:#ef6154; font-size: 0.8em;"></i>
                            <span>&nbsp;{{ $t('high') }}</span>
                          </p>
                          <p v-if="alert.severity == 4" class="p-0 m-0 group-data">
                            <i class="pi pi-circle-fill  criticality-filter-Critical"
                              style="color:#b83c32; font-size: 0.8em;"></i>
                            <span>&nbsp;{{ $t('critical') }}</span>
                          </p>
                        </template>
                    </OpenGroup>
                  </div>
                  </div>
                </ScrollPanel>
              </TabPanel>
            </TabView>
          </div>
        </div>
      </div>
    </div>
  </div>
      <!--============================================================================== createData ==============================================================================-->
      <Modal ref="createData">
      <template #body>
        <div class="row">
          <div class="col-12 text-center pt-4">
            <i class="pi pi-th-large" style="font-size:2.5em;"></i>
          </div>
          <div class="col-12 text-center pt-0 pb-0">
            <h5 class="modal-title">{{ $t('createNewCorrelationRule') }}</h5>
            {{ $t('or') }}
            <h5 class="mt-2"><FileUpload mode="basic" name="demo[]" :auto="true" :chooseLabel="(uploadLoading)?$t('loading'):$t('import')" :disabled="uploadLoading" :maxFileSize="1000000" @uploader="onUploadOne()" customUpload /><Helper :hlp_msg="$t('importFileHelper')"></Helper>
                </h5>
          </div>
        </div>
        <div class="row mt-4">
          <Steps :model="items" :readonly="false" class="mt-4">
            <template #item="{item}">
                <a :style="(item.step == step)?'color:#ec635f; font-weight: 700; cursor:pointer;':'color:black; cursor:pointer;'" @click="step = item.step">{{item.label}}</a>
            </template>
          </Steps>
        </div>
        <div class="row" v-if="step == 0">
          <div class="col-12 p-4">
            <label for="" class="pl-0 pb-0 mb-0">{{ $t('name') }}</label>
            <InputText type="text" v-model="new_title" :placeholder="$t('name')" style="width:100%;">
            </InputText>
            <label for="" class="pl-0 pb-0 mb-0 mt-1">{{ $t('description') }}</label>
            <Textarea type="text" v-model="new_desc" :placeholder="$t('alertDescription')" style="width:100%;">
              </Textarea>
            <label for="" class="pl-0 pb-0 mb-0">{{ $t('author') }}</label>
            <InputText type="text" v-model="new_author" :placeholder="'Alex Turner'" style="width:100%;">
            </InputText>
          </div>
        </div>
        <div class="row" v-if="step == 1">
          <div class="col-12 p-4">
            <DataTable :value="sources" :paginator="true" class="p-datatable-customers" :rows="8" :row-hover="true"
              v-model:selection="new_selectedSources" dataKey="id" v-model:filters="filters" filter-display="menu"
              :loading="loading_table" :global-filter-fields="['id', 'name']" responsive-layout="scroll">
              <template #empty>
                {{ $t('noDataFound') }}
              </template>
              <template #loading>
                {{ $t('loadingData') }}
              </template>
              <Column selection-mode="multiple" header-style="width: 3rem"></Column>
              <Column field="title" :header="$t('name')" sortable>
                <template #body="{ data }">
                  <span class="row-name-link" style="cursor: default; text-decoration: none;">
                    {{ data.name }}
                  </span>
                </template>
              </Column>
              <Column field="description" :header="$t('description')" sortable>
                <template #body="{ data }">
                  <span>
                    {{ data.description }}
                  </span>
                </template>
              </Column>
            </DataTable>
          </div>
        </div>

      </template>
      <template #footer>
        <Button v-if="step != 0" class="btn-custom-save" :label="$t('previous')" @click="step = step-1"></Button>
        <Button v-if="step != 1" class="btn-custom-save" :label="$t('next')" @click="step = step+1"></Button>
        <Button v-if="step == 1" class="btn-custom" :label="$t('save')" @click="addNewCorrelation(new_title,new_desc,new_author)"></Button>
      </template>
    </Modal>
    <Modal ref="importData">
      <template #body>
        <div class="row">
          <div class="col-12 text-center pt-4">
            <i class="pi pi-th-large" style="font-size:2.5em;"></i>
          </div>
          <div class="col-12 text-center pt-0 pb-0">
            <h5 class="modal-title">{{ $t('import') }} {{ $t('assets') }}</h5>
            <h5 class="mt-3">
              <FileUpload mode="basic" name="demo[]" :auto="true" :chooseLabel="(uploadLoading)?$t('loading'):$t('import')" :disabled="uploadLoading" :maxFileSize="1000000"
                @uploader="onUploadVarious()" customUpload />
                
            </h5>
          </div>
        </div>
      </template>
      <template #footer>
      </template>
    </Modal>
</div>
</template>
<!----------------------------------------------->
<!--                    SCRIPT                 -->
<!----------------------------------------------->

<script>
import MyShared from "./shared";
export default {
mixins: [MyShared],
};

</script>

<script setup>
import { useMq } from "vue3-mq";
const mq = useMq();
</script>

<!----------------------------------------------->
<!--                    STYLES                 -->
<!----------------------------------------------->
<style>
</style>
