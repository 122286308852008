<!----------------------------------------------->
<!--                  TEMPLATE                 -->
<!----------------------------------------------->
<template>
  <MobileRegister v-if="  mq.current  === 'mobile'"></MobileRegister>
  <TabletRegister v-if="  mq.current  === 'tablet'"></TabletRegister>
  <DesktopRegister v-if=" mq.current  === 'desktop'"></DesktopRegister>
</template>

<!----------------------------------------------->
<!--                    SCRIPT                 -->
<!----------------------------------------------->
<script>
import MobileRegister from "../components/Sign-up/Mobile";
import DesktopRegister from "../components/Sign-up/Desktop";
import TabletRegister from "../components/Sign-up/Tablet";

export default {
  /* Name */
  name: "Home",
  inject: ["mq"],
  /* Components */
  components: {
    MobileRegister,
    TabletRegister,
    DesktopRegister,
  },
};
</script>

<script setup>
import { useMq } from "vue3-mq";
const mq = useMq();
</script>
<!----------------------------------------------->
<!--                    STYLES                 -->
<!----------------------------------------------->
<style scoped >

</style>
