import $axios from '../utils';

export function getAllColorPalettes(full = true, offset = 0, limit = 10000) {
    return $axios.get(`dashboard/color_palette/?full=${full}&offset=${offset}&limit=${limit}`)
        .then(response => response.data)
}
export function getColorPalettesBy(key, value, full, offset, limit) {
    return $axios.get(`dashboard/color_palette/by?key${key}&value=${value}&full=${full}&offset=${offset}&limit=${limit}`)
        .then(response => response.data)
}
export function getOneColorPalette(id, full) {
    return $axios.get(`dashboard/color_palette/${id}?full=${full}`)
        .then(response => response.data)
}
export function createColorPalette(data) {
    return $axios.post('dashboard/color_palette/', data)
        .then(response => response.data)
}
export function updateColorPalette(id, data) {
    return $axios.put(`dashboard/color_palette/${id}`, data)
        .then(response => response.data)
}
export function deleteAllColorPalettes() {
    return $axios.delete('dashboard/color_palette/')
        .then(response => response.data)
}
export function deleteColorPalette(id) {
    return $axios.delete(`dashboard/color_palette/${id}`)
        .then(response => response.data)
}