import $axios from '../utils';

export function getAllSources(full = true, offset = 0, limit = 10000) {
    return $axios.get(`intake/source/?full=${full}&offset=${offset}&limit=${limit}`)
        .then(response => response.data)
}
export function getSourcesBy(key, value, full, offset, limit) {
    return $axios.get(`intake/source/by?key${key}&value=${value}&full=${full}&offset=${offset}&limit=${limit}`)
        .then(response => response.data)
}
export function getOneSource(id, full) {
    return $axios.get(`intake/source/${id}?full=${full}`)
        .then(response => response.data)
}
export function createSource(data) {
    return $axios.post('intake/source/', data)
        .then(response => response.data)
}
export function updateSource(id, data) {
    return $axios.put(`intake/source/${id}`, data)
        .then(response => response.data)
}
export function deleteAllSources() {
    return $axios.delete('intake/source/')
        .then(response => response.data)
}
export function deleteSource(id) {
    return $axios.delete(`intake/source/${id}`)
        .then(response => response.data)
}
export function getSourceLogo(logo) {
    return $axios.get(`${logo}`)
        .then(response => response.data)
}
export function newSourceLogo(id, data) {
    return $axios.put(`intake/source/new-logo/${id}`, data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(response => response.data)
}