<!----------------------------------------------->
<!--                  TEMPLATE                 -->
<!----------------------------------------------->
<template>
  <div class="aux">
  <Sitebar></Sitebar>
  <div class="maincontainer" >
    <ConfirmDialog></ConfirmDialog>
    <!-- ==================================================================================================================================================== -->
    <!-- =================================================================== Top Buttons ==================================================================== -->
    <!-- ==================================================================================================================================================== -->
    <div class="row">
      <div class="col-4 p-0"></div>
        <div class="col-8 top-config">
        <i class="pi pi-replay top-config-icon" @click="$router.go()"><span class="top-config-label">&nbsp;{{
            $t('refresh')
        }}</span></i>
        <div class="dropdown">
          <i class="pi pi-clock top-config-icon dropdown-toggle" data-toggle="dropdown"><span
              class="top-config-label">&nbsp;{{ lastHours }}</span></i>
          <div class="dropdown-menu" aria-labelledby="navbarDropdown">
            <a class="dropdown-item" v-for="hour in hours" :key="hour" @click="setLastHours(hour)">
              {{ hour }}
            </a>
          </div>
        </div>
        <div class="dropdown">
          <i class="pi pi-cog top-config-icon dropdown-toggle" data-toggle="dropdown"><span
              class="top-config-label">&nbsp;{{ $t('actions') }}</span></i>
          <div class="dropdown-menu" aria-labelledby="navbarDropdown" style="width:max-content">
            <a class="dropdown-item" @click="editMode = false" v-if="editMode">
              <p><i class="pi pi-times"></i>&nbsp;{{ $t('closeEditMode') }}</p>
            </a>
            <a class="dropdown-item" v-for="(action, index) in actions" :key="action" @click="action.callback()">
              <p><i :class="action.icon"></i>&nbsp;{{ action.label }}</p>
            </a>
          </div>
        </div>
        <i class="pi pi-th-large top-config-icon" @click="viewmodemenu = true"><span class="top-config-label">&nbsp;{{
          $t('viewMode')
      }}</span></i>
      </div>
    </div>
    <!-- ==================================================================================================================================================== -->
    <!-- =================================================================== View Menu ==================================================================== -->
    <!-- ==================================================================================================================================================== -->
    <Transition name="slide-fade">
      <div class="row viewmodemenu" v-if="viewmodemenu" @mouseleave="viewmodemenu = false">
        <!-- @mouseleave="viewmodemenu = false" -->
        <div class="col-10">
          <div class="viewmode_selector_cont" @click="viewmode = 0">
            <div class="row m-0 p-0">
              <div class="col-12 m-0 p-0">
                <img :class="(viewmode == 0) ? 'viewmode_selector viewmode_selector_active' : 'viewmode_selector'"
                  :src="require('@/assets/tableviewmode.png')" />
              </div>
              <div class="col-12 m-0 p-0 text-center">{{ $t('tableMode') }}</div>
            </div>
          </div>
          <div class="viewmode_selector_cont" @click="viewmode = 1">
            <div class="row m-0 p-0">
              <div class="col-12 m-0 p-0">
                <img :class="(viewmode == 1) ? 'viewmode_selector viewmode_selector_active' : 'viewmode_selector'"
                  :src="require('@/assets/dashboardviewmode.png')" />
              </div>
              <div class="col-12 m-0 p-0 text-center">{{ $t('dashboardMode') }}</div>
            </div>
          </div>
        </div>
      </div>
    </Transition>
    <!-- ==================================================================================================================================================== -->
    <!-- ======================================================================= Top ======================================================================= -->
    <!-- ==================================================================================================================================================== -->
    <div class="row" style="width:100%">
      <div class="col-12">
        <Card>
          <template #content>
            <div class="row pl-4">
              <div class="col-2 pt-0" v-if="!loading_table">
                <i class="fa-solid fa-server top-tab-icon"></i>
                <span class="top-tab-text">
                  {{ data.length }}
                  <!--
                  <i class="fa-solid fa-caret-up top-tab-subicon">
                    <span class="top-tab-subicon-text">21</span>
                  </i>
                  -->
                </span>
                <p class="top-tab-subtext mb-0">{{ $t('assetTotal') }} </p>
              </div>
              <div class="col-2" v-else><ContentLoader></ContentLoader></div>
              <div class="col-2 pt-0" v-if="!loading_table">
                <i class="fa-solid fa-layer-group top-tab-icon"></i>
                <span class="top-tab-text">
                  {{ groups.length }}
                  <!--
                  <i class="fa-solid fa-caret-up top-tab-subicon">
                    <span class="top-tab-subicon-text">21</span>
                  </i>
                  -->
                </span>
                <p class="top-tab-subtext mb-0">{{ $t('groupsNumber') }} </p>
              </div>
              <div class="col-2" v-else><ContentLoader></ContentLoader></div>
              <div class="col-2 pt-0" v-if="!loading_table">
                <i class="fa-solid fa-virus-covid top-tab-icon"></i>
                <span class="top-tab-text">
                  {{ atRiskAssets }}
                  <!--
                  <i class="fa-solid fa-caret-up top-tab-subicon">
                    <span class="top-tab-subicon-text">21</span>
                  </i>
                  -->
                </span>
                <p class="top-tab-subtext mb-0">{{ $t('atRiskAssets') }} </p>
              </div>
              <div class="col-2" v-else><ContentLoader></ContentLoader></div>
              <div class="col-6" v-if="!loading_table">
                <strong class="chart-label">{{ $t('assetsByCriticality') }}</strong>
                <div class="percentage-bar">
                  <div class="percentage-portion"
                    :style="'display:inline-block; background:#6dc193; width:' + (criticality_1) + '%;'"
                    v-if="criticality_1 != -0.75">&nbsp;</div>
                  <div class="percentage-portion" :style="'display:inline-block; background:white; width:1%;'"
                    v-if="(criticality_1 != -0.75 && criticality_2 != -0.75) || (criticality_1 != -0.75 && criticality_3 != -0.75 && criticality_2 == -0.75) || (criticality_1 != -0.75 && criticality_4 != -0.75 && criticality_2 == -0.75 && criticality_3 == -0.75)">
                  </div>
                  <div class="percentage-portion"
                    :style="'display:inline-block; background:#f7b34c; width:' + (criticality_2) + '%;'"
                    v-if="criticality_2 != -0.75">&nbsp;</div>
                  <div class="percentage-portion" :style="'display:inline-block; background:white; width:1%;'"
                    v-if="(criticality_2 != -0.75 && criticality_3 != -0.75) || (criticality_2 != -0.75 && criticality_4 != -0.75 && criticality_3 == -0.75)">
                  </div>
                  <div class="percentage-portion"
                    :style="'display:inline-block; background:#ef6154; width:' + (criticality_3) + '%;'"
                    v-if="criticality_3 != -0.75">&nbsp;</div>
                  <div class="percentage-portion" :style="'display:inline-block; background:white; width:1%;'"
                    v-if="criticality_3 != -0.75 && criticality_4 != -0.75"></div>
                  <div class="percentage-portion"
                    :style="'display:inline-block; background:#b83c32; width:' + (criticality_4) + '%;'"
                    v-if="criticality_4 != -0.75">&nbsp;</div>
                </div>
                <div class="percentage-bar row">
                  <div class="pt-0 pb-0" style="display:ruby; width:auto;"><i class="pi pi-circle-fill" style="color:#6dc193; font-size: 0.7em;"></i>&nbsp;{{$t('low')}}: <strong>{{number_of_low_criticality}}</strong></div>
                  <div class="pt-0 pb-0" style="display:ruby; width:auto;"><i class="pi pi-circle-fill" style="color:#f7b34c; font-size: 0.7em;"></i>&nbsp;{{$t('medium')}}: <strong>{{number_of_medium_criticality}}</strong></div>
                  <div class="pt-0 pb-0" style="display:ruby; width:auto;"><i class="pi pi-circle-fill" style="color:#ef6154; font-size: 0.7em;"></i>&nbsp;{{$t('high')}}: <strong>{{number_of_high_criticality}}</strong></div>
                  <div class="pt-0 pb-0" style="display:ruby; width:auto;"><i class="pi pi-circle-fill" style="color:#b83c32; font-size: 0.7em;"></i>&nbsp;{{$t('critical')}}: <strong>{{number_of_critical_criticality}}</strong></div>
                </div>
              </div>
              <div class="col-6" v-else><ContentLoader height="90px" width="90%"></ContentLoader></div>
            </div>
          </template>
        </Card>
      </div>
      <!-- ==================================================================================================================================================== -->
      <!-- ===================================================================== EditMenu ===================================================================== -->
      <!-- ==================================================================================================================================================== -->
        <div v-if="editMode" class="col-12 ">
          <Card>
            <template #content >
              <div class="row pl-2">
                <p><strong>{{$t('editOptions')}}</strong></p>
                <div class="col-12">
                  <i class="pi pi-times mr-3" style="position: absolute; top:0; right:0; margin-top: -20px; cursor: pointer;" @click="editMode = false"></i>
                  <Button class="btn-custom-save" icon="pi pi-trash" :label="$t('deleteSelected')" @click="deleteData()"></Button>
                </div>
              </div>
            </template>
          </Card>
        </div>
      <!-- ==================================================================================================================================================== -->
      <!-- ======================================================================= Table ======================================================================= -->
      <!-- ==================================================================================================================================================== -->
      <div :class="tab_position ? 'col-8' : 'col-12 mb-0'" v-if="viewmode == 0">
        <div class="pad-helper main-table pt-2 pr-2 pl-2 m-0 pb-3" style="height:100%">
          <span class="p-input-icon-left" style="width:100%;">
            <InputText v-model="filters['global'].value" :placeholder="$t('searchByAsset')" class="search-input mb-2">
            </InputText>
            <MultiSelect v-model="selectedGroups" :options="groups" option-label="name"
              :placeholder="$t('filterByGroup')" display="chip" style="float:right; width:25em;"
              @change="filterByGroup(selectedGroups, false)" class="mb-2"></MultiSelect>
          </span>
          <div class="loader text-center mt-2 mt-2" style="width:100%;" v-if="loading_table">
            <ContentLoader width="100%"></ContentLoader>
          </div>
          <DataTable v-else :value="data" :paginator="true" class="p-datatable-customers" :rows="tab_position ? 15 : 6"
            :row-hover="true" v-model:selection="selectedData" dataKey="id" v-model:filters="filters"
            filter-display="menu" :loading="loading_table" :selectionMode="(!editMode)?'single':''"
            paginator-template="PrevPageLink PageLinks NextPageLink CurrentPageReport"
            :rows-per-page-options="[10, 25, 50]"
            current-page-report-template="Showing {first} to {last} of {totalRecords} entries"
            :global-filter-fields="['id', 'name', 'os', 'hostname', 'type', 'criticality', 'last_date', 'risk']"
            responsive-layout="scroll" @row-click="setSelectedData($event.data)">

            <template #empty>
              {{$t('noDataFound')}}
            </template>
            <template #loading>
             {{$t('loadingData')}}
            </template>
            <Column selection-mode="multiple" v-if="editMode"></Column>
            <Column field="name" :header="$t('name')" sortable>
              <template #body="{ data }">
                <span @click="$router.push({ path: 'asset-details', query: { id: data.id } })"
                  class="row-name-link">{{
                      data.name
                  }} </span>
              </template>
              <!--
            <template #filter="{filterModel}">
              <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by name"></InputText>
            </template>
            -->
            </Column>
            <Column field="os" :header="$t('operatingSystem')" sortable>
              <template #body="{ data }">
                {{ data.os }}
              </template>
            </Column>
            <Column field="hostname" :header="$t('hostName')" sortable>
              <template #body="{ data }">
                {{ data.hostname }}
              </template>
            </Column>
            <Column field="type" :header="$t('type')" sortable>
              <template #body="{ data }">
                {{ data.type }}
              </template>
            </Column>
            <Column field="criticality" :header="$t('criticality')" sortable>
              <template #body="{ data }">
              <!--
              <span v-if="data.criticality == 1"  style="background:#6dc193; color:#3f7055; padding: 3px; text-transform: uppercase; font-size: 0.85em; border-radius: 2px;">Low</span>
              <span v-if="data.criticality == 2" style="background:#f7b34c; color:#b58338; padding: 3px; text-transform: uppercase; font-size: 0.85em; border-radius: 2px;">Medium</span>
              <span v-if="data.criticality == 3" style="background:#ef6154; color:#963e36; padding: 3px; text-transform: uppercase; font-size: 0.85em; border-radius: 2px;">High</span>
              <span v-if="data.criticality == 4" style="background:#b83c32; color:#63231e; padding: 3px; text-transform: uppercase; font-size: 0.85em; border-radius: 2px;">Critical</span>
              -->
                <span v-if="data.criticality == 1"><i class="pi pi-circle-fill  criticality-filter-Low"
                    style="font-size: 0.7em;"></i>&nbsp;{{ $t('low') }}</span>
                <span v-if="data.criticality == 2"><i class="pi pi-circle-fill  criticality-filter-Medium"
                    style="font-size: 0.7em;"></i>&nbsp;{{ $t('medium') }}</span>
                <span v-if="data.criticality == 3"><i class="pi pi-circle-fill  criticality-filter-High"
                    style="font-size: 0.7em;"></i>&nbsp;{{ $t('high') }}</span>
                <span v-if="data.criticality == 4"><i class="pi pi-circle-fill  criticality-filter-Critical"
                    style="font-size: 0.7em;"></i>&nbsp;{{ $t('critical') }}</span>

              </template>
              <!--
            <template #filter="{filterModel}">
              <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by name"></InputText>
            </template>
            -->
              <!-- 
            <template #filter="{filterModel}">
              <Dropdown v-model="filterModel.value" :options="criticalities" placeholder="Any" class="p-column-filter" :show-clear="true">
                  <template #value="slotProps">
                    <span><i :class="'pi pi-circle-fill criticality-filter-' + slotProps.value"></i>&nbsp;{{slotProps.value}}</span>
                  </template>
                  <template #option="slotProps">
                      <span :class="'pi pi-circle-fill criticality-filter-' + slotProps.option">&nbsp;{{slotProps.option}}</span>
                  </template>
              </Dropdown>
            </template>
          -->
            </Column>
            <Column field="last_date" :header="$t('lastActDate')" sortable>
              <template #body="{ data }">
                {{ formatDate(data.last_date) }}
              </template>
            </Column>
            <Column field="risk" :header="$t('risk')" sortable>
              <template #body="{ data }">
                <span v-if="data.risk"><i class="fa-solid fa-circle-exclamation" style="color:#ef6154"></i>&nbsp;{{
                    $t('yes')
                }}</span>
                <span v-if="!data.risk"><i class="fa-solid fa-circle-exclamation" style="color:#6dc193"></i>&nbsp;{{
                    $t('no')
                }}</span>
              </template>
            </Column>
          </DataTable>
        </div>
      </div>

      <!-- ==================================================================================================================================================== -->
      <!-- ======================================================================= Tabs ======================================================================= -->
      <!-- ==================================================================================================================================================== -->

      <div :class="tab_position ? 'col-4 pl-0' : 'col-12'" :style="tab_position ? 'height:auto;' : 'height:35vh;'"
      v-if="viewmode == 0">
        <div class="pad-helper main-table pt-2 pr-2 pl-2 m-0" style="height:100%;">
          <div class="row">
            <div class="change-pos-div">
              <img v-if="tab_position" style="float:right; cursor:pointer; height:1.25em"
                @click="tab_position = !tab_position" :src="require('@/assets/icon-bottom.svg')">
              <img v-if="!tab_position" style="float:right; cursor:pointer; height:1.25em"
                @click="tab_position = !tab_position" :src="require('@/assets/icon-right.svg')">
              <!-- 
                <i v-if="tab_position" class="fa-solid fa-turn-down icon-flipped" style="float:right; cursor:pointer;"
                  @click="tab_position = !tab_position"></i>
                <i v-if="!tab_position" class="fa-solid fa-turn-up" style="float:right; cursor:pointer;"
                  @click="tab_position = !tab_position"></i>
                -->
            </div>
          </div>
          <div class="loader text-center mt-4" style="width:100%;" v-if="loading_table">
            <ContentLoader width="100%"></ContentLoader>
          </div>
          <div v-else class="row">
            <TabView>
              <!-- ====================================================================== Details ============================================================================== -->
              <TabPanel :header="$t('details')">
                <div class="loader text-center mt-2" style="width:100%;" v-if="loading_details">
                  <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                  <p>{{ $t('loading') }}</p>
                </div>
                <ScrollPanel :style="tab_position ? 'width: 100%; height:auto' : 'width: 100%; height:15em'"
                  class="custom" v-else>
                  <div class="sub-scroll pl-3 pr-3 pb-4" :style="tab_position ? 'height:auto;' : 'height:25em;'">
                    <div class="row details-top">
                      <i class="fa-solid fa-yaveremos detail-icon"><span class="detail-name">{{ details.name
                      }}</span></i>
                      <p class="mb-0 pl-0"
                        @click="$router.push({ path: 'asset-details', query: { id: details.id } })">
                        <span class="id-prefix" style="text-decoration:none;">Asset ID:</span> <span class="details-id">
                          {{ details.id }}
                        </span>
                      </p>
                    </div>
                    <div class="row details-data">
                      <div class="col-4 p-0">
                        <Card>
                          <template #content>
                            <i class="fa-solid fa-tag subdetail-icon">
                              <p class="subdetail-name">&nbsp;{{ details.type }}</p>
                              <p class="subdetail-tag">&nbsp;{{ $t('type') }}</p>
                            </i>
                          </template>
                        </Card>
                      </div>
                      <div class="col-4 p-0">
                        <Card>
                          <template #content>
                            <i class="fa-solid fa-location-dot subdetail-icon">
                              <p class="subdetail-name">&nbsp;{{ details.location.city }}</p>
                              <p class="subdetail-tag">&nbsp;{{ $t('location') }}</p>
                            </i>
                          </template>
                        </Card>
                      </div>
                      <div class="col-4 p-0 dropdown">
                        <Card data-toggle="dropdown">
                          <template #content>
                            <i v-if="details.criticality == 1" class="fa-solid fa-shield-halved subdetail-icon"
                              style="color:#6dc193">
                              <p class="subdetail-name dropdown-toggle" v-if="details.criticality == 1">&nbsp;{{
                                  $t('low')
                              }}</p>
                              <p class="subdetail-tag">&nbsp;{{ $t('criticality') }}</p>
                            </i>
                            <i v-if="details.criticality == 2" class="fa-solid fa-shield-halved subdetail-icon dropdown"
                              style="color:#f7b34c">
                              <p class="subdetail-name dropdown-toggle" v-if="details.criticality == 2">&nbsp;{{
                                  $t('medium')
                              }}</p>
                              <p class="subdetail-tag">&nbsp;{{ $t('criticality') }}</p>
                            </i>
                            <i v-if="details.criticality == 3" class="fa-solid fa-shield-halved subdetail-icon dropdown"
                              style="color:#ef6154">
                              <p class="subdetail-name dropdown-toggle" v-if="details.criticality == 3">&nbsp;{{
                                  $t('high')
                              }}</p>
                              <p class="subdetail-tag">&nbsp;{{ $t('criticality') }}</p>
                            </i>
                            <i v-if="details.criticality == 4" class="fa-solid fa-shield-halved subdetail-icon dropdown"
                              style="color:#b83c32">
                              <p class="subdetail-name dropdown-toggle" v-if="details.criticality == 4">&nbsp;{{
                                  $t('critical')
                              }}
                              </p>
                              <p class="subdetail-tag">&nbsp;{{ $t('criticality') }}</p>
                            </i>
                          </template>
                        </Card>
                        <div class="dropdown-menu">
                          <a class="dropdown-item" v-for="criticality in criticalities" :key="criticality"
                            @click="updateCriticality(details.id, criticality.value)">
                            {{ criticality.label }}
                          </a>
                        </div>
                      </div>
                    </div>
                    <div class="row details-data">
                      <div :class="tab_position ? 'col-12 pr-0 pl-0' : 'col-6 pr-4'">
                        <div class="row">
                          <p class="mt-0 mb-0 details-title"><strong>{{ $t('description') }}</strong></p>
                          <p class="">{{ details.description }}</p>
                        </div>
                        <div class="row">
                          <p class="mb-0 mt-2  details-title"><strong>{{ $t('hostName') }}</strong></p>
                          <p class="mb-0 mb-2">{{ details.hostname }}</p>
                        </div>
                        <div class="row">
                          <p class="mb-0 mt-2 details-title"><strong>{{ $t('tags') }}</strong></p>
                          <div class="tag-cont">
                            <p class="details-tag" v-for="tag in details.tags" :key="tag"
                              style="display:inline-block;">{{ tag }}&nbsp;</p>
                          </div>
                        </div>
                      </div>
                      <div :class="tab_position ? 'col-12 pr-0 pl-0' : 'col-6 pr-4'">
                        <div class="row">
                          <p class="mb-0 mt-2 details-title"><strong>{{ $t('network') }}</strong></p>
                          <div class="ip-cont">
                            <p class="mb-0" style="font-size:small; color: darkgray;"><strong>IPs:&nbsp;</strong>
                            </p>
                            <div class="tag-cont">
                              <p class="details-tag" v-for="ip in details.ip_addr" :key="ip"
                                style="display:inline-block;">{{ ip }}&nbsp;</p>
                            </div>
                          </div>
                          <br>
                          <div class="mac-cont">
                            <p class="mb-0" style="font-size:small; color: darkgray;"><strong>MACs:&nbsp;</strong>
                            </p>
                            <div class="tag-cont mb-2">
                              <p class="details-tag" v-for="mac in details.mac_addr" :key="mac"
                                style="display:inline-block;">{{ mac }}&nbsp;</p>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-6">
                            <p class="mb-0 mt-2 details-title"><strong>{{ $t('createdAt') }}</strong></p>
                            <p class="">{{ formatDate(details.first_date) }}</p>
                          </div>
                          <div class="col-6">
                            <p class="mb-0 mt-2 details-title"><strong>{{ $t('lastActDate') }}</strong></p>
                            <p class="">{{ formatDate(details.last_date) }}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ScrollPanel>
              </TabPanel>
              <!-- ====================================================================== Events =========================================================================== -->
              <TabPanel :header="$t('events')">
                <div class="loader text-center mt-2" style="width:100%;" v-if="loading_details">
                  <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                  <p>{{ $t('loading') }}</p>
                </div>
                <ScrollPanel :style="tab_position ? 'width: 100%;height:32.5em' : 'width: 100%;height:15em'" class="custom" v-else>
                  <div class="row pb-2 pl-1 pr-1 pt-1">
                  <h6 v-if="!details.events" class="text-center mt-4" style="width:100%;">{{ $t('noDataAvailable') }}
                  </h6>
                  <div v-else class="aux pt-2" :style="tab_position ? 'height:55vh;' : 'height:35vh;'">
                    <OpenGroup v-for="(event, index) in details.events" :key="event" :groupName="event.title"
                      :openFirst="index == 0 ? true : false"
                      :new_item="(event.status[event.status.length - 1].status == 'Created' ? true : false)">
                      <template #body>
                        <p class="p-0 m-0 group-title"><strong>{{ $t('ID') }}</strong></p>
                        <p class="p-0 m-0 group-data row-name-link" @click="$router.push({ path: 'event-details', query: { id: event.id } })">{{ event.organization_id }}</p>
                        <p class="p-0 m-0 mt-2 group-title "><strong>{{ $t('description') }}</strong></p>
                        <p class="p-0 m-0 group-data">{{ event.description }}</p>
                        <p class="p-0 m-0 mt-2 group-title "><strong>{{ $t('severity') }}</strong></p>
                        <p v-if="event.severity == 0" class="p-0 m-0 group-data">
                          <i class="pi pi-circle-fill  criticality-filter-Info"
                            style="color:rgb(109, 122, 193); font-size: 0.8em;"></i>
                          <span>&nbsp;{{ $t('informational') }}</span>
                        </p>
                        <p v-if="event.severity == 1" class="p-0 m-0 group-data">
                          <i class="pi pi-circle-fill  criticality-filter-Low"
                            style="color:#6dc193; font-size: 0.8em;"></i>
                          <span>&nbsp;{{ $t('low') }}</span>
                        </p>
                        <p v-if="event.severity == 2" class="p-0 m-0 group-data">
                          <i class="pi pi-circle-fill  criticality-filter-Medium"
                            style="color:#f7b34c; font-size: 0.8em;"></i>
                          <span>&nbsp;{{ $t('medium') }}</span>
                        </p>
                        <p v-if="event.severity == 3" class="p-0 m-0 group-data">
                          <i class="pi pi-circle-fill  criticality-filter-High"
                            style="color:#ef6154; font-size: 0.8em;"></i>
                          <span>&nbsp;{{ $t('high') }}</span>
                        </p>
                        <p v-if="event.severity == 4" class="p-0 m-0 group-data">
                          <i class="pi pi-circle-fill  criticality-filter-Critical"
                            style="color:#b83c32; font-size: 0.8em;"></i>
                          <span>&nbsp;{{ $t('critical') }}</span>
                        </p>
                        <p class="p-0 m-0 mt-2 group-title "><strong>{{ $t('createdAt') }}</strong></p>
                        <p class="p-0 m-0 group-data">{{ formatDate(event.created_at) }}</p>
                        <p class="p-0 m-0 mt-2 group-title "><strong>{{ $t('status') }}</strong></p>
                        <p class="p-0 m-0 group-data">{{ event.status[event.status.length - 1].status }} - <span
                            style="color:darkgray">{{ event.status[event.status.length - 1].description }}</span>
                        </p>
                        <p class="p-0 m-0 group-data"></p>
                      </template>
                    </OpenGroup>
                  </div>
                  </div>
                </ScrollPanel>
              </TabPanel>
              <!-- ====================================================================== Incidents =========================================================================== -->
              <TabPanel :header="$t('incidents')">
                <div class="loader text-center mt-2" style="width:100%;" v-if="loading_details">
                  <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                  <p>{{ $t('loading') }}</p>
                </div>
                <ScrollPanel :style="tab_position ? 'width: 100%;height:32.5em' : 'width: 100%;height:15em'" class="custom" v-else>
                  <div class="row pb-2 pl-1 pr-1 pt-1">
                  <h6 v-if="!details.incidents" class="text-center mt-4" style="width:100%;">{{ $t('noDataAvailable') }}
                  </h6>
                  <div v-else class="aux pt-2" :style="tab_position ? 'height:55vh;' : 'height:35vh;'">
                    <OpenGroup v-for="(incident, index) in details.incidents" :key="incident"
                      :groupName="incident.title" :openFirst="index == 0 ? true : false">
                      <template #body>
                        <p class="p-0 m-0 group-title"><strong>{{ $t('ID') }}</strong></p>
                        <p class="p-0 m-0 group-data row-name-link" @click="$router.push({ path: 'incident-details', query: { id: incident.id } })">{{ incident.id }}</p>
                        <p class="p-0 m-0 mt-2 group-title "><strong>{{ $t('description') }}</strong></p>
                        <p class="p-0 m-0 group-data">{{ incident.description }}</p>
                        <p class="p-0 m-0 mt-2 group-title "><strong>{{ $t('severity') }}</strong></p>
                        <p v-if="incident.severity == 0" class="p-0 m-0 group-data">
                          <i class="pi pi-circle-fill  criticality-filter-Info"
                            style="color:rgb(109, 122, 193); font-size: 0.8em;"></i>
                          <span>&nbsp;{{ $t('informational') }}</span>
                        </p>
                        <p v-if="incident.severity == 1" class="p-0 m-0 group-data">
                          <i class="pi pi-circle-fill  criticality-filter-Low"
                            style="color:#6dc193; font-size: 0.8em;"></i>
                          <span>&nbsp;{{ $t('low') }}</span>
                        </p>
                        <p v-if="incident.severity == 2" class="p-0 m-0 group-data">
                          <i class="pi pi-circle-fill  criticality-filter-Medium"
                            style="color:#f7b34c; font-size: 0.8em;"></i>
                          <span>&nbsp;{{ $t('medium') }}</span>
                        </p>
                        <p v-if="incident.severity == 3" class="p-0 m-0 group-data">
                          <i class="pi pi-circle-fill  criticality-filter-High"
                            style="color:#ef6154; font-size: 0.8em;"></i>
                          <span>&nbsp;{{ $t('high') }}</span>
                        </p>
                        <p v-if="incident.severity == 4" class="p-0 m-0 group-data">
                          <i class="pi pi-circle-fill  criticality-filter-Critical"
                            style="color:#b83c32; font-size: 0.8em;"></i>
                          <span>&nbsp;{{ $t('critical') }}</span>
                        </p>
                        <p class="p-0 m-0 mt-2 group-title "><strong>{{ $t('createdAt') }}</strong></p>
                        <p class="p-0 m-0 group-data">{{ formatDate(incident.created_at) }}</p>
                        <p class="p-0 m-0 mt-2 group-title "><strong>{{ $t('status') }}</strong></p>
                        <p class="p-0 m-0 group-data">{{ incident.status[incident.status.length - 1].status }} -
                          <span style="color:darkgray">{{ incident.status[incident.status.length - 1].description
                          }}</span>
                        </p>
                        <p class="p-0 m-0 group-data"></p>
                      </template>
                    </OpenGroup>
                  </div>
                  </div>
                </ScrollPanel>
              </TabPanel>
              <!-- ====================================================================== Data sources =========================================================================== -->
              <TabPanel :header="$t('dataSources')">
                <div class="loader text-center mt-2" style="width:100%;" v-if="loading_details">
                  <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                  <p>{{ $t('loading') }}</p>
                </div>
                <ScrollPanel :style="tab_position ? 'width: 100%;height:32.5em' : 'width: 100%;height:15em'" class="custom" v-else>
                  <div class="row pb-2 pl-1 pr-1 pt-1">
                  <h6 v-if="!details.sources" class="text-center mt-4" style="width:100%;">{{ $t('noDataAvailable') }}
                  </h6>
                  <div v-else class="aux pt-2" :style="tab_position ? 'height:55vh;' : 'height:35vh;'">
                    <OpenGroup v-for="(source, index) in details.sources" :key="source" :groupName="source.name"
                      :openFirst="index == 0 ? true : false">
                      <template #body>
                        <p class="p-0 m-0 group-title"><strong>{{ $t('ID') }}</strong></p>
                        <p class="p-0 m-0 group-data row-name-link" @click="$router.push({ path: 'source-details', query: { id: source.id } })">{{ source.id }}</p>
                        <p class="p-0 m-0 mt-2 group-title "><strong>{{ $t('description') }}</strong></p>
                        <p class="p-0 m-0 group-data">{{ source.description }}</p>
                      </template>
                    </OpenGroup>
                  </div>
                  </div>
                </ScrollPanel>
              </TabPanel>
              <!-- ====================================================================== Groups =========================================================================== -->
              <TabPanel :header="$t('groups')">
                <div class="loader text-center mt-2" style="width:100%;" v-if="loading_details">
                  <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                  <p>{{ $t('loading') }}</p>
                </div>
                <ScrollPanel :style="tab_position ? 'width: 100%;height:32.5em' : 'width: 100%;height:15em'" class="custom" v-else>
                  <div class="row pb-2 pl-1 pr-1 pt-1">
                  <h6 v-if="!details.asset_groups" class="text-center mt-4" style="width:100%;">{{ $t('noDataAvailable') }}
                  </h6>
                  <div v-else class="aux pt-2" :style="tab_position ? 'height:55vh;' : 'height:35vh;'">
                    <OpenGroup v-for="(group, index) in details.asset_groups" :key="group" :groupName="group.name"
                      :openFirst="index == 0 ? true : false">
                      <template #body>
                        <p class="p-0 m-0 group-title"><strong>{{ $t('ID') }}</strong></p>
                        <p class="p-0 m-0 group-data row-name-link"
                          @click="filterByGroup([group], true)">{{
                              group.organization_id
                          }}</p>
                        <p class="p-0 m-0 mt-2 group-title "><strong>{{ $t('description') }}</strong></p>
                        <p class="p-0 m-0 group-data">{{ group.description }}</p>
                        <p class="p-0 m-0 mt-2 group-title "><strong>{{ $t('tags') }}</strong></p>
                        <div class="tag-cont p-0">
                          <p class="details-tag" v-for="tag in group.tags" :key="tag" style="display:inline-block;">
                            {{ tag }}&nbsp;</p>
                        </div>
                        <p class="p-0 m-0 mt-2 group-title "><strong>{{ $t('organizationId') }}</strong></p>
                        <p class="p-0 m-0 group-data">{{ group.organization_id }}</p>
                      </template>
                    </OpenGroup>
                  </div>
                  </div>
                </ScrollPanel>
              </TabPanel>
              <!-- ====================================================================== Alerts =========================================================================== -->
              <TabPanel :header="$t('alertConfiguration')">
                <div class="loader text-center mt-2" style="width:100%;" v-if="loading_details">
                  <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                  <p>{{ $t('loading') }}</p>
                </div>
                <ScrollPanel :style="tab_position ? 'width: 100%;height:32.5em' : 'width: 100%;height:15em'" class="custom" v-else>
                  <div class="row pb-2 pl-1 pr-1 pt-1">
                  <h6 v-if="!details.alerts || details.alerts.length == 0" class="text-center mt-4" style="width:100%;">{{ $t('noDataAvailable') }}
                  </h6>
                  <div v-else class="aux pt-2" :style="tab_position ? 'height:55vh;' : 'height:35vh;'">
                    <OpenGroup v-for="(alert, index) in details.alerts" :key="alert" :groupName="alert.name"
                      :openFirst="index == 0 ? true : false">
                      <template #body>
                        <p class="p-0 m-0 group-title"><strong>{{ $t('ID') }}</strong></p>
                        <p class="p-0 m-0 group-data row-name-link" @click="$router.push({ path: 'alert-details', query: { id: alert.id } })">{{ alert.id }}</p>
                        <p class="p-0 m-0 mt-2 group-title "><strong>{{ $t('description') }}</strong></p>
                        <p class="p-0 m-0 group-data">{{ alert.description }}</p>
                        <p class="p-0 m-0 mt-2 group-title "><strong>{{ $t('filters') }}</strong></p>
                        <div class="tag-cont p-0">
                          <p class="details-tag" v-for="filter in alert.filters" :key="filter"
                            style="display:inline-block;">
                            {{ filter }}&nbsp;</p>
                        </div>
                        <p class="p-0 m-0 mt-2 group-title "><strong>{{ $t('minimumSeverity') }}</strong></p>
                        <p v-if="alert.severity == 0" class="p-0 m-0 group-data">
                          <i class="pi pi-circle-fill  criticality-filter-Info"
                            style="color:rgb(109, 122, 193); font-size: 0.8em;"></i>
                          <span>&nbsp;{{ $t('informational') }}</span>
                        </p>
                        <p v-if="alert.severity == 1" class="p-0 m-0 group-data">
                          <i class="pi pi-circle-fill  criticality-filter-Low"
                            style="color:#6dc193; font-size: 0.8em;"></i>
                          <span>&nbsp;{{ $t('low') }}</span>
                        </p>
                        <p v-if="alert.severity == 2" class="p-0 m-0 group-data">
                          <i class="pi pi-circle-fill  criticality-filter-Medium"
                            style="color:#f7b34c; font-size: 0.8em;"></i>
                          <span>&nbsp;{{ $t('medium') }}</span>
                        </p>
                        <p v-if="alert.severity == 3" class="p-0 m-0 group-data">
                          <i class="pi pi-circle-fill  criticality-filter-High"
                            style="color:#ef6154; font-size: 0.8em;"></i>
                          <span>&nbsp;{{ $t('high') }}</span>
                        </p>
                        <p v-if="alert.severity == 4" class="p-0 m-0 group-data">
                          <i class="pi pi-circle-fill  criticality-filter-Critical"
                            style="color:#b83c32; font-size: 0.8em;"></i>
                          <span>&nbsp;{{ $t('critical') }}</span>
                        </p>
                      </template>
                    </OpenGroup>
                  </div>
                  </div>
                </ScrollPanel>
              </TabPanel>
            </TabView>
          </div>
        </div>
      </div>
      <!-- ==================================================================================================================================================== -->
      <!-- ===================================================================== Dashboard ==================================================================== -->
      <!-- ==================================================================================================================================================== -->
      <div class="row" v-if="viewmode == 1" style="width:100%">
        <div class="col-12  mt-2 pr-0">
          <div class="row" style="margin-right:-1.5em;">
            <div class="col-4 pt-0">
              <div class="card p-2">
                <h5>{{ $t('IncidentsStatusByCreationTime') }}</h5>
                <Chart type="bar" :data="stackedData" :options="stackedOptions" />
              </div>
            </div>
            <div class="col-8 pt-0" >
              <div class="card p-2" style="height: 297px;">
                <h5>{{ $t('IncidentsStatus') }}</h5>
                <CustomChart :data="data"></CustomChart>
                <hr class="mt-4">
                <div class="row">
                  <div class="col-4">
                    <h5 class="mb-0" style="text-align:left; color:gray;">{{$t('meanTimeToAcknowledge')}}</h5>
                    <number style="font-size: 4em; font-weight:700" ref="users_number" :from="0" :to="5.2"
                      :duration="2" easing="Power1.easeOut" class="ml-2" :format="theFormat" />
                      <h3 class="ml-2" style="display:inline-block">{{ $t('days') }}</h3>
                    </div>
                  <div class="col-4">
                    <h5 class="mb-0" style="text-align:left; color:gray;">{{$t('meanTimeToClose')}}</h5>
                    <number style="font-size: 4em; font-weight:700" ref="users_number" :from="0" :to="22.32"
                      :duration="2" easing="Power1.easeOut" class="ml-2" :format="theFormat"/>
                      <h3 class="ml-2" style="display:inline-block">{{ $t('min') }}</h3>
                    </div>
                  <div class="col-4">
                    <h5 class="mb-0" style="text-align:left; color:gray;">Mean time to acknowledge</h5>
                    <number style="font-size: 4em; font-weight:700" ref="users_number" :from="0" :to="12"
                      :duration="2" easing="Power1.easeOut" class="ml-2" />
                    <h3 class="ml-2" style="display:inline-block">{{ $t('days') }}</h3>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="card p-2">
                <h5>{{ $t('IncidentsStatus') }}</h5>
                <Chart type="line" :data="lineChartData" :options="lineChartOptions" height="250" width="750"/>
              </div>
            </div>
            <div class="col-6">
              <div class="card p-2">
                <h5>{{ $t('IncidentsStatus') }}</h5>
                <Chart type="bar" :data="barData" :options="lineChartOptions" height="250" width="750"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--============================================================================== createData ==============================================================================-->
  <Modal ref="createData">
    <template #body>
      <div class="row">
        <div class="col-12 text-center pt-4">
          <i class="pi pi-th-large" style="font-size:2.5em;"></i>
        </div>
        <div class="col-12 text-center pt-0 pb-0">
          <h5 class="modal-title">{{ $t('createNewAsset') }}</h5>
        </div>
      </div>
      <div class="row mt-4">
        <Steps :model="items" :readonly="false" class="mt-4">
          <template #item="{item}">
              <a :style="(item.step == step)?'color:#ec635f; font-weight: 700; cursor:pointer;':'color:black; cursor:pointer;'" @click="step = item.step">{{item.label}}</a>
          </template>
        </Steps>
      </div>
      <div class="row" v-if="step == 0">
        <div class="col-12 p-4">
          <h6 class="pb-0 mb-0 mt-2"><strong>{{ $t('general') }}</strong></h6>
          <hr class="mt-0 mb-0">
          <label for="" class="pl-0 pb-0 mb-0 modal-label">{{ $t('name') }}</label>
          <InputText type="text" v-model="new_name" :placeholder="$t('name')"
            style="width:100%;">
          </InputText>
          <label for="" class="pl-0 pb-0 mb-0 mt-1 modal-label">{{ $t('description') }}</label>
          <Textarea type="text" v-model="new_description" :placeholder="$t('description')"
            style="width:100%;">
          </Textarea>
          <h6 class="pb-0 mb-0 mt-2"><strong>{{ $t('details') }}</strong></h6>
          <hr class="mt-0 mb-0">
          <label for="" class="pl-0 pb-0 mb-0 modal-label">{{ $t('hostname') }}</label>
          <InputText type="text" v-model="new_hostname" :placeholder="$t('hostname')"
            style="width:100%;">
          </InputText>
          <label for="" class="pl-0 pb-0 mb-0 mt-1 modal-label">{{ $t('type') }}</label>
          <InputText type="text" v-model="new_type" :placeholder="$t('type')"
            style="width:100%;">
          </InputText>
          <label for="" class="pl-0 pb-0 mb-0 mt-1 modal-label">{{ $t('location') }}</label>
          <InputText type="text" v-model="new_location" :placeholder="$t('location')"
            style="width:100%;">
          </InputText>
          <label for="" class="pl-0 pb-0 mb-0 mt-1 modal-label">{{ $t('tags') }}</label>
          <Chips v-model="new_tags" :disabled="locked" style="width:100%;" />
          <h6 class="pb-0 mb-0 mt-2"><strong>{{ $t('network') }}</strong></h6>
          <hr class="mt-0 mb-0">
          <label for="" class="pl-0 pb-0 mb-0 mt-1 modal-label">{{ $t('IPs') }}</label>
          <Chips v-model="new_ips" :disabled="locked" style="width:100%;" />
          <label for="" class="pl-0 pb-0 mb-0 mt-1 modal-label">{{ $t('MACs') }}</label>
          <Chips v-model="new_macs" :disabled="locked" style="width:100%;" />
        </div>
      </div>
      <div class="row" v-if="step == 1">
        <div class="col-12 p-4">

        </div>
      </div>
      <div class="row" v-if="step == 2">
        <div class="col-12 p-4">

        </div>
      </div>
      <div class="row" v-if="step == 3">
        <div class="col-12 p-4">

        </div>
      </div>
      <div class="row" v-if="step == 4">
        <div class="col-12 p-4">

        </div>
      </div>

    </template>
    <template #footer>
      <Button v-if="step != 0" class="btn-custom-save" :label="$t('previous')" @click="step = step-1"></Button>
      <Button v-if="step != 4" class="btn-custom-save" :label="$t('next')" @click="step = step+1"></Button>
      <Button v-if="step == 4" class="btn-custom" :label="$t('save')" @click="addNewIntegration(new_data_source_name,new_data_source_description)"></Button>
    </template>
  </Modal>
</div>
</template>
<!----------------------------------------------->
<!--                    SCRIPT                 -->
<!----------------------------------------------->

<script>
import MyShared from "./shared";
export default {
mixins: [MyShared],
};

</script>

<script setup>
import { useMq } from "vue3-mq";
const mq = useMq();
</script>

<!----------------------------------------------->
<!--                    STYLES                 -->
<!----------------------------------------------->
<style>
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

.main-table {
box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
border-radius: 4px;
background-color: white;
}

.input-icon {
font-size: small !important;
margin-top: -10px !important;
}

.search-input {
width: 25em !important;
}

.icon-flipped {
transform: scaleX(-1);
-moz-transform: scaleX(-1);
-webkit-transform: scaleX(-1);
-ms-transform: scaleX(-1);
}

.change-pos-div {
width: 100%;
}

td {
font-family: 'Open Sans', sans-serif !important;
}

a {
font-family: 'Open Sans', sans-serif !important;
font-size: 0.9em;
}

.dropdown-menu {
position: absolute !important;
margin-right: 5em;
}

.detection-card {
box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
padding: 0.25em;
padding-left: 0.75em !important;
margin-right: 0em !important;
}
.tab-detection-card {
box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
padding: 0.25em;
padding-left: 0em !important;
margin-left: 0.75em !important;
margin-right: 0em !important;
}
.p-tabview-nav-content {
padding-bottom: 0.5em !important;
}

/* Group tab */
.p-tabview .p-tabview-panels{
background-color: rgba(0, 0, 0, 0) !important;
}
.p-tabview .p-component{
background-color: rgba(0, 0, 0, 0) !important;
}
.p-tabview-panels {
padding-right: 0 !important;
padding-left: 0 !important;
padding-top: 0 !important;
;
}

.group-title {
font-size: 0.8em;
font-weight: normal;
text-transform: uppercase;
color: #6c757d;
}

.group-data {
font-size: 0.8em;
font-weight: normal;
}

/* Table name row link*/
.row-name-link {
color: #ef6154;
cursor: pointer;
}

.row-name-link:hover {
color: #ef6154;
text-decoration: underline;
}

/* Top button config */
.top-config {
margin-top: -3em !important;
padding-right: 1.5em !important;
z-index: 2 !important;
}

.top-config-label {
font-family: 'Open Sans', sans-serif !important;
color: #6c757d !important;
}

.top-config-icon {
cursor: pointer;
color: #ec635f !important;
margin-right: 1em;
float: right;
}

/* Criticality filter*/
.criticality-filter-Info {
color: #aeb5c1;
font-size: 0.7em;
}

.criticality-filter-Low {
color: #6dc193;
font-size: 0.7em;
}

.criticality-filter-Medium {
color: #f7b34c;
font-size: 0.7em;
}

.criticality-filter-High {
color: #ef6154;
font-size: 0.7em;
}

.criticality-filter-Critical {
color: #b83c32;
font-size: 0.7em;
}

/* Top Chart*/
.percentage-bar {
width: 80%;
height: auto;
border-radius: 3px;
margin-top: 0.5em;
margin-bottom: 0.5em;
margin-top: 0;
font-size: var(--low-perc);
}

.percentage-portion {
border-radius: 3px;
margin-top: 0.5em;
margin-bottom: 0.5em;
font-size: 0.5em;
}

/* Details tabs */
.tab-main-container {}

.card-details {
width: 100%;
height: 100%;
padding: 0.5em;
margin-top: 1em;
}

.details-tag {
margin-top: 0.5em;
margin-right: 0.5em;
margin-bottom: 0;
padding: 0.05em 0.5em;
border-radius: 500px;
background: #ec635f !important;
font-size: 0.8em;
color: white;
text-align: center;
}

.details-id {
color: #ec635f;
cursor: pointer;
}

.details-id:hover {
color: #ec635f;
text-decoration: underline;
}

.detail-tab {
padding-left: 0 !important;
padding-right: 0 !important;
padding-top: 0 !important;
}

.details-top {
padding-left: 0 !important;
padding-right: 0 !important;
margin-bottom: 1em;
box-shadow: 0 2px 0px 0px rgba(0, 0, 0, 0.2), 0 1px 0px 0 rgba(0, 0, 0, 0.14), 0 1px 0px 0 rgba(0, 0, 0, 0.12);
}


.details-title {
text-transform: uppercase;
font-size: 0.8em;
color: #6e6e6e;
margin-top: 0.5rem !important;
}

.detail-icon {
font-size: 2em;

color: #333333;
padding-left: 0 !important
}

.detail-name {
padding-left: 0 !important;
font-size: 0.65em;
color: #333333;
font-family: 'Open Sans', sans-serif !important;
}

.subdetail-icon {
margin: 0.5em;
font-size: 1.5em !important;
color: #333333;
}

.subdetail-name {
margin-left: 2em;
margin-top: -30px !important;
color: #333333;
font-family: 'Open Sans', sans-serif !important;
font-size: small;
font-weight: bold;
margin-bottom: 0;
}

.subdetail-tag {
margin-left: 2em;
margin-bottom: 0;
color: #333333;
font-family: 'Open Sans', sans-serif !important;
font-size: small;
font-weight: normal;
}

/* Top tab */
.top-tab-text {
font-size: 3em;
color: black;
font-weight: 600;
margin-left: 0.25em;
}

.top-tab-subtext {
font-size: 0.8em;
margin-top: -10px !important;
}

.top-tab-icon {
font-size: 2em;
color: #ec635f;
}

.top-tab-subicon {
font-size: 0.5em !important;
color: green;
}

.top-tab-subicon-text {
font-family: 'Open Sans', sans-serif !important;
color: #000;
}

/* Custom input */
.p-inputtext {
padding: 0.15em !important;
padding-left: 0.5em !important;
}

.p-inputtext:focus {
border-color: #ec635f !important;
box-shadow: 0px 0px 2px #ec635f !important;
outline: none;
}

.p-inputtext:hover {
border-color: #ec635f !important;
}

/* Custom multiselect */
.p-multiselect:focus {
border-color: #ec635f !important;
box-shadow: 0px 0px 2px #ec635f !important;
}

.p-multiselect:hover {
border-color: #ec635f !important;
}

.p-multiselect .p-multiselect-label.p-placeholder {
color: #b7bcbf !important;
padding: 0.1em 0.25em !important;
}

.p-multiselect.p-multiselect-chip .p-multiselect-token {
padding: 0.05rem 0.5rem !important;
margin-right: 0.5rem !important;
background: #ec635f !important;
color: white !important;
border-radius: 16px !important;
font-size: 0.8em !important;
margin-top: 0.25em !important;
margin-bottom: 0.25em !important;
margin-left: 0.1em !important;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
color: #495057;
background: #ffcdcb83 !important;
padding: 0.25em 0.5em !important;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item {
padding: 0.25em 0.5em !important;
}

.p-multiselect-panel .p-multiselect-header {
padding: 0.25em 0.5em !important;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
box-shadow: 0 0 0 0.2rem #ffcdcb83 !important;
border-color: #ec635f !important;
}

.p-checkbox .p-checkbox-box.p-highlight {
border-color: #ec635f !important;
background: #ec635f !important;
}

.p-checkbox:hover {
border-color: #ec635f !important;
}

/* Custom card */
.p-card .p-card-body {
padding: 0 !important;
}

.p-card .p-card-content {
padding: 0 !important;
}

/* Custom data table */
.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
background: #ffffff;
border-color: #ec635f !important;
color: #ec635f !important;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link {
padding: 0.5em !important;
}

.p-datatable .p-sortable-column.p-highlight {
color: #ec635f !important;
}

.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
color: #ec635f !important;
}

.p-datatable .p-datatable-thead>tr>th {
padding: 0.25em !important
}

.p-datatable .p-datatable-tbody>tr>td {
padding: 0.25em !important;
}

.p-datatable .p-datatable-tbody>tr.p-highlight {
background: #86b5bb4f !important;
color: #495057 !important;
}

.p-column-filter-menu-button.p-column-filter-menu-button-active,
.p-column-filter-menu-button.p-column-filter-menu-button-active:hover {
background: #86b5bb4f !important;
;
color: white;
}

.p-paginator {
padding: 0 !important;
}

.p-paginator .p-paginator-first,
.p-paginator .p-paginator-prev,
.p-paginator .p-paginator-next,
.p-paginator .p-paginator-last {
min-width: 1.25rem !important;
height: 1.25rem !important;
;
}
.p-paginator-bottom{
border-bottom-width: 0px !important;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
background: #ffffff !important;
border-color: #ffffff !important;
font-weight: 800;
}
.p-button.p-button-text{
color: white !important;
}

/* Custom buttons */
.btn-custom {
margin: auto;
margin-top: 0.5rem;
margin-bottom: 0.5rem;
background: #ec635f !important;
color: white;
border: none !important;
padding: 0.25rem 0.5rem !important;
font-weight: normal !important;
width: auto !important;
font-size: small !important;
}

.btn-custom:focus {
box-shadow: 0px 0px 2px #ec635f !important;
}

.btn-custom:hover {
background: #bd4f4b !important;
}

.p-inputtext:focus {
border-color: #ec635f !important;
box-shadow: 0px 0px 2px #ec635f !important;
outline: none;
}
.p-toast .p-toast-message .p-toast-message-content{
padding: 0.5em !important;
}
.dropdown-toggle{
cursor: pointer;
}
.dropdown-item{
cursor: pointer;
}
.modal-label{
font-weight: 600;
font-size: 0.8em;
}

.p-scrollpanel-wrapper{
padding-bottom: 1em !important;
}

.p-toast {
opacity: 1 !important;
font-size: 0.8em !important;
width: 20rem !important;
}
</style>
