<!----------------------------------------------->
<!--                  TEMPLATE                 -->
<!----------------------------------------------->
<template>
  <div class="aux">
    <Sitebar></Sitebar>
    <div class="maincontainer" >
      <ConfirmDialog></ConfirmDialog>
      <div class="row main-table mb-2 ml-0">
        <div class="col-2 pt-0">
          <label for="">{{ $t('selectModel') }}</label>
          <div class="p-inputgroup flex-1">
            <span class="p-inputgroup-addon">
              <i class="pi pi-list"></i>
            </span>
            <Dropdown style="width: 100%;" v-model="selectedSource" :options="sources"
              @change="refresh_default_selected = true; refreshData(selectedSource, dates_range[0], dates_range[1], filter, 0, 12)" />
          </div>
        </div>
        <div class="col-3 pt-0">
          <label for="">{{ $t('selectRangeOfDates') }}</label>
          <div class="p-inputgroup flex-1">
            <span class="p-inputgroup-addon">
              <i class="pi pi-calendar"></i>
            </span>
            <Calendar v-model="dates_range" selectionMode="range" :placeholder="$t('datesRange')" :manualInput="false"
              style="width: 100%;" dateFormat="dd/mm/yy" showTime hourFormat="24" showButtonBar
              @update:modelValue="refresh_default_selected = true; refreshData(selectedSource, dates_range[0], dates_range[1], filter, 0, 12)" />
          </div>
        </div>
        <div class="col-4 pt-0">
          <label for="">{{ $t('addAFilter') }}</label>
          <div class="p-inputgroup flex-1">
            <span class="p-inputgroup-addon">
              <i class="pi pi-search"></i>
            </span>
            <InputText v-model="filter" placeholder="Filter" style="width: 100%;" />
          </div>
        </div>
        <div class="col-2 pt-0">
          <label for="">{{ $t('selectChartInterval') }}</label>
          <div class="p-inputgroup flex-1">
            <span class="p-inputgroup-addon">
              <i class="pi pi-chart-bar"></i>
            </span>
            <Dropdown style="width: 100%;" v-model="selectedInterval" :options="intervals"
              @change="refreshChart(selectedSource, dates_range[0], dates_range[1], filter)" />
          </div>
        </div>
        <div class="col-1 pt-4 text-center">
          <label for=""> &nbsp;</label>
          <Button :label="$t('refresh')" icon="pi pi-refresh" class="p-button-success" style="width: 100%;"
            @click="refreshData(selectedSource, dates_range[0], dates_range[1], filter, 0, 12)" />
        </div>
      </div>
      <div class="row ml-0">
        <div class="col-2 pt-0 pb-0 pl-0 pr-2">
          <div class="pad-helper main-table m-0 p-2" style="height:41.5em;">
            <InputText v-model="search_in_fields" placeholder="Search" style="width: 100%;" />
            <label class="mt-2 mb-0"><i :class="(selectedOpen) ? 'pi pi-chevron-up' : 'pi pi-chevron-down'"
                @click="selectedOpen = !selectedOpen" style="font-size: smaller;"></i>&nbsp;{{ $t('selectedFields')
                }}</label>
            <hr class="mt-0 mb-2">
            <span v-if="selectedOpen && selectedCount == 0"><small>{{ $t('noneFieldSelected') }}</small></span>
            <ScrollPanel style="width: 100%; height: 16.5em;" v-if="selectedOpen">
              <div v-for="field of filter_fields" :key="field" class="flex align-items-center">
                <span v-if="field.selected && (field.name.includes(search_in_fields) || search_in_fields == '')"
                  class="selectable-field">
                  <!-- <Checkbox v-model="field.selected" :inputId="field" name="category" :value="field.selected" />-->
                  <label class="mb-1 mt-1 ml-1" :for="field"
                    @click="field.selected = !field.selected; checkSelectedNumber()" style="cursor:pointer;">
                    <Avatar :icon="'pi ' + checkType(field.name)"
                      :style="'height: 1.5em; width: 1.5em; font-size: smaller; background-color: ' + setColor(field.name)[0] + '; border: ' + setColor(field.name)[2] + ' 1px solid; color: ' + setColor(field.name)[1] + ';'" />
                    &nbsp;{{ '- ' + field.name }}
                  </label>
                </span>
              </div>
            </ScrollPanel>

            <label class="mt-2 mb-0"><i :class="(avaliableOpen) ? 'pi pi-chevron-up' : 'pi pi-chevron-down'"
                @click="avaliableOpen = !avaliableOpen" style="font-size: smaller;"></i>&nbsp;{{ $t('avaliableFields')
                }}</label>
            <hr class="mt-0 mb-2">
            <span v-if="avaliableOpen && avaliableCount == 0"><small>{{ $t('noneFieldAvaliable') }}</small></span>
            <ScrollPanel style="width: 100%; height: 16.5em;" v-if="avaliableOpen">
              <div v-for="field of filter_fields" :key="field" class="flex align-items-center">
                <span v-if="!field.selected && (field.name.includes(search_in_fields) || search_in_fields == '')"
                  class="selectable-field">
                  <!-- <Checkbox v-model="field.selected" :inputId="field" name="category" :value="field.selected" />-->
                  <label class="mb-1 mt-1 ml-1" :for="field"
                    @click="field.selected = !field.selected; checkSelectedNumber()" style="cursor:pointer;">
                    <Avatar :icon="'pi ' + checkType(field.name)"
                      :style="'height: 1.5em; width: 1.5em; font-size: smaller; background-color: ' + setColor(field.name)[0] + '; border: ' + setColor(field.name)[2] + ' 1px solid; color: ' + setColor(field.name)[1] + ';'" />
                    &nbsp;{{ '+ ' + field.name }}
                  </label>
                </span>
              </div>
            </ScrollPanel>
          </div>
        </div>
        <div class="col-10 p-0">
          <div class="pad-helper main-table m-0 p-2">
            <ContentLoader v-if="loading_chart" width="100%" height="8em" />
            <Chart v-else type="bar" :data="chartData" :options="chartOptions" />
          </div>
          <div class="pad-helper main-table mt-2 p-2">
            <DataTable :value="data" :paginator="false" class="p-datatable-customers" :rows="12" :row-hover="true"
              dataKey="_id" filter-display="menu" :loading="loading_table" selectionMode="single"
              paginator-template="PrevPageLink PageLinks NextPageLink CurrentPageReport"
              :rows-per-page-options="[10, 25, 50]"
              current-page-report-template="Showing {first} to {last} of {totalRecords} entries"
              responsive-layout="scroll" @row-click="setSelectedData($event.data); $refs.details.openModal()">

              <template #empty>
                {{ $t('noDataFound') }}
              </template>
              <template #loading>
                {{ $t('loadingData') }}
              </template>
              <Column v-for="field in filterFields()" :key="field.name" :field="field.name" :header="field.name"
                :style="(field.name.includes('id') && !field.name.includes('pid') && !field.name.includes('uid')) ? 'min-width: 20.5em;' : 'min-width: 15em;'">
                <template #body="{ index }">
                  <span v-if="(typeof (getValueFromData(field.name, index)) != 'object')">
                    <span v-if="field.name.includes('date')">{{ timestampToDate(getValueFromData(field.name, index))
                    }}</span>
                    <span v-else>{{ getValueFromData(field.name, index) }}</span>
                  </span>
                  <span v-else>
                    <span v-if="!(expanded[field.name])[index]">
                      • {{ getValueFromData(field.name, index)[0] }} <span class="row-name-link"
                        @click="(expanded[field.name])[index] = true">{{ 'more...' }}</span>
                    </span>
                    <span v-else v-for="item in getValueFromData(field.name, index)" :key="item">
                      • {{ item }} <br>
                    </span>
                    <span v-if="(expanded[field.name])[index]" class="row-name-link"
                      @click="(expanded[field.name])[index] = false">{{ 'less...' }}</span>
                  </span>
                </template>
              </Column>
            </DataTable>
            <div class="col-12 pt-1 text-center">
              <Button v-if="actual_page>1" class="table-control-btn" label="<" @click="gotoPage(selectedSource, dates_range[0], dates_range[1], filter, actual_page-1)"></Button>

              <span class="p-paginator-pages">
                <span class="table-page" v-if="actual_page - 2 >= 1" @click="gotoPage(selectedSource, dates_range[0], dates_range[1], filter, actual_page - 2)">{{ actual_page - 2 }}</span>
                <span class="table-page" v-if="actual_page - 1 >= 1" @click="gotoPage(selectedSource, dates_range[0], dates_range[1], filter, actual_page - 1)">{{ actual_page - 1 }}</span>
                <span class="table-page actual-page" >{{ actual_page }}</span>
                <span class="table-page" v-if="actual_page + 1 <= total_pages" @click="gotoPage(selectedSource, dates_range[0], dates_range[1], filter, actual_page + 1)">{{ actual_page + 1 }}</span>
                <span class="table-page" v-if="actual_page + 2 <= total_pages-1" @click="gotoPage(selectedSource, dates_range[0], dates_range[1], filter, actual_page + 2)">{{ actual_page + 2 }}</span>
              </span>

              <Button v-if="actual_page<total_pages"  class="table-control-btn" label=">" @click="gotoPage(selectedSource, dates_range[0], dates_range[1], filter, actual_page+1)"></Button>

              <Dropdown v-model="selectedPage" :options="pageOptions" @change="gotoPage(selectedSource, dates_range[0], dates_range[1], filter, selectedPage)"></Dropdown>
              Showing from {{ offset }} to {{ offset + limit }} of total {{ total_data }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--============================================================================== Details ==============================================================================-->
    <Modal ref="details">
      <template #header>
      </template>
      <template #body>
        <div class="row">
          <div class="col-12 text-center pt-4">
            <i class="pi pi-file" style="font-size:2.5em;"></i>
          </div>
          <div class="col-12 text-center pt-0 pb-0">
            <h5 class="modal-title">{{ details.id }}</h5>
          </div>
        </div>
        <div class="row">
          <div class="col-6 pt-0 pb-0" v-for="field in filter_fields_backup" :key="field">
            <p class="mt-2"><strong>{{ (field.name).toUpperCase() }}</strong></p>
            <hr class="m-0">
            <p class="pt-0" v-if="(typeof (getValueFromDataDetails(field.name)) != 'object')">
              {{ getValueFromDataDetails(field.name) }}</p>
            <span v-else>
              <span v-if="!(expanded[field.name])[index]">
                • {{ getValueFromDataDetails(field.name)[0] }} <span class="row-name-link"
                  @click="(expanded[field.name])[index] = true">{{ 'more...' }}</span>
              </span>
              <span v-else v-for="item in getValueFromDataDetails(field.name)" :key="item">
                • {{ item }} <br>
              </span>
              <span v-if="(expanded[field.name])[index]" class="row-name-link"
                @click="(expanded[field.name])[index] = false">{{ 'less...' }}</span>
            </span>
          </div>
        </div>
      </template>
      <template #footer>
        <div class="row">
          <div class="col-12 text-center">
            <Button label="Close" icon="pi pi-times" class="p-button-secondary" @click="$refs.details.closeModal()" />
          </div>
        </div>
      </template>
    </Modal>
  </div>
</template>
<!----------------------------------------------->
<!--                    SCRIPT                 -->
<!----------------------------------------------->

<script>
import MyShared from "./shared";
export default {
  mixins: [MyShared],
};

</script>

<script setup>
import { useMq } from "vue3-mq";
const mq = useMq();
</script>

<!----------------------------------------------->
<!--                    STYLES                 -->
<!----------------------------------------------->
<style scoped>

.color-setter {
  background-color: #e2adad;
  border: #d38888 1px solid;
  color: #b15c5c;
}

.table-control-btn {
  background-color: white !important;
  color: black;
  font-size: large !important;
}
.table-control-btn:hover {
  background-color: white !important;
  color: black;
}
.table-page{
  background-color: transparent;
  border: 0 none;
  color: #6c757d;
  min-width: 2.357rem;
  height: 2.357rem;
  margin: 0.143rem;
  transition: box-shadow 0.2s;
  border-radius: 3px;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  cursor: pointer;
}
.actual-page{
  text-decoration: underline;
  font-weight: bold;
}
</style>
