import $axios from '../utils';


export function getAllIncidents(full = true, sdate = '1975-01-01T00:00:00', edate = '3000-01-01T00:00:00', offset = 0, limit = 10000) {
    return $axios.get(`soar/incident/?full=${full}&offset=${offset}&limit=${limit}&sdate=${sdate}&edate=${edate}`)
        .then(response => response.data)
}
export function getIncidentsBy(key, value, full, offset, limit) {
    return $axios.get(`soar/incident/by?key${key}&value=${value}&full=${full}&offset=${offset}&limit=${limit}`)
        .then(response => response.data)
}
export function getOneIncident(id, full) {
    return $axios.get(`soar/incident/${id}?full=${full}`)
        .then(response => response.data)
}
export function createIncident(data) {
    return $axios.post('soar/incident/', data)
        .then(response => response.data)
}
export function updateIncident(id, data) {
    return $axios.put(`soar/incident/${id}`, data)
        .then(response => response.data)
}
export function deleteAllIncidents() {
    return $axios.delete('soar/incident/')
        .then(response => response.data)
}
export function deleteIncident(id) {
    return $axios.delete(`soar/incident/${id}`)
        .then(response => response.data)
}