<!----------------------------------------------->
<!--                  TEMPLATE                 -->
<!----------------------------------------------->
<template>
  <MobileIncidents v-if="  mq.current  === 'mobile'"></MobileIncidents>
  <TabletIncidents v-if="  mq.current  === 'tablet'"></TabletIncidents>
  <DesktopIncidents v-if=" mq.current  === 'desktop'"></DesktopIncidents>
</template>

<!----------------------------------------------->
<!--                    SCRIPT                 -->
<!----------------------------------------------->
<script>
import MobileIncidents from "../components/Incidents/MobileIncidents";
import TabletIncidents from "../components/Incidents/TabletIncidents";
import DesktopIncidents from "../components/Incidents/DesktopIncidents";

export default {
  /* Name */
  name: "Incidents",
  inject: ["mq"],
  /* Components */
  components: {
    MobileIncidents,
    TabletIncidents,
    DesktopIncidents,
  },
};
</script>

<script setup>
import { useMq } from "vue3-mq";
const mq = useMq();
</script>

<!----------------------------------------------->
<!--                    STYLES                 -->
<!----------------------------------------------->
<style scoped >
</style>
