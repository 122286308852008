import $axios from '../utils';

export function getAllRoles (full=true, offset=0, limit=10000) {
    return $axios.get(`management/role/?full=${full}&offset=${offset}&limit=${limit}`)
    .then(response => response.data)
}
export function getOneRole(id, full) {
    return $axios.get(`management/role/${id}?full=${full}`)
    .then(response => response.data)
}
export function createRole(data) {
    return $axios.post('management/role/', data)
    .then(response => response.data)
}
export function updateRole(id, data) {
    return $axios.put(`management/role/${id}`, data)
    .then(response => response.data)
}
export function deleteRole(id) {
    return $axios.delete(`management/role/${id}`)
    .then(response => response.data)
}
export function deleteAllRoles() {
    return $axios.delete('management/role/')
    .then(response => response.data)
}
