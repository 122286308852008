import $axios from '../utils';

export function getAllTasks(full = true, sdate = '1975-01-01T00:00:00', edate = '3000-01-01T00:00:00', offset = 0, limit = 10000) {
    return $axios.get(`task/?full=${full}&offset=${offset}&limit=${limit}&sdate=${sdate}&edate=${edate}`)
        .then(response => response.data)
}
export function getTasksBy(key, value, full, offset, limit) {
    return $axios.get(`task/by?key${key}&value=${value}&full=${full}&offset=${offset}&limit=${limit}`)
        .then(response => response.data)
}
export function getOneTask(id, full) {
    return $axios.get(`task/${id}?full=${full}`)
        .then(response => response.data)
}
export function createTask(data) {
    return $axios.post('task/', data)
        .then(response => response.data)
}
export function updateTask(id, data) {
    return $axios.put(`task/${id}`, data)
        .then(response => response.data)
}
export function deleteAllTasks() {
    return $axios.delete('task/')
        .then(response => response.data)
}
export function deleteTask(id) {
    return $axios.delete(`task/${id}`)
        .then(response => response.data)
}