import $axios from '../utils';

export function getAllAlerts (full=true, offset=0, limit=10000) {
    return $axios.get(`soar/alert/?full=${full}&offset=${offset}&limit=${limit}`)
    .then(response => response.data)
}
export function getAlertsBy(key, value, full, offset, limit){
    return $axios.get(`soar/alert/by?key${key}&value=${value}&full=${full}&offset=${offset}&limit=${limit}`)
    .then(response => response.data)
}
export function getOneAlert(id, full) {
    return $axios.get(`soar/alert/${id}?full=${full}`)
    .then(response => response.data)
}
export function createAlert(data) {
    return $axios.post('soar/alert/', data)
    .then(response => response.data)
}
export function updateAlert(id, data) {
    return $axios.put(`soar/alert/${id}`, data)
    .then(response => response.data)
}
export function deleteAllAlerts() {
    return $axios.delete('soar/alert/')
    .then(response => response.data)
}
export function deleteAlert(id) {
    return $axios.delete(`soar/alert/${id}`)
    .then(response => response.data)
}
