<!----------------------------------------------->
<!--                  TEMPLATE                 -->
<!----------------------------------------------->
<template>
  <MobileTasks v-if="  mq.current  === 'mobile'"></MobileTasks>
  <TabletTasks v-if="  mq.current  === 'tablet'"></TabletTasks>
  <DesktopTasks v-if=" mq.current  === 'desktop'"></DesktopTasks>
</template>

<!----------------------------------------------->
<!--                    SCRIPT                 -->
<!----------------------------------------------->
<script>
import MobileTasks from "../components/Tasks/MobileTasks";
import TabletTasks from "../components/Tasks/TabletTasks";
import DesktopTasks from "../components/Tasks/DesktopTasks";

export default {
  /* Name */
  name: "Tasks",
  inject: ["mq"],
  /* Components */
  components: {
    MobileTasks,
    TabletTasks,
    DesktopTasks,
  },
};
</script>

<script setup>
import { useMq } from "vue3-mq";
const mq = useMq();
</script>

<!----------------------------------------------->
<!--                    STYLES                 -->
<!----------------------------------------------->
<style scoped >
</style>
