<!----------------------------------------------->
<!--                  TEMPLATE                 -->
<!----------------------------------------------->
<template>
  <div class="aux">
    <!-- Nav -->
    <nav class="navbar navbar-expand-lg navbar-light bg-light"
    :style="'font-family:' + primaryFont + '; padding-left: 6rem; padding-right:6rem;z-index:5; background-color:white; box-shadow: 1px 0 15px rgba(0, 0, 0, 0.07);'">
      <a class="navbar-brand">
        <router-link to="/home">
          <img class="nav-bar-logo" v-bind:src="projectLogo" alt="logo" />
        </router-link>
      </a>

      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav mr-auto"></ul>
        <div class="form-inline my-2 my-lg-0">
          <ul class="navbar-nav mr-auto pr-2">
            Username
          </ul>
          <ul class="navbar-nav mr-auto">
            <!-- IMAGEN USUARIO -->
            <li class="nav-item">
              <img :src="user.picture_url" class="rounded-circle" height="30" alt="" width="30"
                @click="$router.push({ path: 'profile', query: { userId: '123456789' } })" />
            </li>
          </ul>
          <ul class="navbar-nav mr-auto">
            <li class="nav-item dropdown ">
              <a class="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown"
                aria-haspopup="false" aria-expanded="false">
                <img :src="locale_img" class="rounded-circle" height="20" alt="" width="20" />
              </a>
              <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                <a class="dropdown-item" v-for="entry in languages" :key="entry" @click="setLocale(entry.language)">
                  <img :src="entry.flag" class="rounded-circle mr-2" height="20" alt="" width="20"  /> {{ entry.title }}
                </a>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <SideBarMobile></SideBarMobile>
  </div>
</template>

<!----------------------------------------------->
<!--                    SCRIPT                 -->
<!----------------------------------------------->

<script>
var $ = require("jquery");
import MyShared from "./sharedNavBar";

export default {
  mixins: [MyShared],
};
</script>
<!----------------------------------------------->
<!--                    STYLES                 -->
<!----------------------------------------------->
<style scoped>
.router-link-exact-active {
  text-decoration: underline 2px;
  text-decoration-color: #70ad47 !important;
  color: #70ad47 !important;
}

@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

* {
  font-family: 'Open Sans', sans-serif !important;
}

.username {
  padding-bottom: 15%;
  padding-top: 15%;
  margin-right: 0.5em;
  height: 100%;
}

.nav-bar-logo {
  height: 1.5em;
}

.navbar-item {
  font-size: 0.8em;
  font-weight: 600;
  color: black;
}

.logout {
  font-size: 1em;
  margin-right: 1em;
  width: 2em !important;
  height: 1em !important;
  /* background-color: blueviolet; */
  color: black;
}

.dropdown-menu {
  position: absolute !important;
  margin-right: 5em;
}
.dropdown-item{
  padding: 5px  !important;
}

.dropdown-toggle {
  margin-left: 2em
}

a {
  color: black;
  text-decoration: none;
}

a:hover {
  color: #22bcb3;
}

.navbar-brand {
  margin-right: 3rem;
  text-align: center;
}

h6 {
  margin-bottom: 0% !important;
}

.icon-logOut {
  color: #70ad47;
}

.fa {
  font-size: x-large;

}

.nav-item {
  text-align: center;
}

.center {
  text-align: center;
  margin-top: 0.75rem;
  margin-left: 0.5rem;
}

.navbar {
  position: fixed;
  top: 0;
  width: 100%;
}
</style>
