import Sidebar from "../Sidebar/SideBar";
import SideBarMobile from "../Sidebar/SideBarMobile";
import * as $api from "../../../api/api";


var MyShared = {
    name: "Navbar",
    props: {
        title: String,
    },
    /* Data */
    data() {
        return {
            user: {
                "permissions": []
            },
            user: {
                "full_name": "0"
            },
            loading: true,
            languages: [
                { flag: require('@/assets/locale/en.svg'), language: 'en', title: 'English' },
                { flag: require('@/assets/locale/es.svg'), language: 'es', title: 'Español' }
            ],
            locale_img: require('@/assets/locale/en.svg'),
            darkMode: false,
        };
    },
    components: {
        Sidebar,
        SideBarMobile
    },
    methods: {
        setLocale(locale) {
            $api.updateMe({ "language": locale }).then(
                response => {
                    this.setGlobalLocale(locale)
                    this.$cookies.set("LANGUAGE", locale);
                    this.locale_img = require('@/assets/locale/' + this.getGlobalLocale() + '.svg')
                    this.loading = false;
                }
            ).catch((err) => {
                console.log(`[-] Error (updateMe): ${err}`);
            })
        },
        getSourceLogo(logo) {
            return process.env.VUE_APP_BASE_URL + '/' + logo;
        },
        toggleMode() {
            this.darkMode = !this.darkMode
            if (this.darkMode) {
                document.documentElement.setAttribute('data-theme', 'dark')
                document.getElementById('dark-mode-checkbox').style.backgroundColor = '#111'
            } else {
                document.documentElement.setAttribute('data-theme', 'light')
                document.getElementById('dark-mode-checkbox').style.backgroundColor = 'rgb(141, 206, 243)'
            }

        }
    },
    mounted() {
        // get asset_groups from API
        if (process.env.VUE_APP_ENV === 'development') {} else if (process.env.VUE_APP_ENV === 'staging' || process.env.VUE_APP_ENV === 'production') {
            $api.getMe(true).then((response) => {
                this.user = response;
                //console.log("ME: ", this.user);
                this.setLocale(this.user.language);
            }).catch((err) => {
                console.log(`[-] Error (getMe): ${err}`);
            });
        } else {
            console.log("ERROR: NO ENVIRONMENT SELECTED");
        }
    },
};
export default MyShared;