<!----------------------------------------------->
<!--                  TEMPLATE                 -->
<!----------------------------------------------->
<template>
  <MobileAssetsDetails v-if="  mq.current  === 'mobile'"></MobileAssetsDetails>
  <TabletAssetsDetails v-if="  mq.current  === 'tablet'"></TabletAssetsDetails>
  <DesktopAssetsDetails v-if=" mq.current  === 'desktop'"></DesktopAssetsDetails>
</template>

<!----------------------------------------------->
<!--                    SCRIPT                 -->
<!----------------------------------------------->
<script>
import MobileAssetsDetails from "../components/AssetsDetails/MobileAssetsDetails";
import TabletAssetsDetails from "../components/AssetsDetails/TabletAssetsDetails";
import DesktopAssetsDetails from "../components/AssetsDetails/DesktopAssetsDetails";

export default {
  /* Name */
  name: "AssetsDetails",
  inject: ["mq"],
  /* Components */
  components: {
    MobileAssetsDetails,
    TabletAssetsDetails,
    DesktopAssetsDetails,
  },
};
</script>

<script setup>
import { useMq } from "vue3-mq";
const mq = useMq();
</script>

<!----------------------------------------------->
<!--                    STYLES                 -->
<!----------------------------------------------->
<style scoped >
</style>
