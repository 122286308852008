import $axios from '../utils';

export function getAllOrganizations(full = true, offset = 0, limit = 10000) {
    return $axios.get(`management/organization/?full=${full}&offset=${offset}&limit=${limit}`)
        .then(response => response.data)
}

export function getOneOrganization(id, full = true) {
    return $axios.get(`management/organization/${id}?full=${full}`)
        .then(response => response.data)
}

export function createOrganization(data) {
    return $axios.post('management/organization/', data)
        .then(response => response.data)
}

export function updateOrganization(id, data) {
    return $axios.put(`management/organization/${id}`, data)
        .then(response => response.data)
}

export function deleteOrganization(id) {
    return $axios.delete(`management/organization/${id}`)
        .then(response => response.data)
}

export function newLogo(id, data) {
    return $axios.put(`management/organization/new-logo/${id}`, data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(response => response.data)
}