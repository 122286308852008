import axios from 'axios';

let $axios = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL,
    timeout: 500000,
    headers: { 'Content-Type': 'application/json' }
})

// Request Interceptor
$axios.interceptors.request.use(function(config) {
    // const token = localStorage.getItem('authtoken');
    const token = $cookies.get("ACCESS_TOKEN");
    if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config
})

// Response Interceptor to handle and log errors
$axios.interceptors.response.use(function(response) {
    return response
}, function(error) {
    // Handle Error
    console.log(error)
    return Promise.reject(error)
})

export default $axios;