<!----------------------------------------------->
<!--                  TEMPLATE                 -->
<!----------------------------------------------->
<template>
  <div class="aux" style="height: 100vh;">
    <div class="login-container">
      <div class="form-container">

        <h1><strong>Not Found</strong></h1>
        <h4>The resource requested could not be found on this server</h4>
        <section class="error-container">
          <span><span>4</span></span>
          <span>0</span>
          <span><span>4</span></span>
        </section>
        

      </div>
    </div>
  </div>
</template>

<!----------------------------------------------->
<!--                    SCRIPT                 -->
<!----------------------------------------------->

<script>
import MyShared from "./shared";
export default {
  mixins: [MyShared],
};

</script>

<script setup>
import { useMq } from "vue3-mq";
const mq = useMq();
</script>
<!----------------------------------------------->
<!--                    STYLES                 -->
<!----------------------------------------------->
<style scoped lang="css" >
a {
  text-decoration: none !important;
  color: #ec635f !important;
}

.aux {
  background-image: url("../../assets/bg-login1.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.form-container {
  padding-top: 180px;
}

.btn-login {
  float: right;
  font-weight: 400 !important;
}

@import url('https://fonts.googleapis.com/css?family=Montserrat:400,600,700');
@import url('https://fonts.googleapis.com/css?family=Catamaran:400,800');

.error-container {
  text-align: center;
  font-size: 260px;
  font-family: 'Catamaran', sans-serif;
  font-weight: 800;
  margin-top: -60px;
}

.error-container>span {
  display: inline-block;
  line-height: 0.7;
  position: relative;
  color: #b3dfe4;
}

.error-container>span>span {
  display: inline-block;
  position: relative;
}

.error-container>span:nth-of-type(1) {
  perspective: 1000px;
  perspective-origin: 500% 50%;
  color: #ffd85f;
}

.error-container>span:nth-of-type(1)>span {
  transform-origin: 50% 100% 0px;
  transform: rotateX(0);
  animation: easyoutelastic 8s infinite;
}

.error-container>span:nth-of-type(3) {
  perspective: none;
  perspective-origin: 50% 50%;
  color: #f0817e;
}

.error-container>span:nth-of-type(3)>span {
  transform-origin: 100% 100% 0px;
  transform: rotate(0deg);
  animation: rotatedrop 8s infinite;
}

@keyframes easyoutelastic {
  0% {
    transform: rotateX(0);
  }

  9% {
    transform: rotateX(210deg);
  }

  13% {
    transform: rotateX(150deg);
  }

  16% {
    transform: rotateX(200deg);
  }

  18% {
    transform: rotateX(170deg);
  }

  20% {
    transform: rotateX(180deg);
  }

  60% {
    transform: rotateX(180deg);
  }

  80% {
    transform: rotateX(0);
  }

  100% {
    transform: rotateX(0);
  }
}

@keyframes rotatedrop {
  0% {
    transform: rotate(0);
  }

  10% {
    transform: rotate(30deg);
  }

  15% {
    transform: rotate(90deg);
  }

  70% {
    transform: rotate(90deg);
  }

  80% {
    transform: rotate(0);
  }

  100% {
    transform: rotateX(0);
  }
}





/* demo stuff */
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  background-color: #f4f4f4;
  margin-bottom: 50px;
}

html,
button,
input,
select,
textarea {
  font-family: 'Montserrat', Helvetica, sans-serif;
  color: #bbb;
}

h1 {
  text-align: center;
}
h4 {
  text-align: center;
}

.zoom-area {
  max-width: 490px;
  margin: 30px auto 30px;
  font-size: 19px;
  text-align: center;
}

.link-container {
  text-align: center;
}

a.more-link {
  text-transform: uppercase;
  font-size: 13px;
  background-color: #bbb;
  padding: 10px 15px;
  border-radius: 0;
  color: #fff;
  display: inline-block;
  margin-right: 5px;
  margin-bottom: 5px;
  line-height: 1.5;
  text-decoration: none;
  margin-top: 50px;
  letter-spacing: 1px;
}</style>
