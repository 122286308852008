<!----------------------------------------------->
<!--                  TEMPLATE                 -->
<!----------------------------------------------->
<template>
  <div class="row footer text-center">
    <p class="text" style="float:right;">{{ footerCopyRight }}</p>
    <i style="color: black; position:absolute; bottom:0; right:0; width:fit-content; cursor:pointer;"
      class="pi pi-comment mb-2 chat-open mt-1" @click="openModal"></i>

    <Modal ref="chatbot">
      <template #body>
        <div class="row m-1">
          <div class="col-1">
            <Avatar :image="chatbotimg" class="mr-2" size="large" shape="circle" />
          </div>
          <div class="col-11 chat-name">
            <h5 class="m-0">Air SIEM Chat BOT</h5>
            <p class="m-0">Here to help you!</p>
          </div>
        </div>
        <ScrollPanel class="msg-panel" id="msg-panel">
          <!-- Chat message, floats left -->
          <div :class="'msg-' + msg.type" v-for="msg in messages" :key="msg">
            <p :class="'msg-text-' + msg.type">
              <i class="pi pi-spinner pi-spin" v-if="msg.message == ''"></i>
              {{ msg.message }}
            </p>
          </div>
        </ScrollPanel>
        <textarea class="msg-text-area" placeholder="What do you need?" @keydown.enter.prevent="sendMessage()" v-model="user_message" id="message-text-area"></textarea>
        <Button class="send-btn" icon="pi pi-arrow-right" iconPos="right" @click="sendMessage()" :disabled="btn_disable"></Button>
      </template>
    </Modal>
  </div>
</template>

<!----------------------------------------------->
<!--                    SCRIPT                 -->
<!----------------------------------------------->

<script scoped>
import { nextTick } from "vue";
import * as $api from "../../../api/api";
import Modal from '../Modals/Modal.vue'
export default {
  /* Name */
  name: "NameHere",
  /* Data */
  data() {
    return {
      scrollYRatio: 0,
      //chatbotimg: require('@/assets/robot.svg'),
      user_message: '',
      messages: [{
        message: 'Hola soy el asistente virtual de Air SIEM, ¿En qué puedo ayudarte?',
        type: 'bot'
      },],
      btn_disable: false
    };
  },
  /* Components */
  components: {
    Modal
  },
  /* Props */
  props: {},
  /* Methods */
  methods: {
    openModal() {
      this.$refs.chatbot.openModal()
    },
    async sendMessage(){
      console.log('send message')

      if(this.user_message === '' || this.btn_disable === true){
        return;
      }

      // disable send button
      this.btn_disable = true;

      this.messages.push({
        message: this.user_message,
        type: 'user'
      });

      // add loading response to chat
      this.messages.push({
        message: '',
        type: 'bot'
      });

      // scroll to bottom
      let element = document.getElementsByClassName('p-scrollpanel-content')[document.getElementsByClassName('p-scrollpanel-content').length - 1];
      element.scrollTop = element.scrollHeight;
      await nextTick();
      element.scrollTo(0, element.scrollHeight + 99999);

      // send message to chatbot

      // clear input
      this.clearInput();

      // get response from chatbot

      // add response to chat
      // TEMP: Wait 1 second
      await new Promise(resolve => setTimeout(resolve, 1000));
      this.messages[this.messages.length - 1].message = 'Aun no tengo respuesta para eso, pero puedo ayudarte con otra cosa.';

      // enable send button
      this.btn_disable = false;
    },
    async clearInput(){
      this.user_message = ''
    }
  },
  /* Computed */
  computed: {
  },
  async mounted() {
    //this.openModal();
  },
  /* Emits */
  emits: ["emits", "emits"],
};
</script>

<!----------------------------------------------->
<!--                    STYLES                 -->
<!----------------------------------------------->

<style scoped>
body {
  background-color: #fafafd;
}

.text {
  margin: 0.5em;
  color: black;
  font-size: 0.7em;
}

.footer {
  background: rgb(255, 255, 255) !important;
  margin-top: 2em;
  bottom: 0;
  width: 101%;
  position: fixed;
  margin-bottom: 0.01em;
  z-index: 2;
  border-top: 1px solid rgb(236, 236, 236);
}

.chat-open {
  color: #fafafd;
}


/* Chat box */
.msg-panel {
  height: 300px;
  width: auto;
  background-color: #f4f4f4;
  overflow-y: auto;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  overflow-x: hidden;
}
.chat-name{
  margin-left: -15px;
}
.msg-panel{
  background-color: #ffffff !important;
  border-color: #d1d1d1 !important;
}
.msg-text-area{
  width: 95%;
  height: 40px;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px;
  margin-top: 10px;
  background-color: #ffffff !important;
  border-color: #d1d1d1 !important;
  color: #0c0c0c !important;
}
.msg-text-area::placeholder{
  color: #3a3a3a;
}
.send-btn{
  background-color: #ef6154 !important;
  border-color: #ef6154 !important;
  color: #fff;
  float: right;
  margin-top: 10px;
  width: 10%;
  height: 40px;
  padding-left: 0.75rem !important;
  padding-right: 0.75rem !important;
}
.send-btn:disabled{
  background-color: #7f93af !important;
  border-color: #7f93af !important;
  color: #fff;
}
.msg-bot{
  width: 100%;
  float: left;
}
.msg-user{
  width: 100%;
  float: right;
}
.msg-text-bot{
  background-color: #bed7ff;
  border-radius: 5px;
  padding: 5px;
  margin-top: 10px;
  max-width: 70%;
  float: left;
}
.msg-text-user{
  background-color: #ef6154;
  border-radius: 5px;
  padding: 5px;
  margin-top: 10px;
  max-width: 70%;
  float: right;
  color: #fff;
}
</style>
