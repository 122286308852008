import $axios from '../utils';

export function login(username, password) {
    return $axios.post(
        'management/auth/login',
        {
            username: username,
            password: password
        },
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
    )
    .then(response => response.data)
}
export function logout() {
    return $axios.post('management/auth/logout')
    .then(response => response.data)
}