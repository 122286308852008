<!----------------------------------------------->
<!--                  TEMPLATE                 -->
<!----------------------------------------------->
<template>
  <MobileEvents v-if="  mq.current  === 'mobile'"></MobileEvents>
  <TabletEvents v-if="  mq.current  === 'tablet'"></TabletEvents>
  <DesktopEvents v-if=" mq.current  === 'desktop'"></DesktopEvents>
</template>

<!----------------------------------------------->
<!--                    SCRIPT                 -->
<!----------------------------------------------->
<script>
import MobileEvents from "../components/Events/MobileEvents";
import TabletEvents from "../components/Events/TabletEvents";
import DesktopEvents from "../components/Events/DesktopEvents";

export default {
  /* Name */
  name: "Events",
  inject: ["mq"],
  /* Components */
  components: {
    MobileEvents,
    TabletEvents,
    DesktopEvents,
  },
};
</script>

<script setup>
import { useMq } from "vue3-mq";
const mq = useMq();
</script>

<!----------------------------------------------->
<!--                    STYLES                 -->
<!----------------------------------------------->
<style scoped >
</style>
