<!----------------------------------------------->
<!--                  TEMPLATE                 -->
<!----------------------------------------------->
<template>
  <div class="aux">
    <Sitebar></Sitebar>
    <div class="maincontainer" >
      <ConfirmDialog></ConfirmDialog>
      <!-- ==================================================================================================================================================== -->
      <!-- =================================================================== View Menu ==================================================================== -->
      <!-- ==================================================================================================================================================== -->
      <Transition name="side-slide-fade">
        <div class="configmenu" v-if="configmenu">
          <div class="sub-menu" style="width:auto;">
            <i class="pi pi-times mr-2"
              style="position: absolute; top: 0; right: 0; margin-top: 65px; cursor: pointer; font-size: larger;"
              @click="configmenu = flase"></i>
            <div class="row">
              <h3 class="mt-4">{{ $t('listenerConfiguration') }}</h3>
            </div>
            <div class="row">
              <ScrollPanel style="width: 100%; height:45em" class="custom">
              <div class="user-container">
                <div class="user-aux">
                  <!-------------------------------------------------------------------------------->
                  <div class="row p-1" style="display:inline-flex; width:100%;" @click="changeStep(0)">
                    <li class="user-container-img">
                      <Avatar label="1" shape="circle" size="medium"
                        :style="(steps == 0) ? 'background-color:#ec635f; color:white;' : 'background-color:#dee2e6; color:#212529;'" />
                    </li>
                    <div class="user-container-name">
                      <p class="m-0">{{ $t('listenerInfo') }}</p>
                      <p class="m-0" style="font-size:small">{{ $t('nameAndDescription') }}</p>
                    </div>
                  </div>
                  <div class="row step-template" v-if="steps == 0">
                    <label for="" class="mb-0 pl-0">{{ $t('listenerName') }}</label>
                    <InputText v-model="listener_name"></InputText>
                    <label for="" class="mb-0 mt-2 pl-0">{{ $t('listenerDescription') }}</label>
                    <Textarea v-model="listener_desc"></Textarea>
                  </div>
                  <!-------------------------------------------------------------------------------->
                  <div class="row p-1" style="display:inline-flex; width:100%;" @click="changeStep(1)">
                    <li class="user-container-img">
                      <Avatar label="2" shape="circle" size="medium"
                        :style="(steps == 1) ? 'background-color:#ec635f; color:white;' : 'background-color:#dee2e6; color:#212529;'" />
                    </li>
                    <div class="user-container-name">
                      <p class="m-0">{{ $t('intakeMethod') }}</p>
                      <p class="m-0" style="font-size:small">{{ $t('selectIntakeMethod') }}</p>
                    </div>
                  </div>
                  <div class="row step-template" v-if="steps == 1" style="position:relative; width:auto">
                    <!--
                      <Dropdown v-model="listener_intake_method" :options="activeMethods" optionLabel="name"
                      :placeholder="$t('selectIntakeMethod')" @change="updateFlowChartIntakeMethod()" style="z-index:999"/>
                     -->
                     <!-- Por lo que sea el dropdown no va, hay que usar esto -->
                      <div class="dropdown p-0" v-if="activeMethods.length != 0">
                        <button class="p-dropdown p-component p-inputwrapper mt-1" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="width:100%">
                          <span class="pl-2 pr-2" style="color:#6c757d" v-if="listener_intake_method == null">{{ $t('selectIntakeMethod') }}</span>
                          <span class="pl-2 pr-2" style="color:#495057" v-else>{{ listener_intake_method.name }}</span>
                          <span class="pl-2 pr-2" style="color:#6c757d; position:absolute; right:0"><i class="pi pi-chevron-down"></i></span>
                        </button>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" style="margin-right:0; width:100%;" >
                          <a class="dropdown-item" v-for="option in activeMethods" :key="option" @click="listener_intake_method = option; updateFlowChartIntakeMethod()">{{option.name}}</a>
                        </div>
                      </div>
                      <div v-else class="needNewIntakeSchema">
                        <p class="p-0">{{ $t('needNewIntakeMethod') }}</p>
                        <Button :label="$t('create')" @click="$refs.editIntegration.openModal(); toggleIntakeConfig = true"></Button>
                      </div>
                  </div>
                  <!-------------------------------------------------------------------------------->
                  <div class="row p-1" style="display:inline-flex; width:100%;" @click="changeStep(2)">
                    <li class="user-container-img">
                      <Avatar label="3" shape="circle" size="medium"
                        :style="(steps == 2) ? 'background-color:#ec635f; color:white;' : 'background-color:#dee2e6; color:#212529;'" />
                    </li>
                    <div class="user-container-name">
                      <p class="m-0">{{ $t('configureIntakeMethod') }}</p>
                      <p class="m-0" style="font-size:small">{{ $t('configureIntakeMethod') }}</p>
                    </div>
                  </div>
                  <div class="row step-template" v-if="steps == 2 && listener_intake_method.configuration_schema != {}">
                    <span v-for="(param, index) in listener_intake_method.configuration_schema" :key="param" class="p-0">
                      <label for="" class="p-0 mb-0 ml-o mr-0 mt-1 col-12">{{ index }}</label>
                      <label for="" class="p-0 m-0 col-12"><small style="font-size: smaller; color: lightgray;">{{
                        param.description
                      }}</small></label>
                      <InputText v-if="param.type == 'int'" class="col-12" type="number" v-model="param.value" >
                      </InputText>
                      <InputText v-if="param.type == 'str'" class="col-12" type="text" v-model="param.value"></InputText>
                      <InputSwitch v-if="param.type == 'boolean'" class="mt-2" v-model="param.value"></InputSwitch>
                    </span>
                  </div>
                  <!-------------------------------------------------------------------------------->
                  <div class="row p-1" style="display:inline-flex; width:100%;" @click="changeStep(3)">
                    <li class="user-container-img">
                      <Avatar label="4" shape="circle" size="medium"
                        :style="(steps == 3) ? 'background-color:#ec635f; color:white;' : 'background-color:#dee2e6; color:#212529;'" />
                    </li>
                    <div class="user-container-name">
                      <p class="m-0">{{ $t('intakeSchema') }}</p>
                      <p class="m-0" style="font-size:small">{{ $t('selectIntakeSchema') }}</p>
                    </div>
                  </div>
                  <div class="row step-template" v-if="steps == 3" style="position:relative; width:auto">
                    <!--
                      <Dropdown v-model="listener_intake_method" :options="activeMethods" optionLabel="name"
                      :placeholder="$t('selectIntakeMethod')" @change="updateFlowChartIntakeMethod()" style="z-index:999"/>
                     -->
                     <!-- Por lo que sea el dropdown no va, hay que usar esto -->
                      <div class="dropdown p-0" v-if="!needNewIntakeSchema">
                        <button class="p-dropdown p-component p-inputwrapper mt-1" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="width:100%">
                          <span class="pl-2 pr-2" style="color:#6c757d" v-if="listener_intake_schema == null">{{ $t('selectIntakeSchema') }}</span>
                          <span class="pl-2 pr-2" style="color:#495057" v-else>{{ listener_intake_schema.name }}</span>
                          <span class="pl-2 pr-2" style="color:#6c757d; position:absolute; right:0"><i class="pi pi-chevron-down"></i></span>
                        </button>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" style="margin-right:0; width:100%;" >
                          <a class="dropdown-item" v-for="option in intake_schemas" :key="option" @click="listener_intake_schema = option;">{{option.name}}</a>
                        </div>
                      </div>
                      <div v-else class="needNewIntakeSchema">
                        <p class="p-0">{{ $t('needNewIntakeSchema') }}</p>
                        <Button :label="$t('create')" @click="$refs.newIntakeSchema.openModal();"></Button>
                      </div>
                  </div>
                  <!-------------------------------------------------------------------------------->
                  <div class="row p-1" style="display:inline-flex; width:100%;" @click="changeStep(4)">
                    <li class="user-container-img">
                      <Avatar label="5" shape="circle" size="medium"
                        :style="(steps == 4) ? 'background-color:#ec635f; color:white;' : 'background-color:#dee2e6; color:#212529;'" />
                    </li>
                    <div class="user-container-name">
                      <p class="m-0">{{ $t('createListener') }}</p>
                      <p class="m-0" style="font-size:small">{{ $t('createListener') }}</p>
                    </div>
                  </div>
                  <div class="row step-template" v-if="steps == 4">
                    <div class="card mt-2 col-12 p-0">
                      <div class="card-body">
                        <div class="row">
                          <div class="col-12 pt-0 pb-0">
                            <p class="mt-1 mb-0"><strong>{{ $t('information') }}</strong></p>
                            <hr class="m-0">
                            <h6 class="card-title mb-0">{{ listener_name }}</h6>
                            <p class="m-0"><small>{{ listener_desc }}</small></p>
                          </div>
                          <div class="col-12 pt-0">
                            <p class="mt-0 mb-0"><strong>{{ $t('configuration') }}</strong></p>
                            <hr class="m-0">
                            <p>{{ listener_intake_method.name }}</p>
                            <div class="row p-0 m-0">
                              <div class="col-4 pt-0 pb-0 pl-0 pr-1" v-for="(param, index) in listener_intake_method.configuration_schema"
                                :key="param">
                                <p class="mb-0"><small
                                    style="font-size:0.75em; color:lightgray;">{{ index.toUpperCase() }}</small></p>
                                <p class="mb-0">{{ listener_intake_method.configuration_schema[index].value }}</p>
                              </div>
                            </div>
                          </div>
                          <div class="col-12 pt-0">
                            <p class="mt-0 mb-0"><strong>{{ $t('intakeSchema') }}</strong></p>
                            <hr class="m-0">
                            <p>{{ listener_intake_schema.name }}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card mt-2 pt-3">
                      <p class="mt-0 mb-0"><strong>{{ $t('resume') }}</strong></p>
                      <hr class="m-0">
                      <!---->
                      <VueFlow v-model="flow_chart" :zoom-on-scroll="false" :zoom-on-pinch="false" :zoom-on-double-click="false" :pan-on-scroll-speed="0" :pan-on-drag="false" :prevent-scrolling="true">
                        <template #node-integration="props">
                          <div class="card">
                            <div class="card-body text-center p-1">
                              <img :src="getSourceLogo(data.logo)" alt="" class="mb-1 img-fluid" style="height:2.5rem">
                              <p class="node-title mb-0">{{ props.label }}</p>
                            </div>
                          </div>
                        </template>
                        <template #node-nifi="props">
                          <div class="card">
                            <div class="card-body text-center p-2">
                              <img :src="nifi_logo" alt="" class="mb-1 img-fluid" style="height:1.5rem">
                              <p class="node-title mb-0">{{ props.label }}</p>
                            </div>
                          </div>
                        </template>
                        <template #node-preprocessor="props">
                          <div class="card">
                            <div class="card-body text-center p-2">
                              <img :src="preprocessor_logo" alt="" class="mb-1 img-fluid" style="height:2rem">
                              <p class="node-title mb-0">{{ props.label }}</p>
                              <Handle type="target" :position="Position.Left" connectable="single" :id="'b'"/>
                              <Handle type="target" :position="Position.Right" connectable="single" :id="'c'"  />
                              <Handle type="target" :position="Position.Top" connectable="single" :id="'a'" />
                              <Handle type="target" :position="Position.Bottom" connectable="single" :id="'d'" />
                            </div>
                          </div>
                        </template>
                        <template #node-geo_ip="props">
                          <div class="card">
                            <div class="card-body text-center pt-2 pb-2 pl-4 pr-4">
                              <InputSwitch class="mt-1 mr-1" v-model="geopip_checked" style="position: absolute;top: 0;right: 0;"  @change="updateAnimation()"/>
                              <img :src="geo_ip_logo" alt="" class="mb-1 img-fluid" style="height:2rem">
                              <p class="node-title mb-0">{{ props.label }}</p>
                              <Handle type="target" :position="Position.Right"/>
                            </div>
                          </div>
                        </template>
                        <template #node-threat_intel="props">
                          <div class="card">
                            <div class="card-body text-center p-2">
                              <InputSwitch class="mt-1 mr-1" v-model="threatintel_checked" style="position: absolute;top: 0;right: 0;" @change="updateAnimation()"/>
                              <img :src="threat_intel_logo" alt="" class="mb-1 img-fluid" style="height:2rem">
                              <p class="node-title mb-0">{{ props.label }}</p>
                              <Handle type="target" :position="Position.Left" :is-valid-connection="props.isValidSourcePos" />
                            </div>
                          </div>
                        </template>
                        <template #node-output_index="props">
                          <div class="card">
                            <div class="card-body text-center p-2">
                              <img :src="elastic_logo" alt="" class="mb-1 img-fluid" style="height:2rem">
                              <p class="node-title mb-0">{{ props.label }}</p>
                              <Handle type="target" :position="Position.Top" :is-valid-connection="props.isValidSourcePos" :id="'a'" />
                              <Handle type="target" :position="Position.Right" :is-valid-connection="props.isValidSourcePos" :id="'b'" />
                            </div>
                          </div>
                        </template>
                        <template #node-deepSiem_ia="props">
                          <div class="card" style="background-color: #dfdfdf;">
                            <div class="card-body text-center p-3">
                              <InputSwitch class="mt-1 mr-1" v-model="airsiem_checked" style="position: absolute;top: 0;right: 0;" disabled  @change="updateAnimation()"/>
                              <img :src="deepSiem_logo" alt="" class="mb-1 img-fluid" style="height:1.5rem">
                              <p class="node-title mb-0">{{ props.label }}</p>
                              <Handle type="target" :position="Position.Left" :is-valid-connection="props.isValidSourcePos" />
                            </div>
                          </div>
                        </template>
                      </VueFlow>
                    </div>
                    
                  </div>
                  <!-------------------------------------------------------------------------------->
                </div>
              </div>
            </ScrollPanel>
            </div>
            <div class="row">
              <div class="col-12 text-center">
                <Button label="Save" class="p-button-raised"
                  style="background-color:#ec635f; color:white; margin-top:1rem; margin-bottom:1rem; float:right;"
                  @click="createListener(listener_name, listener_desc, listener_intake_method.configuration_schema); configmenu = false;"
                  v-if="steps == 4"></Button>
              </div>
            </div>
          </div>
          
        </div>
      </Transition>

      <div class="col-12  pl-0">
        <Card>
          <template #content>
            <div class="row pb-2">
              <div class="col-2 text-center" style="padding-left:4rem; padding-right:4rem; padding-bottom:0.5rem" v-if="loading_table">
                <ContentLoader width="8em" heigth="4em"></ContentLoader>
              </div>
              <div class="col-2 text-center" style="padding-left:4rem; padding-right:4rem; padding-bottom:0.5rem" v-else>
                <img v-if="data.logo" :src="getSourceLogo(data.logo)" alt="" class="img-fluid" style="height:auto">
                <div v-else :class="checkChar(data.name.substr(0, 1) )">
                    <p class="default-img-letter">{{ data.name.substr(0, 1) }}</p>
                  </div>
              </div>
              <div class="col-8 pl-0">
                <div class="row" v-if="loading_table">
                  <ContentLoader width="100%" heigth="3em"></ContentLoader>
                </div>
                <div class="row" v-else>
                  <div class="col-12 pb-0 m-0 pl-0 pt-0" style="color:#ec635f; font-size:small" v-if="data.intake_methods.length == 0">
                    <p class="text-left mb-0">*{{ $t('noConfigurationWarningForIntegrations') }}</p>
                  </div>
                  <div class="col-12 pb-0 m-0 pl-0 pt-0">
                    <h5 class="text-left mb-0"><strong>{{ data.name }}</strong></h5>
                  </div>
                  <div class="col-12 pb-0 m-0 pl-0">
                    <p class="text-left m-0" style="margin-top:-10px !important;"><em>{{ data.category }}</em></p>
                  </div>
                  <div class="col-12 pb-0 m-0 pl-0 pt-0">
                    <p class="text-left m-0">{{ data.description }}</p>
                  </div>
                </div>
              </div>
              <div class="col-2 pr-4">
                <div class="row">
                  <div class="col-12 pb-0 m-0 pl-0" style="color:#ec635f">
                    <Button :label="$t('configureListener')" icon="pi pi-cog" iconPos="right" style="float:right"
                      @click="configmenu = true; loadListenerForm()" />
                  </div>
                  <div class="col-12 pb-0 m-0 pl-0" style="color:#ec635f">
                    <Button :label="$t('edit') + ' ' + $t('integration')" icon="pi pi-file-edit" iconPos="right"
                      style="float:right" @click="$refs.editIntegration.openModal(); formatParams()" />
                  </div>
                </div>
              </div>
            </div>
          </template>
        </Card>
      </div>
      <div class="col-12  pl-0">
        <Card>
          <template #content>
            <div class="row p-2">
              <div class="col-4 pt-0 pl-4 pr-0">
                <div class="col-12 pb-0 m-0 pl-0 pt-0">
                  <h5 class="text-left">{{ $t('intakeMethods') }}</h5>
                  <ScrollPanel style="width: 100%; height:33em" class="custom">
                    <div class="padding-aux pr-2">
                      <div class="text-center" v-if="noSelectedIntakeMethods">
                        <p class="mb-0">{{ $t('noSelectedIntakeMethods') }}</p>
                        <p class="row-name-link" @click="$refs.editIntegration.openModal(); toggleIntakeConfig = true">
                          {{ $t('configureOne') }}
                        </p>
                      </div>
                      <span class="" v-for="intake in selectedIntakeMethods" :key="intake">
                        <div class="card mt-2" v-if="intake.active">
                          <div class="card-body">
                            <h6 class="card-title mb-0">{{ intake.name }}</h6>
                            <p class="card-text m-0"><em>{{ intake.nifi_listener }}</em></p>
                            <p class="m-0"><small>{{ intake.description }}</small></p>
                            <p class="m-0 row-name-link"
                              @click="$refs.viewIntakeMethod.openModal(); selectedIntakeMethod = intake;">
                              <small>{{ $t('explore') }}</small>
                            </p>
                          </div>
                        </div>
                      </span>
                    </div>
                  </ScrollPanel>
                </div>
              </div>
              <div class="col-8 pt-0 pl-0 pr-0">
                <div class="col-12 pb-0 m-0 pl-0 pt-0">
                  <h5 class="text-left">{{ $t('listeners') }}</h5>
                  <ScrollPanel style="width: 100%; height:33em" class="custom">
                    <div class="padding-aux pr-2">
                      <div class="row p-0 m-0">
                        <div :class="(showDelete) ? 'card mt-2 col-11 p-0' : 'card mt-2 col-12 p-0'"
                          v-for="listener in data.listeners" :key="listener">
                          <!-- @dragstart="drag()" draggable="true" -->
                          <div :class="'p-inputswitch p-component mt-1 mr-1 ' +  (listener.is_active ? 'p-inputswitch-checked' : '')"
                            :id="'switch_' + listener.id" style="position: absolute; top: 0px; right: 0px; z-index: 5;"
                            @click="(listener.is_active ? pause(listener.id) : play(listener.id))">
                            <div class="p-hidden-accessible">
                              <input type="checkbox" role="switch" class="" aria-checked="false">
                            </div>
                            <span class="p-inputswitch-slider"></span>
                          </div>
                          <i class="pi pi-trash mt-1"
                            style="position: absolute; top: 0px; right: 0px; z-index: 5; margin-right: 2.5em; cursor: pointer;"
                            @click="deleteListener(listener.id)"></i>
                          <div class="card-body">
                            <div class="row">
                              <div class="col-6 pt-0">
                                <p class="mt-1 mb-0"><strong>{{ $t('information') }}</strong></p>
                                <hr class="m-0">
                                <h6 class="card-title mb-0">{{ listener.name }}</h6>
                                <p class="m-0"><small>{{ listener.description }}</small></p>
                                <p class="mt-1 mb-0"><strong>{{ $t('configuration') }}</strong></p>
                                <hr class="m-0">
                                <p>{{ listener.intake_method }}</p>
                                <div class="row p-0 m-0">
                                  <div class="col-4 pt-0 pb-0 pl-0 pr-1"
                                    v-for="(param, index) in listener.configuration" :key="param">
                                    <p class="mb-0"><small
                                        style="font-size:0.75em; color:lightgray;">{{ index.toUpperCase() }}</small></p>
                                    <p class="mb-0">{{ listener.configuration[index] }}</p>
                                  </div>
                                </div>
                                <p class="mt-1 mb-0"><strong>{{ $t('intakeSchema') }}</strong></p>
                                <hr class="m-0">
                                <p>{{ listener.intake_schema.name }}</p>
                              </div>
                              <div class="col-6 pt-0">
                                <p class="mt-1 mb-0"><strong>{{ $t('status') }}</strong></p>
                                <hr class="m-0">
                                <ScrollPanel style="width: 100%; height:13em" class="custom">
                                  <div class="padding-aux pr-2">
                                    <div class="card p-2 mt-2" v-for="state in listener.state" :key="state">
                                      <p class="mt-1 mb-0">
                                        <i class="pi pi-circle-fill"
                                          :style="(state.status == 1) ? 'font-size:0.8em; color:#6dc193' : 'font-size:0.8em;color:#ef6154'">
                                        </i><strong>&nbsp;{{ state.name }}</strong>
                                      </p>
                                      <div class="row p-0 m-0">
                                        <div class="col-4 p-0">
                                          <p class="mb-0"><small style="font-size:0.75em; color:lightgray;">
                                              {{ $t('input_messages').toUpperCase() }}</small>
                                          </p>
                                          <p class="mb-0">{{ state.input_message }}</p>
                                        </div>
                                        <div class="col-4 p-0">
                                          <p class="mb-0"><small style="font-size:0.75em; color:lightgray;">
                                              {{ $t('output_messages').toUpperCase() }}</small>
                                          </p>
                                          <p class="mb-0">{{ state.output_message }}</p>
                                        </div>
                                        <div class="col-4 p-0">
                                          <p class="mb-0"><small style="font-size:0.75em; color:lightgray;">
                                              {{ $t('active_threads').toUpperCase() }}</small>
                                          </p>
                                          <p class="mb-0">{{ state.active_threads }}</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </ScrollPanel>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!--
                        <div class="col-1 card text-center mt-2" v-if="showDelete" style="background-color: #ec635f; cursor: pointer;" @click="deleteListener(listener.name);" @mouseleave="showDelete = false">
                          <i class="pi pi-trash delete-icon" style=""></i>
                        </div>
                        -->
                      </div>
                    </div>
                  </ScrollPanel>
                </div>
              </div>
            </div>
          </template>
        </Card>
      </div>
    </div>
    <!--============================================================================== newIntakeSchema ==============================================================================-->
    <Modal ref="newIntakeSchema">
      <template #header>
      </template>
      <template #body>
        <div class="row">
          <div class="col-12 text-center pt-4">
            <i class="pi pi-comments" style="font-size:2.5em;"></i>
          </div>
          <div class="col-12 text-center pt-0 pb-0">
            <h5 class="modal-title">{{ $t('newIntakeSchema') }}</h5>
          </div>
        </div>
        <div class="row">
          <div class="col-12 p-4">
            <label for="" class="pl-0 pb-0 mb-0">{{ $t('name') }}</label>
            <InputText type="text" v-model="new_intake_name" :placeholder="$t('name')" style="width:100%;">
            </InputText>
            <label for="" class="pl-0 pb-0 mb-0 mt-1">{{ $t('description') }}</label>
            <Textarea type="text" v-model="new_intake_desc" :placeholder="$t('description')" style="width:100%;">
            </Textarea>
            <label for="" class="pl-0 pb-0 mb-0 mt-1">{{ $t('format') }}</label>
            <Dropdown v-model="new_intake_format" :placeholder="$t('format')" style="width:100%;" :options="formats"
                  optionLabel="name"></Dropdown>
            <!-- Aqui va la configuracion de intake_schema -->
            <div class="row mt-4">
              <h6 class="mb-0"><strong>{{ $t('configurationSchemaParams') }}</strong></h6>
              <h6 class="mb-0" style="color:#ec635f" v-if="show_params_error">{{ $t('atLeastOneParamNeeded') }}</h6>
              <div class="col-8">
                <label for="" class="pl-0 pb-0 mb-0">{{ $t('destination') }}</label>
                <InputText type="text" v-model="new_schema_dst" :placeholder="$t('destination')" style="width:100%;">
                </InputText>
                <label for="" class="pl-0 pb-0 mb-0">{{ $t('source') }}</label>
                <InputText type="text" v-model="new_schema_src" :placeholder="$t('source')"
                  style="width:100%;">
                </InputText>
                <label for="" class="pl-0 pb-0 mb-0">{{ $t('src_type') }}</label>
                <Dropdown v-model="new_schema_src_type" :placeholder="$t('type')" style="width:100%;" :options="types"
                  optionLabel="name"></Dropdown>
                <label for="" class="pl-0 pb-0 mb-0">{{ $t('dst_type') }}</label>
                <Dropdown v-model="new_schema_dst_type" :placeholder="$t('dst_type')" style="width:100%;" :options="types"
                  optionLabel="name"></Dropdown>
                <label for="" class="pl-0 pb-0 mb-0">{{ $t('required') }}</label>
                <div class="col-12 p-0">
                  <InputSwitch v-model="new_schema_required"></InputSwitch>
                </div>
                
                <Button class="btn-custom-save mt-2" :label="$t('add')"
                  @click="addNewParamToIntakeSchemaConf(new_schema_dst, new_schema_src, new_schema_src_type, new_schema_dst_type, new_schema_required)" style="float:right"></Button>
              </div>
              <div class="col-4">
                <ScrollPanel style="height:20em">
                    <div class="card mb-1" v-for="param in new_intake_schema_params_array" :key="param">
                    <i class="pi pi-times mt-1 mr-1" @click="deleteParamFromIntakeSchemaConf(param.dst)"
                      style="position: absolute; top:0; right:0; cursor:pointer;"></i>
                    <div class="row m-0">
                      <p class="m-0 pr-2">SRC: <strong> {{ param.src }}</strong></p>
                    </div>
                    <div class="row m-0">
                      <p class="m-0 pr-2">DST: <strong> {{ param.dst }}</strong></p>
                    </div>
                    <!--
                    <div class="row m-0">
                      <p class="m-0 pr-2">{{ param.src_type }} -> {{ param.dst_type }}</p>
                    </div>
                    <div class="row m-0">
                      <p class="m-0 pr-2"><em>{{ $t('required') }}:</em> {{ param.required }}</p>
                    </div>
                    -->
                  </div>
                </ScrollPanel>
                
              </div>
            </div>
          </div>
        </div>
      </template>
      <template #footer>
        <Button class="btn-custom" :label="$t('save')"
          @click="addNewIntakeSchema(new_intake_name, new_intake_desc, new_intake_format)"></Button>
      </template>
    </Modal>
    <!--============================================================================== viewIntakeMethod ==============================================================================-->
    <Modal ref="viewIntakeMethod">
      <template #header>
      </template>
      <template #body>
        <div class="row">
          <div class="col-12 text-center pt-4">
            <i class="pi pi-comments" style="font-size:2.5em;"></i>
          </div>
          <div class="col-12 text-center pt-0 pb-0">
            <h5 class="modal-title">{{ selectedIntakeMethod.name }}</h5>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="row">
              <div class="col-12 pb-0">
                <p class="text-left m-0">{{ selectedIntakeMethod.description }}</p>
              </div>
              <div class="col-12 pt-0">
                <p class="text-left m-0"><strong>{{ $t('nifiListener') + ':'}}</strong>{{
                  selectedIntakeMethod.nifi_listener
                }}</p>
              </div>
              <p class="text-left m-0"><strong>{{ $t('configurationSchemaParams') }}</strong></p>
              <div class="temp pr-4">
                <div class="card mt-1 p-2" v-for="(schema, index) in selectedIntakeMethod.configuration_schema"
                  :key="schema">
                  <p class="text-left m-0"><strong>{{ index.toUpperCase() }}</strong></p>
                  <p class="text-left m-0"><em>{{ schema.type }}</em></p>
                  <p class="text-left m-0"><small>{{ schema.description }}</small></p>
                  <p class="text-left m-0"><small>{{ $t('default')+ ': '}} <strong>{{ schema.default }}</strong>
                    </small>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </Modal>
    <!--============================================================================== editIntegration ==============================================================================-->
    <Modal ref="editIntegration">
      <template #header>
      </template>
      <template #body>
        <div class="row">
          <div class="col-12 text-center pt-4">
            <i class="pi pi-th-large" style="font-size:2.5em;"></i>
          </div>
          <div class="col-12 text-center pt-0 pb-0">
            <h5 class="modal-title">{{ $t('updateIntegration') }}</h5>
          </div>
        </div>
        <div class="row">
          <div class="col-12 p-4" v-if="!toggleIntakeConfig">
            <label for="" class="pl-0 pb-0 mb-0">{{ $t('name') }}</label>
            <InputText type="text" v-model="temp_name" :placeholder="$t('integrationName')" style="width:100%;">
            </InputText>
            <label for="" class="pl-0 pb-0 mb-0 mt-1">{{ $t('description') }}</label>
            <Textarea type="text" v-model="temp_desc" :placeholder="$t('integrationDescription')" style="width:100%;">
            </Textarea>
            <label for="" class="pl-0 pb-0 mb-0 mt-1">{{ $t('integrationLogo') }}</label>
            <FileUpload name="demo[]" @upload="onAdvancedUpload($event)" :multiple="false" accept="image/*"
              :max-file-size="1000000">
              <template #content>
                <ul v-if="uploadedFiles && uploadedFiles[0]">
                  <li v-for="file of uploadedFiles[0]" :key="file">{{ file.name }} - {{ file.size }} bytes</li>
                </ul>
              </template>
              <template #empty>
                <p>Drag and drop files to here to upload.</p>
              </template>
            </FileUpload>
            <p style="color:#ec635f" v-if="data.intake_methods.length == 0">*{{
              $t('noConfigurationWarningForIntegrations')
            }}</p>
          </div>
          <div class="col-12 p-4" v-else>
            <div class="row ml-1 mr-1">
              <label for="" class="pl-0 pb-0 mb-0"><strong>{{ $t('selectIntakeMethods') }}:</strong></label>
            </div>
            <div class="row ml-1 mr-1">
              <div class="card mt-2" v-for="intake in selectedIntakeMethods" :key="intake">
                <div class="card-body">
                  <InputSwitch v-model="intake.active" class="mt-1 mr-1"
                    style="position: absolute; top:0; right:0; z-index:5"
                    @click="updateSelectedIntakeMethods(intake.id)" />
                  <h6 class="card-title mb-0">{{ intake.name }}</h6>
                  <p class="card-text m-0"><em>{{ intake.nifi_listener }}</em></p>
                  <p class="m-0"><small>{{ intake.description }}</small></p>
                  <p class="m-0 row-name-link"
                    @click="$refs.viewIntakeMethod.openModal(); selectedIntakeMethod = intake;">
                    <small>{{ $t('explore') }}</small>
                  </p>
                </div>
              </div>
              <div class="card mt-2 text-center pt-4 pb-4" @click="$refs.newIntakeMethod.openModal()"
                style="cursor:pointer">
                <i class="pi pi-plus"></i>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template #footer>
        <Button class="btn-custom-save" icon="pi pi-chevron-right" iconPos="right"
          :label="$t('continueWithIntakeConfiguration')" @click="toggleIntakeConfig = true"
          v-if="!toggleIntakeConfig"></Button>
        <Button class="btn-custom-save" icon="pi pi-chevron-left" iconPos="left" :label="$t('goback')"
          @click="toggleIntakeConfig = false" v-if="toggleIntakeConfig"></Button>
        <Button class="btn-custom" :label="$t('save')" @click="updateNewIntegration(temp_name, temp_desc)"></Button>
      </template>
    </Modal>

    <!--============================================================================== newIntakeMethod ==============================================================================-->
    <Modal ref="newIntakeMethod">
      <template #header>
      </template>
      <template #body>
        <div class="row">
          <div class="col-12 text-center pt-4">
            <i class="pi pi-question" style="font-size:2.5em;"></i>
          </div>
          <div class="col-12 text-center pt-0 pb-0">
            <h5 class="modal-title">{{ $t('createNewIntakeMethod') }}</h5>
          </div>
        </div>
        <div class="row">
          <div class="col-12 p-4">
            <label for="" class="pl-0 pb-0 mb-0">{{ $t('name') }}</label>
            <InputText type="text" v-model="new_intake_name" :placeholder="$t('name')" style="width:100%;">
            </InputText>
            <label for="" class="pl-0 pb-0 mb-0 mt-1">{{ $t('description') }}</label>
            <Textarea type="text" v-model="new_intake_desc" :placeholder="$t('description')" style="width:100%;">
            </Textarea>
            <label for="" class="pl-0 pb-0 mb-0">{{ $t('nifiListener') }}</label>
            <InputText type="text" v-model="new_intake_nifi" :placeholder="$t('nifiListener')" style="width:100%;">
            </InputText>
            <div class="row mt-4">
              <h6 class="mb-0"><strong>{{ $t('configurationSchemaParams') }}</strong></h6>
              <h6 class="mb-0" style="color:#ec635f" v-if="show_params_error">{{ $t('atLeastOneParamNeeded') }}</h6>
              <div class="col-8">
                <label for="" class="pl-0 pb-0 mb-0">{{ $t('name') }}</label>
                <InputText type="text" v-model="new_param_name" :placeholder="$t('intakeName')" style="width:100%;">
                </InputText>
                <label for="" class="pl-0 pb-0 mb-0">{{ $t('description') }}</label>
                <InputText type="text" v-model="new_param_desc" :placeholder="$t('intakeDescription')"
                  style="width:100%;">
                </InputText>
                <label for="" class="pl-0 pb-0 mb-0">{{ $t('type') }}</label>
                <Dropdown v-model="selectedType" :placeholder="$t('type')" style="width:100%;" :options="types"
                  optionLabel="name"></Dropdown>
                <Button class="btn-custom-save mt-2" :label="$t('add')"
                  @click="addNewParamToIntakeMethodConf(new_param_name, new_param_desc, selectedType)" style="float:right"></Button>
              </div>
              <div class="col-4">
                <div class="card" v-for="param in new_schema_params_array" :key="param">
                  <i class="pi pi-times mt-1 mr-1" @click="deleteParamFromIntakeMethodConf(param.name)"
                    style="position: absolute; top:0; right:0; cursor:pointer;"></i>
                  <div class="row m-0">
                    <p class="m-0 pr-2"><strong>{{ param.name }}</strong></p>
                  </div>
                  <div class="row m-0">
                    <p class="m-0 pr-2">{{ param.type }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template #footer>
        <Button class="btn-custom" :label="$t('save')"
          @click="addNewIntakeMethod(new_intake_name, new_intake_desc, new_intake_nifi, new_intake_conf_schema_name, new_schema_params)"></Button>
      </template>
    </Modal>
  </div>
</template>
<!----------------------------------------------->
<!--                    SCRIPT                 -->
<!----------------------------------------------->
<script>
import MyShared from "./shared";
export default {
  mixins: [MyShared],
};
</script>

<script setup>
import {Position,Handle, Panel, PanelPosition, VueFlow, isNode, useVueFlow } from '@vue-flow/core'
import { ref } from 'vue'
import { useMq } from "vue3-mq";
const mq = useMq();
</script>


<!----------------------------------------------->
<!--                    STYLES                 -->
<!----------------------------------------------->
<style>
@import '@vue-flow/core/dist/style.css';
@import '@vue-flow/core/dist/theme-default.css';
.vue-flow{
  height: 25em !important;
}
.node-title{
  font-size: 0.8em !important;
}
.scroll-area {
  position: relative;
  margin: auto;
  width: 600px;
  height: 400px;
}

.configmenu {
  position: absolute;
  right: 0;
  z-index: 2;
  height: 100%;
  width: 40em;
  top: 0;
  padding: 3rem;
  padding-left: 1rem;
  padding-top: 60px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  background-color: white;
}

.user-container-img {
  padding: 0.5rem;
  padding-right: 0em !important;
  width: max-content !important;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.user-container-name {
  display: inline-block;
  width: max-content !important;
  cursor: pointer;
}

.listener-status-scroll {
  height: 10em;
  overflow-y: scroll;
  padding-right: 0.5em;
}

/*
  Enter and leave animations can use different
  durations and timing functions.
*/
.side-slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.side-slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.side-slide-fade-enter-from,
.side-slide-fade-leave-to {
  transform: translateX(80px);
  opacity: 0;
}

.step-template {
  padding: 1rem;
  padding-left: 2rem;
  margin-left: 1.25rem !important;
  border-left: 1px solid rgb(192, 192, 192);
  margin-top: 0;
  padding-top: 0 !important;
}

.custom .p-scrollpanel-wrapper {
  border-right: 9px solid #ffffff;
}

.custom .p-scrollpanel-bar {
  background-color: #f0f0f0 !important;
  opacity: 1;
  transition: background-color .3s;
}

.custom .p-scrollpanel-bar:hover {
  background-color: #dddddd !important;
}


.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background: #ec635f !important;
}

.p-inputswitch .p-inputswitch-slider {
  background: #ced4da;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 30px;
}

.p-inputswitch {
  width: 2rem !important;
  height: 1rem !important;
}

.p-inputswitch .p-inputswitch-slider::before {
  background: #ffffff;
  width: 0.8rem !important;
  height: 0.8rem !important;
  left: 0.15rem !important;
  margin-top: -0.43rem !important;
  border-radius: 50%;
  transition-duration: 0.2s;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider::before {
  transform: translateX(0.9rem) !important;
}

.p-inputswitch-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.delete-icon {
  color: #fafafd;
  position: relative;
  top: 50%;
  cursor: pointer;
}
.default-img-letter {
  margin: 0;
  font-size: 2em;
}
.default-img-v1 {
  height: 4em;
  width: 4em;
  line-height: 3.8em;
  margin: auto;
  background: #ec635f !important;
  color: white;
  border-radius: 500px;
  text-transform: uppercase;
  font-size: 1em;
  text-align: center;
  padding: 0;
}

.default-img-v2 {
  height: 4em;
  width: 4em;
  line-height: 3.8em;
  margin: auto;
  background-color: #9fd6dd;
  color: white;
  border-radius: 500px;
  text-transform: uppercase;
  font-size: 1em;
  text-align: center;
  padding: 0;
}

.default-img-v3 {
  height: 4em;
  width: 4em;
  line-height: 3.8em;
  margin: auto;
  background-color: #ffce36;
  color: white;
  border-radius: 500px;
  text-transform: uppercase;
  font-size: 1em;
  text-align: center;
  padding: 0;
}
</style>
