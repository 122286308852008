import $axios from '../utils';

export function getAllDetectionRules(full = true, sdate = '1975-01-01T00:00:00', edate = '3000-01-01T00:00:00', offset = 0, limit = 10000) {
    return $axios.get(`analysis/detection_rule/?full=${full}&offset=${offset}&limit=${limit}&sdate=${sdate}&edate=${edate}`)
        .then(response => response.data)
}
export function getDetectionRulesBy(key, value, full, offset, limit) {
    return $axios.get(`analysis/detection_rule/by?key${key}&value=${value}&full=${full}&offset=${offset}&limit=${limit}`)
        .then(response => response.data)
}
export function getOneDetectionRule(id, full) {
    return $axios.get(`analysis/detection_rule/${id}?full=${full}`)
        .then(response => response.data)
}
export function createDetectionRule(data) {
    return $axios.post('analysis/detection_rule/', data)
        .then(response => response.data)
}
export function updateDetectionRule(id, data) {
    return $axios.put(`analysis/detection_rule/${id}`, data)
        .then(response => response.data)
}
export function deleteAllDetectionRules() {
    return $axios.delete('analysis/detection_rule/')
        .then(response => response.data)
}
export function deleteDetectionRule(id) {
    return $axios.delete(`analysis/detection_rule/${id}`)
        .then(response => response.data)
}