<!----------------------------------------------->
<!--                  TEMPLATE                 -->
<!----------------------------------------------->
<template>
  <MobileAlertConfigurationDetails v-if="  mq.current  === 'mobile'"></MobileAlertConfigurationDetails>
  <TabletAlertConfigurationDetails v-if="  mq.current  === 'tablet'"></TabletAlertConfigurationDetails>
  <DesktopAlertConfigurationDetails v-if=" mq.current  === 'desktop'"></DesktopAlertConfigurationDetails>
</template>

<!----------------------------------------------->
<!--                    SCRIPT                 -->
<!----------------------------------------------->
<script>
import MobileAlertConfigurationDetails from "../components/AlertConfigurationDetails/MobileAlertConfigurationDetails";
import TabletAlertConfigurationDetails from "../components/AlertConfigurationDetails/TabletAlertConfigurationDetails";
import DesktopAlertConfigurationDetails from "../components/AlertConfigurationDetails/DesktopAlertConfigurationDetails";

export default {
  /* Name */
  name: "AlertConfigurationDetails",
  inject: ["mq"],
  /* Components */
  components: {
    MobileAlertConfigurationDetails,
    TabletAlertConfigurationDetails,
    DesktopAlertConfigurationDetails,
  },
};
</script>

<script setup>
import { useMq } from "vue3-mq";
const mq = useMq();
</script>

<!----------------------------------------------->
<!--                    STYLES                 -->
<!----------------------------------------------->
<style scoped >
</style>
