import * as $api from "../../api/api";

var MyShared = {
    /* Name */
    name: "Disputas",
    /* Data */
    data() {
        return {
            user: {
                "permissions": []
            },
            pass: '',
            confirm_pass: '',
            error: ''
        };
    },
    /* Components */
    components: {},
    /* Props */
    props: {},
    /* Methods */
    methods: {
        recoverPassword() {
            $api.resetPassword(this.token, {
                "new_password": this.pass,
                "verify_password": this.confirm_pass
            }).then((response) => {
                console.log("RESET PASS: ", response);
                this.$toast.add({ severity: 'success', summary: this.$t('success'), detail: this.$t('The password was successfully changed'), life: 3000 });
                this.error = '';
            }).catch((err) => {
                console.log(`[-] Error (resetPassword): ${err}`);
                this.$toast.add({ severity: 'error', summary: this.$t('error'), detail: this.$t('The password could not be changed'), life: 3000 });
                this.error = err.response.data.detail;
            });
        }
    },
    mounted() {
        // get token from url
        this.token = this.$route.params.token;
        console.log(this.token);
    }
};
export default MyShared;