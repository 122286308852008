import $axios from '../utils';

export function getAllDashboards(full = true, offset = 0, limit = 10000) {
    return $axios.get(`dashboard/dashboard/?full=${full}&offset=${offset}&limit=${limit}`)
        .then(response => response.data)
}
export function getDashboardsBy(key, value, full, offset, limit) {
    return $axios.get(`dashboard/dashboard/by?key${key}&value=${value}&full=${full}&offset=${offset}&limit=${limit}`)
        .then(response => response.data)
}
export function getOneDashboard(id, full = true) {
    return $axios.get(`dashboard/dashboard/${id}?full=${full}`)
        .then(response => response.data)
}
export function createDashboard(data) {
    return $axios.post('dashboard/dashboard/', data)
        .then(response => response.data)
}
export function updateDashboard(id, data) {
    return $axios.put(`dashboard/dashboard/${id}`, data)
        .then(response => response.data)
}
export function deleteAllDashboards() {
    return $axios.delete('dashboard/dashboard/')
        .then(response => response.data)
}
export function deleteDashboard(id) {
    return $axios.delete(`dashboard/dashboard/${id}`)
        .then(response => response.data)
}