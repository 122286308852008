<!----------------------------------------------->
<!--                  TEMPLATE                 -->
<!----------------------------------------------->
<template>
  <MobileInvestigation v-if="mq.current === 'mobile'"></MobileInvestigation>
  <TabletInvestigation v-if="mq.current === 'tablet'"></TabletInvestigation>
  <DesktopInvestigation v-if="mq.current === 'desktop'"></DesktopInvestigation>
</template>

<!----------------------------------------------->
<!--                    SCRIPT                 -->
<!----------------------------------------------->
<script>
import MobileInvestigation from "../components/Investigations/MobileInvestigations";
import TabletInvestigation from "../components/Investigations/TabletInvestigations";
import DesktopInvestigation from "../components/Investigations/DesktopInvestigations";

export default {
  /* Name */
  name: "Investigation",
  inject: ["mq"],
  /* Components */
  components: {
    MobileInvestigation,
    TabletInvestigation,
    DesktopInvestigation,
  },
  methods: {

  },
  /*
  beforeRouteLeave: function(to, from, next) {
      if (this.getDashboardSaved()) {
        next()
      } else {
        const answer = window.confirm('Do you really want to leave? you have unsaved changes!')
        if (answer) {
          next()
        } else {
          next(false)
        }
      }
    }
    */
};
</script>

<script setup>
import { useMq } from "vue3-mq";
const mq = useMq();
</script>

<!----------------------------------------------->
<!--                    STYLES                 -->
<!----------------------------------------------->
<style scoped ></style>
