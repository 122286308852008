<!----------------------------------------------->
<!--                  TEMPLATE                 -->
<!----------------------------------------------->
<template>
  <div class="aux" style="height: 100vh;" v-if="!loading">
    <div class="login-container">
      <div class="form-container" v-if="!register_success">
        <div class="login-header text-center">
          <img class="logo" v-bind:src="projectLogo" alt="logo" />
        </div>
        <div class="field row pass-field">
          <h5 class="p-0">Hi! {{name + ', ' + $t('choosePassForNewAccount') }}</h5>
        </div>
        
        <div class="field row pass-field">
          <label for="gender" class="pl-0 mt-1 mb-0">{{ $t('gender') }}</label>
          <Dropdown name="gender" id="gender" style="width: 100%;" :options="['male', 'female', 'other']" v-model="gender"></Dropdown>
        </div>
        <div class="field row pass-field">
          <label for="phone" class="pl-0 mt-1 mb-0">{{ $t('phone') }}</label>
          <MazPhoneNumberInput
            v-model="phone"
            show-code-on-list
            color="info"
            @update="results = $event"
            :success="results?.isValid"
          />
        </div>
        <div class="field row pass-field">
          <label for="birthdate" class="pl-0 mt-1 mb-0">{{ $t('birthdate') }}</label>
          <Calendar class="p-0" v-model="birthdate"></Calendar>
        </div>
        <div class="field row pass-field">
          <label for="country" class="pl-0 mt-1 mb-0">{{ $t('country') }}</label>
          <InputText name="country" id="country" v-model="country" autocomplete="new-password"></InputText>
        </div>
        <div class="field row pass-field">
          <label for="slack_uname" class="pl-0 mt-1 mb-0">{{ $t('slackUsername') }}</label>
          <InputText type="text" class="p-0" name="slack_uname" id="slack_uname" v-model="slack_uname" autocomplete="new-password"></InputText>
        </div>
        <div class="field row pass-field">
          <label for="pass" class="pl-0 mt-1 mb-0">{{ $t('password') }}</label>
          <p class="show_error" v-if="show_error">{{error}}</p>
          <InputText id="pass" type="password" v-model="pass" class="input-text"></InputText>
        </div>
        <div class="field row pass-field">
          <label for="confirm_pass" class="pl-0 mt-1 mb-0">{{ $t('confirmPassword') }}</label>
          <InputText id="confirm_pass" type="password" v-model="confirm_pass" class="input-text"></InputText>
        </div>

        <div class="row button-row mt-4">
          <Button class="btn-custom btn-login" label="Register" @click="register()"></Button>
        </div>
        
      </div>

      <div class="form-container" v-else>
        <div class="login-header text-center">
          <img class="logo" v-bind:src="projectLogo" alt="logo" />
        </div>
        <div class="field row text-center">
          <router-link to="/" class="pl-0">
            <Button class="btn-custom btn-login" label="Go login"></Button>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<!----------------------------------------------->
<!--                    SCRIPT                 -->
<!----------------------------------------------->

<script>
import MyShared from "./shared";
export default {
  mixins: [MyShared],
};

</script>

<script setup>
import { useMq } from "vue3-mq";
const mq = useMq();
</script>
<!----------------------------------------------->
<!--                    STYLES                 -->
<!----------------------------------------------->
<style scoped lang="css" >
* {
  font-family: 'Open Sans', sans-serif !important;
}

a {
  text-decoration: none !important;
  color: #ec635f !important;
}

.aux {
  background-image: url("../../assets/bg-login1.png");
  background-size: cover;
  background-repeat: no-repeat;}

.button-row {
  width: 100%;
}

.btn-login {
  float: right;
  font-weight: 400 !important;
}

.forgot-pass {
  font-size: 0.9em;
  margin-top: 0.25em;
  font-weight: 600;
  margin-top: 0.5em;
}

.input-text {
  width: 100%;
}

.pass-field {
  margin-top: 1em;
}

.email-field {
  margin-top: 3em;
}

.form-container {
  padding-left: 2em;
  padding-right: 2em;
  padding-bottom: 6em;
  padding-top: 1em;
}

.logo {
  width: 100%;
  height: 8em;
  margin-bottom: 1em;
  margin-top: 2em;
}

.login-container {
  width: 30em;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.title {
  color: black;
}

/* Custom buttons */
.btn-custom {
  margin: auto;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  background: #ec635f !important;
  color: white;
  border: none;
  padding: 0.25rem 0.5rem;
  font-weight: bold;
  width: auto;
}

.btn-custom:focus {
  box-shadow: 0px 0px 2px #ec635f !important;
}

.btn-custom:hover {
  background: #d65955 !important;
}

.p-inputtext:focus {
  border-color: #ec635f !important;
  box-shadow: 0px 0px 2px #ec635f !important;
  outline: none;
}

.p-inputtext:hover {
  border-color: #ec635f !important;
}
.show_error{
  color: #ec635f;
  font-size: 0.8em;
  margin-left: 0 !important;
  padding: 0 !important;
}
.m-phone-number-input{
  padding: 0 !important;
}
</style>
