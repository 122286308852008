import * as $api from "../../api/api";

var MyShared = {
    /* Name */
    name: "Disputas",
    /* Data */
    data() {
        return {
            user: {
                "permissions": []
            },
            gender: '',
            phone: '',
            birthdate: ' ',
            pass: '',
            confirm_pass: '',
            country: '',
            slack_uname: '',
            token: '',
            register_success: false,
            error: '',
            show_error: false,
            name: '',
            loading: true
        };
    },
    /* Components */
    components: {},
    /* Props */
    props: {},
    /* Methods */
    methods: {
        register() {
            $api.register(this.token, {
                    "gender": this.gender,
                    "phone": this.phone,
                    "birthdate": this.birthdate,
                    "country": this.country,
                    "slack_uname": this.slack_uname,
                    "password": this.pass,
                    "verify_password": this.confirm_pass,
                })
                .then(response => {
                    console.log(response);
                    this.$toast.add({ severity: 'info', summary: 'Confirmed', detail: 'Register completed', life: 3000 });
                    this.register_success = true;
                }).catch((err) => {
                    console.log(`[-] Error (register): ${err}`);
                    console.log(err.response);
                    this.show_error = true;
                    this.error = err.response.data.detail;
                })
        }
    },
    beforeMount() {
        // get token from url
        this.token = this.$route.params.token;
        console.log(this.token);
        $api.checkRegister(this.token).then((response) => {
            console.log("CHECK REGISTER: ", response);
            this.name = response.full_name
            this.loading = false;
        }).catch((err) => {
            console.log(`[-] Error (checkRegister): ${err}`);
            // this.$router.push({ name: 'SignIn' });
            this.loading = false;
        });
    },
    mounted() {

    }
};
export default MyShared;