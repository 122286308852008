import $axios from '../utils';

export function getAllCorrelationRules(full = true, sdate = '1975-01-01T00:00:00', edate = '3000-01-01T00:00:00', offset = 0, limit = 10000) {
    return $axios.get(`analysis/correlation_rule/?full=${full}&offset=${offset}&limit=${limit}&sdate=${sdate}&edate=${edate}`)
        .then(response => response.data)
}
export function getCorrelationRulesBy(key, value, full, offset, limit) {
    return $axios.get(`analysis/correlation_rule/by?key${key}&value=${value}&full=${full}&offset=${offset}&limit=${limit}`)
        .then(response => response.data)
}
export function getOneCorrelationRule(id, full) {
    return $axios.get(`analysis/correlation_rule/${id}?full=${full}`)
        .then(response => response.data)
}
export function createCorrelationRule(data) {
    return $axios.post('analysis/correlation_rule/', data)
        .then(response => response.data)
}
export function updateCorrelationRule(id, data) {
    return $axios.put(`analysis/correlation_rule/${id}`, data)
        .then(response => response.data)
}
export function deleteAllCorrelationRules() {
    return $axios.delete('analysis/correlation_rule/')
        .then(response => response.data)
}
export function deleteCorrelationRule(id) {
    return $axios.delete(`analysis/correlation_rule/${id}`)
        .then(response => response.data)
}