<!----------------------------------------------->
<!--                  TEMPLATE                 -->
<!----------------------------------------------->
<template>
  <MobileMachineLearning v-if="  mq.current  === 'mobile'"></MobileMachineLearning>
  <TabletMachineLearning v-if="  mq.current  === 'tablet'"></TabletMachineLearning>
  <DesktopMachineLearning v-if=" mq.current  === 'desktop'"></DesktopMachineLearning>
</template>

<!----------------------------------------------->
<!--                    SCRIPT                 -->
<!----------------------------------------------->
<script>
import MobileMachineLearning from "../components/MachineLearning/MobileMachineLearning";
import TabletMachineLearning from "../components/MachineLearning/TabletMachineLearning";
import DesktopMachineLearning from "../components/MachineLearning/DesktopMachineLearning";

export default {
  /* Name */
  name: "MachineLearning",
  inject: ["mq"],
  /* Components */
  components: {
    MobileMachineLearning,
    TabletMachineLearning,
    DesktopMachineLearning,
  },
};
</script>

<script setup>
import { useMq } from "vue3-mq";
const mq = useMq();
</script>

<!----------------------------------------------->
<!--                    STYLES                 -->
<!----------------------------------------------->
<style scoped >
</style>
