<!----------------------------------------------->
<!--                  TEMPLATE                 -->
<!----------------------------------------------->
<template>
  <div class="aux">
  <Sitebar></Sitebar>
  <div class="maincontainer" >
    <div class="info-card" v-if="!close">
      <div class="col-12 p-0 m-0" style="width:100%">
        <i class="pi pi-times info-card-icon-close "
          style="float: right; cursor: pointer; z-index: 999; position:relative;" @click="close = !close"></i>
      </div>
      <div class="row">
        <i class="pi pi-info-circle info-card-icon mr-2 pl-1" style="position:absolute;"></i>
        <p class="pt-0 mt-0 mb-0" style="padding-left: 3.25em"><strong>{{ $t('eventInfoTitle') }}</strong></p>
        <p class="pt-0 mt-0" style="padding-left: 3.25em">{{ $t('eventInfoDescription') }}</p>
      </div>
    </div>
    <div class="row details-asset-title mr-0" v-if="loading_details"><ContentLoader height="60px"></ContentLoader></div>
    <div class="row details-asset-title mr-0" v-else>
      <h3 v-if="details.severity == 0">
        {{ details.title }}&nbsp;<i class="pi pi-circle-fill" style="color:#aeb5c1"></i>
      </h3>
      <h3 v-if="details.severity == 1">
        {{ details.title }}&nbsp;<i class="pi pi-circle-fill" style="color:#6dc193"></i>
      </h3>
      <h3 v-if="details.severity == 2">
        {{ details.title }}&nbsp;<i class="pi pi-circle-fill" style="color:#f7b34c"></i>
      </h3>
      <h3 v-if="details.severity == 3">
        {{ details.title }}&nbsp;<i class="pi pi-circle-fill" style="color:#ef6154"></i>
      </h3>
      <h3 v-if="details.severity == 4">
        {{ details.title }}&nbsp;<i class="pi pi-circle-fill" style="color:#b83c32"></i>
      </h3>
      <p class="mb-0">
        <span class="id-prefix" style="text-decoration:none;"></span>
        <span class="details-id" style="text-decoration:none; cursor:default;">
          {{ details.id }}
        </span>
      </p>
    </div>
    <br>
    <div class="row">
      <div class="col-4 main-details-info p-0">
        <div class="scroll" style="height:auto; overflow-y:hidden; overflow-x:hidden;">
          <div class="sub-scroll p-2">
            <div class="row details-data pl-2 pr-2">
              <div class="col-6 p-2" v-if="loading_details"><ContentLoader class="pl-4" height="40px" width="95%"></ContentLoader></div>
              <div class="col-6 p-1" v-else>
                <div :class="!locked ? 'dropdown' : ''">
                  <Card :class="!locked ? 'dropdown-toggle' : 'disabled'" :data-toggle="!locked ? 'dropdown' : ''"
                    style="display: -webkit-box;" id="type-dropdown">
                    <template #content>
                      <i class="fa-solid fa-filter subdetail-icon">
                        <p class="subdetail-name">&nbsp;{{ prettifyString(details.status[details.status.length-1].status) }}</p>
                        <p class="subdetail-tag">&nbsp;{{ $t('status')}}</p>
                      </i>
                    </template>
                  </Card>
                  <div class="dropdown-menu">
                    <a class="dropdown-item" v-for="status in statuses" :key="status"
                      @click="addNewStatus(status.status)">
                      {{ status.label }}
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-6 p-2" v-if="loading_details"><ContentLoader class="pl-4" height="40px" width="95%"></ContentLoader></div>
              <div class="col-6 p-1" v-else>
                <div :class="!locked ? 'dropdown' : ''">
                  <Card :class="!locked ? 'dropdown-toggle' : 'disabled'" :data-toggle="!locked ? 'dropdown' : ''"
                    style="display: -webkit-box;" id="criticality-dropdown">
                    <template #content>
                      <i v-if="details.severity == 0" class="fa-solid fa-shield-halved subdetail-icon"
                        style="color:#aeb5c1">
                        <p class="subdetail-name" v-if="details.severity == 0">&nbsp;{{ $t('informational') }}</p>
                        <p class="subdetail-tag">&nbsp;{{ $t('severity') }}</p>
                      </i>
                      <i v-if="details.severity == 1" class="fa-solid fa-shield-halved subdetail-icon"
                        style="color:#6dc193">
                        <p class="subdetail-name" v-if="details.severity == 1">&nbsp;{{ $t('low') }}</p>
                        <p class="subdetail-tag">&nbsp;{{ $t('severity') }}</p>
                      </i>
                      <i v-if="details.severity == 2" class="fa-solid fa-shield-halved subdetail-icon"
                        style="color:#f7b34c">
                        <p class="subdetail-name" v-if="details.severity == 2">&nbsp;{{ $t('medium') }}</p>
                        <p class="subdetail-tag">&nbsp;{{ $t('severity') }}</p>
                      </i>
                      <i v-if="details.severity == 3" class="fa-solid fa-shield-halved subdetail-icon"
                        style="color:#ef6154">
                        <p class="subdetail-name" v-if="details.severity == 3">&nbsp;{{ $t('high') }}</p>
                        <p class="subdetail-tag">&nbsp;{{ $t('severity') }}</p>
                      </i>
                      <i v-if="details.severity == 4" class="fa-solid fa-shield-halved subdetail-icon"
                        style="color:#b83c32">
                        <p class="subdetail-name" v-if="details.severity == 4">&nbsp;{{ $t('critical') }}</p>
                        <p class="subdetail-tag">&nbsp;{{ $t('severity') }}</p>
                      </i>
                    </template>
                  </Card>
                  <div class="dropdown-menu">
                    <a class="dropdown-item" v-for="severity in criticalities" :key="severity"
                      @click="details.severity = severity.value">
                      {{ severity.label }}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="row details-data mt-2" v-if="loading_details"><ContentLoader height="25em"></ContentLoader></div>
            <div class="row details-data mt-2 pl-2 pr-2" v-else style="padding-bottom:4em;">
              <div class="row pr-0">
                <p class="mb-0 main-details-title"><strong>{{ $t('description') }}</strong></p>
                <div class="col-12 pt-0 pb-0">
                  <p v-if="locked" class="mb-1">{{ details.description }}</p>
                  <Textarea v-else v-model="details.description" :disabled="locked" style="width:100%;"></Textarea>
                </div>
              </div>
              <div class="row pr-0">
                <p class="mb-1 main-details-title"><strong>{{ $t('createdAt') }}</strong></p>
                <div class="col-12 pt-0 pb-0">
                  <p class="mb-1">{{ details.created_at }}</p>
                </div>
              </div>
              <div class="row pr-0">
                <p class="mb-0 main-details-title"><strong>{{ $t('source') }}</strong></p>
                <div class="col-12 pt-0 pb-0">
                  <p class="mb-1">{{ details.source.name }}</p>
                </div>
              </div>
              <div class="row pr-0">
                <p class="mb-0 main-details-title"><strong>{{ $t('detectionRule') }}</strong></p>
                <div class="row ml-0 mt-2">
                  <div class="detection-card">
                    <p class="mt-0 mb-0"><strong>{{ details.detection_rule.title }}</strong></p>
                    <p class="details-id mt-0 mb-0" style="font-size: 0.8em;"
                      @click="$router.push({ path: 'detection-rule-details', query: { id: details.detection_rule.id } })">
                      {{ details.detection_rule.id }}
                    </p>
                    <p class="mt-0 mb-0" style="font-size: 0.9em;">{{ details.detection_rule.description }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 pt-2 mt-4 details-actions-div">
          <Button v-if="!locked" class="btn-custom-save mr-2" :label="$t('save')" @click="onSaveUpdate();"></Button>
          <Button v-if="locked" class="btn-custom-save mr-2" :label="$t('edit')" @click="unlockForm()"></Button>
          <Button v-if="!locked" class="btn-custom-cancel mr-2" :label="$t('cancel')" @click="onCancelUpdate();"></Button>
          <Button v-if="!locked" class="btn-custom-cancel mr-2" :label="$t('delete')" @click="lockForm()"
            style="float:right;" icon="pi pi-trash"></Button>
        </div>
      </div>

      <div class="col-8 pt-0 pb-0">
        <div class="pad-helper main-table pt-2 pr-2 pl-2 m-0" style="height:100%;">
        <div class="sec-details-info">
          <TabView>
            <!-- ====================================================================== Incidents =========================================================================== -->
            <TabPanel :header="$t('incidents')">
              <div class="row pb-2 pl-3 pr-3 pt-1" style="overflow-y: auto; overflow-x:hidden;">
                <div class="aux p-0">
                  <div class="row mt-0">
                    <div class="col-6 text-center" v-if="loading_details"><ContentLoader></ContentLoader></div>
                    <div class="col-6 pt-0" v-else style="height:100%;">
                      <Chart type="line" :data="lineChartData" :options="lineChartOptions" />
                    </div>
                    <div class="col-6 text-center" v-if="loading_details"><ContentLoader></ContentLoader></div>
                    <div class="col-6 pt-0" v-else style="height:100%;">
                      <Chart type="bar" :data="barData" :options="barOptions" />
                    </div>
                  </div>
                  <div class="col-12 pl-0 pr-0 text-center" v-if="loading_details"><ContentLoader></ContentLoader></div>
                  <DataTable v-else :value="details.events" :paginator="true" class="p-datatable-customers mt-2" :rows="8"
                    :row-hover="true" dataKey="id" v-model:filters="filters" filter-display="menu"
                    :loading="loading_details" :selectionMode="(!editMode)?'single':''"
                    paginator-template="PrevPageLink PageLinks NextPageLink CurrentPageReport"
                    :rows-per-page-options="[10, 25, 50]"
                    current-page-report-template="Showing {first} to {last} of {totalRecords} entries"
                    :global-filter-fields="['id', 'name', 'os', 'host', 'type', 'criticality', 'last_act_date', 'risk']"
                    responsive-layout="scroll" @row-click="setSelectedData($event.data)">
                    <template #empty>
                      {{$t('noDataFound')}}
                    </template>
                    <template #loading>
                     {{$t('loadingData')}}
                    </template>
                    <Column field="id" :header="$t('id')" sortable>
                      <template #body="{ data }">
                        <span @click="$router.push({ path: 'incident-details', query: { id: data.id } })"
                          class="row-name-link">{{
                              data.id
                          }} </span>
                      </template>
                    </Column>
                    <Column field="title" :header="$t('title')" sortable>
                      <template #body="{ data }">
                        {{ data.title }}
                      </template>
                    </Column>
                    <Column field="severity" :header="$t('severity')" sortable>
                      <template #body="{ data }">
                        <span v-if="data.severity == 0"><i class="pi pi-circle-fill  criticality-filter-Info"
                            style="font-size: 0.7em;"></i>&nbsp;{{ $t('informational') }}</span>
                        <span v-if="data.severity == 1"><i class="pi pi-circle-fill  criticality-filter-Low"
                            style="font-size: 0.7em;"></i>&nbsp;{{ $t('low') }}</span>
                        <span v-if="data.severity == 2"><i class="pi pi-circle-fill  criticality-filter-Medium"
                            style="font-size: 0.7em;"></i>&nbsp;{{ $t('medium') }}</span>
                        <span v-if="data.severity == 3"><i class="pi pi-circle-fill  criticality-filter-High"
                            style="font-size: 0.7em;"></i>&nbsp;{{ $t('high') }}</span>
                        <span v-if="data.severity == 4"><i class="pi pi-circle-fill  criticality-filter-Critical"
                            style="font-size: 0.7em;"></i>&nbsp;{{ $t('critical') }}</span>
                      </template>
                    </Column>
                    <Column field="correlation_rule" :header="$t('correlationRule')" sortable>
                      <template #body="{ data }">
                        {{ data.correlation_rule.title }}
                      </template>
                    </Column>
                    <Column field="created_at" :header="$t('createdAt')" sortable>
                      <template #body="{ data }">
                        {{ formatDate(data.created_at) }}
                      </template>
                    </Column>
                    <Column field="status" :header="$t('status')" sortable>
                      <template #body="{ data }">
                        {{ prettifyString(data.status[data.status.length - 1].status) }}
                      </template>
                    </Column>
                  </DataTable>
                </div>
              </div>
            </TabPanel>
            <!-- ====================================================================== Status history =========================================================================== -->
            <TabPanel :header="$t('statusHistory')">
              <div class="loader text-center mt-2" style="width:100%;" v-if="loading_details">
                <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                <p>{{ $t('loading') }}</p>
              </div>
              <ScrollPanel style="width: 100%; height:39em" class="custom" v-else> 
              <div class="row pb-2 pl-3 pr-3 pt-1" style="overflow-y:auto;">
                <div class="aux p-0" :style="tab_position ? 'height:55vh;' : 'height:35vh;'">
                  <OpenGroup v-for="(status, index) in details.status" :key="status"
                    :groupName="status.status + ' - ' + status.date" :openFirst="index == 0 ? true : false">
                    <template #body>
                      <p class="p-0 m-0 mt-2 group-title "><strong>{{ $t('status') }}</strong></p>
                      <p class="p-0 m-0 group-data">{{ status.status }}</p>
                      <p class="p-0 m-0 mt-2 group-title "><strong>{{ $t('description') }}</strong></p>
                      <p class="p-0 m-0 group-data">{{ status.description }}</p>
                      <p class="p-0 m-0 mt-2 group-title "><strong>{{ $t('date') }}</strong></p>
                      <p class="p-0 m-0 group-data">{{ status.date }}</p>
                    </template>
                  </OpenGroup>
                </div>
              </div>
              </ScrollPanel>
            </TabPanel>
          </TabView>
        </div>
      </div>
    </div>
    </div>
  </div>
</div>
</template>
<!----------------------------------------------->
<!--                    SCRIPT                 -->
<!----------------------------------------------->

<script>
import MyShared from "./shared";
export default {
mixins: [MyShared],
};

</script>

<script setup>
import { useMq } from "vue3-mq";
const mq = useMq();
</script>

<!----------------------------------------------->
<!--                    STYLES                 -->
<!----------------------------------------------->
<style>

</style>
