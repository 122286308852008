<!----------------------------------------------->
<!--                  TEMPLATE                 -->
<!----------------------------------------------->
<template>
  <div class="aux">
    <Sitebar></Sitebar>
    <div class="maincontainer" >
      <div class="info-card" v-if="!close">
        <div class="col-12 p-0 m-0" style="width:100%">
          <i class="pi pi-times info-card-icon-close "
            style="float: right; cursor: pointer; z-index: 999; position:relative;" @click="close = !close"></i>
        </div>
        <div class="row">
          <i class="pi pi-info-circle info-card-icon mr-2 pl-1" style="position:absolute;"></i>
          <p class="pt-0 mt-0 mb-0" style="padding-left: 3.25em"><strong>{{ $t('assetInfoTitle') }}</strong></p>
          <p class="pt-0 mt-0" style="padding-left: 3.25em">{{ $t('assetInfoDescription') }}</p>
        </div>
      </div>
      <div class="row details-asset-title mr-0" v-if="loading_details">
        <ContentLoader height="60px"></ContentLoader>
      </div>
      <div class="row details-asset-title mr-0" v-else>
        <h3 v-if="details.criticality == 1">
          {{ details.name }}&nbsp;<i class="pi pi-circle-fill" style="color:#6dc193"></i>
        </h3>
        <h3 v-if="details.criticality == 2">
          {{ details.name }}&nbsp;<i class="pi pi-circle-fill" style="color:#f7b34c"></i>
        </h3>
        <h3 v-if="details.criticality == 3">
          {{ details.name }}&nbsp;<i class="pi pi-circle-fill" style="color:#ef6154"></i>
        </h3>
        <h3 v-if="details.criticality == 4">
          {{ details.name }}&nbsp;<i class="pi pi-circle-fill" style="color:#b83c32"></i>
        </h3>
        <p class="mb-0">
          <span class="id-prefix" style="text-decoration:none;"></span>
          <span class="details-id" style="text-decoration:none; cursor:default;">
            {{ details.id }}
          </span>
        </p>
      </div>
      <br>
      <div class="row" style="margin-left: 0px;">
        <div class="col-4 main-details-info p-0">
          <div class="scroll" style="height:auto; overflow-y:hidden; overflow-x:hidden;">
            <div class="sub-scroll p-2">
              <div class="row details-data pl-2 pr-2">
                <div class="col-4" v-if="loading_details">
                  <ContentLoader></ContentLoader>
                </div>
                <div class="col-4 p-1" v-else>
                  <div :class="!locked ? 'dropdown' : ''">
                    <Card :class="!locked ? 'dropdown-toggle' : 'disabled'" :data-toggle="!locked ? 'dropdown' : ''"
                      style="display: -webkit-box;" id="type-dropdown">
                      <template #content>
                        <i class="fa-solid fa-tag subdetail-icon">
                          <p class="subdetail-name">&nbsp;{{ details.type }}</p>
                          <p class="subdetail-tag">&nbsp;{{ $t('type') }}</p>
                        </i>
                      </template>
                    </Card>
                    <div class="dropdown-menu">
                      <a class="dropdown-item" v-for="type in types" :key="type" @click="details.type = type.label">
                        {{ type.label }}
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-4" v-if="loading_details">
                  <ContentLoader></ContentLoader>
                </div>
                <div class="col-4 p-1" v-else>
                  <div :class="!locked ? 'dropdown' : 'aux'">
                    <Card :class="!locked ? 'dropdown-toggle' : 'disabled'" :data-toggle="!locked ? 'dropdown' : ''"
                      style="display: -webkit-box;" id="location-dropdown">
                      <template #content>
                        <i class="fa-solid fa-location-dot subdetail-icon">
                          <p class="subdetail-name">&nbsp;{{ details.location.city }}</p>
                          <p class="subdetail-tag">&nbsp;{{ $t('location') }}</p>
                        </i>
                      </template>
                    </Card>
                    <div class="dropdown-menu p-2">
                      <label for="" style="font-size:small">New locations</label>
                      <InputText v-model="details.location.city" placeholder="City" />
                    </div>
                  </div>
                </div>
                <div class="col-4" v-if="loading_details">
                  <ContentLoader></ContentLoader>
                </div>
                <div class="col-4 p-1" v-else>
                  <div :class="!locked ? 'dropdown' : ''">
                    <Card :class="!locked ? 'dropdown-toggle' : 'disabled'" :data-toggle="!locked ? 'dropdown' : ''"
                      style="display: -webkit-box;" id="criticality-dropdown">
                      <template #content>
                        <i v-if="details.criticality == 1" class="fa-solid fa-shield-halved subdetail-icon"
                          style="color:#6dc193">
                          <p class="subdetail-name" v-if="details.criticality == 1">&nbsp;{{ $t('low') }}</p>
                          <p class="subdetail-tag">&nbsp;{{ $t('criticality') }}</p>
                        </i>
                        <i v-if="details.criticality == 2" class="fa-solid fa-shield-halved subdetail-icon"
                          style="color:#f7b34c">
                          <p class="subdetail-name" v-if="details.criticality == 2">&nbsp;{{ $t('medium') }}</p>
                          <p class="subdetail-tag">&nbsp;{{ $t('criticality') }}</p>
                        </i>
                        <i v-if="details.criticality == 3" class="fa-solid fa-shield-halved subdetail-icon"
                          style="color:#ef6154">
                          <p class="subdetail-name" v-if="details.criticality == 3">&nbsp;{{ $t('high') }}</p>
                          <p class="subdetail-tag">&nbsp;{{ $t('criticality') }}</p>
                        </i>
                        <i v-if="details.criticality == 4" class="fa-solid fa-shield-halved subdetail-icon"
                          style="color:#b83c32">
                          <p class="subdetail-name" v-if="details.criticality == 4">&nbsp;{{ $t('critical') }}</p>
                          <p class="subdetail-tag">&nbsp;{{ $t('criticality') }}</p>
                        </i>
                      </template>
                    </Card>
                    <div class="dropdown-menu">
                      <a class="dropdown-item" v-for="criticality in criticalities" :key="criticality"
                        @click="details.criticality = criticality.value">
                        {{ criticality.label }}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row details-data mt-2" v-if="loading_details">
                <ContentLoader height="25em"></ContentLoader>
              </div>
              <div class="row details-data mt-2 pl-2 pr-2" v-else style="padding-bottom:4em;">
                <div class="row pr-0">
                  <p class="mb-0 main-details-title"><strong>{{ $t('description') }}</strong></p>
                  <div class="col-12 pt-0 pb-0">
                    <p v-if="locked" class="mb-1">{{ details.description }}</p>
                    <Textarea v-else v-model="details.description" :disabled="locked" style="width:100%;"></Textarea>
                  </div>
                </div>
                <div class="row pr-0">
                  <p class="mb-1 main-details-title"><strong>{{ $t('hostName') }}</strong></p>
                  <div class="col-12 pt-0 pb-0">
                    <p v-if="locked" class="mb-1">{{ details.hostname }}</p>
                    <InputText v-else v-model="details.hostname" :disabled="locked" style="width:100%;"></InputText>
                  </div>
                </div>
                <div class="row pr-0">
                  <p class="mb-1 main-details-title"><strong>{{ $t('tags') }}:</strong></p>
                  <div v-if="locked" class="tag-cont">
                    <p class="details-tag" v-for="tag in details.tags" :key="tag" style="display:inline-block;">{{
                      tag
                    }}&nbsp;</p>
                  </div>
                  <Chips v-else v-model="details.tags" :disabled="locked" />
                </div>
                <div class="row pr-0">
                  <p class="mb-1 main-details-title"><strong>{{ $t('network') }}</strong></p>
                  <div class="ip-cont">
                    <p class="mb-1" style="font-size:small; color: gray;"><strong>IPs:&nbsp;</strong></p>
                    <div v-if="locked" class="tag-cont">
                      <p class="details-tag" v-for="ip in details.ip_addr" :key="ip" style="display:inline-block;">{{
                        ip
                      }}&nbsp;</p>
                    </div>
                    <Chips v-else v-model="details.ip_addr" :disabled="locked" style="width:100%;" />
                  </div>
                  <br>
                  <div class="mac-cont">
                    <p class="mb-1" style="font-size:small; color: gray;"><strong>MACs:&nbsp;</strong></p>
                    <div v-if="locked" class="tag-cont">
                      <p class="details-tag" v-for="mac in details.mac_addr" :key="mac" style="display:inline-block;">{{
                        mac
                      }}&nbsp;</p>
                    </div>
                    <Chips v-else v-model="details.mac_addr" :disabled="locked" style="width:100%;"
                      :separator="separatorExp" />
                  </div>
                </div>
                <div class="row pr-0">
                  <div class="col-6">
                    <p class="mb-0 mt-2 details-title"><strong>{{ $t('createdAt') }}</strong></p>
                    <p v-if="locked" class="">{{ formatDate(details.first_date) }}</p>
                    <Calendar v-else v-model="details.first_date" />
                  </div>
                  <div class="col-6">
                    <p class="mb-0 mt-2 details-title"><strong>{{ $t('lastActDate') }}</strong></p>
                    <p v-if="locked" class="">{{ formatDate(details.last_date) }}</p>
                    <Calendar v-else v-model="details.last_date" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 pt-2 mt-4 details-actions-div">
            <Button v-if="!locked" class="btn-custom-save mr-2" :label="$t('save')" @click="onSaveUpdate();"></Button>
            <Button v-if="locked" class="btn-custom-save mr-2" :label="$t('edit')" @click="unlockForm()"></Button>
            <Button v-if="!locked" class="btn-custom-cancel mr-2" :label="$t('cancel')"
              @click="onCancelUpdate();"></Button>
            <Button v-if="!locked" class="btn-custom-cancel mr-2" :label="$t('delete')" @click="lockForm()"
              style="float:right;" icon="pi pi-trash"></Button>
          </div>
        </div>

        <div class="col-8 pt-0 pb-0">
          <div class="pad-helper main-table pt-2 pr-2 pl-2 m-0" style="height:100%;">
            <div class="sec-details-info">
              <TabView>
                <!-- ====================================================================== Events =========================================================================== -->
                <TabPanel :header="$t('events')">
                  <div class="row pb-2 pl-3 pr-3 pt-1" style="overflow-y: auto; overflow-x:hidden;">
                    <div class="aux p-0">
                      <div class="row mt-0">
                        <div class="col-6 text-center" v-if="loading_details">
                          <ContentLoader></ContentLoader>
                        </div>
                        <div class="col-6 pt-0" v-else style="height:100%;">
                          <Chart type="line" :data="lineChartData" :options="lineChartOptions" />
                        </div>
                        <div class="col-6 text-center" v-if="loading_details">
                          <ContentLoader></ContentLoader>
                        </div>
                        <div class="col-6 pt-0 text-center" v-else style="height:100%;">
                          <Chart type="bar" :data="barData" :options="barOptions" />
                        </div>
                      </div>
                      <div class="col-12 pl-0 pr-0 text-center" v-if="loading_details">
                        <ContentLoader></ContentLoader>
                      </div>
                      <DataTable v-else :value="details.events" :paginator="true" class="p-datatable-customers mt-2"
                        :rows="8" :row-hover="true" dataKey="id" v-model:filters="filters" filter-display="menu"
                        :loading="loading_details" :selectionMode="(!editMode) ? 'single' : ''"
                        paginator-template="PrevPageLink PageLinks NextPageLink CurrentPageReport"
                        :rows-per-page-options="[10, 25, 50]"
                        current-page-report-template="Showing {first} to {last} of {totalRecords} entries"
                        responsive-layout="scroll" @row-click="setSelectedData($event.data)">
                        <template #empty>
                          {{ $t('noDataFound') }}
                        </template>
                        <template #loading>
                          {{ $t('loadingData') }}
                        </template>
                        <Column field="id" :header="$t('id')" sortable>
                          <template #body="{ data }">
                            <span @click="$router.push({ path: 'event-details', query: { id: data.id } })"
                              class="row-name-link">{{
                                data.id
                              }} </span>
                          </template>
                        </Column>
                        <Column field="title" :header="$t('title')" sortable>
                          <template #body="{ data }">
                            {{ data.title }}
                          </template>
                        </Column>
                        <Column field="severity" :header="$t('severity')" sortable>
                          <template #body="{ data }">
                            <span v-if="data.severity == 0"><i class="pi pi-circle-fill  criticality-filter-Info"
                                style="font-size: 0.7em;"></i>&nbsp;{{ $t('informational') }}</span>
                            <span v-if="data.severity == 1"><i class="pi pi-circle-fill  criticality-filter-Low"
                                style="font-size: 0.7em;"></i>&nbsp;{{ $t('low') }}</span>
                            <span v-if="data.severity == 2"><i class="pi pi-circle-fill  criticality-filter-Medium"
                                style="font-size: 0.7em;"></i>&nbsp;{{ $t('medium') }}</span>
                            <span v-if="data.severity == 3"><i class="pi pi-circle-fill  criticality-filter-High"
                                style="font-size: 0.7em;"></i>&nbsp;{{ $t('high') }}</span>
                            <span v-if="data.severity == 4"><i class="pi pi-circle-fill  criticality-filter-Critical"
                                style="font-size: 0.7em;"></i>&nbsp;{{ $t('critical') }}</span>
                          </template>
                        </Column>
                        <Column field="detection_rules" :header="$t('detectionRules')" sortable>
                          <template #body="{ data }">
                            {{ data.detection_rule.title }}
                          </template>
                        </Column>
                        <Column field="created_at" :header="$t('createdAt')" sortable>
                          <template #body="{ data }">
                            {{ formatDate(data.created_at) }}
                          </template>
                        </Column>
                        <Column field="status" :header="$t('status')" sortable>
                          <template #body="{ data }">
                            {{ prettifyString(data.status[data.status.length - 1].status) }}
                          </template>
                        </Column>
                      </DataTable>
                    </div>
                  </div>
                </TabPanel>
                <!-- ====================================================================== Incidents =========================================================================== -->
                <TabPanel :header="$t('incidents')">
                  <div class="loader text-center mt-2" style="width:100%;" v-if="loading_details">
                    <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                    <p>{{ $t('loading') }}</p>
                  </div>
                  <div class="row pb-2 pl-3 pr-3 pt-1" v-else style="overflow-y: auto; overflow-x:hidden;">
                    <div class="aux p-0">
                      <div class="row mt-0">
                        <div class="col-6 pt-0" style="height:100%;">
                          <Chart type="line" :data="lineChartData" :options="lineChartOptions" />
                        </div>
                        <div class="col-6 pt-0" style="height:100%;">
                          <Chart type="bar" :data="barData" :options="barOptions" />
                        </div>
                      </div>
                      <DataTable :value="details.incidents" :paginator="true" class="p-datatable-customers mt-2" :rows="8"
                        :row-hover="true" dataKey="id" v-model:filters="filters" filter-display="menu"
                        :loading="loading_details" :selectionMode="(!editMode) ? 'single' : ''"
                        paginator-template="PrevPageLink PageLinks NextPageLink CurrentPageReport"
                        :rows-per-page-options="[10, 25, 50]"
                        current-page-report-template="Showing {first} to {last} of {totalRecords} entries"
                        responsive-layout="scroll" @row-click="setSelectedData($event.data)">
                        <template #empty>
                          {{ $t('noDataFound') }}
                        </template>
                        <template #loading>
                          {{ $t('loadingData') }}
                        </template>
                        <Column field="id" :header="$t('id')" sortable>
                          <template #body="{ data }">
                            <span @click="$router.push({ path: 'incident-details', query: { id: data.id } })"
                              class="row-name-link">{{
                                data.id
                              }} </span>
                          </template>
                        </Column>
                        <Column field="title" :header="$t('name')" sortable>
                          <template #body="{ data }">
                            {{ data.title }}
                          </template>
                        </Column>
                        <Column field="severity" :header="$t('severity')" sortable>
                          <template #body="{ data }">
                            <span v-if="data.severity == 0"><i class="pi pi-circle-fill  criticality-filter-Info"
                                style="font-size: 0.7em;"></i>&nbsp;{{ $t('informational') }}</span>
                            <span v-if="data.severity == 1"><i class="pi pi-circle-fill  criticality-filter-Low"
                                style="font-size: 0.7em;"></i>&nbsp;{{ $t('low') }}</span>
                            <span v-if="data.severity == 2"><i class="pi pi-circle-fill  criticality-filter-Medium"
                                style="font-size: 0.7em;"></i>&nbsp;{{ $t('medium') }}</span>
                            <span v-if="data.severity == 3"><i class="pi pi-circle-fill  criticality-filter-High"
                                style="font-size: 0.7em;"></i>&nbsp;{{ $t('high') }}</span>
                            <span v-if="data.severity == 4"><i class="pi pi-circle-fill  criticality-filter-Critical"
                                style="font-size: 0.7em;"></i>&nbsp;{{ $t('critical') }}</span>
                          </template>
                        </Column>
                        <Column field="correlation_rule" :header="$t('correlationRule')" sortable>
                          <template #body="{ data }">
                            {{ data.correlation_rule.title }}
                          </template>
                        </Column>
                        <Column field="created_at" :header="$t('createdAt')" sortable>
                          <template #body="{ data }">
                            {{ formatDate(data.created_at) }}
                          </template>
                        </Column>
                        <Column field="status" :header="$t('status')" sortable>
                          <template #body="{ data }">
                            {{ prettifyString(data.status[data.status.length - 1].status) }}
                          </template>
                        </Column>
                      </DataTable>
                    </div>
                  </div>
                </TabPanel>
                <!-- ====================================================================== Data sources =========================================================================== -->
                <TabPanel :header="$t('dataSources')">
                  <div class="loader text-center mt-2" style="width:100%;" v-if="loading_details">
                    <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                    <p>{{ $t('loading') }}</p>
                  </div>
                  <div class="row pb-2 pl-3 pr-3 pt-1" v-else style="overflow-y: auto; overflow-x:hidden;">
                    <div class="aux p-0">
                      <div class="row mt-0">
                        <div class="col-6 pt-0" style="height:100%;">
                          <Chart type="line" :data="lineChartData" :options="lineChartOptions" />
                        </div>
                        <div class="col-6 pt-0" style="height:100%;">
                          <Chart type="bar" :data="barData" :options="barOptions" />
                        </div>
                      </div>
                      <DataTable :value="details.sources" :paginator="true" class="p-datatable-customers mt-2" :rows="8"
                        :row-hover="true" dataKey="id" v-model:filters="filters" filter-display="menu"
                        :loading="loading_details" :selectionMode="(!editMode) ? 'single' : ''"
                        paginator-template="PrevPageLink PageLinks NextPageLink CurrentPageReport"
                        :rows-per-page-options="[10, 25, 50]"
                        current-page-report-template="Showing {first} to {last} of {totalRecords} entries"
                        :global-filter-fields="['id', 'name', 'os', 'host', 'type', 'criticality', 'last_act_date', 'risk']"
                        responsive-layout="scroll" @row-click="setSelectedData($event.data)">
                        <template #empty>
                          {{ $t('noDataFound') }}
                        </template>
                        <template #loading>
                          {{ $t('loadingData') }}
                        </template>
                        <Column field="id" :header="$t('id')" sortable>
                          <template #body="{ data }">
                            <span @click="$router.push({ path: 'source-details', query: { id: data.id } })"
                              class="row-name-link">{{
                                data.id
                              }} </span>
                          </template>
                        </Column>
                        <Column field="name" :header="$t('name')" sortable>
                          <template #body="{ data }">
                            {{ data.name }}
                          </template>
                        </Column>
                        <Column field="description" :header="$t('description')" sortable>
                          <template #body="{ data }">
                            {{ data.description }}
                          </template>
                        </Column>
                        <Column field="log_number" :header="$t('Nº')" sortable>
                          <template #body="{ data }">
                            {{ data.log_number }}
                          </template>
                        </Column>
                      </DataTable>
                    </div>
                  </div>
                </TabPanel>
                <!-- ====================================================================== Groups =========================================================================== -->
                <TabPanel :header="$t('groups')">
                  <div class="loader text-center mt-2" style="width:100%;" v-if="loading_details">
                    <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                    <p>{{ $t('loading') }}</p>
                  </div>
                  <ScrollPanel style="width: 100%; height:39em" class="custom" v-else>
                    <div class="row pb-2 pl-3 pr-3 pt-1" style="overflow-y:auto; height: 65vh">
                      <div class="aux p-0">
                        <OpenGroup v-for="(group, index) in details.asset_groups" :key="group" :groupName="group.name"
                          :openFirst="index == 0 ? true : false">
                          <template #body>
                            <p class="p-0 m-0 group-title"><strong>{{ $t('ID') }}</strong></p>
                            <p class="p-0 m-0 group-data row-name-link" @click="$router.push({ path: 'assets' })">{{
                              group.organization_id
                            }}</p>
                            <p class="p-0 m-0 mt-2 group-title "><strong>{{ $t('description') }}</strong></p>
                            <p class="p-0 m-0 group-data">{{ group.description }}</p>
                            <p class="p-0 m-0 mt-2 group-title "><strong>{{ $t('tags') }}</strong></p>
                            <div class="tag-cont p-0">
                              <p class="details-tag" v-for="tag in group.tags" :key="tag" style="display:inline-block;">
                                {{ tag }}&nbsp;</p>
                            </div>
                            <p class="p-0 m-0 mt-2 group-title "><strong>{{ $t('organizationId') }}</strong></p>
                            <p class="p-0 m-0 group-data">{{ group.organization_id }}</p>
                            <DataTable :value="data" :paginator="true" class="p-datatable-customers mt-4 p-0" :rows="8"
                              :row-hover="true" dataKey="id" v-model:filters="filters" filter-display="menu"
                              :loading="loading_details" :selectionMode="(!editMode) ? 'single' : ''"
                              paginator-template="PrevPageLink PageLinks NextPageLink CurrentPageReport"
                              :rows-per-page-options="[10, 25, 50]"
                              current-page-report-template="Showing {first} to {last} of {totalRecords} entries"
                              responsive-layout="scroll">
                              <template #empty>
                                {{ $t('noDataFound') }}
                              </template>
                              <template #loading>
                                {{ $t('loadingData') }}
                              </template>
                              <Column field="name" :header="$t('name')" sortable>
                                <template #body="{ data }">
                                  <span @click="$router.push({ path: 'asset-details', query: { id: data.id } })"
                                    class="row-name-link">{{
                                      data.name
                                    }} </span>
                                </template>
                              </Column>
                              <Column field="os" :header="$t('operatingSystem')" sortable>
                                <template #body="{ data }">
                                  {{ data.os }}
                                </template>
                              </Column>
                              <Column field="hostname" :header="$t('hostName')" sortable>
                                <template #body="{ data }">
                                  {{ data.hostname }}
                                </template>
                              </Column>
                              <Column field="type" :header="$t('type')" sortable>
                                <template #body="{ data }">
                                  {{ data.type }}
                                </template>
                              </Column>
                              <Column field="criticality" :header="$t('criticality')" sortable>
                                <template #body="{ data }">
                                  <span v-if="data.criticality == 1"><i class="pi pi-circle-fill  criticality-filter-Low"
                                      style="font-size: 0.7em;"></i>&nbsp;{{ $t('low') }}</span>
                                  <span v-if="data.criticality == 2"><i
                                      class="pi pi-circle-fill  criticality-filter-Medium"
                                      style="font-size: 0.7em;"></i>&nbsp;{{ $t('medium') }}</span>
                                  <span v-if="data.criticality == 3"><i class="pi pi-circle-fill  criticality-filter-High"
                                      style="font-size: 0.7em;"></i>&nbsp;{{ $t('high') }}</span>
                                  <span v-if="data.criticality == 4"><i
                                      class="pi pi-circle-fill  criticality-filter-Critical"
                                      style="font-size: 0.7em;"></i>&nbsp;{{ $t('critical') }}</span>

                                </template>
                              </Column>
                              <Column field="last_date" :header="$t('lastActDate')" sortable>
                                <template #body="{ data }">
                                  {{ formatDate(data.last_date) }}
                                </template>
                              </Column>
                              <Column field="risk" :header="$t('risk')" sortable>
                                <template #body="{ data }">
                                  <span v-if="data.risk"><i class="fa-solid fa-circle-exclamation"
                                      style="color:#ef6154"></i>&nbsp;{{
                                        $t('yes')
                                      }}</span>
                                  <span v-if="!data.risk"><i class="fa-solid fa-circle-exclamation"
                                      style="color:#6dc193"></i>&nbsp;{{
                                        $t('no')
                                      }}</span>
                                </template>
                              </Column>
                            </DataTable>
                          </template>
                        </OpenGroup>
                      </div>
                    </div>
                  </ScrollPanel>
                </TabPanel>
                <!-- ====================================================================== Alerts =========================================================================== -->
                <TabPanel :header="$t('alertConfiguration')">
                  <div class="loader text-center mt-2" style="width:100%;" v-if="loading_details">
                    <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                    <p>{{ $t('loading') }}</p>
                  </div>
                  <ScrollPanel style="width: 100%; height:39em" class="custom" v-else>
                    <div class="row pb-2 pl-3 pr-3 pt-1" style="overflow-y:auto;">
                      <div class="aux p-0">
                        <OpenGroup v-for="(alert, index) in details.alerts" :key="alert" :groupName="alert.name"
                          :openFirst="index == 0 ? true : false">
                          <template #body>
                            <div class="row">
                              <div class="col-6">
                                <p class="p-0 m-0 group-title"><strong>{{ $t('ID') }}</strong></p>
                                <p class="p-0 m-0 group-data row-name-link"
                                  @click="$router.push({ path: 'alert-details', query: { id: alert.id } })">{{ alert.id }}
                                </p>
                                <p class="p-0 m-0 mt-2 group-title "><strong>{{ $t('description') }}</strong></p>
                                <p class="p-0 m-0 group-data">{{ alert.description }}</p>
                                <p class="p-0 m-0 mt-2 group-title "><strong>{{ $t('filters') }}</strong></p>
                                <div class="tag-cont p-0">
                                  <p class="details-tag" v-for="filter in alert.filters" :key="filter"
                                    style="display:inline-block;">
                                    {{ filter }}&nbsp;</p>
                                </div>
                              </div>
                              <div class="col-6">
                                <p class="p-0 m-0 mt-2 group-title "><strong>{{ $t('minimumSeverity') }}</strong></p>
                                <p v-if="alert.severity == 0" class="p-0 m-0 group-data">
                                  <i class="pi pi-circle-fill  criticality-filter-Info"
                                    style="color:rgb(109, 122, 193); font-size: 0.8em;"></i>
                                  <span>&nbsp;{{ $t('informational') }}</span>
                                </p>
                                <p v-if="alert.severity == 1" class="p-0 m-0 group-data">
                                  <i class="pi pi-circle-fill  criticality-filter-Low"
                                    style="color:#6dc193; font-size: 0.8em;"></i>
                                  <span>&nbsp;{{ $t('low') }}</span>
                                </p>
                                <p v-if="alert.severity == 2" class="p-0 m-0 group-data">
                                  <i class="pi pi-circle-fill  criticality-filter-Medium"
                                    style="color:#f7b34c; font-size: 0.8em;"></i>
                                  <span>&nbsp;{{ $t('medium') }}</span>
                                </p>
                                <p v-if="alert.severity == 3" class="p-0 m-0 group-data">
                                  <i class="pi pi-circle-fill  criticality-filter-High"
                                    style="color:#ef6154; font-size: 0.8em;"></i>
                                  <span>&nbsp;{{ $t('high') }}</span>
                                </p>
                                <p v-if="alert.severity == 4" class="p-0 m-0 group-data">
                                  <i class="pi pi-circle-fill  criticality-filter-Critical"
                                    style="color:#b83c32; font-size: 0.8em;"></i>
                                  <span>&nbsp;{{ $t('critical') }}</span>
                                </p>
                                <p class="p-0 m-0 mt-2 group-title "><strong>{{ $t('users') }}</strong></p>
                                <div class="tag-cont p-0">
                                  <p class="row-name-link mb-0" v-for="user in alert.users" :key="user"
                                    style="display:inline-block;">
                                    {{ user.full_name }},&nbsp;
                                  </p>
                                </div>
                                <p class="p-0 m-0 mt-2 group-title "><strong>{{ $t('methods') }}</strong></p>
                                <div class="tag-cont p-0">
                                  <p class="mb-0" v-for="alert in alert.alertMethods" :key="alert"
                                    style="display:inline-block;">
                                    <i class="pi pi-slack" v-if="alert.method == 'slack'"></i>
                                    <i class="pi pi-mobile" v-if="alert.method == 'sms'"></i>
                                    <i class="pi pi-envelope" v-if="alert.method == 'email'"></i>
                                    <span class="ml-1 mr-3">{{ alert.name }}</span>
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-6">
                                <p class="p-0 m-0 group-title"><strong>{{ $t('correlationRules') }}</strong></p>
                                <DataTable :value="alert.correlation_rules" :paginator="true"
                                  class="p-datatable-customers mt-2 p-0" :rows="4" :row-hover="true" dataKey="id"
                                  v-model:filters="filters" filter-display="menu" :loading="loading_details"
                                  selection-mode="single"
                                  paginator-template="PrevPageLink PageLinks NextPageLink CurrentPageReport"
                                  :rows-per-page-options="[10, 25, 50]"
                                  current-page-report-template="Showing {first} to {last} of {totalRecords} entries"
                                  :global-filter-fields="['id', 'name', 'os', 'host', 'type', 'criticality', 'last_act_date', 'risk']"
                                  responsive-layout="scroll">
                                  <template #empty>
                                    {{ $t('noDataFound') }}
                                  </template>
                                  <template #loading>
                                    {{ $t('loadingData') }}
                                  </template>
                                  <Column field="name" :header="$t('name')" sortable>
                                    <template #body="{ data }">
                                      <span @click="$router.push({ path: 'correlation-rule-details', query: { id: data.id } })"
                                        class="row-name-link">{{
                                          data.name
                                        }} </span>
                                    </template>
                                  </Column>
                                  <Column field="severity" :header="$t('severity')" sortable>
                                    <template #body="{ data }">
                                      <span v-if="data.severity == 0"><i
                                          class="pi pi-circle-fill  criticality-filter-Info"
                                          style="font-size: 0.7em;"></i>&nbsp;{{ $t('low') }}</span>
                                      <span v-if="data.severity == 1"><i class="pi pi-circle-fill  criticality-filter-Low"
                                          style="font-size: 0.7em;"></i>&nbsp;{{ $t('low') }}</span>
                                      <span v-if="data.severity == 2"><i
                                          class="pi pi-circle-fill  criticality-filter-Medium"
                                          style="font-size: 0.7em;"></i>&nbsp;{{ $t('medium') }}</span>
                                      <span v-if="data.severity == 3"><i
                                          class="pi pi-circle-fill  criticality-filter-High"
                                          style="font-size: 0.7em;"></i>&nbsp;{{ $t('high') }}</span>
                                      <span v-if="data.severity == 4"><i
                                          class="pi pi-circle-fill  criticality-filter-Critical"
                                          style="font-size: 0.7em;"></i>&nbsp;{{ $t('critical') }}</span>
                                    </template>
                                  </Column>
                                  <Column field="active" :header="$t('active')" sortable>
                                    <template #body="{ data }">
                                      <span v-if="data.is_active"><i class="pi pi-circle-fill  criticality-filter-Low"
                                          style="font-size: 0.7em;"></i>&nbsp;{{ $t('active') }}</span>
                                      <span v-else><i class="pi pi-circle-fill  criticality-filter-High"
                                          style="font-size: 0.7em;"></i>&nbsp;{{ $t('inactive') }}</span>
                                    </template>
                                  </Column>
                                </DataTable>
                              </div>
                              <div class="col-6">
                                <p class="p-0 m-0 group-title"><strong>{{ $t('detectionRules') }}</strong></p>
                                <DataTable :value="alert.detection_rules" :paginator="true"
                                  class="p-datatable-customers mt-2 p-0" :rows="4" :row-hover="true" dataKey="id"
                                  v-model:filters="filters" filter-display="menu" :loading="loading_details"
                                  selection-mode="single"
                                  paginator-template="PrevPageLink PageLinks NextPageLink CurrentPageReport"
                                  :rows-per-page-options="[10, 25, 50]"
                                  current-page-report-template="Showing {first} to {last} of {totalRecords} entries"
                                  :global-filter-fields="['id', 'name', 'os', 'host', 'type', 'criticality', 'last_act_date', 'risk']"
                                  responsive-layout="scroll">
                                  <template #empty>
                                    {{ $t('noDataFound') }}
                                  </template>
                                  <template #loading>
                                    {{ $t('loadingData') }}
                                  </template>
                                  <Column field="name" :header="$t('name')" sortable>
                                    <template #body="{ data }">
                                      <span @click="$router.push({ path: 'detection-rule-details', query: { id: data.id } })"
                                        class="row-name-link">{{
                                          data.name
                                        }} </span>
                                    </template>
                                  </Column>
                                  <Column field="severity" :header="$t('severity')" sortable>
                                    <template #body="{ data }">
                                      <span v-if="data.severity == 0"><i
                                          class="pi pi-circle-fill  criticality-filter-Info"
                                          style="font-size: 0.7em;"></i>&nbsp;{{ $t('low') }}</span>
                                      <span v-if="data.severity == 1"><i class="pi pi-circle-fill  criticality-filter-Low"
                                          style="font-size: 0.7em;"></i>&nbsp;{{ $t('low') }}</span>
                                      <span v-if="data.severity == 2"><i
                                          class="pi pi-circle-fill  criticality-filter-Medium"
                                          style="font-size: 0.7em;"></i>&nbsp;{{ $t('medium') }}</span>
                                      <span v-if="data.severity == 3"><i
                                          class="pi pi-circle-fill  criticality-filter-High"
                                          style="font-size: 0.7em;"></i>&nbsp;{{ $t('high') }}</span>
                                      <span v-if="data.severity == 4"><i
                                          class="pi pi-circle-fill  criticality-filter-Critical"
                                          style="font-size: 0.7em;"></i>&nbsp;{{ $t('critical') }}</span>
                                    </template>
                                  </Column>
                                  <Column field="active" :header="$t('active')" sortable>
                                    <template #body="{ data }">
                                      <span v-if="data.is_active"><i class="pi pi-circle-fill  criticality-filter-Low"
                                          style="font-size: 0.7em;"></i>&nbsp;{{ $t('active') }}</span>
                                      <span v-else><i class="pi pi-circle-fill  criticality-filter-High"
                                          style="font-size: 0.7em;"></i>&nbsp;{{ $t('inactive') }}</span>
                                    </template>
                                  </Column>
                                </DataTable>
                              </div>
                            </div>
                          </template>
                        </OpenGroup>
                      </div>
                    </div>
                  </ScrollPanel>
                </TabPanel>
              </TabView>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<!----------------------------------------------->
<!--                    SCRIPT                 -->
<!----------------------------------------------->

<script>
import MyShared from "./shared";
export default {
  mixins: [MyShared],
};

</script>

<script setup>
import { useMq } from "vue3-mq";
const mq = useMq();
</script>

<!----------------------------------------------->
<!--                    STYLES                 -->
<!----------------------------------------------->
<style>
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

/* General */


#test {
  background-color: red !important;
}

td {
  font-family: 'Open Sans', sans-serif !important;
}

a {
  font-family: 'Open Sans', sans-serif !important;
  font-size: 0.9em;
}

.dropdown-menu {
  position: absolute !important;
  margin-right: 5em;
}

.main-details-title {
  text-transform: uppercase;
  font-size: small;
  color: rgb(73, 73, 73);
  margin-top: 1.25em !important;
}

.details-asset-title {
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  background: white;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}

.details-actions-div {
  border-top: 1px solid #e0e0e0;
  height: auto;
  width: 100%;
  padding-top: 0.5em;
  z-index: 1;
  background: white;
}

/* Custom input */
.p-inputtext {
  padding: 0.1em !important;
  padding-left: 0.5em !important;
}

.p-inputtext:focus {
  border-color: #ec635f !important;
  box-shadow: 0px 0px 2px #ec635f !important;
  outline: none;
}

.p-inputtext:hover {
  border-color: #ec635f !important;
}

.p-chips-multiple-container {
  list-style-type: none;
  cursor: text;
  padding: 0 !important;
  width: 100% !important;
}

/* Chips */
.p-chips .p-chips-multiple-container .p-chips-input-token {
  padding: 0 !important;
}

.p-chips .p-chips-multiple-container .p-chips-token {
  padding: 0.05rem 0.5rem !important;
  margin-right: 0.5rem !important;
  background: #ec635f !important;
  color: white !important;
  border-radius: 16px !important;
  font-size: 0.8em !important;
  margin-top: 0.25em !important;
  margin-bottom: 0.25em !important;
  margin-left: 0.1em !important;
}

/* Info card */
.info-card-icon {
  font-size: 2em !important;
  color: #7083f0;
  display: inline;
}

.info-card-icon-close {
  font-size: 1em;
  color: #7e7c7c;
  display: inline-block;
  float: right;
  cursor: pointer;
}

.info-card-top-row {
  width: auto;
}

.info-card {
  width: 100% !important;
  height: 100%;
  background-color: #b8d1ff5e;
  border: 1px solid #b8c3ff;
  padding: 1em;
  padding-bottom: 2em;
  margin-bottom: 1em !important;
  margin-left: -12.5px;
}

/* Table name row link*/
.row-name-link {
  color: #ef6154;
}

.row-name-link:hover {
  color: #ef6154;
  text-decoration: underline;
}

/* Criticality filter*/
.criticality-filter-Low {
  color: #6dc193;
  font-size: 0.7em;
}

.criticality-filter-Medium {
  color: #f7b34c;
  font-size: 0.7em;
}

.criticality-filter-High {
  color: #ef6154;
  font-size: 0.7em;
}

.criticality-filter-Critical {
  color: #b83c32;
  font-size: 0.7em;
}

/* Custom data table */
.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  background: #ffffff;
  border-color: #ec635f !important;
  color: #ec635f !important;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  padding: 0.5em !important;
}

.p-datatable .p-sortable-column.p-highlight {
  color: #ec635f !important;
}

.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
  color: #ec635f !important;
}

.p-datatable .p-datatable-thead>tr>th {
  padding: 0.25em !important
}

.p-datatable .p-datatable-tbody>tr>td {
  padding: 0.25em !important;
}

.p-datatable .p-datatable-tbody>tr.p-highlight {
  background: #86b5bb4f !important;
  color: #495057 !important;
}

.p-column-filter-menu-button.p-column-filter-menu-button-active,
.p-column-filter-menu-button.p-column-filter-menu-button-active:hover {
  background: #86b5bb4f !important;
  ;
  color: white;
}

.p-paginator {
  padding: 0 !important;
}

.p-paginator .p-paginator-first,
.p-paginator .p-paginator-prev,
.p-paginator .p-paginator-next,
.p-paginator .p-paginator-last {
  min-width: 1.25rem !important;
  height: 1.25rem !important;
  ;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background: #ffffff !important;
  border-color: #ffffff !important;
  font-weight: 800;
}

/* Custom buttons */
.btn-custom-save {
  margin: auto;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  border: 1px solid #ec635f !important;
  color: #ec635f !important;
  background: white !important;
  padding-top: 0.25em !important;
  padding-bottom: 0.25em !important;
  padding-left: 0.5em !important;
  padding-right: 0.5em !important;
  font-weight: bold;
  width: auto !important;
}

/* Custom buttons */
.btn-custom-cancel {
  margin: auto;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  border: 1px solid #ec635f !important;
  color: white !important;
  background: #ec635f !important;
  padding-top: 0.25em !important;
  padding-bottom: 0.25em !important;
  padding-left: 0.5em !important;
  padding-right: 0.5em !important;
  font-weight: bold;
  width: auto !important;
}

.btn-custom:focus {
  box-shadow: 0px 0px 2px #ec635f !important;
}

.btn-custom:hover {
  background: #bd4f4b !important;
}

.p-inputtext:focus {
  border-color: #ec635f !important;
  box-shadow: 0px 0px 2px #ec635f !important;
  outline: none;
}

pad-helper {
  margin-top: 0em !important;
}</style>
