import $axios from '../utils';


export function getAllTeams (full=true, offset=0, limit=10000) {
    return $axios.get(`management/team/?full=${full}&offset=${offset}&limit=${limit}`)
    .then(response => response.data)
}
export function getOneTeam(id, full) {
    return $axios.get(`management/team/${id}?full=${full}`)
    .then(response => response.data)
}
export function createTeam(data) {
    return $axios.post('management/team/', data)
    .then(response => response.data)
}
export function updateTeam(id, data) {
    return $axios.put(`management/team/${id}`, data)
    .then(response => response.data)
}
export function deleteTeam(id) {
    return $axios.delete(`management/team/${id}`)
    .then(response => response.data)
}
export function deleteAllTeams() {
    return $axios.delete('management/team/')
    .then(response => response.data)
}

