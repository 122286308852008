import Sitebar from "../../components/Partials/Sitebar/SiteBar.vue"
import Helper from "../../components/Partials/Helper/Helper.vue"
import { FilterMatchMode, FilterOperator } from 'primevue/api';
import { nextTick } from "vue";
import { useUserStore } from '../../store/store.js'
import OpenGroup from "../../components/Partials/OpenGroup/OpenGroup.vue"
import Chart from 'primevue/chart';
import { ref } from "vue";
import * as $api from "../../api/api";

var MyShared = {
    /* Name */
    name: "Home",
    /* Data */
    setup() {
        const separatorExp = ref(/,| /);
    },
    data() {
        return {
            user: {
                "permissions": []
            },
            close: true,
            locked: true,
            details: {},
            isActiveStatuses: [
                { label: this.$t('activated'), value: true },
                { label: this.$t('deactivated'), value: false }
            ],
            loading_details: false,
            // Preguntar que tipos hay
            types: [
                { label: 'Server', value: 'Server' },
                { label: 'Router', value: 'Router' },
                { label: 'Switch', value: 'Switch' },
                { label: 'Firewall', value: 'Firewall' },
                { label: 'Workstation', value: 'Workstation' },
                { label: 'Printer', value: 'Printer' },
                { label: 'Other', value: 'Other' }
            ],
            criticalities: [
                { label: this.$t('low'), value: 1 },
                { label: this.$t('medium'), value: 2 },
                { label: this.$t('high'), value: 3 },
                { label: this.$t('critical'), value: 4 },
                { label: this.$t('informational'), value: 0 }
            ],
            statuses: [
                { label: this.$t('new'), status: 'new' },
                { label: this.$t('active'), status: 'active' },
                { label: this.$t('mitigated'), status: 'mitigated' },
                { label: this.$t('closed'), status: 'closed' }
            ],
            separatorExp: (/,| /),

        }

    },
    /* Components */
    components: {
        Sitebar,
        Helper,
        OpenGroup,
        Chart
    },
    /* Props */
    props: {},
    /* Methods */
    methods: {
        formatDate(date) {
            if (date == null || date == '') return;

            // Format date to dd/mm/yyyy hh:mm
            let d = new Date(date);
            let day = d.getDate();
            let month = d.getMonth() + 1;
            let year = d.getFullYear();
            let hour = d.getHours();
            let minutes = d.getMinutes();
            // Add 0 if month is less than 10
            if (month < 10) {
                month = "0" + month;
            }
            // Add 0 if day is less than 10
            if (day < 10) {
                day = "0" + day;
            }
            // Add 0 if minutes is less than 10
            if (minutes < 10) {
                minutes = "0" + minutes;
            }
            return `${day}/${month}/${year} ${hour}:${minutes}`;
        },
        lockForm() {
            this.locked = true;
            let dropdowns = document.getElementsByClassName("dropdown-menu");
            for (let i = 0; i < dropdowns.length; i++) {
                dropdowns[i].classList.remove("show");
            }
            /*
            let toggles = document.getElementsByClassName("dropdown-toggle")
            for (let i = 0; i < toggles.length; i++) {
                toggles[i].setAttribute("aria-expanded", 'false')
            }
            */
        },
        unlockForm() {
            this.locked = false;
        },
        onCancelUpdate() {
            this.details.filters = this.filters;
            this.details.description = this.description;
            this.details.severity = this.severity;
            this.details.status = this.status;
            this.lockForm();
        },
        onSaveUpdate() {
            // Update asset via API and update local data
            $api.updateAlert(this.$route.query.id, {
                "filters": this.details.filters,
                "description": this.details.description,
                "severity": this.details.severity,
                "status": this.details.status

            }).then((response) => {
                console.log(`[+] Updated alert: ${response.data}`);
                this.filters = this.details.filters;
                this.description = this.details.description;
                this.severity = this.details.severity;
                this.status = this.details.status;
                // toast
                this.$toast.add({ severity: 'success', summary: this.$t('success'), detail: this.$t('alertUpdated'), life: 3000 });
                this.lockForm();
            }).catch((err) => {
                console.log(`[-] Error (updateAlert): ${err}`);
                // toast
                this.$toast.add({ severity: 'error', summary: this.$t('error'), detail: this.$t('alertNotUpdated'), life: 3000 });
            });
        }
    },
    async mounted() {
        const userStore = useUserStore();
        const userObject = await userStore.getUser();
        this.user = userObject;
        await (this.details = require('./details.json'));
        if (process.env.VUE_APP_ENV === 'development') {
            //await (this.data = require('./events.json'));
            //await (this.data_backup = require('./events.json'));
        } else if (process.env.VUE_APP_ENV === 'staging' || process.env.VUE_APP_ENV === 'production') {
            this.loading_details = true;
            console.log(this.$route.query.id);
            $api.getOneAlert(this.$route.query.id, true).then((response) => {
                this.details = response;
                console.log("ALERT: ", this.details);
                this.filters = this.details.filters;
                this.description = this.details.description;
                this.severity = this.details.severity;
                this.status = this.details.status;
                this.loading_details = false;
            }).catch((err) => {
                console.log(`[-] Error (getOneAlert): ${err}`);
            });
        } else {
            console.log("ERROR: NO ENVIRONMENT SELECTED");
        }

    }
};
export default MyShared;