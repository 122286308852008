<!----------------------------------------------->
<!--                  TEMPLATE                 -->
<!----------------------------------------------->
<template>
  <DesktopError v-if=" mq.current  === 'desktop'"></DesktopError>
</template>

<!----------------------------------------------->
<!--                    SCRIPT                 -->
<!----------------------------------------------->
<script>

import DesktopError from "../components/NotFound/NotFound";

export default {
  /* Name */
  name: "Tasks",
  inject: ["mq"],
  /* Components */
  components: {
    DesktopError,
  },
};
</script>

<script setup>
import { useMq } from "vue3-mq";
const mq = useMq();
</script>

<!----------------------------------------------->
<!--                    STYLES                 -->
<!----------------------------------------------->
<style scoped >
</style>
