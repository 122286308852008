import { nextTick } from "vue";

var MyShared = {
    /* Name */
    name: "OpenGroup",
    /* Data */
    setup() {

    },
    data() {
        return {
            user: {
                "permissions": []
            },
            open: false,
            selected_permissions: [],
        }
    },
    /* Components */
    components: {},
    /* Props */
    props: {
        permissionGroupName: String,
        permissions: Array,
    },
    /* Methods */
    methods: {
        updateSelectedPermissions(selected_permissions) {
            this.$emit('updateSelectedPermissions', selected_permissions);
        }
    },
    emits: ['updateSelectedPermissions'],
    async mounted() {
        await nextTick();
        this.open = this.openFirst;
    }
};
export default MyShared;