import $axios from '../utils';

export function getAllMachineLearningJobs(full = true, offset = 0, limit = 10000) {
    return $axios.get(`analysis/machine_learning_job/?full=${full}&offset=${offset}&limit=${limit}`)
        .then(response => response.data)
}
export function getMachineLearningJobsBy(key, value, full, offset, limit) {
    return $axios.get(`analysis/machine_learning_job/by?key${key}&value=${value}&full=${full}&offset=${offset}&limit=${limit}`)
        .then(response => response.data)
}
export function getOneMachineLearningJob(id, full) {
    return $axios.get(`analysis/machine_learning_job/${id}?full=${full}`)
        .then(response => response.data)
}
export function createMachineLearningJob(data) {
    return $axios.post('analysis/machine_learning_job/', data)
        .then(response => response.data)
}
export function updateMachineLearningJob(id, data) {
    return $axios.put(`analysis/machine_learning_job/${id}`, data)
        .then(response => response.data)
}
export function deleteAllMachineLearningJobs() {
    return $axios.delete('analysis/machine_learning_job/')
        .then(response => response.data)
}
export function deleteMachineLearningJob(id) {
    return $axios.delete(`analysis/machine_learning_job/${id}`)
        .then(response => response.data)
}