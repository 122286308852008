<!----------------------------------------------->
<!--                  TEMPLATE                 -->
<!----------------------------------------------->
<template>
  <MobileSurveillance v-if="  mq.current  === 'mobile'"></MobileSurveillance>
  <TabletSurveillance v-if="  mq.current  === 'tablet'"></TabletSurveillance>
  <DesktopSurveillance v-if=" mq.current  === 'desktop'"></DesktopSurveillance>
</template>

<!----------------------------------------------->
<!--                    SCRIPT                 -->
<!----------------------------------------------->
<script>
import MobileSurveillance from "../components/Surveillance/MobileSurveillance";
import TabletSurveillance from "../components/Surveillance/TabletSurveillance";
import DesktopSurveillance from "../components/Surveillance/DesktopSurveillance";

export default {
  /* Name */
  name: "Surveillance",
  inject: ["mq"],
  /* Components */
  components: {
    MobileSurveillance,
    TabletSurveillance,
    DesktopSurveillance,
  },
};
</script>

<script setup>
import { useMq } from "vue3-mq";
const mq = useMq();
</script>

<!----------------------------------------------->
<!--                    STYLES                 -->
<!----------------------------------------------->
<style scoped >
</style>
