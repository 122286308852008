<!----------------------------------------------->
<!--                  TEMPLATE                 -->
<!----------------------------------------------->
<template>
  <MobileDashboardViewer v-if="  mq.current  === 'mobile'"></MobileDashboardViewer>
  <TabletDashboardViewer v-if="  mq.current  === 'tablet'"></TabletDashboardViewer>
  <DesktopDashboardViewer v-if=" mq.current  === 'desktop'"></DesktopDashboardViewer>
</template>

<!----------------------------------------------->
<!--                    SCRIPT                 -->
<!----------------------------------------------->
<script>
import MobileDashboardViewer from "../components/DashboardViewer/MobileDashboardViewer";
import TabletDashboardViewer from "../components/DashboardViewer/TabletDashboardViewer";
import DesktopDashboardViewer from "../components/DashboardViewer/DesktopDashboardViewer";

export default {
  /* Name */
  name: "DashboardViewer",
  inject: ["mq"],
  /* Components */
  components: {
    MobileDashboardViewer,
    TabletDashboardViewer,
    DesktopDashboardViewer,
  },
};
</script>

<script setup>
import { useMq } from "vue3-mq";
const mq = useMq();
</script>

<!----------------------------------------------->
<!--                    STYLES                 -->
<!----------------------------------------------->
<style scoped >
</style>
