import $axios from '../utils';

export function getAllIntakeSchemas (full=true, offset=0, limit=10000) {
    return $axios.get(`intake/intake_schema/?full=${full}&offset=${offset}&limit=${limit}`)
    .then(response => response.data)
}
export function getIntakeSchemasBy(data, full=true, offset=0, limit=100){
    return $axios.get(`intake/intake_schema/by?by=${data}&full=${full}&offset=${offset}&limit=${limit}`)
    .then(response => response.data)
}
export function getOneIntakeSchema(id, full) {
    return $axios.get(`intake/intake_schema/${id}?full=${full}`)
    .then(response => response.data)
}
export function createIntakeSchema(data) {
    return $axios.post('intake/intake_schema/', data)
    .then(response => response.data)
}
export function updateIntakeSchema(id, data) {
    return $axios.put(`intake/intake_schema/${id}`, data)
    .then(response => response.data)
}
export function deleteAllIntakeSchemas() {
    return $axios.delete('intake/intake_schema/')
    .then(response => response.data)
}
export function deleteIntakeSchema(id) {
    return $axios.delete(`intake/intake_schema/${id}`)
    .then(response => response.data)
}
