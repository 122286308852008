import { defineStore } from "pinia";
import * as $api from "../api/api";

export const useUserStore = defineStore({
    id: "user",
    state: () => ({
        user: null
    }),
    actions: {
        async refresh() {
            await $api.getMe(true).then((response) => {
                this.user = response;
                console.log("STORE ME: ", this.user);
                return true;
            }).catch((err) => {
                console.log(`[-] Error (getMe): ${err}`);
            });
        },
        async getUser() {
            if (this.user == null || this.user == {} || this.user == undefined) {
                console.log("Have been necessary to refresh the user");
                await this.refresh()
            }
            return this.user;
        },
    }
});