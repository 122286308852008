import $axios from '../utils';

export function getAllIntakeMethods (full=true, offset=0, limit=10000) {
    return $axios.get(`intake/intake_method/?full=${full}&offset=${offset}&limit=${limit}`)
    .then(response => response.data)
}
export function getIntakeMethodsBy(key, value, full, offset, limit){
    return $axios.get(`intake/intake_method/by?key${key}&value=${value}&full=${full}&offset=${offset}&limit=${limit}`)
    .then(response => response.data)
}
export function getOneIntakeMethod(id, full) {
    return $axios.get(`intake/intake_method/${id}?full=${full}`)
    .then(response => response.data)
}
export function createIntakeMethod(data) {
    return $axios.post('intake/intake_method/', data)
    .then(response => response.data)
}
export function updateIntakeMethod(id, data) {
    return $axios.put(`intake/intake_method/${id}`, data)
    .then(response => response.data)
}
export function deleteAllIntakeMethods() {
    return $axios.delete('intake/intake_method/')
    .then(response => response.data)
}
export function deleteIntakeMethod(id) {
    return $axios.delete(`intake/intake_method/${id}`)
    .then(response => response.data)
}

