import $axios from '../utils';

export function getAllVisualizations(full = true, offset = 0, limit = 10000) {
    return $axios.get(`dashboard/visualization/?full=${full}&offset=${offset}&limit=${limit}`)
        .then(response => response.data)
}
export function getVisualizationsBy(key, value, full, offset, limit) {
    return $axios.get(`dashboard/visualization/by?key${key}&value=${value}&full=${full}&offset=${offset}&limit=${limit}`)
        .then(response => response.data)
}
export function getOneVisualization(id, full = true) {
    return $axios.get(`dashboard/visualization/${id}?full=${full}`)
        .then(response => response.data)
}
export function createVisualization(data) {
    return $axios.post('dashboard/visualization/', data)
        .then(response => response.data)
}
export function updateVisualization(id, data) {
    return $axios.put(`dashboard/visualization/${id}`, data)
        .then(response => response.data)
}
export function deleteAllVisualizations() {
    return $axios.delete('dashboard/visualization/')
        .then(response => response.data)
}
export function deleteVisualization(id) {
    return $axios.delete(`dashboard/visualization/${id}`)
        .then(response => response.data)
}
export function getSources() {
    return $axios.get('dashboard/visualization/data_sources')
        .then(response => response.data)
}
export function getSourceFields(source_name) {
    return $axios.get(`dashboard/visualization/sources/${source_name}/fields`)
        .then(response => response.data)
}
export function getModelFields(mode_name) {
    return $axios.get(`dashboard/visualization/models/${mode_name}/fields`)
        .then(response => response.data)
}