import Sitebar from "../../components/Partials/Sitebar/SiteBar.vue"
import Helper from "../../components/Partials/Helper/Helper.vue"
import { ref } from 'vue';
import { useUserStore } from '../../store/store.js'
import Chart from 'primevue/chart';
import ConfirmDialog from "primevue/confirmdialog";
import { nextTick } from "vue";
import * as $api from "../../api/api";

var MyShared = {
    /* Name */
    name: "Home",
    /* Data */
    setup() {
        const confirm = useConfirm();
        return { confirm };
    },
    data() {
        return {
            user: {
                "permissions": []
            },
            dashboardName: '',
            selectedToUpdateId: '',
            isUpdating: false,
            toggleLibrary: false,
            chartSelected: false,
            requieredValues: false,
            dashboardRequiredValues: false,
            selectedChartType: '',
            new_name: '',
            new_desc: '',
            new_text: '',
            new_number: '',
            new_unit: '',
            selectedChartToUpdate: {},
            charts_types: [
                { name: this.$t('line'), value: 'line', img: require('@/assets/charts/line.svg'), desc: this.$t('lineDesc') },
                { name: this.$t('bar'), value: 'bar', img: require('@/assets/charts/bar.svg'), desc: this.$t('barDesc') },
                { name: this.$t('pie'), value: 'pie', img: require('@/assets/charts/pie.svg'), desc: this.$t('pieDesc') },
                { name: this.$t('text'), value: 'text', img: require('@/assets/charts/text.svg'), desc: this.$t('textDesc') },
                { name: this.$t('number'), value: 'number', img: require('@/assets/charts/number.svg'), desc: this.$t('numberDesc') },
            ],
            layout: [],
            lineChartOptions: {
                maintainAspectRatio: false,
                responsive: true,
                plugins: {
                    legend: {
                        labels: {
                            color: '#495057'
                        }
                    }
                },
                scales: {
                    x: {
                        ticks: {
                            color: '#495057'
                        },
                        grid: {
                            color: '#ebedef'
                        }
                    },
                    y: {
                        ticks: {
                            color: '#495057'
                        },
                        grid: {
                            color: '#ebedef'
                        }
                    }
                }
            },
            pieChartOptions: {
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        labels: {
                            boxWidth: 15,
                            color: '#495057'
                        }
                    }
                }
            },
            tempChart: {
                "x": 0,
                "y": 0,
                "w": 5,
                "h": 5,
                "i": "0",
                "typeof": "line",
                "name": "Line",
                "chartData": {
                    "labels": [
                        "January",
                        "February",
                        "March",
                        "April",
                        "May",
                        "June",
                        "July"
                    ],
                    "datasets": [{
                        "label": "First Dataset",
                        "data": [
                            65,
                            59,
                            80,
                            81,
                            56,
                            55,
                            40
                        ],
                        "fill": false,
                        "borderColor": "#42A5F5",
                        "tension": 0.4
                    }]
                }
            },
        }
    },
    /* Components */
    components: {
        Sitebar,
        Helper,
        Chart,
        ConfirmDialog
    },
    /* Props */
    props: {},
    /* Methods */
    methods: {
        theFormat(number) {
            return number.toFixed(2);
        },
        updateSelectedChartToUpdate() {
            this.selectedChartToUpdate.chartData = null // Aqui iria el chartData que me devuelva la peticion de los filtros
        },
        async refreshChart(id) {
            //await nextTick();
            console.log('refresh chart: ' + id);
            // find with id in layout
            let element = document.getElementById(id);
            console.log('element: ', element);
            let temp_height = document.getElementById(id).scrollHeight;
            // get canvas in element 
            let canvas = element.getElementsByTagName('canvas')[0];
            console.log('card: ' + temp_height);
            // remove px from temp_height and convert to number
            //temp_height = parseInt(temp_height.replace('px', ''));
            // resize canvas
            canvas.style.height = (temp_height - 55) + 'px';
            console.log('canvas: ' + canvas.style.height);
        },
        checkRequieredValues(type) {
            switch (type) {
                case 'line':
                    if (this.new_name == '') {
                        this.requieredValues = true;
                        return false;
                    }
                    break;
                case 'bar':
                    if (this.new_name == '') {
                        this.requieredValues = true;
                        return false;
                    }
                    break;
                case 'pie':
                    if (this.new_name == '') {
                        this.requieredValues = true;
                        return false;
                    }
                    break;
                case 'text':
                    if (this.new_name == '' || this.new_text == '') {
                        this.requieredValues = true;
                        return false;
                    }
                    break;
                case 'number':
                    if (this.new_name == '' || this.new_number == '' || this.new_unit == '') {
                        this.requieredValues = true;
                        return false;
                    }
                    break;
            }
            return true;
        },
        addChart(chart) {
            this.toggleLibrary = false;
            this.chartSelected = false;
            this.requieredValues = false;
            switch (chart.configuration.type) {
                case 'line':
                    this.layout.push({ x: chart.x, y: chart.y, w: chart.w, h: chart.h, i: chart.i, typeof: chart.configuration.type, name: chart.name, desc: chart.description, chartData: chart.data }); /* Este chartdata sera el que me devuelva al hacer la peticion de los filtros */
                    break;
                case 'bar':
                    this.layout.push({ x: chart.x, y: chart.y, w: chart.w, h: chart.h, i: chart.i, typeof: chart.configuration.type, name: chart.name, desc: chart.description, chartData: chart.data });
                    break;
                case 'pie':
                    this.layout.push({ x: chart.x, y: chart.y, w: chart.w, h: chart.h, i: chart.i, typeof: chart.configuration.type, name: chart.name, desc: chart.description, chartData: chart.data });
                    break;
                case 'text':
                    this.layout.push({ x: chart.x, y: chart.y, w: chart.w, h: chart.h, i: chart.i, typeof: chart.configuration.type, name: chart.name, desc: chart.description, text: text });
                    break;
                case 'number':
                    this.layout.push({ x: chart.x, y: chart.y, w: chart.w, h: chart.h, i: chart.i, typeof: chart.configuration.type, name: chart.name, desc: chart.description, number: number, unit: unit });
                    break;
                default:
                    break;
            }
        },
        updateChart(i, selectedChartType, new_name, new_desc, new_text, new_number, new_unit) {
            console.log('update chart');
            this.isUpdating = false;
            this.chartSelected = false;
            this.requieredValues = false;
            // loop layout
            this.layout.forEach(element => {
                if (element.i == i) {
                    switch (selectedChartType) {
                        case 'line':
                            element.name = new_name;
                            element.desc = new_desc;
                            break;
                        case 'bar':
                            element.name = new_name;
                            element.desc = new_desc;
                            break;
                        case 'pie':
                            element.name = new_name;
                            element.desc = new_desc;
                            break;
                        case 'text':
                            element.name = new_name;
                            element.desc = new_desc;
                            element.text = new_text;
                            break;
                        case 'number':
                            element.name = new_name;
                            element.desc = new_desc;
                            element.number = new_number;
                            element.unit = new_unit;
                            break;
                        default:
                            break;
                    }
                }
            });
        },
        removeChart(i) {
            this.$confirm.require({
                message: this.$t('deleteConfirmationAlert'),
                header: this.$t('deleteConfirmation'),
                icon: 'pi pi-info-circle',
                acceptClass: 'p-button-danger',
                accept: () => {
                    this.layout = this.layout.filter(element => element.i != i);
                    this.chartSelected = false;
                    this.$toast.add({ severity: 'success', summary: this.$t('confirmed'), detail: this.$t('recordDeleted'), life: 3000 });
                },
                reject: () => {
                    //this.$toast.add({severity:'error', summary:this.$t('rejected'), detail:this.$t('rejectedAlert'), life: 3000});
                }
            });
        },
        updateLayout() {
            console.log(this.layout);
        },
        saveDashboard(dashboard_name, dashboard_desc) {
            this.dashboardRequiredValues = false;
            console.log(dashboard_name + ' saved!!');
            // Toast
            this.$toast.add({ severity: 'success', summary: this.$t('success'), detail: this.$t('dashboardSaved'), life: 3000 });
        },
        openUpdateChart(i) {
            this.isUpdating = true;
            this.chartSelected = true;
            this.selectedToUpdateId = i;
            // find chart in layout
            let chart = this.layout.find(element => element.i == i);
            console.log(chart);
            this.selectedChartToUpdate = chart;
            this.selectedChartType = chart.typeof
            this.new_name = chart.name;
            this.new_desc = chart.desc;
            this.new_text = chart.text;
            this.new_number = chart.number;
            this.new_unit = chart.unit;
        },
        changeLabels() {
            // find values in array tempChart.data.labels and substitute as '1' = low, '2' = medium, '3' = high, '4' = critical, '0' = info
            for (let i = 0; i < this.tempChart.data.labels.length; i++) {
                if (this.tempChart.data.labels[i] == 1) {
                    this.tempChart.data.labels[i] = this.$t('low');
                    if (this.tempChart.data.datasets[0].backgroundColor) {
                        this.tempChart.data.datasets[0].backgroundColor[i] = '#6dc193';
                        if (this.tempChart.data.datasets[0].hoverBackgroundColor)
                            this.tempChart.data.datasets[0].hoverBackgroundColor[i] = '#539470';

                    }
                    if (this.tempChart.data.datasets[0].borderColor) {
                        this.tempChart.data.datasets[0].borderColor = '#6dc193';
                    }
                } else if (this.tempChart.data.labels[i] == 2) {
                    this.tempChart.data.labels[i] = this.$t('medium');
                    if (this.tempChart.data.datasets[0].backgroundColor) {
                        this.tempChart.data.datasets[0].backgroundColor[i] = '#f9c851';
                        if (this.tempChart.data.datasets[0].hoverBackgroundColor)
                            this.tempChart.data.datasets[0].hoverBackgroundColor[i] = '#d6a32a';
                    }
                    if (this.tempChart.data.datasets[0].borderColor) {
                        this.tempChart.data.datasets[0].borderColor = '#f9c851';
                    }
                } else if (this.tempChart.data.labels[i] == 3) {
                    this.tempChart.data.labels[i] = this.$t('high');
                    if (this.tempChart.data.datasets[0].backgroundColor) {
                        this.tempChart.data.datasets[0].backgroundColor[i] = '#f86c6b';
                        if (this.tempChart.data.datasets[0].hoverBackgroundColor)
                            this.tempChart.data.datasets[0].hoverBackgroundColor[i] = '#d64a4a';
                    }
                    if (this.tempChart.data.datasets[0].borderColor) {
                        this.tempChart.data.datasets[0].borderColor = '#f86c6b';
                    }
                } else if (this.tempChart.data.labels[i] == 4) {
                    this.tempChart.data.labels[i] = this.$t('critical');
                    if (this.tempChart.data.datasets[0].backgroundColor) {
                        this.tempChart.data.datasets[0].backgroundColor[i] = '#b83c32';
                        if (this.tempChart.data.datasets[0].hoverBackgroundColor)
                            this.tempChart.data.datasets[0].hoverBackgroundColor[i] = '#961f1a';
                    }
                    if (this.tempChart.data.datasets[0].borderColor) {
                        this.tempChart.data.datasets[0].borderColor = '#b83c32';
                    }
                } else if (this.tempChart.data.labels[i] == 0) {
                    this.tempChart.data.labels[i] = this.$t('info');
                    if (this.tempChart.data.datasets[0].backgroundColor) {
                        this.tempChart.data.datasets[0].backgroundColor[i] = '#aeb5c1';
                        if (this.tempChart.data.datasets[0].hoverBackgroundColor)
                            this.tempChart.data.datasets[0].hoverBackgroundColor[i] = '#8c939f';
                    }
                    if (this.tempChart.data.datasets[0].borderColor) {
                        this.tempChart.data.datasets[0].borderColor = '#aeb5c1';
                    }
                }
            }
            if (this.tempChart.data.datasets) {
                for (let i = 0; i < this.tempChart.data.datasets.length; i++) {
                    if (this.tempChart.data.datasets[i].label == 1 || this.tempChart.data.datasets[i].label == 'Low') {
                        this.tempChart.data.datasets[i].label = this.$t('low');
                        this.tempChart.data.datasets[i].borderColor = '#6dc193';
                        if (this.tempChart.configuration.type == 'line')
                            this.tempChart.data.datasets[i].backgroundColor = '#91919126';
                        if (this.tempChart.configuration.type == 'bar')
                            this.tempChart.data.datasets[i].backgroundColor = '#6dc193';
                    } else if (this.tempChart.data.datasets[i].label == 2 || this.tempChart.data.datasets[i].label == 'Medium') {
                        this.tempChart.data.datasets[i].label = this.$t('medium');
                        this.tempChart.data.datasets[i].borderColor = '#f9c851';
                        if (this.tempChart.configuration.type == 'line')
                            this.tempChart.data.datasets[i].backgroundColor = '#91919126';
                        if (this.tempChart.configuration.type == 'bar')
                            this.tempChart.data.datasets[i].backgroundColor = '#f9c851';
                    } else if (this.tempChart.data.datasets[i].label == 3 || this.tempChart.data.datasets[i].label == 'High') {
                        this.tempChart.data.datasets[i].label = this.$t('high');
                        this.tempChart.data.datasets[i].borderColor = '#f86c6b';
                        if (this.tempChart.configuration.type == 'line')
                            this.tempChart.data.datasets[i].backgroundColor = '#91919126';
                        if (this.tempChart.configuration.type == 'bar')
                            this.tempChart.data.datasets[i].backgroundColor = '#f86c6b';

                    } else if (this.tempChart.data.datasets[i].label == 4 || this.tempChart.data.datasets[i].label == 'Critical') {
                        this.tempChart.data.datasets[i].label = this.$t('critical');
                        this.tempChart.data.datasets[i].borderColor = '#b83c32';
                        if (this.tempChart.configuration.type == 'line')
                            this.tempChart.data.datasets[i].backgroundColor = '#91919126';
                        if (this.tempChart.configuration.type == 'bar')
                            this.tempChart.data.datasets[i].backgroundColor = '#b83c32';
                    } else if (this.tempChart.data.datasets[i].label == 0 || this.tempChart.data.datasets[i].label == 'Info') {
                        this.tempChart.data.datasets[i].label = this.$t('info');
                        this.tempChart.data.datasets[i].borderColor = '#aeb5c1';
                        if (this.tempChart.configuration.type == 'line')
                            this.tempChart.data.datasets[i].backgroundColor = '#aeb5c1';
                        if (this.tempChart.configuration.type == 'bar')
                            this.tempChart.data.datasets[i].backgroundColor = '#91919126';
                    }
                }
            }

        },
    },
    async mounted() {
        const userStore = useUserStore();
        const userObject = await userStore.getUser();
        this.user = userObject;

        if (process.env.VUE_APP_ENV === 'development') {
            await (this.layout = require('./layout.json'));
            // find chart in layout
            await nextTick();
        } else if (process.env.VUE_APP_ENV === 'staging' || process.env.VUE_APP_ENV === 'production') {
            this.loading_table = true;
            $api, $api.getOneDashboard(this.$route.query.id).then((response) => {
                console.log("DASHBOARD: ", response);
                this.dashboardName = response.name;
            });
            $api.getOneVisualizationDashboard(this.$route.query.id).then((response) => {
                this.data = response;
                // get the recent dashboards
                console.log("DASHBOARDS: ", this.data);
                // get all visualizations
                for (let i = 0; i < this.data.length; i++) {
                    $api.getOneVisualization(this.data[i].visualization_id).then((response) => {
                        this.tempChart = response;
                        this.tempChart.h = this.data[i].h;
                        this.tempChart.w = this.data[i].w;
                        this.tempChart.x = this.data[i].x;
                        this.tempChart.y = this.data[i].y;
                        this.tempChart.i = this.data[i].visualization_id;
                        console.log("TEMP CHART: ", this.tempChart);
                        this.changeLabels();
                        // add chart to layout
                        this.addChart(this.tempChart);
                        //this.refreshChart(this.tempChart.i)
                    }).catch((err) => {
                        console.log(`[-] Error (getOneVisualization): ${err}`);
                    });
                }
                this.loading_table = false;

            }).catch((err) => {
                console.log(`[-] Error (getOneVisualizationDashboard): ${err.response.data.detail}`);
            });
        } else {
            console.log("ERROR: NO ENVIRONMENT SELECTED");
        }

    }
};
export default MyShared;