<!----------------------------------------------->
<!--                  TEMPLATE                 -->
<!----------------------------------------------->
<template>
  <MobileForgotPass v-if="  mq.current  === 'mobile'"></MobileForgotPass>
  <TabletForgotPass v-if="  mq.current  === 'tablet'"></TabletForgotPass>
  <DesktopForgotPass v-if=" mq.current  === 'desktop'"></DesktopForgotPass>
</template>

<!----------------------------------------------->
<!--                    SCRIPT                 -->
<!----------------------------------------------->
<script>
import MobileForgotPass from "../components/ForgotPass/MobileForgotPass";
import DesktopForgotPass from "../components/ForgotPass/DesktopForgotPass";
import TabletForgotPass from "../components/ForgotPass/TabletForgotPass";

export default {
  /* Name */
  name: "Home",
  inject: ["mq"],
  /* Components */
  components: {
    MobileForgotPass,
    TabletForgotPass,
    DesktopForgotPass,
  },
};
</script>

<script setup>
import { useMq } from "vue3-mq";
const mq = useMq();
</script>
<!----------------------------------------------->
<!--                    STYLES                 -->
<!----------------------------------------------->
<style scoped >

</style>
