<!----------------------------------------------->
<!--                  TEMPLATE                 -->
<!----------------------------------------------->
<template>
  <div class="aux">
    <Sitebar></Sitebar>
    <div class="maincontainer">
      <!-- ==================================================================================================================================================== -->
      <!-- =================================================================== Top Buttons ==================================================================== -->
      <!-- ==================================================================================================================================================== -->
      <div class="row">
        <div class="col-6 p-0"></div>
        <div class="col-6 top-config">
          <i class="pi pi-replay top-config-icon" @click="$router.go()"><span class="top-config-label">&nbsp;{{
              $t('refresh')
          }}</span></i>
          <div class="dropdown">
            <i class="pi pi-clock top-config-icon dropdown-toggle" data-toggle="dropdown"><span
                class="top-config-label">&nbsp;{{ lastHours }}</span></i>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
              <a class="dropdown-item" v-for="hour in hours" :key="hour" @click="setLastHours(hour)">
                {{ hour }}
              </a>
            </div>
          </div>
          <div class="dropdown" v-if="showActions">
            <i class="pi pi-cog top-config-icon dropdown-toggle" data-toggle="dropdown"><span
                class="top-config-label">&nbsp;{{ $t('actions') }}</span></i>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown" style="width:max-content">
              <a class="dropdown-item" @click="editMode = false" v-if="editMode">
                <p><i class="pi pi-times"></i>&nbsp;{{ $t('closeEditMode') }}</p>
              </a>
              <section v-for="(action, index) in actions" :key="action" @click="action.callback()">
                <a class="dropdown-item" v-if="action.permit">
                  <p><i :class="action.icon"></i>&nbsp;{{ action.label }}</p>
                </a>
              </section>
            </div>
          </div>
        </div>
      </div>

      <!-- ==================================================================================================================================================== -->
      <!-- ======================================================================= Top ======================================================================= -->
      <!-- ==================================================================================================================================================== -->
      <div class="row" style="width:100%">
        <div class="col-12 resume-row">
          <Card>
            <template #content>
              <div class="row pl-4">
                <div class="col-2 pt-0">
                  <i class="fa-solid fa-briefcase top-tab-icon"></i>
                  <span class="top-tab-text">
                    {{ data.length }}
                    <i class="fa-solid fa-caret-up top-tab-subicon">
                      <span class="top-tab-subicon-text">21</span>
                    </i>
                  </span>
                  <p class="top-tab-subtext mb-0">{{ $t('eventsTotal') }} </p>
                </div>
                <div class="col-2 pt-0">
                  <i class="fa-solid fa-layer-group top-tab-icon"></i>
                  <span class="top-tab-text">
                    {{ groups.length }}
                    <i class="fa-solid fa-caret-up top-tab-subicon">
                      <span class="top-tab-subicon-text">21</span>
                    </i>
                  </span>
                  <p class="top-tab-subtext mb-0">{{ $t('groupsNumber') }} </p>
                </div>
                <div class="col-2 pt-0">
                  <i class="fa-solid fa-virus-covid top-tab-icon"></i>
                  <span class="top-tab-text">
                    {{ atRiskAssets }}
                    <i class="fa-solid fa-caret-up top-tab-subicon">
                      <span class="top-tab-subicon-text">21</span>
                    </i>
                  </span>
                  <p class="top-tab-subtext mb-0">{{ $t('atRiskAssets') }} </p>
                </div>
                <div class="col-6">
                  <strong class="chart-label">{{ $t('eventsBySeverity') }}</strong>
                  <div class="percentage-bar">
                    <div class="percentage-portion"
                      :style="'display:inline-block; background:#aeb5c1; width:' + (criticality_0) + '%;'"
                      v-if="criticality_0 != -0.8">&nbsp;
                    </div>
                    <div class="percentage-portion" :style="'display:inline-block; background:white; width:1%;'"
                      v-if="(criticality_0 != -0.8 && criticality_1 != -0.8) || (criticality_0 != -0.8 && criticality_1 == -0.8 && criticality_2 != -0.8) || (criticality_0 != -0.8 && criticality_1 == -0.8 && criticality_2 == -0.8 && criticality_3 != -0.8) || (criticality_0 != -0.8 && criticality_1 == -0.8 && criticality_2 == -0.8 && criticality_3 == -0.8 && criticality_4 != -0.8)">
                    </div>

                    <div class="percentage-portion"
                      :style="'display:inline-block; background:#6dc193; width:' + (criticality_1) + '%;'"
                      v-if="criticality_1 != -0.8">&nbsp;
                    </div>
                    <div class="percentage-portion" :style="'display:inline-block; background:white; width:1%;'"
                      v-if="(criticality_1 != -0.8 && criticality_2 != -0.8) || (criticality_1 != -0.8 && criticality_3 != -0.8 && criticality_2 == -0.8) || (criticality_1 != -0.8 && criticality_4 != -0.8 && criticality_2 == -0.8 && criticality_3 == -0.8)">
                    </div>

                    <div class="percentage-portion"
                      :style="'display:inline-block; background:#f7b34c; width:' + (criticality_2) + '%;'"
                      v-if="criticality_2 != -0.8">&nbsp;
                    </div>
                    <div class="percentage-portion" :style="'display:inline-block; background:white; width:1%;'"
                      v-if="(criticality_2 != -0.8 && criticality_3 != -0.8) || (criticality_2 != -0.8 && criticality_4 != -0.8 && criticality_3 == -0.8)">
                    </div>

                    <div class="percentage-portion"
                      :style="'display:inline-block; background:#ef6154; width:' + (criticality_3) + '%;'"
                      v-if="criticality_3 != -0.8">&nbsp;
                    </div>
                    <div class="percentage-portion" :style="'display:inline-block; background:white; width:1%;'"
                      v-if="criticality_3 != -0.8 && criticality_4 != -0.8">
                    </div>
                    <div class="percentage-portion"
                      :style="'display:inline-block; background:#b83c32; width:' + (criticality_4) + '%;'"
                      v-if="criticality_4 != -0.8">&nbsp;
                    </div>
                  </div>

                  <div class="percentage-bar row" style="width: 82%;">
                    <div class="pt-0 pb-0" style="display:ruby; width:auto;"><i class="pi pi-circle-fill"
                        style="color:#aeb5c1; font-size: 0.7em;"></i>&nbsp;{{ $t('informational') }}:
                      <strong>{{ number_of_info_criticality }}</strong>
                    </div>
                    <div class="pt-0 pb-0" style="display:ruby; width:auto;"><i class="pi pi-circle-fill"
                        style="color:#6dc193; font-size: 0.7em;"></i>&nbsp;{{ $t('low') }}:
                      <strong>{{ number_of_low_criticality }}</strong>
                    </div>
                    <div class="pt-0 pb-0" style="display:ruby; width:auto;"><i class="pi pi-circle-fill"
                        style="color:#f7b34c; font-size: 0.7em;"></i>&nbsp;{{ $t('medium') }}:
                      <strong>{{ number_of_medium_criticality }}</strong>
                    </div>
                    <div class="pt-0 pb-0" style="display:ruby; width:auto;"><i class="pi pi-circle-fill"
                        style="color:#ef6154; font-size: 0.7em;"></i>&nbsp;{{ $t('high') }}:
                      <strong>{{ number_of_high_criticality }}</strong>
                    </div>
                    <div class="pt-0 pb-0 pr-0" style="display:ruby; width:auto;"><i class="pi pi-circle-fill"
                        style="color:#b83c32; font-size: 0.7em;"></i>&nbsp;{{ $t('critical')
                        }}:
                      <strong>{{ number_of_critical_criticality }}</strong>
                    </div>
                  </div>
                  <!--
                  <div class="percentage-bar row">
                    <div class="pt-0 pb-0" style="display:ruby; width:auto;"><i class="pi pi-circle-fill" style="color:#6dc193; font-size: 0.7em;"></i>&nbsp;{{$t('low')}}: <strong>{{number_of_low_criticality}}</strong></div>
                    <div class="pt-0 pb-0" style="display:ruby; width:auto;"><i class="pi pi-circle-fill" style="color:#f7b34c; font-size: 0.7em;"></i>&nbsp;{{$t('medium')}}: <strong>{{number_of_medium_criticality}}</strong></div>
                    <div class="pt-0 pb-0" style="display:ruby; width:auto;"><i class="pi pi-circle-fill" style="color:#ef6154; font-size: 0.7em;"></i>&nbsp;{{$t('high')}}: <strong>{{number_of_high_criticality}}</strong></div>
                    <div class="pt-0 pb-0" style="display:ruby; width:auto;"><i class="pi pi-circle-fill" style="color:#b83c32; font-size: 0.7em;"></i>&nbsp;{{$t('critical')}}: <strong>{{number_of_critical_criticality}}</strong></div>
                  </div>
                  -->
                </div>
              </div>
            </template>
          </Card>
        </div>

        <!-- ==================================================================================================================================================== -->
        <!-- ======================================================================= Table ======================================================================= -->
        <!-- ==================================================================================================================================================== -->
        <div :class="tab_position ? 'col-8' : 'col-12 mb-0'">
          <div class="pad-helper main-table pt-2 pr-2 pl-2 m-0" style="height:100%;">
            <span class="p-input-icon-left" style="width:100%;">
              <InputText v-model="filters['global'].value" :placeholder="$t('searchByAsset')" class="search-input mb-2">
              </InputText>
              <MultiSelect v-model="selectedGroups" :options="groups" option-label="name"
                :placeholder="$t('filterByGroup')" display="chip" style="float:right; width:25em;"
                @change="filterByGroup(selectedGroups, false)" class="mb-2"></MultiSelect>
            </span>
           <div class="loader text-center mt-2 mt-2" style="width:100%;" v-if="loading_table">
              <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
              <p>{{ $t('loading') }}</p>
            </div>
            <DataTable v-else :value="data" :paginator="true" class="p-datatable-customers" :rows="tab_position ? 15 : 6"
              :row-hover="true" v-model:selection="selectedAsset" dataKey="id" v-model:filters="filters"
              filter-display="menu" :loading="loading_table" :selectionMode="(!editMode)?'single':''"
              paginator-template="PrevPageLink PageLinks NextPageLink CurrentPageReport"
              :rows-per-page-options="[10, 25, 50]"
              current-page-report-template="Showing {first} to {last} of {totalRecords} entries"
              :global-filter-fields="['id', 'name', 'detection_rule', 'source', 'status', 'severity', 'last_act_date']"
              responsive-layout="scroll" @row-click="setSelectedData($event.data)">
              <template #empty>
                {{$t('noDataFound')}}
              </template>
              <template #loading>
               {{$t('loadingData')}}
              </template>
              <Column field="name" :header="$t('name')" sortable>
                <template #body="{ data }">
                  <span @click="$router.push({ path: 'event-details', query: { id: data.id } })" class="row-name-link">
                    {{ data.title }}
                  </span>
                </template>
              </Column>
              <Column field="source" :header="$t('source')" sortable>
                <template #body="{ data }">
                  {{ data.source.name }}
                </template>
              </Column>
              <Column field="status" :header="$t('status')" sortable>
                <template #body="{ data }">
                  {{ prettifyString(data.status[data.status.length - 1].status) }}
                </template>
              </Column>
              <Column field="severity" :header="$t('severity')" sortable>
                <template #body="{ data }">
                  <span v-if="data.severity == 0"><i class="pi pi-circle-fill  criticality-filter-Info"
                      style="font-size: 0.7em;"></i>&nbsp;{{ $t('informational') }}</span>
                  <span v-if="data.severity == 1"><i class="pi pi-circle-fill  criticality-filter-Low"
                      style="font-size: 0.7em;"></i>&nbsp;{{ $t('low') }}</span>
                  <span v-if="data.severity == 2"><i class="pi pi-circle-fill  criticality-filter-Medium"
                      style="font-size: 0.7em;"></i>&nbsp;{{ $t('medium') }}</span>
                  <span v-if="data.severity == 3"><i class="pi pi-circle-fill  criticality-filter-High"
                      style="font-size: 0.7em;"></i>&nbsp;{{ $t('high') }}</span>
                  <span v-if="data.severity == 4"><i class="pi pi-circle-fill  criticality-filter-Critical"
                      style="font-size: 0.7em;"></i>&nbsp;{{ $t('critical') }}</span>
                </template>
              </Column>
              <Column field="last_act_date" :header="$t('createdAt')" sortable>
                <template #body="{ data }">
                  {{ formatDate(data.created_at) }}
                </template>
              </Column>
            </DataTable>
          </div>
        </div>

        <!-- ==================================================================================================================================================== -->
        <!-- ======================================================================= Tabs ======================================================================= -->
        <!-- ==================================================================================================================================================== -->

        <div :class="tab_position ? 'col-4 pl-0' : 'col-12'"
          :style="tab_position ? 'height:72vh;' : 'height:50vh;'">
          <div class="pad-helper main-table pt-2 pr-2 pl-2 m-0" style="height:100%;">
              <div class="row">
                <div class="change-pos-div">
                  <img v-if="tab_position" style="float:right; cursor:pointer; height:1.25em"
                    @click="tab_position = !tab_position" :src="require('@/assets/icon-bottom.svg')">
                  <img v-if="!tab_position" style="float:right; cursor:pointer; height:1.25em"
                    @click="tab_position = !tab_position" :src="require('@/assets/icon-right.svg')">
                  <!-- 
                  <i v-if="tab_position" class="fa-solid fa-turn-down icon-flipped" style="float:right; cursor:pointer;"
                    @click="tab_position = !tab_position"></i>
                  <i v-if="!tab_position" class="fa-solid fa-turn-up" style="float:right; cursor:pointer;"
                    @click="tab_position = !tab_position"></i>
                  -->
                </div>
              </div>
              <div class="row">
                <TabView>
                  <!-- ====================================================================== Details ============================================================================== -->
                  <TabPanel :header="$t('details')">
                    <div class="loader text-center mt-2 mt-2" style="width:100%;" v-if="loading_details">
                      <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                      <p>{{ $t('loading') }}</p>
                    </div>
                    <div class="scroll" style="overflow-y:auto; overflow-x:hidden;" v-else>
                      <div class="sub-scroll pl-3 pr-3" :style="tab_position ? 'height:35em;' : 'height:25em;'">
                        <div class="row details-top">
                          <i class="fa-solid fa-yaveremos detail-icon"><span class="detail-name">{{ details.title
                          }}</span></i>
                          <p class="mb-0 pl-0"
                            @click="$router.push({ path: 'event-details', query: { id: details.id } })">
                            <span class="id-prefix" style="text-decoration:none;">Event ID:</span> <span class="details-id">
                              {{ details.id }}
                            </span>
                          </p>
                        </div>
                        <div class="row details-data">
                          <div class="col-8 p-0">
                            <Card>
                              <template #content>
                                <i class="fa-solid fa-filter subdetail-icon">
                                  <p class="subdetail-name">&nbsp;{{ details.source.name }}</p>
                                  <p class="subdetail-tag">&nbsp;{{ $t('source') }}</p>
                                </i>
                              </template>
                            </Card>
                          </div>
                          <div class="col-4 p-0">
                            <Card>
                              <template #content>
                                <i v-if="details.severity == 1" class="fa-solid fa-shield-halved subdetail-icon"
                                  style="color:#6dc193">
                                  <p class="subdetail-name" v-if="details.severity == 1">&nbsp;{{ $t('low') }}</p>
                                  <p class="subdetail-tag">&nbsp;{{ $t('severity') }}</p>
                                </i>
                                <i v-if="details.severity == 2" class="fa-solid fa-shield-halved subdetail-icon"
                                  style="color:#f7b34c">
                                  <p class="subdetail-name" v-if="details.severity == 2">&nbsp;{{ $t('medium') }}</p>
                                  <p class="subdetail-tag">&nbsp;{{ $t('severity') }}</p>
                                </i>
                                <i v-if="details.severity == 3" class="fa-solid fa-shield-halved subdetail-icon"
                                  style="color:#ef6154">
                                  <p class="subdetail-name" v-if="details.severity == 3">&nbsp;{{ $t('high') }}</p>
                                  <p class="subdetail-tag">&nbsp;{{ $t('severity') }}</p>
                                </i>
                                <i v-if="details.severity == 4" class="fa-solid fa-shield-halved subdetail-icon"
                                  style="color:#b83c32">
                                  <p class="subdetail-name" v-if="details.severity == 4">&nbsp;{{ $t('critical') }}
                                  </p>
                                  <p class="subdetail-tag">&nbsp;{{ $t('severity') }}</p>
                                </i>
                              </template>
                            </Card>
                          </div>
                        </div>
                        <div class="row details-data">
                          <div :class="tab_position ? 'col-12 pr-0 pl-0' : 'col-6 pr-4'">
                            <div class="row">
                              <p class="mt-0 mb-0 details-title"><strong>{{ $t('description') }}</strong></p>
                              <p class="">{{ details.description }}</p>
                              <p class="mt-0 mb-0 details-title"><strong>{{ $t('createdAt') }}</strong></p>
                              <p class="">{{ formatDate(details.created_at) }}</p>
                              <p class="mt-0 mb-0 details-title"><strong>{{ $t('status') }}</strong></p>
                              <p class="">{{ details.status[details.status.length - 1].status }} - <span
                                  style="color:darkgray">{{ details.status[details.status.length - 1].description
                                  }}</span></p>
                            </div>
                          </div>
                          <div :class="tab_position ? 'col-12 pr-0 pl-0 pt-0' : 'col-6 pr-4'">
                            <p class="mt-0 mb-2 details-title"><strong>{{ $t('detectionRule') }}</strong></p>
                            <div class="row tab-detection-card">
                              <p class="mt-0 mb-0"><strong>{{ details.detection_rule.title }}</strong></p>
                              <p class="details-id mt-0 mb-0" style="font-size: 0.8em;"
                                @click="$router.push({ path: 'detection-rule-details', query: { id: details.detection_rule.id } })">
                                {{ details.detection_rule.id }}</p>
                              <p class="mt-0 mb-0" style="font-size: 0.9em;">{{ details.detection_rule.description }}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                  <!-- ====================================================================== Incidents =========================================================================== -->
                  <TabPanel :header="$t('incidents')">
                    <div class="loader text-center mt-2" style="width:100%;" v-if="loading_details">
                      <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                      <p>{{ $t('loading') }}</p>
                    </div>
                    <div class="row pb-2 pl-1 pr-1 pt-1" style="overflow-y:auto;" v-else>
                      <div class="aux pt-2" :style="tab_position ? 'height:55vh;' : 'height:35vh;'">
                        <OpenGroup v-for="(incident, index) in details.incidents" :key="incident"
                          :groupName="incident.title" :openFirst="index == 0 ? true : false">
                          <template #body>
                            <p class="p-0 m-0 group-title"><strong>{{ $t('ID') }}</strong></p>
                            <p class="p-0 m-0 group-data row-name-link">{{ incident.id }}</p>
                            <p class="p-0 m-0 mt-2 group-title "><strong>{{ $t('description') }}</strong></p>
                            <p class="p-0 m-0 group-data">{{ incident.description }}</p>
                            <p class="p-0 m-0 mt-2 group-title "><strong>{{ $t('severity') }}</strong></p>
                            <p v-if="incident.severity == 0" class="p-0 m-0 group-data">
                              <i class="pi pi-circle-fill  criticality-filter-Info"
                                style="color:rgb(109, 122, 193); font-size: 0.8em;"></i>
                              <span>&nbsp;{{ $t('informational') }}</span>
                            </p>
                            <p v-if="incident.severity == 1" class="p-0 m-0 group-data">
                              <i class="pi pi-circle-fill  criticality-filter-Low"
                                style="color:#6dc193; font-size: 0.8em;"></i>
                              <span>&nbsp;{{ $t('low') }}</span>
                            </p>
                            <p v-if="incident.severity == 2" class="p-0 m-0 group-data">
                              <i class="pi pi-circle-fill  criticality-filter-Medium"
                                style="color:#f7b34c; font-size: 0.8em;"></i>
                              <span>&nbsp;{{ $t('medium') }}</span>
                            </p>
                            <p v-if="incident.severity == 3" class="p-0 m-0 group-data">
                              <i class="pi pi-circle-fill  criticality-filter-High"
                                style="color:#ef6154; font-size: 0.8em;"></i>
                              <span>&nbsp;{{ $t('high') }}</span>
                            </p>
                            <p v-if="incident.severity == 4" class="p-0 m-0 group-data">
                              <i class="pi pi-circle-fill  criticality-filter-Critical"
                                style="color:#b83c32; font-size: 0.8em;"></i>
                              <span>&nbsp;{{ $t('critical') }}</span>
                            </p>
                            <p class="p-0 m-0 mt-2 group-title "><strong>{{ $t('createdAt') }}</strong></p>
                            <p class="p-0 m-0 group-data">{{ formatDate(incident.created_at) }}</p>
                            <p class="p-0 m-0 mt-2 group-title "><strong>{{ $t('status') }}</strong></p>
                            <p class="p-0 m-0 group-data">{{ incident.status[incident.status.length - 1].status }} -
                              <span style="color:darkgray">{{ incident.status[incident.status.length - 1].description
                              }}</span></p>
                            <p class="p-0 m-0 group-data"></p>
                          </template>
                        </OpenGroup>
                      </div>
                    </div>
                  </TabPanel>
                  <!-- ====================================================================== Logs =========================================================================== -->
                  <TabPanel :header="$t('logs')">
                    <div class="loader text-center mt-2" style="width:100%;" v-if="loading_details">
                      <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                      <p>{{ $t('loading') }}</p>
                    </div>
                    <div class="row pb-2 pl-1 pr-1 pt-1" style="overflow-y:auto;" v-else>
                      <div class="aux pt-2" :style="tab_position ? 'height:55vh;' : 'height:35vh;'">
                        <OpenGroup v-for="(log, index) in details.logs" :key="log" :groupName="log.id"
                          :openFirst="index == 0 ? true : false">
                          <template #body>
                            <p class="p-0 m-0 group-title"><strong>{{ $t('assetId') }}</strong></p>
                            <p class="p-0 m-0 group-data row-name-link"
                              @click="$router.push({ path: 'asset-details', query: { id: data.id } })">{{ log.asset_id
                              }}</p>
                            <p class="p-0 m-0 mt-2 group-title"><strong>{{ $t('hostname') }}</strong></p>
                            <p class="p-0 m-0 group-data">{{ log.log.asset.hostname }}</p>
                            <p class="p-0 m-0 mt-2 group-title "><strong>{{ $t('date') }}</strong></p>
                            <p class="p-0 m-0 group-data">{{ formatDate(log.log.date) }}</p>
                          </template>
                        </OpenGroup>
                      </div>
                    </div>
                  </TabPanel>
                  <!-- ====================================================================== Status history =========================================================================== -->
                  <TabPanel :header="$t('statusHistory')">
                    <div class="loader text-center mt-2" style="width:100%;" v-if="loading_details">
                      <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                      <p>{{ $t('loading') }}</p>
                    </div>
                    <div class="row pb-2 pl-1 pr-1 pt-1" style="overflow-y:auto;" v-else>
                      <div class="aux pt-2" :style="tab_position ? 'height:55vh;' : 'height:35vh;'">
                        <OpenGroup v-for="(status, index) in details.status" :key="status"
                          :groupName="status.status + ' - ' + status.date" :openFirst="index == 0 ? true : false">
                          <template #body>
                            <p class="p-0 m-0 mt-2 group-title "><strong>{{ $t('status') }}</strong></p>
                            <p class="p-0 m-0 group-data">{{ status.status }}</p>
                            <p class="p-0 m-0 mt-2 group-title "><strong>{{ $t('description') }}</strong></p>
                            <p class="p-0 m-0 group-data">{{ status.description }}</p>
                            <p class="p-0 m-0 mt-2 group-title "><strong>{{ $t('date') }}</strong></p>
                            <p class="p-0 m-0 group-data">{{ status.date }}</p>
                          </template>
                        </OpenGroup>
                      </div>
                    </div>
                  </TabPanel>
                </TabView>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<!----------------------------------------------->
<!--                    SCRIPT                 -->
<!----------------------------------------------->

<script>
import MyShared from "./shared";
export default {
  mixins: [MyShared],
};

</script>

<script setup>
import { useMq } from "vue3-mq";
const mq = useMq();
</script>

<!----------------------------------------------->
<!--                    STYLES                 -->
<!----------------------------------------------->
<style>
</style>
