<!----------------------------------------------->
<!--                  TEMPLATE                 -->
<!----------------------------------------------->
<template>
  <div class="aux">
    <Sitebar></Sitebar>
    <div class="maincontainer">
      <div class="row">
        <div class="col-6">
          <div class="welcome-container">
            <img class="welcome-img" :src="require('@/assets/logo.svg')" />
            <h2 class="welcome-text">{{ $t('welcomeMsg') }}</h2>
            <div class="working-text">

              <h6 class="mt-4" style="color:gray"></h6>
              <div class="mt-0 home-resume-card pt-0 pb-2" style="width:100% !important;">
                <div class="row" >
                  <div class="col-2">
                    <img class="company-logo" :src="getSourceLogo(user.organization.logo)" />
                  </div>
                  <div class="col-4">
                    <h5 class="company-name">{{ user.organization.name }}</h5>
                    <h6 class="company-role">{{ user.roles[0].name }}</h6>
                  </div>
                  <div class="col-3 m-0">
                    <p class="company-data m-0">{{ users.length }} <i class="pi pi-user"></i><small class="company-data-legend">users</small></p>
                  </div>
                  <div class="col-3 m-0">
                    <p class="company-data m-0">{{teams.length}} <i class="pi pi-users"></i><small class="company-data-legend">teams</small></p>
                  </div>
                </div>
              </div>
              <h6 class="mt-4" style="color:gray">{{ $t('workingOnTeams') }}</h6>
              <div class="team-div mt-2">
                <div class="team" v-for="team in teams" :key="team" @click="$router.push({ path: 'manage-roles' })">
                  <p class="p-0 m-0 team-text"><i class="pi pi-users"></i>&nbsp;{{ team.name }}</p>
                </div>
              </div>
              <h2 class="welcome-text mt-0">{{ $t('quickAccess') }}</h2>
              <div class="card-div mt-2">
                <router-link to="incidents" style="text-decoration:none; color:black;">
                  <div class="card">
                    <p class="p-0 m-0 card-text"><i class="pi pi-briefcase"></i>&nbsp;&nbsp;{{ $t('incidents') }}</p>
                  </div>
                </router-link>
                <router-link to="events" style="text-decoration:none; color:black;">
                  <div class="card">
                    <p class="p-0 m-0 card-text"><i class="pi pi-exclamation-triangle"></i>&nbsp;&nbsp;{{ $t('events')
                    }}</p>
                  </div>
                </router-link>
                <router-link to="dashboards" style="text-decoration:none; color:black;">
                  <div class="card">
                    <p class="p-0 m-0 card-text"><i class="pi pi-chart-line"></i>&nbsp;&nbsp;{{ $t('dashboards') }}</p>
                  </div>
                </router-link>
                <router-link to="assets" style="text-decoration:none; color:black;">
                  <div class="card">
                    <p class="p-0 m-0 card-text"><i class="pi pi-server"></i>&nbsp;&nbsp;{{ $t('assets') }}</p>
                  </div>
                </router-link>
                <router-link to="logs" style="text-decoration:none; color:black;">
                  <div class="card">
                    <p class="p-0 m-0 card-text"><i class="pi pi-desktop"></i>&nbsp;&nbsp;{{ $t('logs') }}</p>
                  </div>
                </router-link>
                <router-link to="integrations" style="text-decoration:none; color:black;">
                  <div class="card">
                    <p class="p-0 m-0 card-text"><i class="pi pi-th-large"></i>&nbsp;&nbsp;{{ $t('integrations') }}</p>
                  </div>
                </router-link>
              </div>

            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="row resume-row mb-2 p-2" style="height:25em;">
            <h5 class="recent-main-title"><i class="pi pi-briefcase"></i>&nbsp;{{$t('incidentsByState')}}</h5>
            <MapChart :countryData="countryData" highColor="#b83c32"
              lowColor="#ffb7b0" countryStrokeColor="#909090" defaultCountryFillColor="#f9f9f9" LangUser="en" :showEmptyValue="false" :currencyAdd="false" :showLegend="true"/>
          </div>
          <div class="row resume-row mb-2 p-2">
            <h5 class="recent-main-title"><i class="pi pi-briefcase"></i>&nbsp;{{$t('mostRecentIncidetns')}}</h5>
            <DataTable :value="recentIncidents" class="p-datatable-customers" :rows="6" :row-hover="true"
              :global-filter-fields="['id', 'name', 'criticality', 'createdAt', 'status']" dataKey="id"
              responsive-layout="scroll">
              <template #empty>
                {{$t('noDataFound')}}
              </template>
              <template #loading>
               {{$t('loadingData')}}
              </template>
              <Column field="name" :header="$t('name')">
                <template #body="{ data }">
                  <span @click="$router.push({ path: 'incident-details', query: { id: data.id } })"
                    class="row-name-link">{{
                        reduceString(data.title,55)
                    }} </span>
                </template>
              </Column>
              <Column field="criticality" :header="$t('criticality')">
                <template #body="{ data }">
                  <span v-if="data.severity == 1"><i class="pi pi-circle-fill  criticality-filter-Low"
                      style="font-size: 0.7em;"></i>&nbsp;{{ $t('low') }}</span>
                  <span v-if="data.severity == 2"><i class="pi pi-circle-fill  criticality-filter-Medium"
                      style="font-size: 0.7em;"></i>&nbsp;{{ $t('medium') }}</span>
                  <span v-if="data.severity == 3"><i class="pi pi-circle-fill  criticality-filter-High"
                      style="font-size: 0.7em;"></i>&nbsp;{{ $t('high') }}</span>
                  <span v-if="data.severity == 4"><i class="pi pi-circle-fill  criticality-filter-Critical"
                      style="font-size: 0.7em;"></i>&nbsp;{{ $t('critical') }}</span>
                </template>
              </Column>
              <Column field="createdAt" :header="$t('createdAt')">
                <template #body="{ data }">
                  {{ formatDate(data.created_at) }}
                </template>
              </Column>
              <Column field="status" :header="$t('status')">
                <template #body="{ data }">
                  {{ data.status[0].status }}
                </template>
              </Column>
            </DataTable>
          </div>
          <div class="row resume-row mb-2 p-2">
            <h5 class="recent-main-title"><i class="pi pi-chart-line"></i>&nbsp;{{$t('dashboards')}}</h5>
            <div class="recent-container row ml-0 mt-0">
              <div class="col-12 pl-0">
                <div class="p-3 recent-dashboard"
                  @click="$router.push({ path: 'dashboard-viewer', query: { id: dashboard.id } })"
                  v-for="dashboard in recentDashboards" :key="dashboard">
                  <p class="p-0 m-0 card-text"><i class="pi pi-desktop"></i>&nbsp;&nbsp;
                    {{ dashboard.name }}
                  </p>
                  <p class="pl-4 m-0 card-text">
                    <small>{{ reduceString(dashboard.description, 50) }}</small>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <!-- 
          <div class="row resume-row">
            <div class="col-4 pt-4 text-center">
              <number style="font-size: 8em; font-weight:700" ref="users_number" :from="0" :to="80" :duration="2"
                easing="Power1.easeOut" />
              <h3>{{ $t('totalIncidents') }}</h3>
            </div>
            <div class="col-5">
              <Chart type="doughnut" :data="chartData" :options="lightOptions" />
            </div>
            <div class="col-3" style="padding-top:2em">
              <div class="col-12 legend" style="border-left:#b83c32 10px solid;">
                <p class="m-0 p-0 incidents-label">&nbsp;{{ $t('critical') }}:</p>
                <p class="m-0 p-0 incidents-number">&nbsp;40</p>
              </div>
              <div class="col-12 legend" style="border-left:#ef6154 10px solid;">
                <p class="m-0 p-0 incidents-label">&nbsp;{{ $t('high') }}:</p>
                <p class="m-0 p-0 incidents-number">&nbsp;60</p>
              </div>
              <div class="col-12 legend" style="border-left:#f7b34c 10px solid;">
                <p class="m-0 p-0 incidents-label">&nbsp;{{ $t('medium') }}:</p>
                <p class="m-0 p-0 incidents-number">&nbsp;10</p>
              </div>
              <div class="col-12 legend" style="border-left:#6dc193 10px solid;">
                <p class="m-0 p-0 incidents-label">&nbsp;{{ $t('low') }}:</p>
                <p class="m-0 p-0 incidents-number">&nbsp;30</p>
              </div>
              <div class="col-12 legend" style="border-left:#aeb5c1 10px solid;">
                <p class="m-0 p-0 incidents-label">&nbsp;{{ $t('informational') }}:</p>
                <p class="m-0 p-0 incidents-number">&nbsp;60</p>
              </div>
            </div>
          </div>
          <div class="row resume-row mt-4">
            <Chart type="line" :data="lineChartData" :options="lineChartOptions" />
          </div>
          -->
        </div>
      </div>
    </div>
  </div>
</template>
<!----------------------------------------------->
<!--                    SCRIPT                 -->
<!----------------------------------------------->

<script>
import MyShared from "./shared";
export default {
  mixins: [MyShared],
};

</script>

<script setup>
import { useMq } from "vue3-mq";
const mq = useMq();
</script>

<!----------------------------------------------->
<!--                    STYLES                 -->
<!----------------------------------------------->
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

p {
  font-family: 'Open Sans', sans-serif !important;
}

.incidents-number {
  font-size: 1.25em;
  font-weight: 800 !important;
}
.company-logo{
  max-height: 100%;
  max-width: 100%;
  padding-right: 0;
  height: 100%;
  vertical-align: middle;
}
.company-data{
  font-size: 2.5em;
}
.company-data-legend{
  font-size: 0.35em !important;
  margin-left: 0.5em;
}

.recent-main-title {
  font-weight: 700 !important;
}

.incidents-label {
  font-size: 0.8em;
}



.welcome-container {
  margin-left: 2em;
  padding-right: 7em;
}

.resume-row {
  background: white;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
}

.welcome-img {
  height: 10em;
  padding: 0.5em;
  padding-right: 0;
}

.welcome-text {
  padding-left: 0em;
  font-size: 2rem;
  padding-top: 1em;
  font-weight: 200;
}

.working-text {
  font-size: 0.9rem;
}



.team {
  margin-right: 1em;
  margin-top: 1em;
  width: fit-content;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  border-radius: 3px;
  padding: 0.5em;
  padding-left: 1em;
  padding-right: 1em;
  display: inline-block;
  cursor: pointer;
}

.team:hover {
  box-shadow: 0 4px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
}

.team-text {
  font-size: 0.8rem;
}

.card {
  margin-right: 1em;
  margin-top: 1em;
  min-width: 12.5em;
  max-width: 15em;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  border-radius: 3px;
  padding: 1.5em;
  padding-top: 2em;
  padding-bottom: 2em;
  display: inline-block;
}

.home-resume-card{
  margin-right: 1em;
  margin-top: 1em;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  border-radius: 3px;
  padding: 1.5em;
  padding-top: 2em;
  padding-bottom: 2em;
  display: inline-block;
  cursor: pointer;
}

.card:hover {
  box-shadow: 0 4px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
}

.card-text {
  font-size: 0.9rem;
}

.legend {
  font-size: 1rem;
  margin: 0 !important;
  padding: 0 !important;
  margin-top: 0.25em !important;
}

.recent-dashboard {
  width: 14em !important;
  display: inline-block !important;
  border: 1px solid rgba(0, 0, 0, .125);
  border-radius: .25rem;
  margin-right: 1em;
  background-color: white;
  cursor: pointer;
  margin-top: 0.5em;
}
.vue-world-map[data-v-a77a8494], #map-svg[data-v-a77a8494] {
  max-height: 90% !important;
}
</style>
