import $axios from '../utils';

export function getAllLogs(full = true, offset = 0, limit = 10000) {
    return $axios.get(`intake/log/?full=${full}&offset=${offset}&limit=${limit}`)
        .then(response => response.data)
}
export function getLogBy(key, value, full, offset, limit) {
    return $axios.get(`intake/log/by?key${key}&value=${value}&full=${full}&offset=${offset}&limit=${limit}`)
        .then(response => response.data)
}
export function getOneLog(id, full) {
    return $axios.get(`intake/log/${id}?full=${full}`)
        .then(response => response.data)
}
export function createLog(data) {
    return $axios.post(`intake/log/`, data)
        .then(response => response.data)
}
export function updateLog(id, data) {
    return $axios.post('intake/log/', data)
        .then(response => response.data)
}
export function deleteAllLogs() {
    return $axios.delete('intake/log/')
        .then(response => response.data)
}
export function deleteLog(id) {
    return $axios.delete(`intake/log/${id}`)
        .then(response => response.data)
}
export function getLogsSources() {
    return $axios.get('intake/log/storage/sources')
        .then(response => response.data)
}
export function getLogsFields(source) {
    return $axios.get(`intake/log/storage/sources/${source}/fields`)
        .then(response => response.data)
}

export function getIntakeLogs(source, init_date, end_date, filter, offset = 0, limit = 13) {
    return $axios.get(`intake/log/storage/query/${source}?init_date=${init_date}&end_date=${end_date}&filter=`+ filter +`&offset=${offset}&limit=${limit}`)
        .then(response => response.data)
}

export function getStats(source, init_date, end_date, filter, interval) {
    return $axios.get(`intake/log/storage/stats/${source}?init_date=${init_date}&end_date=${end_date}&filter=`+ filter +`&calendar_interval=${interval}`)
        .then(response => response.data)
}