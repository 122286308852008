import $axios from '../utils';

export function getMe() {
    return $axios.get('management/me/')
        .then(response => response.data)
}
export function updateMe(data) {
    return $axios.put('management/me/', data)
        .then(response => response.data)
}
export function deleteMe() {
    return $axios.delete('management/me/')
        .then(response => response.data)
}
export function changePassword(data) {
    return $axios.put('management/me/change-password/', data)
        .then(response => response.data)
}
export function newPicture(data) {
    return $axios.put('management/me/new-picture/', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(response => response.data)
}