<!----------------------------------------------->
<!--                  TEMPLATE                 -->
<!----------------------------------------------->
<template>
  <MobileManageRoles v-if="  mq.current  === 'mobile'"></MobileManageRoles>
  <TabletManageRoles v-if="  mq.current  === 'tablet'"></TabletManageRoles>
  <DesktopManageRoles v-if=" mq.current  === 'desktop'"></DesktopManageRoles>
</template>

<!----------------------------------------------->
<!--                    SCRIPT                 -->
<!----------------------------------------------->
<script>
import MobileManageRoles from "../components/ManageRoles/MobileManageRoles";
import TabletManageRoles from "../components/ManageRoles/TabletManageRoles";
import DesktopManageRoles from "../components/ManageRoles/DesktopManageRoles";

export default {
  /* Name */
  name: "ManageRoles",
  inject: ["mq"],
  /* Components */
  components: {
    MobileManageRoles,
    TabletManageRoles,
    DesktopManageRoles,
  },
};
</script>

<script setup>
import { useMq } from "vue3-mq";
const mq = useMq();
</script>

<!----------------------------------------------->
<!--                    STYLES                 -->
<!----------------------------------------------->
<style scoped >
</style>
