<!----------------------------------------------->
<!--                  TEMPLATE                 -->
<!----------------------------------------------->
<template>
  <MobileCorrelation v-if="  mq.current  === 'mobile'"></MobileCorrelation>
  <TabletCorrelation v-if="  mq.current  === 'tablet'"></TabletCorrelation>
  <DesktopCorrelation v-if=" mq.current  === 'desktop'"></DesktopCorrelation>
</template>

<!----------------------------------------------->
<!--                    SCRIPT                 -->
<!----------------------------------------------->
<script>
import MobileCorrelation from "../components/Correlation/MobileCorrelations";
import TabletCorrelation from "../components/Correlation/TabletCorrelations";
import DesktopCorrelation from "../components/Correlation/DesktopCorrelations";

export default {
  /* Name */
  name: "Correlation",
  inject: ["mq"],
  /* Components */
  components: {
    MobileCorrelation,
    TabletCorrelation,
    DesktopCorrelation,
  },
};
</script>

<script setup>
import { useMq } from "vue3-mq";
const mq = useMq();
</script>

<!----------------------------------------------->
<!--                    STYLES                 -->
<!----------------------------------------------->
<style scoped >
</style>
