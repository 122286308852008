<!----------------------------------------------->
<!--                  TEMPLATE                 -->
<!----------------------------------------------->
<template>
  <div>
    <Sitebar></Sitebar>
    <div class="maincontainer" :style="'--my-font-var:' + primaryFont + ';   padding-bottom: 4rem;'">
      <div class="row">
        <div class="col-6">
          <h3 class="mb-0">{{$t('personalInformation')}}</h3>
          <p class="profile-subtitle">{{$t('personalInformationDescription')}}</p>
          <Card>
            <template #content>
              <div class="row profile-info-card mt-2">
                <h5 class="mb-0">{{$t('basicInformation')}}</h5>
                <p class="profile-subtitle">{{$t('basicInformationDescription')}}</p>
                <div class="row profile-data-row">
                  <div class="col-2 pt-0 profile-data-row-title">{{$t('picture')}}</div>
                  <div class="col-8 pt-0">{{$t('pictureDescription')}}</div>
                  <div class="col-2 pt-0">
                    <Avatar v-if="user.picture == null" :label="user.full_name[0].toUpperCase()" class="update-profile-img" shape="circle" size="large" @click="$refs.uploadFile.openModal()"/>
                    <img v-else :src="getSourceLogo(user.picture)" class="rounded-circle update-profile-img" height="45" width="45" @click="$refs.uploadFile.openModal()" />
                  </div>
                </div>
                <hr class="mb-0 ml-2" style="width:98%;">
                <div class="row profile-data-row">
                  <div class="col-2 pt-0 profile-data-row-title">{{$t('name')}}</div>
                  <div class="col-8 pt-0" v-if="!loading && !edit_full_name">{{user.full_name}}</div>
                  <div class="col-8 pt-0" v-if="loading"><ContentLoader height="20px" width="100px"></ContentLoader></div>
                  <div class="col-8 pt-0" v-if="edit_full_name"><InputText v-model="user.full_name"></InputText></div>
                  <div class="col-2 pt-0" v-if="!edit_full_name"><i class="pi pi-pencil" style="float: right; cursor: pointer;" @click="edit_full_name = !edit_full_name"></i></div>
                  <div class="col-2 pt-0" v-else><i class="pi pi-save" style="float: right; cursor: pointer;" @click="updateFullName(its_me)"></i></div>
                </div>
                <hr class="mb-0 ml-2" style="width:98%;">
                <div class="row profile-data-row">
                  <div class="col-2 pt-0 profile-data-row-title">{{$t('birthdate')}}</div>
                  <div class="col-8 pt-0" v-if="!loading && !edit_birthdate">{{formatDate(user.birthdate)}}</div>
                  <div class="col-8 pt-0" v-if="loading"><ContentLoader height="20px" width="100px"></ContentLoader></div>
                  <div class="col-8 pt-0" v-if="edit_birthdate"><Calendar v-model="user.birthdate"></Calendar></div>
                  <div class="col-2 pt-0" v-if="!edit_birthdate"><i class="pi pi-pencil" style="float: right; cursor: pointer;" @click="edit_birthdate = !edit_birthdate"></i></div>
                  <div class="col-2 pt-0" v-else><i class="pi pi-save" style="float: right; cursor: pointer;" @click="updateBirthdate(its_me)"></i></div>
                </div>
                <hr class="mb-0 ml-2" style="width:98%;">
                <div class="row profile-data-row">
                  <div class="col-2 pt-0 profile-data-row-title">{{$t('gender')}}</div>
                  <div class="col-8 pt-0" v-if="!loading && !edit_gender">{{user.gender}}</div>
                  <div class="col-8 pt-0" v-if="loading"><ContentLoader height="20px" width="100px"></ContentLoader></div>
                  <div class="col-8 pt-0" v-if="edit_gender"><Dropdown style="width: 100%;" :options="['male', 'female', 'other']" v-model="user.gender"></Dropdown></div>
                  <div class="col-2 pt-0" v-if="!edit_gender"><i class="pi pi-pencil" style="float: right; cursor: pointer;" @click="edit_gender = !edit_gender"></i></div>
                  <div class="col-2 pt-0" v-else><i class="pi pi-save" style="float: right; cursor: pointer;" @click="updateGender(its_me)"></i></div>
                </div>
              </div>
            </template>
          </Card>
          <Card class="mt-4">
            <template #content>
              <div class="row profile-info-card">
                <h5 class="mb-0">{{$t('contactInformation')}}</h5>
                <p class="profile-subtitle">{{$t('contactInformationDescription')}}</p>
                <div class="row profile-data-row">
                  <div class="col-2 pt-0 profile-data-row-title">{{$t('email')}}</div>
                  <div class="col-8 pt-0" v-if="!loading && !edit_email">{{user.email}}</div>
                  <div class="col-8 pt-0" v-if="loading"><ContentLoader height="20px" width="100px"></ContentLoader></div>
                  <div class="col-8 pt-0" v-if="edit_email"><InputText v-model="user.email"></InputText></div>
                  <div class="col-2 pt-0" v-if="!edit_email"><i class="pi pi-pencil" style="float: right; cursor: pointer;" @click="edit_email = !edit_email"></i></div>
                  <div class="col-2 pt-0" v-else><i class="pi pi-save" style="float: right; cursor: pointer;" @click="updateEmail(its_me)"></i></div>
                </div>
                <hr class="mb-0 ml-2" style="width:98%;">
                <div class="row profile-data-row">
                  <div class="col-2 pt-0 profile-data-row-title">{{$t('phone')}}</div>
                  <div class="col-8 pt-0" v-if="!loading && !edit_phone">{{user.phone}}</div>
                  <div class="col-8 pt-0" v-if="loading"><ContentLoader height="20px" width="100px"></ContentLoader></div>
                  <div class="col-8 pt-0" v-if="edit_phone"><InputText v-model="user.phone"></InputText></div>
                  <div class="col-2 pt-0" v-if="!edit_phone"><i class="pi pi-pencil" style="float: right; cursor: pointer;" @click="edit_phone = !edit_phone"></i></div>
                  <div class="col-2 pt-0" v-else><i class="pi pi-save" style="float: right; cursor: pointer;" @click="updatePhone(its_me)"></i></div>
                </div>
              </div>
            </template>
          </Card>
          <Card class="mt-4">
            <template #content>
              <div class="row profile-info-card">
                <h5 class="mb-0">{{$t('password')}}</h5>
                <p class="profile-subtitle">{{$t('passwordDescription')}}</p>
                <div class="col-8 pt-0" v-if="!edit_pass">•••••••••••••</div>
                <div class="col-8 pt-0" v-if="edit_pass">
                  <label for="pass" class="mt-1 mb-0 pr-2">{{ $t('oldPassword') }}</label>
                  <InputText id="pass" type="password" v-model="old_pass" class="input-text"></InputText>
                </div>
                <div class="col-8 pt-0" v-if="edit_pass">
                  <p class="show_error" v-if="show_pass_error">{{pass_error}}</p>
                  <label for="pass" class="mt-1 mb-0 pr-2">{{ $t('password') }}</label>
                  <InputText id="pass" type="password" v-model="new_pass" class="input-text"></InputText>
                </div>
                <div class="col-8 pt-0" v-if="edit_pass">
                  <label for="pass" class="mt-1 mb-0 pr-2">{{ $t('confirmPassword') }}</label>
                  <InputText id="pass" type="password" v-model="new_confirm_pass" class="input-text"></InputText>
                </div>
                <div class="col-4 pt-0">
                  <Button v-if="!its_me" class="btn-custom-save mb-0" :label="$t('sendMail')" style="float:right" @click="updatePass()"></Button>
                  <Button v-if="!edit_pass && its_me" class="btn-custom-save mb-0" :label="$t('change')" style="float:right" @click="edit_pass =! edit_pass"></Button>
                  <Button v-if="edit_pass && its_me" class="btn-custom-save mb-0" :label="$t('save')" style="float:right" @click="updatePass()"></Button>
                  <Button v-if="edit_pass && its_me" class="btn-custom mb-0 mr-1" :label="$t('cancel')" style="float:right" @click="edit_pass =! edit_pass"></Button>

                </div>
              </div>
            </template>
          </Card>
        </div>
        <div class="col-6">
          <h3 class="mb-0">{{$t('teams')}}</h3>
          <p class="profile-subtitle mb-2">{{$t('mainDescription')}}</p>
          <Card>
            <template #content>
              <div class="row profile-info-card mt-2">
                <h5 class="mb-0">{{$t('teams')}}</h5>
                <p class="profile-subtitle mb-0">{{$t('teamsSubDescription')}}</p>
                <div class="team-div mt-2">
                  <div class="team" v-for="team in user.teams" :key="team">
                    <p class="p-0 m-0 team-text"><i class="pi pi-users"></i>&nbsp;{{ team.name }}</p>
                  </div>
                </div>
              </div>
            </template>
          </Card>
          <h3 class="mb-0 mt-4">{{$t('roles')}}</h3>
          <p class="profile-subtitle mb-2">{{$t('mainDescription')}}</p>
          <Card>
            <template #content>
              <div class="row profile-info-card">
                <h5 class="mb-0">{{$t('roles')}}</h5>
                <p class="profile-subtitle mb-0">{{$t('rolesDescription')}}</p>
                <div class="team-div mt-2">
                  <div class="team" v-for="role in user.roles" :key="role">
                    <p class="p-0 m-0 team-text"><i class="pi pi-users"></i>&nbsp;{{ role.name }}</p>
                  </div>
                </div>
              </div>
            </template>
          </Card>
          <h3 v-if="its_me" class="mb-0 mt-4">{{$t('prefereces')}}</h3>
          <p v-if="its_me" class="profile-subtitle mb-2">{{$t('preferecesDescription')}}</p>
          <Card v-if="its_me">
            <template #content>
              <div class="row profile-info-card">
                <h5 class="mb-0">{{$t('lenguage')}}</h5>
                <p class="profile-subtitle mb-0">{{$t('lenguageDescription')}}</p>
                <div class="row profile-data-row">
                  <div class="col-1 pt-0 pl-4">
                    <div class="dropdown">
                      <a class="" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="false"
                        aria-expanded="false">
                        <img :src="locale_img" class="rounded-circle mt-2" height="30" alt="" width="30" />
                      </a>
                      <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                        <a class="dropdown-item" v-for="entry in languages" :key="entry"
                          @click="changeLanguage(entry.language)">
                          <img :src="entry.flag" class="rounded-circle mr-2" height="20" alt="" width="20" /> {{
                              entry.title
                          }}
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="col-2 pt-0 profile-data-row-title">
                    <div class="row">
                      <span class="profile-data-row-title pl-0">{{$t('lenguage')}}</span>
                    </div>
                    <div class="row">
                      <span class="pl-0">{{ locale_name }}</span>
                    </div>
                  </div>
                  <div class="col-8"></div>
                </div>
              </div>
            </template>
          </Card>
          <Card v-if="its_me" class="mt-4">
            <template #content>
              <div class="row profile-info-card">
                <h5 class="mb-0">{{$t('sesions')}}</h5>
                <p class="profile-subtitle mb-0 mt-2" style="cursor: pointer;" @click="logout()"><i class="pi pi-sign-out" style="font-size: small;"></i>&nbsp;{{$t('logout')}}</p>
              </div>
            </template>
          </Card>
        </div>
        
      </div>
    </div>
    <Modal ref="uploadFile">
      <template #header>
        &nbsp;
      </template>
      <template #body>
        <div class="col-12"></div>
        <FileUpload name="demo[]" @uploader="onAdvancedUpload($event)" :multiple="false" accept="image/*"
          :max-file-size="1000000" customUpload >
          <template #content>
            <ul v-if="uploadedFiles && uploadedFiles[0]">
              <li v-for="file of uploadedFiles[0]" :key="file">{{ file.name }} - {{ file.size }} bytes</li>
            </ul>
          </template>
          <template #empty>
            <p>Drag and drop files to here to upload.</p>
          </template>
        </FileUpload>
      </template>
    </Modal>
  </div>
</template>

<!----------------------------------------------->
<!--                    SCRIPT                 -->
<!----------------------------------------------->
<script>
import MyShared from "./shared";

export default {
  mixins: [MyShared],
};
</script>

<!----------------------------------------------->
<!--                    STYLES                 -->
<!----------------------------------------------->
<style>
.profile-info-card {
  padding: 1rem;
}

.profile-subtitle {
  color: gray;
  font-size: 0.9rem;
}

.profile-data-row {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.profile-data-row {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.profile-data-row-title {
  font-size: 0.9rem;
}

.update-profile-img:hover {
  cursor: pointer;
  filter: brightness(1.5);
}

/* Custom buttons */
.p-button.p-fileupload-choose {
  background: #ec635f !important;
  color: white;
  border: none !important;
  padding: 0.25rem 0.5rem !important;
  font-weight: normal !important;
  width: auto !important;
  font-size: small !important;
}

.p-button {
  background: #ec635f !important;
  color: white;
  border: none !important;
  padding: 0.25rem 0.5rem !important;
  font-weight: normal !important;
  width: auto !important;
  font-size: small !important;
}

.p-button.p-button-rounded {
  border-radius: 500px !important;
  max-width: 2em !important;
  max-height: 2em !important;
  font-size: small !important;
}

.p-dialog .p-button {
  min-width: 2em !important;
}

.btn-custom:focus {
  box-shadow: 0px 0px 2px #ec635f !important;
}

.p-button-icon {
  font-size: small !important;
}

.btn-custom:hover {
  background: #bd4f4b !important;
}

.team {
  margin-right: 1em;
  margin-top: 1em;
  width: fit-content;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  border-radius: 3px;
  padding: 0.5em;
  padding-left: 1em;
  padding-right: 1em;
  display: inline-block;
  cursor: pointer;
}
.team:hover {
  box-shadow: 0 4px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
}
.team-text {
  font-size: 0.8rem;
}
.show_error{
  color: #ec635f;
  font-size: 0.8em;
  margin-left: 0 !important;
  padding: 0 !important;
}
</style>


