<!----------------------------------------------->
<!--                  TEMPLATE                 -->
<!----------------------------------------------->
<template>
  <MobileRecoverPass v-if="  mq.current  === 'mobile'"></MobileRecoverPass>
  <TabletRecoverPass v-if="  mq.current  === 'tablet'"></TabletRecoverPass>
  <DesktopRecoverPass v-if=" mq.current  === 'desktop'"></DesktopRecoverPass>
</template>

<!----------------------------------------------->
<!--                    SCRIPT                 -->
<!----------------------------------------------->
<script>
import MobileRecoverPass from "../components/RecoverPass/MobileRecoverPass";
import DesktopRecoverPass from "../components/RecoverPass/DesktopRecoverPass";
import TabletRecoverPass from "../components/RecoverPass/TabletRecoverPass";

export default {
  /* Name */
  name: "Home",
  inject: ["mq"],
  /* Components */
  components: {
    MobileRecoverPass,
    TabletRecoverPass,
    DesktopRecoverPass,
  },
};
</script>

<script setup>
import { useMq } from "vue3-mq";
const mq = useMq();
</script>
<!----------------------------------------------->
<!--                    STYLES                 -->
<!----------------------------------------------->
<style scoped >

</style>
