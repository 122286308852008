import Sitebar from "../../components/Partials/Sitebar/SiteBar.vue"
import Helper from "../../components/Partials/Helper/Helper.vue"
import { FilterMatchMode, FilterOperator } from 'primevue/api';
import OpenGroup from "../../components/Partials/OpenGroup/OpenGroup.vue"
import CustomChart from "../../components/Partials/CustomChart/CustomChart.vue"
import Modal from "../../components/Partials/Modals/Modal.vue"
import ConfirmDialog from "primevue/confirmdialog";
import * as $api from "../../api/api";
import { useUserStore } from '../../store/store.js'
import Chart from 'primevue/chart';

import { nextTick } from "vue";

var MyShared = {
    /* Name */
    name: "Home",
    /* Data */
    setup() {

    },
    data() {
        return {
            user: {
                "permissions": []
            },
            alertMethods: [],
            // New Alert Configuration
            new_detectionRules: [],
            new_correlationRules: [],
            new_assets: [],
            new_users: [],
            //................
            editMode: false,
            uploadReady: false,
            uploadError: false,
            uploadLoading: false,
            showActions: true,
            actions: [{
                    label: this.$t('newAlertConfiguration'),
                    icon: 'pi pi-plus',
                    callback: () => { this.createAlert() },
                    permit: false
                },
                {
                    label: this.$t('editMode'),
                    icon: 'pi pi-pencil',
                    callback: () => { this.changeToEditMode() },
                    permit: false
                },
                {
                    label: this.$t('import'),
                    icon: 'pi pi-upload',
                    callback: () => { this.openImportData() },
                    permit: false
                },
            ],
            viewmode: 0,
            viewmodemenu: false,
            tab_position: true,

            filters: {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
                id: {
                    operator: FilterOperator.AND,
                    constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
                },
                name: {
                    operator: FilterOperator.AND,
                    constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
                },
                os: {
                    operator: FilterOperator.AND,
                    constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
                },
                host: {
                    operator: FilterOperator.AND,
                    constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
                },
                type: {
                    operator: FilterOperator.AND,
                    constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
                },
                criticality: {
                    operator: FilterOperator.OR,
                    constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
                },
                last_act_date: {
                    operator: FilterOperator.AND,
                    constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
                }
            },
            filters1: {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
                id: {
                    operator: FilterOperator.AND,
                    constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
                },
                title: {
                    operator: FilterOperator.AND,
                    constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
                }
            },
            selectedData: null,
            atRiskAssets: 0,
            selectedGroups: [],

            isActiveStatuses: [
                { label: this.$t('activated'), value: true },
                { label: this.$t('deactivated'), value: false }
            ],
            hours: [
                this.$t('all_time'),
                this.$t('last_12_hours'),
                this.$t('last_24_hours'),
                this.$t('last_48_hours'),
                this.$t('last_7_days'),
                this.$t('last_1_month'),
            ],
            lastHours: this.$t('all_time'),
            data: [],
            data_backup: {},
            details: {
                "description": "",
                "id": "",
                "organization_id": "",
                "filters": [
                    "Command Execution",
                ],
                "name": "",
                "severity": 3
            },
            criticalities: [
                { label: this.$t('low'), value: 1 },
                { label: this.$t('medium'), value: 2 },
                { label: this.$t('high'), value: 3 },
                { label: this.$t('critical'), value: 4 },
                { label: this.$t('informational'), value: 0 }
            ],
            statuses: [
                { label: this.$t('new'), status: 'new' },
                { label: this.$t('active'), status: 'active' },
                { label: this.$t('mitigated'), status: 'mitigated' },
                { label: this.$t('closed'), status: 'closed' }
            ],
            groups: [],
            criticality_0: 0,
            criticality_1: 0,
            criticality_2: 0,
            criticality_3: 0,
            criticality_4: 0,
            number_of_low_criticality: 0,
            number_of_medium_criticality: 0,
            number_of_high_criticality: 0,
            number_of_critical_criticality: 0,
            loading_table: false,
            loading_details: false,

            activeAlerts: 0,
            activeAlertMethods: 0,

            new_alert_tags: [],
            new_alert_name: '',
            new_alert_description: '',

            slack_checked: false,
            email_checked: false,
            phone_checked: false,

            items: [{
                    label: this.$t('details'),
                    step: 0
                },
                {
                    label: this.$t('detectionRules'),
                    step: 1
                },
                {
                    label: this.$t('correlationRules'),
                    step: 2
                },
                {
                    label: this.$t('assets'),
                    step: 3
                },
                {
                    label: this.$t('users'),
                    step: 4
                }
            ],
            step: 0,
            new_selectedCorrelationsRules: [],
            new_selectedDetectionRules: [],
            new_selectedAssets: [],
            new_selectedUsers: [],
        }
    },
    /* Components */
    components: {
        Sitebar,
        Helper,
        OpenGroup,
        Chart,
        CustomChart,
        Modal,
        ConfirmDialog
    },
    /* Props */
    props: {},
    /* Methods */
    methods: {
        openImportData() {
            this.$refs.importData.openModal();
        },
        onUploadOne() {
            console.log('onUploadOne');
            this.uploadLoading = true;
            setTimeout(() => {
                this.uploadLoading = false;
                this.uploadReady = true;
                this.$toast.add({ severity: 'success', summary: this.$t('success'), detail: this.$t('importedSuccesfully'), life: 3000 });
            }, 2000);
        },
        onUploadVarious() {
            console.log('onUploadVarious');
            this.uploadLoading = true;
            setTimeout(() => {
                this.uploadLoading = false;
                this.uploadReady = true;
                this.$toast.add({ severity: 'success', summary: this.$t('success'), detail: this.$t('importedSuccesfully'), life: 3000 });
            }, 2000);
        },
        theFormat(number) {
            return number.toFixed(2);
        },
        setSelectedData(data) {
            this.loading_details = true;
            $api.getOneAlert(data.id, true).then((response) => {
                this.details = data;
                if (response.correlation_rules.length > 0) {
                    this.details.correlation_rules = response.correlation_rules;
                }
                if (response.detection_rules.length > 0) {
                    this.details.detection_rules = response.detection_rules;
                }
                if (response.assets.length > 0) {
                    this.details.assets = response.assets;
                }
                if (response.users.length > 0) {
                    this.details.users = response.users;
                }
                this.loading_details = false;
            }).catch((err) => {
                console.log(`[-] Error (getOneAsset): ${err}`);
            });
        },
        setLastHours(hour) {
            this.loading_table = true;
            this.lastHours = hour;
            let sdate = new Date();
            let edate = new Date();

            switch (this.lastHours) {
                case this.$t('last_12_hours'):
                    sdate.setHours(sdate.getHours() - 12);
                    break;
                case this.$t('last_24_hours'):
                    sdate.setHours(sdate.getHours() - 24);
                    break;
                case this.$t('last_48_hours'):
                    sdate.setHours(sdate.getHours() - 48);
                    break;
                case this.$t('last_7_days'):
                    sdate.setDate(sdate.getDate() - 7);
                    break;
                case this.$t('last_1_month'):
                    sdate.setMonth(sdate.getMonth() - 1);
                    break;
                case this.$t('all_time'):
                    sdate.setFullYear(sdate.getFullYear() - 10);
                    break;
            }

            // Format dates to yyyy-mm-ddThh:mm:ss
            sdate = sdate.toISOString().split('.')[0];
            edate = edate.toISOString().split('.')[0];

            $api.getAllAssets(true, sdate).then((response) => {
                this.data = response;
                if (this.data) {
                    this.details = this.data[0];
                    this.calcPercentageByCriticality();
                    this.createChartsData();
                }

                this.loading_table = false;
            }).catch((err) => {
                console.log(`[-] Error (getAssets): ${err}`);
            });
        },
        formatDate(date) {
            if (date == null || date == '') return;

            // Format date to dd/mm/yyyy hh:mm
            let d = new Date(date);
            let day = d.getDate();
            let month = d.getMonth() + 1;
            let year = d.getFullYear();
            let hour = d.getHours();
            let minutes = d.getMinutes();
            // Add 0 if month is less than 10
            if (month < 10) {
                month = "0" + month;
            }
            // Add 0 if day is less than 10
            if (day < 10) {
                day = "0" + day;
            }
            // Add 0 if minutes is less than 10
            if (minutes < 10) {
                minutes = "0" + minutes;
            }
            return `${day}/${month}/${year} ${hour}:${minutes}`;
        },
        getActiveAlerts() {
            // Get all Alerts with is_active == true
            let data = this.data;
            let data_filtered = [];
            for (let i = 0; i < data.length; i++) {
                if (data[i].is_active) {
                    data_filtered.push(data[i]);
                }
            }
            console.log(data_filtered);
            this.activeAlerts = data_filtered.length;
        },
        createAlert() {
            console.log('Create Alert');
            this.$refs.createAlert.openModal();
            //get detection rules
            $api.getAllDetectionRules(false).then((response) => {
                this.new_detectionRules = response;
                console.log("New Detection Rules: ", this.new_detectionRules);
            }).catch((error) => {
                console.log(error);
            });
            //get correlation rules
            $api.getAllCorrelationRules(false).then((response) => {
                this.new_correlationRules = response;
                console.log("New Correlation Rules: ", this.new_correlationRules);
            }).catch((error) => {
                console.log(error);
            });
            //get assets
            $api.getAllAssets(false).then((response) => {
                this.new_assets = response;
                console.log("New Assets: ", this.new_assets);
            }).catch((error) => {
                console.log(error);
            });
            //get users
            $api.getAllUsers(false).then((response) => {
                this.new_users = response;
                console.log("New Users: ", this.new_users);
            }).catch((error) => {
                console.log(error);
            });
        },
        createArrayOfIds(array) {
            let ids = [];
            for (let i = 0; i < array.length; i++) {
                ids.push(array[i].id);
            }
            return ids;
        },
        createNewAlertConfiguration(name, desc, tags) {
            console.log("Selected Detection Rules: ", this.createArrayOfIds(this.new_selectedDetectionRules));
            console.log("Selected Correlation Rules: ", this.createArrayOfIds(this.new_selectedCorrelationsRules));
            console.log("Selected Assets: ", this.createArrayOfIds(this.new_selectedAssets));
            console.log("Selected Users: ", this.createArrayOfIds(this.new_selectedUsers));

            let alert_methods = [];
            if (this.slack_checked) {
                // find obj with method == slack in alertMethods and push the id
                for (let i = 0; i < this.alertMethods.length; i++) {
                    if (this.alertMethods[i].method == 'slack') {
                        alert_methods.push(this.alertMethods[i].id);
                    }
                }
            }
            if (this.email_checked) {
                // find obj with method == email in alertMethods and push the id
                for (let i = 0; i < this.alertMethods.length; i++) {
                    if (this.alertMethods[i].method == 'email') {
                        alert_methods.push(this.alertMethods[i].id);
                    }
                }
            }
            if (this.phone_checked) {
                // find obj with method == phone in alertMethods and push the id
                for (let i = 0; i < this.alertMethods.length; i++) {
                    if (this.alertMethods[i].method == 'phone') {
                        alert_methods.push(this.alertMethods[i].id);
                    }
                }
            }


            let new_alert = {
                "name": name,
                "description": desc,
                "filters": tags,
                "detection_rules": this.createArrayOfIds(this.new_selectedDetectionRules),
                "correlation_rules": this.createArrayOfIds(this.new_selectedCorrelationsRules),
                "assets": this.createArrayOfIds(this.new_selectedAssets),
                "users": this.createArrayOfIds(this.new_selectedUsers),
                "is_active": true,
                "alert_methods": alert_methods
            }
            $api.createAlert(new_alert).then((response) => {
                console.log("New Alert: ", response);
                this.data.push(response);
                this.$toast.add({ severity: 'success', summary: this.$t('confirmed'), detail: this.$t('recordCreated'), life: 3000 });
                this.$refs.createAlert.closeModal();
            }).catch((error) => {
                console.log(error);
                this.$toast.add({ severity: 'error', summary: this.$t('error'), detail: this.$t('errorAlert'), life: 3000 });
            });
        },
        changeToEditMode() {
            this.editMode = true;
            this.selectedData = null
        },
        deleteData() {
            this.$confirm.require({
                message: this.$t('deleteConfirmationAlert'),
                header: this.$t('deleteConfirmation'),
                icon: 'pi pi-info-circle',
                acceptClass: 'p-button-danger',
                accept: () => {
                    console.log(this.selectedData);
                    // loop selectedData and delete each one
                    for (let i = 0; i < this.selectedData.length; i++) {
                        $api.deleteAlert(this.selectedData[i].id).then((response) => {
                            console.log(`[+] Alert ${this.selectedData[i].name} deleted`);
                            // toast
                            this.$toast.add({ severity: 'success', summary: this.$t('success'), detail: this.$t('recordDeleted'), life: 3000 });
                            //refresh 
                            this.$router.go();
                        }).catch((err) => {
                            console.log(`[-] Error (deleteAlert): ${err}`);
                            // toast
                            this.$toast.add({ severity: 'error', summary: this.$t('error'), detail: this.$t('recordNotDeleted'), life: 3000 });
                        });
                    }
                },
                reject: () => {
                    //this.$toast.add({severity:'error', summary:this.$t('rejected'), detail:this.$t('rejectedAlert'), life: 3000});
                }
            });
        },
        setCriticalityForSelectedItems(criticality){
            this.$confirm.require({
                message: this.$t('confirmDialog'),
                header: this.$t('confirmation'),
                icon: 'pi pi-info-circle',
                acceptClass: 'p-button-danger',
                accept: () => {
                    console.log(this.selectedData);
                    // loop selectedData and delete each one
                    for (let i = 0; i < this.selectedData.length; i++) {
                        $api.updateAlert(this.selectedData[i].id, { "severity": criticality }).then((response) => {
                            console.log(`[+] Asset ${this.selectedData[i].id} updated`);
                            // toast
                            this.$toast.add({ severity: 'success', summary: this.$t('success'), detail: this.$t('assetUpdated'), life: 3000 });
                            //refresh
                            this.$router.go();
                        }).catch((err) => {
                            console.log(`[-] Error (updateAsset): ${err}`);
                            // toast
                            this.$toast.add({ severity: 'error', summary: this.$t('error'), detail: this.$t('assetNotUpdated'), life: 3000 });
                        });;
                    }
                },
                reject: () => {
                    //this.$toast.add({severity:'error', summary:this.$t('rejected'), detail:this.$t('rejectedAlert'), life: 3000});
                }
            });
        },
        updateSeverity(id, severity) {
            let data = this.data;

            for (let i = 0; i < data.length; i++) {
                if (data[i].id == id) {
                    data[i].severity = severity;
                    $api.updateAlert(id, { "severity": severity }).then((response) => {
                        console.log(`[+] Alert ${id} updated`);
                        // toast
                        this.$toast.add({ severity: 'success', summary: this.$t('success'), detail: this.$t('alertUpdated'), life: 3000 });
                    }).catch((err) => {
                        console.log(`[-] Error (updateAlert): ${err}`);
                        // toast
                        this.$toast.add({ severity: 'error', summary: this.$t('error'), detail: this.$t('alertNotUpdated'), life: 3000 });
                    });;
                }
            }
            this.data = data;
            //this.calcPercentageByCriticality();
        },
        updateIsActive(id, is_active) {
            let data = this.data;
            console.log();
            for (let i = 0; i < data.length; i++) {
                if (data[i].id == id) {
                    data[i].is_active = is_active;
                    $api.updateAlert(id, { "is_active": is_active }).then((response) => {
                        console.log(`[+] Alert ${id} updated`);
                        // toast
                        this.$toast.add({ severity: 'success', summary: this.$t('success'), detail: this.$t('alertUpdated'), life: 3000 });
                    }).catch((err) => {
                        console.log(`[-] Error (updateAlert): ${err}`);
                        // toast
                        this.$toast.add({ severity: 'error', summary: this.$t('error'), detail: this.$t('alertNotUpdated'), life: 3000 });
                    });;
                }
            }
            this.data = data;
            //this.calcPercentageByCriticality();
        }

    },
    async mounted() {
        const userStore = useUserStore();
        const userObject = await userStore.getUser();
        this.user = userObject;

        // set actions permissions
        this.actions[0].permit = this.hasUserPermissionGlobal(this.user, 'alert:create')
        this.actions[1].permit = this.hasUserPermissionGlobal(this.user, 'alert:update')
        this.actions[2].permit = this.hasUserPermissionGlobal(this.user, 'alert:create')
            // Loop actions, if all are false, then hide actions button
        let allFalse = true;
        for (let i = 0; i < this.actions.length; i++) {
            if (this.actions[i].permit) {
                allFalse = false;
                break;
            }
        }
        if (allFalse) {
            this.showActions = false;
        }

        // get assets from API
        if (process.env.VUE_APP_ENV === 'development') {
            await (this.data = require('./alerts.json'));
            await (this.data_backup = require('./alerts.json'));
        } else if (process.env.VUE_APP_ENV === 'staging' || process.env.VUE_APP_ENV === 'production') {
            this.loading_table = true;
            $api.getAllAlerts().then((response) => {
                this.data = response;
                this.data_backup = response;
                console.log("ALERTS: ", this.data);

                /* ---- Data loaded ---- */
                this.selectedData = this.data[0];
                this.setSelectedData(this.data[0]);
                if (this.data)
                    this.details = this.data[0];
                this.loading_table = false;

                this.getActiveAlerts();
                this.lastHours = this.hours[0];

            }).catch((err) => {
                this.loading_table = false;
                this.loading_details = false;
                console.log(`[-] Error (getAllAlerts): ${err}`);
            });
        } else {
            console.log("ERROR: NO ENVIRONMENT SELECTED");
        }
        // get all alert methods
        $api.getAllAlertMethods(false).then((response) => {
            this.alertMethods = response;
            console.log("New Alert Methods: ", this.alertMethods);
        }).catch((error) => {
            console.log(error);
        });
    }
};
export default MyShared;