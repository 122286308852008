<!----------------------------------------------->
<!--                  TEMPLATE                 -->
<!----------------------------------------------->
<template>
    <span class="custom-helper"><i class="pi pi-question-circle" style="font-size: small;" v-tooltip.top="hlp_msg"></i></span>
</template>

<!----------------------------------------------->
<!--                    SCRIPT                 -->
<!----------------------------------------------->
<script>
export default {
  name: "Helper",
  data() {
        return {
            user: {
                "permissions": []
            },
        };
    },
  methods: {

  },
  props: {
    hlp_msg: String
  },
};
</script>
  
<!----------------------------------------------->
<!--                    STYLES                 -->
<!----------------------------------------------->
<style scoped>
.custom-helper{
  margin-left: 3px;
}
</style>
  