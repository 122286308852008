<!----------------------------------------------->
<!--                  TEMPLATE                 -->
<!----------------------------------------------->
<template>
  <MobileDetectionDetails v-if="  mq.current  === 'mobile'"></MobileDetectionDetails>
  <TabletDetectionDetails v-if="  mq.current  === 'tablet'"></TabletDetectionDetails>
  <DesktopDetectionDetails v-if=" mq.current  === 'desktop'"></DesktopDetectionDetails>
</template>

<!----------------------------------------------->
<!--                    SCRIPT                 -->
<!----------------------------------------------->
<script>
import MobileDetectionDetails from "../components/DetectionDetails/MobileDetectionDetails";
import TabletDetectionDetails from "../components/DetectionDetails/TabletDetectionDetails";
import DesktopDetectionDetails from "../components/DetectionDetails/DesktopDetectionDetails";

export default {
  /* Name */
  name: "DetectionDetails",
  inject: ["mq"],
  /* Components */
  components: {
    MobileDetectionDetails,
    TabletDetectionDetails,
    DesktopDetectionDetails,
  },
};
</script>

<script setup>
import { useMq } from "vue3-mq";
const mq = useMq();
</script>

<!----------------------------------------------->
<!--                    STYLES                 -->
<!----------------------------------------------->
<style scoped >
</style>
