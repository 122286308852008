<!----------------------------------------------->
<!--                  TEMPLATE                 -->
<!----------------------------------------------->
<template>
  <div class="aux">
    <nav class="main-menu" @mouseover="addPadding()" @mouseout="reducePadding()" @click="addPadding()">
      <div class="settings"></div>

      <ul>
        <li class="group-item">
          <span class="nav-text" v-if="is_side_open"><small style="font-weight: 400 !important; text-transform:uppercase;">{{ $t('general') }}</small></span>
          <hr class="m-0">
        </li>
        <li class="item" v-if="!is_side_open">
          &nbsp;
          <hr class="mt-0 ml-3">
        </li>
        <li class="item">
          <router-link to="home">
            <v-icon class="ml-3" name="md-home-outlined" scale="1.25"/>
            <span class="nav-text">{{ $t('home') }}</span>
          </router-link>
        </li>
        <li class="item" v-if="hasUserPermission('source' + ':get')">
          <router-link to="source">
            <v-icon class="ml-3" name="md-allinbox-outlined" scale="1.25"/>
            <span class="nav-text">{{ $t('integrations') }}</span>
          </router-link>
        </li>
        <li class="item" v-if="hasUserPermission('listener' + ':get')">
          <router-link to="listener">
            <v-icon class="ml-3" name="md-filteralt-outlined" scale="1.25"/>
            <span class="nav-text">{{ $t('listeners') }}</span>
          </router-link>
        </li>
        <li class="item" v-if="hasUserPermission('log' + ':get')">
          <router-link to="log">
            <v-icon class="ml-3" name="md-desktopmac-outlined" scale="1.25"/>
            <span class="nav-text">{{ $t('logs') }}</span>
          </router-link>
        </li>
        <li class="item" v-if="hasUserPermission('asset' + ':get')">
          <router-link to="asset">
            <v-icon class="ml-3" name="md-dns-outlined" scale="1.25"/>
            <span class="nav-text">{{ $t('assets') }}</span>
          </router-link>
        </li>
        <li class="item" > <!-- v-if="hasUserPermission('investigation' + ':get')" -->
          <router-link to="investigation">
            <v-icon class="ml-3" name="md-mediation-outlined" scale="1.25"/>
            <span class="nav-text">{{ $t('investigations') }}</span>
          </router-link>
        </li>
        <li class="item" v-if="hasUserPermission('dashboard' + ':get')">
          <router-link to="dashboard">
            <v-icon class="ml-3" name="md-insertchart-outlined" scale="1.25"/>
            <span class="nav-text">{{ $t('dashboards') }}<Tag class="ml-1" value="beta" style="height: 1.25em"></Tag></span> 
          </router-link>
        </li>
        <li class="group-item">
            <span class="nav-text" v-if="is_side_open"><small style="font-weight: 400 !important; text-transform:uppercase;">{{ $t('alerts') }}</small></span>
            <hr :class="is_side_open ? 'm-0' : 'mt-3 mb-1 ml-0 mr-0'">
        </li>
        <li class="item" v-if="hasUserPermission('event' + ':get')">
          <router-link to="event">
            <v-icon class="ml-3" name="md-warning-outlined" scale="1.25"/>
            <span class="nav-text">{{ $t('events') }}</span>
          </router-link>
        </li>
        <li class="item" v-if="hasUserPermission('incident' + ':get')">
          <router-link to="incident">
            <v-icon class="ml-3" name="md-cases-outlined" scale="1.25"/>
            <span class="nav-text">{{ $t('incidents') }}</span>
          </router-link>
        </li>
        <li class="item" v-if="hasUserPermission('alert' + ':get')">
          <router-link to="alert">
            <v-icon class="ml-3" name="md-settings-outlined" scale="1.25"/>
            <span class="nav-text">{{ $t('alertConfiguration') }}</span>
          </router-link>
        </li>
        <li class="group-item">
          <span class="nav-text" v-if="is_side_open"><small style="font-weight: 400 !important; text-transform:uppercase;">{{ $t('threatDetection') }}</small></span>
          <hr :class="is_side_open ? 'm-0' : 'mt-3 mb-1 ml-0 mr-0'">
        </li>
        <!--
        <li class="item" v-if="hasUserPermission('machine-learning-job' + ':get')">
          <router-link to="machine-learning-job">
            <i class="fa pi-sitemap pi fa-lg"></i>
            <span class="nav-text">{{ $t('machineLearning') }}</span>
          </router-link>
        </li>
        -->
        <li class="item" v-if="hasUserPermission('correlation-rule' + ':get')">
          <router-link to="correlation-rule">
            <v-icon class="ml-3" name="md-link-outlined" scale="1.25"/>
            <span class="nav-text">{{ $t('correlation') }}</span>
          </router-link>
        </li>
        <li class="item" v-if="hasUserPermission('detection-rule' + ':get')">
          <router-link to="detection-rule">
            <v-icon class="ml-3" name="md-flag-outlined" scale="1.25"/>
            <span class="nav-text">{{ $t('detection') }}</span>
          </router-link>
        </li>
        <!--
        <li class="group-item">
          <span class="nav-text"><small style="font-weight: 400 !important; text-transform:uppercase;">{{ $t('UEBA') }}</small></span>
        </li>
        <li class="item">
          <router-link to="surveillance">
            <i class="fa pi-eye pi fa-lg"></i>
            <span class="nav-text">{{ $t('surveillance') }}<Tag class="ml-1" value="New" style="height: 1.25em; background-color: #ec635f;"></Tag></span>
          </router-link>
        </li>
        -->
        <li class="group-item">
          <span class="nav-text" v-if="is_side_open"><small style="font-weight: 400 !important; text-transform:uppercase;">{{ $t('management') }}</small></span>
          <hr :class="is_side_open ? 'm-0' : 'mt-3 mb-1 ml-0 mr-0'">
        </li>
        <!--
        <li class="item">
          <router-link to="manage-users">
            <i class="fa pi-user pi fa-lg"></i>
            <span class="nav-text">{{ $t('manageUsers') }}</span>
          </router-link>
        </li>
        
        <li class="item">
          <router-link to="manage-teams">
            <v-icon class="ml-3" name="md-people-outlined" scale="1.25"/>
            <span class="nav-text">{{ $t('manageTeams') }}</span>
          </router-link>
        </li>
        -->
        <!--
        <li class="item" v-if="hasUserPermission('task:get')">
          <router-link to="task">
            <v-icon class="ml-3" name="md-calendar-outlined" scale="1.25"/>
            <span class="nav-text">{{ $t('tasks') }}</span>
          </router-link>
        </li>
        -->
        <li class="item">
          <router-link to="manage-roles" v-if="hasUserPermission('manage-roles:get')">
            <v-icon class="ml-3" name="md-people-outlined" scale="1.25"/>
            <span class="nav-text">{{ $t('manageUsersAndRoles') }}</span>
          </router-link>
        </li>
        <li class="item" v-if="hasUserPermission('organization:get')">
          <router-link to="organization">
            <v-icon class="ml-3" name="md-business-outlined" scale="1.25"/>
            <span class="nav-text">{{ $t('manageOrganizations') }}</span>
          </router-link>
        </li>
        
        <li class="item">
          <router-link to="documentation">
            <v-icon class="ml-3" name="md-insertdrivefile-outlined" scale="1.25"/>
            <span class="nav-text">{{ $t('documentation') }}</span>
          </router-link>
        </li>
        
      </ul>
    </nav>
  </div>
</template>
  
  <!----------------------------------------------->
  <!--                    SCRIPT                 -->
  <!----------------------------------------------->
<script>
import MyShared from "./sharedSideBar";

export default {
  mixins: [MyShared],
};
</script>

<script setup>
import { useMq } from "vue3-mq";
const mq = useMq();
</script>
  
  <!----------------------------------------------->
  <!--                    STYLES                 -->
  <!----------------------------------------------->
<style>
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

.maincontainer {
  width: 100% !important;
  padding-left: 80px;
  padding-right: 2.5% !important;
}

.details-asset-title{
  margin-left: 0rem;
}

.settings {
  height: 73px;
  float: left;
  background-repeat: no-repeat;
  width: 250px;
  margin: 0px;
  text-align: center;
  font-size: 20px;
}

.fa-lg {
  font-size: 1em;
}
.fa {
  position: relative;
  display: table-cell;
  width: 55px;
  height: 36px;
  text-align: center;
  top: 12px;
  font-size: 20px;
}
.main-menu:hover,
nav.main-menu.expanded {
  width: 250px;
  opacity: 1;
}

.main-menu {
  background: #fff;
  position: fixed;
  top: 0;
  bottom: 0;
  height: 100%;
  left: 0;
  width: 55px; /* 55px para que entre y salga */
  -webkit-transition: width .2s linear;
  transition: width .2s linear;
  -webkit-transform: translateZ(0) scale(1, 1);
  box-shadow: 1px 0 15px rgba(0, 0, 0, 0.07);
  opacity: 1;
  z-index: 3 !important;
  overflow-y: auto;
  overflow-x: hidden;
}
.main-menu>ul {
  margin: 7px 0;
}

.main-menu li {
  position: relative;
  display: block;
  width: 250px;
}

.main-menu li>a {
  position: relative;
  width: 255px;
  display: table;
  border-collapse: collapse;
  border-spacing: 0;
  color: #6b6b6b;
  font-size: 13px;
  text-decoration: none;
  -webkit-transform: translateZ(0) scale(1, 1);
  -webkit-transition: all .14s linear;
  transition: all .14s linear;
}

.main-menu .nav-icon {
  position: relative;
  display: table-cell;
  width: 55px;
  height: 36px;
  text-align: center;
  vertical-align: middle;
  font-size: 18px;
}

.main-menu .nav-text {
  position: relative;
  display: table-cell;
  vertical-align: middle;
  width: 190px;
}

.main-menu .share {}

.main-menu .fb-like {
  left: 180px;
  position: absolute;
  top: 15px;
}

.main-menu>ul.logout {
  position: absolute;
  left: 0;
  bottom: 0;
}

nav {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

nav ul,
nav li {
  outline: 0;
  margin: 0;
  padding: 0;
  text-transform: uppercase;
}

/* Darker element side menu Start*/
.item {
  background-color: white;
  text-transform: capitalize;
  margin-top: 1em;
}

.group-item {
  margin-top: 0.25em;
  padding-left:1em;
  color: #8b8b8b;
  background-color: white;
  text-transform: capitalize !important;
}
/* Darker element side menu End*/
.main-menu li:hover>a,
nav.main-menu li.active>a,
.dropdown-menu>li>a:hover,
.dropdown-menu>li>a:focus,
.dropdown-menu>.active>a,
.dropdown-menu>.active>a:hover,
.dropdown-menu>.active>a:focus,
.no-touch .dashboard-page nav.dashboard-menu ul li:hover a,
.dashboard-page nav.dashboard-menu ul li.active a {
  color: black;
  text-shadow: 0px 0px 0px;
}

.area {
  float: left;
  background: #e2e2e2;
  width: 100%;
  height: 100%;
}
.nav-text{
  font-family: 'Open Sans', sans-serif !important;
}
.router-link-exact-active{
  color: #ec635f !important;
}
.vue-flow__panel.bottom {
  bottom: 10px !important;
}
</style>
  
  
  