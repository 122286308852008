<!----------------------------------------------->
<!--                  TEMPLATE                 -->
<!----------------------------------------------->
<template>
  <div>
    <Sitebar></Sitebar>
    <div class="maincontainer" :style="'--my-font-var:' + primaryFont + ';   padding-bottom: 4rem;'">
      <div class="row">
        <div class="col-6">
          <h3 class="mb-0">{{$t('organizationInformation')}}</h3>
          <p class="profile-subtitle">{{$t('organizationInformationDescription')}}</p>
          <Card>
            <template #content>
              <div class="row profile-info-card mt-2">
                <h5 class="mb-0">{{$t('basicInformation')}}</h5>
                <p class="profile-subtitle">{{$t('basicInformationDescription')}}</p>
                <div class="row profile-data-row">
                  <div class="col-2 pt-0 profile-data-row-title">{{$t('picture')}}</div>
                  <div class="col-8 pt-0">{{$t('pictureDescription')}}</div>
                  <div class="col-2 pt-0">
                    <Avatar v-if="data.logo == null" :label="data.name[0].toUpperCase()" class="update-profile-img" shape="circle" size="large" @click="$refs.uploadFile.openModal()"/>
                    <img v-else :src="getSourceLogo(data.logo)" class="rounded-circle update-profile-img" height="45" width="45" @click="$refs.uploadFile.openModal()" />
                  </div>
                </div>
                <hr class="mb-0 ml-2" style="width:98%;">
                <div class="row profile-data-row">
                  <div class="col-2 pt-0 profile-data-row-title">{{$t('name')}}</div>
                  <div class="col-8 pt-0" v-if="!loading && !edit_full_name">{{data.name}}</div>
                  <div class="col-8 pt-0" v-if="loading"><ContentLoader height="20px" width="100px"></ContentLoader></div>
                  <div class="col-8 pt-0" v-if="edit_full_name"><InputText v-model="data.name"></InputText></div>
                  <div class="col-2 pt-0" v-if="!edit_full_name"><i class="pi pi-pencil" style="float: right; cursor: pointer;" @click="edit_full_name = !edit_full_name"></i></div>
                  <div class="col-2 pt-0" v-else><i class="pi pi-save" style="float: right; cursor: pointer;" @click="updateName()"></i></div>
                </div>
                <hr class="mb-0 ml-2" style="width:98%;">
                <div class="row profile-data-row">
                  <div class="col-2 pt-0 profile-data-row-title">{{$t('description')}}</div>
                  <div class="col-8 pt-0" v-if="!loading && !edit_desc">{{data.info}}</div>
                  <div class="col-8 pt-0" v-if="loading"><ContentLoader height="20px" width="100px"></ContentLoader></div>
                  <div class="col-8 pt-0" v-if="edit_desc"><Textarea cols="60" rows="10" v-model="data.info"></Textarea></div>
                  <div class="col-2 pt-0" v-if="!edit_desc"><i class="pi pi-pencil" style="float: right; cursor: pointer;" @click="edit_desc = !edit_desc"></i></div>
                  <div class="col-2 pt-0" v-else><i class="pi pi-save" style="float: right; cursor: pointer;" @click="updateDescription()"></i></div>
                </div>
              </div>
            </template>
          </Card>
        </div>
        
      </div>
    </div>
    <Modal ref="uploadFile">
      <template #header>
        &nbsp;
      </template>
      <template #body>
        <div class="col-12"></div>
        <FileUpload name="demo[]" @uploader="onAdvancedUpload($event)" :multiple="false" accept="image/*"
          :max-file-size="1000000" customUpload >
          <template #content>
            <ul v-if="uploadedFiles && uploadedFiles[0]">
              <li v-for="file of uploadedFiles[0]" :key="file">{{ file.name }} - {{ file.size }} bytes</li>
            </ul>
          </template>
          <template #empty>
            <p>Drag and drop files to here to upload.</p>
          </template>
        </FileUpload>
      </template>
    </Modal>
  </div>
</template>

<!----------------------------------------------->
<!--                    SCRIPT                 -->
<!----------------------------------------------->

<script>
import MyShared from "./shared";
export default {
  mixins: [MyShared],
};

</script>

<script setup>
import { useMq } from "vue3-mq";
const mq = useMq();
</script>

<!----------------------------------------------->
<!--                    STYLES                 -->
<!----------------------------------------------->
<style scoped>
</style>
