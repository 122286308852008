import { createI18n } from "vue-i18n";

const i18n = createI18n({
    locale: "en",
    globalInjection: true,
    messages: {
        en: {
            language: "English",
            //General
            deleteSelected: "Delete Selected",
            closeEditMode: "Close Edit Mode",
            editOptions: "Edit Options",
            noDataFound: "No data found",
            loadingData: "Loading data...",
            next: "Next",
            previous: "Previous",
            min: "min",
            max: "max",
            date: "Date",
            change: "Change",
            profile: "Profile",
            password: "Password",
            confirmPassword: "Confirm Password",
            choosePassForNewAccount: "choose a password for your new account!",
            chooseNewPassForAccount: "Choose a new password for your account",
            chooseNewPassForYourAccount: "Choose a new password for your account",
            viewMode: "View Mode",
            tableMode: "Table Mode",
            dashboardMode: "Dashboard Mode",
            noDataAvailable: "No data available",
            undefined: "Undefined",
            team: "Team",
            explore: "Explore",
            goback: "Go Back",
            showMore: "Show More",
            refresh: "Refresh",
            actions: "Actions",
            last_12_hours: "Last 12 Hours",
            last_24_hours: "Last 24 Hours",
            last_48_hours: "Last 48 Hours",
            last_7_days: "Last 7 Days",
            last_1_month: "Last 1 Month",
            all_time: "All Time",
            search: "Search",
            cibersecurity: "Cibersecurity",
            dataBase: "Data Base",
            status: "Status",
            active: "Active",
            inactive: "Inactive",
            yes: "Yes",
            no: "No",
            loading: "Loading...",
            cancel: "Cancel",
            edit: "Edit",
            delete: "Delete",
            detectionRules: "Detection Rules",
            detectionRule: "Detection Rule",
            correlationRules: "Correlation Rules",
            correlationRule: "Correlation Rule",
            id: "ID",
            ID: "ID",
            title: "Title",
            filters: "Filters",
            minimumSeverity: "Minimum Severity",
            alertingMethods: "Alerting Methods",
            suscribedUsers: "Suscribed Users",
            deleteConfirmation: "Delete Confirmation",
            deleteConfirmationAlert: "Are you sure you want to proceed?",
            recordDeleted: "Record deleted",
            rejected: "Rejected",
            rejectedAlert: "You have rejected",
            confirmed: "Confirmed",
            email: "Email",
            rule: "Rule",
            configuration: "Configuration",
            deactivated: "Deactivated",
            activated: "Activated",
            integration: "Integration",
            input_messages: "Input Messages",
            output_messages: "Output Messages",
            active_threads: "Active Threads",
            resume: "Resume",
            slackUsername: "Slack Username",
            country: "Country",
            worldWideIncidents: "Worldwide Incidents",
            yourDashboards: "Your Dashboards",

            // Status
            new: "New",
            inProgress: "In Progress",
            closed: "Closed",
            mitigated: "Mitigated",
            partiallyMitigated: "Partially Mitigated",
            active: "Active",
            // Critically Important
            critical: "Critical",
            high: "High",
            medium: "Medium",
            low: "Low",
            informational: "Informational",
            // Home
            welcomeMsg: "Welcome to AirSIEM!",
            workingOnDashboard: "You are working on:",
            workingOnTeams: "You are currently working for these teams:",
            workingOnCompany: "You are currently working for this company:",
            quickAccess: "Quick Access",
            totalIncidents: "Total Incidents",
            // Sidebar views
            home: "Home",
            dashboard: "Dashboard",
            teams: "Teams",
            integrations: "Integrations",
            listeners: "Listeners",
            logs: "Logs",
            assets: "Assets",
            events: "Events",
            incidents: "Incidents",
            alertConfiguration: "Alert Configuration",
            machineLearning: "Machine Learning",
            correlation: "Correlation",
            detection: "Detection",
            manageUsers: "Manage Users",
            manageTeams: "Manage Teams",
            manageOrganizations: "Manage Organizations",
            manageRoles: "Manage Roles",
            permissions: "Permissions",
            dashboards: "Dashboards",
            manageUsersAndRoles: "Users, teams and roles",
            dashboardCreator: "Dashboard Creator",
            dashboardViewer: "Dashboard Viewer",
            tasks: "Tasks",
            documentation: "Documentation",
            // -- 
            general: "General",
            alerts: "Alerts",
            threatDetection: "Threat Detection",
            management: "Management",
            // Assets view
            name: "Name",
            operatingSystem: "Operating System",
            hostName: "Hostname",
            type: "Type",
            criticality: "Criticality",
            lastActDate: "Last act.",
            createdAt: "Created At",
            updatedAt: "Updated At",
            firstDate: "First Date",
            lastDate: "Last Date",
            details: "Details",
            assetTotal: "Total assets",
            groupsNumber: "Groups number",
            atRiskAssets: "Assets at risk ",
            group: "Group",
            groups: "Groups",
            description: "Description",
            location: "Location",
            assetsByCriticality: "Assets by criticality",
            tags: "Tags",
            network: "Network",
            dates: "Dates",
            searchByAsset: "Search by name, os, host name, type or criticality",
            filterByGroup: "Filter by group",
            organizationId: "Organization ID",
            risk: "At risk",
            integrations: "Integration",
            asset: "Asset",
            newAsset: "New Asset",
            editMode: "Edit Mode",
            createNewAsset: "Create new asset",
            hostname: "Hostname",
            createNewAgent: "Create agent setup",
            selectAnOS: "Select an OS",
            selectTheSources: "Select the sources you want to monitor",
            // Assets details view
            general_info: "General Information",
            details_info: "Asset Details",
            save: "Save",
            severity: "Severity",
            eventsNumber: "Events Number",
            affectedAssets: "Affected Assets",
            // Events view
            eventsTotal: "Total events",
            eventsBySeverity: "Events by severity",
            source: "Source",
            statusHistory: "Status History",
            assetId: "Asset ID",
            eventsInProgress: "Events in progress",
            eventsClosed: "Events closed",

            // Incidents view
            incidentsTotal: "Total incidents",
            incidentsInProgress: "Incidents in progress",
            incidentsClosed: "Incidents closed",
            incidentsBySeverity: "Incidents by severity",
            statusDescription: "Status Description",
            investigate: "Investigate incident",
            node: "Node",
            nodeOptions: "Node options",
            handles: "Handles",
            nodeType: "Node type",
            investigations: "Investigations",
            investigation: "Investigation",
            investigationCreator: "Investigation Creator",
            recentInvestigations: "Recent investigations",
            noRecentInvestigations: "No recent investigations, create one!",
            createNewInvestigation: "Create a new investigation",
            newNodeOptions: "New node options",
            addElementToCanvas: "Add element to canvas",
            chooseObject: "Choose element",
            machineLearningJobs: "Machine learning jobs",
            outputIndex: "Output index",
            modelClass: "Model class",
            modelPath: "Model path",
            ipAddress: "IP address",
            macAddress: "MAC address",

            // User management view
            addPeople: "Add People",
            addTeam: "Add Team",
            role: "Role",
            findPlaceHolder: "Find organization member or user...",
            findTeamPlaceHolder: "Find team by name...",
            remove: "Remove",
            roles: "Roles",
            add: "Add",
            addPeopleToAirSIEM: "Add people to AirSIEM",
            searchUsers: "Search users by username, email or full name",
            addUser: "Add User",
            chooseRole: "Choose a role",
            updateRolesToUser: "Update roles to user",
            addToTeam: "Add user to a team",
            newUser: "New User",
            addPeopleToTeam: "Add people to team",
            users: "Users",
            newTeam: "New Team",
            manageUserRoles: "Manage user roles",
            managePermissions: "Manage permissions",
            temsForUser: "Teams for user",
            usersForTeam: "Users in team",
            usersForRole: "Users in role",
            permissionsForRole: "Permissions in role",
            createNewTeam: "Create a new team",
            addRoleToUser: "Add role to user",
            newRole: "New role",
            chooseTeam: "Choose a team",
            userEmail: "User email",
            sendEmailCaption: "An email will be sent to the user with the invitation to join the AirSIEM platform",
            addTeamsToThisUser: "Add teams to this user",
            addUsersToThisTeam: "Add users to this team",
            addOrUpdateRolesFor: "Add or update roles for",
            addOrUpdateTeamsFor: "Add or update teams for",
            chooseTeams: "Choose teams",
            chooseRoles: "Choose roles",
            newRoleName: "Role name",
            newRoleDescription: "Role description",
            selectPermissions: "Select permissions for new role",
            addOrRemoveUsers: "Add or remove users",
            chooseUsers: "Choose users",
            userFullName: "User full name",
            teamDescription: "Team description",
            teamName: "Team name",
            teamUsers: "Team users",
            roleSuccessfullyCreated: "Role successfully created",
            teamSuccessfullyCreated: "Team successfully created",
            // Crud
            create: "Create",
            update: "Update",
            delete: "Delete",
            get: "Get",
            category: "Category",
            search: "Search...",
            // Messages
            success: "Success",
            error: "Error",
            warning: "Warning",
            info: "Info",
            userSuccessfullyCreated: "User successfully created",
            // Profile
            personalInformation: "Personal information",
            personalInformationDescription: "Personal information and options for managing it",
            organizationInformation: "Organization information",
            organizationInformationDescription: "Information about your organization",
            basicInformation: "Basic information",
            basicInformationDescription: "It is possible that other users may be able to see some of the information when using AirSIEM services.",
            picture: "Picture",
            pictureDescription: "A photo helps to personalize your account",
            name: "Name",
            birthdate: "Birthdate",
            gender: "Gender",
            contactInformation: "Contact information",
            contactInformationDescription: "This information will only be used to contact you if necessary. It will not be made public to other users.",
            email: "Email",
            phone: "Phone",
            password: "Password",
            oldPassword: "Old password",
            passwordDescription: "Choosing a strong password helps protect your AirSIEM account.",
            change: "Change",
            teams: "Teams",
            teamsDescription: "Information about your equipment in AirSIEM services",
            teamsSubDescription: "Direct access to the teams you belong to.",
            prefereces: "Preferences",
            preferecesDescription: "Information about your preferences in AirSIEM's services",
            lenguage: "Language",
            lenguageDescription: "Choose the language you want to use in AirSIEM services.",
            rolesDescription: "Information about your roles in AirSIEM's services",
            mainDescription: "Some information about your account in AirSIEM's services",
            sendMail: "Send mail",
            // Dashboard mode
            IncidentsStatusByCreationTime: "Incidents status by creation time",
            IncidentsStatus: "Incidents status",
            EventStatusByCreationTime: "Event status by creation time",
            EventStatus: "Event status",
            meanTimeToAcknowledge: "Mean time to acknowledge",
            meanTimeToClose: "Mean time to close",
            topCorrelationRules: "Top correlation rules",
            topDetectionRules: "Top detection rules",
            topSources: "Top data sources",
            days: "days",
            // Dashboard View
            dashboardName: "Dashboard name",
            dashboardDescription: "Dashboard description",
            chartLibrary: "Chart library",
            chartLibraryDesc: "Click to create a new chart. Select a chart on the canvas to siplay the properties panel.",
            line: "Line",
            bar: "Bar",
            pie: "Pie",
            library: "Library",
            text: "Text",
            number: "Number",
            lineDesc: "Best use for time series data",
            barDesc: "Show parts of a whole",
            pieDesc: "Best use for comparing data",
            libraryDesc: "Selects chart from the existing chart library",
            textDesc: "Show text with some data",
            numberDesc: "Show one important value",
            preview: "Preview",
            addToDashboard: "Add to dashboard",
            updateChart: "Update chart",
            requiredValue: "*Some required values are empty",
            dashboardSaved: "Dashboard saved",
            recentDashboards: "Recent dashboards",
            createNewDashboard: "Create a new dashboard",
            filterBy: "Filter by",
            incidentsByState: "Incidents by state",
            mostRecentIncidetns: "Most recent incidents",
            mostRecentEvents: "Most recent events",
            palette: "Palette",
            dataSources: "Data sources",
            newVisualization: "New visualization",
            existingVisualization: "Existing visualization",
            selectVisualization: "Select visualization",
            chartAlreadyInLayout: "This chart is already in the layout",
            chartAlreadyInLayoutDetails: "Please select another chart or remove the existing one from the layout",
            visualizationNotCreated: "Visualization not created",
            addedFilters: "Added filters",

            // Integration
            integrations: "Integration",
            integrationName: "Integration name",
            integrationDescription: "Information about your integration",
            createNewIntegration: "Create a new integration",
            integrationLogo: "Integration logo",
            noConfigurationWarningForIntegrations: "There is no ingest method configured. Please configure at least one ingest method to be able to use this integration.",
            continueWithIntakeConfiguration: "Continue with ingest configuration",
            selectIntakeMethods: "Select ingest methods",
            createNewIntakeMethod: "Create a new ingest method",
            updateIntegration: "Update integration",
            configurationSchema: "Configuration schema",
            configurationSchemaName: "Configuration schema name",
            configurationParams: "Configuration params",
            configurationSchemaParams: "Schema's parameters configuration",
            nifiListener: "NiFi listener",
            intakeName: "Ingest method name",
            intakeDescription: "Ingest method description",
            intakeMethods: "Ingest methods",
            listeners: "Listeners",
            default: "Default",
            configureListener: "Configure listener",
            listenerConfiguration: "Listener configuration",
            information: "Information",
            tasks: "Tasks",
            state: "State",
            port: "Port",
            maxSizeOfMessageQueue: "Max size of message queue",
            maxNumberOfTCPConnections: "Max nº of TCP connections",
            continueLaterConfiguration: "You can continue the configuration later",
            noSelectedIntakeMethods: "No selected ingest methods",
            configureOne: "Configure one",
            activateListener: "This listener is not activated. Activate to receive data.",
            listnerWorking: "This listener is working and receiving data.",
            listenerName: "Listener name",
            listenerDescription: "Listener description",
            playConfirmation: "Are you sure you want to proceed? This will start the ingest method and it will start receiving data.",
            pauseConfirmation: "Are you sure you want to proceed? This will pause the ingest method and it will stop receiving data.",
            confirmation: "Confirmation",
            integration: "Integration",
            preprocessor: "Preprocessor",
            geo_ip: "GeoIP",
            threat_intel: "Threat Inteligence",
            output_index: "Output index",
            deep_siem_ia: "AirSIEM AI",
            generalConfigurationParams: "General configuration parameters",
            noParams:"There are no configurated parameters. Add at least one parameter to continue.",
            intakeMethodInfoTitle: "Ingest method information",
            intakeMethodInfoDescription: "An ingest method is a method that is used to ingest data from actives and send it into the system. Ingest methods are used to ingest data from actives. Ingest methods are created by the user and are used by the system to ingest data from actives.",
            mustBeUnique: "Must be unique",
            ingestMethodParams: "Ingest method parameters",
            ingestSchema: "Ingest schema",
            ingestSchemasParams: "Ingest schemas parameters",
            ingestFormat: "Ingest format",
            intakeSchemaInfoTitle: "Ingest schema information",
            intakeSchemaInfoDescription: "An ingest schema is a schema that is used to ingest data from actives. Ingest schemas are used to ingest data from actives. Ingest schemas are created by the user and are used by the system to ingest data from actives.",

            // Types
            types: "Types",
            integer: "Integer",
            string: "String",
            boolean: "Boolean",
            float: "Float",

            // Intake configuration
            IntegrationConfiguration: "Integration Configuration",
            intakeMethod: "Ingest method",
            intakeSchema: "Ingest schema",
            intakeSchemas: "Ingest schemas",
            selectIntakeMethod: "Select ingest method",
            selectIntakeSchema: "Select ingest schema",
            listenerInfo: "Listener information",
            nameAndDescription: "Name and description",
            configureListener: "Configure listener",
            configureListener: "Configure listener",
            createListener: "Create listener",
            createListener: "Create listener",
            configureIntakeMethod: "Configure ingest method",
            configureIntakeSchema: "Configure ingest schema",
            atLeastOneParamNeeded: "At least one parameter is needed",
            needNewIntakeSchema: "You need to create a new ingest schema",
            needNewIntakeMethod: "You need to create a new ingest method",
            newIntakeSchema: "New ingest schema",
            src: "Source",
            destination: "Destination",
            src_type: "Source type",
            dst_type: "Destination type",
            required: "Required",
            format: "Format",
            newIntakeMethod: "New intake method",
            intakeSchemaName: "Ingest schema name",
            intakeSchemaDescription: "Ingest schema description",

            // Alert Configurations
            alertConfigurations: "Alert configurations",
            alertConfigurationTotal: "Total alert configurations",
            activeConfigurations: "Active configurations",
            avaliableAlertMethods: "Avaliable alert methods",
            alertMethods: "Alert methods",
            isActive: "Is active?",
            newAlertConfiguration: "New alert configuration",
            createAlertConfiguration: "Create alert configuration",
            alertName: "Alert name",
            alertDescription: "Alert description",
            sources: "Sources",
            newEvent: "New event",
            newIncident: "New incident",
            newCorrelationRule: "New correlation rule",
            newDetectionRule: "New detection rule",
            createNewEvent: "Create new event",
            createNewIncident: "Create new incident",
            createNewCorrelationRule: "Create new correlation rule",
            createNewDetectionRule: "Create new detection rule",
            author: "Author",
            detectionRulesTotal: "Total detection rules",
            correlationRulesTotal: "Total correlation rules",
            configTasks: "Task configuration",
            sesions: "Sesions",
            logout: "Logout",
            unasigned: "Unasigned",

            // Toast messages
            alertUpdated: "Alert updated",
            alertNotUpdated: "Alert not updated",
            alertCreated: "Alert created",
            alertDeleted: "Alert deleted",
            alertConfigurationUpdated: "Alert configuration updated",
            alertConfigurationNotUpdated: "Alert configuration not updated",
            alertConfigurationCreated: "Alert configuration created",
            alertConfigurationDeleted: "Alert configuration deleted",
            assetUpdated: "Asset updated",
            assetNotUpdated: "Asset not updated",
            assetCreated: "Asset created",
            assetDeleted: "Asset deleted",
            eventUpdated: "Event updated",
            eventNotUpdated: "Event not updated",
            eventCreated: "Event created",
            eventDeleted: "Event deleted",
            incidentUpdated: "Incident updated",
            incidentNotUpdated: "Incident not updated",
            incidentCreated: "Incident created",
            incidentDeleted: "Incident deleted",
            correlationRuleUpdated: "Correlation rule updated",
            correlationRuleNotUpdated: "Correlation rule not updated",
            correlationRuleCreated: "Correlation rule created",
            correlationRuleDeleted: "Correlation rule deleted",
            detectionRuleUpdated: "Detection rule updated",
            detectionRuleNotUpdated: "Detection rule not updated",
            detectionRuleCreated: "Detection rule created",
            detectionRuleDeleted: "Detection rule deleted",
            integrationUpdated: "Integration updated",
            integrationNotUpdated: "Integration not updated",
            integrationCreated: "Integration created",
            integrationDeleted: "Integration deleted",
            listenerUpdated: "Listener updated",
            listenerNotUpdated: "Listener not updated",
            listenerCreated: "Listener created",
            listenerDeleted: "Listener deleted",
            userUpdated: "User updated",
            userNotUpdated: "User not updated",
            userCreated: "User created",
            userDeleted: "User deleted",
            teamUpdated: "Team updated",
            teamNotUpdated: "Team not updated",
            teamCreated: "Team created",
            teamDeleted: "Team deleted",
            roleUpdated: "Role updated",
            roleNotUpdated: "Role not updated",
            roleCreated: "Role created",
            roleDeleted: "Role deleted",
            enterTheEmailOfYourAccount: "Enter the email of your account",
            weWillSendYouAnEmailWithInstructions: "We will send you an email with instructions",
            visualizationUpdated: "Visualization updated",
            visualizationNotUpdated: "Visualization not updated",
            visualizationCreated: "Visualization created",
            visualizationDeleted: "Visualization deleted",
            paletteCreated: "Palette created",
            paletteNotCreated: "Palette not created",
            paletteUpdated: "Palette updated",
            paletteNotUpdated: "Palette not updated",
            paletteDeleted: "Palette deleted",
            paletteNotDeleted: "Palette not deleted",
            dashboardCreated: "Dashboard created",
            dashboardInfoTitle: "Dashboard Information",
            dashboardInfoDescription: "A dashboard is a set of charts that are used to represent data. Dashboards are used to represent data. Dashboards are created by the user and are used by the system to represent data.",
            visualizationInfoTitle: "Visualization Information",
            visualizationInfoDescription: "A visualization is a chart that is used to represent data. Visualizations are used to represent data. Visualizations are created by the user and are used by the system to represent data.",

            dashboardXAxisInfo: "X axis means the horizontal axis of the chart.",
            dashboardYAxisInfo: "Y axis means the vertical axis of the chart.",
            dashboardMetricInfo: "Metric is the value that will be represented in the chart. \n\n Counter: A counter is a cumulative metric that represents a single monotonically increasing counter whose value can only increase or be reset to zero on restart. \n \n Summarie: Similar to a histogram, a summary samples observations (usually things like request durations and response sizes). While it also provides a total count of observations and a sum of all observed values, it calculates configurable quantiles over a sliding time window. \n \n Average: a number expressing the central or typical value in a set of data",
            dashboardXAxis4Info: "Helper... Ask what to put here",
            dashboardModelInfo: "Model is the type of data that will be used to represent.",
            dashboardSlicesInfo: "Slices are the different values that will be represented in the chart.",

            // Listeners
            listenersTotal: "Total listeners",
            activeListeners: "Active listeners",
            unactiveListeners: "Inactive listeners",
            outputIndex: "Output index",
            newListener: "New listener",

            // Dashboard
            noRecentDashboards: "No recent dashboards, create one!",
            notSaved: "Not saved",

            // Surveillance
            UEBA: "UEBA",
            surveillance: "Surveillance",
            onAssets: "Power on assets",
            explore: "Explore",
            close: "Close",
            OS: "OS",
            checkpoints: "Checkpoints",
            setCheckpoint: "Set checkpoint",
            checkpointName: "Checkpoint name",
            time: "Time",
            startDate: "Start date",
            endDate: "End date",
            newCheckpoint: "New checkpoint",
            changeThis: "Filter by date",
            recordingNotLoaded: "Recording not loaded",
            NotVideoForThisDates: "There is no video for this dates",

            // Create new visualization
            model: "Data model",
            metric: "Metric",
            select: "Seleccionar",
            slices: "Slices",
            field: "Field",
            interval: "Interval",
            maxNumbOfSlices: "Max. number of slices",
            min: "Min",
            orderby: "Order by",
            x_axis: "X axis",
            y_axis: "Y axis",
            qwery: "Query",
            restoOfDataLabel: "Rest of data label",
            label: "Label",
            options: "Options",
            Data: "Data",
            visualizationExample: "*This is just an example of what the visualization will look like.",
            chooseLabel: "Choose a label",
            generalOptions: "General options",
            dataModel: "Data model",
            metricOptions: "Metric options",
            xAxisOptions: "X axis options",
            slicesOptions: "Slices options",

            customizeNameDescAndPallete: "Customize name, description and palette",
            youHaveSelected: "You have selected",
            asDataModel: "as data model",
            asMetric: "as metric",
            yourMetricWill: "Your metric will ",
            asField: "as field",
            asXAxisFormat: "as X axis format",
            yourXaxisWillDraw: "Your X axis will draw ",
            in: "in",
            order: "order",
            withAMaxOf: "with a max of",
            slices: "Slices",
            inThisInterval: "in this interval:",
            asSlicesFormat: "as slices format",
            yourVisualizationWillBeSeparatedInMax: "Visualization will be separated in a max of",
            using: "using",
            thereWillNotBeSlices: "There will not be slices",

            // Logs
            selectedFields: "Selected fields",
            avaliableFields: "Avaliable fields",
            logs: "Logs",
            noneFieldAvaliable: "None field avaliable",
            noneFieldSelected: "None field selected",
            datesRange: "Dates range",
            logsByDate: "Logs by date",
            selectModel: "Select model",
            selectRangeOfDates: "Select range of dates",
            addAFilter: "Add a filter (optional)",
            selectChartInterval: "Select chart interval",

            // Info cards   
            assetInfoTitle: "Asset Information",
            assetInfoDescription: "An asset is a device that is connected to the network and is monitored by the system. Assets can be servers, workstations, routers, switches, firewalls, etc. Assets are the basis of the system, since they are the ones that generate the events that are analyzed by the system.",
            eventInfoTitle: "Event Information",
            eventInfoDescription: "An event is a change in the state of an asset. For example, a change in the status of a service, a change in the status of a port, a change in the status of a process, etc. Events are generated by the system and are analyzed by the system to detect incidents.",
            incidentInfoTitle: "Incident Information",
            incidentInfoDescription: "An incident is an event that has been analyzed by the system and has been classified as an incident. Incidents are generated by the system and are analyzed by the system to detect incidents.",
            correlationRuleInfoTitle: "Correlation rule Information",
            correlationRuleInfoDescription: "A correlation rule is a rule that is used to correlate events. Correlation rules are used to detect incidents. Correlation rules are created by the user and are used by the system to detect incidents.",
            detectionRuleInfoTitle: "Detection rule Information",
            detectionRuleInfoDescription: "A detection rule is a rule that is used to detect incidents. Detection rules are used to detect incidents. Detection rules are created by the user and are used by the system to detect incidents.",
            alertConfigurationInfoTitle: "Alert configuration Information",
            alertConfigurationInfoDescription: "An alert configuration is a configuration that is used to send alerts. Alert configurations are used to send alerts. Alert configurations are created by the user and are used by the system to send alerts.",


            activatedDetections: "Activated detections",
            activatedCorrelations: "Activated correlations",
            deactivatedDetections: "Deactivated detections",
            deactivatedCorrelations: "Deactivated correlations",

            noRole: "No role yet",
            methods: "Methods",
            confirm: "Confirm",
            deleteAll: "Delete all",
            deleteAllDescription: "All database elements will be deleted",
            writeThisToConfirm: "Type this to confirm that you want to delete the data:",
            noMatch: "The sentence does not match",
            searchAmongTheOptions: "Search among the options",

            optionsDescription: "Organization-specific options.",
            import: "Import",
            or: "or",
            importedSuccesfully: "Imported succesfully",
            deletedSuccesfully: "deleted succesfully",
            notDeletedSuccesfully: "not deleted succesfully",
            correlationsBySeverity: "Correlations by minimum severity",
            detectionsBySeverity: "Detections by minimum severity",
            allParamsRequired: "All params are required",

            // Individual dashboards
            atRisk: "At risk",
            notAtRisk: "Not at risk",
            assetGroups: "Asset groups",
            assetLocation: "Asset location",
            OSCounter: "OS counter",
            others: "Others",
            assetsWhitMoreIncidents: "Most affected assets (with more incidents)",
            pleaseEnterValue: "Please enter a value",
            topAssetsEvents: "Top assets (with more events)",
            topAssetsIncidents: "Top assets (with more incidents)",
            eventsInTime: "Events in time",
            incidentsInTime: "Incidents in time",

            // Helpers
            incidentByStateHelper: "This chart shows the number of incidents of each state.",
            ingestMethodHelper: "The ingest method is the method used to ingest the data from actives and send it into the system.",
            listenerHelper: "A listener is a process that is in charge of receiving data from an active and sending it to the system.",
            elementByCriticalityHelper: "This chart shows the number of elements of each criticality.",
            elementBySeverityHelper: "This chart shows the number of elements of each severity.",
            elementByMinimumSeverityHelper: "This chart shows the number of elements of each minimum severity.",
            importFileHelper: "The file must be a properly formatted .json file. You can check the format in the documentation.",


            // General
            changeSelectedCriticality: "Change criticality of selected items",
            changeSelectedStatus: "Change status of selected items",
            confirmDialog: "Are you sure you want to proceed?",
            schedulde: "Schedule",

            // Tasks
            expires: "Expires",
            startsAfter: "Starts after",
            soft_time_limit: "Soft time limit",
            total_run_count: "Total run count",
            max_run_count: "Max run count",
            object_id: "Object ID",
            object_type: "Object type",
            every: "Every",
            period: "Period",

            investigationName: "Investigation name",
            investigationDescription: "Investigation description",

            deeplql_helper: "For more information about DeepLQL, please visit the documentation.",

            // Documentation
            introduction: "Introduction",
            gettingStarted: "Getting started",
            deepqlGettingStarted: "DeepQL is a query language created for AirSIEM. With DeepQL, users can perform advanced queries on data stored in the system. DeepQL is a query language based on SQL, but with some differences. In this section, a guide to get started with DeepQL is provided.",
            whereToFindDeepqlTitle: "Where to find DeepQL?",
            whereToFindDeepql: "DeepQL can be found in the custom dashboards section. Within the configurator of a visualization, in the X-axis options, we can select 'Filters', which will make a DeepQL terminal available to us.",
            howToUseDeepqlTitle: "How to use DeepQL?",
            howToUseDeepql: "First we must choose the name that will identify the set of data referring to the query that we will later introduce in the terminal. We will do this through the input 'Label'. Next, we must enter the query in DeepQL. Once the query is entered, we must press 'Enter' and the terminal will return us if the query is correct and has been added correctly. If not, it will return an error message.",
            howToUseDeepql2: "Using the 'Tab' button, the terminal will provide us with a list of possible options that we can enter in the query based on the data model selected for that visualization. This will facilitate the task of entering the query.",
            howToUseDeepql3: "In the lower section of the terminal, we can find the list of the queries we have added. If we want to delete a query, we can do it by pressing the 'x' button. ",
            howToUseDeepql4: "DeepQL will take care of calculating the remaining portion of the queries we have entered. To do this, we must enter the name that will identify the remaining portion in the input 'Rest of data label'.",
            usingDeepQL: "Using DeepQL",
            usingDeepQL1: "This section shows a list of the operators and functions that can be used in DeepQL.",
            examples: "Examples",
            examples1: "This section shows some examples of how to use DeepQL.",

        },
        /* ================================================================================================================================================================== */
        /* ================================================================================================================================================================== */
        /* ================================================================================================================================================================== */
        es: {
            language: "Español",
            //General
            deleteSelected: "Eliminar seleccionados",
            closeEditMode: "Cerrar modo edición",
            editOptions: "Opciones de edición",
            noDataFound: "No se encontraron datos",
            loadingData: "Cargando datos...",
            next: "Siguiente",
            previous: "Anterior",
            min: "min",
            max: "max",
            date: "Fecha",
            change: "Cambiar",
            profile: "Perfil",
            password: "Contraseña",
            confirmPassword: "Confirmar contraseña",
            choosePassForNewAccount: "elija una contraseña para su nueva cuenta!",
            chooseNewPassForAccount: "Elija una nueva contraseña para su cuenta!",
            chooseNewPassForYourAccount: "Elija una nueva contraseña para su cuenta",
            viewMode: "Modo de vista",
            tableMode: "Modo tabla",
            dashboardMode: "Modo dashboard",
            noDataAvailable: "No hay datos disponibles",
            undefined: "Indefinido",
            team: "Equipo",
            explore: "Ver",
            goback: "Volver",
            showMore: "Ver Más",
            refresh: "Actualizar",
            actions: "Acciones",
            last_12_hours: "Últimas 12 horas",
            last_24_hours: "Últimas 24 horas",
            last_48_hours: "Últimas 48 horas",
            last_7_days: "Últimos 7 días",
            last_1_month: "Último 1 mes",
            all_time: "Todo el tiempo",
            search: "Buscar",
            cibersecurity: "Ciberseguridad",
            dataBase: "Base de Datos",
            status: "Estado",
            active: "Activo",
            inactive: "Inactivo",
            yes: "Sí",
            no: "No",
            loading: "Cargando...",
            cancel: "Cancelar",
            edit: "Editar",
            delete: "Eliminar",
            detectionRules: "Reglas de detección",
            detectionRule: "Regla de detección",
            correlationRules: "Reglas de correlación",
            correlationRule: "Regla de correlación",
            id: "ID",
            ID: "ID",
            title: "Título",
            filters: "Filtros",
            minimumSeverity: "Severidad mínima",
            alertingMethods: "Métodos de alerta",
            suscribedUsers: "Usuarios suscritos",
            deleteConfirmation: "Confirmacion de eliminación",
            deleteConfirmationAlert: "¿Está seguro que desea eliminar este registro?",
            recordDeleted: "Registro eliminado",
            rejected: "Ha rechazado",
            rejectedAlert: "Ha rechazado la petición",
            confirmed: "Ha confirmado",
            email: "Email",
            rule: "Regla",
            configuration: "Configuración",
            deactivated: "Desactivado",
            activated: "Activado",
            integration: "Integración",
            input_messages: "Mensajes de entrada",
            output_messages: "Mensajes de salida",
            active_threads: "Hilos activos",
            resume: "Resumen",
            slackUsername: "Nombre de usuario de Slack",
            country: "País",
            worldWideIncidents: "Incidentes en el mundo",
            yourDashboards: "Tus dashboards",

            // Status
            new: "Nuevo",
            inProgress: "En progreso",
            closed: "Cerrado",
            mitigated: "Mitigado",
            partiallyMitigated: "Parcialmente mitigado",
            active: "Activo",
            // Critically Important
            critical: "Crítico",
            high: "Alto",
            medium: "Medio",
            low: "Bajo",
            informational: "Informativo",
            // Home
            welcomeMsg: "Bienvenido a AirSIEM!",
            workingOnDashboard: "Estas trabajando en:",
            workingOnTeams: "Ahora mismo estas trabajando para estos equipos:",
            workingOnCompany: "Ahora mismo estas trabajando para esta compañía:",
            quickAccess: "Accesos rápidos",
            totalIncidents: "Incidentes totales",
            // Sidebar views
            home: "Inicio",
            dashboard: "Dashboard",
            teams: "Equipos",
            integrations: "Integraciones",
            listeners: "Listeners",
            logs: "Logs",
            assets: "Activos",
            events: "Eventos",
            incidents: "Incidentes",
            alertConfiguration: "Configuración de Alertas",
            machineLearning: "Machine Learning",
            correlation: "Correlación",
            detection: "Detección",
            manageUsers: "Gestionar Usuarios",
            manageTeams: "Gestionar Equipos",
            manageOrganizations: "Gestionar Organizaciones",
            manageRoles: "Gestionar Roles",
            permissions: "Permisos",
            dashboards: "Dashboards",
            manageUsersAndRoles: "Usuarios, equipos y roles",
            dashboardCreator: "Creador de dashboard",
            dashboardViewer: "Visor de dashboard",
            tasks: "Tareas",
            documentation: "Documentación",
            // -- 
            general: "General",
            alerts: "Alertas",
            threatDetection: "Detección de Amenazas",
            management: "Gestión",
            // Assets view
            name: "Nombre",
            operatingSystem: "Sistema Operativo",
            hostName: "Nombre del Host",
            type: "Tipo",
            criticality: "Criticidad",
            lastActDate: "Fecha de la última actividad",
            createdAt: "Fecha",
            updatedAt: "Actualizado el",
            firstDate: "Fecha de inicio",
            lastDate: "Fecha de fin",
            details: "Detalles",
            assetTotal: "Total de Activos",
            groupsNumber: "Número de grupos",
            atRiskAssets: "Activos en riesgo",
            group: "Grupo",
            groups: "Grupos",
            description: "Descripción",
            location: "Localización",
            assetsByCriticality: "Activos por criticidad",
            tags: "Etiquetas",
            network: "Red",
            dates: "Fechas",
            searchByAsset: "Buscar por nombre, os, host name, tipo o criticidad",
            filterByGroup: "Filtrar por grupo",
            organizationId: "ID de la organización",
            risk: "En riesgo",
            integrations: "Fuentes de datos",
            asset: "Asset",
            newAsset: "Nuevo Asset",
            editMode: "Modo edición",
            createNewAsset: "Crear nuevo asset",
            hostname: "Hostname",
            createNewAgent: "Descargar instaldor",
            selectAnOS: "Selecciona un sistema operativo",
            selectTheSources: "Selecciona las fuentes de datos que desea monitorizar",
            // Assets details view
            general_info: "Información general",
            details_info: "Detalles del asset",
            save: "Guardar",
            severity: "Severidad",
            eventsNumber: "Número de eventos",
            affectedAssets: "Activos afectados",
            // Events view
            eventsTotal: "Total de eventos",
            eventsBySeverity: "Eventos por severidad",
            source: "Fuente",
            statusHistory: "Historial de estados",
            assetId: "ID del asset",
            eventsInProgress: "Eventos en progreso",
            eventsClosed: "Eventos cerrados",
            // Incidents view
            incidentsTotal: "Total de incidentes",
            incidentsInProgress: "Incidentes en progreso",
            incidentsClosed: "Incidentes cerrados",
            incidentsBySeverity: "Incidentes por severidad",
            statusDescription: "Descripción del estado",
            investigate: "Investigar incidente",
            node: "Nodo",
            nodeOptions: "Opciones del nodo",
            handles: "Anclajes",
            nodeType: "Tipo de nodo",
            investigations: "Investigaciones",
            investigation: "Investigación",
            investigationCreator: "Creador de la investigación",
            recentInvestigations: "Investigaciones recientes",
            noRecentInvestigations: "No hay investigaciones recientes",
            createNewInvestigation: "Crear nueva investigación",
            newNodeOptions: "Configuración del nuevo nodo",
            addElementToCanvas: "Añadir elemento al panel",
            chooseObject: "Elige un elemento",
            machineLearningJobs: "Trabajos de Machine Learning",
            outputIndex: "Índice de salida",
            modelClass: "Clase de modelo",
            modelPath: "Ruta del modelo",
            ipAddress: "Dirección IP",
            macAddress: "Dirección MAC",

            // User management view
            addPeople: "Añadir personas",
            addTeam: "Añadir equipo",
            role: "Rol",
            findPlaceHolder: "Buscar un miembro de la organización o usuario...",
            findTeamPlaceHolder: "Buscar un equipo por nombre...",
            remove: "Eliminar",
            roles: "Roles",
            add: "Añadir",
            addPeopleToAirSIEM: "Añadir personas a AirSIEM",
            searchUsers: "Buscar usuarios por nombre de usuario, email o nombre completo",
            addUser: "Añadir Usuario",
            chooseRole: "Elige un rol",
            updateRolesToUser: "Actualizar roles al usuario",
            addToTeam: "Añadir usuario a un equipo",
            newUser: "Nuevo Usuario",
            addPeopleToTeam: "Añadir personas al equipo",
            users: "Usuarios",
            newTeam: "Nuevo Equipo",
            manageUserRoles: "Gestionar roles de usuario",
            managePermissions: "Gestionar permisos",
            temsForUser: "Equipos del usuario",
            usersForTeam: "Usuarios en el equipo",
            usersForRole: "Usuarios en el rol",
            permissionsForRole: "Permisos del rol",
            createNewTeam: "Crear nuevo equipo",
            addRoleToUser: "Añadir rol al usuario",
            newRole: "Nuevo rol",
            chooseTeam: "Elige un equipo",
            userEmail: "Email del usuario",
            sendEmailCaption: "Se enviará un correo electrónico al usuario con la invitación para unirse a la plataforma AirSIEM.",
            addTeamsToThisUser: "Añadir equipos a este usuario",
            addUsersToThisTeam: "Añadir usuarios a este equipo",
            addOrUpdateRolesFor: "Añadir o actualizar roles de",
            addOrUpdateTeamsFor: "Añadir o actualizar equipos de",
            chooseTeams: "Elige equipos",
            chooseRoles: "Elige roles",
            newRoleName: "Nombre del rol",
            newRoleDescription: "Descripción del rol",
            selectPermissions: "Selecciona los permisos del nuevo rol",
            addOrRemoveUsers: "Añadir o eliminar usuarios",
            chooseUsers: "Elige usuarios",
            userFullName: "Nombre completo del usuario",
            teamDescription: "Descripción del equipo",
            teamName: "Nombre del equipo",
            teamUsers: "Usuarios del equipo",
            roleSuccessfullyCreated: "Rol creado correctamente",
            teamSuccessfullyCreated: "Equipo creado correctamente",
            // Crud
            create: "Create",
            update: "Update",
            delete: "Delete",
            get: "Get",
            category: "Categoria",
            search: "Buscar...",
            // Messages
            success: "Éxito",
            error: "Error",
            warning: "Advertencia",
            info: "Información",
            userSuccessfullyCreated: "Usuario creado correctamente",

            // Profile
            personalInformation: "Información personal",
            personalInformationDescription: "Información personal y opciones para gestionarla",
            organizationInformation: "Información de la organización",
            organizationInformationDescription: "Información de la organización y opciones para gestionarla",
            basicInformation: "Información básica",
            basicInformationDescription: "Es posible que otros usuarios puedan ver parte de la información al usar los servicios de AirSIEM.",
            picture: "Foto",
            pictureDescription: "Una foto ayuda a personalizar tu cuenta",
            name: "Nombre",
            birthdate: "Fecha de nacimiento",
            gender: "Género",
            contactInformation: "Información de contacto",
            contactInformationDescription: "Está información solo se usara para contactar contigo si fuera necesario. No se hará publica a otros usuarios.",
            email: "Email",
            phone: "Teléfono",
            password: "Contraseña",
            oldPassword: "Contraseña antigua",
            passwordDescription: "Si eliges una contraseña segura, ayudas a proteger tu cuenta de AirSIEM",
            change: "Cambiar",
            teams: "Equipos",
            teamsDescription: "Informacion sobre tus equipos en los servicios de AirSIEM",
            teamsSubDescription: "Acceso directo a los equipos a los que perteneces.",
            prefereces: "Preferencias",
            preferecesDescription: "Información sobre tus preferencias en los servicios de AirSIEM",
            lenguage: "Idioma",
            lenguageDescription: "Elige el idioma que quieres usar en los servicios de AirSIEM.",
            rolesDescription: "Información sobre tus roles en los servicios de AirSIEM",
            mainDescription: "Información sobre tu cuenta en los servicios de AirSIEM",
            sendMail: "Enviar correo",

            // Dashboard mode
            IncidentsStatusByCreationTime: "Estado de los incidentes por fecha de creación",
            IncidentsStatus: "Estado de los incidentes",
            EventStatusByCreationTime: "Estado de los eventos por fecha de creación",
            EventStatus: "Estado de los eventos",
            meanTimeToAcknowledge: "Tiempo medio de reconocimiento",
            meanTimeToClose: "Tiempo medio de cierre",
            topCorrelationRules: "Top reglas de correlación",
            topDetectionRules: "Top reglas de detección",
            topSources: "Top fuentes de datos",
            days: "días",
            // Dashboard View
            dashboardName: "Nombre del dashboard",
            dashboardDescription: "Descripción del dashboard",
            chartLibrary: "Librería de gráficos",
            chartLibraryDesc: "Haga clic para crear un nuevo gráfico. Seleccione un gráfico en el lienzo para desplegar el panel de propiedades.",
            line: "Líneas",
            bar: "Barras",
            pie: "Tarta",
            library: "Biblioteca",
            text: "Texto",
            number: "Número",
            lineDesc: "Muestra tendencias a lo largo del tiempo",
            barDesc: "Muestra comparaciones entre categorías",
            pieDesc: "Muestra proporciones de una categoría",
            libraryDesc: "Selecciona grafico de la biblioteca de graficos existentes",
            textDesc: "Mostrar texto con algunos datos",
            numberDesc: "Mostrar un valor importante",
            preview: "Vista previa",
            addToDashboard: "Añadir al dashboard",
            updateChart: "Actualizar gráfico",
            requiredValue: "*Algunos campos obligatorios estan incompletos",
            dashboardSaved: "Dashboard guardado correctamente",
            recentDashboards: "Dashboards recientes",
            createNewDashboard: "Crear nuevo dashboard",
            filterBy: "Filtrando por",
            incidentsByState: "Incidentes por estado",
            mostRecentIncidetns: "Incidentes más recientes",
            mostRecentEvents: "Eventos más recientes",
            palette: "Paleta",
            dataSources: "Fuentes de datos",
            newVisualization: "Nueva visualización",
            existingVisualization: "Visualización existente",
            selectVisualization: "Selecciona una visualización",
            chartAlreadyInLayout: "El gráfico seleccionado ya está en el layout",
            chartAlreadyInLayoutDetails: "El gráfico seleccionado ya está en el layout. Por favor, selecciona otro gráfico.",
            visualizationNotCreated: "La visualización no se ha podido crear",
            addedFilters: "Filtros añadidos",

            // Integration
            integrations: "Integraciones",
            integrationName: "Nombre de la integración",
            integrationDescription: "Descripción de la integración",
            createNewIntegration: "Crear nueva integración",
            integrationLogo: "Logo de la integración",
            noConfigurationWarningForIntegrations: "No hay ningun método de ingesta configurado. Por favor, configura al menos un método de ingesta para poder usar esta integración.",
            continueWithIntakeConfiguration: "Continuar con la configuración de la ingesta",
            selectIntakeMethods: "Selecciona los métodos de ingesta",
            createNewIntakeMethod: "Crear nuevo método de ingesta",
            updateIntegration: "Actualizar integración",
            configurationSchema: "Esquema de configuración",
            configurationSchemaName: "Nombre del esquema de configuración",
            configurationParams: "Parámetros de configuración",
            configurationSchemaParams: "Configuración de los parámetros del esquema",
            intakeMethods: "Métodos de ingesta",
            listeners: "Listeners",
            default: "Por defecto",
            configureListener: "Configurar listener",
            listenerConfiguration: "Configuración del listener",
            information: "Información",
            tasks: "Tareas",
            state: "Estado",
            port: "Puerto",
            maxSizeOfMessageQueue: "Tamaño máximo de la cola de mensajes",
            maxNumberOfTCPConnections: "Nº máximo de conexiones TCP",
            continueLaterConfiguration: "Podras continuar la configuracion más tarde",
            noSelectedIntakeMethods: "No hay métodos de ingesta seleccionados",
            configureOne: "Por favor, configura al menos uno",
            activateListener: "Este listener no esta activado. Activar para recibir datos.",
            listnerWorking: "Este listener esta activado y recibiendo datos.",
            listenerName: "Nombre del listener",
            listenerDescription: "Descripción del listener",
            playConfirmation: "¿Estas seguro de que quieres continuar? Esto reanudará el método de ingesta y empezará a recibir datos.",
            pauseConfirmation: "¿Estas seguro de que quieres continuar? Esto pausará el método de ingesta y dejará de recibir datos.",
            confirmation: "Confirmación",
            integration: "Integración",
            preprocessor: "Preprocesado",
            geo_ip: "GeoIP",
            threat_intel: "Inteligencia de amenazas",
            output_index: "Índice de salida",
            deep_siem_ia: "AirSIEM IA",
            generalConfigurationParams: "Parámetros de configuración generales",
            noParams: "No hay parámetros configurados. Añade al menos uno para continuar.",
            intakeMethodInfoTitle: "Información sobre métodos de ingesta",
            intakeMethodInfoDescription: "Los métodos de ingesta son los métodos usados para recibir datos de los activos y enviarlos al sistema.",
            mustBeUnique: "Debe ser único",
            ingestMethodParams: "Parámetros del método de ingesta",
            ingestSchema: "Esquema de ingesta",
            ingestSchemasParams: "Parámetros del esquema de ingesta",
            ingestFormat: "Formato de ingesta",
            intakeSchemaInfoTitle: "Información sobre esquemas de ingesta",
            intakeSchemaInfoDescription: "Los esquemas de ingesta son los esquemas usados para recibir datos de los activos y enviarlos al sistema.",

            // Types
            types: "Tipos",
            integer: "Entero",
            string: "Cadena",
            boolean: "Booleano",
            float: "Decimal",

            // Intake configuration
            IntegrationConfiguration: "Configuración de la integración",
            intakeMethod: "Método de ingesta",
            intakeSchema: "Esquema de ingesta",
            intakeSchemas: "Esquemas de ingesta",
            selectIntakeMethod: "Selecciona el método de ingesta",
            selectIntakeSchema: "Selecciona el esquema de ingesta",
            listenerInfo: "Información del listener",
            nameAndDescription: "Nombre y descripción",
            configureListener: "Configurar listener",
            configureListener: "Configurar listener",
            createListener: "Crear listener",
            createListener: "Crear listener",
            configureIntakeMethod: "Configurar método de ingesta",
            configureIntakeSchema: "Configurar esquema de ingesta",
            atLeastOneParamNeeded: "Al menos un parámetro es necesario",
            needNewIntakeSchema: "Es necesario crear un nuevo esquema de ingesta",
            needNewIntakeMethod: "Es necesario crear un nuevo método de ingesta",
            newIntakeSchema: "Nuevo esquema de ingesta",
            src: "Origen",
            destination: "Destino",
            src_type: "Tipo del origen",
            dst_type: "Tipo del destino",
            required: "Obligatorio",
            format: "Formato",
            newIntakeMethod: "Nuevo método de ingesta",
            intakeSchemaName: "Nombre del esquema de ingesta",
            intakeSchemaDescription: "Descripción del esquema de ingesta",

            // Alert Configurations
            alertConfigurations: "Configuraciones de alerta",
            alertConfigurationTotal: "Total de configuraciones de alerta",
            activeConfigurations: "Configuraciones activas",
            avaliableAlertMethods: "Métodos de alerta disponibles",
            alertMethods: "Métodos de alerta",
            isActive: "Activo",
            newAlertConfiguration: "Nueva configuración de alerta",
            createAlertConfiguration: "Crear configuración de alerta",
            alertName: "Nombre de la alerta",
            alertDescription: "Descripción de la alerta",
            sources: "Fuentes",
            newEvent: "Nuevo evento",
            newIncident: "Nuevo incidente",
            newCorrelationRule: "Nueva regla de correlación",
            newDetectionRule: "Nueva regla de detección",
            createNewEvent: "Crear nuevo evento",
            createNewIncident: "Crear nuevo incidente",
            createNewCorrelationRule: "Crear nueva regla de correlación",
            createNewDetectionRule: "Crear nueva regla de detección",
            author: "Autor",
            detectionRulesTotal: "Total de reglas de detección",
            correlationRulesTotal: "Total de reglas de correlación",
            configTasks: "Tareas de configuración",
            sesions: "Sesiones",
            logout: "Cerrar sesión",
            unasigned: "Sin asignar",

            // Toast messages
            alertUpdated: "Alerta actualizada correctamente",
            alertNotUpdated: "No se ha podido actualizar la alerta",
            alertCreated: "Alerta creada correctamente",
            alertDeleted: "Alerta eliminada correctamente",
            alertConfigurationUpdated: "Configuración de alerta actualizada correctamente",
            alertConfigurationNotUpdated: "No se ha podido actualizar la configuración de alerta",
            alertConfigurationCreated: "Configuración de alerta creada correctamente",
            alertConfigurationDeleted: "Configuración de alerta eliminada correctamente",
            assetUpdated: "Activo actualizado correctamente",
            assetNotUpdated: "No se ha podido actualizar el activo",
            assetCreated: "Activo creado correctamente",
            assetDeleted: "Activo eliminado correctamente",
            eventUpdated: "Evento actualizado correctamente",
            eventNotUpdated: "No se ha podido actualizar el evento",
            eventCreated: "Evento creado correctamente",
            eventDeleted: "Evento eliminado correctamente",
            incidentUpdated: "Incidente actualizado correctamente",
            incidentNotUpdated: "No se ha podido actualizar el incidente",
            incidentCreated: "Incidente creado correctamente",
            incidentDeleted: "Incidente eliminado correctamente",
            correlationRuleUpdated: "Regla de correlación actualizada correctamente",
            correlationRuleNotUpdated: "No se ha podido actualizar la regla de correlación",
            correlationRuleCreated: "Regla de correlación creada correctamente",
            correlationRuleDeleted: "Regla de correlación eliminada correctamente",
            detectionRuleUpdated: "Regla de detección actualizada correctamente",
            detectionRuleNotUpdated: "No se ha podido actualizar la regla de detección",
            detectionRuleCreated: "Regla de detección creada correctamente",
            detectionRuleDeleted: "Regla de detección eliminada correctamente",
            integrationUpdated: "Integración actualizada correctamente",
            integrationNotUpdated: "No se ha podido actualizar la integración",
            integrationCreated: "Integración creada correctamente",
            integrationDeleted: "Integración eliminada correctamente",
            listenerUpdated: "Listener actualizado correctamente",
            listenerNotUpdated: "No se ha podido actualizar el listener",
            listenerCreated: "Listener creado correctamente",
            listenerDeleted: "Listener eliminado correctamente",
            userUpdated: "Usuario actualizado correctamente",
            userNotUpdated: "No se ha podido actualizar el usuario",
            userCreated: "Usuario creado correctamente",
            userDeleted: "Usuario eliminado correctamente",
            teamUpdated: "Equipo actualizado correctamente",
            teamNotUpdated: "No se ha podido actualizar el equipo",
            teamCreated: "Equipo creado correctamente",
            teamDeleted: "Equipo eliminado correctamente",
            roleUpdated: "Rol actualizado correctamente",
            roleNotUpdated: "No se ha podido actualizar el rol",
            roleCreated: "Rol creado correctamente",
            roleDeleted: "Rol eliminado correctamente",
            visualizationUpdated: "Visualización actualizada correctamente",
            visualizationNotUpdated: "No se ha podido actualizar la visualización",
            visualizationCreated: "Visualización creada correctamente",
            visualizationDeleted: "Visualización eliminada correctamente",
            paletteCreated: "Paleta creada correctamente",
            paletteNotCreated: "No se ha podido crear la paleta",
            paletteDeleted: "Paleta eliminada correctamente",
            paletteUpdated: "Paleta actualizada correctamente",
            paletteNotUpdated: "No se ha podido actualizar la paleta",
            paletteNotDeleted: "No se ha podido eliminar la paleta",
            dashboardCreated: "Dashboard creado correctamente",
            dashboardInfoTitle: "Información del dashboard",
            dashboardInfoDescription: "Un dashboard es una colección de visualizaciones que se muestran en una página web. Los dashboards son la base de la visualización de datos.",
            visualizationInfoTitle: "Información de la visualización",
            visualizationInfoDescription: "Una visualización es un gráfico que muestra datos de una forma visual. Las visualizaciones son la base de la visualización de datos.",

            dashboardXAxisInfo: "El eje X significa el eje horizontal de la gráfica.",
            dashboardYAxisInfo: "El eje Y significa el eje vertical de la gráfica.",
            dashboardMetricInfo: "La métrica es el tipo de dato que se representará en la gráfica.\n \n Counter: Un contador es una métrica acumulativa que representa un único contador monotónicamente creciente cuyo valor sólo puede aumentar o ponerse a cero al reiniciarse \n \n Summarie: Similar a un histograma, un resumen muestra observaciones (normalmente cosas como duraciones de solicitud y tamaños de respuesta). Aunque también proporciona un recuento total de observaciones y una suma de todos los valores observados, calcula cuantiles configurables en una ventana temporal deslizante. \n \n Average: número que expresa el valor central o típico de un conjunto de datos",
            dashboardXAxis4Info: "Helper... Ask what to put here",
            dashboardModelInfo: "El modelo es el tipo de dato que se representará en la gráfica.",
            dashboardSlicesInfo: "Las porciones son los datos que se representarán en la gráfica.",

            // Listeners
            listenersTotal: "Total de listeners",
            activeListeners: "Listeners activos",
            unactiveListeners: "Listeners inactivos",
            outputIndex: "Índice de salida",
            newListener: "Nuevo listener",

            // Dashboard
            noRecentDashboards: "No hay dashboards recientes, crea uno nuevo!",
            notSaved: "Sin guardar",

            // Surveillance
            UEBA: "UEBA",
            surveillance: "Supervisión",
            onAssets: "Activos activos",
            explore: "Explorar",
            close: "Cerrar",
            OS: "OS",
            checkpoints: "Checkpoints",
            setCheckpoint: "Set checkpoint",
            checkpointName: "Nombre del checkpoint",
            time: "Tiempo",
            startDate: "Fecha de inicio",
            endDate: "Fecha de fin",
            newCheckpoint: "Nuevo checkpoint",
            changeThis: "Filtrar por fecha",
            recordingNotLoaded: "No se ha podido cargar la grabación",
            NotVideoForThisDates: "No hay vídeo para estas fechas",

            // Create new visualization
            model: "Modelo de datos",
            metric: "Métrica",
            select: "Seleccionar",
            slices: "Porciones",
            field: "Campo",
            interval: "Intervalo",
            maxNumbOfSlices: "Máx. número de porciones",
            min: "Mínimo",
            orderby: "Ordenar por",
            x_axis: "Eje X",
            y_axis: "Eje Y",
            qwery: "Consulta",
            restoOfDataLabel: "Nombre de la porción restante",
            label: "Etiqueta",
            options: "Options",
            Data: "Data",
            visualizationExample: "*Esto es sólo un ejemplo de cómo será la visualización",
            chooseLabel: "Elige una etiqueta",
            generalOptions: "Opciones generales",
            dataModel: "Modelo de datos",
            metricOptions: "Opciones de métrica",
            xAxisOptions: "Opciones del eje X",
            slicesOptions: "Opciones de las porciones",

            customizeNameDescAndPallete: "Personaliza el nombre, la descripción y la paleta de colores",
            youHaveSelected: "Has seleccionado",
            asDataModel: "como modelo de datos",
            asMetric: "como métrica",
            yourMetricWill: "Tu métrica se representará",
            asField: "como campo",
            asXAxisFormat: "como formato del eje X",
            yourXaxisWillDraw: "Tu eje X se representará como",
            in: "en",
            order: "orden",
            withAMaxOf: "con un máximo de",
            slices: "porciones",
            inThisInterval: "en este intervalo",
            asSlicesFormat: "como formato de las porciones",
            yourVisualizationWillBeSeparatedInMax: "Tu visualización se separará en un máximo de",
            using: "usando",
            thereWillNotBeSlices: "No habrá porciones",

            // Logs
            selectedFields: "Campos seleccionados",
            avaliableFields: "Campos disponibles",
            logs: "Logs",
            noneFieldAvaliable: "No hay campos disponibles",
            noneFieldSelected: "No hay campos seleccionados",
            datesRange: "Rango de fechas",
            logsByDate: "Logs por fecha",
            selectModel: "Modelo de datos",
            selectRangeOfDates: "Rango de fechas",
            addAFilter: "Añadir filtro (opcional)",
            selectChartInterval: "Intervalo de gráfica",

            // Info cards   
            assetInfoTitle: "Información del activo",
            assetInfoDescription: "Un activo es un dispositivo conectado a la red y supervisado por el sistema. Los activos pueden ser servidores, estaciones de trabajo, enrutadores, conmutadores, cortafuegos, etc. Los activos son la base del sistema, ya que son los que generan los eventos que analiza el sistema.",
            eventInfoTitle: "Información del evento",
            eventInfoDescription: "Un evento es un mensaje generado por un activo que indica que algo ha ocurrido en el mismo. Los eventos pueden ser de diferentes tipos, como por ejemplo: alertas de seguridad, fallos de hardware, fallos de software, etc.",
            incidentInfoTitle: "Información del incidente",
            incidentInfoDescription: "Un incidente es un evento que ha sido analizado por el sistema y que ha sido considerado como un incidente de seguridad. Los incidentes son la base de la gestión de incidentes de seguridad.",
            correlationRuleInfoTitle: "Información de la regla de correlación",
            correlationRuleInfoDescription: "Una regla de correlación es una regla que permite correlacionar eventos de diferentes activos. Las reglas de correlación son la base de la detección de incidentes de seguridad.",
            detectionRuleInfoTitle: "Información de la regla de detección",
            detectionRuleInfoDescription: "Una regla de detección es una regla que permite detectar incidentes de seguridad. Las reglas de detección son la base de la detección de incidentes de seguridad.",
            alertConfigurationInfoTitle: "Información de la configuración de alerta",
            alertConfigurationInfoDescription: "Una configuración de alerta es una configuración que permite configurar la forma en la que se notificará a los usuarios de un incidente de seguridad.",

            activatedDetections: "Detecciones activadas",
            activatedCorrelations: "Correlaciones activadas",
            deactivatedDetections: "Detecciones desactivadas",
            deactivatedCorrelations: "Correlaciones desactivadas",

            noRole: "Usuario sin roles",
            methods: "Métodos",
            confirm: "Confirmar",
            deleteAll: "Eliminar todo",
            deleteAllDescription: "Se eliminarar todos los elementos de la base de datos",
            writeThisToConfirm: "Escribe esto para confirmar que deseas borrar los datos:",
            noMatch: "La frase no coincide",
            searchAmongTheOptions: "Buscar entre las opciones",

            optionsDescription: "Opciones especificas de la organizacion.",
            import: "Importar",
            or: "o",
            importedSuccesfully: "Importado correctamente",
            deletedSuccesfully: "eliminados correctamente",
            notDeletedSuccesfully: "no se han podido eliminar",
            correlationsBySeverity: "Correlaciones por severidad mínima",
            detectionsBySeverity: "Detecciones por severidad mínima",
            allParamsRequired: "Todos los parámetros son obligatorios",

            // Individual dashboards
            atRisk: "En riesgo",
            notAtRisk: "Sin riesgo",
            assetGroups: "Grupos de activos",
            assetLocation: "Geolocalización de activos",
            OSCounter: "Contador de sistemas operativos",
            others: "Otros",
            assetsWhitMoreIncidents: "Activos más afectados (con más incidentes)",
            pleaseEnterValue: "Por favor, introduce un valor",
            topAssetsEvents: "Top activos (con más eventos)",
            topAssetsIncidents: "Top activos (con más incidentes)",
            eventsInTime: "Eventos en el tiempo",
            incidentsInTime: "Incidentes en el tiempo",

            // Helpers
            incidentByStateHelper: "Este grafico muestra el numero de incidentes por estado",
            ingestMethodHelper: "Los metodos de ingestión son los que se encargan de recoger los datos de los activos y de enviarlos al sistema.",
            listenerHelper: "Un listener es un proceso que se encarga de recibir datos de un activo y enviarlos al sistema.",
            elementByCriticalityHelper: "Este grafico muestra el numero de elementos por criticidad",
            elementBySeverityHelper: "Este grafico muestra el numero de elementos por severidad",
            elementByMinimumSeverityHelper: "Este grafico muestra el numero de elementos por severidad mínima",
            importFileHelper: "El archivo debe ser un archivo .json con el formato adecuado. Puedes consultar el formato en la documentación.",

            // General 
            changeSelectedCriticality: "Cambiar criticidad de los seleccionados",
            changeSelectedStatus: "Cambiar estado de los seleccionados",
            confirmDialog: "¿Esta seguro de que quieres continuar?",
            schedulde: "Programación",

            // Tasks
            expires: "Expira",
            startsAfter: "Empieza después",
            soft_time_limit: "Límite de tiempo suave",
            total_run_count: "Número total de ejecuciones",
            max_run_count: "Número máximo de ejecuciones",
            object_id: "ID del objeto",
            object_type: "Tipo de objeto",
            every: "Cada",
            period: "Periodo",
            investigationName: "Nombre de la investigación",
            investigationDescription: "Descripción de la investigación",

            deeplql_helper: "Para obtener más información sobre cómo usar DeepLQL, consulte la documentación.",

            // Documentation
            introduction: "Introducción",
            gettingStarted: "Primeros pasos",
            deepqlGettingStarted: "DeepQL es un lenguaje de consultas creado para AirSIEM. Con DeepQL, los usuarios pueden realizar consultas avanzadas sobre los datos almacenados en el sistema. DeepQL es un lenguaje de consultas basado en SQL, pero con algunas diferencias. En esta sección, se proporciona una guía para comenzar a usar DeepQL.",
            whereToFindDeepqlTitle: "¿Dónde encontrar DeepQL?",
            whereToFindDeepql: "Podemos encontrar DeepQL en la sección de dashboards personalizados. Dentro del configurador de una visualización, en las opciones del eje X, podemos seleccionar 'Filters', lo que nos pondrá a disposición una terminal de DeepQL.",
            howToUseDeepqlTitle: "¿Cómo usar DeepQL?",
            howToUseDeepql: "En primer lugar debemos elegir el nombre que identificará el conjunto de datos referente a la consulta que posteriormente introduciremos en la terminal. Esto lo haremos a traves del input 'Label'. A continuación, debemos introducir la consulta en DeepQL. Una vez introducida la consulta, debemos pulsar 'Enter' y la terminal nos devolverá si la consulta es correcta y ha sido añadida correcaamente. En caso contrario, nos devolverá un mensaje de error.",
            howToUseDeepql2: "Haciendo uso del boton 'Tab', la terminal nos proporcionará una lista de las posibles opciones que podemos introducir en la consulta basandose en el modelo de datos seleccionado para esa visualización. Esto nos facilitará la tarea de introducir la consulta.",
            howToUseDeepql3: "En la sección inferior de la terminal, podemos encontrar el listado de las consultas que hemos añadido. Si queremos eliminar alguna consulta, podemos hacerlo pulsando el boton 'x'. ",
            howToUseDeepql4: "DeepQL se encargará de calcular la porción restante a las consultas que hemos introducido. Para ello, debemos introducir el nombre que identificará a la porción restante en el input 'Rest of data label'.",
            usingDeepQL: "Usando DeepQL",
            usingDeepQL1: "En este apartado se muestra un listado de los operadores y funciones que podemos usar en DeepQL.",
            examples: "Ejemplos",
            examples1: "En esta sección se muestran ejemplos de consultas que podemos realizar en DeepQL.",
        }
    }
});
export default i18n;