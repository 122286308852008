import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'
import { app } from '@/main.js'
import SignIn from '../views/Sign-in'
import SignUp from '../views/Sign-up'
import ForgotPassword from '../views/ForgotPass'
import RecoverPass from '../views/RecoverPass'
import Profile from '../views/Profile'
import Home from '../views/Home'
import Integrations from '../views/Integrations'
import IntegrationsDetails from '../views/IntegrationsDetails'
import Listeners from '../views/Listeners'
import Logs from '../views/Logs'
import Assets from '../views/Assets'
import AssetsDetails from '../views/AssetsDetails'
import Dashboards from '../views/Dashboards'
import DashboardCreator from '../views/DashboardCreator'
import DashboardViewer from '../views/DashboardViewer'
import Investigation from '../views/Investigation'
import InvestigationCreator from '../views/InvestigationCreator'
import Events from '../views/Events'
import EventsDetails from '../views/EventsDetails'
import Incidents from '../views/Incidents'
import IncidentsDetails from '../views/IncidentsDetails'
import AlertConfiguration from '../views/AlertConfiguration'
import AlertConfigurationDetails from '../views/AlertConfigurationDetails'
import MachineLearning from '../views/MachineLearning'
import Correlation from '../views/Correlation'
import CorrelationDetails from '../views/CorrelationDetails'
import Detection from '../views/Detection'
import DetectionDetails from '../views/DetectionDetails'
import ManageOrganizations from '../views/ManageOrganizations'
import ManageRoles from '../views/ManageRoles'
import Tasks from '../views/Tasks'
import Surveillance from '../views/Surveillance'
import NotFound from '../views/NotFound'
import Documentation from '../views/Documentation'

import * as $api from "../api/api";
import { useUserStore } from '../store/store.js'

const routes = [{
        path: '/',
        name: 'SignIn',
        component: SignIn,
    },
    {
        path: '/register/:token',
        name: 'SignUp',
        component: SignUp,
    },
    {
        path: '/forgot-password',
        name: 'ForgotPassword',
        component: ForgotPassword,
    },
    {
        path: '/password-reset/:token',
        name: 'RecoverPass',
        component: RecoverPass,
    },
    {
        path: '/home',
        name: 'Home',
        component: Home,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/profile',
        name: 'Profile',
        component: Profile,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/source',
        name: 'Integrations',
        component: Integrations,
        meta: {
            requiresAuth: true,
            requiresAccess: true
        }
    },
    {
        path: '/source-details',
        name: 'IntegrationsDetails',
        component: IntegrationsDetails,
        meta: {
            requiresAuth: true,
            requiresAccess: true
        }
    },
    {
        path: '/listener',
        name: 'Listeners',
        component: Listeners,
        meta: {
            requiresAuth: true,
            requiresAccess: true
        }
    },
    {
        path: '/log',
        name: 'Logs',
        component: Logs,
        meta: {
            requiresAuth: true,
            requiresAccess: true
        }
    },
    {
        path: '/asset',
        name: 'Assets',
        component: Assets,
        meta: {
            requiresAuth: true,
            requiresAccess: true
        }
    },
    {
        path: '/asset-details',
        name: 'AssetsDetails',
        component: AssetsDetails,
        meta: {
            requiresAuth: true,
            requiresAccess: true
        }
    },
    {
        path: '/dashboard',
        name: 'Dashboards',
        component: Dashboards,
        meta: {
            requiresAuth: true,
            requiresAccess: true
        }
    }, {
        path: '/dashboard-creator',
        name: 'DashboardCreator',
        component: DashboardCreator,
        meta: {
            requiresAuth: true,
            requiresSave: true
        }
    }, {
        path: '/dashboard-viewer',
        name: 'DashboardViewer',
        component: DashboardViewer,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/investigation',
        name: 'Investigation',
        component: Investigation,
        meta: {
            requiresAuth: true,
            requiresAccess: true
        }
    },
    {
        path: '/investigation-creator',
        name: 'InvestigationCreator',
        component: InvestigationCreator,
        meta: {
            requiresAuth: true,
            requiresAccess: true
        }
    },
    {
        path: '/event',
        name: 'Events',
        component: Events,
        meta: {
            requiresAuth: true,
            requiresAccess: true
        }
    },
    {
        path: '/event-details',
        name: 'EventsDetails',
        component: EventsDetails,
        meta: {
            requiresAuth: true,
            requiresAccess: true
        }
    },
    {
        path: '/incident',
        name: 'Incidents',
        component: Incidents,
        meta: {
            requiresAuth: true,
            requiresAccess: true
        }
    },
    {
        path: '/incident-details',
        name: 'IncidentsDetails',
        component: IncidentsDetails,
        meta: {
            requiresAuth: true,
            requiresAccess: true
        }
    },
    {
        path: '/alert',
        name: 'AlertConfiguration',
        component: AlertConfiguration,
        meta: {
            requiresAuth: true,
            requiresAccess: true
        }
    },
    {
        path: '/alert-details',
        name: 'AlertConfigurationDetails',
        component: AlertConfigurationDetails,
        meta: {
            requiresAuth: true,
            requiresAccess: true
        }
    },
    {
        path: '/machine-learning-job',
        name: 'MachineLearning',
        component: MachineLearning,
        meta: {
            requiresAuth: true,
            requiresAccess: true
        }
    },
    {
        path: '/correlation-rule',
        name: 'Correlation',
        component: Correlation,
        meta: {
            requiresAuth: true,
            requiresAccess: true
        }
    },
    {
        path: '/correlation-rule-details',
        name: 'CorrelationDetails',
        component: CorrelationDetails,
        meta: {
            requiresAuth: true,
            requiresAccess: true
        }
    },
    {
        path: '/detection-rule',
        name: 'Detection',
        component: Detection,
        meta: {
            requiresAuth: true,
            requiresAccess: true
        }
    },
    {
        path: '/detection-rule-details',
        name: 'DetectionDetails',
        component: DetectionDetails,
        meta: {
            requiresAuth: true,
            requiresAccess: true
        }
    },
    {
        path: '/organization',
        name: 'ManageOrganizations',
        component: ManageOrganizations,
        meta: {
            requiresAuth: true,
            requiresAccess: true
        }
    },
    {
        path: '/manage-roles',
        name: 'ManageRoles',
        component: ManageRoles,
        meta: {
            requiresAuth: true,
            requiresAccess: true
        }
    },
    {
        path: '/task',
        name: 'Tasks',
        component: Tasks,
        meta: {
            requiresAuth: true,
            requiresAccess: true
        }
    },
    {
        path: '/surveillance',
        name: 'Surveillance',
        component: Surveillance,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/documentation',
        name: 'Documentation',
        component: Documentation,
        meta: {
            requiresAuth: true,
        }
    },
    // 404 error page
    {
        path: '/:catchAll(.*)',
        name: 'NotFound',
        component: NotFound
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes,
})

function isLoggedIn() {
    if ($cookies.get("ACCESS_TOKEN")) {
        return true;
    } else {
        return false;
    }
}

function checkIfCookieHasExpired() {
    console.log("Checking if cookie has expired...");
    console.log("Cookie: " + $cookies.get("ACCESS_TOKEN"));
    const token = $cookies.get("ACCESS_TOKEN");
    $api.checkRegister(token).then((response) => {
        console.log(response);
        if (response.status == 200) {
            console.log("Cookie has not expired");
            return false;
        } else {
            console.log("Cookie has expired");
            return true;
        }
    });
}

async function hasUserPermission(permission) {
    // Get user object
    const userStore = useUserStore();
    const userObject = await userStore.getUser();

    if (userObject.is_superuser) {
        return true;
    }

    // If permission contains '-details' remove it
    if (permission.includes("-details")) {
        permission = permission.replace("-details", "");
    }

    // Remove / from permission
    permission = permission.replace("/", "");
    // Replace all - with _
    permission = permission.replace(/-/g, "_");

    // If path is manage-roles
    if (permission == "manage_roles:get") {
        for (let i = 0; i < userObject.permissions.length; i++) {
            if (userObject.permissions[i].value == "user:get" || userObject.permissions[i].value == "role:get" || userObject.permissions[i].value == "team:get") {
                return true;
            }
        }
    }


    // Loop through userObject.permissions
    // Check if userObject.permissions[i].value is equal to permission
    for (let i = 0; i < userObject.permissions.length; i++) {
        if (userObject.permissions[i].value === permission) {
            return true;
        }
    }
    // If it is not, return false
    return false;
}

router.beforeEach(async(to, from) => {
    if (checkIfCookieHasExpired() && to.path != '/') {
        console.log("Cookie has expired, redirecting to login page");
        return {
            path: '/',
        }
    }
    // instead of having to check every route record with
    // to.matched.some(record => record.meta.requiresAuth)
    if (to.path === '/' && isLoggedIn()) {
        // if logged in redirect to home
        return {
            path: '/home',
        }
    }

    if (to.meta.requiresAuth && !isLoggedIn()) {
        // this route requires auth, check if logged in
        // if not, redirect to login page.
        return {
            path: '/',
            // save the location we were at to come back later
            // query: { redirect: to.fullPath },
        }
    }

    if (from.meta.requiresSave) {
        //console.log('Is dashboard saved? ->', app.config.globalProperties.dashboardSaved);
        let answer = false
        if (!app.config.globalProperties.dashboardSaved) {
            switch (app.config.globalProperties.getGlobalLocale()) {
                case 'en':
                    answer = window.confirm('Do you really want to leave? You have unsaved changes!')
                    break;
                case 'es':
                    answer = window.confirm('¿Realmente quieres salir? Tienes cambios sin guardar!')
                    break;
                default:
                    answer = window.confirm('Do you really want to leave? You have unsaved changes!')
            }

            if (answer) {
                return true
            } else {
                return false
            }
        } else {
            return true
        }
    }

    if (to.meta.requiresAccess && !await hasUserPermission(to.path + ':get')) {
        return {
            path: from,
            // save the location we were at to come back later
            query: { redirect: to.fullPath },
        }
    }

})



export default router