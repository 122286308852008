<!----------------------------------------------->
<!--                  TEMPLATE                 -->
<!----------------------------------------------->
<template>
  <div class="aux">
    <Sitebar></Sitebar>
    <div class="maincontainer">
      <div class="row">
        <div class="col-6">
          <div class="pad-helper main-table p-2 m-0" style="height:100%">
            <h5 class="task-table-title"><i class="pi pi-filter"></i>&nbsp;{{ $t('listeners') }}</h5>
            <span class="p-input-icon-left" style="width:100%;">
              <InputText v-model="listenerFilters['global'].value" :placeholder="$t('searchByAsset')"
                class="search-input mb-2">
              </InputText>
            </span>
            <DataTable :value="listeners" :paginator="true" class="p-datatable-customers" :rows="5" :row-hover="true"
              v-model:selection="selectedData" dataKey="id" v-model:filters="listenerFilters" filter-display="menu"
              :loading="loading_table" selectionMode="single"
              paginator-template="PrevPageLink PageLinks NextPageLink CurrentPageReport"
              :rows-per-page-options="[10, 25, 50]"
              current-page-report-template="Showing {first} to {last} of {totalRecords} entries"
              :global-filter-fields="['name', 'created_at', 'schedule', 'start_after', 'expires', 'enabled']" responsive-layout="scroll">
              <template #empty>
                {{ $t('noDataFound') }}
              </template>
              <template #loading>
                {{ $t('loadingData') }}
              </template>
              <Column field="name" :header="$t('name')" sortable>
                <template #body="{ data }">
                  {{ data.name }}
                </template>
              </Column>
              <Column field="created_at" :header="$t('createdAt')" sortable>
                <template #body="{ data }">
                  {{ formatDate(data.created_at) }}
                </template>
              </Column>
              <Column field="scheulde" :header="$t('schedulde')" sortable>
                <template #body="{ data }">
                  {{ prettifyString(data.scheulde.type) + ': every ' + data.scheulde.every + ' ' + data.scheulde.period }}
                </template>
              </Column>
              <Column field="start_after" :header="$t('startsAfter')" sortable>
                <template #body="{ data }">
                  {{ formatDate(data.start_after) }}
                </template>
              </Column>
              <Column field="expires" :header="$t('expires')" sortable>
                <template #body="{ data }">
                  {{ formatDate(data.expires) }}
                </template>
              </Column>
              <Column field="enabled" :header="$t('status')" sortable>
                <template #body="{ data }">
                  <InputSwitch v-model="data.enabled" class="mt-1 mr-1" @click="updateSelectedTaskStatus(data.id)" />
                </template>
              </Column>
              <Column field="configure">
                <template #body="{ data }">
                  <i class="pi pi-cog" @click="getTaskData(data); $refs.configTask.openModal()"></i>
                </template>
              </Column>
            </DataTable>
          </div>
        </div>
        <div class="col-6">
          <div class="pad-helper main-table p-2 m-0" style="height:100%">
            <h5 class="task-table-title"><i class="pi pi-server"></i>&nbsp;{{ $t('assets') }}</h5>
            <span class="p-input-icon-left" style="width:100%;">
              <InputText v-model="assetsFilters['global'].value" :placeholder="$t('searchByAsset')"
                class="search-input mb-2">
              </InputText>
            </span>
            <DataTable :value="assets" :paginator="true" class="p-datatable-customers" :rows="5" :row-hover="true"
              v-model:selection="selectedData" dataKey="id" v-model:filters="listenerFilters" filter-display="menu"
              :loading="loading_table" selectionMode="single"
              paginator-template="PrevPageLink PageLinks NextPageLink CurrentPageReport"
              :rows-per-page-options="[10, 25, 50]"
              current-page-report-template="Showing {first} to {last} of {totalRecords} entries"
              :global-filter-fields="['name', 'created_at', 'schedule', 'start_after', 'expires', 'enabled']" responsive-layout="scroll">
              <template #empty>
                {{ $t('noDataFound') }}
              </template>
              <template #loading>
                {{ $t('loadingData') }}
              </template>
              <Column field="name" :header="$t('name')" sortable>
                <template #body="{ data }">
                  {{ data.name }}
                </template>
              </Column>
              <Column field="created_at" :header="$t('createdAt')" sortable>
                <template #body="{ data }">
                  {{ formatDate(data.created_at) }}
                </template>
              </Column>
              <Column field="scheulde" :header="$t('schedulde')" sortable>
                <template #body="{ data }">
                  {{ prettifyString(data.scheulde.type) + ': every ' + data.scheulde.every + ' ' + data.scheulde.period }}
                </template>
              </Column>
              <Column field="start_after" :header="$t('startsAfter')" sortable>
                <template #body="{ data }">
                  {{ formatDate(data.start_after) }}
                </template>
              </Column>
              <Column field="expires" :header="$t('expires')" sortable>
                <template #body="{ data }">
                  {{ formatDate(data.expires) }}
                </template>
              </Column>
              <Column field="enabled" :header="$t('status')" sortable>
                <template #body="{ data }">
                  <InputSwitch v-model="data.enabled" class="mt-1 mr-1" @click="updateSelectedTaskStatus(data.id)" />
                </template>
              </Column>
              <Column field="configure">
                <template #body="{ data }">
                  <i class="pi pi-cog" @click="getTaskData(data); $refs.configTask.openModal()"></i>
                </template>
              </Column>
            </DataTable>
          </div>
        </div>
        <div class="col-6">
          <div class="pad-helper main-table p-2 m-0" style="height:100%">
            <h5 class="task-table-title"><i class="pi pi-flag"></i>&nbsp;{{ $t('detectionRules') }}</h5>
            <span class="p-input-icon-left" style="width:100%;">
              <InputText v-model="detectionFilters['global'].value" :placeholder="$t('searchByAsset')"
                class="search-input mb-2">
              </InputText>
            </span>
            <DataTable :value="detections" :paginator="true" class="p-datatable-customers" :rows="5" :row-hover="true"
              v-model:selection="selectedData" dataKey="id" v-model:filters="listenerFilters" filter-display="menu"
              :loading="loading_table" selectionMode="single"
              paginator-template="PrevPageLink PageLinks NextPageLink CurrentPageReport"
              :rows-per-page-options="[10, 25, 50]"
              current-page-report-template="Showing {first} to {last} of {totalRecords} entries"
              :global-filter-fields="['name', 'created_at', 'schedule', 'start_after', 'expires', 'enabled']" responsive-layout="scroll">
              <template #empty>
                {{ $t('noDataFound') }}
              </template>
              <template #loading>
                {{ $t('loadingData') }}
              </template>
              <Column field="name" :header="$t('name')" sortable>
                <template #body="{ data }">
                  {{ data.name }}
                </template>
              </Column>
              <Column field="created_at" :header="$t('createdAt')" sortable>
                <template #body="{ data }">
                  {{ formatDate(data.created_at) }}
                </template>
              </Column>
              <Column field="scheulde" :header="$t('schedulde')" sortable>
                <template #body="{ data }">
                  {{ prettifyString(data.scheulde.type) + ': every ' + data.scheulde.every + ' ' + data.scheulde.period }}
                </template>
              </Column>
              <Column field="start_after" :header="$t('startsAfter')" sortable>
                <template #body="{ data }">
                  {{ formatDate(data.start_after) }}
                </template>
              </Column>
              <Column field="expires" :header="$t('expires')" sortable>
                <template #body="{ data }">
                  {{ formatDate(data.expires) }}
                </template>
              </Column>
              <Column field="enabled" :header="$t('status')" sortable>
                <template #body="{ data }">
                  <InputSwitch v-model="data.enabled" class="mt-1 mr-1" @click="updateSelectedTaskStatus(data.id)" />
                </template>
              </Column>
              <Column field="configure">
                <template #body="{ data }">
                  <i class="pi pi-cog" @click="getTaskData(data); $refs.configTask.openModal()"></i>
                </template>
              </Column>
            </DataTable>
          </div>
        </div>
        <div class="col-6">
          <div class="pad-helper main-table p-2 m-0" style="height:100%">
            <h5 class="task-table-title"><i class="fa pi-link pi"></i>&nbsp;{{ $t('correlationRules') }}</h5>
            <span class="p-input-icon-left" style="width:100%;">
              <InputText v-model="correlationFilters['global'].value" :placeholder="$t('searchByAsset')"
                class="search-input mb-2">
              </InputText>
            </span>
            <DataTable :value="correlations" :paginator="true" class="p-datatable-customers" :rows="5" :row-hover="true"
              v-model:selection="selectedData" dataKey="id" v-model:filters="listenerFilters" filter-display="menu"
              :loading="loading_table" selectionMode="single"
              paginator-template="PrevPageLink PageLinks NextPageLink CurrentPageReport"
              :rows-per-page-options="[10, 25, 50]"
              current-page-report-template="Showing {first} to {last} of {totalRecords} entries"
              :global-filter-fields="['name', 'created_at', 'schedule', 'start_after', 'expires', 'enabled']" responsive-layout="scroll">
              <template #empty>
                {{ $t('noDataFound') }}
              </template>
              <template #loading>
                {{ $t('loadingData') }}
              </template>
              <Column field="name" :header="$t('name')" sortable>
                <template #body="{ data }">
                  {{ data.name }}
                </template>
              </Column>
              <Column field="created_at" :header="$t('createdAt')" sortable>
                <template #body="{ data }">
                  {{ formatDate(data.created_at) }}
                </template>
              </Column>
              <Column field="scheulde" :header="$t('schedulde')" sortable>
                <template #body="{ data }">
                  {{ prettifyString(data.scheulde.type) + ': every ' + data.scheulde.every + ' ' + data.scheulde.period }}
                </template>
              </Column>
              <Column field="start_after" :header="$t('startsAfter')" sortable>
                <template #body="{ data }">
                  {{ formatDate(data.start_after) }}
                </template>
              </Column>
              <Column field="expires" :header="$t('expires')" sortable>
                <template #body="{ data }">
                  {{ formatDate(data.expires) }}
                </template>
              </Column>
              <Column field="enabled" :header="$t('status')" sortable>
                <template #body="{ data }">
                  <InputSwitch v-model="data.enabled" class="mt-1 mr-1" @click="updateSelectedTaskStatus(data.id)" />
                </template>
              </Column>
              <Column field="configure">
                <template #body="{ data }">
                  <i class="pi pi-cog" @click="getTaskData(data); $refs.configTask.openModal()"></i>
                </template>
              </Column>
            </DataTable>
          </div>
        </div>
        <div class="col-6">
          <div class="pad-helper main-table p-2 m-0" style="height:100%">
            <h5 class="task-table-title"><i class="fa pi-sitemap pi"></i>&nbsp;{{ $t('machineLearning') }}</h5>
            <span class="p-input-icon-left" style="width:100%;">
              <InputText v-model="machineLearningFilters['global'].value" :placeholder="$t('searchByAsset')"
                class="search-input mb-2">
              </InputText>
            </span>
            <DataTable :value="machine_learning" :paginator="true" class="p-datatable-customers" :rows="5"
              :row-hover="true" v-model:selection="selectedData" dataKey="id" v-model:filters="listenerFilters"
              filter-display="menu" :loading="loading_table" selectionMode="single"
              paginator-template="PrevPageLink PageLinks NextPageLink CurrentPageReport"
              :rows-per-page-options="[10, 25, 50]"
              current-page-report-template="Showing {first} to {last} of {totalRecords} entries"
              :global-filter-fields="['name', 'created_at', 'schedule', 'start_after', 'expires', 'enabled']" responsive-layout="scroll">
              <template #empty>
                {{ $t('noDataFound') }}
              </template>
              <template #loading>
                {{ $t('loadingData') }}
              </template>
              <Column field="name" :header="$t('name')" sortable>
                <template #body="{ data }">
                  {{ data.name }}
                </template>
              </Column>
              <Column field="created_at" :header="$t('createdAt')" sortable>
                <template #body="{ data }">
                  {{ formatDate(data.created_at) }}
                </template>
              </Column>
              <Column field="scheulde" :header="$t('schedulde')" sortable>
                <template #body="{ data }">
                  {{ prettifyString(data.scheulde.type) + ': every ' + data.scheulde.every + ' ' + data.scheulde.period }}
                </template>
              </Column>
              <Column field="start_after" :header="$t('startsAfter')" sortable>
                <template #body="{ data }">
                  {{ formatDate(data.start_after) }}
                </template>
              </Column>
              <Column field="expires" :header="$t('expires')" sortable>
                <template #body="{ data }">
                  {{ formatDate(data.expires) }}
                </template>
              </Column>
              <Column field="enabled" :header="$t('status')" sortable>
                <template #body="{ data }">
                  <InputSwitch v-model="data.enabled" class="mt-1 mr-1" @click="updateSelectedTaskStatus(data.id)" />
                </template>
              </Column>
              <Column field="configure">
                <template #body="{ data }">
                  <i class="pi pi-cog" @click="getTaskData(data); $refs.configTask.openModal()"></i>
                </template>
              </Column>

            </DataTable>
          </div>
        </div>
      </div>
    </div>

    <!-- ===================================== configTask ===================================== -->
    <Modal ref="configTask">
      <template #header>
      </template>
      <template #body>
        <div class="row">
          <div class="col-12 text-center pt-4">
            <i class="pi pi-cog" style="font-size:2.5em;"></i>
          </div>
          <div class="col-12 text-center pt-0 pb-0">
            <h5 class="modal-title">{{ $t('configTasks') }}</h5>
          </div>
        </div>
        <div class="row">
          <div class="col-12 p-4">
            <label for="" class="pl-0 pb-0 mb-0">{{ $t('id') }}</label>
            <InputText disabled type="text" v-model="task_id" :placeholder="$t('task_id')" style="width:100%;">
            </InputText>

            <label for="" class="pl-0 pb-0 mb-0">{{ $t('name') }}</label>
            <InputText type="text" v-model="task_name" :placeholder="$t('taskName')" style="width:100%;">
            </InputText>

            <label for="" class="pl-0 pb-0 mb-0">{{ $t('description') }}</label>
            <Textarea type="text" v-model="task_desc" :placeholder="$t('taskDescription')" style="width:100%;">
            </Textarea>

            <label for="" class="pl-0 pb-0 mb-0 mt-1">{{ $t('Task') }}</label>
            <InputText type="text" v-model="task_task" :placeholder="$t('task')" style="width:100%;">
            </InputText>

            <label for="" class="pl-0 pb-0 mb-0 mt-1">{{ $t('soft_time_limit') }}</label>
            <InputNumber type="text" v-model="task_soft_time_limit" :placeholder="$t('soft_time_limit')" style="width:100%;">
            </InputNumber>

            <hr class="mt-4 mb-0 ml-0 mr-0">
            <div class="row">
              <div class="col-4 pl-3 pr-3">
                <label for="" class="pl-0 pb-0 mb-0 mt-1">{{ $t('type') }}</label>
                <Dropdown v-model="task_schedule_type" :options="task_schedule_type_options" optionLabel="label" :placeholder="$t('type')"
                  style="width:100%;">
                </Dropdown>
              </div>
              <div class="col-4 pl-3 pr-3">
                <label for="" class="pl-0 pb-0 mb-0 mt-1">{{ $t('every') }}</label>
                <InputNumber type="text" v-model="task_schedule_every" :placeholder="$t('every')" style="width:100%;">
                </InputNumber>
              </div>
              <div class="col-4 pl-3 pr-3">
                <label for="" class="pl-0 pb-0 mb-0 mt-1">{{ $t('period') }}</label>
                <Dropdown v-model="task_schedule_period" :options="task_schedule_period_options" optionLabel="label" :placeholder="$t('period')"
                  style="width:100%;">
                </Dropdown>
              </div>
            </div>
            
            <hr class="mt-4 mb-0 ml-0 mr-0">
            <div class="row">
              <div class="col-6 pl-3 pr-3">
                <label for="" class="pl-0 pb-0 mb-0 mt-1">{{ $t('startsAfter') }}</label>
                <Calendar v-model="task_start_after" :placeholder="$t('start_after')" style="width:100%;">
                </Calendar>
              </div>
              <div class="col-6 pl-3 pr-3">
                <label for="" class="pl-0 pb-0 mb-0 mt-1">{{ $t('expires') }}</label>
                <Calendar v-model="task_expires" :placeholder="$t('expires')" style="width:100%;">
                </Calendar>
              </div>
            </div>
            <hr class="mt-4 mb-0 ml-0 mr-0">
            <div class="row">
              <div class="col-6 pl-3 pr-3">
                <label for="" class="pl-0 pb-0 mb-0 mt-1">{{ $t('total_run_count') }}</label>
                <InputText disabled type="text" v-model="task_total_run_count" :placeholder="$t('total_run_count')" style="width:100%;">
                </InputText>
                <label for="" class="pl-0 pb-0 mb-0 mt-1">{{ $t('object_id') }}</label>
                <InputText disabled type="text" v-model="task_object_id" :placeholder="$t('object_id')" style="width:100%;">
                </InputText>
              </div>
              <div class="col-6 pl-3 pr-3">
                <label for="" class="pl-0 pb-0 mb-0 mt-1">{{ $t('max_run_count') }}</label>
                <InputNumber type="text" v-model="task_max_run_count" :placeholder="$t('max_run_count')" style="width:100%;">
                </InputNumber>
                <label for="" class="pl-0 pb-0 mb-0 mt-1">{{ $t('object_type') }}</label>
                <InputText disabled type="text" v-model="task_object_type" :placeholder="$t('object_type')" style="width:100%;">
                </InputText>
              </div>
            </div>

          </div>
        </div>
      </template>
      <template #footer>
        <Button class="btn-custom" :label="$t('save')" @click="updateTask(task_id)"></Button>
      </template>
    </Modal>

  </div>
</template>
<!----------------------------------------------->
<!--                    SCRIPT                 -->
<!----------------------------------------------->

<script>
import MyShared from "./shared";
export default {
  mixins: [MyShared],
};

</script>

<script setup>
import { random } from "gsap";
import { useMq } from "vue3-mq";
const mq = useMq();
</script>

<!----------------------------------------------->
<!--                    STYLES                 -->
<!----------------------------------------------->
<style scoped>
</style>
