
<!----------------------------------------------->
<!--                  TEMPLATE                 -->
<!----------------------------------------------->
<template>
    <div class="row">
        <div class="col-md-12">
            <div class="swiper-container">
                <p class="swiper-control">
                    <button type="button" class="swiper-button-prev"></button>
                    <button type="button" class="swiper-button-next"></button>
                </p>
                <h5 class="timeline-title">{{ title }}</h5>
                <div class="swiper-wrapper timeline">
                    <div class="swiper-slide" v-for="item in steps">
                        <div class="timestamp">
                            <span class="date"> {{ formatDate(item.dateLabel) }} </span>
                        </div>
                        <div :class="((router_type != 'status')?'link ': '') + 'status  status_color_' + item.severity" style="cursor: pointer;">
                            <span v-tooltip.top="item.title" @click="(router_type != 'status')?routeTo(item.id):null">{{ formatString(item.title, 15) }}</span>
                        </div>
                    </div>
                </div>
                <!-- Add Pagination -->
                <div class="swiper-pagination"></div>
            </div>
        </div>
    </div>
</template>
<!----------------------------------------------->
<!--                    SCRIPT                 -->
<!----------------------------------------------->
<script>
// import Swiper bundle with all modules installed
import Swiper from 'swiper/bundle';

// import styles bundle
import 'swiper/css/bundle';

export default {
    /* Name */
    name: "CustomChart",
    inject: ["mq"],
    /* Props*/
    props: {
        data: [],
        title: String,
        router_type: String,
    },
    data() {
        return {
            steps: []
        }
    },
    methods: {
        routeTo(id) {
            this.$router.push({ path: this.router_type+'-details', query: { id: id } })
        },
        formatDate(date) {
            // format date to dd/mm/yyyy hh:mm
            return new Date(date).toLocaleString('en-GB');
        },
    },
    mounted() {
        this.steps = this.data;
        var swiper = new Swiper('.swiper-container', {
            //pagination: '.swiper-pagination',
            slidesPerView: 5,
            initialSlide: 999,
            paginationClickable: true,
            grabCursor: true,
            paginationClickable: true,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
        });
    },

};
</script>

<script setup>
import { useMq } from "vue3-mq";
const mq = useMq();
</script>

<!----------------------------------------------->
<!--                    STYLES                 -->
<!----------------------------------------------->
<style scoped >
.link:hover {
    color: #ec635f;
    cursor: pointer;
    text-decoration: underline;
}
.timeline {
    margin: 50px 0;
    list-style-type: none;
    display: flex;
    padding: 0;
    text-align: center;
}

.timeline li {
    transition: all 200ms ease-in;
}

.timestamp {
    /*width: 200px;*/
    margin-bottom: 20px;
    /* padding: 0px 40px;*/
    padding: 0px 20px;
    display: flex;
    flex-direction: column;
    align-items: /*center*/ baseline;
    font-weight: 100;
}

.status {
    padding: 0px 40px;
    display: flex;
    justify-content: center;
    border-top: 4px solid #9b9898;
    position: relative;
    transition: all 200ms ease-in;
}

.status span {
    font-weight: 600;
    padding-top: 20px;
}

.status span:before {
    content: '';
    width: 25px;
    height: 25px;
    background-color: #e8eeff;
    border-radius: 25px;
    border: 4px solid #ec635f;
    position: absolute;
    top: -15px;
    left: 42%;
    transition: all 200ms ease-in;
}

.status_color_0 span:before{
    border: 4px solid #aeb5c1 !important;
}
.status_color_1 span:before{
    border: 4px solid #6dc193 !important;
}
.status_color_2 span:before{
    border: 4px solid #f7b34c !important;
}
.status_color_3 span:before{
    border: 4px solid #ef6154 !important;
}
.status_color_4 span:before{
    border: 4px solid #b83c32 !important;
}

.status_color_new span:before{
    border: 4px solid #f28263 !important;
}
.status_color_mitigated span:before{
    border: 4px solid #f6a437 !important;
}
.status_color_partial_mitigated span:before{
    border: 4px solid #ffd366 !important;
}
.status_color_active span:before{
    border: 4px solid #a3dfc9 !important;
}
.status_color_closed span:before{
    border: 4px solid #b0b0b0 !important;
}

.swiper-control {
    text-align: right;
}

.swiper-container {
    width: 100%;
    height: 200px;
    margin: 0px 0; /* margin: 50px 0; */
    overflow: hidden;
    padding: 0 20px 30px 20px;
}
.swiper-wrapper{
    margin-top: 25px;
}

.swiper-slide {
    width: 200px;
    text-align: center;
    font-size: small;
}

.swiper-slide:nth-child(2n) {
    width: 40%;
}

.swiper-slide:nth-child(3n) {
    width: 20%;
}
.swiper-button-prev, .swiper-button-next{
    margin-top: -2em !important;
}
.swiper-button-next::after, .swiper-button-prev::after{
    font-size: 1em !important;
}
</style>
