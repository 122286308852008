<!----------------------------------------------->
<!--                  TEMPLATE                 -->
<!----------------------------------------------->
<template>
  <MobileCorrelationDetails v-if="  mq.current  === 'mobile'"></MobileCorrelationDetails>
  <TabletCorrelationDetails v-if="  mq.current  === 'tablet'"></TabletCorrelationDetails>
  <DesktopCorrelationDetails v-if=" mq.current  === 'desktop'"></DesktopCorrelationDetails>
</template>

<!----------------------------------------------->
<!--                    SCRIPT                 -->
<!----------------------------------------------->
<script>
import MobileCorrelationDetails from "../components/CorrelationDetails/MobileCorrelationDetails";
import TabletCorrelationDetails from "../components/CorrelationDetails/TabletCorrelationDetails";
import DesktopCorrelationDetails from "../components/CorrelationDetails/DesktopCorrelationDetails";

export default {
  /* Name */
  name: "CorrelationDetails",
  inject: ["mq"],
  /* Components */
  components: {
    MobileCorrelationDetails,
    TabletCorrelationDetails,
    DesktopCorrelationDetails,
  },
};
</script>

<script setup>
import { useMq } from "vue3-mq";
const mq = useMq();
</script>

<!----------------------------------------------->
<!--                    STYLES                 -->
<!----------------------------------------------->
<style scoped >
</style>
