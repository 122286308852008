import $axios from '../utils';

export function getAllEvents(full = true, sdate = '1975-01-01T00:00:00', edate = '3000-01-01T00:00:00', offset = 0, limit = 10000) {
    return $axios.get(`soar/event/?full=${full}&offset=${offset}&limit=${limit}&sdate=${sdate}&edate=${edate}`)
        .then(response => response.data)
}
export function getEventsBy(key, value, full, offset, limit) {
    return $axios.get(`soar/event/by?key${key}&value=${value}&full=${full}&offset=${offset}&limit=${limit}`)
        .then(response => response.data)
}
export function getOneEvent(id, full) {
    return $axios.get(`soar/event/${id}?full=${full}`)
        .then(response => response.data)
}
export function createEvent(data) {
    return $axios.post('soar/event/', data)
        .then(response => response.data)
}
export function updateEvent(id, data) {
    return $axios.put(`soar/event/${id}`, data)
        .then(response => response.data)
}
export function deleteAllEvents() {
    return $axios.delete('soar/event/')
        .then(response => response.data)
}
export function deleteEvent(id) {
    return $axios.delete(`soar/event/${id}`)
        .then(response => response.data)
}