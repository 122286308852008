<!----------------------------------------------->
<!--                  TEMPLATE                 -->
<!----------------------------------------------->
<template>
  <div class="aux">
  <Sitebar></Sitebar>
  <div class="maincontainer" >
    <ConfirmDialog></ConfirmDialog>
    <!-- ==================================================================================================================================================== -->
    <!-- =================================================================== Top Buttons ==================================================================== -->
    <!-- ==================================================================================================================================================== -->
    <div class="row">
      <div class="col-4 p-0"></div>
        <div class="col-8 top-config">
        <i class="pi pi-replay top-config-icon" @click="$router.go()"><span class="top-config-label">&nbsp;{{
            $t('refresh')
        }}</span></i>
        <div class="dropdown">
          <i class="pi pi-clock top-config-icon dropdown-toggle" data-toggle="dropdown"><span
              class="top-config-label">&nbsp;{{ lastHours }}</span></i>
          <div class="dropdown-menu" aria-labelledby="navbarDropdown">
            <a class="dropdown-item" v-for="hour in hours" :key="hour" @click="setLastHours(hour)">
              {{ hour }}
            </a>
          </div>
        </div>
        <div class="dropdown">
          <i class="pi pi-cog top-config-icon dropdown-toggle" data-toggle="dropdown"><span
              class="top-config-label">&nbsp;{{ $t('actions') }}</span></i>
          <div class="dropdown-menu" aria-labelledby="navbarDropdown" style="width:max-content">
            <a class="dropdown-item" @click="editMode = false" v-if="editMode">
              <p><i class="pi pi-times"></i>&nbsp;{{ $t('closeEditMode') }}</p>
            </a>
            <a class="dropdown-item" v-for="(action, index) in actions" :key="action" @click="action.callback()">
              <p><i :class="action.icon"></i>&nbsp;{{ action.label }}</p>
            </a>
          </div>
        </div>
      </div>
    </div>
    <!-- ==================================================================================================================================================== -->
    <!-- ======================================================================= Top ======================================================================= -->
    <!-- ==================================================================================================================================================== -->
    <div class="row" style="width:100%">
      <div class="col-12">
        <Card>
          <template #content>
            <div class="row pl-4">
              <div class="col-2 pt-0" v-if="!loading_table">
                <i class="fa-solid fa-eye top-tab-icon"></i>
                <span class="top-tab-text">
                  {{ data.length }}
                  <!--
                  <i class="fa-solid fa-caret-up top-tab-subicon">
                    <span class="top-tab-subicon-text">21</span>
                  </i>
                  -->
                </span>
                <p class="top-tab-subtext mb-0">{{ $t('assetTotal') }} </p>
              </div>
              <div class="col-2" v-else><ContentLoader></ContentLoader></div>
              <div class="col-2 pt-0" v-if="!loading_table">
                <i class="fa-solid fa-layer-group top-tab-icon"></i>
                <span class="top-tab-text">
                  {{ groups.length }}
                  <!--
                  <i class="fa-solid fa-caret-up top-tab-subicon">
                    <span class="top-tab-subicon-text">21</span>
                  </i>
                  -->
                </span>
                <p class="top-tab-subtext mb-0">{{ $t('groupsNumber') }} </p>
              </div>
              <div class="col-2" v-else><ContentLoader></ContentLoader></div>
              <div class="col-2 pt-0" v-if="!loading_table">
                <i class="fa-solid fa-power-off top-tab-icon"></i>
                <span class="top-tab-text">
                  {{ powerOnAssets }}
                  <!--
                  <i class="fa-solid fa-caret-up top-tab-subicon">
                    <span class="top-tab-subicon-text">21</span>
                  </i>
                  -->
                </span>
                <p class="top-tab-subtext mb-0">{{ $t('onAssets') }} </p>
              </div>
              <div class="col-2" v-else><ContentLoader></ContentLoader></div>
              <div class="col-6" v-if="!loading_table">
                <strong class="chart-label">{{ $t('assetsByCriticality') }}</strong>
                <div class="percentage-bar">
                  <div class="percentage-portion"
                    :style="'display:inline-block; background:#6dc193; width:' + (criticality_1) + '%;'"
                    v-if="criticality_1 != -0.75">&nbsp;</div>
                  <div class="percentage-portion" :style="'display:inline-block; background:white; width:1%;'"
                    v-if="(criticality_1 != -0.75 && criticality_2 != -0.75) || (criticality_1 != -0.75 && criticality_3 != -0.75 && criticality_2 == -0.75) || (criticality_1 != -0.75 && criticality_4 != -0.75 && criticality_2 == -0.75 && criticality_3 == -0.75)">
                  </div>
                  <div class="percentage-portion"
                    :style="'display:inline-block; background:#f7b34c; width:' + (criticality_2) + '%;'"
                    v-if="criticality_2 != -0.75">&nbsp;</div>
                  <div class="percentage-portion" :style="'display:inline-block; background:white; width:1%;'"
                    v-if="(criticality_2 != -0.75 && criticality_3 != -0.75) || (criticality_2 != -0.75 && criticality_4 != -0.75 && criticality_3 == -0.75)">
                  </div>
                  <div class="percentage-portion"
                    :style="'display:inline-block; background:#ef6154; width:' + (criticality_3) + '%;'"
                    v-if="criticality_3 != -0.75">&nbsp;</div>
                  <div class="percentage-portion" :style="'display:inline-block; background:white; width:1%;'"
                    v-if="criticality_3 != -0.75 && criticality_4 != -0.75"></div>
                  <div class="percentage-portion"
                    :style="'display:inline-block; background:#b83c32; width:' + (criticality_4) + '%;'"
                    v-if="criticality_4 != -0.75">&nbsp;</div>
                </div>
                <div class="percentage-bar row">
                  <div class="pt-0 pb-0" style="display:ruby; width:auto;"><i class="pi pi-circle-fill" style="color:#6dc193; font-size: 0.7em;"></i>&nbsp;{{$t('low')}}: <strong>{{number_of_low_criticality}}</strong></div>
                  <div class="pt-0 pb-0" style="display:ruby; width:auto;"><i class="pi pi-circle-fill" style="color:#f7b34c; font-size: 0.7em;"></i>&nbsp;{{$t('medium')}}: <strong>{{number_of_medium_criticality}}</strong></div>
                  <div class="pt-0 pb-0" style="display:ruby; width:auto;"><i class="pi pi-circle-fill" style="color:#ef6154; font-size: 0.7em;"></i>&nbsp;{{$t('high')}}: <strong>{{number_of_high_criticality}}</strong></div>
                  <div class="pt-0 pb-0" style="display:ruby; width:auto;"><i class="pi pi-circle-fill" style="color:#b83c32; font-size: 0.7em;"></i>&nbsp;{{$t('critical')}}: <strong>{{number_of_critical_criticality}}</strong></div>
                </div>
              </div>
              <div class="col-6" v-else><ContentLoader height="90px" width="90%"></ContentLoader></div>
            </div>
          </template>
        </Card>
      </div>
      <!-- ==================================================================================================================================================== -->
      <!-- ===================================================================== EditMenu ===================================================================== -->
      <!-- ==================================================================================================================================================== -->
        <div v-if="editMode" class="col-12 ">
          <Card>
            <template #content >
              <div class="row pl-2">
                <p><strong>{{$t('editOptions')}}</strong></p>
                <div class="col-12">
                  <i class="pi pi-times mr-3" style="position: absolute; top:0; right:0; margin-top: -20px; cursor: pointer;" @click="editMode = false"></i>
                  <Button class="btn-custom-save" icon="pi pi-trash" :label="$t('deleteSelected')" @click="deleteData()"></Button>
                </div>
              </div>
            </template>
          </Card>
        </div>
      <!-- ==================================================================================================================================================== -->
      <!-- ======================================================================= Table ======================================================================= -->
      <!-- ==================================================================================================================================================== -->
      <div :class="tab_position ? 'col-8' : 'col-12 mb-0'">
        <div class="pad-helper main-table pt-2 pr-2 pl-2 m-0 pb-3" style="height:100%">
          <span class="p-input-icon-left" style="width:100%;">
            <InputText v-model="filters['global'].value" :placeholder="$t('searchByAsset')" class="search-input mb-2">
            </InputText>
            <MultiSelect v-model="selectedGroups" :options="groups" option-label="name"
              :placeholder="$t('filterByGroup')" display="chip" style="float:right; width:25em;"
              @change="filterByGroup(selectedGroups, false)" class="mb-2"></MultiSelect>
          </span>
          <div class="loader text-center mt-2 mt-2" style="width:100%;" v-if="loading_table">
            <ContentLoader width="100%"></ContentLoader>
          </div>
          <DataTable v-else :value="data" :paginator="true" class="p-datatable-customers" :rows="15"
              :row-hover="true" v-model:selection="selectedData" dataKey="id" v-model:filters="filters"
              filter-display="menu" :loading="loading_table" :selectionMode="(!editMode)?'single':''"
              paginator-template="PrevPageLink PageLinks NextPageLink CurrentPageReport"
              :rows-per-page-options="[10, 25, 50]"
              current-page-report-template="Showing {first} to {last} of {totalRecords} entries"
              :global-filter-fields="['id', 'name', 'os', 'hostname', 'type', 'criticality', 'last_date', 'risk']"
              responsive-layout="scroll" @row-click="setSelectedData($event.data)">

              <template #empty>
                {{$t('noDataFound')}}
              </template>
              <template #loading>
               {{$t('loadingData')}}
              </template>
              <Column selection-mode="multiple" v-if="editMode"></Column>
              <Column field="turn_on" header="Power">
                <template #body="{ data }">
                  <span v-if="!checkDate(data.last_date)"><i class="fa-solid fa-power-off" style="color:#ef6154"></i>&nbsp;{{
                  }}</span>
                  <span v-if="checkDate(data.last_date)"><i class="fa-solid fa-power-off" style="color:#6dc193"></i>&nbsp;{{
                  }}</span>
                </template>
              </Column>
              <Column field="name" :header="$t('name')" sortable>
                <template #body="{ data }">
                  <span @click="$router.push({ path: 'asset-details', query: { id: data.id } })"
                    class="row-name-link">{{
                        formatString(data.name)
                    }} </span>
                </template>
              </Column>
              <Column field="os" :header="$t('OS')" sortable>
                <template #body="{ data }">
                  {{ data.os }}
                </template>
              </Column>
              <Column field="type" :header="$t('type')" sortable>
                <template #body="{ data }">
                  {{ data.type }}
                </template>
              </Column>
              <Column field="location" :header="$t('location')" sortable>
                <template #body="{ data }">
                  {{ data.location.city }}, {{ data.location.country }}
                </template>
              </Column>
              <!--
              <Column field="criticality" :header="$t('criticality')" sortable>
                <template #body="{ data }">
                  <span v-if="data.criticality == 1"><i class="pi pi-circle-fill  criticality-filter-Low"
                      style="font-size: 0.7em;"></i>&nbsp;{{ $t('low') }}</span>
                  <span v-if="data.criticality == 2"><i class="pi pi-circle-fill  criticality-filter-Medium"
                      style="font-size: 0.7em;"></i>&nbsp;{{ $t('medium') }}</span>
                  <span v-if="data.criticality == 3"><i class="pi pi-circle-fill  criticality-filter-High"
                      style="font-size: 0.7em;"></i>&nbsp;{{ $t('high') }}</span>
                  <span v-if="data.criticality == 4"><i class="pi pi-circle-fill  criticality-filter-Critical"
                      style="font-size: 0.7em;"></i>&nbsp;{{ $t('critical') }}</span>
                </template>
              </Column>
              -->
              <Column field="last_date" :header="$t('lastActDate')" sortable>
                <template #body="{ data }">
                  {{ formatDate(data.last_date) }}
                </template>
              </Column>
              <Column field="last_date" :header="$t('explore')" sortable body-style="text-align: center;">
                <template #body="{ data }">
                  <Button class="btn-custom m-0" icon="pi pi-eye" @click="openRecordingModal(data.id);"></Button>
                </template>
              </Column>
              
            </DataTable>
        </div>
      </div>

      <!-- ==================================================================================================================================================== -->
      <!-- ======================================================================= Tabs ======================================================================= -->
      <!-- ==================================================================================================================================================== -->

      <div :class="tab_position ? 'col-4 pl-0' : 'col-12'" :style="tab_position ? 'height:auto;' : 'height:35vh;'"
      v-if="viewmode == 0">
        <div class="pad-helper main-table pt-2 pr-2 pl-2 m-0" style="height:100%;">
          <div class="row">
            <div class="change-pos-div">
              <img v-if="tab_position" style="float:right; cursor:pointer; height:1.25em"
                @click="tab_position = !tab_position" :src="require('@/assets/icon-bottom.svg')">
              <img v-if="!tab_position" style="float:right; cursor:pointer; height:1.25em"
                @click="tab_position = !tab_position" :src="require('@/assets/icon-right.svg')">
              <!-- 
                <i v-if="tab_position" class="fa-solid fa-turn-down icon-flipped" style="float:right; cursor:pointer;"
                  @click="tab_position = !tab_position"></i>
                <i v-if="!tab_position" class="fa-solid fa-turn-up" style="float:right; cursor:pointer;"
                  @click="tab_position = !tab_position"></i>
                -->
            </div>
          </div>
          <div class="loader text-center mt-4" style="width:100%;" v-if="loading_table">
            <ContentLoader width="100%"></ContentLoader>
          </div>
          <div v-else class="row">
            <TabView v-if="details">
              <!-- ====================================================================== Details ============================================================================== -->
              <TabPanel :header="$t('details')">
                <div class="loader text-center mt-2" style="width:100%;" v-if="loading_details">
                  <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                  <p>{{ $t('loading') }}</p>
                </div>
                <ScrollPanel :style="tab_position ? 'width: 100%; height:auto' : 'width: 100%; height:15em'"
                  class="custom" v-else>
                  <div class="sub-scroll pl-3 pr-3 pb-4" :style="tab_position ? 'height:auto;' : 'height:25em;'">
                    <div class="row">
                      <button class="btn-custom mr-4" @click="openRecordingModal(details.id);" style="position:absolute; top: 0; right:0;">{{$t('explore')}}</button>
                    </div>
                    <div class="row details-top">
                      <i class="fa-solid fa-yaveremos detail-icon"><span class="detail-name">{{ details.name
                      }}</span></i>
                      <p class="mb-0 pl-0"
                        @click="$router.push({ path: 'asset-details', query: { id: details.id } })">
                        <span class="id-prefix" style="text-decoration:none;">Asset ID:</span> <span class="details-id">
                          {{ details.id }}
                        </span>
                      </p>
                    </div>
                    <div class="row details-data">
                      <div class="col-4 p-0">
                        <Card>
                          <template #content>
                            <i class="fa-solid fa-tag subdetail-icon">
                              <p class="subdetail-name">&nbsp;{{ details.type }}</p>
                              <p class="subdetail-tag">&nbsp;{{ $t('type') }}</p>
                            </i>
                          </template>
                        </Card>
                      </div>
                      <div class="col-4 p-0">
                        <Card>
                          <template #content>
                            <i class="fa-solid fa-location-dot subdetail-icon">
                              <p class="subdetail-name">&nbsp;{{ details.location.city }}</p>
                              <p class="subdetail-tag">&nbsp;{{ $t('location') }}</p>
                            </i>
                          </template>
                        </Card>
                      </div>
                      <div class="col-4 p-0 dropdown">
                        <Card data-toggle="dropdown">
                          <template #content>
                            <i v-if="details.criticality == 1" class="fa-solid fa-shield-halved subdetail-icon"
                              style="color:#6dc193">
                              <p class="subdetail-name dropdown-toggle" v-if="details.criticality == 1">&nbsp;{{
                                  $t('low')
                              }}</p>
                              <p class="subdetail-tag">&nbsp;{{ $t('criticality') }}</p>
                            </i>
                            <i v-if="details.criticality == 2" class="fa-solid fa-shield-halved subdetail-icon dropdown"
                              style="color:#f7b34c">
                              <p class="subdetail-name dropdown-toggle" v-if="details.criticality == 2">&nbsp;{{
                                  $t('medium')
                              }}</p>
                              <p class="subdetail-tag">&nbsp;{{ $t('criticality') }}</p>
                            </i>
                            <i v-if="details.criticality == 3" class="fa-solid fa-shield-halved subdetail-icon dropdown"
                              style="color:#ef6154">
                              <p class="subdetail-name dropdown-toggle" v-if="details.criticality == 3">&nbsp;{{
                                  $t('high')
                              }}</p>
                              <p class="subdetail-tag">&nbsp;{{ $t('criticality') }}</p>
                            </i>
                            <i v-if="details.criticality == 4" class="fa-solid fa-shield-halved subdetail-icon dropdown"
                              style="color:#b83c32">
                              <p class="subdetail-name dropdown-toggle" v-if="details.criticality == 4">&nbsp;{{
                                  $t('critical')
                              }}
                              </p>
                              <p class="subdetail-tag">&nbsp;{{ $t('criticality') }}</p>
                            </i>
                          </template>
                        </Card>
                        <div class="dropdown-menu">
                          <a class="dropdown-item" v-for="criticality in criticalities" :key="criticality"
                            @click="updateCriticality(details.id, criticality.value)">
                            {{ criticality.label }}
                          </a>
                        </div>
                      </div>
                    </div>
                    <div class="row details-data">
                      <div :class="tab_position ? 'col-12 pr-0 pl-0' : 'col-6 pr-4'">
                        <div class="row">
                          <p class="mt-0 mb-0 details-title"><strong>{{ $t('description') }}</strong></p>
                          <p class="">{{ details.description }}</p>
                        </div>
                        <div class="row">
                          <p class="mb-0 mt-2  details-title"><strong>{{ $t('hostName') }}</strong></p>
                          <p class="mb-0 mb-2">{{ details.hostname }}</p>
                        </div>
                        <div class="row">
                          <p class="mb-0 mt-2 details-title"><strong>{{ $t('tags') }}</strong></p>
                          <div class="tag-cont">
                            <p class="details-tag" v-for="tag in details.tags" :key="tag"
                              style="display:inline-block;">{{ tag }}&nbsp;</p>
                          </div>
                        </div>
                      </div>
                      <div :class="tab_position ? 'col-12 pr-0 pl-0' : 'col-6 pr-4'">
                        <div class="row">
                          <p class="mb-0 mt-2 details-title"><strong>{{ $t('network') }}</strong></p>
                          <div class="ip-cont">
                            <p class="mb-0" style="font-size:small; color: darkgray;"><strong>IPs:&nbsp;</strong>
                            </p>
                            <div class="tag-cont">
                              <p class="details-tag" v-for="ip in details.ip_addr" :key="ip"
                                style="display:inline-block;">{{ ip }}&nbsp;</p>
                            </div>
                          </div>
                          <br>
                          <div class="mac-cont">
                            <p class="mb-0" style="font-size:small; color: darkgray;"><strong>MACs:&nbsp;</strong>
                            </p>
                            <div class="tag-cont mb-2">
                              <p class="details-tag" v-for="mac in details.mac_addr" :key="mac"
                                style="display:inline-block;">{{ mac }}&nbsp;</p>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-6">
                            <p class="mb-0 mt-2 details-title"><strong>{{ $t('createdAt') }}</strong></p>
                            <p class="">{{ formatDate(details.first_date) }}</p>
                          </div>
                          <div class="col-6">
                            <p class="mb-0 mt-2 details-title"><strong>{{ $t('lastActDate') }}</strong></p>
                            <p class="">{{ formatDate(details.last_date) }}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ScrollPanel>
              </TabPanel>
            </TabView>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--============================================================================== showRecording ==============================================================================-->
  <Modal ref="exploreDesktop">
    <template #body>
      <div class="mt-4">
        
        <h6 class="">Showing <strong>{{ details.name }}</strong> from: <strong v-if="!loading_video && !recording_err">{{ actual_start_date }}</strong> <span v-if="loading_video && !recording_err"><ContentLoader width="5em" height="1.5em"></ContentLoader></span> to <strong v-if="!loading_video && !recording_err">{{ actual_end_date }}</strong><span v-if="loading_video && !recording_err"><ContentLoader width="5em" height="1.5em"></ContentLoader></span> <span v-if="!showDateConfig" @click="showDateConfig = true;" class="row-name-link">{{ $t('changeThis') }}</span><span v-if="showDateConfig" @click="showDateConfig = false" class="row-name-link">{{ $t('close') }}</span></h6>
        <div class="aux" v-if="showDateConfig" >
          <div class="row mb-0">
            <div class="col-5 pt-0">
              <label for="" class="col-12 p-0 m-0">{{ $t('startDate') }}</label>
              <Calendar v-model="actual_start_date" style="width: 100%;" :showTime="true" dateFormat="yy/mm/dd"/>
            </div>
            <div class="col-5 pt-0">
              <label for="" class="col-12 p-0 m-0">{{ $t('endDate') }}</label>
              <Calendar v-model="actual_end_date" style="width: 100%;" :showTime="true" dateFormat="yy/mm/dd"/>
            </div>
            <div class="col-2 pt-0">
              <label for="" class="col-12 p-0 m-0"> </label>
              <Button class="btn-custom m-0" :label="$t('save')" @click="setNewDates(actual_start_date, actual_end_date)" style="width: 100%;"></Button>
            </div>
          </div>
        </div>
        <div class="row video-container" v-if="!loading_video && !recording_err">
          <vue3-video-player  class="mt-2" :src="recording_src" @timeupdate="(event) => getCurrentTime(event.target)" :muted="true" @loadeddata="(event) => setVideo(event.target)" autoplay ></vue3-video-player>
        </div>
        
        <div v-if="loading_video && !recording_err" class="mt-2">
          <div class="row">
            <div class="col-12">
              <ContentLoader width="100%" height="25em"></ContentLoader>
            </div>
          </div>
        </div>
        <div class="row" v-if="recording_err && !loading_video">
            <div class="col-12 text-center">
              <h6 style="color:#ef6154" class="mt-2">{{ $t('NotVideoForThisDates') }}</h6>
            </div>
          </div>
        

        <h6 class="mt-3 p-0">{{ $t('newCheckpoint') }}</h6>
        <hr class="mt-0 mb-0">
        <div class="row pl-3" style="width: 100%;">
          <InputText class="mt-2" :placeholder="$t('checkpointName')" v-model="checkpoint_name"></InputText>
          <Button class="btn-custom" :label="$t('setCheckpoint')" @click="setCheckpoint(checkpoint_name, currentTime)" style="float:right"></Button>
        </div>

        <div class="row pl-3 pr-4">
          <h6 class="m-0 p-0">{{ $t('checkpoints') }}</h6>
          <hr class="mt-0">
          <DataTable :value="checkpoints" :paginator="true" class="p-datatable-customers p-0" :rows="5"
              :row-hover="true" dataKey="id" v-model:filters="filters"
              filter-display="menu" :loading="loading_table"
              responsive-layout="scroll">

              <template #empty>
                {{$t('noDataFound')}}
              </template>
              <template #loading>
               {{$t('loadingData')}}
              </template>
              <Column field="name" :header="$t('name')" sortable>
                <template #body="{ data }">
                  <span class="row-name-link" @click="moveToSecond(data.sec)">{{
                        data.name
                    }} </span>
                </template>
              </Column>
              <Column field="sec" :header="$t('time')" sortable>
                <template #body="{ data }">
                  {{ data.time_label }}
                </template>
              </Column>
              <Column field="real_time_label" :header="$t('date')" sortable>
                <template #body="{ data }">
                  {{ data.real_time_label }}
                </template>
              </Column>
            </DataTable>
        </div>
        
      </div>      
    </template>
    <template #footer>
      <Button class="btn-custom" :label="$t('close')" @click="$refs.exploreDesktop.closeModal()"></Button>
    </template>
  </Modal>
</div>
</template>
<!----------------------------------------------->
<!--                    SCRIPT                 -->
<!----------------------------------------------->

<script>
import MyShared from "./shared";
export default {
mixins: [MyShared],
};

</script>

<script setup>
import { useMq } from "vue3-mq";
const mq = useMq();
</script>

<!----------------------------------------------->
<!--                    STYLES                 -->
<!----------------------------------------------->
<style>
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

/* Custom buttons */
.btn-custom {
  margin: auto;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  background: #ec635f !important;
  color: white;
  border: none !important;
  padding: 0.25rem 0.5rem !important;
  font-weight: normal !important;
  width: auto !important;
  font-size: small !important;
}

.btn-custom:focus {
  box-shadow: 0px 0px 2px #ec635f !important;
}

.vcp-container video {
  display: block;
}
.video-container{
  width: 100%;
  height: 30em;
}
</style>
