<!----------------------------------------------->
<!--                  TEMPLATE                 -->
<!----------------------------------------------->
<template>
  <MobileManageOrganizations v-if="  mq.current  === 'mobile'"></MobileManageOrganizations>
  <TabletManageOrganizations v-if="  mq.current  === 'tablet'"></TabletManageOrganizations>
  <DesktopManageOrganizations v-if=" mq.current  === 'desktop'"></DesktopManageOrganizations>
</template>

<!----------------------------------------------->
<!--                    SCRIPT                 -->
<!----------------------------------------------->
<script>
import MobileManageOrganizations from "../components/ManageOrganizations/MobileManageOrganizations";
import TabletManageOrganizations from "../components/ManageOrganizations/TabletManageOrganizations";
import DesktopManageOrganizations from "../components/ManageOrganizations/DesktopManageOrganizations";

export default {
  /* Name */
  name: "ManageOrganizations",
  inject: ["mq"],
  /* Components */
  components: {
    MobileManageOrganizations,
    TabletManageOrganizations,
    DesktopManageOrganizations,
  },
};
</script>

<script setup>
import { useMq } from "vue3-mq";
const mq = useMq();
</script>

<!----------------------------------------------->
<!--                    STYLES                 -->
<!----------------------------------------------->
<style scoped >
</style>
