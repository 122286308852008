<!----------------------------------------------->
<!--                  TEMPLATE                 -->
<!----------------------------------------------->
<template>
  <div class="aux">
    <Sitebar></Sitebar>
    <div class="maincontainer" >
      <ConfirmDialog></ConfirmDialog>
      <div class="row main-table mb-2">
        <div class="col-7">
          <div class="p-inputgroup flex-1">
            <span class="p-inputgroup-addon">
              <i class="pi pi-search"></i>
            </span>
          <InputText v-model="search" placeholder="Search" style="width: 100%;" />
        </div>
        </div>
        <div class="col-4">
          <div class="p-inputgroup flex-1">
            <span class="p-inputgroup-addon">
              <i class="pi pi-calendar"></i>
            </span>
            <Calendar v-model="dates_range" selectionMode="range" :placeholder="$t('datesRange')" :manualInput="false"
              style="width: 100%;" />
          </div>
        </div>
        <div class="col-1 text-center">
          <Button :label="$t('refresh')" icon="pi pi-refresh" class="p-button-success" style="width: 100%;" />
        </div>
      </div>
      <div class="row">
        <div class="col-2 pt-0 pb-0 pl-0 pr-2">
          <div class="pad-helper main-table m-0 p-2" style="height:100%">
            <InputText v-model="search_in_fields" placeholder="Search" style="width: 100%;" />
            <label class="mt-2 mb-0"><i :class="(selectedOpen) ? 'pi pi-chevron-up' : 'pi pi-chevron-down'"
                @click="selectedOpen = !selectedOpen" style="font-size: smaller;"></i>&nbsp;{{ $t('selectedFields')
                }}</label>
            <hr class="mt-0 mb-2">
            <span v-if="selectedOpen && selectedCount == 0"><small>{{ $t('noneFieldSelected') }}</small></span>
            <div v-for="field of filter_fields" :key="field" class="flex align-items-center" v-if="selectedOpen">
              <span v-if="field.selected && (field.name.includes(search_in_fields) || search_in_fields == '')">
                <!-- <Checkbox v-model="field.selected" :inputId="field" name="category" :value="field.selected" />-->
                <label class="mb-1 mt-1 ml-1" :for="field"
                  @click="field.selected = !field.selected; checkSelectedNumber()" style="cursor:pointer;">
                  <Avatar :icon="'pi ' + checkType(field.name)"
                    :style="'height: 1.5em; width: 1.5em; font-size: smaller; background-color: ' + setColor(field.name)[0] + '; border: ' + setColor(field.name)[2] + ' 1px solid; color: ' + setColor(field.name)[1] + ';'" />
                  &nbsp;{{ field.name }}
                </label>
              </span>
            </div>
            <label class="mt-2 mb-0"><i :class="(avaliableOpen) ? 'pi pi-chevron-up' : 'pi pi-chevron-down'"
                @click="avaliableOpen = !avaliableOpen" style="font-size: smaller;"></i>&nbsp;{{ $t('avaliableFields')
                }}</label>
            <hr class="mt-0 mb-2">
            <span v-if="avaliableOpen && avaliableCount == 0"><small>{{ $t('noneFieldAvaliable') }}</small></span>
            <div v-for="field of filter_fields" :key="field" class="flex align-items-center" v-if="avaliableOpen">
              <span v-if="!field.selected">
                <!-- <Checkbox v-model="field.selected" :inputId="field" name="category" :value="field.selected" />-->
                <label class="mb-1 mt-1 ml-1" :for="field"
                  @click="field.selected = !field.selected; checkSelectedNumber()" style="cursor:pointer;">
                  <Avatar :icon="'pi ' + checkType(field.name)"
                    :style="'height: 1.5em; width: 1.5em; font-size: smaller; background-color: ' + setColor(field.name)[0] + '; border: ' + setColor(field.name)[2] + ' 1px solid; color: ' + setColor(field.name)[1] + ';'" />
                  &nbsp;{{ field.name }}
                </label>
              </span>
            </div>
          </div>
        </div>
        <div class="col-10 p-0">
          <div class="pad-helper main-table m-0 p-2">
            <Chart type="bar" :data="chartData" :options="chartOptions" />
          </div>
          <div class="pad-helper main-table mt-2 p-2">
            <DataTable :value="data" :paginator="true" class="p-datatable-customers" :rows="13" :row-hover="true"
              v-model:selection="selectedData" dataKey="id" v-model:filters="filters" filter-display="menu"
              :loading="loading_table" :selectionMode="(!editMode) ? 'single' : ''"
              paginator-template="PrevPageLink PageLinks NextPageLink CurrentPageReport"
              :rows-per-page-options="[10, 25, 50]"
              current-page-report-template="Showing {first} to {last} of {totalRecords} entries"
              :global-filter-fields="['id', 'name', 'os', 'hostname', 'type', 'criticality', 'last_date', 'risk']"
              responsive-layout="scroll" @row-click="setSelectedData($event.data)">

              <template #empty>
                {{ $t('noDataFound') }}
              </template>
              <template #loading>
                {{ $t('loadingData') }}
              </template>
              <Column selection-mode="multiple" v-if="editMode"></Column>
              <Column v-for="field in filterFields()" :key="field.name" :field="field.name" :header="field.name" sortable
                :style="(field.name.includes('id') && !field.name.includes('pid') && !field.name.includes('uid')) ? 'min-width: 20.5em;' : 'min-width: 15em;'">
                <template #body="{ index }">
                  <span v-if="(typeof (getValueFromData(field.name)) != 'object')"> {{ getValueFromData(field.name) }}
                  </span>
                  <span v-else>
                    <span v-if="!(expanded[field.name])[index]">
                      • {{ getValueFromData(field.name)[0] }} <span class="row-name-link"
                        @click="(expanded[field.name])[index] = true">{{ 'more...' }}</span>
                    </span>
                    <span v-else v-for="item in getValueFromData(field.name)" :key="item">
                      • {{ item }} <br>
                    </span>
                    <span v-if="(expanded[field.name])[index]" class="row-name-link"
                      @click="(expanded[field.name])[index] = false">{{ 'less...' }}</span>
                  </span>
              </template>
            </Column>
            <!--
              <Column field="id" header="id" sortable>
                <template #body="{ data }">
                  <span @click="$router.push({ path: 'asset-details', query: { id: data.id } })"
                    class="row-name-link">
                    {{data.id}}
                  </span>
                </template>
              </Column>
              <Column field="asset_id" header="asset_id" sortable>
                <template #body="{ data }">
                  <span @click="$router.push({ path: 'asset-details', query: { id: data.asset_id } })"
                    class="row-name-link">
                    {{data.asset_id}}
                  </span>
                </template>
              </Column>
              <Column field="source_id" header="source_id" sortable>
                <template #body="{ data }">
                  <span @click="$router.push({ path: 'source-details', query: { id: data.source_id } })"
                    class="row-name-link">
                    {{data.source_id}}
                  </span>
                </template>
              </Column>
              <Column field="log.asset.os" header="log.asset.id" sortable>
                <template #body="{ data }">
                    {{ data.log.asset.id }}
                </template>
              </Column>
              <Column field="log.asset.hostname" header="log.asset.hostname" sortable>
                <template #body="{ data }">
                  {{ data.log.asset.hostname }}
                </template>
              </Column>
              <Column field="log.asset.os" header="log.asset.os" sortable>
                <template #body="{ data }">
                  {{ data.log.asset.os }}
                </template>
              </Column>
              <Column field="log.asset.containerized" header="log.asset.containerized" sortable>
                <template #body="{ data }">
                  {{data.log.asset.containerized}}
                </template>
              </Column>
              <Column field="log.source" header="log.source" sortable>
                <template #body="{ data }">
                  {{data.log.source}}
                </template>
              </Column>
              <Column field="log.date" header="log.date" sortable>
                <template #body="{ data }">
                  {{timestampToDate(data.log.date)}}
                </template>
              </Column>
              <Column field="log.date_intake" header="log.date_intake" sortable>
                <template #body="{ data }">
                  {{timestampToDate(data.log.date_intake)}}
                </template>
              </Column>
              <Column field="log.msg.action" header="log.msg.action" sortable>
                <template #body="{ data }">
                  {{data.log.msg.action}}
                </template>
              </Column>
              <Column field="log.msg.result" header="log.msg.result" sortable>
                <template #body="{ data }">
                  {{data.log.msg.result}}
                </template>
              </Column>
              <Column field="log.msg.object" header="log.msg.object" sortable>
                <template #body="{ data }">
                  {{data.log.msg.object}}
                </template>
              </Column>
              <Column field="log.msg.uname" header="log.msg.uname" sortable>
                <template #body="{ data }">
                  {{data.log.msg.uname}}
                </template>
              </Column>
              <Column field="log.msg.uid" header="log.msg.uid" sortable>
                <template #body="{ data }">
                  {{data.log.msg.action}}
                </template>
              </Column>
              <Column field="log.msg.pid" header="log.msg.pid" sortable>
                <template #body="{ data }">
                  {{data.log.msg.pid}}
                </template>
              </Column>
              <Column field="log.msg.executable" header="log.msg.executable" sortable>
                <template #body="{ data }">
                  {{data.log.msg.executable}}
                </template>
              </Column>
              <Column field="log.msg.working_directory" header="log.msg.working_directory" sortable>
                <template #body="{ data }">
                  {{data.log.msg.working_directory}}
                    </template>
                  </Column>
                -->
            </DataTable>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<!----------------------------------------------->
<!--                    SCRIPT                 -->
<!----------------------------------------------->

<script>
import MyShared from "./shared";
export default {
  mixins: [MyShared],
};

</script>

<script setup>
import { useMq } from "vue3-mq";
const mq = useMq();
</script>

<!----------------------------------------------->
<!--                    STYLES                 -->
<!----------------------------------------------->
<style scoped>

.color-setter {
  background-color: #e2adad;
  border: #d38888 1px solid;
  color: #b15c5c;
}</style>
