<!----------------------------------------------->
<!--                  TEMPLATE                 -->
<!----------------------------------------------->
<template>
  <div class="aux">
    <Sitebar></Sitebar>
    <div class="maincontainer">
      <div class="ml-1 row search-integration-row">
        <div class="col-2 p-0 m-0">
          <InputText :placeholder="$t('search')" class="mb-2" v-model="search"></InputText>
        </div>
          <div class="form-check ml-4 checkbox">
            <input class="form-check-input" type="checkbox" value="" id="Security" v-model="security"
              @change="addOrRemove('Security')">
            <label class="form-check-label" for="Security">
              {{ $t('Security') }}
            </label>
          </div>
          <div class="form-check ml-2 checkbox">
            <input class="form-check-input" type="checkbox" value="" id="OS & System" v-model="os"
              @change="addOrRemove('OS & System')">
            <label class="form-check-label" for="OS & System">
              {{ $t('OS & System') }}
            </label>
          </div>
          <div class="form-check ml-2 checkbox">
            <input class="form-check-input" type="checkbox" value="" id="Web" v-model="web"
              @change="addOrRemove('Web')">
            <label class="form-check-label" for="Web">
              {{ $t('Web') }}
            </label>
          </div>
          <div class="form-check ml-2 checkbox">
            <input class="form-check-input" type="checkbox" value="" id="Database" v-model="database"
              @change="addOrRemove('Database')">
            <label class="form-check-label" for="Database">
              {{ $t('Database') }}
            </label>
          </div>
          <div class="form-check ml-2 checkbox">
            <input class="form-check-input" type="checkbox" value="" id="Network" v-model="network"
              @change="addOrRemove('Network')">
            <label class="form-check-label" for="Network">
              {{ $t('Network') }}
            </label>
          </div>
          <div class="form-check ml-2 checkbox">
            <input class="form-check-input" type="checkbox" value="" id="Azure" v-model="azure"
              @change="addOrRemove('Azure')">
            <label class="form-check-label" for="Azure">
              {{ $t('Azure') }}
            </label>
          </div>
          <div class="form-check ml-2 checkbox">
            <input class="form-check-input" type="checkbox" value="" id="Cloud" v-model="cloud"
              @change="addOrRemove('Cloud')">
            <label class="form-check-label" for="Cloud">
              {{ $t('Cloud') }}
            </label>
          </div>
          <Button :label="$t('createNewIntegration')" class="custom-btn mr-4" icon="pi pi-plus" @click="$refs.newIntegration.openModal()" style="float:right;" />
        
      </div>
      <div class="row">
        <div v-for="source in sources" :key="source" class="source-card-container">
          <Card class="source-card" @click="$router.push({ path: 'source-details', query: { id: source.id } })" style="cursor:pointer;"
            v-if="(source.name.toLowerCase().includes(search.toLowerCase()) && selected.length == 0) || (source.name.toLowerCase().includes(search.toLowerCase()) && selected.includes(source.category))">
            <template #content>
              <div class="source-cont text-center">
                <div class="row source-img-row pl-4 pr-4" v-if="source.logo"><img class="source-img" :src="getSourceLogo(source.logo)">
                </div>
                <!-- Div with the first character -->
                <div class="row source-img-row pl-4 pr-4" v-else>
                  <div :class="checkChar(source.name.substr(0, 1) )">
                    <p class="default-img-letter">{{ source.name.substr(0, 1) }}</p>
                  </div>
                </div>
                <div class="row">
                  <h6 class="source-title mt-4 pl-4 pr-4 text-center">
                    <strong v-if="source.name.length > 20">{{ source.name.substr(0, 20) }}...</strong>
                    <strong v-else>{{ source.name }}</strong>
                  </h6>
                  <h6 class="source-title text-center explore-label"
                    @click="$router.push({ path: 'source-details', query: { id: source.id } })">
                    <small>{{ $t('explore') }}</small>
                  </h6>
                </div>
              </div>
            </template>
          </Card>
        </div>
      </div>
    </div>

    <!--============================================================================== newIntegration ==============================================================================-->
    <Modal ref="newIntegration">
      <template #header>
      </template>
      <template #body>
        <div class="row">
          <div class="col-12 text-center pt-4">
            <i class="pi pi-th-large" style="font-size:2.5em;"></i>
          </div>
          <div class="col-12 text-center pt-0 pb-0">
            <h5 class="modal-title">{{ $t('createNewIntegration') }}</h5>
          </div>
        </div>
        <div class="row">
          <div class="col-12 p-4" v-if="!toggleIntakeConfig">
            <label for="" class="pl-0 pb-0 mb-0">{{ $t('name') }}</label>
            <InputText type="text" v-model="new_data_source_name" :placeholder="$t('integrationName')"
              style="width:100%;">
            </InputText>
            <label for="" class="pl-0 pb-0 mb-0 mt-1">{{ $t('description') }}</label>
            <Textarea type="text" v-model="new_data_source_description" :placeholder="$t('integrationDescription')"
              style="width:100%;">
            </Textarea>
            <label for="" class="pl-0 pb-0 mb-0 mt-1">{{ $t('integrationLogo') }}</label>
            <FileUpload name="demo[]" @upload="onAdvancedUpload($event)" :multiple="false" accept="image/*"
              :max-file-size="1000000">
              <template #content>
                <ul v-if="uploadedFiles && uploadedFiles[0]">
                  <li v-for="file of uploadedFiles[0]" :key="file">{{ file.name }} - {{ file.size }} bytes</li>
                </ul>
              </template>
              <template #empty>
                <p>Drag and drop files to here to upload.</p>
              </template>
            </FileUpload>
            <p style="color:#ec635f">*{{ $t('noConfigurationWarningForIntegrations') }}</p>
          </div>
          <div class="col-12 p-4" v-else>
            <div class="row ml-1 mr-1">
              <label for="" class="pl-0 pb-0 mb-0"><strong>{{ $t('selectIntakeMethods') }}</strong></label>
            </div>
            <div class="row ml-1 mr-1">
              <div class="col-12 p-0 m-0">
                <div class="card mt-2" v-for="intake in selectedIntakeMethods" :key="intake">
                <div class="card-body">
                  <InputSwitch v-model="intake.active" class="mt-1 mr-1" style="position: absolute; top:0; right:0; z-index:5" @click="updateSelectedIntakeMethods(intake.id)"/>
                  <h6 class="card-title mb-0">{{ intake.name }}</h6>
                  <p class="card-text m-0"><em>{{intake.nifi_listener}}</em></p>
                  <p class="m-0"><small>{{intake.description}}</small></p>
                </div>
              </div>
              </div>
              <div class="card mt-2 text-center pt-4 pb-4" @click="$refs.newIntakeMethod.openModal()" style="cursor:pointer">
                <i class="pi pi-plus"></i>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template #footer>
        <Button class="btn-custom-save" icon="pi pi-chevron-right" iconPos="right"
          :label="$t('continueWithIntakeConfiguration')" @click="toggleIntakeConfig = true"
          v-if="!toggleIntakeConfig"></Button>
        <Button class="btn-custom-save" icon="pi pi-chevron-left" iconPos="left" :label="$t('goback')"
          @click="toggleIntakeConfig = false" v-if="toggleIntakeConfig"></Button>
        <Button class="btn-custom" :label="$t('save')" @click="addNewIntegration(new_data_source_name,new_data_source_description)" v-tooltip.top="$t('continueLaterConfiguration')"></Button>
      </template>
    </Modal>

    <!--============================================================================== newIntakeMethod ==============================================================================-->
    <Modal ref="newIntakeMethod">
      <template #header>
      </template>
      <template #body>
        <div class="row">
          <div class="col-12 text-center pt-4">
            <i class="pi pi-question" style="font-size:2.5em;"></i>
          </div>
          <div class="col-12 text-center pt-0 pb-0">
            <h5 class="modal-title">{{ $t('createNewIntakeMethod') }}</h5>
          </div>
        </div>
        <div class="row">
          <div class="col-12 p-4">
            <label for="" class="pl-0 pb-0 mb-0">{{ $t('name') }}</label>
            <InputText type="text" v-model="new_intake_name" :placeholder="$t('name')"
              style="width:100%;">
            </InputText>
            <label for="" class="pl-0 pb-0 mb-0 mt-1">{{ $t('description') }}</label>
            <Textarea  type="text" v-model="new_intake_desc" :placeholder="$t('description')"
              style="width:100%;">
            </Textarea>
            <label for="" class="pl-0 pb-0 mb-0">{{ $t('nifiListener') }}</label>
            <InputText type="text" v-model="new_intake_nifi" :placeholder="$t('nifiListener')"
              style="width:100%;">
            </InputText>
            <div class="row mt-4">
              <h6 class="mb-0"><strong>{{$t('configurationSchemaParams')}}</strong></h6>
              <div class="col-8">
                <label for="" class="pl-0 pb-0 mb-0">{{ $t('name') }}</label>
                <InputText type="text" v-model="new_param_name" :placeholder="$t('intakeName')"
                  style="width:100%;">
                </InputText>
                <label for="" class="pl-0 pb-0 mb-0">{{ $t('description') }}</label>
                <InputText type="text" v-model="new_param_desc" :placeholder="$t('intakeDescription')"
                  style="width:100%;">
                </InputText>
                <label for="" class="pl-0 pb-0 mb-0">{{ $t('type') }}</label>
                <Dropdown v-model="selectedType" :placeholder="$t('type')" style="width:100%;" :options="types" optionLabel="name"></Dropdown>
                <Button class="btn-custom-save mt-2" :label="$t('add')" @click="addNewParam(new_param_name,new_param_desc,selectedType)" style="float:right"></Button>
              </div>
              <div class="col-4">
                <div class="card" v-for="param in new_schema_params_array" :key="param">
                  <i class="pi pi-times mt-1 mr-1" @click="deleteParam()" style="position: absolute; top:0; right:0; cursor:pointer;"></i>
                  <div class="row m-0">
                    <p class="m-0 pr-2"><strong>{{param.name}}</strong></p>
                  </div>
                  <div class="row m-0">
                    <p class="m-0 pr-2">{{param.type}}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template #footer>
        <Button class="btn-custom" :label="$t('save')" @click="addNewIntakeMethod(new_intake_name,new_intake_desc,new_intake_nifi,new_intake_conf_schema_name,new_schema_params)"></Button>
      </template>
    </Modal>
  </div>
</template>
<!----------------------------------------------->
<!--                    SCRIPT                 -->
<!----------------------------------------------->

<script>
import MyShared from "./shared";
export default {
  mixins: [MyShared],
};

</script>

<script setup>
import { random } from "gsap";
import { useMq } from "vue3-mq";
const mq = useMq();
</script>

<!----------------------------------------------->
<!--                    STYLES                 -->
<!----------------------------------------------->
<style scoped>
.search-integration-row {
  display: block ruby;
}

.checkbox {
  width: auto;
}

.source-card-container {
  padding: 0;
  margin: 0;
  display: contents;
}

.explore-label {
  color: #ec635f;
  font-size: 0.9em;
  cursor: pointer;
}

.explore-label:hover {
  color: #ec635f;
  font-size: 0.9em;
  text-decoration: underline;
}

.p-card {
  width: 100%;
  display: inline-block;
}

.source-card {
  width: 12em;
  height: 12em;
  margin: 1em;
}

.source-cont {
  padding-top: 2em;
}

.source-img {
  width: 100%;
  max-height: 4em;
  object-fit: contain;
}

.default-img-v1 {
  height: 4em;
  width: 4em;
  line-height: 3.8em;
  margin: auto;
  background: #ec635f !important;
  color: white;
  border-radius: 500px;
  text-transform: uppercase;
  font-size: 1em;
  text-align: center;
  padding: 0;
}

.default-img-v2 {
  height: 4em;
  width: 4em;
  line-height: 3.8em;
  margin: auto;
  background-color: #9fd6dd;
  color: white;
  border-radius: 500px;
  text-transform: uppercase;
  font-size: 1em;
  text-align: center;
  padding: 0;
}

.default-img-v3 {
  height: 4em;
  width: 4em;
  line-height: 3.8em;
  margin: auto;
  background-color: #ffce36;
  color: white;
  border-radius: 500px;
  text-transform: uppercase;
  font-size: 1em;
  text-align: center;
  padding: 0;
}

.default-img-letter {
  margin: 0;
  font-size: 2em;
}

.source-img-row {
  height: 4em;
}

.form-check-input:checked {
  background-color: #ec635f !important;
  border-color: #ec635f !important;
}
</style>
