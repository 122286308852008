<!----------------------------------------------->
<!--                  TEMPLATE                 -->
<!----------------------------------------------->
<template>
  <div class="aux">
    <nav class="main-menu">
      <div class="settings"></div>

      <ul>
        <li class="group-item">
          <span class="nav-text"><small style="font-weight: 600 !important;">{{ $t('general') }}</small></span>
        </li>
        <li class="item">
          <router-link to="home">
            <i class="fa pi pi-home fa-lg"></i>
            <span class="nav-text">{{ $t('home') }}</span>
          </router-link>
        </li>
        <li class="item">
          <router-link to="integrations">
            <i class="fa pi pi-th-large fa-lg"></i>
            <span class="nav-text">{{ $t('integrations') }}</span>
          </router-link>
        </li>
        <li class="item">
          <router-link to="listeners">
            <i class="fa pi pi-filter fa-lg"></i>
            <span class="nav-text">{{ $t('listeners') }}</span>
          </router-link>
        </li>
        <li class="item">
          <router-link to="logs">
            <i class="fa pi pi-desktop fa-lg"></i>
            <span class="nav-text">{{ $t('logs') }}</span>
          </router-link>
        </li>
        <li class="item">
          <router-link to="assets">
            <i class="fa pi pi-server fa-lg"></i>
            <span class="nav-text">{{ $t('assets') }}</span>
          </router-link>
        </li>
        <li class="group-item">
            <span class="nav-text"><small style="font-weight: 600 !important;">{{ $t('alerts') }}</small></span>
        </li>
        <li class="item">
          <router-link to="events">
            <i class="fa pi-exclamation-triangle pi fa-lg"></i>
            <span class="nav-text">{{ $t('events') }}</span>
          </router-link>
        </li>
        <li class="item">
          <router-link to="incidents">
            <i class="fa pi-briefcase pi fa-lg"></i>
            <span class="nav-text">{{ $t('incidents') }}</span>
          </router-link>
        </li>
        <li class="item">
          <router-link to="alert-configuration">
            <i class="fa pi-cog pi fa-lg"></i>
            <span class="nav-text">{{ $t('alertConfiguration') }}</span>
          </router-link>
        </li>
        <li class="group-item">
          <span class="nav-text"><small style="font-weight: 600 !important;">{{ $t('threatDetection') }}</small></span>
        </li>
        <li class="item">
          <router-link to="machine-learning">
            <i class="fa pi-sitemap pi fa-lg"></i>
            <span class="nav-text">{{ $t('machineLearning') }}</span>
          </router-link>
        </li>
        <li class="item">
          <router-link to="correlation">
            <i class="fa pi-link pi fa-lg"></i>
            <span class="nav-text">{{ $t('correlation') }}</span>
          </router-link>
        </li>
        <li class="item">
          <router-link to="detection">
            <i class="fa pi-flag pi fa-lg"></i>
            <span class="nav-text">{{ $t('detection') }}</span>
          </router-link>
        </li>
        <li class="group-item">
          <span class="nav-text"><small style="font-weight: 600 !important;">{{ $t('management') }}</small></span>
        </li>
        <li class="item">
          <router-link to="manage-users">
            <i class="fa pi-user pi fa-lg"></i>
            <span class="nav-text">{{ $t('manageUsers') }}</span>
          </router-link>
        </li>
        <li class="item">
          <router-link to="manage-teams">
            <i class="fa pi-users pi fa-lg"></i>
            <span class="nav-text">{{ $t('manageTeams') }}</span>
          </router-link>
        </li>
        <li class="item">
          <router-link to="manage-organizations">
            <i class="fa pi-building pi fa-lg"></i>
            <span class="nav-text">{{ $t('manageOrganizations') }}</span>
          </router-link>
        </li>
        <li class="item">
          <router-link to="tasks">
            <i class="fa pi-calendar pi fa-lg"></i>
            <span class="nav-text">{{ $t('tasks') }}</span>
          </router-link>
        </li>
        <li class="item">
          <router-link to="manage-roles">
            <i class="fa pi-bookmark pi fa-lg"></i>
            <span class="nav-text">{{ $t('manageRoles') }}</span>
          </router-link>
        </li>
        <li class="item">
          <router-link to="permissions">
            <i class="fa pi-lock-open pi fa-lg"></i>
            <span class="nav-text">{{ $t('permissions') }}</span>
          </router-link>
        </li>
      </ul>
    </nav>
  </div>
</template>
  <!----------------------------------------------->
  <!--                    SCRIPT                 -->
  <!----------------------------------------------->
<script>
import MyShared from "./sharedSideBar";

export default {
  mixins: [MyShared],
};
</script>

<script setup>
import { useMq } from "vue3-mq";
const mq = useMq();
</script>
  
  <!----------------------------------------------->
  <!--                    STYLES                 -->
  <!----------------------------------------------->
<style scoped>
.settings {
  height: 73px;
  float: left;
  background: url(https://s3.postimg.org/bqfooag4z/startific.jpg);
  background-repeat: no-repeat;
  width: 250px;
  margin: 0px;
  text-align: center;
  font-size: 20px;
  font-family: 'Strait', sans-serif;

}

.fa-lg {
  font-size: 1em;
}
.fa {
  position: relative;
  display: table-cell;
  width: 55px;
  height: 36px;
  text-align: center;
  top: 12px;
  font-size: 20px;
}
.main-menu:hover,
nav.main-menu.expanded {
  width: 250px;
  opacity: 1;
}

.main-menu {
  background: #fff;
  position: fixed;
  top: 0;
  bottom: 0;
  height: 100%;
  left: 0;
  width: 55px; /* 55px para que entre y salga */
  -webkit-transition: width .2s linear;
  transition: width .2s linear;
  -webkit-transform: translateZ(0) scale(1, 1);
  box-shadow: 1px 0 15px rgba(0, 0, 0, 0.07);
  opacity: 1;
  z-index: 1 !important;
  overflow-y: auto;
  overflow-x: hidden;
}
.main-menu>ul {
  margin: 7px 0;
}

.main-menu li {
  position: relative;
  display: block;
  width: 250px;
}

.main-menu li>a {
  position: relative;
  width: 255px;
  display: table;
  border-collapse: collapse;
  border-spacing: 0;
  color: #6b6b6b;
  font-size: 13px;
  text-decoration: none;
  -webkit-transform: translateZ(0) scale(1, 1);
  -webkit-transition: all .14s linear;
  transition: all .14s linear;
}

.main-menu .nav-icon {
  position: relative;
  display: table-cell;
  width: 55px;
  height: 36px;
  text-align: center;
  vertical-align: middle;
  font-size: 18px;
}

.main-menu .nav-text {
  position: relative;
  display: table-cell;
  vertical-align: middle;
  width: 190px;
}

.main-menu .share {}

.main-menu .fb-like {
  left: 180px;
  position: absolute;
  top: 15px;
}

.main-menu>ul.logout {
  position: absolute;
  left: 0;
  bottom: 0;
}

nav {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

nav ul,
nav li {
  outline: 0;
  margin: 0;
  padding: 0;
  text-transform: uppercase;
}

/* Darker element side menu Start*/
.item {
  background-color: white;
  text-transform: capitalize;
  margin-top: 0.5em;
}
.group-item {
  margin-top: 0.25em;
  padding-left:1em;
  color: #8b8b8b;
  background-color: white;
  text-transform: capitalize !important;
}
/* Darker element side menu End*/
.main-menu li:hover>a,
nav.main-menu li.active>a,
.dropdown-menu>li>a:hover,
.dropdown-menu>li>a:focus,
.dropdown-menu>.active>a,
.dropdown-menu>.active>a:hover,
.dropdown-menu>.active>a:focus,
.no-touch .dashboard-page nav.dashboard-menu ul li:hover a,
.dashboard-page nav.dashboard-menu ul li.active a {
  color: black;
  text-shadow: 0px 0px 0px;
}

.area {
  float: left;
  background: #e2e2e2;
  width: 100%;
  height: 100%;
}
</style>
  
  
  