<!----------------------------------------------->
<!--                  TEMPLATE                 -->
<!----------------------------------------------->
<template>
  <MobileListeners v-if="  mq.current  === 'mobile'"></MobileListeners>
  <TabletListeners v-if="  mq.current  === 'tablet'"></TabletListeners>
  <DesktopListeners v-if=" mq.current  === 'desktop'"></DesktopListeners>
</template>

<!----------------------------------------------->
<!--                    SCRIPT                 -->
<!----------------------------------------------->
<script>
import MobileListeners from "../components/Listeners/MobileListeners";
import TabletListeners from "../components/Listeners/TabletListeners";
import DesktopListeners from "../components/Listeners/DesktopListeners";

export default {
  /* Name */
  name: "Listeners",
  inject: ["mq"],
  /* Components */
  components: {
    MobileListeners,
    TabletListeners,
    DesktopListeners,
  },
};
</script>

<script setup>
import { useMq } from "vue3-mq";
const mq = useMq();
</script>

<!----------------------------------------------->
<!--                    STYLES                 -->
<!----------------------------------------------->
<style scoped >
</style>
