<!----------------------------------------------->
<!--                  TEMPLATE                 -->
<!----------------------------------------------->
<template>
  <div class="aux">
    <!-- Nav -->
    <nav class="navbar navbar-expand-lg navbar-light"
      :style="'font-family:' + primaryFont + '; padding-left: 6rem; padding-right:2rem;z-index:5; background-color:white; box-shadow: 1px 0 15px rgba(0, 0, 0, 0.07);'">
      <a class="navbar-brand">
        <router-link to="/home">
          <img class="nav-bar-logo" v-bind:src="projectLogo" alt="logo" />
        </router-link>
      </a>

      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav mr-auto"></ul>
        <div class="form-inline my-2 my-lg-0">
          <ul class="navbar-nav mr-auto pr-4" @click="$router.push({ path: 'profile'})"
            style="cursor: pointer; font-family:'Segoe UI'" v-if="!loading">
            {{ user.full_name }}
          </ul>
          <ul class="navbar-nav mr-auto pr-2" @click="$router.push({ path: 'profile'})"
            style="cursor: pointer; font-family:'Segoe UI'" v-else>
            <ContentLoader class="mr-2" height="25px" width="100px"></ContentLoader>
          </ul>
          <ul class="navbar-nav mr-3">
            <!-- IMAGEN USUARIO -->
            <li class="nav-item" @click="$router.push({ path: 'profile'})"
              style="cursor: pointer;" v-if="!loading">
              <Avatar v-if="user.picture == null" :label="user.full_name[0].toUpperCase()" shape="circle" size="medium" />
              <img v-else :src="getSourceLogo(user.picture)" class="rounded-circle" height="35" width="35" />
            </li>
            <li class="nav-item" v-else>
              <ContentLoader height="35px" width="35px"></ContentLoader>
            </li>
          </ul>
          <ul class="navbar-nav mr-1">
            <li class="nav-item dropdown ">
              <a class="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown"
                aria-haspopup="false" aria-expanded="false">
                <img :src="locale_img" class="rounded-circle" height="20" alt="" width="20" v-if="!loading"/>
                <ContentLoader class="ml-4" height="25px" width="25px" v-else></ContentLoader>
              </a>
              <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                <a class="dropdown-item" v-for="entry in languages" :key="entry" @click="setLocale(entry.language)">
                  <img :src="entry.flag" class="rounded-circle mr-2" height="20" alt="" width="20" /> {{ entry.title }}
                </a>
              </div>
            </li>
          </ul>
          <!--
          <ul class="navbar-nav">
            <li class="nav-item">
              <a class="nav-link">
                <div style="margin-top: 2px;">
                  <input type="checkbox" class="checkbox" id="checkbox" @click="toggleMode()">
                  <label for="checkbox" class="label" id="dark-mode-checkbox">
                    <i class="fas fa-moon"></i>
                    <i class='fas fa-sun'></i>
                    <div class='ball'></div>
                  </label>
                </div>
              </a>
            </li>
          </ul>
          -->
        </div>
      </div>
    </nav>
    <Sidebar></Sidebar>
  </div>
</template>

<!----------------------------------------------->
<!--                    SCRIPT                 -->
<!----------------------------------------------->

<script>
var $ = require("jquery");
import MyShared from "./sharedNavBar";

export default {
  mixins: [MyShared],
};
</script>
<!----------------------------------------------->
<!--                    STYLES                 -->
<!----------------------------------------------->
<style scoped>
.router-link-exact-active {
  text-decoration: underline 2px;
  text-decoration-color: #70ad47 !important;
  color: #70ad47 !important;
}

.username {
  padding-bottom: 15%;
  padding-top: 15%;
  margin-right: 0.5em;
  height: 100%;
}

.nav-bar-logo {
  height: 2em;
}

.navbar-item {
  font-size: 0.8em;
  font-weight: 600;
  color: black;
}

.logout {
  font-size: 1em;
  margin-right: 1em;
  width: 2em !important;
  height: 1em !important;
  /* background-color: blueviolet; */
  color: black;
}

.dropdown-menu {
  position: absolute !important;
  margin-right: 5em;
}

.dropdown-item {
  padding: 5px !important;
}

.dropdown-toggle {
  margin-left: 2em
}

a {
  color: black;
  text-decoration: none;
}

a:hover {
  color: #22bcb3;
}

.navbar-brand {
  margin-right: 3rem;
  text-align: center;
}

h6 {
  margin-bottom: 0% !important;
}

.icon-logOut {
  color: #70ad47;
}

.fa {
  font-size: x-large;

}

.nav-item {
  text-align: center;
}

.center {
  text-align: center;
  margin-top: 0.75rem;
  margin-left: 0.5rem;
}

.navbar {
  position: fixed;
  top: 0;
  width: 100%;
}


.checkbox {
  opacity: 0;
  position: absolute;
}

.label {
  width: 25px;
  height: 13px;
  background-color:rgb(128, 201, 243);
  display: flex;
  border-radius:50px;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  position: relative;
  transform: scale(1.5);
}

.ball {
  width: 9px;
  height: 9px;
  background-color: white;
  position: absolute;
  top: 2px;
  left: 2px;
  border-radius: 50%;
  transition: transform 0.2s linear;
}

/*  target the elemenent after the label*/
.checkbox:checked + .label .ball{
  transform: translateX(11px);
}

.fa-moon {
  font-size: 8px;
  color: rgb(221, 221, 224);
  margin-bottom: 1px;
  margin-left: -1px;
}

.fa-sun {
  font-size: 8px;
  color: rgb(255, 166, 0);
  margin-left: 3px;
  margin-bottom: 1px;
}
</style>
