import { nextTick } from "vue";

var MyShared = {
    /* Name */
    name: "OpenGroup",
    /* Data */
    setup() {

    },
    data() {
        return {
            user: {
                "permissions": []
            },
            open: false,
        }
    },
    /* Components */
    components: {},
    /* Props */
    props: {
        groupName: String,
        openFirst: Boolean,
        new_item: Boolean,
    },
    /* Methods */
    methods: {

    },
    async mounted() {;
        await nextTick();
        this.open = this.openFirst;
    }
};
export default MyShared;