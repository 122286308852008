import $axios from '../utils';

export function getAllPermissions (full=true, offset=0, limit=10000) {
    return $axios.get(`management/permission/?full=${full}&offset=${offset}&limit=${limit}`)
    .then(response => response.data)
}
export function getOnePermission(id, full) {
    return $axios.get(`management/permission/${id}?full=${full}`)
    .then(response => response.data)
}
export function createPermission(data) {
    return $axios.post('management/permission/', data)
    .then(response => response.data)
}
export function updatePermission(id, data) {
    return $axios.put(`management/permission/${id}`, data)
    .then(response => response.data)
}
export function deletePermission(id) {
    return $axios.delete(`management/permission/${id}`)
    .then(response => response.data)
}
