import Sitebar from "../../components/Partials/Sitebar/SiteBar.vue"
import Helper from "../../components/Partials/Helper/Helper.vue"
import { nextTick } from "vue";
import OrganizationChart from 'primevue/organizationchart';
import { ref } from "vue";
import * as $api from "../../api/api";
import { useUserStore } from '../../store/store.js'
import { MiniMap } from '@vue-flow/minimap'
import '@vue-flow/minimap/dist/style.css'
import { Controls } from '@vue-flow/controls'
import '@vue-flow/controls/dist/style.css'

var MyShared = {
    /* Name */
    name: "Home",
    /* Data */
    setup() {
    },
    data() {
        return {
            user: {
                "permissions": []
            },
            investigationRequiredValues: false,
            investigationName: '',
            investigationDescription: '',
            is_saved: false,
            elastic_logo: require('@/assets/elastic.png'),
            db_logo: require('@/assets/db.jpg'),

            /* Investigate variables */
            lowOpacity: false,
            toggleNewNode: false,
            flow_chart: [],
            isNodeMenuOpen: false,
            selectedNode: {},
            selectedNodeData: {},
            selectedNodeType: '',
            defaultZoom: 2,
            active_tab: 2,
            isNewNode: false,
            isNodeDetails: false,
            newNodeID: 1,
            newNodeSelectedType: 'incident',
            newObjectNode: {
                type: 'none',
                data: {
                    id: '',
                    type: 'none',
                    handles: [true, true, true, true],
                    icon_color: '#000000',
                    object: {
                        incidents: [],
                        events: [],
                    },
                    selected: false,
                    warning: false,
                    warning_description: '',

                },
                id: '',
                label: 'New node',
            },
            isEdgeOptions: false,
            edgeObject: {},
            openOptions: false,
            nodeTypes: [],
            sources_and_models: [],
            nodeTypeModelOptions: [],
            newNodeSelectedObject: {},
        }

    },
    /* Components */
    components: {
        Sitebar,
        Helper,
        OrganizationChart,
        MiniMap,
        Controls
    },
    /* Props */
    props: {},
    /* Methods */
    methods: {
        formatDate(date) {
            if (date == null || date == '') return;

            // Format date to dd/mm/yyyy hh:mm
            let d = new Date(date);
            let day = d.getDate();
            let month = d.getMonth() + 1;
            let year = d.getFullYear();
            let hour = d.getHours();
            let minutes = d.getMinutes();
            // Add 0 if month is less than 10
            if (month < 10) {
                month = "0" + month;
            }
            // Add 0 if day is less than 10
            if (day < 10) {
                day = "0" + day;
            }
            // Add 0 if minutes is less than 10
            if (minutes < 10) {
                minutes = "0" + minutes;
            }
            return `${day}/${month}/${year} ${hour}:${minutes}`;
        },
        saveInvestigation(dashboard_name, dashboard_desc) {
            this.is_saved = true;
            this.investigationRequiredValues = false;
        },
        /* ------------------------------------- Investigate functions  -------------------------------------*/
        calculateFlowChartData() {

        },
        /* ------------------------------------- Handlers functions  -------------------------------------*/
        onNodeDragStart(event) {
            //console.log("onNodeDragStart: ", event);
        },
        onNodeDrag(event) {
            //console.log("onNodeDrag: ", event);
        },
        onNodeDragStop(event) {
            console.log("onNodeDragStop: ", event);
            // parse flow_chart to json
            let flow_chart_json = JSON.stringify(this.flow_chart);
            console.log("flow_chart_json: ", flow_chart_json);
        },
        async onNodeClick(event) {
            console.log("Clicked node: ", event);

            // Set selectedNode
            this.selectedNode = event.node;

            this.selectedNodeData = event.node.data;
            this.selectedNodeType = event.node.type;

            // if event.node.data.type == 'event' get incidents
            if (event.node.data.type == 'event') {
                console.log(event.node.data.object);
                await $api.getOneEvent(event.node.data.object.id, true).then((response) => {
                    this.selectedNodeData.object.incidents = response.incidents;
                }).catch((error) => {
                    console.log('[-] Error on getAllEvents: ', error);
                });
            }

            this.isNodeDetails = true;
            this.isEdgeOptions = false;

            // find node and set selected true
            this.focusSelectedNode();
        },
        focusFirstNode() {
            this.selectedNode = this.flow_chart[0];
            this.selectedNodeData = this.flow_chart[0].data;
            this.selectedNodeType = this.flow_chart[0].type;

            // if event.node.data.type == 'event' get incidents
            if (this.flow_chart[0].data.type == 'event') {
                console.log(this.flow_chart[0].data.object);
                $api.getOneEvent(this.flow_chart[0].data.object.id, true).then((response) => {
                    this.selectedNodeData.object.incidents = response.incidents;
                }).catch((error) => {
                    console.log('[-] Error on getAllEvents: ', error);
                });
            }

            this.isNodeDetails = true;
            this.isEdgeOptions = false;

            // find node and set selected true
            this.focusSelectedNode();
        },
        onNodeDoubleClick(event) {
            //console.log("onNodeDoubleClick: ", event);
        },
        onNodeContextMenu(event) {
            //console.log("onNodeContextMenu: ", event);
        },
        onNodeMouseEnter(event) {
            // console.log("onNodeMouseEnter: ", event);
        },
        onNodeMouseLeave(event) {
            //console.log("onNodeMouseLeave: ", event);
        },
        onNodeMouseMove(event) {
            //console.log("onNodeMouseMove: ", event);
        },
        onConnectCustom() {
            console.log(this.flow_chart);
        },
        onEdgeClick(event) {
            console.log("onEdgeClick: ", event);

            // find edge and set animated true
            let index = this.flow_chart.findIndex(x => x.id === event.edge.id);
            this.edgeObject = this.flow_chart[index];
            this.focusSelectedEdge();

            this.isEdgeOptions = true;
            this.edgeObject = this.flow_chart[index];
        },
        focusFirstEdge() {
            // find first edge with id containing 'vueflow__edge'
            let index = this.flow_chart.findIndex(x => x.id.includes('vueflow__edge'));
            this.edgeObject = this.flow_chart[index];
            //this.focusSelectedEdge();
            //this.isEdgeOptions = true;
            this.edgeObject = this.flow_chart[index];
        },
        onEdgeDoubleClick(event) {
            console.log("onEdgeDoubleClick: ", event);
            // Delete edge from flow_chart
            let index = this.flow_chart.findIndex(x => x.id === event.edge.id);
            this.flow_chart.splice(index, 1);
        },
        /* ------------------------------------- Aux functions  -------------------------------------*/
        checkIfValidSourcePos(sourceHandlePosition, targetHandlePosition) {

        },
        focusSelectedNode() {
            console.log("focusSelectedNode", this.selectedNode);
            //set all edeges to animated false
            this.unfocusSelectedNode();
            // find edge and set selected true
            let index = this.flow_chart.findIndex(x => x.id === this.selectedNode.id);
            this.flow_chart[index].data.selected = true;
        },
        unfocusSelectedNode() {
            //console.log("unfocusSelectedNode");
            //set all edeges to selected false
            for (let i = 0; i < this.flow_chart.length; i++) {
                this.flow_chart[i].data.selected = false;
            }
        },
        focusSelectedEdge() {
            //console.log("focusSelectedEdge");
            //set all edeges to animated false
            this.unfocusSelectedEdge();
            // find edge and set animated true
            let index = this.flow_chart.findIndex(x => x.id === this.edgeObject.id);
            this.flow_chart[index].animated = true;
        },
        unfocusSelectedEdge() {
            //console.log("unfocusSelectedEdge");
            //set all edeges to animated false
            for (let i = 0; i < this.flow_chart.length; i++) {
                this.flow_chart[i].animated = false;
            }
        },
        deleteNode(id) {
            console.log("deleteNode: ", id);
            // Delete node from flow_chart
            let index = this.flow_chart.findIndex(x => x.id === id);
            this.flow_chart.splice(index, 1);
            // Delete edges from flow_chart
            for (let i = 0; i < this.flow_chart.length; i++) {
                if (this.flow_chart[i].source === id || this.flow_chart[i].target === id) {
                    this.flow_chart.splice(i, 1);
                    i--;
                }
            }
            // Close node details
            this.isNodeDetails = false;
        },
        exploreNode(id, type) {
            this.$router.push({ path: type + '-details', query: { id: id } })
        },
        getLastPositionY() {
            let y = 0;
            for (let i = 0; i < this.flow_chart.length - 1; i++) {
                if (this.flow_chart[i].position != undefined) {
                    if (this.flow_chart[i].position.y > y) {
                        y = this.flow_chart[i].position.y;
                    }
                }
            }
            return y;
        },

        async newNode(type) {
            console.log("newNode: ", type);
            this.toggleNewNode = false;
            this.isNewNode = false;

            // add new node to flow_chart
            //let x = 125;
            // let y = this.getLastPositionY() + 75;

            let x = 125;
            let y = 125;

            // Set new node values
            this.newObjectNode.data.icon_color = '#' + this.newObjectNode.data.icon_color;
            // loop flow_chart to find highest node id
            for (let i = 0; i < this.flow_chart.length; i++) {
                //remove 'n' letter from id
                let id = this.flow_chart[i].id.replace('n', '');
                id = parseInt(id);
                // if id is higher than newNodeID set newNodeID to id
                if (id > this.newNodeID) {
                    this.newNodeID = id;
                }
            }

            this.newObjectNode.id = 'n' + (++this.newNodeID).toString();
            if (type == 'none')
                this.newObjectNode.type = 'none';
            else
                this.newObjectNode.type = 'customdefault';
            this.newObjectNode.position = { x: x, y: y };

            //console.log("newObjectNode: ", this.newNodeSelectedObject);

            if (type == 'none') {
                this.newObjectNode.data.object = {};
            } else {
                if (this.newNodeSelectedObject.title)
                    this.newObjectNode.label = this.newNodeSelectedObject.title;

                if (this.newNodeSelectedObject.name)
                    this.newObjectNode.label = this.newNodeSelectedObject.name;

                if (this.newNodeSelectedObject.full_name)
                    this.newObjectNode.label = this.newNodeSelectedObject.full_name;


                this.newObjectNode.data.object = this.newNodeSelectedObject;
                this.newObjectNode.data.type = type;

                this.setColor(type)
                this.setIcon(type)
            }

            // if event.node.data.type == 'event' get incidents ---> cambiar en el backend
            if (type == 'event') {
                await $api.getOneEvent(this.newNodeSelectedObject.id, true).then((response) => {
                    console.log("response: ", response);
                    this.newObjectNode.data.object = response;
                    console.log("newObjectNode: ", this.newObjectNode);
                }).catch((error) => {
                    console.log('[-] Error on getOneEvent: ', error);
                });
            }
            // if event.node.data.type == 'event' get incidents ---> cambiar en el backend
            if (type == 'incident') {
                await $api.getOneIncident(this.newNodeSelectedObject.id, true).then((response) => {
                    console.log("response: ", response);
                    this.newObjectNode.data.object = response;
                    console.log("newObjectNode: ", this.newObjectNode);
                }).catch((error) => {
                    console.log('[-] Error on getOneIncident: ', error);
                });
            }

            let handles = [this.newObjectNode.data.handles[0], this.newObjectNode.data.handles[1], this.newObjectNode.data.handles[2], this.newObjectNode.data.handles[3]];
            // if default handles uncomment

            if (type != 'none') {
                handles = [true, true, true, true];
            }

            let addNode = {
                id: this.newObjectNode.id,
                type: this.newObjectNode.type,
                label: this.newObjectNode.label,
                position: this.newObjectNode.position,
                data: {
                    type: type,
                    description: this.newObjectNode.data.description,
                    object: this.newObjectNode.data.object,
                    icon_color: this.newObjectNode.data.icon_color,
                    handles: handles,
                    warning: this.newObjectNode.data.warning,
                    selected: this.newObjectNode.data.selected,
                    warning_description: this.newObjectNode.data.warning_description,
                    icon: this.newObjectNode.data.icon,
                }
            }

            this.flow_chart.push(addNode);
            this.selectedNode = addNode;
            this.focusSelectedNode();
            this.isNodeDetails = true;

        },

        updateNodeColor(color) {
            console.log("updateNodeColor: ", color);
            // find node and update selectedNode
            let index = this.flow_chart.findIndex(x => x.id === this.selectedNode.id);

            // Update color
            this.flow_chart[index].data.icon_color = '#' + color;

        },
        updatedNodeType(type) {
            console.log("updatedNode");
            // find node and update selectedNode
            let index = this.flow_chart.findIndex(x => x.id === this.selectedNode.id);

            // Update type
            this.flow_chart[index].type = type;

            console.log("flow_chart: ", this.flow_chart);
        },
        updateEdge() {
            console.log("updateEdge: ", this.edgeObject);

            let index = this.flow_chart.findIndex(x => x.id === this.edgeObject.id);

            // Update edge
            this.flow_chart[index].style = { stroke: this.edgeObject.style.stroke };
            this.flow_chart[index].label = this.edgeObject.label;

            console.log("flow_chart: ", this.flow_chart);
        },
        getSources() {
            $api.getSources().then((response) => {
                this.sources_and_models = response;
                this.nodeTypes = response.models.concat(response.sources);
                // convert to singular
                // if ends with s, remove s


                //find visualization visualization_dashboard, permission, color_palette, role, asset_group, activity, alert_method, screenshot and remove
                let index = this.nodeTypes.indexOf('visualizations');
                if (index > -1) {
                    this.nodeTypes.splice(index, 1);
                }
                index = this.nodeTypes.indexOf('visualization_dashboards');
                if (index > -1) {
                    this.nodeTypes.splice(index, 1);
                }
                index = this.nodeTypes.indexOf('permissions');
                if (index > -1) {
                    this.nodeTypes.splice(index, 1);
                }
                index = this.nodeTypes.indexOf('color_palettes');
                if (index > -1) {
                    this.nodeTypes.splice(index, 1);
                }
                index = this.nodeTypes.indexOf('roles');
                if (index > -1) {
                    this.nodeTypes.splice(index, 1);
                }
                index = this.nodeTypes.indexOf('asset_groups');
                if (index > -1) {
                    this.nodeTypes.splice(index, 1);
                }
                index = this.nodeTypes.indexOf('activity');
                if (index > -1) {
                    this.nodeTypes.splice(index, 1);
                }
                index = this.nodeTypes.indexOf('alert_methods');
                if (index > -1) {
                    this.nodeTypes.splice(index, 1);
                }
                index = this.nodeTypes.indexOf('screenshots');
                if (index > -1) {
                    this.nodeTypes.splice(index, 1);
                }

                this.nodeTypes.push('none');

                // sort nodeTypes alphabetically
                this.nodeTypes.sort();
                
            }).catch((error) => {
                console.log('[-] Error on getSources: ', error);
            });
        },
        getSourceLogo(logo) {
            return process.env.VUE_APP_BASE_URL + '/' + logo;
        },
        async getSelectedNodeTypeObjects(type) {
            console.log("getSelectedNodeTypeObjects: ", type);
            console.log("type: ", type);
            switch (type) {
                case 'incidents':
                    await $api.getAllIncidents().then((response) => {
                        this.nodeTypeModelOptions = response;
                    }).catch((error) => {
                        console.log('[-] Error on getAllIncidents: ', error);
                    });
                    break;
                case 'events':
                    await $api.getAllEvents(true).then((response) => {
                        this.nodeTypeModelOptions = response;
                    }).catch((error) => {
                        console.log('[-] Error on getAllEvents: ', error);
                    });
                    break;
                case 'assets':
                    await $api.getAllAssets().then((response) => {
                        this.nodeTypeModelOptions = response;
                    }).catch((error) => {
                        console.log('[-] Error on getAllAssets: ', error);
                    });
                    break;
                case 'listeners':
                    await $api.getAllListeners().then((response) => {
                        this.nodeTypeModelOptions = response;
                    }).catch((error) => {
                        console.log('[-] Error on getAllListeners: ', error);
                    });
                    break;
                case 'alerts':
                    await $api.getAllAlerts().then((response) => {
                        this.nodeTypeModelOptions = response;
                    }).catch((error) => {
                        console.log('[-] Error on getAllAlerts: ', error);
                    });
                    break;
                case 'correlation_rules':
                    await $api.getAllCorrelationRules().then((response) => {
                        this.nodeTypeModelOptions = response;
                    }).catch((error) => {
                        console.log('[-] Error on getAllCorrelationRules: ', error);
                    });
                    break;
                case 'detection_rules':
                    await $api.getAllDetectionRules().then((response) => {
                        this.nodeTypeModelOptions = response;
                    }).catch((error) => {
                        console.log('[-] Error on getAllDetectionRules: ', error);
                    });
                    break;
                case 'tasks':
                    await $api.getAllTasks().then((response) => {
                        this.nodeTypeModelOptions = response;
                    }).catch((error) => {
                        console.log('[-] Error on getAllTasks: ', error);
                    });
                    break;
                case 'users':
                    await $api.getAllUsers().then((response) => {
                        this.nodeTypeModelOptions = response;
                    }).catch((error) => {
                        console.log('[-] Error on getAllUsers: ', error);
                    });
                    break;
                case 'teams':
                    await $api.getAllTeams().then((response) => {
                        this.nodeTypeModelOptions = response;
                    }).catch((error) => {
                        console.log('[-] Error on getAllTeams: ', error);
                    });
                    break;
                case 'dashboards':
                    await $api.getAllDashboards().then((response) => {
                        this.nodeTypeModelOptions = response;
                    }).catch((error) => {
                        console.log('[-] Error on getAllDashboards: ', error);
                    });
                    break;
                case 'sources':
                    await $api.getAllSources().then((response) => {
                        this.nodeTypeModelOptions = response;
                    }).catch((error) => {
                        console.log('[-] Error on getAllSources: ', error);
                    });
                    break;
                case 'logs':
                    await $api.getAllLogs().then((response) => {
                        this.nodeTypeModelOptions = response;
                    }).catch((error) => {
                        console.log('[-] Error on getAllLogs: ', error);
                    });
                    break;
                case 'intake_methods':
                    await $api.getAllIntakeMethods().then((response) => {
                        this.nodeTypeModelOptions = response;
                    }).catch((error) => {
                        console.log('[-] Error on getAllIntakeMethods: ', error);
                    });
                    break;
                case 'intake-schemas':
                    await $api.getAllIntakeSchemas().then((response) => {
                        this.nodeTypeModelOptions = response;
                    }).catch((error) => {
                        console.log('[-] Error on getAllIntakeSchemas: ', error);
                    });
                    break;

                case 'machine_learning_jobs':
                    await $api.getAllMachineLearningJobs().then((response) => {
                        this.nodeTypeModelOptions = response;
                    }).catch((error) => {
                        console.log('[-] Error on getAllMachineLearningJobs: ', error);
                    });
                    break;

                case 'machine_learning_tasks':
                    await $api.getAllMachineLearningTasks().then((response) => {
                        this.nodeTypeModelOptions = response;
                    }).catch((error) => {
                        console.log('[-] Error on getAllMachineLearningTasks: ', error);
                    });
                    break;
                case 'tasks':
                    await $api.getAllTasks().then((response) => {
                        this.nodeTypeModelOptions = response;
                    }).catch((error) => {
                        console.log('[-] Error on getAllTasks: ', error);
                    });
                    break;
                default:
                    break;
            }
        },
        isInModelsOrSources(source) {
            for (let i = 0; i < this.sources_and_models.models.length; i++) {
                if (this.sources_and_models.models[i] == source) {
                    return 'model'
                }
            }

            return 'source';

        },
        setIcon(type) {
            switch (type) {
                case 'incidents':
                    this.newObjectNode.data.icon = 'pi-briefcase';
                    break;
                case 'events':
                    this.newObjectNode.data.icon = 'pi-exclamation-triangle';
                    break;
                case 'assets':
                    this.newObjectNode.data.icon = 'pi-server';
                    break;
                case 'listeners':
                    this.newObjectNode.data.icon = 'pi-filter';
                    break;
                case 'alerts':
                    this.newObjectNode.data.icon = 'pi-cog';
                    break;
                case 'correlation_rules':
                    this.newObjectNode.data.icon = 'pi-link';
                    break;
                case 'detection_rules':
                    this.newObjectNode.data.icon = 'pi-flag';
                    break;
                case 'tasks':
                    this.newObjectNode.data.icon = 'pi-calendar';
                    break;
                case 'users':
                    this.newObjectNode.data.icon = 'pi-user';
                    break;
                case 'teams':
                    this.newObjectNode.data.icon = 'pi-users';
                    break;
                case 'dashboards':
                    this.newObjectNode.data.icon = 'pi-chart-line';
                    break;
                case 'sources':
                    this.newObjectNode.data.icon = 'pi-cloud';
                    break;
                case 'logs':
                    this.newObjectNode.data.icon = 'pi-file';
                    break;
                case 'intake_methods':
                    this.newObjectNode.data.icon = 'pi-envelope';
                    break;
                case 'intake_schemas':
                    this.newObjectNode.data.icon = 'pi-file';
                    break;
                case 'machine_learning_jobs':
                    this.newObjectNode.data.icon = 'pi-calculator';
                    break;
                case 'machine_learning_tasks':
                    this.newObjectNode.data.icon = 'pi-calculator';
                    break;
                case 'tasks':
                    this.newObjectNode.data.icon = 'pi-calendar';
                    break;
                case 'none':
                    this.newObjectNode.data.icon = 'pi-box';
                    break;
                default:
                    break;
            }
        },
        setColor(type) {
            if (this.newNodeSelectedObject.severity) {
                switch (this.newNodeSelectedObject.severity) {
                    case 0:
                        this.newObjectNode.data.icon_color = '#aeb5c1';
                        break;
                    case 1:
                        this.newObjectNode.data.icon_color = '#6dc193';
                        break;
                    case 2:
                        this.newObjectNode.data.icon_color = '#f7b34c';
                        break;
                    case 3:
                        this.newObjectNode.data.icon_color = '#ef6154';
                        break;
                    case 4:
                        this.newObjectNode.data.icon_color = '#b83c32';
                        break;
                    default:
                        break;
                }
            }
            if (this.newNodeSelectedObject.criticality) {
                switch (this.newNodeSelectedObject.criticality) {
                    case 0:
                        this.newObjectNode.data.icon_color = '#aeb5c1';
                        break;
                    case 1:
                        this.newObjectNode.data.icon_color = '#6dc193';
                        break;
                    case 2:
                        this.newObjectNode.data.icon_color = '#f7b34c';
                        break;
                    case 3:
                        this.newObjectNode.data.icon_color = '#ef6154';
                        break;
                    case 4:
                        this.newObjectNode.data.icon_color = '#b83c32';
                        break;
                    default:
                        break;
                }
            }
        },
        handleClickOutside(event) {
            console.log("handleClickOutside: ", event);
            let targetElement = document.getElementById('node_details_div');
            console.log("targetElement: ", targetElement);
            if (!targetElement.contains(event.target) && this.isNodeDetails) {
                this.unfocusSelectedNode()
                this.unfocusSelectedEdge()
                this.isNodeDetails = false;
                this.isEdgeOptions = false;
            }
        }
    },
    async mounted() {
        const userStore = useUserStore();
        const userObject = await userStore.getUser();

        //document.addEventListener('click', this.handleClickOutside);
        this.getSources();

        this.flow_chart = await require('./TestObj.json')

        console.log("flow_chart: ", this.flow_chart);

        this.focusFirstNode();
        this.focusFirstEdge();
    }
};
export default MyShared;