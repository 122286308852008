<!----------------------------------------------->
<!--                  TEMPLATE                 -->
<!----------------------------------------------->
<template>
  <MobileDocumentation v-if="  mq.current  === 'mobile'"></MobileDocumentation>
  <TabletDocumentation v-if="  mq.current  === 'tablet'"></TabletDocumentation>
  <DesktopDocumentation v-if=" mq.current  === 'desktop'"></DesktopDocumentation>
</template>

<!----------------------------------------------->
<!--                    SCRIPT                 -->
<!----------------------------------------------->
<script>
import MobileDocumentation from "../components/Documentation/MobileDocumentation";
import TabletDocumentation from "../components/Documentation/TabletDocumentation";
import DesktopDocumentation from "../components/Documentation/DesktopDocumentation";

export default {
  /* Name */
  name: "Documentation",
  inject: ["mq"],
  /* Components */
  components: {
    MobileDocumentation,
    TabletDocumentation,
    DesktopDocumentation,
  },
};
</script>

<script setup>
import { useMq } from "vue3-mq";
const mq = useMq();
</script>

<!----------------------------------------------->
<!--                    STYLES                 -->
<!----------------------------------------------->
<style scoped >
</style>
