<!----------------------------------------------->
<!--                  TEMPLATE                 -->
<!----------------------------------------------->
<template>
  <MobileAlertConfiguration v-if="  mq.current  === 'mobile'"></MobileAlertConfiguration>
  <TabletAlertConfiguration v-if="  mq.current  === 'tablet'"></TabletAlertConfiguration>
  <DesktopAlertConfiguration v-if=" mq.current  === 'desktop'"></DesktopAlertConfiguration>
</template>

<!----------------------------------------------->
<!--                    SCRIPT                 -->
<!----------------------------------------------->
<script>
import MobileAlertConfiguration from "../components/AlertConfiguration/MobileAlertConfiguration";
import TabletAlertConfiguration from "../components/AlertConfiguration/TabletAlertConfiguration";
import DesktopAlertConfiguration from "../components/AlertConfiguration/DesktopAlertConfiguration";

export default {
  /* Name */
  name: "AlertConfiguration",
  inject: ["mq"],
  /* Components */
  components: {
    MobileAlertConfiguration,
    TabletAlertConfiguration,
    DesktopAlertConfiguration,
  },
};
</script>

<script setup>
import { useMq } from "vue3-mq";
const mq = useMq();
</script>

<!----------------------------------------------->
<!--                    STYLES                 -->
<!----------------------------------------------->
<style scoped >
</style>
