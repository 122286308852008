<!----------------------------------------------->
<!--                  TEMPLATE                 -->
<!----------------------------------------------->
<template>
  <MobileEventsDetails v-if="  mq.current  === 'mobile'"></MobileEventsDetails>
  <TabletEventsDetails v-if="  mq.current  === 'tablet'"></TabletEventsDetails>
  <DesktopEventsDetails v-if=" mq.current  === 'desktop'"></DesktopEventsDetails>
</template>

<!----------------------------------------------->
<!--                    SCRIPT                 -->
<!----------------------------------------------->
<script>
import MobileEventsDetails from "../components/EventsDetails/MobileEventsDetails";
import TabletEventsDetails from "../components/EventsDetails/TabletEventsDetails";
import DesktopEventsDetails from "../components/EventsDetails/DesktopEventsDetails";

export default {
  /* Name */
  name: "EventsDetails",
  inject: ["mq"],
  /* Components */
  components: {
    MobileEventsDetails,
    TabletEventsDetails,
    DesktopEventsDetails,
  },
};
</script>

<script setup>
import { useMq } from "vue3-mq";
const mq = useMq();
</script>

<!----------------------------------------------->
<!--                    STYLES                 -->
<!----------------------------------------------->
<style scoped >
</style>
