<!----------------------------------------------->
<!--                  TEMPLATE                 -->
<!----------------------------------------------->
<template>
  <div class="aux">
    <Sitebar></Sitebar>
    <div class="maincontainer" >
      <ConfirmDialog></ConfirmDialog>
      <!-- ==================================================================================================================================================== -->
      <!-- =================================================================== Top Buttons ==================================================================== -->
      <!-- ==================================================================================================================================================== -->
      <div class="row">
        <div class="col-6 p-0"></div>
        <div class="col-6 top-config">
          <i class="pi pi-replay top-config-icon" @click="$router.go()"><span class="top-config-label">&nbsp;{{
              $t('refresh')
          }}</span></i>
          <div class="dropdown" v-if="showActions">
            <i class="pi pi-cog top-config-icon dropdown-toggle" data-toggle="dropdown"><span
                class="top-config-label">&nbsp;{{ $t('actions') }}</span></i>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown" style="width:max-content">
              <a class="dropdown-item" @click="editMode = false" v-if="editMode">
                <p><i class="pi pi-times"></i>&nbsp;{{ $t('closeEditMode') }}</p>
              </a>
              <section v-for="(action, index) in actions" :key="action" @click="action.callback()">
                <a class="dropdown-item" v-if="action.permit">
                  <p><i :class="action.icon"></i>&nbsp;{{ action.label }}</p>
                </a>
              </section>
            </div>
          </div>
        </div>
      </div>
      <!-- ==================================================================================================================================================== -->
      <!-- ======================================================================= Top ======================================================================= -->
      <!-- ==================================================================================================================================================== -->
      <div class="row" style="width:100%">
        <div class="col-12 ">
          <Card>
            <template #content>
              <div class="row pl-4">
                <div class="col-2 pt-0" v-if="!loading_table">
                  <i class="fa-solid fa-filter top-tab-icon"></i>
                  <span class="top-tab-text">
                    {{ data.length }}
                    <i class="fa-solid fa-caret-up top-tab-subicon">
                      <span class="top-tab-subicon-text">&nbsp;{{ newEvents }}</span>
                    </i>
                  </span>
                  <p class="top-tab-subtext mb-0">{{ $t('listenersTotal') }} </p>
                </div>
                <div class="col-2" v-else><ContentLoader></ContentLoader></div>
                <div class="col-2 pt-0" v-if="!loading_table">
                  <i class="fa-solid fa-play top-tab-icon"></i>
                  <span class="top-tab-text">
                    {{ activeListeners }}
                  </span>
                  <p class="top-tab-subtext mb-0">{{ $t('activeListeners') }} </p>
                </div>
                <div class="col-2" v-else><ContentLoader></ContentLoader></div>
                <div class="col-2 pt-0" v-if="!loading_table">
                  <i class="fa-solid fa-pause top-tab-icon"></i>
                  <span class="top-tab-text">
                    {{ inactiveListeners }}
                  </span>
                  <p class="top-tab-subtext mb-0">{{ $t('unactiveListeners') }} </p>
                </div>
                <div class="col-2" v-else><ContentLoader></ContentLoader></div>
              </div>
            </template>
          </Card>
        </div>
        <!-- ==================================================================================================================================================== -->
        <!-- ===================================================================== EditMenu ===================================================================== -->
        <!-- ==================================================================================================================================================== -->
        <div v-if="editMode" class="col-12 ">
            <Card>
              <template #content>
                <div class="row pl-2">
                  <p><strong>{{$t('editOptions')}}</strong></p>
                  <div class="col-12">
                    <i class="pi pi-times mr-3" style="position: absolute; top:0; right:0; margin-top: -20px; cursor: pointer;" @click="editMode = false"></i>
                    <Button class="btn-custom-save" icon="pi pi-trash" :label="$t('deleteSelected')" @click="deleteData()"></Button>
                  </div>
                </div>
              </template>
            </Card>
          </div>
        <!-- ==================================================================================================================================================== -->
        <!-- ======================================================================= Table ======================================================================= -->
        <!-- ==================================================================================================================================================== -->
        <div :class="tab_position ? 'col-8' : 'col-12 mb-0'">
          <div class="pad-helper main-table pt-2 pr-2 pl-2 m-0 pb-3" style="height:100%;">
            <span class="p-input-icon-left" style="width:100%;">
              <InputText v-model="filters['global'].value" :placeholder="$t('searchByAsset')" class="search-input mb-2">
              </InputText>
            </span>
           <div class="loader text-center mt-2 mt-2" style="width:100%;" v-if="loading_table">
            <ContentLoader width="100%"></ContentLoader>
            </div>
            <DataTable v-else :value="data" :paginator="true" class="p-datatable-customers" :rows="tab_position ? 15 : 6"
              :row-hover="true" v-model:selection="selectedData" dataKey="id" v-model:filters="filters"
              filter-display="menu" :loading="loading_table" :selectionMode="(!editMode)?'single':''"
              paginator-template="PrevPageLink PageLinks NextPageLink CurrentPageReport"
              :rows-per-page-options="[10, 25, 50]"
              current-page-report-template="Showing {first} to {last} of {totalRecords} entries"
              :global-filter-fields="['id', 'name', 'is_active']"
              responsive-layout="scroll" @row-click="setSelectedData($event.data)">
              <template #empty>
                {{$t('noDataFound')}}
              </template>
              <template #loading>
               {{$t('loadingData')}}
              </template>
              <Column selection-mode="multiple" v-if="editMode"></Column>
              <Column field="name" :header="$t('name')" sortable>
                <template #body="{ data }">
                  <span @click="$router.push({ path: 'source-details', query: { id: data.intake_schema.source_id } })" class="row-name-link">
                    {{ data.name }}
                  </span>
                </template>
              </Column>
              <Column field="intake_schema" :header="$t('intakeSchema')" sortable>
                <template #body="{ data }">
                    {{ data.intake_schema.name }}
                </template>
              </Column>
              <Column field="intake_method" :header="$t('intakeMethod')" sortable>
                <template #body="{ data }">
                    {{ data.intake_method.name }}
                </template>
              </Column>
              <Column field="is_active" :header="$t('isActive')" sortable>
                <template #body="{ data }">
                  <span v-if="data.is_active"><i class="fa-solid fa-circle-exclamation" style="color:#6dc193"></i>&nbsp;{{
                      $t('yes')
                  }}</span>
                  <span v-if="!data.is_active"><i class="fa-solid fa-circle-exclamation" style="color:#ef6154"></i>&nbsp;{{
                      $t('no')
                  }}</span>
                </template>
              </Column>
            </DataTable>
          </div>
        </div>

        <!-- ==================================================================================================================================================== -->
        <!-- ======================================================================= Tabs ======================================================================= -->
        <!-- ==================================================================================================================================================== -->

        <div :class="tab_position ? 'col-4 pl-0' : 'col-12'" :style="tab_position ? 'height:auto;' : 'height:35vh;'">
          <div class="pad-helper main-table pt-2 pr-2 pl-2 m-0" style="height:100%;">
            <div class="row">
              <div class="change-pos-div">
                <img v-if="tab_position" style="float:right; cursor:pointer; height:1.25em"
                  @click="tab_position = !tab_position" :src="require('@/assets/icon-bottom.svg')">
                <img v-if="!tab_position" style="float:right; cursor:pointer; height:1.25em"
                  @click="tab_position = !tab_position" :src="require('@/assets/icon-right.svg')">
                <!-- 
                  <i v-if="tab_position" class="fa-solid fa-turn-down icon-flipped" style="float:right; cursor:pointer;"
                    @click="tab_position = !tab_position"></i>
                  <i v-if="!tab_position" class="fa-solid fa-turn-up" style="float:right; cursor:pointer;"
                    @click="tab_position = !tab_position"></i>
                  -->
              </div>
            </div>
            <div class="loader text-center mt-4" style="width:100%;" v-if="loading_table">
              <ContentLoader width="100%"></ContentLoader>
            </div>
            <div v-else class="row">
              <TabView v-if="details">
                <!-- ====================================================================== Details ============================================================================== -->
                <TabPanel :header="$t('details')">
                  <div class="loader text-center mt-2 mt-2" style="width:100%;" v-if="loading_details">
                    <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                    <p>{{ $t('loading') }}</p>
                  </div>
                  <ScrollPanel :style="tab_position ? 'width: 100%;height:35em' : 'width: 100%;height:15em'" class="custom" v-else>
                    <div class="sub-scroll pl-3 pr-3 pb-4" :style="tab_position ? 'height:auto;' : 'height:25em;'">
                      <div class="row details-top">
                        <i class="fa-solid fa-yaveremos detail-icon"><span class="detail-name">{{ details.name
                        }}</span></i>
                        <p class="mb-0 pl-0"
                          @click="$router.push({ path: 'source-details', query: { id: details.intake_schema.source_id } })">
                          <span class="id-prefix" style="text-decoration:none;">Listener ID:</span> <span class="details-id">
                            {{ details.id }}
                          </span>
                        </p>
                      </div>
                      <div class="row details-data">
                        <div class="col-12 p-0 dropdown">
                          <Card data-toggle="dropdown">
                              <template #content>
                                <i v-if="details.is_active" class="fa-solid fa-circle-check subdetail-icon"
                                  style="color:#6dc193">
                                  <p class="subdetail-name dropdown-toggle" v-if="details.is_active">&nbsp;{{
                                    $t('activated')
                                  }}</p>
                                  <p class="subdetail-tag">&nbsp;{{ $t('isActive') }}</p>
                                </i>
                                <i v-else class="fa-solid fa-circle-xmark subdetail-icon dropdown" style="color:#ef6154">
                                  <p class="subdetail-name dropdown-toggle" v-if="!details.is_active">&nbsp;{{
                                    $t('deactivated')
                                  }}</p>
                                  <p class="subdetail-tag">&nbsp;{{ $t('isActive') }}</p>
                                </i>
                              </template>
                            </Card>
                            <div class="dropdown-menu">
                              <a class="dropdown-item" v-for="status in statuses" :key="status" @click="(status.value == details.is_active) ? null:(!status.value ? pause(details.id) : play(details.id))">
                                {{ status.label }}
                              </a>
                            </div>
                        </div>
                      </div>
                      <div class="row details-data">
                        <div :class="tab_position ? 'col-12 pr-0 pl-0' : 'col-6 pr-4'">
                          <div class="row">
                            <p class="mt-0 mb-0 details-title"><strong>{{ $t('description') }}</strong></p>
                            <p class="">{{ details.description }}</p>
                            <p class="mt-0 mb-0 details-title"><strong>{{ $t('nifiListener') }}</strong></p>
                            <p class="">{{ details.flow_nifi }}</p>
                            <p class="mt-0 mb-0 details-title"><strong>{{ $t('outputIndex') }}</strong></p>
                            <p class="">{{ details.output_index }}</p>
                          </div>
                        </div>
                        <div :class="tab_position ? 'col-12 pr-0 pl-0 pt-0 pb-4' : 'col-6 pr-4'">
                          <div class="row mb-2" style="width:98%;">
                            <p class="mt-0 mb-2 details-title"><strong>{{ $t('intakeMethod') }}</strong></p>
                            <div class="row tab-detection-card">
                              <p class="mt-0 mb-0"><strong>{{ details.intake_method.name }}</strong></p> <!-- Aqui el nombre del intakemethod -->
                              <p class="mt-0 mb-0" style="font-size: 0.9em;">{{ details.intake_method.description }} <!-- Aqui la descripcion del intakemethod -->
                                <div class="row p-0 m-0">
                                  <div class="col-4 pt-0 pb-0 pl-0 pr-1" v-for="(param, index) in details.configuration" :key="param">
                                      <p class="mb-0"><small style="font-size:0.75em; color:lightgray;">{{ index.toUpperCase() }}</small></p>
                                      <p class="mb-0">{{ details.configuration[index] }}</p>
                                  </div>
                                </div>
                              </p>
                            </div>
                          </div>
                          <div class="row" style="margin-top:1em; width:98%; padding-bottom:2em;">
                            <p class="mt-2 mb-2 details-title"><strong>{{ $t('intakeSchema') }}</strong></p>
                            <div class="row tab-detection-card">
                              <p class="mt-0 mb-0"><strong>{{ details.intake_schema.name }}</strong></p>
                              <p class="mt-0 mb-0" style="font-size: 0.9em;">{{ details.intake_schema.description }}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ScrollPanel>
                </TabPanel>
              </TabView>
            </div>
          </div>
        </div>
      </div>
    </div>
        <!--============================================================================== createData ==============================================================================-->
        <Modal ref="createData">
      <template #body>
        <div class="row">
          <div class="col-12 text-center pt-4">
            <i class="pi pi-th-large" style="font-size:2.5em;"></i>
          </div>
          <div class="col-12 text-center pt-0 pb-0">
            <h5 class="modal-title">{{ $t('createNewEvent') }}</h5>
            {{ $t('or') }}
            <h5 class="mt-2"><FileUpload mode="basic" name="demo[]" :auto="true" :chooseLabel="(uploadLoading)?$t('loading'):$t('import')" :disabled="uploadLoading" :maxFileSize="1000000" @uploader="onUploadOne()" customUpload /><Helper :hlp_msg="$t('importFileHelper')"></Helper>
                </h5>
          </div>
        </div>
        <div class="row mt-4">
          <Steps :model="items" :readonly="false" class="mt-4">
            <template #item="{item}">
                <a :style="(item.step == step)?'color:#ec635f; font-weight: 700; cursor:pointer;':'color:black; cursor:pointer;'" @click="step = item.step">{{item.label}}</a>
            </template>
          </Steps>
        </div>
        <div class="row" v-if="step == 0">
          <div class="col-12 p-4">
            <label for="" class="pl-0 pb-0 mb-0">{{ $t('name') }}</label>
            <InputText type="text" v-model="new_alert_name" :placeholder="$t('alertName')"
              style="width:100%;">
            </InputText>
            <label for="" class="pl-0 pb-0 mb-0 mt-1">{{ $t('description') }}</label>
            <Textarea type="text" v-model="new_alert_description" :placeholder="$t('alertDescription')"
              style="width:100%;">
            </Textarea>
            <label for="" class="pl-0 pb-0 mb-0 mt-1">{{ $t('tags') }}</label>
            <Chips v-model="new_alert_tags" :disabled="locked" style="width:100%;" />
          </div>
        </div>
        <div class="row" v-if="step == 1">
          <div class="col-12 p-4">

          </div>
        </div>
        <div class="row" v-if="step == 2">
          <div class="col-12 p-4">

          </div>
        </div>
        <div class="row" v-if="step == 3">
          <div class="col-12 p-4">

          </div>
        </div>
        <div class="row" v-if="step == 4">
          <div class="col-12 p-4">

          </div>
        </div>

      </template>
      <template #footer>
        <Button v-if="step != 0" class="btn-custom-save" :label="$t('previous')" @click="step = step-1"></Button>
        <Button v-if="step != 4" class="btn-custom-save" :label="$t('next')" @click="step = step+1"></Button>
        <Button v-if="step == 4" class="btn-custom" :label="$t('save')" @click="addNewIntegration(new_data_source_name,new_data_source_description)"></Button>
      </template>
    </Modal>
    <Modal ref="importData">
      <template #body>
        <div class="row">
          <div class="col-12 text-center pt-4">
            <i class="pi pi-th-large" style="font-size:2.5em;"></i>
          </div>
          <div class="col-12 text-center pt-0 pb-0">
            <h5 class="modal-title">{{ $t('import') }} {{ $t('assets') }}</h5>
            <h5 class="mt-3">
              <FileUpload mode="basic" name="demo[]" :auto="true" :chooseLabel="(uploadLoading)?$t('loading'):$t('import')" :disabled="uploadLoading" :maxFileSize="1000000"
                @uploader="onUploadVarious()" customUpload />
                
            </h5>
          </div>
        </div>
      </template>
      <template #footer>
      </template>
    </Modal>
  </div>
</template>
<!----------------------------------------------->
<!--                    SCRIPT                 -->
<!----------------------------------------------->

<script>
import MyShared from "./shared";
export default {
  mixins: [MyShared],
};

</script>

<script setup>
import { useMq } from "vue3-mq";
const mq = useMq();
</script>

<!----------------------------------------------->
<!--                    STYLES                 -->
<!----------------------------------------------->
<style>
</style>
