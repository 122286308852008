<!----------------------------------------------->
<!--                  TEMPLATE                 -->
<!----------------------------------------------->
<template>
  <MobileIncidentsDetails v-if="  mq.current  === 'mobile'"></MobileIncidentsDetails>
  <TabletIncidentsDetails v-if="  mq.current  === 'tablet'"></TabletIncidentsDetails>
  <DesktopIncidentsDetails v-if=" mq.current  === 'desktop'"></DesktopIncidentsDetails>
</template>

<!----------------------------------------------->
<!--                    SCRIPT                 -->
<!----------------------------------------------->
<script>
import MobileIncidentsDetails from "../components/IncidentsDetails/MobileIncidentsDetails";
import TabletIncidentsDetails from "../components/IncidentsDetails/TabletIncidentsDetails";
import DesktopIncidentsDetails from "../components/IncidentsDetails/DesktopIncidentsDetails";

export default {
  /* Name */
  name: "IncidentsDetails",
  inject: ["mq"],
  /* Components */
  components: {
    MobileIncidentsDetails,
    TabletIncidentsDetails,
    DesktopIncidentsDetails,
  },
};
</script>

<script setup>
import { useMq } from "vue3-mq";
const mq = useMq();
</script>

<!----------------------------------------------->
<!--                    STYLES                 -->
<!----------------------------------------------->
<style scoped >
</style>
