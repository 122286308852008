import Sitebar from "../../components/Partials/Sitebar/SiteBar.vue"
import Helper from "../../components/Partials/Helper/Helper.vue"
import { FilterMatchMode, FilterOperator } from 'primevue/api';
import { nextTick } from "vue";
import OpenGroup from "../../components/Partials/OpenGroup/OpenGroup.vue"
import Timeline from "../../components/Partials/Timeline/Timeline.vue"
import Chart from 'primevue/chart';
import { ref } from "vue";
import { useUserStore } from '../../store/store.js'
import * as $api from "../../api/api";

var MyShared = {
    /* Name */
    name: "Home",
    /* Data */
    setup() {
        const separatorExp = ref(/,| /);
    },
    data() {
        return {
            user: {
                "permissions": []
            },
            close: true,
            locked: true,
            loading_details: false,
            details: {
                "name": "",
                "description": "",
                "os": "",
                "hostname": "",
                "type": "",
                "criticality": 0,
                "risk": true,
                "location": {},
                "tags": [],
                "ip_addr": [],
                "mac_addr": [],
                "first_date": "",
                "last_date": "",
                "id": "",
                "asset_groups": [],
            },
            events: [],
            incidents: [],
            sources: [],
            alerts: [{
                "name": "Alert SOC (Tier 1) when DDOS on production server",
                "description": "Alert SOC-Tier1 configuration is triggered when DDOS is detected on production server",
                "severity": 1,
                "filters": [
                    "DDoS",
                    "RCE",
                    "SQLi"
                ],
                "users": [
                    { full_name: "John Doe" },
                ],
                "alertMethods": [{
                    "name": "Email",
                    "description": "Send email to the user",
                    "method": "email"
                }],
                "id": "a5f7f9d9-3229-4506-8cff-95bbfb9b6d64",
                correlation_rules: [{
                    "id": "8944febb-2162-4d9d-b3f0-9fc89e16d3d9",
                    "title": "DDOS Attack",
                    severity: 4,
                    is_active: true,
                }],
                detection_rules: [{
                    "id": "e85f1784-bf05-4142-9a41-9b731f53d85a",
                    "title": "Console login failure",
                    severity: 0,
                    is_active: true,
                }]
            }],
            loading_details: false,
            // Preguntar que tipos hay
            types: [
                { label: 'Server', value: 'Server' },
                { label: 'Router', value: 'Router' },
                { label: 'Switch', value: 'Switch' },
                { label: 'Firewall', value: 'Firewall' },
                { label: 'Workstation', value: 'Workstation' },
                { label: 'Printer', value: 'Printer' },
                { label: 'Other', value: 'Other' }
            ],
            criticalities: [
                { label: this.$t('low'), value: 1 },
                { label: this.$t('medium'), value: 2 },
                { label: this.$t('high'), value: 3 },
                { label: this.$t('critical'), value: 4 }
            ],
            separatorExp: (/,| /),
            lineChartData: {
                labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
                datasets: [{
                        label: 'Critical',
                        data: [65, 59, 80, 81, 56, 55, 40],
                        fill: false,
                        tension: .4,
                        borderColor: '#b83c32'
                    },
                    {
                        label: 'High',
                        data: [28, 48, 40, 19, 86, 27, 90],
                        fill: false,
                        tension: .4,
                        borderColor: '#ef6154'
                    },
                    {
                        label: 'Medium',
                        data: [12, 51, 62, 33, 21, 62, 45],
                        fill: false,
                        borderColor: '#f7b34c',
                        tension: .4,
                        /*backgroundColor: 'rgba(255,167,38,0.2)'*/
                    },
                    {
                        label: 'Low',
                        data: [12, 80, 41, 60, 72, 10, 35],
                        fill: false,
                        borderColor: '#6dc193',
                        tension: .4,
                    },
                    {
                        label: 'Informational',
                        data: [30, 25, 40, 80, 52, 62, 13],
                        fill: false,
                        borderColor: '#aeb5c1',
                        tension: .4,
                    }
                ]
            },
            lineChartOptions: {
                responsive: true,
                plugins: {
                    legend: {
                        labels: {
                            color: '#495057'
                        }
                    }
                },
                scales: {
                    x: {
                        ticks: {
                            color: '#495057'
                        },
                        grid: {
                            color: '#ebedef'
                        }
                    },
                    y: {
                        ticks: {
                            color: '#495057'
                        },
                        grid: {
                            color: '#ebedef'
                        }
                    }
                }
            },
            barData: {
                labels: [this.$t('informational'), this.$t('low'), this.$t('medium'), this.$t('high'), this.$t('critical')],
                datasets: [{
                    backgroundColor: ['#c2c2c2', '#e8a8a8', '#d96e6e', '#cf4545', '#8b1717'],
                    data: [65, 59, 80, 81, 56]
                }, ]
            },
            barOptions: {
                indexAxis: 'y',
                plugins: {
                    legend: {
                        display: false,
                        labels: {
                            color: '#495057'
                        }
                    }
                },
                scales: {
                    x: {
                        ticks: {
                            color: '#495057'
                        },
                        grid: {
                            color: '#ebedef'
                        }
                    },
                    y: {
                        ticks: {
                            color: '#495057'
                        },
                        grid: {
                            color: '#ebedef'
                        }
                    }
                }
            },
            pieData1: {
                labels: ['A', 'B', 'C', 'D', 'E'],
                datasets: [{
                    data: [540, 325, 702, 421, 256],
                    backgroundColor: ['#f28263', '#a3dfc9', '#ffd366', '#f6a437', '#b0b0b0'],
                }]
            },
            pieData2: {
                labels: ['A', 'B', 'C', 'D', 'E'],
                datasets: [{
                    data: [540, 325, 702, 421, 256],
                    backgroundColor: ['#aeb5c1', '#6dc193', '#f7b34c', '#ef6154', '#b83c32'],
                }]
            },
            pieData3: {
                labels: ['A', 'B', 'C', 'D', 'E'],
                datasets: [{
                    data: [540, 325, 702, 421, 256],
                    backgroundColor: ['#f28263', '#a3dfc9', '#ffd366', '#f6a437', '#b0b0b0'],
                }]
            },
            pieData4: {
                labels: ['A', 'B', 'C', 'D', 'E'],
                datasets: [{
                    data: [540, 325, 702, 421, 256],
                    backgroundColor: ['#aeb5c1', '#6dc193', '#f7b34c', '#ef6154', '#b83c32'],
                }]
            },
            pieOptions: {
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        labels: {
                            boxWidth: 15,
                            color: '#495057'
                        }
                    }
                }
            },
            filters: null,
            editMode: false,
            events_timeline: [],
            incidents_timeline: [],
        }
    },
    /* Components */
    components: {
        Sitebar,
        Helper,
        OpenGroup,
        Chart,
        Timeline
    },
    /* Props */
    props: {},
    /* Methods */
    methods: {
        formatDate(date) {
            if (date == null || date == '') return;

            // Format date to dd/mm/yyyy hh:mm
            let d = new Date(date);
            let day = d.getDate();
            let month = d.getMonth() + 1;
            let year = d.getFullYear();
            let hour = d.getHours();
            let minutes = d.getMinutes();
            // Add 0 if month is less than 10
            if (month < 10) {
                month = "0" + month;
            }
            // Add 0 if day is less than 10
            if (day < 10) {
                day = "0" + day;
            }
            // Add 0 if minutes is less than 10
            if (minutes < 10) {
                minutes = "0" + minutes;
            }
            return `${day}/${month}/${year} ${hour}:${minutes}`;
        },
        lockForm() {
            this.locked = true;
            let dropdowns = document.getElementsByClassName("dropdown-menu");
            for (let i = 0; i < dropdowns.length; i++) {
                dropdowns[i].classList.remove("show");
            }
            /*
            let toggles = document.getElementsByClassName("dropdown-toggle")
            for (let i = 0; i < toggles.length; i++) {
                toggles[i].setAttribute("aria-expanded", 'false')
            }
            */
        },
        unlockForm() {
            this.locked = false;
        },
        onCancelUpdate() {
            this.details.ip_addr = this.ips;
            this.details.mac_addr = this.macs;
            this.details.tags = this.tags;
            this.details.description = this.desc;
            this.details.hostname = this.hostname;
            this.details.criticality = this.criticality;
            this.details.type = this.type;
            this.details.location = this.location;
            this.lockForm();
        },
        onSaveUpdate() {
            // Update asset via API and update local data
            $api.updateAsset(this.$route.query.id, {
                "ip_addr": this.details.ip_addr,
                "mac_addr": this.details.mac_addr,
                "tags": this.details.tags,
                "description": this.details.description,
                "hostname": this.details.hostname,
                "criticality": this.details.criticality,
                "type": this.details.type,
                "location": this.details.location
            }).then((response) => {
                console.log(`[+] Updated asset: ${response.data}`);
                this.ips = this.details.ip_addr;
                this.macs = this.details.mac_addr;
                this.tags = this.details.tags;
                this.desc = this.details.description;
                this.hostname = this.details.hostname;
                this.criticality = this.details.criticality;
                this.type = this.details.type;
                this.location = this.details.location;
                // toast
                this.$toast.add({ severity: 'success', summary: this.$t('success'), detail: this.$t('assetUpdated'), life: 3000 });
                this.lockForm();
            }).catch((err) => {
                console.log(`[-] Error (getOneAsset): ${err}`);
                // toast
                this.$toast.add({ severity: 'error', summary: this.$t('error'), detail: this.$t('assetNotUpdated'), life: 3000 });
            });
        },
        /* Create charts */
        createChartData() {
            // get last 7 days
            let today = new Date();
            let labels = [];
            for (let i = 0; i < 7; i++) {
                let d = new Date(today);
                d.setDate(d.getDate() - i);
                d = this.formatDate(d);
                labels.push(d);
            }
            labels.reverse();
            this.lineChartData.labels = labels;

            // get all details.events and calculate the number of events per severity
            let informational = 0;
            let low = 0;
            let medium = 0;
            let high = 0;
            let critical = 0;
            for (let i = 0; i < this.details.events.length; i++) {
                switch (this.details.events[i].severity) {
                    case 0:
                        informational++;
                        break;
                    case 1:
                        low++;
                        break;
                    case 2:
                        medium++;
                        break;
                    case 3:
                        high++;
                        break;
                    case 4:
                        critical++;
                        break;
                }
            }
            this.pieData2.labels = ['Informational', 'Low', 'Medium', 'High', 'Critical'];
            this.pieData2.datasets[0].data = [informational, low, medium, high, critical];

            // get all details.events and calculate the number of events per status
            let new_ = 0;
            let open = 0;
            let partial_mitigated = 0;
            let mitigated = 0;
            let closed = 0;
            for (let i = 0; i < this.details.events.length; i++) {
                switch (this.details.events[i].status[this.details.events[i].status.length - 1].status) {
                    case 'new':
                        new_++;
                        break;
                    case 'active':
                        open++;
                        break;
                    case 'partial_mitigated':
                        partial_mitigated++;
                        break;
                    case 'mitigated':
                        mitigated++;
                        break;
                    case 'closed':
                        closed++;
                        break;
                }
            }
            this.pieData1.labels = ['New', 'Active', 'Partial Mitigated', 'Mitigated', 'Closed'];
            this.pieData1.datasets[0].data = [new_, open, partial_mitigated, mitigated, closed];
            console.log(this.pieData1.datasets[0].data);


            // get all details.incidents and calculate the number of events per severity
            informational = 0;
            low = 0;
            medium = 0;
            high = 0;
            critical = 0;
            for (let i = 0; i < this.details.incidents.length; i++) {
                switch (this.details.incidents[i].severity) {
                    case 0:
                        informational++;
                        break;
                    case 1:
                        low++;
                        break;
                    case 2:
                        medium++;
                        break;
                    case 3:
                        high++;
                        break;
                    case 4:
                        critical++;
                        break;
                }
            }
            this.pieData4.labels = ['Informational', 'Low', 'Medium', 'High', 'Critical'];
            this.pieData4.datasets[0].data = [informational, low, medium, high, critical];
            console.log(this.pieData4.datasets[0].data);

            // get all details.incidents and calculate the number of events per status
            new_ = 0;
            open = 0;
            partial_mitigated = 0;
            mitigated = 0;
            closed = 0;
            for (let i = 0; i < this.details.incidents.length; i++) {
                switch (this.details.incidents[i].status[this.details.incidents[i].status.length - 1].status) {
                    case 'new':
                        new_++;
                        break;
                    case 'active':
                        open++;
                        break;
                    case 'partial_mitigated':
                        partial_mitigated++;
                        break;
                    case 'mitigated':
                        mitigated++;
                        break;
                    case 'closed':
                        closed++;
                        break;
                }
            }
            this.pieData3.labels = ['New', 'Active', 'Partial Mitigated', 'Mitigated', 'Closed'];
            this.pieData3.datasets[0].data = [new_, open, partial_mitigated, mitigated, closed];
        },
        getSourceLogo(logo) {
            return process.env.VUE_APP_BASE_URL + '/' + logo;
        },
        checkChar(letter) {
            // check if letter is between a-i or A-I
            if ((letter >= 'a' && letter <= 'i') || (letter >= 'A' && letter <= 'I')) {
                return 'default-img-v1';
            }
            // check if letter is between j-r or J-R
            if ((letter >= 'j' && letter <= 'r') || (letter >= 'J' && letter <= 'R')) {
                return 'default-img-v2';
            }
            // check if letter is between s-z or S-Z
            if ((letter >= 's' && letter <= 'z') || (letter >= 'S' && letter <= 'Z')) {
                return 'default-img-v3';
            }
        },
        generateTimelineData() {
            // format { dateLabel: 'January 2017', title: 'Gathering Information' }
            // get all events
            for (let i = 0; i < this.details.events.length; i++) {
                let date = new Date(this.details.events[i].created_at);
                let dateLabel = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} ${date.getHours()}:${date.getMinutes()}`;
                let title = this.details.events[i].title;
                let id = this.details.events[i].id;
                let severity = this.details.events[i].severity;
                this.events_timeline.push({ dateLabel, title, id, severity });
            }
            // sort events by date
            this.events_timeline.sort((a, b) => {
                return new Date(a.dateLabel) - new Date(b.dateLabel);
            });

            // get all incidents
            for (let i = 0; i < this.details.incidents.length; i++) {
                let date = new Date(this.details.incidents[i].created_at);
                let dateLabel = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} ${date.getHours()}:${date.getMinutes()}`;
                let title = this.details.incidents[i].title;
                let id = this.details.incidents[i].id;
                let severity = this.details.incidents[i].severity;
                this.incidents_timeline.push({ dateLabel, title, id, severity });
            }
            // sort incidents by date
            this.incidents_timeline.sort((a, b) => {
                return new Date(a.dateLabel) - new Date(b.dateLabel);
            });

        }
    },
    async mounted() {
        const userStore = useUserStore();
        const userObject = await userStore.getUser();
        this.user = userObject;
        // get ASSET from API
        if (process.env.VUE_APP_ENV === 'development') {
            //await (this.data = require('./events.json'));
            //await (this.data_backup = require('./events.json'));
        } else if (process.env.VUE_APP_ENV === 'staging' || process.env.VUE_APP_ENV === 'production') {
            this.loading_details = true;
            console.log(this.$route.query.id);
            $api.getOneAsset(this.$route.query.id, true).then((response) => {
                this.details = response;
                
                this.ips = this.details.ip_addr;
                this.macs = this.details.mac_addr;
                this.tags = this.details.tags;
                this.desc = this.details.description;
                this.hostname = this.details.hostname;
                this.criticality = this.details.criticality;
                this.type = this.details.type;
                this.location = this.details.location;
                

                // create charts
                this.createChartData();
                this.generateTimelineData();
                console.log("ASSET: ", this.details);


                if (this.details.events.length == 0) {
                    this.no_events = true;
                }
                if (this.details.incidents.length == 0) {
                    this.no_incidents = true;
                }
                
                this.loading_details = false;
            }).catch((err) => {
                console.log(`[-] Error (getOneAsset): ${err}`);
            });
        } else {
            console.log("ERROR: NO ENVIRONMENT SELECTED");
        }
    }
};
export default MyShared;