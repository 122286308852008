
<!----------------------------------------------->
<!--                  TEMPLATE                 -->
<!----------------------------------------------->
<template>
    <div class="aux">
        <div class="percentage-bar" style="width:100% !important;">
            <div class="percentage-portion"
                :style="'display:inline-block; background:#f28263; width:' + (criticality_0) + '%;'"
                v-if="criticality_0 != -0.8">&nbsp;
            </div>
            <div class="percentage-portion" :style="'display:inline-block; background:white; width:1%;'"
                v-if="(criticality_0 != -0.8 && criticality_1 != -0.8) || (criticality_0 != -0.8 && criticality_1 == -0.8 && criticality_2 != -0.8) || (criticality_0 != -0.8 && criticality_1 == -0.8 && criticality_2 == -0.8 && criticality_3 != -0.8) || (criticality_0 != -0.8 && criticality_1 == -0.8 && criticality_2 == -0.8 && criticality_3 == -0.8 && criticality_4 != -0.8)">
            </div>

            <div class="percentage-portion"
                :style="'display:inline-block; background:#a3dfc9; width:' + (criticality_1) + '%;'"
                v-if="criticality_1 != -0.8">&nbsp;
            </div>
            <div class="percentage-portion" :style="'display:inline-block; background:white; width:1%;'"
                v-if="(criticality_1 != -0.8 && criticality_2 != -0.8) || (criticality_1 != -0.8 && criticality_3 != -0.8 && criticality_2 == -0.8) || (criticality_1 != -0.8 && criticality_4 != -0.8 && criticality_2 == -0.8 && criticality_3 == -0.8)">
            </div>

            <div class="percentage-portion"
                :style="'display:inline-block; background:#b0b0b0; width:' + (criticality_2) + '%;'"
                v-if="criticality_2 != -0.8">&nbsp;
            </div>
            <div class="percentage-portion" :style="'display:inline-block; background:white; width:1%;'"
                v-if="(criticality_2 != -0.8 && criticality_3 != -0.8) || (criticality_2 != -0.8 && criticality_4 != -0.8 && criticality_3 == -0.8)">
            </div>

            <div class="percentage-portion"
                :style="'display:inline-block; background:#ffd366; width:' + (criticality_3) + '%;'"
                v-if="criticality_3 != -0.8">&nbsp;
            </div>
            <div class="percentage-portion" :style="'display:inline-block; background:white; width:1%;'"
                v-if="criticality_3 != -0.8 && criticality_4 != -0.8">
            </div>
            <div class="percentage-portion"
                :style="'display:inline-block; background:#f6a437; width:' + (criticality_4) + '%;'"
                v-if="criticality_4 != -0.8">&nbsp;
            </div>
        </div>

        <div class="percentage-bar row" style="width: 90%;"> <!-- Si se encuentra algun bug poner a 82% -->
            <div class="pt-0 pb-0" style="display:ruby; width:auto;"><i class="pi pi-circle-fill"
                    style="color:#f28263; font-size: 0.7em;"></i>&nbsp;{{ $t('new') }}:
                <strong>{{ number_of_info_criticality }}</strong>
            </div>
            <div class="pt-0 pb-0" style="display:ruby; width:auto;"><i class="pi pi-circle-fill"
                    style="color:#a3dfc9; font-size: 0.7em;"></i>&nbsp;{{ $t('active') }}:
                <strong>{{ number_of_low_criticality }}</strong>
            </div>
            <div class="pt-0 pb-0" style="display:ruby; width:auto;"><i class="pi pi-circle-fill"
                    style="color:#ffd366; font-size: 0.7em;"></i>&nbsp;{{ $t('partiallyMitigated') }}:
                <strong>{{ number_of_high_criticality }}</strong>
            </div>
            <div class="pt-0 pb-0 pr-0" style="display:ruby; width:auto;"><i class="pi pi-circle-fill"
                    style="color:#f6a437; font-size: 0.7em;"></i>&nbsp;{{ $t('mitigated')
                    }}:
                <strong>{{ number_of_critical_criticality }}</strong>
            </div>
            <div class="pt-0 pb-0" style="display:ruby; width:auto;"><i class="pi pi-circle-fill"
                style="color:#b0b0b0; font-size: 0.7em;"></i>&nbsp;{{ $t('closed') }}:
            <strong>{{ number_of_medium_criticality }}</strong>
            </div>
        </div>
    </div>
</template>
<!----------------------------------------------->
<!--                    SCRIPT                 -->
<!----------------------------------------------->
<script>
export default {
    /* Name */
    name: "CustomChart",
    inject: ["mq"],
    /* Props*/
    props: {
        data: {

        },
        status: false
    },  
    data() {
        return {
            user: {
                "permissions": []
            },
            criticality_0: 0,
            criticality_1: 0,
            criticality_2: 0,
            criticality_3: 0,
            criticality_4: 0,
            number_of_low_criticality: 0,
            number_of_medium_criticality: 0,
            number_of_high_criticality: 0,
            number_of_critical_criticality: 0,
            number_of_info_criticality: 0,
        }
    },
    /* Components */
    components: {
    },
    methods: {
        calcPercentageByStatus() {
            let total = 0;
            let new_ = 0;
            let active = 0;
            let closed = 0;
            let partiallyMitigated = 0;
            let mitigated = 0;

            this.data.forEach(element => {
                switch (element.status[element.status.length - 1].status) {
                    case 'new':
                        new_++;
                        break;
                    case 'active':
                        active++;
                        break;
                    case 'closed':
                        closed++;
                        break;
                    case 'partial_mitigated':
                        partiallyMitigated++;
                        break;
                    case 'mitigated':
                        mitigated++;
                        break;
                }
                total++;
            });

            total = new_ + active + closed + partiallyMitigated + mitigated;
            // Set the percentage of each criticality
            this.new_ = (new_ / total) * 100
            this.active = (active / total) * 100
            this.closed = (closed / total) * 100
            this.partiallyMitigated = (partiallyMitigated / total) * 100
            this.mitigated = (mitigated / total) * 100

            // Prepare for the chart
            this.new_ = this.new_ - 0.8;
            this.active = this.active - 0.8;
            this.closed = this.closed - 0.8;
            this.partiallyMitigated = this.partiallyMitigated - 0.8;
            this.mitigated = this.mitigated - 0.8;

            // Need for the chart
            this.criticality_0 = this.new_;
            this.criticality_1 = this.active;
            this.criticality_2 = this.closed;
            this.criticality_3 = this.partiallyMitigated;
            this.criticality_4 = this.mitigated;

            // Number of elements 
            this.number_of_info_criticality = new_;
            this.number_of_low_criticality = active;
            this.number_of_medium_criticality = closed;
            this.number_of_high_criticality = partiallyMitigated;
            this.number_of_critical_criticality = mitigated;

        },
        calcPercentageByCriticality() {
            // Calculate the percentage of each criticality
            let criticality_0 = 0;
            let criticality_1 = 0;
            let criticality_2 = 0;
            let criticality_3 = 0;
            let criticality_4 = 0;
            let total = 0;
            this.data.forEach(element => {
                switch (element.severity) {
                    case 0:
                        criticality_0++;
                        break;
                    case 1:
                        criticality_1++;
                        break;
                    case 2:
                        criticality_2++;
                        break;
                    case 3:
                        criticality_3++;
                        break;
                    case 4:
                        criticality_4++;
                        break;
                }
                if (!element.severity) {
                    switch (element.criticality) {
                        case 0:
                            criticality_0++;
                            break;
                        case 1:
                            criticality_1++;
                            break;
                        case 2:
                            criticality_2++;
                            break;
                        case 3:
                            criticality_3++;
                            break;
                        case 4:
                            criticality_4++;
                            break;
                    }
                }
                total++;
            }

            );

            total = criticality_0 + criticality_1 + criticality_2 + criticality_3 + criticality_4;
            // Set the percentage of each criticality
            this.criticality_0 = (criticality_0 / total) * 100
            this.criticality_1 = (criticality_1 / total) * 100
            this.criticality_2 = (criticality_2 / total) * 100
            this.criticality_3 = (criticality_3 / total) * 100
            this.criticality_4 = (criticality_4 / total) * 100

            // Prepare for the chart
            this.criticality_0 = this.criticality_0 - 0.8;
            this.criticality_1 = this.criticality_1 - 0.8;
            this.criticality_2 = this.criticality_2 - 0.8;
            this.criticality_3 = this.criticality_3 - 0.8;
            this.criticality_4 = this.criticality_4 - 0.8;

            // Number of elements 
            this.number_of_info_criticality = criticality_0;
            this.number_of_low_criticality = criticality_1;
            this.number_of_medium_criticality = criticality_2;
            this.number_of_high_criticality = criticality_3;
            this.number_of_critical_criticality = criticality_4;
        },
    },
    mounted() {
        if (this.status)
            this.calcPercentageByStatus();
        else
            this.calcPercentageByCriticality();
    },

};
</script>

<script setup>
import { useMq } from "vue3-mq";
const mq = useMq();
</script>

<!----------------------------------------------->
<!--                    STYLES                 -->
<!----------------------------------------------->
<style scoped ></style>
