import Sitebar from "../../components/Partials/Sitebar/SiteBar.vue"
import Helper from "../../components/Partials/Helper/Helper.vue"
import Chart from 'primevue/chart';
import * as $api from "../../api/api";
import { useUserStore } from '../../store/store.js'
import Modal from "../../components/Partials/Modals/Modal.vue"


var MyShared = {
    /* Name */
    name: "Home",
    /* Data */
    setup() {

    },
    data() {
        return {
            user: {
                "permissions": []
            },
            selectedPage: null,
            pageOptions: [],
            loading_chart: true,
            actual_page: 1,
            stats: [],
            refresh_default_selected: true,
            aux_field: '',
            expanded: {},
            data: [],
            sources: [],
            selectedSource: null,
            selectedData: {},
            selectedCount: 0,
            avaliableCount: 0,
            filter_fields: [{
                name: '',
                selected: false
            }],
            filter: '',
            filter_fields_backup: [],
            selectedFilter: [],
            search_in_fields: '',
            dates_range: null,
            avaliableOpen: false,
            selectedOpen: false,
            selectedModel: null,
            selectedIndex: null,
            chartData: {},
            chartOptions: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    y: {
                        beginAtZero: true
                    }
                }
            },
            intervals: ['default', '1m', '1h', '1d', '1w', '1M', '1q', '1y'],
            selectedInterval: null,
        }
    },
    /* Components */
    components: {
        Sitebar,
        Helper,
        Chart,
        Modal
    },
    /* Props */
    props: {},
    /* Methods */
    methods: {
        setSelectedData(data, index) {
            this.loading_details = true;
            this.loading_details = false;
            this.selectedIndex = index;
            this.details = data;
        },
        formatDate(date) {
            if (date == null || date == '') return;

            // Format date to dd/mm/yyyy hh:mm
            let d = new Date(date);
            let day = d.getDate();
            let month = d.getMonth() + 1;
            let year = d.getFullYear();
            let hour = d.getHours();
            let minutes = d.getMinutes();
            // Add 0 if month is less than 10
            if (month < 10) {
                month = "0" + month;
            }
            // Add 0 if day is less than 10
            if (day < 10) {
                day = "0" + day;
            }
            // Add 0 if minutes is less than 10
            if (minutes < 10) {
                minutes = "0" + minutes;
            }
            return `${day}/${month}/${year} ${hour}:${minutes}`;
        },
        timestampToDate(timestamp) {
            // Format date to dd/mm/yyyy hh:mm
            let d = new Date(timestamp);
            let day = d.getDate();
            let month = d.getMonth() + 1;
            let year = d.getFullYear();
            let hour = d.getHours();
            let minutes = d.getMinutes();
            return `${day}/${month}/${year} ${hour}:${minutes}`;
        },
        dateToTimestamp(date) {
            // Get timestamp from date
            let d = new Date(date);
            return d.getTime();
        },
        checkType(value) {
            if (value.includes("date") || value.includes("created_at") || value.includes("updated_at")) {
                return "pi-calendar";
            }
            if (value.includes("id")) {
                return "pi-id-card";
            }
            if (value.includes("msg")) {
                return "pi-comment";
            }
            if (value.includes("ip") || value.includes("mac")) {
                return "pi-wifi";
            }
            return "pi-hashtag";
        },
        setColor(value) {
            if (value.includes("date") || value.includes("created_at") || value.includes("updated_at")) {
                return ['#e1f6fc', '#5385a3', '#d2eafc']
            }
            if (value.includes("id")) {
                return ['#f3f2ea', '#b3b876', '#e4e4d7'];
            }
            if (value.includes("ip") || value.includes("mac")) {
                return ['#e6e6e6', '#8c8c8c', '#cccccc'];
            }
            if (value.includes("msg")) {
                return ['#eaf3ec', '#65a875', '#e4e4d7'];
            }
            return ['#fee6e4', '#943127', '#e6c8c0'];
        },
        getAllProperties(obj) {
            const isObject = val =>
                val && typeof val === 'object' && !Array.isArray(val);

            const addDelimiter = (a, b) =>
                a ? `${a}.${b}` : b;

            const paths = (obj = {}, head = '') => {
                return Object.entries(obj)
                    .reduce((product, [key, value]) => {
                        let fullPath = addDelimiter(head, key)
                        return isObject(value) ?
                            product.concat(paths(value, fullPath)) :
                            product.concat(fullPath)
                    }, []);
            }
            console.log("OBJ: ", paths(obj));
            return paths(obj);
        },
        addSelectedAtribute(data) {
            // loop through data and create a new object
            let new_data = [];
            for (let i = 0; i < data.length; i++) {
                new_data.push({
                    name: data[i],
                    selected: false
                })
            }

            console.log("NEW DATA: ", new_data);
            this.checkSelectedNumber();
            return new_data;
        },
        checkSelectedNumber() {
            let count = 0;
            for (let i = 0; i < this.filter_fields.length; i++) {
                if (this.filter_fields[i].selected) {
                    count++;
                }
            }
            this.selectedCount = count;
            this.avaliableCount = this.filter_fields.length - count;
        },
        getValueFromData(string, index) {
            // input log.msg.action
            let data = this.data[index];
            let split = string.split(".");
            for (let i = 0; i < split.length; i++) {
                data = data[split[i]];
            }
            return data;
        },
        getValueFromDataDetails(string) {
            // input log.msg.action
            let data = this.details;
            let split = string.split(".");
            for (let i = 0; i < split.length; i++) {
                data = data[split[i]];
            }
            return data;
        },
        convertObjectToFormattedString(obj) {
            let string = "";
            for (let key in obj) {
                string += `• ${obj[key]} `;
            }
            return string;
        },
        filterFields() {
            // filter this.filter_fields and return only selected
            let selected = [];
            for (let i = 0; i < this.filter_fields.length; i++) {
                if (this.filter_fields[i].selected) {
                    selected.push(this.filter_fields[i]);
                }
            }
            return selected;
        },
        setDefaultSelected() {
            // set default selected
            for (let i = 0; i < this.filter_fields.length; i++) {
                if (this.filter_fields[i].name == "_id" || this.filter_fields[i].name == "_source.date") {
                    this.filter_fields[i].selected = true;
                }
            }
        },
        setDateRange() {
            // set this.date_range to 7 days ago
            let today = new Date();
            let firstDate = new Date();
            firstDate.setDate(today.getDate() - 7);
            //console.log("FIRST DATE: ", firstDate);
            //console.log("TODAY: ", today);
            this.dates_range = [firstDate, today];
        },
        getSources() {
            $api.getLogsSources().then((response) => {
                this.sources = response;
                this.selectedSource = this.sources[0];
            }).catch((error) => {
                console.log('[-] Error on getSources: ', error);
            });
        },
        stringToFilter(string) {
            // input format: severity=1 and (severity=2 or severity=3)
            // output format: [{"type": "query","field": "severity","operator": "=","value": "1"}, {"type": "operator","value": "and"}]

            // REMOVE SPACES BEFORE AND AFTER AN ARITHMETIC OPERATOR (>, <, >=, <=, =, !=)
            string = string.replace(/ > /g, '>'); // GREATER THAN
            string = string.replace(/> /g, '>');
            string = string.replace(/ >/g, '>');
            string = string.replace(/ < /g, '<'); // LESS THAN
            string = string.replace(/< /g, '<');
            string = string.replace(/ </g, '<');
            string = string.replace(/ >= /g, '>='); // GREATER THAN OR EQUAL TO
            string = string.replace(/>= /g, '>=');
            string = string.replace(/ >=/g, '>=');
            string = string.replace(/ <= /g, '<='); // LESS THAN OR EQUAL TO
            string = string.replace(/<= /g, '<=');
            string = string.replace(/ <=/g, '<=');
            string = string.replace(/ = /g, '='); // EQUAL TO
            string = string.replace(/= /g, '=');
            string = string.replace(/ =/g, '=');
            string = string.replace(/ != /g, '!='); // NOT EQUAL TO
            string = string.replace(/!= /g, '!=');
            string = string.replace(/ !=/g, '!=');

            console.log("STRING with no SPACES: ", string);

            let filter = [];
            let aux = string.split(' ');

            aux.forEach(element => {

                // CHECK IF element CONTAINS AN ARITHMETIC OPERATOR (>, <, >=, <=, =, !=)
                if ((element.includes('>')) || element.includes('<') || element.includes('>=') || element.includes('<=') || element.includes('=') || element.includes('!=')) {
                    // SWITCH CASE TO CHECK WHICH ARITHMETIC OPERATOR IS IN THE STRING
                    let aux1;
                    let aux2;
                    let operator;
                    switch (true) {
                        case element.includes('>') && !element.includes('>='):
                            aux1 = element.split('>')[0];
                            aux2 = element.split('>')[1];
                            operator = '>';
                            break;
                        case element.includes('<') && !element.includes('<='):
                            aux1 = element.split('<')[0];
                            aux2 = element.split('<')[1];
                            operator = '<';
                            break;
                        case element.includes('>='):
                            aux1 = element.split('>=')[0];
                            aux2 = element.split('>=')[1];
                            operator = '>=';
                            break;
                        case element.includes('<='):
                            aux1 = element.split('<=')[0];
                            aux2 = element.split('<=')[1];
                            operator = '<=';
                            break;
                        case element.includes('=') && !element.includes('!='):
                            aux1 = element.split('=')[0];
                            aux2 = element.split('=')[1];
                            operator = '=';
                            break;
                        case element.includes('!='):
                            aux1 = element.split('!=')[0];
                            aux2 = element.split('!=')[1];
                            operator = '!=';
                            break;
                    }

                    if (aux1.includes('(')) {
                        aux1 = aux1.replace('(', '')
                            // CHECK IF aux1 IS A STRING OR A NUMBER
                        if (isNaN(aux1)) {
                            filter.push({
                                "type": "operator",
                                "value": '('
                            });
                            filter.push({
                                "type": "query",
                                "field": aux1,
                                "operator": operator,
                                "value": aux2
                            });
                            return;
                        } else {
                            filter.push({
                                "type": "operator",
                                "value": '('
                            });
                            filter.push({
                                "type": "query",
                                "field": aux2,
                                "operator": operator,
                                "value": aux1
                            });
                            return;
                        }
                    }

                    if (aux2.includes(')')) {
                        aux2 = aux2.replace(')', '')
                            // CHECK IF aux1 IS A STRING OR A NUMBER
                        if (isNaN(aux1)) {
                            filter.push({
                                "type": "query",
                                "field": aux1,
                                "operator": operator,
                                "value": aux2
                            });
                            filter.push({
                                "type": "operator",
                                "value": ')'
                            });
                            return;
                        } else {
                            filter.push({
                                "type": "query",
                                "field": aux2,
                                "operator": operator,
                                "value": aux1
                            });
                            filter.push({
                                "type": "operator",
                                "value": ')'
                            });
                            return;
                        }
                    }

                    // CHECK IF aux1 IS A STRING OR A NUMBER
                    if (isNaN(aux1)) {
                        filter.push({
                            "type": "query",
                            "field": aux1,
                            "operator": operator,
                            "value": aux2
                        });
                    } else {
                        filter.push({
                            "type": "query",
                            "field": aux2,
                            "operator": operator,
                            "value": aux1
                        });
                    }


                } else {
                    // IF element DOES NOT CONTAIN AN ARITHMETIC OPERATOR, IS AN OPERATOR (AND, OR, NOT)
                    filter.push({
                        "type": "operator",
                        "value": element
                    });
                }
            });

            //console.log("FILTER: ", filter);
            return filter;
        },
        previous(source, init_date, end_date, filter) {
            if (this.offset > 0) {
                this.offset -= this.limit;
                this.refreshData(source, init_date, end_date, filter, this.offset, this.limit);
            }
        },
        next(source, init_date, end_date, filter) {
            if (this.offset < this.total_data - this.limit) {
                this.offset += this.limit;
                this.refreshData(source, init_date, end_date, filter, this.offset, this.limit);
            }
        },
        gotoPage(source, init_date, end_date, filter, page) {
            this.loading_table = false;
            console.log("GOTO PAGE: ", page);
            console.log("LIMIT: ", this.limit);
            console.log("OFFSET: ", this.offset);
            this.actual_page = page;
            this.selectedPage = page;

            let limit = this.limit;
            if (page == this.total_pages) {
                console.log("LAST PAGE");
                this.offset = (page - 1) * limit;
                limit = this.total_data - this.offset;
            } else {
                limit = this.limit = 12;
            }

            this.offset = (page - 1) * limit;
            console.log("--------------------");
            console.log("ACTUAL PAGE: ", this.actual_page);
            console.log("GOTO PAGE: ", page);
            console.log("LIMIT: ", this.limit);
            console.log("OFFSET: ", this.offset);
            this.refreshData(source, init_date, end_date, filter, this.offset, limit);
        },
        createArrayOfPagesOptions() {
            let pages = [];
            for (let i = 1; i <= this.total_pages; i++) {
                pages.push(i);
            }
            return pages;
        },
        checkDaysBetweenDates(date1, date2) {
            let date1_ms = date1.getTime();
            let date2_ms = date2.getTime();

            let difference_ms = date2_ms - date1_ms;

            return Math.round(difference_ms / 86400000); // days

        },
        setInterval(init_date, end_date) {
            let checkDaysBetweenDates = this.checkDaysBetweenDates(init_date, end_date);
            console.log('DAYS BETWEEN DATES: ', checkDaysBetweenDates);
            if (checkDaysBetweenDates > 0 && checkDaysBetweenDates <= 3) {
                console.log('INTERVAL: 1h');
                return '1h';
            } else if (checkDaysBetweenDates > 2 && checkDaysBetweenDates <= 7) {
                console.log('INTERVAL: 12h');
                return '1d';
            } else if (checkDaysBetweenDates > 7 && checkDaysBetweenDates <= 30) {
                console.log('INTERVAL: 1d');
                return '1d';
            } else if (checkDaysBetweenDates > 30 && checkDaysBetweenDates <= 90) {
                console.log('INTERVAL: 7d');
                return '1w';
            } else if (checkDaysBetweenDates > 90 && checkDaysBetweenDates <= 182) {
                console.log('INTERVAL: 1M');
                return '1M';
            } else if (checkDaysBetweenDates > 182 && checkDaysBetweenDates <= 365) {
                console.log('INTERVAL: 3M');
                return '1q';
            } else if (checkDaysBetweenDates > 365) {
                console.log('INTERVAL: 1y');
                return '1y';
            }
        },
        refreshChart(source, init_date, end_date, filter) {
            let formated_filter = null;
            if (filter != '') {
                formated_filter = this.stringToFilter(filter);
            } else {
                formated_filter = null;
            }
            if (this.selectedInterval == 'default')
                this.selectedInterval = this.setInterval(init_date, end_date);

            this.loading_chart = true;
            $api.getStats(source, this.dateToTimestamp(init_date), this.dateToTimestamp(end_date), formated_filter, this.selectedInterval).then((response) => {
                this.stats = response.aggregations.histogram.buckets;
                this.chartData = this.createChartData();
                this.loading_chart = false;
            }).catch((err) => {
                console.log(`[-] Error (getStats): ${err}`);
                this.loading_table = false;
            });
        },
        createChartData() {
            console.log("STATS: ", this.stats);
            // create chart data
            let chart_data = {
                // array of months
                labels: [],
                datasets: [{
                    label: this.$t('logsByDate'),
                    data: [],
                    backgroundColor: ['#ec635f75'],
                    borderColor: ['#ec635f'],
                    borderWidth: 1
                }]
            }
            for (let i = 0; i < this.stats.length; i++) {
                //console.log("KEY: ", this.stats[i].key);
                //console.log("DOC_COUNT: ", this.stats[i].doc_count);
                //console.log("DATE: ", this.timestampToDate(this.stats[i].key));

                chart_data.labels.push(this.timestampToDate(this.stats[i].key));
                chart_data.datasets[0].data.push(this.stats[i].doc_count);
            }
            //console.log("CHART DATA: ", chart_data);
            return chart_data;
        },
        refreshData(source, init_date, end_date, filter, offset, limit) {
            this.loading_table = true;
            if (this.refresh_default_selected) {
                this.selectedOpen = false;
                this.avaliableOpen = false;
            }

            let formated_filter = [];
            if (filter != '') {
                formated_filter = this.stringToFilter(filter);
            } else {
                formated_filter = [];
            }
            console.log("REFRESH DATA: ", source, formated_filter, this.dateToTimestamp(init_date), this.dateToTimestamp(end_date));
            // Comentar y descomentar estas dos lineas para pintar los logs normales o los logs de intake
            $api.getIntakeLogs(source, this.dateToTimestamp(init_date), this.dateToTimestamp(end_date), JSON.stringify(formated_filter), offset, limit).then((response) => {
                //$api.getAllLogs(false).then((response) => {
                this.total_data = response.hits.total.value;
                this.offset = offset;
                this.limit = limit;

                this.data = response.hits.hits;
                this.data_backup = response.hits.hits;
                //console.log("LOGS: ", this.data);

                /* ---- Data loaded ---- */
                this.selectedData = this.data[0];
                if (this.data)
                    this.details = this.data[0];

                if (this.refresh_default_selected) {
                    this.filter_fields = this.addSelectedAtribute(this.getAllProperties(this.data[0]));
                    this.filter_fields_backup = this.addSelectedAtribute(this.getAllProperties(this.data[0]));
                    this.setDefaultSelected();
                    this.refresh_default_selected = false;
                    this.checkSelectedNumber();
                    this.selectedPage = this.actual_page = 1;
                    this.total_pages = Math.ceil(this.total_data / limit);
                    this.pageOptions = this.createArrayOfPagesOptions();
                    console.log("TOTAL pages: ", this.total_pages);
                    this.refreshChart(source, init_date, end_date, filter);
                }

                //this.chartData = this.createChartData();
                this.loading_table = false;

                // create an object in expanded for each filtered field
                for (let i = 0; i < this.filter_fields.length; i++) {
                    this.expanded[this.filter_fields[i].name] = []
                        //console.log("EXPANDED reciente: ", this.expanded);
                        // loop through data and create a new object
                    for (let j = 0; j < this.data.length; j++) {
                        (this.expanded[this.filter_fields[i].name])[j] = false;
                    }
                }
                //console.log("EXPANDED: ", this.expanded);
                this.loading_table = false;
                this.selectedOpen = true;
                this.avaliableOpen = true;
            }).catch((err) => {
                console.log(`[-] Error (getIntakeLogs): ${err}`);
                this.loading_table = false;
            });
        }
    },
    async mounted() {

        // get assets from API
        if (process.env.VUE_APP_ENV === 'development') {

        } else if (process.env.VUE_APP_ENV === 'staging' || process.env.VUE_APP_ENV === 'production') {
            this.loading_table = true;
            $api.getLogsSources().then((response) => {
                this.sources = response;
                this.selectedSource = this.sources[0];
                this.setDateRange();
                this.selectedInterval = this.intervals[0];
                this.refreshData(this.selectedSource, this.dates_range[0], this.dates_range[1], '', 0, 12);
            }).catch((error) => {
                console.log('[-] Error on getLogsSources: ', error);
                //this.refreshData(this.selectedSource, null, null, null);
                this.loading_table = false;
            });

        } else {
            console.log("ERROR: NO ENVIRONMENT SELECTED");
        }
    },
};
export default MyShared;