import Sitebar from "../../components/Partials/Sitebar/SiteBar.vue"
import Helper from "../../components/Partials/Helper/Helper.vue"
import Modal from "../../components/Partials/Modals/Modal.vue"
import PermissionGroup from "../../components/Partials/PermissionGroup/PermissionGroup.vue"
import ConfirmDialog from "primevue/confirmdialog";
import { FilterMatchMode, FilterOperator } from 'primevue/api';
import { ref } from 'vue';
import { useUserStore } from '../../store/store.js'
import * as $api from "../../api/api";

var MyShared = {
    /* Name */
    name: "Home",
    /* Data */
    setup() {
        const confirm = useConfirm();
        const confirm2 = () => {
            confirm.require({
                message: 'Do you want to delete this record?',
                header: 'Delete Confirmation',
                icon: 'pi pi-info-circle',
                acceptClass: 'p-button-danger',
                accept: () => {
                    toast.add({ severity: 'info', summary: 'Confirmed', detail: 'Record deleted', life: 3000 });
                },
                reject: () => {
                    toast.add({ severity: 'error', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
                }
            });
        }
        return { confirm, confirm2 };
    },
    data() {
        return {
            user: {
                "permissions": []
            },
            creating_new_user: false,
            filters: {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
                id: {
                    operator: FilterOperator.AND,
                    constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
                },
                category: {
                    operator: FilterOperator.AND,
                    constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
                },
            },
            roles: [],
            users: [],
            roles: [],
            teams: [],

            selectedRole: {},
            selected_teams: [],
            selected_roles: [],
            filter_selected_roles: [],
            selectedRolesToAdd: [],
            selectedTeamsToAdd: [],


            usersToAddToTeam: [],
            usersToAddToRole: [],

            selected_users: [],
            selectedUser: {},
            isUserSelected: false,

            add_search_value: "",
            tab: 0,

            permissions_checked: [],
            selected_roles_string: "All",

            // User table pagination
            users_backup: [],
            users_in_page: [],
            currentPage: 1,
            perPage: 5,
            totalUsers: 0,
            totalPages: 0,
            firstPage: 1,
            lastPage: 0,
            search_value: "",

            // roles table pagination
            roles_backup: [],
            roles_in_page: [],
            currentrolePage: 1,
            totalroles: 0,
            totalrolePages: 0,
            firstrolePage: 1,
            lastrolePage: 0,
            search_role_value: "",

            // Teams table pagination
            teams_backup: [],
            teams_in_page: [],
            currentTeamPage: 1,
            totalTeams: 0,
            totalTeamPages: 0,
            firstTeamPage: 1,
            lastTeamPage: 0,
            search_team_value: "",

            // PERMISSONS
            permissions: [],
            permission_categories: [],
            selectedPermissions: [],
            permissions_for_selected_role: [],
            new_role_permissions_checked: [],

            //New users variables
            new_user_selectedRolesToAdd: [],
            new_user_selectedTeamsToAdd: [],
            new_team_users: [],
        }
    },
    /* Components */
    components: {
        Sitebar,
        Helper,
        Modal,
        ConfirmDialog,
        PermissionGroup
    },
    /* Props */
    props: {},
    /* Methods */
    methods: {
        prettifyText(text) {
            return text.replace(/_/g, " ").replace(/\w\S*/g, function(txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); });
        },
        getSourceLogo(logo) {
            return process.env.VUE_APP_BASE_URL + '/' + logo;
        },
        // ---------------------------------------------------- User table pagination
        next() {
            this.currentPage++;
            // load users next page
            this.users_in_page = this.users.slice((this.currentPage - 1) * this.perPage, this.currentPage * this.perPage);
        },
        previous() {
            this.currentPage--;
            // load users previous page
            this.users_in_page = this.users.slice((this.currentPage - 1) * this.perPage, this.currentPage * this.perPage);
        },

        updatePage(search_value) {
            if (search_value == '') {
                this.users = this.users_backup;
            }
            // Get all users that match the search_value in the username, name or email
            let users2 = this.users_backup.filter(user => user.full_name.toLowerCase().includes(search_value.toLowerCase()));
            let users3 = this.users_backup.filter(user => user.email.toLowerCase().includes(search_value.toLowerCase()));
            // Concatenate the arrays
            let users = users2.concat(users3);
            // Remove duplicates
            this.users = users.filter((user, index) => users.indexOf(user) === index);
            console.log(this.users);

            // Update pagination
            this.totalUsers = this.users.length;
            this.totalPages = Math.ceil(this.totalUsers / this.perPage);
            this.lastPage = this.totalPages;
            this.currentPage = 1;
            this.loadUsersFirstPage()
        },
        updatePageRoles(selected_roles) {
            if (selected_roles.length == 0) {
                this.users = this.users_backup;
                this.selected_roles_string = "All";
            } else {
                // Get all users that match the selected_roles in the roles array
                this.users = this.users_backup.filter(user => user.roles.some(role => selected_roles.includes(role.name)));
                // String of selected roles separated by commas
                this.selected_roles_string = selected_roles.join(", ");
                console.log(this.selected_roles_string);
            }

            // Update pagination
            this.totalUsers = this.users.length;
            this.totalPages = Math.ceil(this.totalUsers / this.perPage);
            this.lastPage = this.totalPages;
            this.currentPage = 1;
            this.users_in_page = this.users.slice((this.currentPage - 1) * this.perPage, this.currentPage * this.perPage);
        },

        // ---------------------------------------------------- roles table pagination
        nextrole() {
            this.currentrolePage++;
            // load roles next page
            this.roles_in_page = this.roles.slice((this.currentrolePage - 1) * this.perPage, this.currentrolePage * this.perPage);
        },
        previousrole() {
            this.currentrolePage--;
            // load roles previous page
            this.roles_in_page = this.roles.slice((this.currentrolePage - 1) * this.perPage, this.currentrolePage * this.perPage);
        },
        updatePagerole(search_value) {
            if (search_value == '') {
                this.roles = this.roles_backup;
            }
            // Get all roles that match the search_value in name
            let roles = this.roles_backup.filter(role => role.name.toLowerCase().includes(search_value.toLowerCase()));
            // Remove duplicates
            this.roles = roles.filter((role, index) => roles.indexOf(role) === index);

            // Update pagination
            this.totalroles = this.roles.length;
            this.totalrolePages = Math.ceil(this.totalroles / this.perPage);
            this.lastrolePage = this.totalrolesPages;
            this.currentrolePage = 1;
            this.roles_in_page = this.roles.slice((this.currentrolePage - 1) * this.perPage, this.currentrolePage * this.perPage);
            this.loadRolesFirstPage();
        },

        // ---------------------------------------------------- teams table pagination
        nextTeam() {
            this.currentTeamPage++;
            // load teams next page
            this.teams_in_page = this.teams.slice((this.currentTeamPage - 1) * this.perPage, this.currentTeamPage * this.perPage);
        },
        previousTeam() {
            this.currentTeamPage--;
            // load teams previous page
            this.teams_in_page = this.teams.slice((this.currentTeamPage - 1) * this.perPage, this.currentTeamPage * this.perPage);
        },
        updatePageTeam(search_value) {
            console.log('team search value', search_value);
            if (search_value == '') {
                this.teams = this.teams_backup;
            }
            // Get all teams that match the search_value in name
            let teams = this.teams_backup.filter(team => team.name.toLowerCase().includes(search_value.toLowerCase()));
            // Remove duplicates
            this.teams = teams.filter((team, index) => teams.indexOf(team) === index);

            // Update pagination
            this.totalTeams = this.teams.length;
            this.totalTeamPages = Math.ceil(this.totalTeams / this.perPage);
            this.lastTeamPage = this.totalTeamsPages;
            this.currentTeamPage = 1;
            this.teams_in_page = this.teams.slice((this.currentTeamPage - 1) * this.perPage, this.currentTeamPage * this.perPage);
            this.loadTeamsFirstPage();
        },

        setClickedUser(user) {
            this.isUserSelected = true;
            this.selectedUser = user;
            console.log(user);
        },
        // ---------------------------------------------------- openXModal functions
        openUpdateRolesModal(user) {
            this.setClickedUser(user)
                // Find user's roles in roles backup array and add it to selected_roles
            this.selectedRolesToAdd = [];
            for (let i = 0; i < this.roles_backup.length; i++) {
                for (let j = 0; j < user.roles.length; j++) {
                    if (this.roles_backup[i].id == user.roles[j].id) {
                        this.selectedRolesToAdd.push(this.roles_backup[i]);
                    }
                }
            }
            console.log(this.selectedRolesToAdd);
            this.$refs.addOrUpdateRoles.openModal();
        },
        openUpdateTeamsModal(user) {
            this.setClickedUser(user)
                // Find user's teams in teams backup array and add it to selectedTeamsToAdd
            this.selectedTeamsToAdd = [];
            for (let i = 0; i < this.teams_backup.length; i++) {
                for (let j = 0; j < user.teams.length; j++) {
                    if (this.teams_backup[i].id == user.teams[j].id) {
                        this.selectedTeamsToAdd.push(this.teams_backup[i]);
                    }
                }
            }
            this.$refs.addOrUpdateTeams.openModal();
        },
        openPermissionsModal(role) {
            this.selectedRole = role
                // Find role's permissions in permissions backup array and add its id to permissions_for_selected_role
            this.permissions_for_selected_role = [];
            for (let i = 0; i < this.permissions.length; i++) {
                for (let j = 0; j < role.permissions.length; j++) {
                    if (this.permissions[i].id == role.permissions[j].id) {
                        this.permissions_for_selected_role.push(this.permissions[i].id);
                    }
                }
            }
            this.$refs.viewPermissionsInRole.openModal();
        },
        openUsersInTeamModal(team) {
            this.selectedTeam = team;
            //Find team's users in users backup array and add it to usersToAddToTeam
            this.usersToAddToTeam = [];
            for (let i = 0; i < this.users_backup.length; i++) {
                for (let j = 0; j < team.users.length; j++) {
                    if (this.users_backup[i].id == team.users[j].id) {
                        this.usersToAddToTeam.push(this.users_backup[i]);
                    }
                }
            }
            this.$refs.viewUsersInTeam.openModal();
        },
        openUsersInRoleModal(role) {
            this.selectedRole = role;
            //Find role's users in users backup array and add it to usersToAddToRole
            this.usersToAddToRole = [];
            for (let i = 0; i < this.users_backup.length; i++) {
                for (let j = 0; j < role.users.length; j++) {
                    if (this.users_backup[i].id == role.users[j].id) {
                        this.usersToAddToRole.push(this.users_backup[i]);
                    }
                }
            }
            this.$refs.viewUsersInRole.openModal();
        },

        // ---------------------------------------------------- addXtoY functions
        addRolesToUser(selectedUser, selectedRolesToAdd) {
            this.selectedUser.roles = []
            let roles_ids = []
                // Loop the selected roles
            for (var i = 0; i < selectedRolesToAdd.length; i++) {
                // Find the role in the roles_backup array
                var role = this.roles_backup.find(role => role.id === selectedRolesToAdd[i].id);
                console.log(selectedRolesToAdd[i]);
                // Add the role to the selected_roles array
                this.selectedUser.roles.push(role);
                roles_ids.push(role.id);
            }
            // update user via api 
            $api.updateUser(this.selectedUser.id, { "roles": roles_ids }).then(response => {
                this.$toast.add({ severity: 'success', summary: this.$t('confirmed'), detail: this.$t('userUpdated'), life: 3000 });
                console.log("Roles actualizados");
                console.log(this.selectedUser);
                this.updateAllTables();
                this.$refs.addOrUpdateRoles.closeModal();
            }).catch(error => {
                this.$toast.add({ severity: 'error', summary: this.$t('error'), detail: this.$t('userNotUpdated'), life: 3000 });
                this.$refs.addOrUpdateRoles.closeModal();
            });
        },
        addTeamsToUser(selectedUser, selectedTeamsToAdd) {
            this.selectedUser.teams = []
            let teams_ids = []
                // Loop the selected teams
            for (var i = 0; i < selectedTeamsToAdd.length; i++) {
                // Find the team in the teams_backup array
                var team = this.teams_backup.find(team => team.id === selectedTeamsToAdd[i].id);
                console.log(selectedTeamsToAdd[i]);
                // Add the team to the selected_teams array
                this.selectedUser.teams.push(team);
                teams_ids.push(team.id);
            }

            // update user via api
            $api.updateUser(this.selectedUser.id, { "teams": teams_ids }).then(response => {
                this.$toast.add({ severity: 'success', summary: this.$t('confirmed'), detail: this.$t('userUpdated'), life: 3000 });
                console.log("Teams actualizados");
                console.log(this.selectedUser);
                this.updateAllTables();
                this.$refs.addOrUpdateTeams.closeModal();
            }).catch(error => {
                this.$toast.add({ severity: 'error', summary: this.$t('error'), detail: this.$t('userNotUpdated'), life: 3000 });
                this.$refs.addOrUpdateTeams.closeModal();
            });
        },

        addUsersToTeam() {
            // Add users of usersToAddToTeam array to the selectedTeam.users 
            let users_ids = [];
            console.log("usersToAddToTeam", this.usersToAddToTeam);
            this.usersToAddToTeam.forEach(user => {
                users_ids.push(user.id);
            });
            // update team via api
            $api.updateTeam(this.selectedTeam.id, { "users": users_ids }).then(response => {
                this.$toast.add({ severity: 'success', summary: this.$t('confirmed'), detail: this.$t('teamUpdated'), life: 3000 });
                this.selectedTeam.users = this.usersToAddToTeam
                console.log("Teams actualizados");
                this.updateAllTables();
                this.$refs.viewUsersInTeam.closeModal();
            }).catch(error => {
                this.$toast.add({ severity: 'error', summary: this.$t('error'), detail: this.$t('teamNotUpdated'), life: 3000 });
                console.log("Error al actualizar los equipos");
                console.log(error);
                this.$refs.viewUsersInTeam.closeModal();
            });
        },
        addUsersToRole() {
            // Add users of usersToAddToRole array to the selectedRole.users 
            let users_ids = [];
            console.log("usersToAddToRole", this.usersToAddToRole);
            this.usersToAddToRole.forEach(user => {
                users_ids.push(user.id);
            });
            // update role via api
            $api.updateRole(this.selectedRole.id, { "users": users_ids }).then(response => {
                this.$toast.add({ severity: 'success', summary: this.$t('confirmed'), detail: this.$t('roleUpdated'), life: 3000 });
                this.selectedRole.users = this.usersToAddToRole
                console.log("Roles actualizados");
                this.updateAllTables();
                this.$refs.viewUsersInRole.closeModal();
            }).catch(error => {
                this.$toast.add({ severity: 'error', summary: this.$t('error'), detail: this.$t('roleNotUpdated'), life: 3000 });
                console.log("Error al actualizar los roles");
                console.log(error);
                this.$refs.viewUsersInRole.closeModal();
            });
        },

        // ---------------------------------------------------- removeX functions
        removeRole(id) {
            this.$confirm.require({
                message: this.$t('deleteConfirmationAlert'),
                header: this.$t('deleteConfirmation'),
                icon: 'pi pi-info-circle',
                acceptClass: 'p-button-danger',
                accept: () => {
                    // Find role id in roles array and remove it
                    $api.deleteRole(id).then(response => {
                        for (let i = 0; i < this.roles.length; i++) {
                            if (this.roles[i].id == id) {
                                this.roles.splice(i, 1);
                            }
                        }
                        this.loadRolesFirstPage();
                        this.updateAllTables();
                        this.$toast.add({ severity: 'success', summary: this.$t('confirmed'), detail: this.$t('recordDeleted'), life: 3000 });
                    }).catch(error => {
                        this.$toast.add({ severity: 'error', summary: this.$t('error'), detail: this.$t('roleNotDeleted'), life: 3000 });
                    });
                },
                reject: () => {
                    //this.$toast.add({severity:'error', summary:this.$t('rejected'), detail:this.$t('rejectedAlert'), life: 3000});
                }
            });
        },
        removeUser(id) {
            this.$confirm.require({
                message: this.$t('deleteConfirmationAlert'),
                header: this.$t('deleteConfirmation'),
                icon: 'pi pi-info-circle',
                acceptClass: 'p-button-danger',
                accept: () => {
                    $api.deleteUser(id).then(response => {
                        // Find user id in users array and remove it
                        for (let i = 0; i < this.users.length; i++) {
                            if (this.users[i].id == id) {
                                this.users.splice(i, 1);
                            }
                        }
                        this.loadUsersFirstPage();
                        this.updateAllTables();
                        this.$toast.add({ severity: 'success', summary: this.$t('confirmed'), detail: this.$t('recordDeleted'), life: 3000 });
                    }).catch(error => {
                        this.$toast.add({ severity: 'error', summary: this.$t('error'), detail: this.$t('userNotDeleted'), life: 3000 });
                    });

                },
                reject: () => {
                    //this.$toast.add({severity:'error', summary:this.$t('rejected'), detail:this.$t('rejectedAlert'), life: 3000});
                }
            });
        },
        removeTeam(id) {
            this.$confirm.require({
                message: this.$t('deleteConfirmationAlert'),
                header: this.$t('deleteConfirmation'),
                icon: 'pi pi-info-circle',
                acceptClass: 'p-button-danger',
                accept: () => {
                    $api.deleteTeam(id).then(response => {
                        // Find team id in teams array and remove it
                        for (let i = 0; i < this.teams.length; i++) {
                            if (this.teams[i].id == id) {
                                this.teams.splice(i, 1);
                            }
                        }
                        this.loadTeamsFirstPage();
                        this.updateAllTables();
                        this.$toast.add({ severity: 'success', summary: this.$t('confirmed'), detail: this.$t('recordDeleted'), life: 3000 });
                    }).catch(error => {
                        this.$toast.add({ severity: 'error', summary: this.$t('error'), detail: this.$t('teamNotDeleted'), life: 3000 });
                    });
                },
                reject: () => {
                    //this.$toast.add({severity:'error', summary:this.$t('rejected'), detail:this.$t('rejectedAlert'), life: 3000});
                }
            });
        },

        createPermisionCategoriesArray() {
            let formatedPermissions = [];
            // Format permissions array by category
            this.permissions.forEach(permission => {
                let category = permission.category;
                let name = permission.name;
                let id = permission.id;
                let departament = permission.departament;
                let value = permission.value;
                let aux = {
                    category: category,
                    permissions: [{
                        name: name,
                        id: id,
                        departament: departament,
                        value: value
                    }]
                };
                // If category already exists, add the permission to the category
                if (formatedPermissions.some(p => p.category == category)) {
                    formatedPermissions.find(p => p.category == category).permissions.push(aux.permissions[0]);
                } else {
                    formatedPermissions.push(aux);
                }
            });

            console.log(formatedPermissions);
            return formatedPermissions;
        },

        updateSelectedPermissions() {
            let permissions_ids = [];
            // Find permissions_for_selected_role in permissions backup array and add it to selectedRole.permissions
            this.selectedRole.permissions = [];
            for (let i = 0; i < this.permissions.length; i++) {
                for (let j = 0; j < this.permissions_for_selected_role.length; j++) {
                    if (this.permissions[i].id == this.permissions_for_selected_role[j]) {
                        //Check if permission is already in selectedRole.permissions
                        if (!this.selectedRole.permissions.some(p => p.id == this.permissions[i].id)) {
                            this.selectedRole.permissions.push(this.permissions[i]);
                            permissions_ids.push(this.permissions[i].id);
                        }
                    }
                }
            }

            $api.updateRole(this.selectedRole.id, { "permissions": permissions_ids }).then(response => {
                this.$toast.add({ severity: 'success', summary: this.$t('success'), detail: this.$t('rolePermissionsUpdated'), life: 3000 });
            }).catch(error => {
                this.$toast.add({ severity: 'error', summary: this.$t('error'), detail: this.$t('rolePermissionsNotUpdated'), life: 3000 });
            });

            console.log("Selected permissions: ");
            console.log(this.selectedPermissions);
            // Close modal
            this.$refs.viewPermissionsInRole.closeModal();
        },

        // -------------------------------------------------------- addNewReecord functions
        addNewUser(name, email, teams, roles) {
            this.creating_new_user = true;
            let teams_ids = [];
            let roles_ids = [];
            // Get teams ids from teams array
            for (let i = 0; i < teams.length; i++) {
                teams_ids.push(teams[i].id);
            }
            // Get roles ids from roles array
            for (let i = 0; i < roles.length; i++) {
                roles_ids.push(roles[i].id);
            }
            $api.createUser({ "full_name": name, "email": email, "teams": teams_ids, "roles": roles_ids }).then(response => {
                this.$toast.add({ severity: 'success', summary: this.$t('confirmed'), detail: this.$t('userCreated'), life: 3000 });
                this.updateAllTables();
                this.creating_new_user = false;
                this.$refs.newUser.closeModal();
            }).catch(error => {
                this.$toast.add({ severity: 'error', summary: this.$t('error'), detail: this.$t('userNotCreated'), life: 3000 });
                console.log("Error al crear los usuarios");
                console.log(error);
                this.$refs.newUser.closeModal();
            });
        },
        addNewRole(name, desc, permissions) {
            $api.createRole({ "name": name, "description": desc, "permissions": permissions }).then(response => {
                this.$toast.add({ severity: 'success', summary: this.$t('confirmed'), detail: this.$t('roleCreated'), life: 3000 });
                this.updateAllTables();
            }).catch(error => {
                this.$toast.add({ severity: 'error', summary: this.$t('error'), detail: this.$t('roleNotCreated'), life: 3000 });
                console.log("Error al actualizar los roles");
                console.log(error);
                this.$refs.newRole.closeModal();
            });
        },
        addNewTeam(name, desc, users) {
            let users_ids = [];
            users.forEach(user => {
                users_ids.push(user.id);
            });
            $api.createTeam({ "name": name, "description": desc, "users": users_ids }).then(response => {
                this.$toast.add({ severity: 'success', summary: this.$t('confirmed'), detail: this.$t('teamCreated'), life: 3000 });
                this.updateAllTables();
            }).catch(error => {
                this.$toast.add({ severity: 'error', summary: this.$t('error'), detail: this.$t('teamNotCreated'), life: 3000 });
                console.log("Error al actualizar los equipos");
                console.log(error);
                this.$refs.newTeam.closeModal();
            });
        },

        // -------------------------------------------------------- loadPages functions
        loadUsersFirstPage() {
            // load users first page
            this.users_in_page = this.users.slice((this.currentPage - 1) * this.perPage, this.currentPage * this.perPage);
            this.totalUsers = this.users.length;
            this.totalPages = Math.ceil(this.totalUsers / this.perPage);
            this.lastPage = this.totalPages;
        },
        loadRolesFirstPage() {
            // load roles first page
            this.roles_in_page = this.roles.slice((this.currentrolePage - 1) * this.perPage, this.currentrolePage * this.perPage);
            this.totalroles = this.roles.length;
            this.totalrolePages = Math.ceil(this.totalroles / this.perPage);
            this.lastrolePage = this.totalrolePages;
        },
        loadTeamsFirstPage() {
            // load teams first page
            this.teams_in_page = this.teams.slice((this.currentTeamPage - 1) * this.perPage, this.currentTeamPage * this.perPage);
            this.totalTeams = this.teams.length;
            this.totalTeamPages = Math.ceil(this.totalTeams / this.perPage);
            this.lastTeamPage = this.totalTeamPages;
        },

        // -------------------------------------------------------- update functions
        async updateAllTables() {
            // get USERS from API
            if (process.env.VUE_APP_ENV === 'development') {
                await (this.users = require('./TempFiles/users.json'));
                await (this.users_backup = require('./TempFiles/users.json'));
            } else if (process.env.VUE_APP_ENV === 'staging' || process.env.VUE_APP_ENV === 'production') {
                $api.getAllUsers().then((response) => {
                    this.users = response;
                    this.users_backup = response;
                    console.log("USERS: ", this.users);
                    // load users first page
                    this.loadUsersFirstPage();
                }).catch((err) => {
                    console.log(`[-] Error (getAllUsers): ${err}`);
                });
            } else {
                console.log("ERROR: NO ENVIRONMENT SELECTED");
            }

            // get ROLES from API
            if (process.env.VUE_APP_ENV === 'development') {
                await (this.roles = require('./TempFiles/roles.json'));
                await (this.roles_backup = require('./TempFiles/roles.json'));
            } else if (process.env.VUE_APP_ENV === 'staging' || process.env.VUE_APP_ENV === 'production') {
                $api.getAllRoles().then((response) => {
                    this.roles = response;
                    this.roles_backup = response;
                    console.log("ROLES: ", this.roles);
                    // load roles first page
                    this.loadRolesFirstPage();
                }).catch((err) => {
                    console.log(`[-] Error (getAllRoles): ${err}`);
                });
            } else {
                console.log("ERROR: NO ENVIRONMENT SELECTED");
            }

            // get TEAMS from API
            if (process.env.VUE_APP_ENV === 'development') {
                await (this.teams = require('./TempFiles/teams.json'));
                await (this.teams_backup = require('./TempFiles/teams.json'));
            } else if (process.env.VUE_APP_ENV === 'staging' || process.env.VUE_APP_ENV === 'production') {
                $api.getAllTeams().then((response) => {
                    this.teams = response;
                    this.teams_backup = response;
                    console.log("TEAMS: ", this.teams);
                    // load teams first page
                    this.loadTeamsFirstPage();
                }).catch((err) => {
                    console.log(`[-] Error (getAllTeams): ${err}`);
                });
            } else {
                console.log("ERROR: NO ENVIRONMENT SELECTED");
            }

            // get PERMISSIONS from API
            if (process.env.VUE_APP_ENV === 'development') {
                await (this.permissions = require('./TempFiles/permissions.json'));
                await (this.permissions_backup = require('./TempFiles/permissions.json'));
            } else if (process.env.VUE_APP_ENV === 'staging' || process.env.VUE_APP_ENV === 'production') {
                $api.getAllPermissions().then((response) => {
                    this.permissions = response;
                    this.permissions_backup = response;
                    console.log("PERMISSIONS: ", this.permissions);
                    // load permissions
                    this.permission_categories = this.createPermisionCategoriesArray();
                    console.log(this.permission_categories);
                }).catch((err) => {
                    console.log(`[-] Error (getAllPermissions): ${err}`);
                });

            } else {
                console.log("ERROR: NO ENVIRONMENT SELECTED");
            }
        }
    },
    async mounted() {
        const userStore = useUserStore();
        const userObject = await userStore.getUser();
        this.user = userObject;
        this.updateAllTables();

        // get PERMISSIONS
        this.get_user_permission = this.hasUserPermissionGlobal(this.user, 'user:get');
        this.create_user_permission = this.hasUserPermissionGlobal(this.user, 'user:create');
        this.update_user_permission = this.hasUserPermissionGlobal(this.user, 'user:update');
        this.delete_user_permission = this.hasUserPermissionGlobal(this.user, 'user:delete');

        this.get_role_permission = this.hasUserPermissionGlobal(this.user, 'role:get');
        this.create_role_permission = this.hasUserPermissionGlobal(this.user, 'role:create');
        this.update_role_permission = this.hasUserPermissionGlobal(this.user, 'role:update');
        this.delete_role_permission = this.hasUserPermissionGlobal(this.user, 'role:delete');

        this.get_team_permission = this.hasUserPermissionGlobal(this.user, 'team:get');
        this.create_team_permission = this.hasUserPermissionGlobal(this.user, 'team:create');
        this.update_team_permission = this.hasUserPermissionGlobal(this.user, 'team:update');
        this.delete_team_permission = this.hasUserPermissionGlobal(this.user, 'team:delete');
    }
};
export default MyShared;