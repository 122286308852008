<!----------------------------------------------->
<!--                  TEMPLATE                 -->
<!----------------------------------------------->
<template>
  <MobileIntegrations v-if="  mq.current  === 'mobile'"></MobileIntegrations>
  <TabletIntegrations v-if="  mq.current  === 'tablet'"></TabletIntegrations>
  <DesktopIntegrations v-if=" mq.current  === 'desktop'"></DesktopIntegrations>
</template>

<!----------------------------------------------->
<!--                    SCRIPT                 -->
<!----------------------------------------------->
<script>
import MobileIntegrations from "../components/Integrations/MobileIntegrations";
import TabletIntegrations from "../components/Integrations/TabletIntegrations";
import DesktopIntegrations from "../components/Integrations/DesktopIntegrations";

export default {
  /* Name */
  name: "Integrations",
  inject: ["mq"],
  /* Components */
  components: {
    MobileIntegrations,
    TabletIntegrations,
    DesktopIntegrations,
  },
};
</script>

<script setup>
import { useMq } from "vue3-mq";
const mq = useMq();
</script>

<!----------------------------------------------->
<!--                    STYLES                 -->
<!----------------------------------------------->
<style scoped >
</style>
