<!----------------------------------------------->
<!--                  TEMPLATE                 -->
<!----------------------------------------------->
<template>
  <div class="aux">
    <Sitebar></Sitebar>
    <div class="maincontainer pr-4">
      <ConfirmDialog></ConfirmDialog>
      <div class="row">
        <div class="col-6">
          <div class="row">
            <div class="col-12">
              <h5 class="m-0 pl-1" style="float:left;">{{ $t('manageUsers') }}</h5>
              <Button class="btn-custom ml-2" :label="$t('newUser')" style="float:right;"
                @click="$refs.newUser.openModal()"></Button>
            </div>
          </div>
          <div class="row mt-0">
            <div class="col-12">
              <div class="main-user-container">
                <div class="top-user-container">
                  <span>{{$t('filterBy')}}: <strong>{{selected_roles_string}}</strong></span> 
                  <div class="dropdown mr-2" style="float:right;">
                    <div class="dropdown-toggle" data-toggle="dropdown" style="display: -webkit-box; cursor: pointer;"
                      id="location-dropdown">
                      {{ $t('role') }}
                    </div>
                    <div class="dropdown-menu p-2">
                      <div class="col-12 p-0 m-0" v-for="role in roles" :key="role">
                        <Checkbox v-model="filter_selected_roles" :value="role.name"
                          @change="updatePageRoles(filter_selected_roles)" />
                        <span class="user-name-manage-roles">&nbsp;{{ role.name }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="search-users-container">
                  <InputText type="text" v-model="search_value" :placeholder="$t('findPlaceHolder')" style="width:100%;"
                    @keyup="updatePage(search_value)"></InputText>
                </div>
                <div class="user-container" v-for="user in users_in_page" :key="user">
                  <div class="user-aux" v-if="(user.full_name.toLowerCase().includes(search_value.toLowerCase()))">
                    <div class="row p-1" style="display:inline-flex; width:100%;">
                      <li class="user-container-checkbox">
                        <Checkbox v-model="selected_users" :value="user.id" />
                      </li>
                      <li class="user-container-img" @click="$router.push({ path: 'profile', query: { id: user.id } })">
                        <Avatar v-if="user.picture == null" :label="user.full_name[0].toUpperCase()" shape="circle" size="medium"/>
                        <img v-else :src="getSourceLogo(user.picture)" class="rounded-circle" height="35" width="35" />
                      </li>
                      <div class="user-container-name" @click="$router.push({ path: 'profile', query: { id: user.id } })">
                        <p class="m-0">{{ user.full_name }}</p>
                        <p class="m-0" style="font-size:small">{{ user.email }}</p>
                      </div>
                      <div class="user-container-options">
                        <div class="user-option" style="float:right;">
                          <span class="option-aux">
                            <Button class="btn-custom mr-2" :label="$t('roles')"
                              @click="openUpdateRolesModal(user);"></Button>
                          </span>
                          <span class="option-aux">
                            <Button class="btn-custom" :label="$t('teams')"
                              @click="openUpdateTeamsModal(user);"></Button>
                          </span>
                        </div>
                        <div class="user-option">
                          <span class="option-aux">
                            <Button class="btn-custom btn-custom-remove mr-4" :label="$t('remove')"
                              @click="removeUser(user.id)"></Button>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-0">
            <div class="col-12 text-center">
              <i class="pi pi-chevron-left" @click="previous()" style="font-size:small"
                v-if="currentPage > firstPage"></i>
              &nbsp;
              <span>{{ currentPage }}</span>
              &nbsp;
              <i class="pi pi-chevron-right" @click="next()" style="font-size:small" v-if="currentPage < lastPage"></i>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="row">
            <div class="col-12">
              <h5 :class="(tab == 0) ? 'custom-tab-active m-0 pl-1 pr-2 mb-1' : 'custom-tab m-0 pl-1 pr-2 mb-1'"
                style="float:left; cursor:pointer;" @click="tab = 0">{{ $t('manageRoles') }}</h5>
              <h5 :class="(tab == 1) ? 'custom-tab-active m-0 pl-1 pr-2 mb-1' : 'custom-tab m-0 pl-1 pr-2 mb-1'"
                style="float:left; cursor:pointer;" @click="tab = 1">{{ $t('manageTeams') }}</h5>
              <Button class="btn-custom ml-2" :label="$t('newRole')" style="float:right;"
                @click="$refs.newRole.openModal()" v-if="tab == 0"></Button>
              <Button class="btn-custom ml-2" :label="$t('newTeam')" style="float:right;"
                @click="$refs.newTeam.openModal()" v-if="tab == 1"></Button>
              <!--
                <Button class="btn-custom ml-2" :label="$t('addRoleToUser')" style="float:right;"
                @click="$refs.addUser.openModal()"></Button>
                -->
            </div>
          </div>
          <div class="table-teams" v-if="tab == 1">
            <div class="row mt-0">
              <div class="col-12">
                <div class="main-user-container">
                  <div class="top-user-container">
                  </div>
                  <div class="search-users-container">
                    <InputText type="text" v-model="search_team_value" :placeholder="$t('findPlaceHolder')"
                      style="width:100%;" @keyup="updatePageTeam(search_team_value)"></InputText>
                  </div>
                  <div class="user-container" v-for="team in teams_in_page" :key="team">
                    <div class="user-aux" v-if="(team.name.toLowerCase().includes(search_team_value.toLowerCase()))">
                      <div class="row p-1 pb-2" style="display:inline-flex; width:100%;">
                        <li class="user-container-checkbox">
                          <Checkbox v-model="selected_teams" :value="team.id" />
                        </li>
                        <div class="user-container-name">
                          <p class="m-0">{{ team.name }}</p>
                          <p class="m-0" style="font-size:small; max-width: 500px;">{{ team.description }}</p>
                        </div>
                        <div class="user-container-options">
                          <div class="user-option" style="float:right;">
                            <span class="option-aux">
                              <Button class="btn-custom" :label="$t('users')"
                                @click="openUsersInTeamModal(team)"></Button>
                            </span>
                          </div>
                          <div class="user-option">
                            <span class="option-aux">
                              <Button class="btn-custom btn-custom-remove mr-4" :label="$t('remove')"
                                @click="removeTeam(team.id)"></Button>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-0">
              <div class="col-12 text-center">
                <i class="pi pi-chevron-left" @click="previous()" style="font-size:0.75rem; cursor: pointer;"
                  v-if="currentTeamPage > firstTeamPage"></i>
                &nbsp;
                <span><strong>{{ currentTeamPage }}</strong></span>
                &nbsp;
                <i class="pi pi-chevron-right" @click="next()" style="font-size:0.75rem; cursor: pointer;"
                  v-if="currentTeamPage < lastTeamPage"></i>
              </div>
            </div>
          </div>
          <div class="table-roles" v-if="tab == 0">
            <div class="row mt-0">
              <div class="col-12">
                <div class="main-user-container">
                  <div class="top-user-container">
                  </div>
                  <div class="search-users-container">
                    <InputText type="text" v-model="search_role_value" :placeholder="$t('findPlaceHolder')"
                      style="width:100%;" @keyup="updatePagerole(search_role_value)"></InputText>
                  </div>
                  <div class="user-container" v-for="role in roles" :key="role">
                    <div class="user-aux" v-if="(role.name.toLowerCase().includes(search_role_value.toLowerCase()))">
                      <div class="row p-1 pb-2" style="display:inline-flex; width:100%;">
                        <li class="user-container-checkbox">
                          <Checkbox v-model="selected_roles" :value="role.id" />
                        </li>
                        <div class="user-container-name">
                          <p class="m-0">{{ role.name }}</p>
                          <p class="m-0" style="font-size:small; max-width: 450px;">{{ role.description }}</p>
                        </div>
                        <div class="user-container-options">
                          <div class="user-option" style="float:right;">
                            <span class="option-aux">
                              <Button class="btn-custom mr-2" :label="$t('permissions')"
                                @click="openPermissionsModal(role)"></Button>
                              <Button class="btn-custom" :label="$t('users')"
                                @click="openUsersInRoleModal(role)"></Button>
                            </span>
                          </div>
                          <div class="user-option">
                            <span class="option-aux">
                              <Button class="btn-custom btn-custom-remove mr-4" :label="$t('remove')"
                                @click="removeRole(role.id)"></Button>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-0">
              <div class="col-12 text-center">
                <i class="pi pi-chevron-left" @click="previousrole()" style="font-size:small"
                  v-if="currentrolePage > firstrolePage"></i>
                &nbsp;
                <span>{{ currentrolePage }}</span>
                &nbsp;
                <i class="pi pi-chevron-right" @click="nextrole()" style="font-size:small"
                  v-if="currentrolePage < firstrolePage"></i>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <!--============================================================================== addOrUpdateRolesFor ==============================================================================-->
    <Modal ref="addOrUpdateRoles">
      <template #header>
      </template>
      <template #body>
        <div class="row">
          <div class="col-12 text-center pt-4">
            <i class="pi pi-book" style="font-size:2.5em;"></i>
          </div>
          <div class="col-12 text-center pt-0">
            <h5 class="modal-title">{{ $t('addOrUpdateRolesFor') }} {{ selectedUser.full_name }}</h5>
          </div>
          <div class="col-12 ml-2" v-if="isUserSelected">
            <div class="row p-1 selected-user" style="display:inline-flex">
              <li class="user-container-img pl-4">
                <Avatar v-if="selectedUser.picture == null" :label="selectedUser.full_name[0].toUpperCase()" shape="circle" size="medium"/>
                <img v-else :src="getSourceLogo(selectedUser.picture)" class="rounded-circle" height="35" width="35" />
              </li>
              <div class="user-container-name">
                <p class="m-0">{{ selectedUser.full_name }}</p>
                <p class="m-0" style="font-size:small">{{ selectedUser.email }}</p>
              </div>
            </div>
          </div>
          <div class="col-12 m-0 p-2 mr-3" v-if="isUserSelected">
            <p class="mb-0">{{ $t('chooseRoles') }}</p>
            <MultiSelect v-model="selectedRolesToAdd" :options="roles_backup" optionLabel="name"
              :placeholder="$t('chooseRoles')" style="width:99%;" display="chip" :filter="true" />
          </div>
        </div>
      </template>
      <template #footer>
        <Button class="btn-custom" :label="$t('save')"
          @click="addRolesToUser(selectedUser, selectedRolesToAdd)"></Button>
      </template>
    </Modal>

    <!--============================================================================== addOrUpdateTeamsFor ==============================================================================-->
    <Modal ref="addOrUpdateTeams">
      <template #header>
      </template>
      <template #body>
        <div class="row">
          <div class="col-12 text-center pt-4">
            <i class="pi pi-th-large" style="font-size:2.5em;"></i>
          </div>
          <div class="col-12 text-center pt-0">
            <h5 class="modal-title">{{ $t('addOrUpdateTeamsFor') }} {{ selectedUser.full_name }}</h5>
          </div>
          <div class="col-12 ml-2" v-if="isUserSelected">
            <div class="row p-1 selected-user" style="display:inline-flex">
              <li class="user-container-img pl-4">
                <Avatar v-if="selectedUser.picture == null" :label="selectedUser.full_name[0].toUpperCase()" shape="circle" size="medium"/>
                <img v-else :src="getSourceLogo(selectedUser.picture)" class="rounded-circle" height="35" width="35" />
              </li>
              <div class="user-container-name">
                <p class="m-0">{{ selectedUser.full_name }}</p>
                <p class="m-0" style="font-size:small">{{ selectedUser.email }}</p>
              </div>
            </div>
          </div>
          <div class="col-12 m-0 p-2 mr-3" v-if="isUserSelected">
            <p class="mb-0">{{ $t('chooseTeams') }}</p>
            <MultiSelect v-model="selectedTeamsToAdd" :options="teams_backup" optionLabel="name"
              :placeholder="$t('chooseTeam')" style="width:99%;" display="chip" :filter="true" />
          </div>
        </div>
      </template>
      <template #footer>
        <Button class="btn-custom" :label="$t('save')"
          @click="addTeamsToUser(selectedUser, selectedTeamsToAdd)"></Button>
      </template>
    </Modal>

    <!--============================================================================== New User ==============================================================================-->
    <Modal ref="newUser">
      <template #header>
      </template>
      <template #body>
        <div class="row">
          <div class="col-12 text-center pt-4">
            <i class="pi pi-book" style="font-size:2.5em;"></i>
          </div>
          <div class="col-12 text-center pt-0 pb-0">
            <h5 class="modal-title">{{ $t('addPeopleToDeepSiem') }}</h5>
          </div>
        </div>
        <div class="row">
          <div class="col-12 p-4">
            <label for="" class="pl-0 pb-0 mb-0">{{ $t('userFullName') }}</label>
            <InputText type="text" v-model="new_user_full_name" :placeholder="$t('userFullName')" style="width:100%;">
            </InputText>
            <label for="" class="pl-0 pb-0 mb-0 mt-1">{{ $t('userEmail') }}</label>
            <InputText type="text" v-model="new_user_email" :placeholder="$t('userEmail')" style="width:100%;">
            </InputText>
            <p class="pb-0 mb-0"><small>{{ $t('sendEmailCaption') }}</small></p>
            <hr>
            <label for="" class="pl-0 pb-0 mb-0">{{ $t('teams') }}</label>
            <MultiSelect v-model="new_user_selectedTeamsToAdd" :options="teams_backup" optionLabel="name"
              :placeholder="$t('chooseTeams')" style="width:100%;" display="chip" :filter="true" />
            <label for="" class="pl-0 pb-0 mb-0 mt-1">{{ $t('roles') }}</label>
            <MultiSelect v-model="new_user_selectedRolesToAdd" :options="roles_backup" optionLabel="name"
              :placeholder="$t('chooseRoles')" style="width:100%;" display="chip" :filter="true" />
          </div>
        </div>
      </template>
      <template #footer>
        <Button v-if="!creating_new_user" class="btn-custom" :label="$t('save')" @click="addNewUser(new_user_full_name,new_user_email,new_user_selectedTeamsToAdd,new_user_selectedRolesToAdd)"></Button>
        <Button v-else class="btn-custom" icon="pi pi-spin pi-spinner" disabled></Button>
      </template>
    </Modal>

    <!--============================================================================== New team ==============================================================================-->
    <Modal ref="newTeam">
      <template #header>
      </template>
      <template #body>
        <div class="row">
          <div class="col-12 text-center pt-4">
            <i class="pi pi-book" style="font-size:2.5em;"></i>
          </div>
          <div class="col-12 text-center pt-0 pb-0">
            <h5 class="modal-title">{{ $t('createNewTeam') }}</h5>
          </div>
        </div>
        <div class="row">
          <div class="col-12 p-4">
            <label for="" class="pl-0 pb-0 mb-0">{{ $t('teamName') }}</label>
            <InputText v-model="new_team_name" :placeholder="$t('teamName')" class="search-input"
              style="width:100% !important;"></InputText>
            <label for="" class="pl-0 pb-0 mb-0 mt-1">{{ $t('teamDescription') }}</label>
            <Textarea v-model="new_team_description" :placeholder="$t('teamDescription')"
              class="search-input" style="width:100% !important;"></Textarea>
              <hr>
              <label for="" class="pl-0 pb-0 mb-0">{{ $t('teamUsers') }}</label>
              <MultiSelect v-model="new_team_users" :options="users_backup" optionLabel="full_name"
              :placeholder="$t('chooseUsers')" style="width:100%" display="chip" :filter="true" />
          </div>
        </div>
      </template>
      <template #footer>
        <Button class="btn-custom" :label="$t('save')" @click="addNewTeam(new_team_name,new_team_description,new_team_users)"></Button>
      </template>
    </Modal>>

    <!--============================================================================== New Role ==============================================================================-->
    <Modal ref="newRole">
      <template #header>
      </template>
      <template #body>
        <div class="row">
          <div class="col-12 text-center pt-4">
            <i class="pi pi-book" style="font-size:2.5em;"></i>
          </div>
          <div class="col-12 text-center pt-0 pb-0">
            <h5 class="modal-title">{{ $t('newRole') }}</h5>
          </div>
        </div>
        <div class="row p-4">
          <label for="" class="pl-0 pb-0 mb-0"><strong>{{ $t('newRoleName') }}</strong></label>
          <hr class="m-0">
          <InputText v-model="new_role_name" :placeholder="$t('newRoleName')" class="search-input mb-2 mt-2"
            style="width:100% !important;"></InputText>
          <label for="" class="pl-0 pb-0 mb-0"><strong>{{ $t('newRoleDescription') }}</strong></label>
          <hr class="m-0">
          <Textarea v-model="new_role_description" :placeholder="$t('newRoleDescription')"
            class="search-input mb-2 mt-2" style="width:100% !important;"></Textarea>
          <label for="" class="pl-0 pb-0 mb-0"><strong>{{ $t('selectPermissions') }}</strong></label>
          <hr class="m-0 mb-1">
          <!--<InputText v-model="filters['global'].value" :placeholder="$t('searchBy')" class="search-input mb-2 mt-2"></InputText>-->
          <div class="col-12 p-0">
            <div class="row">
              <DataTable :value="permission_categories" :paginator="true" class="p-datatable-customers mt-0" :rows="12"
                current-page-report-template="Showing {first} to {last} of {totalRecords} entries"
                v-model:filters="filters" :global-filter-fields="['id', 'category']" responsive-layout="scroll">
                <template #empty>
                  {{$t('noDataFound')}}
                </template>
                <template #loading>
                 {{$t('loadingData')}}
                </template>
                <Column field="category" :header="$t('category')" sortable>
                  <template #body="{ data }">
                    {{ prettifyText(data.category) }}
                  </template>
                </Column>
                <Column field="get" :header="$t('get').toUpperCase()">
                  <template #body="{ data }">
                    <Checkbox v-model="new_role_permissions_checked" :value="data.permissions[0].id" />
                  </template>
                </Column>
                <Column field="create" :header="$t('create').toUpperCase()">
                  <template #body="{ data }">
                    <Checkbox v-model="new_role_permissions_checked"
                      :value="(data.category != 'Own account') ? data.permissions[1].id : null"
                      v-if="data.category != 'Own account'" />
                  </template>
                </Column>
                <Column field="update" :header="$t('update').toUpperCase()">
                  <template #body="{ data }">
                    <Checkbox v-model="new_role_permissions_checked"
                      :value="(data.category != 'Own account') ? data.permissions[2].id : data.permissions[1].id" />
                  </template>
                </Column>
                <Column field="type" :header="$t('delete').toUpperCase()">
                  <template #body="{ data }">
                    <Checkbox v-model="new_role_permissions_checked"
                      :value="(data.category != 'Own account') ? data.permissions[3].id : data.permissions[2].id" />
                  </template>
                </Column>
              </DataTable>
              <!--
              <div class="col-6" v-for="permission in permission_categories" :key="permission">
                <PermissionGroup :permissionGroupName="permission.category" :permissions="permission.permissions"
                  @updateSelectedPermissions="updateSelectedPermissions"></PermissionGroup>
              </div>
            -->
            </div>
          </div>
        </div>
      </template>
      <template #footer>
        <Button class="btn-custom" :label="$t('save')" @click="addNewRole(new_role_name,new_role_description,new_role_permissions_checked)"></Button>
      </template>
    </Modal>

    <!--============================================================================== viewPermissionsInRole ==============================================================================-->
    <Modal ref="viewPermissionsInRole">
      <template #header>
      </template>
      <template #body>
        <div class="row">
          <div class="col-12 text-center pt-4">
            <i class="pi pi-book" style="font-size:2.5em;"></i>
          </div>
          <div class="col-12 text-center pt-0 pb-0">
            <h5 class="modal-title">{{ $t('permissionsForRole') }} {{ selectedRole.name }}</h5>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <InputText v-model="filters['global'].value" :placeholder="$t('searchBy')" class="search-input mb-2 mt-2"
              style="width:100% !important;"></InputText>
            <div class="col-12 p-0">
              <div class="row">
                <DataTable :value="permission_categories" :paginator="true" class="p-datatable-customers mt-0"
                  :rows="12" current-page-report-template="Showing {first} to {last} of {totalRecords} entries"
                  v-model:filters="filters" :global-filter-fields="['id', 'category']" responsive-layout="scroll">
                  <template #empty>
                    {{$t('noDataFound')}}
                  </template>
                  <template #loading>
                   {{$t('loadingData')}}
                  </template>
                  <Column field="category" :header="$t('category')" sortable>
                    <template #body="{ data }">
                      {{ prettifyText(data.category) }}
                    </template>
                  </Column>
                  <!--
                  <Column field="permissions" :header="$t('departament')" sortable>
                    <template #body="{ data }">
                      {{ prettifyText(data.permissions.departament) }}
                    </template>
                  </Column>
                  -->
                  <Column field="get" :header="$t('get').toUpperCase()">
                    <template #body="{ data }">
                      <Checkbox v-model="permissions_for_selected_role" :value="data.permissions[0].id" />
                    </template>
                  </Column>
                  <Column field="create" :header="$t('create').toUpperCase()">
                    <template #body="{ data }">
                      <Checkbox v-model="permissions_for_selected_role"
                        :value="(data.category != 'Own account') ? data.permissions[1].id : null"
                        v-if="data.category != 'Own account'" />
                    </template>
                  </Column>
                  <Column field="update" :header="$t('update').toUpperCase()">
                    <template #body="{ data }">
                      <Checkbox v-model="permissions_for_selected_role"
                        :value="(data.category != 'Own account') ? data.permissions[2].id : data.permissions[1].id" />
                    </template>
                  </Column>
                  <Column field="type" :header="$t('delete').toUpperCase()">
                    <template #body="{ data }">
                      <Checkbox v-model="permissions_for_selected_role"
                        :value="(data.category != 'Own account') ? data.permissions[3].id : data.permissions[2].id" />
                    </template>
                  </Column>
                </DataTable>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template #footer>
        <Button class="btn-custom" :label="$t('save')" @click="updateSelectedPermissions()"></Button>
      </template>
    </Modal>

    <!--============================================================================== viewUsersInRole ==============================================================================-->
    <Modal ref="viewUsersInRole">
      <template #header>
      </template>
      <template #body>
        <div class="row">
          <div class="col-12 text-center pt-4">
            <i class="pi pi-book" style="font-size:2.5em;"></i>
          </div>
          <div class="col-12 text-center pt-0 pb-0">
            <h5 class="modal-title">{{ $t('usersForRole') }} {{ selectedRole.name }} </h5>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <DataTable :value="usersToAddToRole" :paginator="true" class="p-datatable-customers mt-0" :rows="12"
              current-page-report-template="Showing {first} to {last} of {totalRecords} entries"
              v-model:filters="filters" :global-filter-fields="['id', 'category']" responsive-layout="scroll">
              <template #empty>
                {{$t('noDataFound')}}
              </template>
              <template #loading>
               {{$t('loadingData')}}
              </template>
              <Column field="id" :header="$t('id')" sortable>
                <template #body="{ data }">
                  <span class="row-name-link">{{ data.id }}</span>
                </template>
              </Column>
              <Column field="full_name" :header="$t('name')" sortable>
                <template #body="{ data }">
                  {{ data.full_name }}
                </template>
              </Column>
              <Column field="email" :header="$t('email')">
                <template #body="{ data }">
                  {{ data.email }}
                </template>
              </Column>
            </DataTable>
            <label for="" class="pl-0 pb-0 mb-0"><strong>{{ $t('addOrRemoveUsers') }}</strong></label>
            <hr class="m-0">
            <MultiSelect class="mt-2" v-model="usersToAddToRole" :options="users_backup" optionLabel="full_name"
              :placeholder="$t('chooseUsers')" style="width:100%" display="chip" :filter="true" />
          </div>
        </div>
      </template>
      <template #footer>
        <Button class="btn-custom" :label="$t('save')" @click="addUsersToRole()"></Button>
      </template>
    </Modal>

    <!--============================================================================== viewUsersInTeam ==============================================================================-->
    <Modal ref="viewUsersInTeam">
      <template #header>
      </template>
      <template #body>
        <div class="row">
          <div class="col-12 text-center pt-4">
            <i class="pi pi-th-large" style="font-size:2.5em;"></i>
          </div>
          <div class="col-12 text-center pt-0 pb-0">
            <h5 class="modal-title">{{ $t('usersForTeam') }} {{ selectedTeam.name }}</h5>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <DataTable :value="usersToAddToTeam" :paginator="true" class="p-datatable-customers mt-0" :rows="12"
              current-page-report-template="Showing {first} to {last} of {totalRecords} entries"
              v-model:filters="filters" :global-filter-fields="['id', 'category']" responsive-layout="scroll">
              <template #empty>
                {{$t('noDataFound')}}
              </template>
              <template #loading>
               {{$t('loadingData')}}
              </template>
              <Column field="id" :header="$t('id')" sortable>
                <template #body="{ data }">
                  <span class="row-name-link">{{ data.id }}</span>
                </template>
              </Column>
              <Column field="full_name" :header="$t('name')" sortable>
                <template #body="{ data }">
                  {{ data.full_name }}
                </template>
              </Column>
              <Column field="email" :header="$t('email')">
                <template #body="{ data }">
                  {{ data.email }}
                </template>
              </Column>
            </DataTable>
            <label for="" class="pl-0 pb-0 mb-0"><strong>{{ $t('addOrRemoveUsers') }}</strong></label>
            <hr class="m-0">
            <MultiSelect class="mt-2" v-model="usersToAddToTeam" :options="users_backup" optionLabel="full_name"
              :placeholder="$t('chooseUsers')" style="width:100%" display="chip" :filter="true" />
          </div>
        </div>
      </template>
      <template #footer>
        <Button class="btn-custom" :label="$t('save')" @click="addUsersToTeam()"></Button>
      </template>
    </Modal>

  </div>
</template>
<!----------------------------------------------->
<!--                    SCRIPT                 -->
<!----------------------------------------------->
<script>
import MyShared from "./shared";
export default {
  mixins: [MyShared],
};
</script>
<script setup>
import { useMq } from "vue3-mq";
const mq = useMq();
</script>
<!----------------------------------------------->
<!--                    STYLES                 -->
<!----------------------------------------------->
<style scoped>
.main-user-container {
  height: auto;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
}

.top-user-container {
  background: #fafafd;
  border-bottom: 1px solid #e0e0e0;
  padding: 0.5rem;
  height: 2.5rem;
  background-color: #f7f7f7;
}

.search-users-container {
  padding: 1rem;
  height: auto;
  border-bottom: 1px solid #e0e0e0;
}

.user-aux {
  padding: 0rem;
  padding-left: 1em !important;
  padding-bottom: 0.5em !important;
  border-bottom: 1px solid #e0e0e0;
  width: 100%;
  height: auto;
}

.role-aux {
  padding: 0.5rem;
  padding-top: 0;
  border-bottom: 1px solid #e0e0e0;
  width: 100%;
  height: auto;
}

.role-selector {
  padding-left: 0em;
  padding-right: 0.75em;
}

.user-container-img {
  padding: 0.5rem;
  padding-right: 0em;
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.user-container-checkbox {
  padding: 0.75rem;
  padding-right: 0.5em;
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.user-container-options {
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0;
}

.add-user-container {
  width: 100%;
  margin-left: 12px;
}

.user-option {
  margin: 0.5rem;
}

.option-aux {
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
}

.user-container-name {
  display: block;
  width: auto;
}

.search-user-cont {
  min-height: 80px;
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
  width: 98.75%;
}

.select-user:hover {
  background-color: #f7f7f7;
}

.selected-user {
  border: 1px solid #95d5fa;
  background: #e0f3fa;
  color: #437eec;
  border-radius: 5px;
  width: 100%;
  height: auto;
}

.btn-custom-remove {
  background-color: rgba(255, 255, 255, 0) !important;
  color: #ec635f !important;
  border: none;
  margin-bottom: 0.25rem;
}

.select-user {
  border-left: 1px solid lightgray;
  border-right: 1px solid lightgray;
  border-bottom: 1px solid lightgray;
}

.custom-tab-active {
  color: #ec635f;
  border-bottom: 3px solid #ec635f;
}

.custom-tab {
  color: rgb(182, 182, 182);
  border-bottom: 3px solid rgb(182, 182, 182);
}
</style>