<!----------------------------------------------->
<!--                  TEMPLATE                 -->
<!----------------------------------------------->
<template>
    <div class="aux">
    <Sitebar></Sitebar>
    <div class="maincontainer">
      <Card v-for="source in sources" :key="source" class="source-card">
        <template #content>
          <div class="source-cont text-center">
            <img class="source-img" :src="source.logo">
            <h6 class="source-title mt-4"><strong>{{source.name}}</strong></h6>
          </div>
          
        </template>
      </Card>
    </div>
  </div>
</template>
<!----------------------------------------------->
<!--                    SCRIPT                 -->
<!----------------------------------------------->

<script>
import MyShared from "./shared";
export default {
  mixins: [MyShared],
};

</script>

<script setup>
import { useMq } from "vue3-mq";
const mq = useMq();
</script>

<!----------------------------------------------->
<!--                    STYLES                 -->
<!----------------------------------------------->
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

* {
  font-family: 'Open Sans', sans-serif !important;
}

.source-img {
  width: 100%;
  height: 4em;
  object-fit: contain;
}

.source-card {
  width: 12em;
  height: 12em;
  display: inline-block;
  margin: 1em;
}
</style>
