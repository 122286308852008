<!----------------------------------------------->
<!--                  TEMPLATE                 -->
<!----------------------------------------------->
<template>
  <MobileHome v-if="  mq.current  === 'mobile'"></MobileHome>
  <TabletHome v-if="  mq.current  === 'tablet'"></TabletHome>
  <DesktopHome v-if=" mq.current  === 'desktop'"></DesktopHome>
</template>

<!----------------------------------------------->
<!--                    SCRIPT                 -->
<!----------------------------------------------->
<script>
import MobileHome from "../components/Home/MobileHome";
import TabletHome from "../components/Home/TabletHome";
import DesktopHome from "../components/Home/DesktopHome";

export default {
  /* Name */
  name: "Home",
  inject: ["mq"],
  /* Components */
  components: {
    MobileHome,
    TabletHome,
    DesktopHome,
  },
};
</script>

<script setup>
import { useMq } from "vue3-mq";
const mq = useMq();
</script>

<!----------------------------------------------->
<!--                    STYLES                 -->
<!----------------------------------------------->
<style scoped >
</style>
