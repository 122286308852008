import Sitebar from "../../components/Partials/Sitebar/SiteBar.vue"
import Helper from "../../components/Partials/Helper/Helper.vue"
import Modal from "../../components/Partials/Modals/Modal.vue"
import ConfirmDialog from "primevue/confirmdialog";
import { FilterMatchMode, FilterOperator } from 'primevue/api';
import { ref } from 'vue';
import { useUserStore } from '../../store/store.js'

var MyShared = {
    /* Name */
    name: "Tasks",
    /* Data */
    setup() {

    },
    data() {
        return {
            user: {
                "permissions": []
            },
            listenerFilters: {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
                id: {
                    operator: FilterOperator.AND,
                    constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
                },
                name: {
                    operator: FilterOperator.AND,
                    constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
                },
            },
            assetsFilters: {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
                id: {
                    operator: FilterOperator.AND,
                    constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
                },
                name: {
                    operator: FilterOperator.AND,
                    constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
                },
            },
            detectionFilters: {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
                id: {
                    operator: FilterOperator.AND,
                    constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
                },
                name: {
                    operator: FilterOperator.AND,
                    constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
                },
            },
            correlationFilters: {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
                id: {
                    operator: FilterOperator.AND,
                    constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
                },
                name: {
                    operator: FilterOperator.AND,
                    constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
                },
            },
            machineLearningFilters: {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
                id: {
                    operator: FilterOperator.AND,
                    constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
                },
                name: {
                    operator: FilterOperator.AND,
                    constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
                },
            },
            data: [{
                    id: 1,
                    name: "Analysis periodic",
                    description: "Analysis periodic description",
                    task: "test",
                    scheulde: {"type": "interval", "every": 10, "period": "seconds"},
                    kwargs: {},
                    soft_time_limit: 60,
                    expires: "2023-07-24 11:51:53.561090",
                    start_after: "2020-07-24 11:51:53.561090",
                    enabled: true,
                    total_run_count: 0,
                    max_run_count: 0,
                    run_immediately: true,
                    object_id: "a5f7f9d3-3229-4506-8cff-95bbfb9b6d64",
                    object_type: "Listener",
                    created_at: "2020-07-24 11:51:53.561090",
                },
                {
                    id: 2,
                    name: "Analysis A periodic",
                    description: "Analysis periodic description",
                    task: "test",
                    scheulde: {"type": "interval", "every": 10, "period": "seconds"},
                    kwargs: {},
                    soft_time_limit: 60,
                    expires: "2023-07-24 11:51:53.561090",
                    start_after: "2020-07-24 11:51:53.561090",
                    enabled: true,
                    total_run_count: 0,
                    max_run_count: 0,
                    run_immediately: true,
                    object_id: "a5f7f9d3-3229-4506-8cff-95bbfb9b6d64",
                    object_type: "Detection Rule",
                    created_at: "2020-07-24 11:51:53.561090",
                },
                {
                    id: 3,
                    name: "Analysis B periodic",
                    description: "Analysis periodic description",
                    task: "test",
                    scheulde: {"type": "interval", "every": 20, "period": "seconds"},
                    kwargs: {},
                    soft_time_limit: 60,
                    expires: "2023-07-26 11:51:53.561090",
                    start_after: "2020-07-24 11:51:53.561090",
                    enabled: false,
                    total_run_count: 0,
                    max_run_count: 0,
                    run_immediately: true,
                    object_id: "a5f7f9d3-3229-4506-8cff-95bbfb9b6d64",
                    object_type: "Detection Rule",
                    created_at: "2020-07-28 11:51:53.561090",
                },
                {
                    id: 4,
                    name: "Analysis periodic",
                    description: "Analysis periodic description",
                    task: "test",
                    scheulde: {"type": "interval", "every": 10, "period": "seconds"},
                    kwargs: {},
                    soft_time_limit: 60,
                    expires: "2023-07-24 11:51:53.561090",
                    start_after: "2020-07-24 11:51:53.561090",
                    enabled: true,
                    total_run_count: 0,
                    max_run_count: 0,
                    run_immediately: true,
                    object_id: "a5f7f9d3-3229-4506-8cff-95bbfb9b6d64",
                    object_type: "Asset",
                    created_at: "2020-07-24 11:51:53.561090",
                },
            ],
            listeners: [],
            assets: [],
            detections: [],
            correlations: [],
            machine_learning: [],
            task_name: "",
            task_desc: "",
            task_id: "",
            task_task: "",
            task_schedule_type: "",
            task_schedule_every: "",
            task_schedule_period: "",
            task_kwargs: "",
            task_soft_time_limit: "",
            task_expires: "",
            task_start_after: "",
            task_enabled: "",
            task_total_run_count: "",
            task_max_run_count: "",
            task_run_immediately: "",
            task_object_id: "",
            task_object_type: "",
            
            task_schedule_type_options: [
                { label: "Interval", value: "interval" },
                { label: "Crontab", value: "crontab" },
                { label: "Solar", value: "solar" },
            ],

            task_schedule_period_options: [
                { label: "Seconds", value: "seconds" },
                { label: "Minutes", value: "minutes" },
                { label: "Hours", value: "hours" },
                { label: "Days", value: "days" },
                { label: "Weeks", value: "weeks" },
                { label: "Months", value: "months" },
                { label: "Years", value: "years" },
            ],

        }
    },
    /* Components */
    components: {
        Sitebar,
        Helper,
        Modal,
        ConfirmDialog
    },
    /* Props */
    props: {},
    /* Methods */
    methods: {
        formatDate(date) {
            if (date == null || date == '') return;

            // Format date to dd/mm/yyyy hh:mm
            let d = new Date(date);
            let day = d.getDate();
            let month = d.getMonth() + 1;
            let year = d.getFullYear();
            let hour = d.getHours();
            let minutes = d.getMinutes();
            // Add 0 if month is less than 10
            if (month < 10) {
                month = "0" + month;
            }
            // Add 0 if day is less than 10
            if (day < 10) {
                day = "0" + day;
            }
            // Add 0 if minutes is less than 10
            if (minutes < 10) {
                minutes = "0" + minutes;
            }
            return `${day}/${month}/${year} ${hour}:${minutes}`;
        },
        updateSelectedTaskStatus(id) {
            // find task by id and change is_active to opposite
            this.data.forEach((task) => {
                if (task.id == id) {
                    task.is_active = !task.is_active;
                }
            });
        },
        getTaskData(data) {
            console.log(data);
            this.task_id = data.id;
            this.task_name = data.name;
            this.task_desc = data.description;
            this.task_task = data.task;
            this.task_schedule = data.schedule;
            this.task_kwargs = data.kwargs;
            this.task_soft_time_limit = data.soft_time_limit;
            this.task_expires = data.expires;
            this.task_start_after = data.start_after;
            this.task_enabled = data.enabled;
            this.task_total_run_count = data.total_run_count;
            this.task_max_run_count = data.max_run_count;
            this.task_run_immediately = data.run_immediately;
            this.task_object_id = data.object_id;
            this.task_object_type = data.object_type;
        },
        updateTask(id) {
            // update task
            console.log("update task: " + id);
            // api 


            this.$refs.configTask.closeModal()
        }

    },
    mounted() {
        // loop data and add each task to the correct array depending on object_type
        this.data.forEach((task) => {
            // use switch
            switch (task.object_type) {
                case "Listener":
                    this.listeners.push(task);
                    break;
                case "Asset":
                    this.assets.push(task);
                    break;
                case "Detection Rule":
                    this.detections.push(task);
                    break;
                case "Correlation Rule":
                    this.correlations.push(task);
                    break;
                case "Machine Learning":
                    this.machine_learning.push(task);
                    break;
                default:
                    break;
            }
        });
    }
};
export default MyShared;