<!----------------------------------------------->
<!--                  TEMPLATE                 -->
<!----------------------------------------------->
<template>
  <div class="aux" ref="testDiv">
    <Sitebar></Sitebar>
    <div class="maincontainer" style="padding-bottom:4em; padding-right: 50px;" >
      <ConfirmDialog></ConfirmDialog>
      <!-- ==================================================================================================================================================== -->
      <!-- ======================================================================= Top ======================================================================= -->
      <!-- ==================================================================================================================================================== -->
      <div class="row create-dashboard-top mb-2">
        <div class="card p-2">
          <div class="row p-0 m-0">
            <p for="" class="p-0 m-0 mb-1" v-if="dashboardRequiredValues"><small style="color:red">{{
              $t('requiredValue')
            }}</small></p>
            <Button class="custom-btn col-1 mr-2" :icon="(toggleLibrary || chartSelected) ? 'pi pi-minus' : 'pi pi-plus'"
              style="width:2em !important;"
              @click="(toggleLibrary || chartSelected) ? clearVariables() : toggleLibrary = true; chartSelected = false;" />
            <InputText class="col-4 mr-2" :placeholder="'*' + $t('dashboardName')" v-model="dashboard_name"></InputText>
            <InputText class="col-6 mr-2" :placeholder="$t('dashboardDescription')" v-model="dashboard_desc">
            </InputText>
            <Button :label="$t('save')" class="custom-btn col-2" icon="pi pi-save"
              @click="(dashboard_name) ? saveDashboard(dashboard_name, dashboard_desc) : dashboardRequiredValues = true"
              style="float:right" />
          </div>
        </div>
      </div>
      <!-- ==================================================================================================================================================== -->
      <!-- ======================================================================= Selector ======================================================================= -->
      <!-- ==================================================================================================================================================== -->
      <div class="col-2 card ml-0 mt-2" style="position:absolute; z-index:3;" v-if="toggleLibrary"
        @mouseleave="toggleLibrary = false">
        <h6 class="choose-chart">{{ $t('chartLibrary') }}</h6>
        <hr class="mt-0">
        <p class="choose-chart-helper">{{ $t('chartLibraryDesc') }}</p>
        <div class="create-chart-types" v-for="chart in charts_types" :key="chart">
          <div class="card mb-3 chart-type-selector"
            @click="selectedChartType = chart.value; toggleLibrary = false; getInput();">
            <div class="row">
              <div class="col-4 p-3 pl-4">
                <img :src="chart.img" alt="" class="img-fluid pb-2 pl-3" style="height: 4em;">
              </div>
              <div class="col-8">
                <h6 class="chart-type">{{ chart.name }}</h6>
                <p class="chart-desc">{{ chart.desc }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- ==================================================================================================================================================== -->
      <!-- ======================================================================= Editor ======================================================================= -->
      <!-- ==================================================================================================================================================== -->
      <div class="col-8 card ml-0 mt-2" style="position:absolute; z-index:3;" v-if="chartSelected">
        <div class="row">
          <div class="col">
            <div class="row mb-3 pr-2 pl-2">
              <div
                :class="(new_visualization) ? 'col-6 pt-0 pb-0 text-center createVisualizationTopMenuActive' : 'col-6 pt-0 pb-0 text-center createVisualizationTopMenu'"
                @click="new_visualization = true">
                <p>{{ $t('newVisualization') }}</p>
              </div>
              <div
                :class="(!new_visualization) ? 'col-6 pt-0 pb-0 text-center createVisualizationTopMenuActive' : 'col-6 pt-0 pb-0 text-center createVisualizationTopMenu'"
                @click="new_visualization = false; filterExistingVisualizationsByType(selectedChartType)">
                <p>{{ $t('existingVisualization') }}</p>
              </div>
            </div>
            <span v-if="new_visualization">
              <div class="row p-0 m-0">
                <div class="col-6 pt-0">
                  <!----------------------------------------------------------------- 1 ----------------------------------------------------------------->
                  <!----------------------------------------------------------------- 1 ----------------------------------------------------------------->
                  <!----------------------------------------------------------------- 1 ----------------------------------------------------------------->
                  <div class="row ml-0 mr-0">
                    <label class="mt-1 p-0 col-12"><strong>{{ '1. ' + $t('general')  + ' ' + $t('options')}}</strong></label>
                  </div>
                  <p for="" class="m-0" v-if="requieredValues"><small style="color:red">{{ $t('requiredValue') }}</small>
                  </p>
                  <!-- General -->
                  <label class="mb-0"><span style="color:red">*</span><small>{{ $t('name') }}</small></label>
                  <InputText class="col-12" :placeholder="$t('name')" v-model="new_name"></InputText>
                  <label class="mb-0 mt-2"><small>{{ $t('description') }}</small></label>
                  <InputText class="col-12" :placeholder="$t('description')" v-model="new_desc"></InputText>

                <!-- Rules -->
                <!--
              <label class="mb-0 mt-2">{{ $t('rule') }}</label>
              <div class="row ml-0 mr-0 dropdown">
              <div class="col-11 tags-input p-inputtext p-component pl-0 t-0 pb-0">
                <span id="ruleFormatter"></span>
                
                <input class="input-custom dropdown-toggle" id="navbarDropdown" data-toggle="dropdown" type="text" v-model="aux" @change="onRulesChange(null)" @keyup.delete="deleteLastRule(aux)" @keyup.enter="onEnter(aux)" placeholder="Now goes a value"/>
                <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                  <a class="dropdown-item" v-for="option in option_rules" :key="option">
                    {{ option.name }}
                      </a>
                    </div>
                  <Dropdown id="changeInputFocus" class="input-custom" v-model="aux" :options="option_rules"
                    option-label="name" @keyup="filterOptions()" @change="onRulesChange(null); filterOptions(aux)"
                    :editable="true" @keyup.delete="deleteLastRule(aux)" @keyup.enter="onEnter(aux)"
                    :emptyMessage="'Now goes a value'">
                    <template #option="slotProps">
                      <div class="parentesis-template">{{ slotProps.option.name }}</div>
                    </template>
                  </Dropdown>
                
                </div>
                <div class="col-1 m-0 p-0">
                  <Button class="btn-custom ml-1 mt-0 mr-0 mb-0" icon="pi pi-refresh" @click="refreshRules()"></Button>
                          </div>
                        </div>
                  -->
                  <!-- Text -->
                  <label class="mb-0 mt-1" v-if="selectedChartType == 'text'"><small>{{ $t('text') }}</small></label>
                  <InputText class="col-12" :placeholder="$t('text')" v-if="selectedChartType == 'text'"
                    v-model="new_text">
                  </InputText>

                  <!-- Number -->
                  <label class="mb-0 mt-1" v-if="selectedChartType == 'number'"><small>{{ $t('number') }}</small></label>
                  <InputNumber class="col-12 p-0" :placeholder="$t('number')" v-if="selectedChartType == 'number'"
                    v-model="new_number"></InputNumber>
                  <label class="mb-0 mt-1" v-if="selectedChartType == 'number'"><small>{{ $t('unit') }}</small></label>
                  <InputText class="col-12" :placeholder="$t('unit')" v-if="selectedChartType == 'number'"
                    v-model="new_unit">
                  </InputText>

                  <!-- Palette selector -->
                  <label class="mb-0 mt-1"><small>{{ $t('palette') }}</small></label>
                  <div class="row ml-0 mr-0">
                    <div class="col-11  pl-0 pt-0 pb-0 pr-0">
                      <Dropdown v-model="selectedPalette" :options="palettes" placeholder="Select a Palette"
                        :showClear="true" class="col-12 p-0">
                        <template #value="slotProps">
                          <div class="p-dropdown-palette-value" v-if="slotProps.value">
                            <i class="fa-solid fa-square" v-for="color in slotProps.value.colors" :key="color"
                              :style="'color:' + color"></i>
                          </div>
                        </template>
                        <template #option="slotProps">
                          <div class="p-dropdown-palette-option">
                            <i class="fa-solid fa-square" v-for="color in slotProps.option.colors" :key="color"
                              :style="'color:' + color"></i>
                          </div>
                        </template>
                      </Dropdown>
                    </div>
                    <div class="col-1 m-0 p-0">
                      <Button class="btn-custom ml-1 mt-0 mr-0 mb-0"
                        :icon="(!toggleNewPalette) ? 'pi pi-plus' : 'pi pi-minus'" style="height: 100%;"
                        @click="toggleNewPalette = !toggleNewPalette"></Button>
                    </div>
                  </div>

                  <!------------------------------------------------ New palette ------------------------------------------------>
                  <div class="row ml-0 mr-0 mt-3" v-if="toggleNewPalette" @mouseleave="toggleNewPalette = true"
                    style="width: 25em; z-index: 999; position: inherit;">
                    <div class="card">
                      <label class="mb-0"><span style="color:red">*</span>{{ $t('name') }}</label>
                      <InputText class="col-12" :placeholder="$t('name')" v-model="new_palette_name"></InputText>
                      <div class="row pb-2 pt-0">
                        <div class="col-6 p-2">
                          <ColorPicker v-model="new_color" :inline="true" />
                        </div>
                        <div class="col-6 p-2">
                          <div class="">
                            <span class="p-tag mb-1 mr-1" :style="'background-color: ' + color + ';'"
                              v-for="color in new_palette" :key="color">
                              <p class="m-0 pr-2">{{ color }}</p>
                              <i class="pi pi-times" @click="deleteColor(color)"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div class="row p-0 mb-2 mt-0" style="width:100%;">
                        <div class="col-6 p-0 m-0">
                          <Button class="btn-custom ml-2" icon="pi pi-plus"
                            @click="new_palette.push('#' + new_color)"></Button>
                        </div>
                        <div class="col-6 p-0 m-0">
                          <Button class="btn-custom mr-0" icon="pi pi-plus"
                            @click="addNewPalette(new_palette_name, new_palette)" :label="$t('create')"
                            style="float:right;"></Button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!----------------------------------------------------------------- 2 ----------------------------------------------------------------->
                  <!----------------------------------------------------------------- 2 ----------------------------------------------------------------->
                  <!----------------------------------------------------------------- 2 ----------------------------------------------------------------->
                  <div class="row ml-0 mr-0">
                    <label class="mt-1 p-0 col-12"><strong>{{'2. ' +  $t('Data')  + ' ' + $t('options')}}</strong></label>
                  </div>
                  <label class="mb-0"><small>{{ $t('model') }}</small></label>
                  <Dropdown class="col-12 p-0" v-model="selected_main_model" :options="main_models"
                    :placeholder="$t('select') + ' ' + $t('model')" />

                  <!----------------------------------------------------------------- 3 ----------------------------------------------------------------->
                  <!----------------------------------------------------------------- 3 ----------------------------------------------------------------->
                  <!----------------------------------------------------------------- 3 ----------------------------------------------------------------->
                  <div class="row ml-0 mr-0">
                    <label class="mt-1 p-0 col-12"><strong>{{'3. ' +  $t('metric')  + ' ' + $t('options')}}</strong></label>
                  </div>
                  <label class="mb-0"><small>{{ $t('metric') }}</small></label>
                  <Dropdown class="col-12 p-0" v-model="selected_metric" :options="metrics" optionLabel="name"
                    :placeholder="$t('select') + ' ' + $t('metric')" />

                  <!-- ---------------------------------- Count - Sum - Aug ---------------------------------- -->
                  <span v-if="selected_metric.value == 'sum' || selected_metric.value == 'avg'">
                    <label class="mb-0"><small>{{ $t('field') }}</small></label>
                    <Dropdown class="col-12 p-0" v-model="selected_main_field" :options="main_fields"
                      :placeholder="$t('select') + ' ' + $t('field')" />
                  </span>
                  <!--------------------------------------------------------------------------------------------->
                </div>
                <div class="col-6 pt-0">
                  <!----------------------------------------------------------------- 4 ----------------------------------------------------------------->
                  <!----------------------------------------------------------------- 4 ----------------------------------------------------------------->
                  <!----------------------------------------------------------------- 4 ----------------------------------------------------------------->

                  <!----------------------------------------------------------------- A ----------------------------------------------------------------->
                  <div class="row ml-0 mr-0" v-if="selectedChartType != 'pie'">
                    <label class="mt-1 p-0 col-12"><strong>{{'4. ' +  $t('x_axis')  + ' ' + $t('options')}}</strong></label>
                  </div>
                  <span v-if="selectedChartType != 'pie'">
                    <label class="mb-0"><small>{{ $t('x_axis') }}</small></label>
                    <Dropdown class="col-12 p-0" v-model="selected_x_axis" :options="x_axis" optionLabel="name"
                      :placeholder="$t('select') + ' ' + $t('x_axis')" />
                  </span>
                  <!----------------------------------------------------------------- B ----------------------------------------------------------------->
                  <div class="row ml-0 mr-0" v-if="selectedChartType == 'pie'">
                    <label class="mt-1 p-0 col-12"><strong>{{'4. ' +  $t('slices')  + ' ' + $t('options')}}</strong></label>
                  </div>
                  <label v-if="selectedChartType == 'pie'" class="mb-0"><small>{{ $t('slices') }}</small></label>
                  <Dropdown v-if="selectedChartType == 'pie'"  class="col-12 p-0" v-model="selected_slice" :options="slices" optionLabel="name"
                      :placeholder="$t('select') + ' ' + $t('slices')" />

                  <!-- ---------------------------------- Filter - Term - Date Hist ---------------------------------- -->
                  <span
                    v-if="(selected_slice.value == 'filters' || selected_x_axis.value == 'filters') && (selected_slice.value != '' || selected_x_axis.value != '')">
                    <label class="mb-0"><small>{{ $t('label') }}</small></label>
                    <InputText class="col-12" v-model="filter_label" :placeholder="$t('chooseLabel')"></InputText>
                    <label class="mb-0"><small>{{ $t('qwery') }}</small></label>
                    <div class="dropdown">
                      <Textarea v-model="slices_filter_string" rows="5" cols="28" class="dropdown-toggle" data-toggle="dropdown" autoResize />
                      <div class="dropdown-menu" aria-labelledby="qweryDropdown" style="width: 105%;" @change="updateOptionsList(query)">
                        <a class="dropdown-item" v-for="option in options" :key="option" @click="addToTextarea(option.name)">
                          {{ option.name }}
                        </a>
                      </div>
                    </div>
                    <Button class="col-12" :label="$t('add')" @click="addFilter(filter_label, stringToFilter(slices_filter_string))"></Button>
                    <label class="col-12 mb-0 p-0"><small>{{ $t('filters') }}</small></label>
                    <div class="col-12 pt-1 pb-4 pr-0" style="box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;">
                      <div v-for="(filter, index) in slices_filters" :key="index" class="row">
                        <div class="col-10 p-0">
                          <label class="mb-0"><small>{{ filter.label }}</small></label>
                        </div>
                        <div class="col-2 p-0">
                          <Button class="col-12" :label="$t('X')" @click="deleteFilter(index)"></Button>
                        </div>
                      </div>
                    </div>
                    <label class="mb-0"><small>{{ $t('restoOfDataLabel') }}</small></label>
                    <InputText class="col-12" v-model="other_bucket_key"></InputText>
                  </span>
                  <span
                    v-if="(selected_slice.value == 'terms' || selected_x_axis.value == 'terms') && (selected_slice.value != '' || selected_x_axis.value != '')">
                    <label class="mb-0"><small>{{ $t('field') }}</small></label>
                    <Dropdown v-if="selectedChartType == 'pie'" class="col-12 p-0" v-model="selected_slices_field" :options="fields"
                      :placeholder="$t('select') + ' ' + $t('field')" />
                    <Dropdown v-else class="col-12 p-0" v-model="selected_xaxis_field" :options="fields"
                      :placeholder="$t('select') + ' ' + $t('field')" />
                    <label class="mb-0"><small>{{ $t('orderby') }}</small></label>
                    <Dropdown class="col-12 p-0" v-model="selected_order_by" :options="order_by" optionLabel="name"
                      :placeholder="$t('select') + ' ' + $t('orderby')" />
                    <label class="mb-0"><small>{{ $t('maxNumbOfSlices') }}</small></label>
                    <InputNumber v-if="selectedChartType == 'pie'" class="col-12 p-0" v-model="slices_max"
                      :placeholder="$t('select') + ' ' + $t('maxNumbOfSlices')" />
                    <InputNumber v-else class="col-12 p-0" v-model="xaxis_max"
                      :placeholder="$t('select') + ' ' + $t('maxNumbOfSlices')" />
                  </span>
                  <span
                    v-if="(selected_slice.value == 'date_histogram' || selected_x_axis.value == 'date_histogram') && (selected_slice.value != '' || selected_x_axis.value != '')">
                    <label class="mb-0"><small>{{ $t('field') }}</small></label>
                    <Dropdown v-if="selectedChartType == 'pie'" class="col-12 p-0" v-model="selected_slices_field" :options="fields"
                      :placeholder="$t('select') + ' ' + $t('field')" />
                    <Dropdown v-else class="col-12 p-0" v-model="selected_xaxis_field" :options="fields"
                      :placeholder="$t('select') + ' ' + $t('field')" />
                    <label class="mb-0"><small>{{ $t('interval') }}</small></label>
                    <Dropdown class="col-12 p-0" v-model="selected_interval" :options="intervals" optionLabel="name"
                      :placeholder="$t('select') + ' ' + $t('interval')" />
                    <label class="mb-0"><small>{{ $t('maxNumbOfSlices') }}</small></label>
                    <InputNumber v-if="selectedChartType == 'pie'" class="col-12 p-0" v-model="slices_max"
                      :placeholder="$t('select') + ' ' + $t('maxNumbOfSlices')" />
                    <InputNumber v-else class="col-12 p-0" v-model="xaxis_max"
                      :placeholder="$t('select') + ' ' + $t('maxNumbOfSlices')" />
                  </span>
                  <!------------------------------------------------------------------------------------------------------------------------------------->



                  <!----------------------------------------------------------------- 5 ----------------------------------------------------------------->
                  <!----------------------------------------------------------------- 5 ----------------------------------------------------------------->
                  <!----------------------------------------------------------------- 5 ----------------------------------------------------------------->
                  <div class="row ml-0 mr-0" v-if="(selectedChartType == 'bar' || selectedChartType == 'line')">
                    <label class="mt-1 p-0 col-12"><strong>{{'5. ' +  $t('slices')  + ' ' + $t('options')}}</strong></label>
                  </div>
                    <label class="mb-0" v-if="(selectedChartType == 'bar' || selectedChartType == 'line')"><small>{{ $t('slices') }}</small></label>
                    <div class="col-12 p-0" v-if="(selectedChartType == 'bar' || selectedChartType == 'line')">
                      <InputSwitch v-model="slices_boolean"></InputSwitch>
                      <!-- ---------------------------------- Term ---------------------------------- -->
                      <div class="" v-if="slices_boolean">
                        <label class="mb-0"><small>{{ $t('field') }}</small></label>
                        <Dropdown class="col-12 p-0" v-model="selected_slices_field" :options="fields"
                          :placeholder="$t('select') + ' ' + $t('field')" />
                        <label class="mb-0"><small>{{ $t('orderby') }}</small></label>
                        <Dropdown class="col-12 p-0" v-model="selected_order_by" :options="order_by"
                          optionLabel="name" :placeholder="$t('select') + ' ' + $t('orderby')" />
                        <label class="mb-0"><small>{{ $t('maxNumbOfSlices') }}</small></label>
                        <InputNumber class="col-12 p-0" v-model="slices_max"
                          :placeholder="$t('select') + ' ' + $t('maxNumbOfSlices')" />
                      </div>
                    </div>
                </div>
              </div>


              <!-- Temp -->
              <!--
              <div class="row ml-0 mr-0 mb-2">
                <Button type="submit" class="mt-2" icon="pi pi-plus" style="height: 100%;"
                  @click="addNewData(selectedChartType)"></Button>
              </div>
              -->
            </span>

            <span v-else>
              <div class="row ml-0 mr-0 mb-2 ">
                <Dropdown v-model="selectedExistingChart" :options="filteredVisualizations" optionLabel="name"
                  :placeholder="$t('selectVisualization')"
                  @change="loadSelectedVisualization(selectedChartType, selectedExistingChart.id)" />
              </div>
            </span>


          </div>
          <div class="col-6" style="margin:auto">
            <div class="card p-2" style="">
              <h4 class="mb-0">{{ new_name }}</h4>
              <p class="mt-0 chart-desc">{{ new_desc }}</p>
              <!-- Al tocar un filtro hacer llamada y asignar el chartData devuelto a tempChart.chartData -->
              <Chart v-if="selectedChartType == 'line'" type="line" :data="tempChartAuxData.chartData"
                :options="lineChartOptions" />
              <Chart v-if="selectedChartType == 'bar'" type="bar" :data="tempChartAuxData.chartData"
                :options="lineChartOptions" />
              <Chart v-if="selectedChartType == 'pie'" type="pie" :data="tempChartAuxData.chartData"
                :options="pieChartOptions" />
              <div class="text-chart" v-if="selectedChartType == 'text'">
                <h6>{{ new_text }}</h6>
              </div>
              <div class="number-chart" v-if="selectedChartType == 'number'">
                <number style="font-size: 4em; font-weight:700;" ref="users_number" :from="0" :to="new_number"
                  :duration="2" easing="Power1.easeOut" class="ml-2" :format="theFormat" />
                <h3 class="ml-2" style="display:inline-block">{{ new_unit }}</h3>
              </div>
            </div>
            <p>{{$t('visualizationExample')}}</p>

          </div>
        </div>
        <Button class="mt-2" :label="(!isUpdating) ? $t('addToDashboard') : $t('updateChart')"
          @click="checkRequieredValues(selectedChartType) ? ((!isUpdating) ? addChart(selectedChartType, new_name, new_desc, new_text, new_number, new_unit) : updateChart(selectedToUpdateId, selectedChartType, new_name, new_desc, new_text, new_number, new_unit)) : requieredValues = true"></Button>
        <!-- Here check requiered values -->
      </div>
      <!-- ==================================================================================================================================================== -->
      <!-- ======================================================================= Canvas ======================================================================= -->
      <!-- ==================================================================================================================================================== -->
      <div class="row" style="width: -moz-available;">
        <div class="col-12 pt-0 pb-0 pl-0 pr-0" style="min-height:auto;">
          <div class="dashboard-canvas" style="min-height:72vh;">
            <smart-widget-grid :layout="layout" :verticalCompact="true" @layout-updated="updateLayout" :colNum="18">
              <!-- layout[$].i as slot name -->
              <template v-for="slot in layout" v-slot:[slot.i]="slotProps">
                <!-- ======================================================================= Visualization ======================================================================= -->
                <smart-widget simple :resized="/*refreshChart(slot.i)*/ null" style="height: inherit;">
                  <div class="layout-center" :id="slot.i">
                    <i style="position:absolute; top:0; right:0; cursor:pointer; z-index:9999;"
                      class="pi pi-file-edit mt-1 mr-1" @click="openUpdateChart(slot.i)"></i>
                    <i style="position:absolute; top:0; right:0; cursor:pointer; z-index:9999;"
                      class="pi pi-trash mt-1 mr-4" @click="removeChart(slot.i)"></i>
                    <h5 class="mb-0">{{ slot.name }}</h5>
                    <p class="mt-0 chart-desc">{{ slot.desc }}</p>
                    <!-- Loading auxs -->
                    <div class="loading-aux" v-if="slot.typeof == 'line' || slot.typeof == 'bar' || slot.typeof == 'pie'">
                      <div class="loader text-center mt-2" style="width:100%;" v-if="!slot.chartData">
                        <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                        <p>{{ $t('loading') }}</p>
                      </div>
                    </div>
                    <div class="loading-aux" v-if="slot.typeof == 'number'">
                      <div class="loader text-center mt-2" style="width:100%;" v-if="!slot.number">
                        <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                        <p>{{ $t('loading') }}</p>
                      </div>
                    </div>
                    <div class="loading-aux" v-if="slot.typeof == 'text'">
                      <div class="loader text-center mt-2" style="width:100%;" v-if="!slot.text">
                        <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                        <p>{{ $t('loading') }}</p>
                      </div>
                    </div>
                    <!-- Chart -->
                    <div class="chart-container-aux">
                      <Chart v-if="slot.typeof == 'line' && slot.chartData" type="line" :data="slot.chartData"
                        :options="lineChartOptions" :ref="slot.i" heigth="auto" />

                      <Chart v-if="slot.typeof == 'bar' && slot.chartData" type="bar" :data="slot.chartData"
                        :options="lineChartOptions" :ref="slot.i" />

                      <Chart v-if="slot.typeof == 'pie' && slot.chartData" type="pie" :data="slot.chartData"
                        :options="pieChartOptions" :ref="slot.i" />
                    </div>
                    <div class="text-chart" v-if="slot.typeof == 'text'">
                      <h6>{{ slot.text }}</h6>
                    </div>

                    <div class="number-chart" v-if="slot.typeof == 'number'">
                      <number style="font-size: 4em; font-weight:700;" ref="users_number" :from="0" :to="slot.number"
                        :duration="2" easing="Power1.easeOut" class="ml-2" :format="theFormat" />
                      <h3 class="ml-2" style="display:inline-block">{{ slot.unit }}</h3>
                    </div>
                  </div>
                  <div class="vue-resizable-handle"></div>
                </smart-widget>
                <!-- ==================================================================================================================================================== -->
              </template>
            </smart-widget-grid>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<!----------------------------------------------->
<!--                    SCRIPT                 -->
<!----------------------------------------------->

<script>
import MyShared from "./shared";
export default {
  mixins: [MyShared],
};

</script>

<script setup>
import { useMq } from "vue3-mq";
const mq = useMq();
</script>

<!----------------------------------------------->
<!--                    STYLES                 -->
<!----------------------------------------------->
<style>
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

.choose-chart-helper {
  font-size: 0.8em;
  color: #9e9e9e;
}

.chart-type {
  font-size: 1em;
  font-weight: 500;
  margin-bottom: 0;
}

.chart-desc {
  font-size: 0.8em;
  color: #9e9e9e;
  margin: 0;
  margin-bottom: 0.5em;
}

.chart-type-selector {
  cursor: pointer;
  border-radius: 5px;
  transition: 0.2s;
}

.chart-type-selector:hover {
  background: #f5f5f5;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.dashboard-canvas {
  background: #ffffff;
  height: 100%;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background-size: 40px 40px;
  background-image: radial-gradient(circle, #000000 1px, rgba(0, 0, 0, 0) 1px);
  padding: 0.5em;
}

.vue-grid-item {
  border-radius: 5px !important;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px !important;
  background: #ffffff !important;
  z-index: 2 !important;
}

.vue-resizable-handle {
  width: 15px;
  height: 15px;
  display: inline-block;
  position: absolute;
  bottom: 0;
  right: 0;
}

.vue-grid-item.vue-grid-placeholder {
  background: #eeeeee81 !important;
  z-index: 1 !important;
}

.p-colorpicker-panel {
  background: #f0f0f0 !important;
  border: none !important;
}

.p-tag {
  color: #ffffff;
  font-size: 0.75rem;
  font-weight: 700;
  padding: 0.25rem 0.25rem !important;
  border-radius: 3px;
}

.operator-template {
  background: #ffffff !important;
  color: #737faf !important;
  font-size: 0.75rem;
  font-weight: 700;
  padding: 0.25rem 0.35rem;
  padding-left: 0;
  border-radius: 3px;
  width: max-content;
}

.field-template {
  background: #ffffff !important;
  color: #68afad !important;
  font-size: 0.75rem;
  font-weight: 700;
  padding: 0.25rem 0.4rem;
  padding-right: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  border-radius: 3px;
  width: max-content;
}

.attributte-template {
  background: #ffffff !important;
  color: #74b350 !important;
  font-size: 0.75rem !important;
  font-weight: 700;
  padding: 0.25rem 0.4rem;
  padding-left: 0 !important;
  padding-right: 0 !important;
  border-radius: 3px;
  width: max-content;
}

.value-template {
  background: #ffffff !important;
  color: #e9823d !important;
  font-size: 0.75rem !important;
  font-weight: 700;
  padding: 0.25rem 0.4rem;
  padding-left: 0 !important;
  padding-right: 0 !important;
  border-radius: 3px;
  width: max-content;
}

.parentesis-template {
  background: #ffffff !important;
  color: #000000 !important;
  font-size: 0.75rem;
  font-weight: 700;
  padding: 0.25rem 0.25rem !important;
  border-radius: 3px;
  width: max-content;
}


.tags-input {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}

.input-custom {
  display: flex;
  flex: 1;
  border: none;
  border: none !important;
  width: inherit;
  z-index: 99;
  position: absolute;
  background-color: #737faf;
}

.input-custom:focus {
  border: none !important;
  box-shadow: none !important;
}

.p-dropdown:not(.p-disabled).p-focus {
  box-shadow: none !important;
}

.dropdown-menu {
  position: absolute !important;
  margin-right: 5em;
}

.dropdown-item {
  padding: 5px !important;
}

.widget-body-simple {
  height: 100% !important;
}

.widget-body__content {
  height: 100% !important;
}

.layout-center {
  height: 100% !important;
}

.createVisualizationTopMenu {
  color: #000000;
  border-bottom: #c2c2c2 4px solid;
  cursor: pointer;
}

.createVisualizationTopMenuActive {
  color: #000000;
  border-bottom: #ec635f 4px solid;
  cursor: pointer;
}

.p-chart{
  height: 85% !important;
}
.chart-container-aux{
  height: 92% !important;
}
</style>
