<!----------------------------------------------->
<!--                  TEMPLATE                 -->
<!----------------------------------------------->
<template>
  <MobileDashboardCreator v-if="mq.current === 'mobile'"></MobileDashboardCreator>
  <TabletDashboardCreator v-if="mq.current === 'tablet'"></TabletDashboardCreator>
  <DesktopDashboardCreator v-if="mq.current === 'desktop'"></DesktopDashboardCreator>
</template>

<!----------------------------------------------->
<!--                    SCRIPT                 -->
<!----------------------------------------------->
<script>
import MobileDashboardCreator from "../components/DashboardCreator3/MobileDashboardCreator";
import TabletDashboardCreator from "../components/DashboardCreator3/TabletDashboardCreator";
import DesktopDashboardCreator from "../components/DashboardCreator3/DesktopDashboardCreator";

export default {
  /* Name */
  name: "DashboardCreator",
  inject: ["mq"],
  /* Components */
  components: {
    MobileDashboardCreator,
    TabletDashboardCreator,
    DesktopDashboardCreator,
  },
  methods: {

  },
  /*
  beforeRouteLeave: function(to, from, next) {
      if (this.getDashboardSaved()) {
        next()
      } else {
        const answer = window.confirm('Do you really want to leave? you have unsaved changes!')
        if (answer) {
          next()
        } else {
          next(false)
        }
      }
    }
    */
};
</script>

<script setup>
import { useMq } from "vue3-mq";
const mq = useMq();
</script>

<!----------------------------------------------->
<!--                    STYLES                 -->
<!----------------------------------------------->
<style scoped ></style>
