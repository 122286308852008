<!----------------------------------------------->
<!--                  TEMPLATE                 -->
<!----------------------------------------------->
<template>
    <div class="aux">
    <Sitebar></Sitebar>
    <div class="maincontainer">
      <div class="row">
        <div class="col-12">
          <div class="welcome-container">
            <img class="welcome-img" :src="require('@/assets/logo.svg')" />
            <h2 class="welcome-text">You are welcome</h2>
            <div class="working-text">
              <h5 class=""> You are working in: <a>Name of the Dashboard</a></h5>
              <h6 class="mt-4"> You are actually working for this teams:</h6>
              <div class="team-div mt-2">
                <div class="team" v-for="team in 5" :key="team">
                  <p class="p-0 m-0 team-text"><i class="pi pi-bookmark-fill"></i>&nbsp;Team 1</p>
                </div>
              </div>
              <h2 class="welcome-text mt-2">Quick access</h2>
              <div class="card-div mt-2">
                <div class="card" v-for="access in access_array" :key="access">
                  <p class="p-0 m-0 card-text"><i :class="'pi ' + access.icon"></i>&nbsp;&nbsp;{{ access.name }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="row ">
            <div class="col-12 pt-4 text-center">
              <number style="font-size: 8em; font-weight:700" ref="users_number" :from="0" :to="80" :duration="2"
                easing="Power1.easeOut" />
              <h3>Total indicents</h3>
            </div>
            <div class="col-8 pl-4 pr-0">
              <Chart type="doughnut" :data="chartData" :options="lightOptions" />
            </div>
            <div class="col-4">
              <div class="col-12 legend" style="border-left:#b83c32 10px solid;">
                <p class="m-0 p-0 incidents-label">&nbsp;Critical:</p>
                <p class="m-0 p-0 incidents-number">&nbsp;40</p>
              </div>
              <div class="col-12 legend" style="border-left:#ef6154 10px solid;">
                <p class="m-0 p-0 incidents-label">&nbsp;High:</p>
                <p class="m-0 p-0 incidents-number">&nbsp;60</p>
              </div>
              <div class="col-12 legend" style="border-left:#f7b34c 10px solid;">
                <p class="m-0 p-0 incidents-label">&nbsp;Medium:</p>
                <p class="m-0 p-0 incidents-number">&nbsp;10</p>
              </div>
              <div class="col-12 legend" style="border-left:#6dc193 10px solid;">
                <p class="m-0 p-0 incidents-label">&nbsp;Low:</p>
                <p class="m-0 p-0 incidents-number">&nbsp;30</p>
              </div>
              <div class="col-12 legend" style="border-left:#aeb5c1 10px solid;">
                <p class="m-0 p-0 incidents-label">&nbsp;Information:</p>
                <p class="m-0 p-0 incidents-number">&nbsp;60</p>
              </div>
            </div>
          </div>
          <div class="row  mt-4">
            <Chart type="line" :data="lineChartData" :options="lineChartOptions" />
          </div>

        </div>
      </div>

    </div>
  </div>
</template>
<!----------------------------------------------->
<!--                    SCRIPT                 -->
<!----------------------------------------------->

<script>
import MyShared from "./shared";
export default {
  mixins: [MyShared],
};

</script>

<script setup>
import { useMq } from "vue3-mq";
const mq = useMq();
</script>

<!----------------------------------------------->
<!--                    STYLES                 -->
<!----------------------------------------------->
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

* {
  font-family: 'Open Sans', sans-serif !important;
}


</style>
