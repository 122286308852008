import $axios from '../utils';

export function getAllAssetGroups (full=true, offset=0, limit=10000) {
    return $axios.get(`asset_group/?full=${full}&offset=${offset}&limit=${limit}`)
    .then(response => response.data)
}
export function getAssetGroupsBy(key, value, full, offset, limit){
    return $axios.get(`asset_group/by?key${key}&value=${value}&full=${full}&offset=${offset}&limit=${limit}`)
    .then(response => response.data)
}
export function getOneAssetGroup(id, full) {
    return $axios.get(`asset_group/${id}?full=${full}`)
    .then(response => response.data)
}
export function createAssetGroup(data) {
    return $axios.post('asset_group/', data)
    .then(response => response.data)
}
export function updateAssetGroup(id, data) {
    return $axios.put(`asset_group/${id}`, data)
    .then(response => response.data)
}
export function deleteAllAssetGroups() {
    return $axios.delete('asset_group/')
    .then(response => response.data)
}
export function deleteAssetGroup(id) {
    return $axios.delete(`asset_group/${id}`)
    .then(response => response.data)
}
