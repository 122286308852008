import $axios from '../utils';

export function getAllListeners (full=true, offset=0, limit=10000) {
    return $axios.get(`intake/listener/?full=${full}&offset=${offset}&limit=${limit}`)
    .then(response => response.data)
}
export function getListenersBy(key, value, full, offset, limit){
    return $axios.get(`intake/listener/by?key${key}&value=${value}&full=${full}&offset=${offset}&limit=${limit}`)
    .then(response => response.data)
}
export function getOneListener(id, full) {
    return $axios.get(`intake/listener/${id}?full=${full}`)
    .then(response => response.data)
}
export function createListener(data) {
    return $axios.post('intake/listener/', data)
    .then(response => response.data)
}
export function updateListener(id, data) {
    return $axios.put(`intake/listener/${id}`, data)
    .then(response => response.data)
}
export function deleteAllListeners() {
    return $axios.delete('intake/listener/')
    .then(response => response.data)
}
export function deleteListener(id) {
    return $axios.delete(`intake/listener/${id}`)
    .then(response => response.data)
}
