import $axios from '../utils';

export function getAllVisualizationDashboards(full = true, offset = 0, limit = 10000) {
    return $axios.get(`dashboard/visualization_dashboard/?full=${full}&offset=${offset}&limit=${limit}`)
        .then(response => response.data)
}
export function getVisualizationDashboardsBy(key, value, full, offset, limit) {
    return $axios.get(`dashboard/visualization_dashboard/by?key${key}&value=${value}&full=${full}&offset=${offset}&limit=${limit}`)
        .then(response => response.data)
}
export function getOneVisualizationDashboard(id, full = true) {
    return $axios.get(`dashboard/visualization_dashboard/${id}?full=${full}`)
        .then(response => response.data)
}
export function createVisualizationDashboard(data) {
    return $axios.post('dashboard/visualization_dashboard/', data)
        .then(response => response.data)
}
export function updateVisualizationDashboard(data) {
    return $axios.put('dashboard/visualization_dashboard/', data)
        .then(response => response.data)
}
export function deleteAllVisualizationDashboards() {
    return $axios.delete('dashboard/visualization_dashboard/')
        .then(response => response.data)
}
export function deleteVisualizationDashboard(id) {
    return $axios.delete(`dashboard/visualization_dashboard/${id}`)
        .then(response => response.data)
}