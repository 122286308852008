import Sitebar from "../../components/Partials/Sitebar/SiteBar.vue"
import Helper from "../../components/Partials/Helper/Helper.vue"
import { FilterMatchMode, FilterOperator } from 'primevue/api';
import * as $api from "../../api/api";
import { ref } from 'vue';
import ConfirmDialog from "primevue/confirmdialog";
import { useUserStore } from '../../store/store.js'

var MyShared = {
    /* Name */
    name: "Home",
    /* Data */
    setup() {

    },
    data() {
        return {
            user: {
                "permissions": []
            },
            filters: {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
                id: {
                    operator: FilterOperator.AND,
                    constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
                },
                name: {
                    operator: FilterOperator.AND,
                    constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
                },
                created_at: {
                    operator: FilterOperator.AND,
                    constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
                }
            },
            recentDashboards: [],
            data: [],
        }
    },
    /* Components */
    components: {
        Sitebar,
        Helper,
        ConfirmDialog
    },
    /* Props */
    props: {},
    /* Methods */
    methods: {
        calculateRecentDashboards() {
            // Use created_at to calculate the most recent dashboards
            // Sort by created_at
            this.data.sort((a, b) => {
                return new Date(b.created_at) - new Date(a.created_at);
            });
            // Return the first 3
            return this.data.slice(0, 3);
        },
        formatDate(date) {
            if (date == null || date == '') return;

            // Format date to dd/mm/yyyy hh:mm
            let d = new Date(date);
            let day = d.getDate();
            let month = d.getMonth() + 1;
            let year = d.getFullYear();
            let hour = d.getHours();
            let minutes = d.getMinutes();
            // Add 0 if month is less than 10
            if (month < 10) {
                month = "0" + month;
            }
            // Add 0 if day is less than 10
            if (day < 10) {
                day = "0" + day;
            }
            // Add 0 if minutes is less than 10
            if (minutes < 10) {
                minutes = "0" + minutes;
            }
            return `${day}/${month}/${year} ${hour}:${minutes}`;
        },
        deleteDashboard(id) {
            this.$confirm.require({
                message: this.$t('deleteConfirmationAlert'),
                header: this.$t('deleteConfirmation'),
                icon: 'pi pi-info-circle',
                acceptClass: 'p-button-danger',
                accept: () => {
                    $api.deleteDashboard(id).then((response) => {
                        console.log("[+] Dashboard deleted");
                        // delete the dashboard from the data
                        this.data = this.data.filter((dashboard) => {
                            return dashboard.id !== id;
                        });
                        this.$toast.add({ severity: 'success', summary: this.$t('confirmed'), detail: this.$t('recordDeleted'), life: 3000 });
                    }).catch((err) => {
                        this.$toast.add({ severity: 'error', summary: this.$t('error'), detail: this.$t('errorAlert'), life: 3000 });
                        console.log(`[-] Error (deleteDashboard): ${err}`);
                    });
                },
                reject: () => {
                    //this.$toast.add({severity:'error', summary:this.$t('rejected'), detail:this.$t('rejectedAlert'), life: 3000});
                }
            });

        }
    },
    async mounted() {
        const userStore = useUserStore();
        const userObject = await userStore.getUser();
        this.user = userObject;
        if (process.env.VUE_APP_ENV === 'development') {

        } else if (process.env.VUE_APP_ENV === 'staging' || process.env.VUE_APP_ENV === 'production') {
            this.loading_table = true;
            $api.getAllDashboards(true).then((response) => {
                this.data = response;
                // get the recent dashboards
                console.log("DASHBOARDS: ", this.data);
                this.recentDashboards = this.calculateRecentDashboards();
                this.loading_table = false;

            }).catch((err) => {
                console.log(`[-] Error (getAllDashboards): ${err}`);
            });
        } else {
            console.log("ERROR: NO ENVIRONMENT SELECTED");
        }
    }
};
export default MyShared;