<!----------------------------------------------->
<!--                  TEMPLATE                 -->
<!----------------------------------------------->
<template>
  <MobileLogs v-if="  mq.current  === 'mobile'"></MobileLogs>
  <TabletLogs v-if="  mq.current  === 'tablet'"></TabletLogs>
  <DesktopLogs v-if=" mq.current  === 'desktop'"></DesktopLogs>
</template>

<!----------------------------------------------->
<!--                    SCRIPT                 -->
<!----------------------------------------------->
<script>
import MobileLogs from "../components/Logs/MobileLogs";
import TabletLogs from "../components/Logs/TabletLogs";
import DesktopLogs from "../components/Logs/DesktopLogs";

export default {
  /* Name */
  name: "Logs",
  inject: ["mq"],
  /* Components */
  components: {
    MobileLogs,
    TabletLogs,
    DesktopLogs,
  },
};
</script>

<script setup>
import { useMq } from "vue3-mq";
const mq = useMq();
</script>

<!----------------------------------------------->
<!--                    STYLES                 -->
<!----------------------------------------------->
<style scoped >
</style>
