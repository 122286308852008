<!----------------------------------------------->
<!--                  TEMPLATE                 -->
<!----------------------------------------------->
<template>
  <div class="aux">
    <Sitebar></Sitebar>
    <div class="maincontainer">
      <h4>DeepQL</h4>
      <div class="card p-2 mb-2">
        <h5 class="mt-3">{{ $t('gettingStarted') }}</h5>
        <hr class="mt-0 mb-1">
        <p>{{ $t('deepqlGettingStarted') }}</p>
        <p><strong>{{ $t('whereToFindDeepqlTitle') }}</strong></p>
        <p>{{ $t('whereToFindDeepql') }}</p>
        <img :src="require('@/assets/documentation/deepql_0.png')" alt="DeepQL" class="img-fluid mt-2 mb-2" style="height: auto; width: 720px; margin: auto;">
        <p><strong>{{ $t('howToUseDeepqlTitle') }}</strong></p>
        <p>{{ $t('howToUseDeepql') }}</p>
        <p>{{ $t('howToUseDeepql2') }}</p>
        <img :src="require('@/assets/documentation/deepql_1.png')" alt="DeepQL" class="img-fluid mt-2 mb-2" style="height: auto; width: 400px; margin: auto;">
        <p>{{ $t('howToUseDeepql3') }}</p>
        <p>{{ $t('howToUseDeepql4') }}</p>
        <h5 class="mt-3">{{ $t('usingDeepQL') }}</h5>
        <hr class="mt-0 mb-1">
        <p>{{ $t('usingDeepQL1') }}</p>
        <div class="row m-0 p-0">
          <div class="col-2 m-0 p-0">
            <ul class="ml-4">
              <li class="ml-0 pl-0">LIKE</li>
              <li class="ml-0 pl-0">NLIKE</li>
              <li class="ml-0 pl-0">ILIKE</li>
              <li class="ml-0 pl-0">NILIKE</li>
              <li class="ml-0 pl-0">IN</li>
              <li class="ml-0 pl-0">NOT IN</li>
              <li class="ml-0 pl-0">REGEXP</li>
              <li class="ml-0 pl-0">NREGEXP</li>
              <li class="ml-0 pl-0">IREGEXP</li>
              <li class="ml-0 pl-0">NIREGEXP</li>
            </ul>
          </div>
          <div class="col-2 m-0 p-0">
            <ul class="ml-4">
              <li class="ml-0 pl-0">=</li>
              <li class="ml-0 pl-0">!=</li>
              <li class="ml-0 pl-0">></li>
              <li class="ml-0 pl-0">>=</li>
              <li class="ml-0 pl-0">{{ '<' }}</li>
              <li class="ml-0 pl-0">{{ '<=' }}</li>
            </ul>
          </div>
          <div class="col-2 m-0 p-0">
            <ul class="ml-4">
              <li class="ml-0 pl-0">AND</li>
              <li class="ml-0 pl-0">OR</li>
              <li class="ml-0 pl-0">NOT</li>
              <li class="ml-0 pl-0">()</li>
            </ul>
          </div>
        </div>
        <h5 class="mt-3">{{ $t('examples') }}</h5>
        <hr class="mt-0 mb-1">
        <p>{{ $t('examples1') }}</p>
        <p>- <i>criticality = 0 OR criticality = 1</i></p>
        <p>- <i>criticality IN (0, 1)</i></p>
        <p>- <i>severity > 2</i></p>


        
      </div>
      <h4>Assets</h4>
      <div class="card p-2 mb-2">
        <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. A recusandae debitis ea illum perspiciatis dolore omnis ratione voluptas, dolorum deserunt quam mollitia totam, enim amet velit, voluptatibus exercitationem? Consequuntur, corrupti.</p>
      </div>
    </div>
  </div>
</template>
<!----------------------------------------------->
<!--                    SCRIPT                 -->
<!----------------------------------------------->

<script>
import MyShared from "./shared";
export default {
  mixins: [MyShared],
};

</script>

<script setup>
import { random } from "gsap";
import { useMq } from "vue3-mq";
const mq = useMq();
</script>

<!----------------------------------------------->
<!--                    STYLES                 -->
<!----------------------------------------------->
<style scoped>
</style>
